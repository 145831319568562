<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-19
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">

        <template #buttons>
            <button :disabled="reportInProgress" @click.prevent="saveReport()" class="btn btn-sm btn-outline-primary mr-1">
                <i class="fas fa-download"></i> {{ $t('lib.Grid.download') }}
            </button>
            <button :disabled="reportInProgress || !filterData.dateFrom || !filterData.dateTo" @click.prevent="filterModified()" class="btn btn-sm btn-primary mr-1">
                <i class="fas fa-play"></i> {{ $t('app.build') }}
            </button>
        </template>

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm-6 col-lg-4 col-xl-4">
                    <FieldDate
                        :label="$t('attribute.common.date_from')"
                        :value.sync="filterData.dateFrom"
                        :daysPrevNext="1"
                        required
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl-4">
                    <FieldDate
                        :label="$t('attribute.common.date_to')"
                        :value.sync="filterData.dateTo"
                        :daysPrevNext="1"
                        required
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl-4">
                    <FieldSelect
                        :label="$t('object.core_building')"
                        :value.sync="filterData.core_building_id"
                        source="core_building"
                        showEmptyRow
                        :emptyRowLabel="$t('app.allBuildings')"
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl-4">
                    <FieldSwitch
                        id="isShowDebtEndOnly"
                        label="Только с откр.долгом"
                        :value.sync="filterData.isShowDebtEndOnly"
                        :saveSessionState="`${$options.name}-isShowDebtEndOnly`"
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl-4">
                    <FieldSwitch
                        id="isShowFullReport"
                        label="По датам"
                        :value.sync="filterData.isShowFullReport"
                        :saveSessionState="`${$options.name}-isShowFullReport`"
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl-4">
                    <FieldNumber
                        :label="$t('attribute.common.rows_qty')"
                        :value.sync="filterData.row_qty"
                        :defaultValue="5000"
                        :minValue="10"
                        :maxValue="10000"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <Grid ref="report" :config="gridConfig" />
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    // import { mapGetters } from 'vuex';
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ReportDebts',

        components: {
            TemplatePageStandard,
            FieldDate, FieldSelect, FieldSwitch, FieldNumber,
            Grid,
        },

        // =============== Data ===============
        data() {
            return {
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.reports') },
                    { text: this.$t('MainMenu.reports-debts') },
                ],
                filterData: {
                    // dateOf: null,
                    dateFrom: null,
                    dateTo: null,
                    core_building_id: null,
                    isShowDebtEndOnly: false,
                    isShowFullReport: false,
                    row_qty: null,
                },
                reportInProgress: false,
                gridConfig: null,
                gridConfigFull: {
                    apiUrl: null,
                    readonly: true,
                    hideZero: true,
                    columns: [
                        { type:'string', key:'client_fullname', header:this.$t('object.core_client'), sortable:false },
                        { type:'date', key:'date_of', header:'Дата', sortable:false },
                        { type:'string', key:'user_shortname', header:'Сотрудник', sortable:false },
                        { type:'number', key:'sum_payment', header:'Оказано', sortable:false },
                        { type:'number', key:'sum_debt_begin', header:'Долг (на утро)', sortable:false },
                        { type:'number', key:'sum_debt_plus', header:'Долг (+)', sortable:false },
                        { type:'number', key:'sum_debt_minus', header:'Долг (-)', sortable:false },
                        { type:'number', key:'sum_debt_end', header:'Долг (на вечер)', sortable:false },
                        { type:'string', key:'bill_commentary', header:this.$t('attribute.common.commentary'), sortable:false },
                    ],
                    totals: {
                        columns: [
                            { type:'total', key:'user_shortname', value:this.$t('app.total'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_payment', $value: data => tools.getArrayFieldSum(data, 'sum_payment'), style:'font-weight:bold;' },
                            // { type:'number', key:'sum_debt_begin', $value: data => tools.getArrayFieldSum(data, 'sum_debt_begin'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_debt_plus', $value: data => tools.getArrayFieldSum(data, 'sum_debt_plus'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_debt_minus', $value: data => tools.getArrayFieldSum(data, 'sum_debt_minus'), style:'font-weight:bold;' },
                            // { type:'number', key:'sum_debt_end', $value: data => tools.getArrayFieldSum(data, 'sum_debt_end'), style:'font-weight:bold;' },
                        ],
                    },
                    order: [ 'client_fullname', 'date_of' ],
                    displayToolbar: false,
                    // buttons: [  'actions', 'download' ],
                },
                gridConfigShort: {
                    apiUrl: null,
                    readonly: true,
                    hideZero: true,
                    columns: [
                        { type:'string', key:'client_fullname', header:this.$t('object.core_client') },
                        // { type:'date', key:'date_of', header:'Дата' },
                        { type:'string', key:'user_shortname', header:'Сотрудник' },
                        { type:'number', key:'sum_payment', header:'Оказано' },
                        { type:'number', key:'sum_debt_begin', header:'Долг (на начало периода)' },
                        { type:'number', key:'sum_debt_plus', header:'Долг (+)' },
                        { type:'number', key:'sum_debt_minus', header:'Долг (-)' },
                        { type:'number', key:'sum_debt_end', header:'Долг (на конец периода)' },
                        { type:'string', key:'bill_commentary', header:this.$t('attribute.common.commentary') },
                    ],
                    totals: {
                        columns: [
                            { type:'total', key:'user_shortname', value:this.$t('app.total'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_payment', $value: data => tools.getArrayFieldSum(data, 'sum_payment'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_debt_begin', $value: data => tools.getArrayFieldSum(data, 'sum_debt_begin'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_debt_plus', $value: data => tools.getArrayFieldSum(data, 'sum_debt_plus'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_debt_minus', $value: data => tools.getArrayFieldSum(data, 'sum_debt_minus'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_debt_end', $value: data => tools.getArrayFieldSum(data, 'sum_debt_end'), style:'font-weight:bold;' },
                        ],
                    },
                    order: [ 'client_fullname' ],
                    displayToolbar: false,
                    // buttons: [  'actions', 'download' ],
                },
            };
        },

        // =============== Methods ===============
        methods: {
            async setGridConfig() {
                this.reportInProgress = true;
                this.$refs.report.clear();
                let gc;
                if (this.filterData.isShowFullReport) {
                    gc = this.gridConfigFull;
                } else {
                    gc = this.gridConfigShort;
                }
                gc.paginationPageSize = this.filterData.row_qty;
                gc.filter = {
                    where: [
                        [ 'visit_date', '>=', this.filterData.dateFrom ],
                        [ 'visit_date', '<=', this.filterData.dateTo ],
                        { core_building_id: this.filterData.core_building_id },
                    ],
                };
                gc.filter.where.push({ isShowDebtEndOnly: this.filterData.isShowDebtEndOnly });
                gc.filter.where.push({ isShowFullReport: this.filterData.isShowFullReport });
                // if (this.filterData.isShowDebtEndOnly) {
                //     this.gridConfigFull.filter.where.push({ isShowDebtEndOnly: true });
                // }
                // if (this.filterData.isShowFullReport) {
                //     this.gridConfigFull.filter.where.push({ isShowFullReport: true });
                // }
                if (!gc.apiUrl) {
                    gc.apiUrl = 'report-debts';
                }
                this.gridConfig = gc;
                if (this.gridConfig.apiUrl) {
                    await this.$refs.report.refresh();
                }
                this.reportInProgress = false;
            },

            async filterModified() { await this.setGridConfig() },

            saveReport() {
                this.gridConfig.downloadFileName = (
                    this.breadcrumb[this.breadcrumb.length - 1].text + '_' +
                    moment(this.filterData.dateFrom).format('YYYY-MM-DD') + '_' +
                    moment(this.filterData.dateTo).format('YYYY-MM-DD')
                );
                this.$refs.report.download();
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.filterData.dateFrom = moment().startOf('month').format(moment.HTML5_FMT.DATE);
            this.filterData.dateTo = moment().format(moment.HTML5_FMT.DATE);
        },

        async mounted() {
            // this.setGridConfig();
        },
    }
</script>

<style>
</style>
