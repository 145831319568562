<!--
    Author: Eduard Grebenyukov
    Date: 2020-02-16
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" >
                <i class="fas fa-trash"></i> {{ $t('component.UserEdit.deleteUser') }}
            </ButtonDelete>
            <button v-if="buttons.includes('change-password')" @click.prevent="displayModalPassword = true" class="btn btn-sm btn-outline-primary mr-1">
                <i class="fas fa-key"></i> {{ is_password ? $t('component.UserEdit.changePassword') : $t('component.UserEdit.createPassword') }}
            </button>
        </template>
        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-md-12 col-xl-9">

                        <div class="form-row">
                            <div class="col-sm col-md-3">
                                <FieldString id='lastname' :label="$t('attribute.core_person.lastname')" :value.sync="editFormData.lastname" @modified="itemModified" required labelPosition="above" />
                            </div>
                            <div class="col-sm col-md-3">
                                <FieldString id='firstname' :label="$t('attribute.core_person.firstname')" :value.sync="editFormData.firstname" @modified="itemModified" required labelPosition="above" />
                            </div>
                            <div class="col-sm col-md-3">
                                <FieldString id='patronymic' :label="$t('attribute.core_person.patronymic')" :value.sync="editFormData.patronymic" @modified="itemModified" labelPosition="above" />
                            </div>
                            <div class="col-sm col-md-3">
                                <FieldString id='username' :label="$t('Settings.username')" :value.sync="editFormData.username" @modified="itemModified" labelPosition="above" autocomplete="new-password" />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-8 col-lg-6">
                                <FieldEmail :label="$t('attribute.core_person.email')" :value.sync="editFormData.email" @modified="itemModified" placeholder="name@example.com" labelPosition="above" />
                            </div>
                            <div class="col-md-2 col-lg-3">
                                <FieldSwitch :label="$t('component.UserEdit.is_curator')" id="is_curator" :value.sync="editFormData.is_curator" @modified="itemModified" labelPosition="above" />
                            </div>
                            <div class="col-md-2 col-lg-3">
                                <FieldSwitch :label="$t('attribute.common.is_archived')" id="is_archived" :value.sync="editFormData.is_archived" @modified="itemModified" labelPosition="above" />
                            </div>
                        </div>
                        <!-- <div class="form-row">
                            <div class="col-md-6 col-lg-3">
                                <FieldSelect :label="$t('attribute.core_person.sex')" :value.sync="editFormData.sex" @modified="itemModified" required :source="sexKind" keyId="code"/>
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <FieldDate :label="$t('attribute.core_person.dob')" :value.sync="editFormData.dob" @modified="itemModified" required />
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <FieldString :label="$t('attribute.core_person.social_number')" :value.sync="editFormData.social_number" @modified="itemModified" placeholder="XXX-XXX-XXX YY" />
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <FieldString :label="$t('attribute.core_person.upn')" :value.sync="editFormData.upn" @modified="itemModified" placeholder="XXX-XXX-XXX YY" />
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <FieldEmail :label="$t('attribute.core_person.email')" :value.sync="editFormData.email" @modified="itemModified" placeholder="name@example.com"/>
                            </div>
                        </div> -->
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText :label="$t('Settings.subscribe')" :value.sync="editFormData.subscribe" @modified="itemModified" />
                            </div>
                        </div>
                    </div>
                    <div v-if="displayGrids" class="col-md-12 col-xl-3" style="min-width:220px;">
                        <div class="row">
                            <div class="col">
                                <Grid ref="roleGrid" :config="roleGridConfig" @modified="roleGridModified($event)" />
                                <!-- <grid ref="addresses" :config="addressGridConfig" @add-item="addAddress()" @edit-item="editAddress($event.id)" /> -->
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col">
                                <Grid ref="phones" :config="phoneGridConfig" @add-item="addPhone()" @edit-item="editPhone($event.id)" />
                            </div>
                        </div> -->
                    </div>
                </div>
                <div v-if="displayGrids" class="row">
                    <div class="col-sm">
                        <Grid ref="workplaceGrid" :config="workplaceGridConfig" @modified="workplaceGridModified" @edit-item="editItem($event.id)" />
                    </div>
                </div>
            </div>
        </form>

        <UserPasswordEdit v-if="displayModalPassword" @close-modal="displayModalPassword = false" :userId="itemId" :username="editFormData.username" />

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required } from 'vuelidate/lib/validators';
    //import { mapState, mapActions } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    // import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldString from '@/components/lib/controls/FieldString';
    // import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldEmail from '@/components/lib/controls/FieldEmail';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    // import FieldPassword from '@/components/lib/controls/FieldPassword';
    import FieldText from '@/components/lib/controls/FieldText';

    import Grid from '@/components/lib/regions/Grid';
    import UserPasswordEdit from '@/components/pages/settings/UserPasswordEdit';

    const componentUrl = 'v_user';
    const rolemappingsUrl = 'core_user_role';
    const workplacesUrl = 'core_workplace';

    export default {
        name: 'UserEdit',

        components: {
            TemplatePageStandard,
            ButtonClose, ButtonSave, ButtonDelete,
            FieldString, FieldEmail, FieldSwitch, FieldText,
            Grid, UserPasswordEdit,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                isRoleGridModified: false,
                isWorkplaceGridModified: false,
                editFormData: {
                    lastname: null,
                    firstname: null,
                    patronymic: null,
                    username: null,
                    email: null,
                    is_curator: false,
                    is_archived: false,
                    subscribe: null
                },
                oldFormData: {},
                is_password: null,
                displayModalPassword: false,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-rights-users'), to: '/settings/rights/users' },
                    { text: this.$t('object.core_user') },
                ],
                roleGridConfig: {
                    apiUrl: rolemappingsUrl,
                    filter: null,
                    title: this.$t('component.UserEdit.roles'),
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'core_user_id', header:this.$t('Settings.user'), required:true, source:'users', keyLabel:'username' },
                        { type:'select', key:'core_role_id', header:this.$t('Settings.role'), required:true, source:'core_role' }
                    ],
                    order: [ 'id' ],
                    displayToolbar: true,
                    displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                },
                workplaceGridConfig: {
                    apiUrl: workplacesUrl,
                    filter: null,
                    title: this.$t('component.UserEdit.workplaces'),
                    columns: [
                        { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'core_user_id', header:'User', required: true, source:'users', keyLabel:'username' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        { type:'select', key:'core_dept_id', header:this.$t('object.core_dept'), source:'core_dept', showEmptyRow:true },
                        { type:'select', key:'core_speciality_id', header:this.$t('object.core_speciality'), source:'core_speciality', showEmptyRow:true },
                        { type:'select', key:'core_room_id', header:this.$t('object.core_room'), source:'core_room', showEmptyRow:true },
                        { type:'select', key:'core_device_id', header:this.$t('object.core_device'), source:'core_device', showEmptyRow:true },
                        { type:'string', key:'position', header:this.$t('attribute.common.position') },
                        { type:'date', key:'date_from', header:this.$t('attribute.common.date_from') },
                        { type:'date', key:'date_to', header:this.$t('attribute.common.date_to') },
                        { type:'checkbox', key:'is_schedule', header:this.$t('attribute.core_workplace.is_schedule'), defaultValue:true },
                        { type:'checkbox', key:'is_final', header:this.$t('attribute.core_workplace.is_final'), defaultValue:true },
                        { type:'checkbox', key:'is_self_recording', header:this.$t('attribute.core_workplace.is_self_recording'), defaultValue:false },
                    ],
                    order: [ 'id' ],
                    displayToolbar: true,
                    displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'delete', 'edit' ],
                    // onAdd: 'settings/visits/workplace-edit',
                    onEdit: 'settings/visits/workplace-edit',
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            apiUrl() { return `${this.baseUrl}/${componentUrl}` },
            displayGrids() { return typeof this.editFormData.id !='undefined' },
        },

        // =============== Validations ===============
        validations: {
            editFormData: {
                username: { required },
                email: { required }
            }
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = [ 'save', 'delete', 'close', 'change-password' ];
                } else {
                    this.buttons = [ 'save', 'close' ];
                }
            },

            setGridFilter() {
                let filter = { where:[
                    { core_user_id: this.itemId },
                ]};
                this.roleGridConfig.filter = filter;
                this.roleGridConfig.columns.find(x => x.key === 'core_user_id').defaultValue = this.itemId;
                this.workplaceGridConfig.filter = filter;
                this.workplaceGridConfig.columns.find(x => x.key === 'core_user_id').defaultValue = this.itemId;
                // !!! why its works without .refresh()???
            },

            async getData() {
                try {
                    let user = (await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getData() user=`, user);
                    this.is_password = user.is_password;
                    delete user.is_password;
                    this.editFormData = user;
                    this.isModified = false;
                    this.msgItemModified = '';
                    this.breadcrumb[this.breadcrumb.length - 1].text = user.shortname;
                    this.itemId = user.id;
                    this.checkButtons();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    //this.$v.$touch();
                    //if (!this.$v.$invalid) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update user
                            await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData);
                            tools.displaySuccessSave(this);
                            this.isModified = false;
                        } else {
                            // New user created
                            let user = (await this.authenticatedAxios.post(this.apiUrl, this.editFormData)).data;
                            tools.displaySuccessSave(this);
                            this.isModified = false;
                            // console.log(`DEBUG: ${this.$options.name}.saveData() user=`, user);
                            this.itemId = user.id;
                            this.editFormData.id = this.itemId;
                            this.setGridFilter();
                            this.getData();
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
                
                if (this.isRoleGridModified) {
                    this.$refs.roleGrid.$emit('save');
                    this.isRoleGridModified = false;
                }
                if (this.isWorkplaceGridModified) {
                    this.$refs.workplaceGrid.$emit('save');
                    this.isWorkplaceGridModified = false;
                }
                // this.$emit('close-modal', true);
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified()`);
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                if (
                    this.isModified ||
                    this.isRoleGridModified ||
                    this.isWorkplaceGridModified
                ) {
                    this.msgItemModified = this.$t('component.UserEdit.itemModified');
                } else {
                    this.msgItemModified = '';
                }
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },

            // Grids
            roleGridModified() {
                this.isRoleGridModified = true;
            },

            workplaceGridModified() {
                this.isWorkplaceGridModified = true;
            },

            editItem(id) {
                this.$router.push({ name: this.workplaceGridConfig.onEdit, params: { id } });
            },

        },

        // =============== Life cycle ===============
        beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.id=`, this.id);
            this.itemId = parseInt(this.$route.params.id);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.itemId=`, this.itemId);
            if (this.itemId) {
                await this.getData();
                this.setGridFilter();
            }
            this.checkButtons();
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
        },
    }
</script>

<style>
</style>
