<!--
    Author: Eduard Grebenyukov
    Date: 2020-03-14
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <template #buttons>
            <!-- <ul class="nav nav-tabs schedule-tabs">
                <li class="nav-item">
                    <a href="#schedule-day" @click="selectScheduleType('day')" class="nav-link" :class="{ active: filter.scheduleType == 'day'}" >{{$t('Visit.day')}}</a>
                </li>
                <li class="nav-item">
                    <a href="#schedule-week" @click="selectScheduleType('week')" class="nav-link" :class="{ active: filter.scheduleType == 'week'}" >{{$t('Visit.week')}}</a>
                </li>
            </ul> -->
            <ButtonSwitch
                :value.sync="isDisplayRightSidebar"
                :defaultValue="true"
                :saveState="`${$options.name}.display_sidebar`"
                :title="$t('app.hideShow')"
            />
            <TabSwitch
                :value.sync="filter.scheduleType"
                defaultValue="day"
                :source="navigationTabs"
                :saveState="`${$options.name}.display_type`"
                @modified="filterTabsModified"
            />
        </template>
        <div id="page-content">
            <div class="row">
                <div :class="isDisplayRightSidebar ? 'col-lg-12 col-xl-9' : 'col'">
                    <ScheduleDay
                        ref="scheduleDay"
                        v-if="isMounted && filter.scheduleType == 'day' && filter.visitGroupId && filter.visitDate"
                        :visitGroupId="parseInt(filter.visitGroupId)"
                        :visitDate="getLocalDateFromString(filter.visitDate)"
                        :refreshInterval="parseInt(filter.refreshInterval)"
                        :isShowOfftime="filter.isShowOfftime"
                        :isShowCanceled="filter.isShowCanceled"
                    />
                    <ScheduleWeek
                        ref="scheduleWeek"
                        v-if="isMounted && filter.scheduleType == 'week' && filter.visitGroupId && filter.visitDate"
                        :visitGroupId="parseInt(filter.visitGroupId)"
                        :coreWorkplaceId="parseInt(filter.coreWorkplaceId)"
                        :visitDate="getLocalDateFromString(filter.visitDate)"
                        :refreshInterval="parseInt(filter.refreshInterval)"
                        :isShowOfftime="filter.isShowOfftime"
                        :isShowCanceled="filter.isShowCanceled"
                    />
                </div>
                <div id="right-panel" class="col-lg-12 col-xl-3" :style="isDisplayRightSidebar ? '' : 'display:none;'">
                    <!-- Right sidebar -->
                    <form>
                        <div class="form-float">
                            <div class="form-row">
                                <div class="col">
                                    <FieldSelect
                                        :label="$t('object.visit_group')"
                                        :value.sync="filter.visitGroupId"
                                        :defaultValue="filter.visitGroupIdDefault"
                                        :source="filter.visitGroupSource"
                                        :saveState="`${$options.name}.visit_group_id`"
                                        @modified="filterGroupModified"
                                    />
                                </div>
                            </div>
                            <div v-if="filter.scheduleType == 'week'" class="form-row">
                                <div class="col">
                                    <FieldSelect
                                        :label="$t('object.core_workplace')"
                                        :value.sync="filter.coreWorkplaceId"
                                        :defaultValue="filter.coreWorkplaceIdDefault"
                                        :source="filter.coreWorkplaceSource"
                                        keyLabel="workplace_name"
                                        :saveState="`${$options.name}.core_workplace_id`"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col">
                                    <FieldDate
                                        :label="$t('app.date')"
                                        :value.sync="filter.visitDate"
                                        :defaultValue="filter.visitDateDefault"
                                        saveSessionState="schedule.date"
                                        :daysPrevNext="filter.daysPrevNext"
                                        @modified="filterDateModified"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col">
                                    <FieldSelect
                                        :label="$t('app.refresh_interval')"
                                        :value.sync="filter.refreshInterval"
                                        :defaultValue="null"
                                        :source="refreshIntervalArray"
                                        order="id"
                                        :saveState="`${$options.name}.refresh_interval`"
                                        @modified="filterRefreshIntervalModified"
                                    >
                                        <button slot="buttons"
                                            @click.prevent="refresh()"
                                            class="btn btn-outline-secondary"
                                            :title="$t('app.buttonRefresh')"
                                        >
                                            <i class="fas fa-sync"></i>
                                        </button>
                                    </FieldSelect>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col">
                                    <FieldSwitch
                                        id="is_show_offtime"
                                        :label="$t('Visit.show_offtime')"
                                        :value.sync="filter.isShowOfftime"
                                        :defaultValue="false"
                                        :saveState="`${$options.name}.is_show_offtime`"
                                        @modified="filterIsShowOfftimeModified"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col">
                                    <FieldSwitch
                                        id="is_show_canceled"
                                        :label="$t('Visit.show_canceled')"
                                        :value.sync="filter.isShowCanceled"
                                        :defaultValue="false"
                                        :saveState="`${$options.name}.is_show_canceled`"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ButtonSwitch from '@/components/lib/controls/ButtonSwitch';
    import TabSwitch from '@/components/lib/controls/TabSwitch';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import ScheduleDay from './ScheduleDay';
    import ScheduleWeek from './ScheduleWeek';

    export default {
        name: 'Schedule',
        components: {
            TemplatePageStandard, ScheduleDay, ScheduleWeek, ButtonSwitch, TabSwitch,
            FieldSelect, FieldDate, FieldSwitch,
        },

        // =============== Data ===============
        data() {
            return {
                isMounted: false,
                scheduleDescription: null,
                navigationTabs: [
                    { id: 'day', name: this.$t('Visit.day') },
                    { id: 'week', name: this.$t('Visit.week') },
                ],
                isDisplayRightSidebar: null,
                filter: {
                    scheduleType: null,
                    daysPrevNext: null,
                    visitGroupId: null,
                    visitGroupSource: 'visit_group' + tools.getFilterString({ order:[ 'name' ]}),
                    visitGroupIdDefault: null,
                    coreWorkplaceId: null,
                    coreWorkplaceIdDefault: null,
                    coreWorkplaceSource: null,
                    visitDate: null,
                    visitDateDefault: null,
                    visitDateStart: null,
                    visitDateEnd: null,
                    isShowOfftime: false,
                    isShowCanceled: false,
                    refreshInterval: null,
                },
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    // { text: this.$t('MainMenu.schedule') },
                    { text: this.scheduleDescription },
                ],
                refreshIntervalArray: [
                    { id: null, name: `${this.$t('app.no_refresh')}` },
                    { id: 60, name: `1 ${this.$t('app.minute_short')}` },
                    { id: 120, name: `2 ${this.$t('app.minute_short')}` },
                    { id: 300, name: `5 ${this.$t('app.minute_short')}` },
                    { id: 600, name: `10 ${this.$t('app.minute_short')}` },
                ],
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            // apiUrl() { return this.baseUrl + topicsUrl },
            // daysPrevNext() { return this.filter.scheduleType === 'week' ? 7 : 1 },
        },

        // =============== Methods ===============
        methods: {
            setBreadcrumb() {
                if (this.filter.visitDate && this.filter.visitDateStart && this.filter.visitDateEnd) {
                    this.breadcrumb[this.breadcrumb.length - 1].text =
                        this.$t('MainMenu.schedule') + ' ' + (
                            this.filter.scheduleType ==='day' ?
                            moment(this.filter.visitDate).format('L') :
                            `${moment(this.filter.visitDateStart).format('L')} - ${moment(this.filter.visitDateEnd).format('L')}`
                        )
                    ;
                }
            },

            async setGroupDefaultValue() {
                try {
                    let response = await this.authenticatedAxios.get(`${this.baseUrl}/visit_group`);
                    // console.log(`DEBUG: ${this.$options.name}.setGroupDefaultValue() data=`, response.data);
                    this.filter.visitGroupIdDefault = response.data.rows[0].id;
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async setWorkplaceDefaultValue() {
                try {
                    if (this.filter.coreWorkplaceSource) {
                        let workplaceArray = (await this.authenticatedAxios.get(`${this.baseUrl}/${this.filter.coreWorkplaceSource}`)).data.rows;
                        if (workplaceArray.length > 0 && !workplaceArray.find( el => el.id == this.filter.coreWorkplaceId )) {
                            // use "==" not "===" in find() above since the user_setting stored value may represented id as string
                            this.filter.coreWorkplaceIdDefault = workplaceArray[0].id;
                            this.filter.coreWorkplaceId = workplaceArray[0].id;
                            // !!!!
                            // when line above is uncommented:
                            // if this.workplaceArray will be loaded faster than filter.coreWorkplaceId loaded it's default value, then list value will be set to first value
                            // when line above is commened:
                            // if list value loaded its default value and value will be not in new list, then value will not displayed in list
                        }
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            setDates() {
                this.filter.daysPrevNext = this.filter.scheduleType === 'week' ? 7 : 1;
                if (this.filter.daysPrevNext) {
                    this.filter.visitDateStart = moment(this.filter.visitDate).clone().weekday(0).toDate();
                    this.filter.visitDateEnd = moment(this.filter.visitDateStart).clone().add(this.filter.daysPrevNext - 1, 'days').toDate();
                    // console.log(`DEBUG: ${this.$options.name}.setDates() this.filter.visitDateStart=${this.filter.visitDateStart}, this.filter.visitDateEnd=${this.filter.visitDateEnd}`);
                    this.setBreadcrumb();
                } else {
                    this.filter.visitDateStart = null;
                    this.filter.visitDateEnd = null;
                }
            },

            async setWorkplaceSource() {
                // console.log(`DEBUG: ${this.$options.name}.setWorkplaceSource(), this.filter=`, this.filter);
                if (this.filter.visitDateStart && this.filter.visitDateEnd && this.filter.visitGroupId) {
                    let dateFrom = moment(this.filter.visitDateStart).format(moment.HTML5_FMT.DATE);
                    let dateTo = moment(this.filter.visitDateEnd).format(moment.HTML5_FMT.DATE);
                    this.filter.coreWorkplaceSource = `schedule-workplaces?dateFrom=${dateFrom}&dateTo=${dateTo}&visitGroup=${this.filter.visitGroupId}`;
                    await this.setWorkplaceDefaultValue();
                } else {
                    this.filter.coreWorkplaceSource = null;
                }
            },

            getLocalDateFromString(dateISOString) {
                // console.log(`DEBUG: ${this.$options.name}.getLocalDateFromString('${dateISOString}')`);
                // return new Date(dateISOString.split('-')[0], dateISOString.split('-')[1] - 1, dateISOString.split('-')[2]);
                // return new Date(dateISOString); // parsed as UTC 00:00:00
                // let d = new Date(dateISOString);
                // return new Date(d.getTime() + d.getTimezoneOffset() * 60000); // local 00:00:00
                return moment(dateISOString).toDate(); // local 00:00:00
            },

            async filterTabsModified() {
                // console.log(`DEBUG: ${this.$options.name}.filterTabsModified() filter.scheduleType=`, this.filter.scheduleType);
                // console.log(`DEBUG: ${this.$options.name}.filterTabsModified() this.$refs=`, this.$refs);
                // this.$refs.scheduleDay.refresh();
                this.setDates();
                await this.setWorkplaceSource();
            },

            async filterGroupModified() {
                // console.log(`DEBUG: ${this.$options.name}.filterGroupModified() filter.visitGroupId=`, this.filter.visitGroupId);
                // this.$refs.scheduleDay.refresh();
                await this.setWorkplaceSource();
            },

            async filterDateModified() {
                // console.log(`DEBUG: ${this.$options.name}.filterDateModified() this.filter.daysPrevNext=`, this.filter.daysPrevNext);
                // this.$refs.scheduleDay.refresh();
                this.setDates();
                await this.setWorkplaceSource();
            },

            filterIsShowOfftimeModified() {
                // console.log(`DEBUG: ${this.$options.name}.filterIsShowOfftimeModified() filter.visitDate=`, this.filter.visitDate);
                // this.$refs.scheduleDay.refresh();
            },

            filterRefreshIntervalModified() {
                // console.log(`DEBUG: ${this.$options.name}.filterRefreshIntervalModified filter.visitDate=`, this.filter.visitDate);
                // this.$refs.scheduleDay.refresh();
            },

            refresh() {
                if (this.filter.scheduleType ==='day') {
                    this.$refs.scheduleDay.getData();
                }
                if (this.filter.scheduleType ==='week') {
                    this.$refs.scheduleWeek.getData();
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // let currentDate = moment().locale('en').format('L');
            // let currentDate = (new Date()).toISOString().split('T')[0]; // UTC 00:00:00
            // let currentDate = moment().toDate().toISOString().split('T')[0]; // local 00:00:00
            let currentDate = moment().format(moment.HTML5_FMT.DATE);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() filter.visitGroupId=`, this.filter.visitGroupId, ', currentDate=', currentDate);
            this.filter.visitDateDefault = currentDate;
            await this.setGroupDefaultValue();
        },
        async mounted() {
            this.isMounted = true;
        },
    }
</script>

<style scoped>
    #page-content {
        /* background: #efe; */
        height: calc(100vh - 130px);
    }
    #right-panel {
        background: #e9ecef;
        height: calc(100vh - 130px);
        margin-top: -8px;
        padding-top: 8px;
    }
    .schedule-tabs {
        margin-right: 8px;
    }
</style>
