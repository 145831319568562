<!--
    Author: Eduard Grebenyukov
    Date: 2020-05-01
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>
        <form>
            <div id="page-content" class="form-float">
                <div class="form-row">
                    <div class="col-sm">
                        <FieldString :label="$t('attribute.common.app_code')" :value.sync="editFormData.app_code" @modified="itemModified" />
                    </div>
                    <div class="col-sm">
                        <FieldString :label="$t('attribute.common.code')" :value.sync="editFormData.code" required @modified="itemModified" />
                    </div>
                    <div class="col-sm">
                        <FieldString :label="$t('attribute.common.name')" :value.sync="editFormData.name" required @modified="itemModified" />
                    </div>
                    <div class="col-sm">
                        <FieldString :label="$t('attribute.common.url')" :value.sync="editFormData.url" @modified="itemModified" />
                    </div>
                </div>
                <div class="form-row mb-2">
                    <div class="col-sm">
                        <TabSwitch
                            :value.sync="currentTab"
                            :source="navigationTabs"
                        />
                    </div>
                </div>
                <div :class="{'component-edit__invisible': currentTab !== 'template_text'}" class="form-row">
                    <div class="col-sm">
                        <!-- :label="$t('attribute.core_component.template_text')"  -->
                        <!-- <FieldText :value.sync="editFormData.template_text" :maxHeight="650" @modified="itemModified" inputStyle="background-color: #343a40; color: #fff;" /> -->
                        <!-- <CodeEditor v-model="editFormData.template_text" width="100%" height="650px" :hide_header="true" ></CodeEditor> -->
                        <!-- <textarea type="text" v-model="editFormData.template_text" width="100%" height="650px"></textarea> -->
                        <FieldCodeEditor :value.sync="editFormData.template_text" syntax="html" @modified="itemModified" />
                        <!-- <FieldCodeEditor2 id="asdf" :value.sync="editFormData.template_text" @modified="itemModified" /> -->
                    </div>
                </div>
                <div :class="{'component-edit__invisible': currentTab !== 'script_text'}" class="form-row">
                    <div class="col-sm">
                        <!-- :label="$t('attribute.core_component.script_text')"  -->
                        <!-- <FieldText :value.sync="editFormData.script_text" :maxHeight="650" @modified="itemModified" inputStyle="background-color: #343a40; color: #fff;" /> -->
                        <!-- <CodeEditor v-model="editFormData.script_text" width="100%" height="650px" :hide_header="true" /> -->
                        <FieldCodeEditor :value.sync="editFormData.script_text" syntax="js" @modified="itemModified" />
                        <!-- <FieldCodeEditor2 :value.sync="editFormData.script_text" @modified="itemModified" /> -->
                    </div>
                </div>
                <div :class="{'component-edit__invisible': currentTab !== 'init_text'}" class="form-row">
                    <div class="col-sm">
                        <!-- :label="$t('attribute.core_component.init_text')"  -->
                        <!-- <FieldText :value.sync="editFormData.init_text" :maxHeight="650" @modified="itemModified" inputStyle="background-color: #343a40; color: #fff;" /> -->
                        <FieldCodeEditor :value.sync="editFormData.init_text" syntax="js" @modified="itemModified" />
                    </div>
                </div>
                <div :class="{'component-edit__invisible': currentTab !== 'request_text'}" class="form-row">
                    <div class="col-sm">
                        <FieldSelect
                            :label="$t('attribute.core_component.request_type_id')"
                            :value.sync="editFormData.request_type_id"
                            source="enum/component_request_type"
                            showEmptyRow
                            @modified="itemModified"
                        />
                    </div>
                </div>
                <div :class="{'component-edit__invisible': currentTab !== 'request_text'}" class="form-row">
                    <div class="col-sm">
                        <!-- :label="$t('attribute.core_component.request_text')"  -->
                        <!-- <FieldText :value.sync="editFormData.request_text" :maxHeight="650" @modified="itemModified" inputStyle="background-color: #343a40; color: #fff;" /> -->
                        <FieldCodeEditor :value.sync="editFormData.request_text" syntax="sql" @modified="itemModified" />
                    </div>
                </div>
                <div :class="{'component-edit__invisible': currentTab !== 'style_text'}"  class="form-row">
                    <div class="col-sm">
                        <!-- :label="$t('attribute.core_component.style_text')"  -->
                        <!-- <FieldText :value.sync="editFormData.style_text" :maxHeight="650" @modified="itemModified" inputStyle="background-color: #343a40; color: #fff;" /> -->
                        <FieldCodeEditor :value.sync="editFormData.style_text" syntax="css" @modified="itemModified" />
                    </div>
                </div>
            </div>
        </form>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    import TabSwitch from '@/components/lib/controls/TabSwitch';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldString from '@/components/lib/controls/FieldString';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldCodeEditor from '@/components/lib/controls/FieldCodeEditor';
    // import FieldCodeEditor2 from '@/components/lib/controls/FieldCodeEditor2';

    const componentUrl = 'core_component';

    export default {
        name: 'ComponentEdit',

        components: {
            // CodeEditor,
            // PrismEditor,
            TemplatePageStandard, TabSwitch,
            ButtonClose, ButtonSave, ButtonDelete,
            FieldString, FieldSelect, FieldCodeEditor, //FieldCodeEditor2,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                currentTab: 'template_text',
                navigationTabs: [
                    { id: 'template_text', name: this.$t('attribute.core_component.template_text') },
                    { id: 'script_text', name: this.$t('attribute.core_component.script_text') },
                    { id: 'init_text', name: this.$t('attribute.core_component.init_text') },
                    { id: 'request_text', name: this.$t('attribute.core_component.request_text') },
                    { id: 'style_text', name: this.$t('attribute.core_component.style_text') },
                ],
                editFormData: {
                    app_code: null,
                    code: null,
                    name: null,
                    url: null,
                    description: null,
                    request_type_id: null,
                    request_text: null,
                    template_text: null,
                    script_text: null,
                    init_text: null,
                    style_text: null,
                },
                oldFormData: {},
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-general-components'), to: '/settings/general/components' },
                    { text: this.$t('object.core_component') },
                ],
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            apiUrl() { return `${this.baseUrl}/${componentUrl}` },
            displayGrids() { return typeof this.editFormData.id !='undefined' },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            gotData() {
                this.itemId = this.editFormData.id;
                this.isModified = false;
                this.msgItemModified = '';
                this.breadcrumb[this.breadcrumb.length - 1].text = this.editFormData.name;
                this.itemId = this.editFormData.id;
                this.checkButtons();
            },

            async getData() {
                try {
                    this.editFormData = (await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getData() data=`, response.data);
                    this.gotData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // console.log('Update...');
                            // Update
                            this.editFormData = (await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData)).data;
                            tools.displaySuccessSave(this);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response=`, response);
                            this.gotData();
                        } else {
                            // console.log('Create new...');
                            // Create new
                            this.editFormData = (await this.authenticatedAxios.post(this.apiUrl, this.editFormData)).data;
                            tools.displaySuccessSave(this);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                            this.gotData();
                            this.$router.replace({ name: 'settings/general/component-edit', params: { id:this.itemId } });
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },

            // codeModified(evt) {
            //     console.log(`DEBUG: ${this.$options.name}.codeModified() evt=`, evt);
            //     this.editFormData.template_text = evt.target.value;
            //     this.itemModified();
            // },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() evt=`, evt);
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.editFormData=`, this.editFormData);
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
            },

            editItem(id) {
                this.$router.push({ name: this.workplaceGridConfig.onEdit, params: { id } });
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },
        },

        // =============== Life cycle ===============
        beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.id=`, this.id);
            this.itemId = parseInt(this.$route.params.id);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.itemId=`, this.itemId);
            if (this.itemId) {
                await this.getData();
            }
            this.checkButtons();
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
        },
    }
</script>

<style>
    .component-edit__invisible {
        display: none;
    }
</style>
