/*
    Author: Eduard Grebenyukov
    Date: 20190122
*/

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './lang/en.json';
import ru from './lang/ru.json';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: { en, ru }
});
