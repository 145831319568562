/*
    Author: Eduard Grebenyukov
    Date: 2021-06-26
*/

import { i18n } from '@/locales';

const pageConfigNavigation = {
    nav_map: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-navigation-maps') }
        ],
        gridConfig: {
            apiUrl: 'nav_map',
            // readonly: true,
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                { type:'select', key:'core_building_id', header:i18n.t('object.core_building'), required:true, source:'core_building' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name') },
                { type:'number', key:'floor', header:i18n.t('Settings.floor') },
            ],
            // buttons: [ 'add', 'save', 'delete', 'edit' ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'nav_map',
            // onAdd: 'settings/navigation/map-new',
            // onEdit: 'settings/navigation/map-edit',
        }
    },
    nav_point: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-navigation-points') }
        ],
        gridConfig: {
            apiUrl: 'nav_point',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                { type:'select', key:'nav_map_id', header:i18n.t('object.nav_map'), required:true, source:'nav_map' },
                { type:'select', key:'core_room_id', header:i18n.t('object.core_room'), source:'core_room', showEmptyRow:true },
                { type:'select', key:'core_device_id', header:i18n.t('object.core_device'), source:'core_device', showEmptyRow:true },
                { type:'number', key:'x', header:i18n.t('Settings.positionX'), width:'128px' },
                { type:'number', key:'y', header:i18n.t('Settings.positionY'), width:'128px' },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'nav_point',
        },
    },
    nav_connection: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-navigation-connections') }
        ],
        gridConfig: {
            apiUrl: 'nav_connection',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                { type:'select', key:'point1_id', header:i18n.t('object.nav_point'), required:true, source:'nav_point', keyLabel:'code' },
                { type:'select', key:'point2_id', header:i18n.t('object.nav_point'), required:true, source:'nav_point', keyLabel:'code' },
                { type:'number', key:'distance', header:i18n.t('Settings.distance'), width:'128px' },
                { type:'checkbox', key:'is_archived', header:i18n.t('attribute.nav_connection.is_archived'), defaultValue:false, align:'center', width:'50px' },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'nav_connection',
        },
    },
}

export { pageConfigNavigation };
