<!--
    Author: Eduard Grebenyukov
    Date: 2021-06-17
-->

<template>
    <StandardRegion :title="$t('component.CashdeskInfo.cashdesk')">
        <div class="cashdesk-info__wrapper">
            <table class="cashdesk-info__header">
                <tr>
                    <th class="cashdesk-info__title">{{ $t('attribute.fin_cash.core_workplace_id') + ':' }}</th>
                    <td class="cashdesk-info__value">{{ currentUser.shortname }}</td>
                </tr>
                <!-- <tr>
                    <th class="cashdesk-info__title">{{ $t('attribute.fin_cash.cash_register_id') + ':' }}</th>
                    <td class="cashdesk-info__value error">{{ $t('component.CashdeskInfo.notConnected') }}</td>
                </tr> -->
                <tr v-if="!coreDeviceArray || coreDeviceArray.length < 1">
                    <th class="cashdesk-info__title">{{ $t('attribute.fin_cash.cash_register_id') + ':' }}</th>
                    <td class="cashdesk-info__value error">{{ $t('component.CashdeskInfo.notConnected') }}</td>
                </tr>
                <tr v-if="coreDeviceArray && coreDeviceArray.length > 0">
                    <th class="cashdesk-info__title">
                        <div style="margin-top:0.3rem;">
                            {{ $t('attribute.fin_cash.cash_register_id') + ':' }}
                        </div>
                    </th>
                    <td class="cashdesk-info__value error">
                        <FieldSelect
                            labelPosition="before"
                            :value.sync="editFormData.cash_register_id"
                            :source="coreDeviceArray"
                            inputStyle="margin-bottom:-15px;"
                            :showEmptyRow="true"
                            @modified="cashRegisterIdSelected()"
                        />
                    </td>
                </tr>
                <tr>
                    <th class="cashdesk-info__title">{{ $t('attribute.fin_cash.pos_terminal_id') + ':' }}</th>
                    <td class="cashdesk-info__value error">{{ $t('component.CashdeskInfo.notConnected') }}</td>
                </tr>
            </table>
        </div>
    </StandardRegion>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import tools from '@/components/lib/tools';
    import StandardRegion from '@/components/lib/regions/StandardRegion';
    import FieldSelect from '@/components/lib/controls/FieldSelect';

    export default {
        name: 'CashdeskInfo',

        components: {
            StandardRegion,
            FieldSelect,
        },

        // =============== Data ===============
        data() {
            return {
                coreDeviceArray: null,
                editFormData: {
                    cash_register_id: null,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                selectedCashRegisterId: 'selectedCashRegisterId',
            }),
        },

        // =============== Watch ===============
        watch: {
            selectedCashRegisterId() {
                // console.log(`DEBUG: ${this.$options.name}.watch:cashRegisterIdSelected() this.selectedCashRegisterId=`, this.selectedCashRegisterId);
                this.editFormData.cash_register_id = this.selectedCashRegisterId;
            },
        },

        // =============== Methods ===============
        methods: {
            ...mapActions([
                'selectCashRegisterId',
            ]),

            async cashRegisterIdSelected() {
                // console.log(`DEBUG: ${this.$options.name}.cashRegisterIdSelected()`);
                this.selectCashRegisterId(this.editFormData.cash_register_id);
            },

            async getData() {
                // console.log(`DEBUG: ${this.$options.name}.getData()`);
                try {
                    this.coreDeviceArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-get-cash-registers`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.coreDeviceArray=`, this.coreDeviceArray);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            await this.getData();
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.selectedCashRegisterId=`, this.selectedCashRegisterId);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.selectedCashRegisterId=`, this.selectedCashRegisterId);
            this.editFormData.cash_register_id = this.selectedCashRegisterId;
        },
    }

</script>

<style scoped>
    .cashdesk-info__wrapper {
        padding-bottom: 1rem;
        /* background-color: rgb(252, 242, 213); */
        /* background-color: #fff8e5; */
    }
    .cashdesk-info__title {
        padding: .375rem 0.5rem;
        white-space: nowrap;
        vertical-align: top;
        font-size: 0.875rem;
        font-weight: bold;
    }
    .cashdesk-info__header {
        width: 100%;
    }
    .cashdesk-info__header .cashdesk-info__title {
        text-align: right;
    }
    .cashdesk-info__details .cashdesk-info__title {
        margin-top: 0.5rem;
    }
    .cashdesk-info__value {
        padding-right: 0.5rem;
        font-size: 0.875rem;
    }
    .cashdesk-info__value.error {
        color: red;
    }
    .cashdesk-info__details .cashdesk-info__value {
        padding-left: 0.5rem;
    }
</style>
