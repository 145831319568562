<!--
    Author: Eduard Grebenyukov
    Date: Date: 2020-10-17
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <div id="page-content">
            <Grid ref="grid" :key="$route.fullPath" :config="gridConfig" />
        </div>
    </TemplatePageStandard>
</template>

<script>
    import { mapActions } from 'vuex';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ClientsToMerge',

        components: { TemplatePageStandard, Grid },
        // props: ['config'],

        // =============== Data ===============
        data() {
            return {
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.clients'), to: '/clients' },
                    { text: this.$t('MainMenu.clients-to-merge'), to: '/clients-to-merge' },
                ],
                gridConfig: {
                    apiUrl: 'v_similar_client',
                    readonly: true,
                    columns: [
                        {
                            type:'icon', icon:'fas fa-compress-alt', title:this.$t('app.merge'), style:'color: #056bbf;',
                            $onClick: (entry) => { this.openMerge(entry.id1, entry.id2) },
                        },
                        { type:'string', key:'code1', header:this.$t('attribute.common.code') + '1' },
                        { type:'string', key:'code2', header:this.$t('attribute.common.code') + '2' },
                        // { type:'string', key:'fullname', header:this.$t('object.core_client') },
                        {
                            type:'string', key:'client_name1',
                            header:this.$t('object.core_client') + '1',
                            title:this.$t('MainMenu.clients-visits'),
                            style:'color: #056bbf;',
                            $cellStyle: (entry) => entry.client_name_eq ? 'background-color:#eeffee;' : 'background-color:#ffeeee;',
                            $textOnClick: (entry) => { this.showItem(entry.id1) },
                        },
                        {
                            type:'string', key:'client_name2',
                            header:this.$t('object.core_client') + '2',
                            title:this.$t('MainMenu.clients-visits'),
                            style:'color: #056bbf;',
                            $cellStyle: (entry) => entry.client_name_eq ? 'background-color:#eeffee;' : 'background-color:#ffeeee;',
                            $textOnClick: (entry) => { this.showItem(entry.id2) },
                        },
                        {
                            type:'date', key:'dob1', header:this.$t('attribute.core_person.dob') + '1',
                            $cellStyle: (entry) => entry.dob_eq ? 'background-color:#eeffee;' : '',
                        },
                        {
                            type:'date', key:'dob2', header:this.$t('attribute.core_person.dob') + '2',
                            $cellStyle: (entry) => entry.dob_eq ? 'background-color:#eeffee;' : '',
                        },
                        {
                            type:'string', key:'phones1', header:this.$t('Contacts.phones') + '1',
                            $cellStyle: (entry) => entry.phones_eq ? 'background-color:#eeffee;' : 'background-color:#ffeeee;',
                        },
                        {
                            type:'string', key:'phones2', header:this.$t('Contacts.phones') + '2',
                            $cellStyle: (entry) => entry.phones_eq ? 'background-color:#eeffee;' : 'background-color:#ffeeee;',
                        },
                        { type:'string', hidden:true, key:'phones_stripped1', header:this.$t('Contacts.phones') },
                        { type:'string', hidden:true, key:'phones_stripped2', header:this.$t('Contacts.phones') },
                    ],
                    order: [ 'client_name1' ],
                    displayToolbar: true,
                    saveSessionState: `${this.$options.name}`,
                }
            }
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['setLastClientId']),

            async openMerge(id1, id2) {
                this.$router.push({ name: 'clients-merge', params: { id1, id2 } });
            },
            async showItem(id) {
                await this.setLastClientId(id);
                // this.$router.push({ name: 'client-visits', params: { clientId: id } });

                let routeData = this.$router.resolve({ name: 'client-edit', params: { id } });
                window.open(
                    routeData.href, '_blank'
                );
            },
        },
    }
</script>

<style>
</style>
