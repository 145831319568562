<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-03
-->

<template>
    <ModalWindow
        width="90%"
        max-width="1200px"
        :buttons="buttons"
        @cancel="closeModal"
        @save="saveData"
        @delete="askDeleteItem"
    >
        <span v-if="!templateId" slot="header" :title="msgItemModified">{{ $t('Templates.create') }} {{ isModified? '*' : '' }}</span>
        <span v-if="templateId"  slot="header" :title="msgItemModified">{{ $t('Templates.edit') }} {{ isModified? '*' : '' }}</span>

        <div class="container">
            <div class="row">
                <div class="col-12-sm col-md">
                    <FieldString :label="$t('attribute.common.name')" :value.sync="editFormData.name" @modified="itemModified" required />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <FieldText :label="$t('app.text')" :value.sync="editFormData.template_text" @modified="itemModified" />
                </div>
            </div>
        </div>

        <WarningClose v-if="isDisplayWarningClose" @process="$event ? processClose() : cancelClose()" />
        <WarningDelete v-if="isDisplayWarningDelete" @process="$event ? processDeleteItem() : cancelDeleteItem()" />

    </ModalWindow>
</template>

<script>
    import { mapGetters } from 'vuex';
    //import { mapState, mapActions } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import WarningClose from '@/components/lib/regions/WarningClose';
    import WarningDelete from '@/components/lib/regions/WarningDelete';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldText from '@/components/lib/controls/FieldText';

    export default {
        name: 'ReferralTemplateEdit',

        components: {
            ModalWindow, WarningClose, WarningDelete,
            FieldString, FieldText
        },

        // =============== Props ===============
        props: {
            templateId: Number,
            visitTypeId: Number,
            userId: Number,
            // attributeName: String,
            // attributeValue: String,
            // formData: Object,
        },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                isModified: false,
                msgItemModified: '',
                editFormData: {
                    // id: null,
                    parent_id: null,
                    visit_type_id: null,
                    core_speciality_id: null,
                    core_user_id: null,
                    // attribute_name: null,
                    name: null,
                    sort_order: null,
                    // template_text: null,
                },
                oldFormData: {},
                isDisplayWarningDelete: false,
                isDisplayWarningClose: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            apiUrl() { return `${this.baseUrl}/visit_template` },
            // displayGrids() {return typeof this.editFormData.id !='undefined' },
            // formFilled() {
            //     return this.editFormData.visit_type_id && this.editFormData.attribute_name;
            // },
        },

        // =============== Validations ===============
        // validations: {
        //     editFormData: {
        //         username: { required },
        //         email: { required },
        //         organization_id: {required}
        //     }
        // },

        // =============== Methods ===============
        methods: {
            async getData() {
                try {
                    let response = await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`);
                    // console.log(`DEBUG: ${this.$options.name}.getData() data=`, response.data);
                    this.editFormData = response.data;

                    this.isModified = false;
                    this.msgItemModified = '';
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                if (this.isModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData);
                            // this.$emit('close-modal', true);
                        } else {
                            // New user created
                            let response = await this.authenticatedAxios.post(this.apiUrl, this.editFormData);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response.data=`, response.data);
                            this.itemId = response.data.id;
                            // this.editFormData.id = this.itemId;
                            // this.getData();
                        }
                        tools.displaySuccessSave(this.$parent);
                        this.$emit('close-modal', true);
                    } catch (err) {
                        tools.displayApiError(this.$parent, err);
                    }
                } else {
                    // no changes
                    this.$emit('close-modal', true);
                }
            },
            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified()`);
                if (JSON.stringify(this.oldFormData) === JSON.stringify(this.editFormData)) {
                    this.isModified = false;
                    this.msgItemModified = '';
                } else {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                }
            },

            askDeleteItem() {
                this.isDisplayWarningDelete = true;
            },
            async processDeleteItem() {
                try {
                    await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                    tools.displaySuccessDelete(this.$parent);
                    this.$emit('close-modal', true);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
            cancelDeleteItem() { this.isDisplayWarningDelete = false },

            closeModal() {
                if (this.isModified) {
                    this.isDisplayWarningClose = true;
                } else {
                    this.processClose();
                }
            },
            processClose() { this.$emit('close-modal', false) },
            cancelClose() { this.isDisplayWarningClose = false },

        },

        // =============== Life cycle ===============
        beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.attributeName=${this.attributeName}`);
            this.itemId = this.templateId;
            if (!this.itemId) {
                this.editFormData.visit_type_id = this.visitTypeId;
                this.editFormData.core_user_id = this.userId;
                // this.editFormData.attribute_name = this.attributeName;
                // this.editFormData.template_text = this.attributeValue;
            }
        },

        async mounted() {
            if (this.itemId) {
                await this.getData();
                this.buttons = ['save', 'delete', 'close'];
            } else {
                this.buttons = ['save', 'close'];
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.editFormData=`, this.editFormData);
        },
    }
</script>

<style lang="scss" src="@/assets/context-menu.scss"></style>

