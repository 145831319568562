<!--
    Author: Eduard Grebenyukov
    Date: Date: 2020-05-20
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <!-- <template #buttons>
            <b-form-group class="mb-0" style="padding-top:4px;" >
                <b-form-radio-group
                    id="radio-group-1"
                    v-model="filterPayerType"
                    :options="radioButtonsPayerType"
                    name="radio-options"
                />
            </b-form-group>
        </template> -->

        <div id="page-content">
            <Grid ref="grid" :key="$route.fullPath" :config="gridConfig" @edit-item="accountSelected($event)" >
                <template #buttons>
                    <FieldSwitch
                        id="isShowOrg"
                        :label="$t('component.Accounts.isShowOrg')"
                        labelPosition="after"
                        size="small"
                        :value.sync="isShowOrg"
                        :defaultValue="true"
                        :saveSessionState="`${$options.name}-isShowOrg`"
                        @modified="filterChanged()"
                    />
                    <FieldSwitch
                        id="isShowClient"
                        :label="$t('component.Accounts.isShowClient')"
                        labelPosition="after"
                        size="small"
                        :value.sync="isShowClient"
                        :defaultValue="true"
                        :saveSessionState="`${$options.name}-isShowClient`"
                        @modified="filterChanged()"
                    />
                    <FieldSwitch
                        id="isShowContract"
                        :label="$t('component.Accounts.isShowContract')"
                        labelPosition="after"
                        size="small"
                        :value.sync="isShowContract"
                        :defaultValue="true"
                        :saveSessionState="`${$options.name}-isShowContract`"
                        @modified="filterChanged()"
                    />
                    <FieldSwitch
                        id="isShowNoContract"
                        :label="$t('component.Accounts.isShowNoContract')"
                        labelPosition="after"
                        size="small"
                        :value.sync="isShowNoContract"
                        :defaultValue="true"
                        :saveSessionState="`${$options.name}-isShowNoContract`"
                        @modified="filterChanged()"
                    />
                    <!-- <div style="padding-top:4px;">
                        <b-form-checkbox v-model="isShowOrg" inline @input="filterChanged()" >{{ $t('component.Accounts.isShowOrg') }}</b-form-checkbox>
                        <b-form-checkbox v-model="isShowClient" inline @input="filterChanged()" >{{ $t('component.Accounts.isShowClient') }}</b-form-checkbox>
                        <b-form-checkbox v-model="isShowContract" inline @input="filterChanged()" >{{ $t('component.Accounts.isShowContract') }}</b-form-checkbox>
                        <b-form-checkbox v-model="isShowNoContract" inline @input="filterChanged()" >{{ $t('component.Accounts.isShowNoContract') }}</b-form-checkbox>
                    </div> -->
                    <!-- <div slot="buttons" class="custom-control custom-switch" style="padding-top:6px;">
                        <input type="checkbox" class="custom-control-input" id="is_only_debt" v-model="isShowDebtOnly" >
                        <label class="custom-control-label" for="is_only_debt">{{ $t('component.Payers.onlyDebt') }}</label>
                    </div> -->
                </template>
            </Grid>
        </div>

    </TemplatePageStandard>
</template>

<script>
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'Accounts',

        components: { TemplatePageStandard, Grid, FieldSwitch },

        // =============== Data ===============
        data() {
            return {
                isShowDebtOnly: true,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.finances') },
                    { text: this.$t('MainMenu.finances-accounts') },
                ],
                gridConfig: {
                    apiUrl: null, // fin-get-accounts
                    filter: null,
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right' },
                        // { type:'select', key:'payer_org_id', header:this.$t('attribute.fin_account.payer_org_id'), source:'core_org' },
                        // { type:'select', key:'payer_client_id', header:this.$t('attribute.fin_account.payer_client_id'), keyLabel:"fullname", source:'v_client' },
                        { type:'string', key:'payer_name', header:this.$t('component.Accounts.payer_name') },
                        { type:'select', key:'fin_contract_id', header:this.$t('object.fin_contract'), source:'fin_contract' },
                        // { type:'select', key:'fin_currency_id', header:this.$t('object.fin_currency'), source:'fin_currency', keyLabel:'name_short', align:'center;' },
                        // { type:'select', key:'core_org_id', header:this.$t('object.core_org'), source:'core_org' },
                        { type:'number', key:'sum_account', header:this.$t('attribute.fin_account.sum_account') },
                        { type:'number', key:'sum_payment_final', header:this.$t('component.Accounts.sum_payment') },
                        { type:'number', key:'sum_paid', header:this.$t('component.Accounts.sum_paid') },
                        { type:'number', key:'sum_unpaid', header:this.$t('component.Accounts.sum_to_pay') },
                    ],
                    order: [ 'payer_name' ],
                    buttons: [ 'edit' ],
                    saveSessionState: `${this.$options.name}`,
                    $onEdit: (ctx, item) => { ctx.$router.push({ name: 'finances/account', params: { accountId: item.id } }) },
                },
                isShowOrg: true,
                isShowClient: true,
                isShowContract: true,
                isShowNoContract: true,
                // filterPayerType: 'all',
                // radioButtonsPayerType: [
                //     { value:'org', text:'Организации' },
                //     { value:'client', text:'Физ.лица' },
                //     { value:'all', text:'Все' },
                // ],
                // filterPayerContract: 'all',
                // radioButtonsContract: [
                //     { value:'contract', text:'Договоры' },
                //     { value:'noContract', text:'Без договоров' },
                //     { value:'all', text:'Все' },
                // ],
            }
        },

        // =============== Methods ===============
        methods: {
            filterChanged() {
                if (this.$refs.grid) {
                    let filter = { where:[
                        { isShowOrg:this.isShowOrg },
                        { isShowClient:this.isShowClient},
                        { isShowContract:this.isShowContract },
                        { isShowNoContract:this.isShowNoContract },
                    ]};
                    // let filterString = tools.getFilterString(filter);
                    this.gridConfig.apiUrl = `fin-get-accounts`;
                    this.gridConfig.filter = filter;
                    this.$refs.grid.refresh();
                    // console.log(`DEBUG: ${this.$options.name}.filterChanged() filter=`, filter);
                    // this.gridConfig.apiUrl = `fin-get-accounts?org=${this.isShowOrg}&client=${this.isShowClient}&contract=${this.isShowContract}&no_contract=${this.isShowNoContract}`;
                }
            },

            accountSelected(account) {
                this.$router.push({ name: 'cashdesk/account', params: { accountId: account.id } });
            },

        },
        // =============== Life cycle ===============
        async mounted() {
            // this.getAccountsUrl();
            this.filterChanged();
        },
    }
</script>

<style scoped>
</style>
