/*
    Author: Eduard Grebenyukov
    Date: 2020-06-12
*/

import check from './check';

function listToTree(list, keyId, keyParentId) {
    // id, parent_id => children
    let map = {}, node, roots = [], i;
    for (i = 0; i < list.length; i += 1) {
        map[list[i][keyId || 'id']] = i; // initialize the map
        list[i].children = []; // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node[keyParentId || 'parent_id']) {
            // if you have dangling branches check that map[node.parent_id] exists
            // console.log(`DEBUG: tools.listToTree() list[map[node.parent_id]]=`, list[map[node.parent_id]]);
            if (list[map[node[keyParentId || 'parent_id']]])
                list[map[node[keyParentId || 'parent_id']]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

// function listToTree(list) {
//     // id, parent_id => children
//     let map = {}, node, roots = [], i;
//     for (i = 0; i < list.length; i += 1) {
//         map[list[i].id] = i; // initialize the map
//         list[i].children = []; // initialize the children
//     }
//     for (i = 0; i < list.length; i += 1) {
//         node = list[i];
//         if (node.parent_id) {
//             // if you have dangling branches check that map[node.parent_id] exists
//             // console.log(`DEBUG: tools.listToTree() list[map[node.parent_id]]=`, list[map[node.parent_id]]);
//             if (list[map[node.parent_id]])
//                 list[map[node.parent_id]].children.push(node);
//         } else {
//             roots.push(node);
//         }
//     }
//     return roots;
// }

function filterTree(treeData, filter, keyLabel) {
    if (check.isStringEmpty(filter)) return treeData;
    let tree = JSON.parse(JSON.stringify(treeData)); // very expensive!!!
    // let tree = treeData.slice();
    return tree.filter( item => {
        if (item.children) item.children = this.filterTree(Array.from(item.children), filter, keyLabel);
        return item[keyLabel || 'name'].toLowerCase().indexOf(filter.toLowerCase()) > -1 || (item.children && item.children.length > 0);
    });
}

// // this version affects source!!!
// function filterTree(tree, filter, keyLabel) {
//     if (check.isStringEmpty(filter)) return tree;
//     return tree.filter( item => {
//         if (item.children) item.children = this.filterTree(Array.from(item.children), filter, keyLabel);
//         return item[keyLabel || 'name'].toLowerCase().indexOf(filter) > -1 || (item.children && item.children.length > 0);
//     });
// }

export default {
    listToTree, filterTree
}
