<!--
    Author: Eduard Grebenyukov
    Date: 2021-03-15
-->

<template>
    <div >
        <label :for="id" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark" >*</sup>:
        </label>

        <input
            :id="id"
            type="number"
            :value="value"
            :required="required"
            :disabled="disabled"
            :maxlength="maxlength"
            :min="minValue"
            :max="maxValue"
            :class="{
                'is-invalid': !!errorMessage,
                'field__required-empty': required && (isNaN(itemData) || itemData === null || itemData === '')
            }"
            :style="`text-align: right; border: 1px solid #ced4da; border-radius: .25rem; line-height: 1.5; height: calc(1.5em + .75rem + 2px); ${inputStyle}`"
            @input="onInput($event)"
            @change="onChange($event)"
            @focus="itemFocused($event)"
        />

        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'FieldNumberPlain',

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            // labelPosition: { type: String, default: () => { return 'above' } },
            value: Number,
            defaultValue: Number,
            minValue: Number,
            maxValue: Number,
            // placeholder: String,
            disabled: Boolean,
            required: Boolean,
            readonly: Boolean,
            maxlength: Number,
            saveState: String,
            saveSessionState: String,
            inputStyle: String,
            textClass: String,
            textStyle: String,
            // autocomplete: String,
            errorMessage: String,
            decimalPlaces: Number,
        },

        // =============== Data ===============
        data() {
            return {
                itemData: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            presentation() {
                // if (this.decimalPlaces) return this.value.toFixed(this.decimalPlaces);
                // if (this.decimalPlaces) return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(this.value);
                if (this.decimalPlaces) return new Intl.NumberFormat('ru-RU', { minimumFractionDigits:this.decimalPlaces }).format(this.value); //!!!!
                return this.value;
            },
        },

        // =============== Watch ===============
        watch: {
            value() {
                this.itemData = this.convert(this.value);
                // console.log(`DEBUG: ${this.$options.name}(${this.label}).watch.value this.value=`, this.value);
            },
        },

        // =============== Methods ===============
        methods: {
            convert(value) {
                return value === '' || value === null || isNaN(parseFloat(value)) ? null : parseFloat(value);
            },

            itemModified(value) {
                // console.log(`DEBUG: ${this.$options.name}.itemModified(${value})=`, value);
                if (this.saveState) {
                    // Save current value
                    this.authenticatedAxios.post(`${this.baseUrl}/${userSettingsUrl}`, { code:this.saveState, value:value });
                }
                if (this.saveSessionState) {
                    sessionStorage.setItem(this.saveSessionState, JSON.stringify(value));
                }
                this.$emit('update:value', this.convert(value));
                this.$emit('modified');
            },

            itemFocused(e) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() e=`, e);
                this.$emit('focus');
            },

            onInput(evt) {
                this.itemModified(evt.target.value);
            },

            onChange(evt) {
                this.$emit('update:value', this.convert(evt.target.value));
                this.$emit('change');
                this.itemModified(evt.target.value);
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemData = this.convert(this.value);
            if (!this.itemData && this.saveState) {
                // Restore stored value
                let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() url response=`, response);
                let item = response.data.rows[0];
                if (item) this.itemData = item.value;
                this.itemModified(this.itemData);
            }
            if (!this.itemData && this.saveSessionState) {
                let sessionData = JSON.parse(sessionStorage.getItem(this.saveSessionState));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() sessionData=`, sessionData);
                if (sessionData) this.itemData = sessionData;
                this.itemModified(this.itemData);
            }
            if (!this.itemData && typeof this.defaultValue != 'undefined' && this.defaultValue !== null) {
                this.itemData = this.defaultValue;
                this.itemModified(this.itemData);
            }
        },

        update() {
            this.itemData = parseFloat(this.value);
        },
    }
</script>

<style>
</style>
