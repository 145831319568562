<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-14
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonPrint v-if="buttons.includes('print')" @click="printItem()" />
            <!-- <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" /> -->
        </template>

        <VisitDescription />

        <VisitTabs currentTab="visit-ophtalmology-exam" />

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-sm-12 col-xl-8">
                        <div class="form-row">
                            <div class="col">
                                <FieldString
                                    :label="$t('component.MedOphtalmologyExam.heterotropy_in')"
                                    :value.sync="editFormData.heterotropy_in"
                                    @focus="itemFocused('eyelid_od', 'med_ophtalmology_exam.heterotropy_in')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldString
                                    :label="$t('component.MedOphtalmologyExam.heterotropy_out')"
                                    :value.sync="editFormData.heterotropy_out"
                                    @focus="itemFocused('eyelid_od', 'med_ophtalmology_exam.heterotropy_out')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <!-- <div class="form-row">
                            <div class="col">
                                {{ $t('component.MedOphtalmologyExam.eyelid') }}
                            </div>
                        </div> -->
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyelid_od')"
                                    :value.sync="editFormData.eyelid_od"
                                    @focus="itemFocused('eyelid_od', 'med_ophtalmology_exam.eyelid')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.eyelid_od = editFormData.eyelid_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.eyelid_os = editFormData.eyelid_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyelid_os')"
                                    :value.sync="editFormData.eyelid_os"
                                    @focus="itemFocused('eyelid_os', 'med_ophtalmology_exam.eyelid')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.lacrimal_od')"
                                    :value.sync="editFormData.lacrimal_od"
                                    @focus="itemFocused('lacrimal_od', 'med_ophtalmology_exam.lacrimal')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.lacrimal_od = editFormData.lacrimal_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.lacrimal_os = editFormData.lacrimal_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.lacrimal_os')"
                                    :value.sync="editFormData.lacrimal_os"
                                    @focus="itemFocused('lacrimal_os', 'med_ophtalmology_exam.lacrimal')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.conjunctiva_od')"
                                    :value.sync="editFormData.conjunctiva_od"
                                    @focus="itemFocused('conjunctiva_od', 'med_ophtalmology_exam.conjunctiva')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.conjunctiva_od = editFormData.conjunctiva_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.conjunctiva_os = editFormData.conjunctiva_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.conjunctiva_os')"
                                    :value.sync="editFormData.conjunctiva_os"
                                    @focus="itemFocused('conjunctiva_os', 'med_ophtalmology_exam.conjunctiva')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.pericorneal_injection_od')"
                                    :value.sync="editFormData.pericorneal_injection_od"
                                    @focus="itemFocused('pericorneal_injection_od', 'med_ophtalmology_exam.pericorneal_injection')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.pericorneal_injection_od = editFormData.pericorneal_injection_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.pericorneal_injection_os = editFormData.pericorneal_injection_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.pericorneal_injection_os')"
                                    :value.sync="editFormData.pericorneal_injection_os"
                                    @focus="itemFocused('pericorneal_injection_os', 'med_ophtalmology_exam.pericorneal_injection')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.palpatory_tenderness_od')"
                                    :value.sync="editFormData.palpatory_tenderness_od"
                                    @focus="itemFocused('palpatory_tenderness_od', 'med_ophtalmology_exam.palpatory_tenderness')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.palpatory_tenderness_od = editFormData.palpatory_tenderness_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.palpatory_tenderness_os = editFormData.palpatory_tenderness_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.palpatory_tenderness_os')"
                                    :value.sync="editFormData.palpatory_tenderness_os"
                                    @focus="itemFocused('palpatory_tenderness_os', 'med_ophtalmology_exam.palpatory_tenderness')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.cornea_od')"
                                    :value.sync="editFormData.cornea_od"
                                    @focus="itemFocused('cornea_od', 'med_ophtalmology_exam.cornea')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.cornea_od = editFormData.cornea_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.cornea_os = editFormData.cornea_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.cornea_os')"
                                    :value.sync="editFormData.cornea_os"
                                    @focus="itemFocused('cornea_os', 'med_ophtalmology_exam.cornea')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.anterior_chamber_od')"
                                    :value.sync="editFormData.anterior_chamber_od"
                                    @focus="itemFocused('anterior_chamber_od', 'med_ophtalmology_exam.anterior_chamber')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.anterior_chamber_od = editFormData.anterior_chamber_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.anterior_chamber_os = editFormData.anterior_chamber_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.anterior_chamber_os')"
                                    :value.sync="editFormData.anterior_chamber_os"
                                    @focus="itemFocused('anterior_chamber_os', 'med_ophtalmology_exam.anterior_chamber')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.iris_od')"
                                    :value.sync="editFormData.iris_od"
                                    @focus="itemFocused('iris_od', 'med_ophtalmology_exam.iris')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.iris_od = editFormData.iris_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.iris_os = editFormData.iris_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.iris_os')"
                                    :value.sync="editFormData.iris_os"
                                    @focus="itemFocused('iris_os', 'med_ophtalmology_exam.iris')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.pupil_od')"
                                    :value.sync="editFormData.pupil_od"
                                    @focus="itemFocused('pupil_od', 'med_ophtalmology_exam.pupil')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.pupil_od = editFormData.pupil_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.pupil_os = editFormData.pupil_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.pupil_os')"
                                    :value.sync="editFormData.pupil_os"
                                    @focus="itemFocused('pupil_os', 'med_ophtalmology_exam.pupil')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.lens_od')"
                                    :value.sync="editFormData.lens_od"
                                    @focus="itemFocused('lens_od', 'med_ophtalmology_exam.lens')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.lens_od = editFormData.lens_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.lens_os = editFormData.lens_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.lens_os')"
                                    :value.sync="editFormData.lens_os"
                                    @focus="itemFocused('lens_os', 'med_ophtalmology_exam.lens')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.vitreous_body_od')"
                                    :value.sync="editFormData.vitreous_body_od"
                                    @focus="itemFocused('vitreous_body_od', 'med_ophtalmology_exam.vitreous_body')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.vitreous_body_od = editFormData.vitreous_body_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.vitreous_body_os = editFormData.vitreous_body_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.vitreous_body_os')"
                                    :value.sync="editFormData.vitreous_body_os"
                                    @focus="itemFocused('vitreous_body_os', 'med_ophtalmology_exam.vitreous_body')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.reflex_od')"
                                    :value.sync="editFormData.reflex_od"
                                    @focus="itemFocused('reflex_od', 'med_ophtalmology_exam.reflex')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.reflex_od = editFormData.reflex_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.reflex_os = editFormData.reflex_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.reflex_os')"
                                    :value.sync="editFormData.reflex_os"
                                    @focus="itemFocused('reflex_os', 'med_ophtalmology_exam.reflex')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col d-flex justify-content-center">
                                <h6>{{ $t('component.MedOphtalmologyExam.eyeground') }}</h6>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyeground_disk_od')"
                                    :value.sync="editFormData.eyeground_disk_od"
                                    @focus="itemFocused('eyeground_disk_od', 'med_ophtalmology_exam.eyeground_disk')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.eyeground_disk_od = editFormData.eyeground_disk_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.eyeground_disk_os = editFormData.eyeground_disk_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyeground_disk_os')"
                                    :value.sync="editFormData.eyeground_disk_os"
                                    @focus="itemFocused('eyeground_disk_os', 'med_ophtalmology_exam.eyeground_disk')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyeground_vessels_od')"
                                    :value.sync="editFormData.eyeground_vessels_od"
                                    @focus="itemFocused('eyeground_vessels_od', 'med_ophtalmology_exam.eyeground_vessels')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.eyeground_vessels_od = editFormData.eyeground_vessels_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.eyeground_vessels_os = editFormData.eyeground_vessels_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyeground_vessels_os')"
                                    :value.sync="editFormData.eyeground_vessels_os"
                                    @focus="itemFocused('eyeground_vessels_os', 'med_ophtalmology_exam.eyeground_vessels')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyeground_mac_od')"
                                    :value.sync="editFormData.eyeground_mac_od"
                                    @focus="itemFocused('eyeground_mac_od', 'med_ophtalmology_exam.eyeground_mac')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.eyeground_mac_od = editFormData.eyeground_mac_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.eyeground_mac_os = editFormData.eyeground_mac_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyeground_mac_os')"
                                    :value.sync="editFormData.eyeground_mac_os"
                                    @focus="itemFocused('eyeground_mac_os', 'med_ophtalmology_exam.eyeground_mac')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyeground_paramac_od')"
                                    :value.sync="editFormData.eyeground_paramac_od"
                                    @focus="itemFocused('eyeground_paramac_od', 'med_ophtalmology_exam.eyeground_paramac')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.eyeground_paramac_od = editFormData.eyeground_paramac_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.eyeground_paramac_os = editFormData.eyeground_paramac_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyeground_paramac_os')"
                                    :value.sync="editFormData.eyeground_paramac_os"
                                    @focus="itemFocused('eyeground_paramac_os', 'med_ophtalmology_exam.eyeground_paramac')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyeground_perif_od')"
                                    :value.sync="editFormData.eyeground_perif_od"
                                    @focus="itemFocused('eyeground_perif_od', 'med_ophtalmology_exam.eyeground_perif')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.eyeground_perif_od = editFormData.eyeground_perif_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.eyeground_perif_os = editFormData.eyeground_perif_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyeground_perif_os')"
                                    :value.sync="editFormData.eyeground_perif_os"
                                    @focus="itemFocused('eyeground_perif_os', 'med_ophtalmology_exam.eyeground_perif')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyeground_trait_od')"
                                    :value.sync="editFormData.eyeground_trait_od"
                                    @focus="itemFocused('eyeground_trait_od', 'med_ophtalmology_exam.eyeground_trait')"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="shuttle__buttons">
                                <h6>&nbsp;</h6>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-left fa-fw"
                                            :title="$t('app.add')"
                                            @click="editFormData.eyeground_trait_od = editFormData.eyeground_trait_os"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ButtonCustom
                                            :disabled="!isFormEditable"
                                            buttonClass="btn-outline-secondary"
                                            iconClass="fas fa-angle-right fa-fw"
                                            :title="$t('app.delete')"
                                            @click="editFormData.eyeground_trait_os = editFormData.eyeground_trait_od"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <FieldText
                                    :label="$t('attribute.med_ophtalmology_exam.eyeground_trait_os')"
                                    :value.sync="editFormData.eyeground_trait_os"
                                    @focus="itemFocused('eyeground_trait_os', 'med_ophtalmology_exam.eyeground_trait')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-4">
                        <!-- Right sidebar -->
                        <VisitFormTemplate
                            :title="$t(currentAttribute ? `VisitDetails.${currentAttribute}` : 'Templates.templates')"
                            :visitTypeId="visitRec.visit_type_id"
                            :specialityId="visitRec.core_speciality_id"
                            :formItemName="currentFormItem"
                            :attributeName="currentAttribute"
                            :formData.sync="editFormData"
                            @modified="itemModified()"
                        />
                    </div>
                </div>
            </div>
        </form>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonPrint from '@/components/lib/controls/ButtonPrint';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import FieldString from '@/components/lib/controls/FieldString';
    import FieldText from '@/components/lib/controls/FieldText';
    import VisitFormTemplate from '@/components/pages/visits/VisitFormTemplate';

    export default {
        name: 'MedOphtalmologyExam',

        components: {
            TemplatePageStandard, VisitDescription, VisitTabs,
            ButtonClose, ButtonSave, ButtonPrint, ButtonCustom,
            FieldString, FieldText,
            VisitFormTemplate,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                visitRec: {},
                currentFormItem: null,
                currentAttribute: null,
                isFormEditable: true,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.ophtalmology-exam') }),
                editFormData: {
                    // id: null,
                    visit_rec_id: null,
                    heterotropy_in: null,
                    heterotropy_out: null,
                    eyelid_od: null,
                    eyelid_os: null,
                    lacrimal_od: null,
                    lacrimal_os: null,
                    conjunctiva_od: null,
                    conjunctiva_os: null,
                    pericorneal_injection_od: null,
                    pericorneal_injection_os: null,
                    palpatory_tenderness_od: null,
                    palpatory_tenderness_os: null,
                    cornea_od: null,
                    cornea_os: null,
                    anterior_chamber_od: null,
                    anterior_chamber_os: null,
                    iris_od: null,
                    iris_os: null,
                    pupil_od: null,
                    pupil_os: null,
                    lens_od: null,
                    lens_os: null,
                    vitreous_body_od: null,
                    vitreous_body_os: null,
                    reflex_od: null,
                    reflex_os: null,
                    eyeground_disk_od: null,
                    eyeground_disk_os: null,
                    eyeground_vessels_od: null,
                    eyeground_vessels_os: null,
                    eyeground_mac_od: null,
                    eyeground_mac_os: null,
                    eyeground_paramac_od: null,
                    eyeground_paramac_os: null,
                    eyeground_perif_od: null,
                    eyeground_perif_os: null,
                    eyeground_trait_od: null,
                    eyeground_trait_os: null,
                    cornea_draw: null,
                    iris_draw: null,
                    lens_draw: null,
                    eyeground_draw: null,
                },
                oldFormData: {},
                // isDisplayWarningDelete: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close', 'print'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            setBreadcrumb() {
            },

            gotData() {
                // this.editFormData = data;
                this.isModified = false;
                this.msgItemModified = '';
                this.itemId = this.editFormData.id;

                this.setBreadcrumb();
                this.checkButtons();
            },

            async getData() {
                try {
                    this.visitRec = (await this.authenticatedAxios.get(`${this.baseUrl}/visit/${this.visitId}`)).data;
                    // this.workplace = (await this.authenticatedAxios.get(`${this.baseUrl}/core_workplace/${this.visitRec.core_workplace_id}`)).data.rows[0];
                    let filterString = tools.getFilterString({ where:[{ visit_rec_id:this.editFormData.visit_rec_id }]});
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/med_ophtalmology_exam${filterString}`)).data.rows[0];
                    if (data)
                        this.editFormData = data;
                    this.gotData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isModified=${this.isModified}, this.editFormData=`, this.editFormData);
                if (this.isModified) {
                    //this.$v.$touch();
                    //if (!this.$v.$invalid) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            let data = (await this.authenticatedAxios.put(`${this.baseUrl}/med_ophtalmology_exam/${this.editFormData.id}`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                this.isModified = false;
                                tools.displaySuccessSave(this);
                            }
                        } else {
                            // New record
                            let data = (await this.authenticatedAxios.post(`${this.baseUrl}/med_ophtalmology_exam`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                this.gotData();
                                // this.getData();
                                tools.displaySuccessSave(this);
                            }
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },

            itemFocused(item, templateName) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() item=${item}`);
                this.currentFormItem = item;
                this.currentAttribute = templateName ? templateName : item;
                // this.currentFormItemValue = this.editFormData[this.currentFormItem.split('.')[1]];
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() this.currentFormItemValue=${this.currentFormItemValue}`);
            },

            itemModified() {
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
                this.setBreadcrumb();
            },

            async printItem() {
                this.saveData();
                tools.printForm(
                    this,
                    {
                        // taskId: `print.${this.$options.name}`,
                        template: 'ophtalmology-exam',
                        consultation: JSON.parse(JSON.stringify(this.editFormData)),
                        visit: { visit_rec_id: this.visitId },
                    }
                );
                // console.log(`DEBUG: ${this.$options.name}.printItem() printData=`, printData);
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            this.editFormData.visit_rec_id = this.visitId;
        },

        async mounted() {
            if (this.visitId) {
                await this.getData();
            } else {
                this.setBreadcrumb();
                this.checkButtons();
            }
            this.oldFormData = tools.deepCopy(this.editFormData);
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.clientId=${this.clientId}, this.visitId=${this.visitId}`);
        },
    }
</script>

<style>
    .shuttle__buttons h6 {
        height: 18px;
    }

    .shuttle__buttons .row {
        margin-bottom:4px;
    }

    .shuttle__buttons button {
        width:64px;
    }
</style>
