<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-14
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonPrint v-if="buttons.includes('print')" @click="printItem()" />
            <!-- <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" /> -->
        </template>

        <VisitDescription />

        <VisitTabs currentTab="visit-consultation" />

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-sm-12 col-xl-8">
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.complaints')"
                                    :value.sync="editFormData.complaints"
                                    @focus="itemFocused('complaints', 'med_consultation.complaints')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.illness_history')"
                                    :value.sync="editFormData.illness_history"
                                    @focus="itemFocused('illness_history', 'med_consultation.illness_history')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.physical_exam')"
                                    :value.sync="editFormData.physical_exam"
                                    @focus="itemFocused('physical_exam', 'med_consultation.physical_exam')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.instrumental_exam')"
                                    :value.sync="editFormData.instrumental_exam"
                                    @focus="itemFocused('instrumental_exam', 'med_consultation.instrumental_exam')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.diff_diag')"
                                    :value.sync="editFormData.diff_diag"
                                    @focus="itemFocused('diff_diag', 'med_consultation.diff_diag')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.exam_plan')"
                                    :value.sync="editFormData.exam_plan"
                                    @focus="itemFocused('exam_plan', 'med_consultation.exam_plan')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.diagnosis')"
                                    :value.sync="editFormData.diagnosis"
                                    @focus="itemFocused('diagnosis', 'med_consultation.diagnosis')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.treatment')"
                                    :value.sync="editFormData.treatment"
                                    @focus="itemFocused('treatment', 'med_consultation.treatment')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.regime')"
                                    :value.sync="editFormData.regime"
                                    @focus="itemFocused('regime', 'med_consultation.regime')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <PlanNextVisit :clientId="clientId" :visitId="visitId" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-4">
                        <!-- Right sidebar -->
                        <VisitFormTemplate
                            :title="$t(currentAttribute ? `VisitDetails.${currentAttribute}` : 'Templates.templates')"
                            :visitTypeId="visitRec.visit_type_id"
                            :specialityId="visitRec.core_speciality_id"
                            :formItemName="currentFormItem"
                            :attributeName="currentAttribute"
                            :formData.sync="editFormData"
                            @modified="itemModified()"
                        />
                    </div>
                </div>
            </div>
        </form>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonPrint from '@/components/lib/controls/ButtonPrint';

    import FieldText from '@/components/lib/controls/FieldText';
    import VisitFormTemplate from '@/components/pages/visits/VisitFormTemplate';
    import PlanNextVisit from '@/components/pages/visits/PlanNextVisit';

    export default {
        name: 'Consultation',

        components: {
            TemplatePageStandard, VisitDescription, VisitTabs,
            ButtonClose, ButtonSave, ButtonPrint,
            FieldText, VisitFormTemplate, PlanNextVisit,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                visitRec: {},
                currentFormItem: null,
                currentAttribute: null,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.consultation') }),
                editFormData: {
                    // id: null,
                    visit_rec_id: null,
                    complaints: null,
                    illness_history: null,
                    physical_exam: null,
                    instrumental_exam: null,
                    diff_diag: null,
                    exam_plan: null,
                    diagnosis: null,
                    treatment: null,
                    regime: null,
                },
                oldFormData: {},
                // isDisplayWarningDelete: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close', 'print'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            setBreadcrumb() {
                // console.log(`DEBUG: ${this.$options.name}.setBreadcrumb core_workplace_id=`, this.$refs.core_workplace_id);
                // console.log(`DEBUG: core_workplace_id.itemPresentation=`, this.$refs.core_workplace_id.itemPresentation);
                // console.log(`DEBUG: core_workplace_id.getPresentation()=`, this.$refs.core_workplace_id.getPresentation());
            },

            gotData() {
                // console.log(`DEBUG: ${this.$options.name}.gotData this.$refs.core_workplace_id=`, this.$refs.core_workplace_id);
                // console.log(`DEBUG: ${this.$options.name}.gotData this.$refs.core_workplace_id.itemPresentation=`, this.$refs.core_workplace_id.itemPresentation);
                // this.editFormData = data;
                this.isModified = false;
                this.msgItemModified = '';
                this.itemId = this.editFormData.id;

                this.setBreadcrumb();
                this.checkButtons();
            },

            async getData() {
                try {
                    this.visitRec = (await this.authenticatedAxios.get(`${this.baseUrl}/visit/${this.visitId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getData this.visitRec=`, this.visitRec);
                    // this.workplace = (await this.authenticatedAxios.get(`${this.baseUrl}/core_workplace/${this.visitRec.core_workplace_id}`)).data.rows[0];
                    // console.log(`DEBUG: ${this.$options.name}.getData this.workplace=`, this.workplace);
                    let filterString = tools.getFilterString({ where:[{ visit_rec_id:this.editFormData.visit_rec_id }]});
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/med_consultation${filterString}`)).data.rows[0];
                    if (data)
                        this.editFormData = data;
                    // console.log(`DEBUG: ${this.$options.name}.getData data=`, this.editFormData);
                    this.gotData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isModified=${this.isModified}, this.editFormData=`, this.editFormData);
                if (this.isModified) {
                    //this.$v.$touch();
                    //if (!this.$v.$invalid) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            // console.log(`DEBUG: ${this.$options.name}.saveData() Update this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.put(`${this.baseUrl}/med_consultation/${this.editFormData.id}`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                this.isModified = false;
                                tools.displaySuccessSave(this);
                            }
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveData() New this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.post(`${this.baseUrl}/med_consultation`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                                this.gotData();
                                // this.getData();
                                tools.displaySuccessSave(this);
                            }
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },

            itemFocused(item, templateName) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() item=${item}`);
                this.currentFormItem = item;
                this.currentAttribute = templateName ? templateName : item;
                // this.currentFormItemValue = this.editFormData[this.currentFormItem.split('.')[1]];
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() this.currentFormItemValue=${this.currentFormItemValue}`);
            },
            
            itemModified() {
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.isModified=`, this.isModified);
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.oldFormData=`, JSON.stringify(this.oldFormData));
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.editFormData=`, JSON.stringify(this.editFormData));
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
                this.setBreadcrumb();
            },

            // askDeleteItem() {
            //     this.isDisplayWarningDelete = true;
            // },
            // async processDeleteItem(doDelete) {
            //     if (doDelete) {
            //         try {
            //         await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
            //         this.isModified = false;
            //         tools.displaySuccessDelete(this.$parent);
            //         this.$router.go(-1);
            //     } else {
            //         this.isDisplayWarningDelete = false;
            //     }
            // },

            async printItem() {
                this.saveData();
                tools.printForm(
                    this,
                    {
                        // taskId: `print.${this.$options.name}`,
                        template: 'consultation',
                        consultation: JSON.parse(JSON.stringify(this.editFormData)),
                        visit: { visit_rec_id: this.visitId },
                    }
                );
                // console.log(`DEBUG: ${this.$options.name}.printItem() printData=`, printData);
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            this.editFormData.visit_rec_id = this.visitId;
        },

        async mounted() {
            if (this.visitId) {
                await this.getData();
            } else {
                this.setBreadcrumb();
                this.checkButtons();
            }
            this.oldFormData = tools.deepCopy(this.editFormData);
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.clientId=${this.clientId}, this.visitId=${this.visitId}`);
        },
    }
</script>

<style scoped>

</style>
