/*
    Author: Eduard Grebenyukov
    Date: 2020-01-07
*/

import { pageConfigCore } from "./pageConfigCore";
import { pageConfigVisit } from "./pageConfigVisit";
import { pageConfigFinance } from "./pageConfigFinance";
import { pageConfigStore } from "./pageConfigStore";
import { pageConfigMed } from "./pageConfigMed";
import { pageConfigCRM } from "./pageConfigCRM";
import { pageConfigReports } from "./pageConfigReports";
import { pageConfigNavigation } from "./pageConfigNavigation";
import { pageConfigQuestionaire } from "./pageConfigQuestionaire";

const pageConfig = {
    ...pageConfigCore,
    ...pageConfigVisit,
    ...pageConfigFinance,
    ...pageConfigStore,
    ...pageConfigMed,
    ...pageConfigCRM,
    ...pageConfigNavigation,
    ...pageConfigQuestionaire,
    ...pageConfigReports,
}

export { pageConfig };
