<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-11
-->

<template>
    <div class="visit-description__wrapper">
        <template v-if="isDisplay">
            {{ this.lastVisit.time_of | timeString }} &#9679;
            {{ lastVisit.user_shortname }} &#9679;
            {{ lastVisit.visit_type_name }}
            <!-- {{ lastVisit.speciality_name }} -->
            <!-- {{ lastVisit.dept_name }} -->
            <!-- {{ lastVisit.status_name }} / -->
        </template>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';
    import tools from '@/components/lib/tools';

    export default {
        name: 'VisitDescription',

        // =============== Filters ===============
        filters: {
            timeString(t) {
                return t ? moment(t).format('L LT') : '';
            },
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                lastVisit: 'lastVisit',
            }),
            isDisplay() { return !tools.isEqual(this.lastVisit, {}) },
        },
        // timeString() { return moment(this.lastVisit.time_of).format('L LT'); },
    }
</script>

<style>
    .visit-description__wrapper {
        height: 2.25rem;
        /* background-color: #8f8f8f; */
        background-color: #dadedf;
        /* color: white; */
        padding: 0.5rem 1rem;
    }
</style>