<!--
    Author: Eduard Grebenyukov
    Date: 2020-06-10
-->

<!--
    labelPosition: before | above
    format: null | YYYY-MM-DDTHH:mm (default) | YYYY-MM-DDTHH:mm:ss | YYYY-MM-DDTHH:mm:ss.SSS
    options: {start: '00:00', step:'00:30' , end: '23:30', format: 'HH:mm' }
-->

<template>
    <div :class="{ 'field-required': required, 'form-group': labelPosition == 'above', 'form-label-group': labelPosition == 'float' }">
        <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label :for="id" v-if="label && (labelPosition === 'before')" :title="required ? $t('lib.required') : ''" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <!-- <label v-if="label && labelPosition != 'before'" :title="required ? $t('lib.required') : ''" :class="{ 'col-form-label':labelPosition === 'before' }" >
            {{label}}<sup v-if="required" class="required-mark">*</sup>
        </label> -->

        <div class="input-group">

            <!-- <label v-if="label && labelPosition === 'before'" :title="required ? $t('lib.required') : ''" class="field-date__label__before" >
                {{label}}<sup v-if="required" class="required-mark">*</sup>
            </label> -->

            <DatePicker
                v-if="!displayonly"
                :id="id"
                type="time"
                :value-type="'HH:mm:ss'"
                :format="format || 'HH:mm'"
                :clearable="false"
                :confirm="false"
                v-model="itemData"
                @input="itemModified($event)"
                @change="itemModified($event)"
                :input-class="{
                    'form-control': !readonly,
                    'form-control-plaintext': readonly,
                    'is-invalid': !!errorMessage,
                    'field__required-empty': required && (!itemData || itemData == '')
                }"
                :placeholder="placeholder"
                :disabled="disabled || readonly"
                class="form-control"
                :style="`padding: 0; border: none; ${inputStyle}`"
                :hour-options="hours"
                :hour-step="hourStep"
                :minute-step="minuteStep"
                :second-step="secondStep"
                :time-picker-options="options"
            />
        </div>

        <span
            v-if="displayonly"
            @click="() => {if (textEmit) $emit(textEmit, itemData); if (textOnClick) eval(textOnClick, itemData);}"
            class="form-control-plaintext"
            :class="textClass"
            :style="textStyle"
        >
            {{ itemPresentation }}
        </span>
        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/ru';

    const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'FieldTime',

        components: { DatePicker },

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: String,
            defaultValue: String,
            format: String,
            placeholder: String,
            disabled: Boolean,
            readonly: Boolean,
            displayonly: Boolean,
            required: Boolean,
            textEmit: String,
            textOnClick: String,
            textClass: String,
            textStyle: String,
            saveState: String,
            saveSessionState: String,
            inputStyle: String,
            errorMessage: String,
            hours: Array,
            hourStep: Number,
            minuteStep: Number,
            secondStep: Number,
            options: Object,
        },

        // =============== Data ===============
        data() {
            return {
                itemData: null,
                itemPresentation: null,
                timeFormat: null,
                presentationFormat: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Watch ===============
        watch: {
            value() {
                // console.log(`DEBUG: ${this.$options.name}.watch.value this.value=`, this.value);
                this.itemData = this.value;
            },
            itemData() {
                // this.itemPresentation = `${moment(this.value).format(this.presentationFormat)}`;
                this.itemPresentation = this.value;
            }
        },

        // =============== Methods ===============
        methods: {
            itemModified(e) {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.itemData=`, this.itemData);
                if (this.itemData === '') this.itemData = null;
                if (this.saveState) {
                    // Save current value
                    this.authenticatedAxios.post(`${this.baseUrl}/${userSettingsUrl}`, {code: this.saveState, value: this.itemData});
                }
                if (this.saveSessionState) {
                    sessionStorage.setItem(this.saveSessionState, JSON.stringify(this.itemData));
                }
                this.$emit('update:value', this.itemData);
                this.$emit('modified');
            },

            changeDate(days) {
                let oldDate = new Date(this.itemData);
                let newDate = new Date(oldDate.setDate(oldDate.getDate() + days));
                // console.log(`DEBUG: ${this.$options.name}.changeDate() newDate=`, newDate, ', typeof newDate=', typeof newDate);
                this.itemData = newDate.toISOString().split('T')[0];
                // console.log(`DEBUG: ${this.$options.name}.changeDate() this.itemData=`, this.itemData);
            },

        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.timeFormat = moment.HTML5_FMT.TIME_SEC; // 'HH:mm:ss'
            this.presentationFormat = this.format || moment.HTML5_FMT.TIME; // 'HH:mm'

            this.itemData = this.value;
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.itemData=`, this.itemData);
            if (!this.itemData && this.saveState) {
                // Restore stored value
                let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() response=`, response);
                let item = response.data.rows[0];
                if (item) this.itemData = item.value;
                this.itemModified(this);
            }
            if (!this.itemData && this.saveSessionState) {
                let sessionData = JSON.parse(sessionStorage.getItem(this.saveSessionState));
                if (sessionData) this.itemData = sessionData;
                this.itemModified(this);
            }
            if (!this.itemData && typeof this.defaultValue != 'undefined' && this.defaultValue !== null) {
                this.itemData = this.defaultValue;
                this.itemModified(this);
            }
        },
    }
</script>

<style>
    /* .field-date__label__before {
        margin-top: .5rem;
        margin-bottom: 0;
        margin-right: 8px;
        font-size: 1rem;
        line-height: 1.5;
        font-weight: bold;
    } */
</style>
