var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        'field-required':_vm.required,
        'form-group': true,
        'form-inline': _vm.labelPosition === 'before',
        'form-label-group': _vm.labelPosition === 'float'
    }},[(_vm.label && (_vm.labelPosition === 'above'))?_c('label',{staticClass:"field__label__above",attrs:{"for":_vm.id,"title":_vm.required ? _vm.$t('lib.required') : ''}},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('sup',{staticClass:"required-mark"},[_vm._v("*")]):_vm._e()]):_vm._e(),(_vm.label && (_vm.labelPosition === 'before'))?_c('label',{staticClass:"field__label__before",attrs:{"for":_vm.id,"title":_vm.required ? _vm.$t('lib.required') : ''}},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('sup',{staticClass:"required-mark"},[_vm._v("*")]):_vm._e()]):_vm._e(),(!_vm.readonly)?_c('div',{staticClass:"input-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemData),expression:"itemData"}],class:{
                'form-control': true,
                'custom-select': true,
                'field-select': true,
                'is-invalid': _vm.isInvalid || _vm.errorMessage,
                'is-valid': _vm.isValid,
                'field__readonly': _vm.readonly || _vm.disabled,
                'field__disabled': _vm.disabled,
                'field__required-empty': _vm.required && (!_vm.itemData || _vm.itemData == '')
            },style:(("" + _vm.inputStyle)),attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.itemData=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.itemModified()}]}},[(_vm.showEmptyRow)?_c('option',{key:("field-select-" + _vm._uid + "-empty_row"),domProps:{"value":null}},[_vm._v(_vm._s(_vm.emptyRowLabel))]):_vm._e(),_vm._l((_vm.selectListData),function(listItem,index){return _c('option',{key:("field-select-" + _vm._uid + "-" + index + "-" + (listItem[_vm.keyId||'id'])),domProps:{"value":listItem[_vm.keyId||'id']}},[_vm._v(_vm._s(listItem[_vm.keyLabel||'name']))])})],2),_c('div',{staticClass:"input-group-append"},[_vm._t("buttons")],2)]):_vm._e(),(_vm.readonly)?_c('span',{staticClass:"field__readonly",class:{textClass: _vm.textClass, 'form-control':_vm.labelPosition != 'plain', 'col-sm-10':_vm.labelPosition === 'before' },style:(_vm.textStyle),on:{"click":function () {if (_vm.textEmit) { _vm.$emit(_vm.textEmit, _vm.itemData); } if (_vm.textOnClick) { _vm.eval(_vm.textOnClick, _vm.itemData); }}}},[_vm._v(" "+_vm._s(_vm.itemPresentation)+" ")]):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.errorMessage}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }