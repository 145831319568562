<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-19
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">

        <template #buttons>
            <button :disabled="reportInProgress" @click.prevent="saveReport()" class="btn btn-sm btn-outline-primary mr-1">
                <i class="fas fa-download"></i> {{ $t('lib.Grid.download') }}
            </button>
            <button :disabled="reportInProgress || !filterData.dateFrom || !filterData.dateTo" @click.prevent="filterModified()" class="btn btn-sm btn-primary mr-1">
                <i class="fas fa-play"></i> {{ $t('app.build') }}
            </button>
        </template>

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldDate
                        :label="$t('attribute.common.date_from')"
                        :value.sync="filterData.dateFrom"
                        :daysPrevNext="1"
                        required
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldDate
                        :label="$t('attribute.common.date_to')"
                        :value.sync="filterData.dateTo"
                        :daysPrevNext="1"
                        required
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldSelect
                        :label="$t('object.core_building')"
                        :value.sync="filterData.core_building_id"
                        source="core_building"
                        showEmptyRow
                        :emptyRowLabel="$t('app.allBuildings')"
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldNumber
                        :label="$t('attribute.common.rows_qty')"
                        :value.sync="filterData.row_qty"
                        :defaultValue="5000"
                        :minValue="10"
                        :maxValue="10000"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <Grid ref="report" :config="gridConfig" />
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    // import { mapGetters } from 'vuex';
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ReportDeposit',

        components: {
            TemplatePageStandard,
            FieldDate, FieldSelect, FieldNumber,
            Grid,
        },

        // =============== Data ===============
        data() {
            return {
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.reports') },
                    { text: this.$t('MainMenu.reports-deposit') },
                ],
                filterData: {
                    // dateOf: null,
                    dateFrom: null,
                    dateTo: null,
                    core_building_id: null,
                    row_qty: null,
                },
                reportInProgress: false,
                gridConfig: {
                    apiUrl: null,
                    readonly: true,
                    hideZero: true,
                    columns: [
                        { type:'date', key:'payment_date', header:this.$t('attribute.common.date_of') },
                        { type:'string', key:'building_name', header:'Филиал' },
                        { type:'string', key:'user_shortname', header:'Сотрудник' },
                        { type:'string', key:'payer_fullname', header:'Плательщик' },
                        { type:'string', key:'is_primary_string', header:'Перв./Пост.', $cellStyle:entry => entry.is_primary ? 'color:#0a0;' : '' },
                        { type:'string', key:'payment_type_name', header:this.$t('object.fin_payment_type') },
                        // { type:'string', key:'is_day_string', header:'Д/Н' },
                        // { type:'number', key:'sum_transaction', header:'Сумма' },
                        { type:'number', key:'sum_deposit', header:'Сумма внесения' },
                        { type:'number', key:'sum_offset', header:'Сумма списания' },
                        { type:'string', key:'payment_commentary', header:this.$t('attribute.common.commentary') },
                    ],
                    // subtotals: {
                    //     position: 'above',
                    //     columns: [ 'payment_date' ]
                    // },
                    totals: {
                        columns: [
                            { type:'total', key:'user_shopayment_datertname', value:this.$t('app.total'), style:'font-weight:bold;' },
                            {
                                type:'number', key:'is_primary_string',
                                $value: data => {
                                    return `${data.reduce( ((sum, item) => sum + (item.is_primary ? 1 : 0)), 0 )} / ${data.reduce( ((sum, item) => sum + (!item.is_primary ? 1 : 0)), 0 )}`;
                                }, style:'font-weight:bold;'
                            },
                            { type:'number', key:'sum_transaction', $value: data => tools.getArrayFieldSum(data, 'sum_transaction'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_deposit', $value: data => tools.getArrayFieldSum(data, 'sum_deposit'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_offset', $value: data => tools.getArrayFieldSum(data, 'sum_offset'), style:'font-weight:bold;' },
                        ],
                    },
                    // paginationPageSize: 1000,
                    order: [ 'payment_date', 'building_name', 'user_shortname' ],
                    displayToolbar: false,
                    // buttons: [  'actions', 'download' ],
                },
            };
        },

        // =============== Methods ===============
        methods: {
            async setGridConfig() {
                this.reportInProgress = true;
                this.gridConfig.paginationPageSize = this.filterData.row_qty;
                this.gridConfig.filter = {
                    where: [
                        [ 'visit_date', '>=', this.filterData.dateFrom ],
                        [ 'visit_date', '<=', this.filterData.dateTo ],
                        { core_building_id:this.filterData.core_building_id },
                    ],
                };
                if (!this.gridConfig.apiUrl) {
                    this.gridConfig.apiUrl = 'report-deposit';
                } else {
                    await this.$refs.report.refresh();
                }
                this.reportInProgress = false;
            },

            async filterModified() { await this.setGridConfig() },

            saveReport() {
                this.gridConfig.downloadFileName = (
                    this.breadcrumb[this.breadcrumb.length - 1].text + '_' +
                    // moment(this.filterData.dateOf).format('YYYY-MM-DD') + '_' +
                    moment(this.filterData.dateFrom).format('YYYY-MM-DD') + '_' +
                    moment(this.filterData.dateTo).format('YYYY-MM-DD')
                );
                this.$refs.report.download();
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this=`, this);
            // this.filterData.dateFrom = moment().startOf('month').format(moment.HTML5_FMT.DATE);
            // this.filterData.dateOf = moment().format(moment.HTML5_FMT.DATE);
            this.filterData.dateFrom = moment().format(moment.HTML5_FMT.DATE);
            this.filterData.dateTo = moment().format(moment.HTML5_FMT.DATE);
        },

        async mounted() {
            // this.setGridConfig();
        },
    }
</script>

<style>
</style>
