/*
    Author: Eduard Grebenyukov
    Date: 2020-04-22
*/

import { i18n } from '@/locales';

const pageConfigCRM = {
    crm_request: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.crm') },
            { text: i18n.t('MainMenu.crm-requests') }
        ],
        gridConfig: {
            apiUrl: 'v_crm_request',
            readonly: true,
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, align:'right' },
                { type:'datetime', key:'time_of', header:i18n.t('app.datetime') },
                { type:'select', key:'channel_id', header:i18n.t('crm.channel'), required:true, source:'enum/crm_channel' },
                { type:'select', key:'core_user_id', header:i18n.t('object.core_user'), required:true, source:'v_user', keyLabel:'shortname' },
                { type:'string', key:'subscriber_identifier', header:i18n.t('attribute.common.phone') },
                {
                    type:'string', key:'client_name', header:i18n.t('object.core_client'),
                    $cellStyle: item => (item.is_client ? '' : 'color: #ff0000;'),
                },
                { type:'string', key:'request_reason', header:i18n.t('crm.request_reason') },
                { type:'string', key:'request_result', header:i18n.t('crm.request_result') },
                { type:'string', key:'commentary', header:i18n.t('attribute.common.commentary') },
            ],
            order: [{ key:'id', direction:'desc' }],
            buttons: [ 'add', 'edit' ],
            saveSessionState: 'crm_request',
            onAdd: 'crm/request-new',
            onEdit: 'crm/request-edit',
        }
    },
}

export { pageConfigCRM };
