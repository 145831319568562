<!--
    Author: Eduard Grebenyukov
    Date: 2018-12-02
-->

<template>
    <TemplatePageStandard :config="config">
        <div id="page-content">
            <Grid :key="$route.fullPath" :config="config.gridConfig" @modified="gridModified($event)" @add-item="addItem" @edit-item="editItem($event.id)" />
        </div>
    </TemplatePageStandard>
</template>

<script>
    import $ from 'jquery';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'Roles',
        components: { TemplatePageStandard, Grid },
        props: ['config'],

        // =============== Data ===============
        data() {
            return {
                isModified: Boolean,
            }
        },

        // =============== Watch ===============
        watch: {
            isModified() {
                if (this.isModified) {
                    $('#breadcrumb').addClass('modified');
                } else {
                    $('#breadcrumb').removeClass('modified');
                }
            }
        },

        // =============== Methods ===============
        methods: {
            gridModified(e) {
                this.isModified = e;
            },
            addItem() {
                this.id = null;
                this.showEditModal = true;
            },
            editItem(id) {
                //console.log(`DEBUG: ${this.$options.name}.editItem()`);
                this.$router.push({ name: 'settings/rights/role', params: { id } });
            },
            closeModal(isRefresh) {
                //console.log('SystemSettingsEdit asked close');
                this.showEditModal = false;
                if (isRefresh) this.getData();
            }
        }
    }
</script>

<style>

</style>
