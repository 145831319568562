/*
    Author: Eduard Grebenyukov
    Date: 2020-08-11
*/

import { i18n } from '@/locales';

function isTaskRunnable(task, currentUser) {
    return (
        // ['new', 'started'].includes(task.status_code) &&
        task.status_code ==='new' &&
        (
            task.performer_user_id === null ||
            task.performer_user_id === currentUser.id
        )
    );
}

function isTaskCompletable(task, currentUser) {
    return (
        // ['started'].includes(this.otherData.status_code) &&
        task.status_code === 'started' &&
        (
            task.performer_user_id === null ||
            task.performer_user_id === currentUser.id
        )
    );
}

async function runTask(ctx, taskId, currentUser) {
    // !!! move it to Vuex !!!
    // catch error outside this function
    let data = {
        task_rec_id: taskId,
        performer_user_id: currentUser.id,
        performer_workplace_id: currentUser.currentWorkplace ? currentUser.currentWorkplace.id : null,
    };
    await ctx.authenticatedAxios.post(`${ctx.baseUrl}/task-run`, data);
}

async function setTaskStatus(ctx, taskId, status_code) {
    // !!! move it to Vuex !!!
    // catch error outside this function
    return ctx.authenticatedAxios.post(`${ctx.baseUrl}/task-set-status`, { task_rec_id: taskId, status_code: status_code });
}

async function rollbackTaskStatus(ctx, taskId, status_code) {
    // catch error outside this function
    // !!! move it to Vuex !!!
    return ctx.authenticatedAxios.post(`${ctx.baseUrl}/task-rollback-status`, { task_rec_id: taskId, status_code: status_code });
}

async function setTaskPerformer(ctx, taskId, performer) {
    // !!! move it to Vuex !!!
    // catch error outside this function
    let data = {
        task_rec_id: taskId,
        performer_role_id: performer.performer_role_id,
        performer_user_id: performer.performer_user_id,
        performer_workplace_id: performer.performer_workplace_id,
    };
    return ctx.authenticatedAxios.post(`${ctx.baseUrl}/task-set-status`, data);
}

function getTaskIcon(task) {
    // !!! move it to Vuex !!!
    // catch error outside this function
    if (task.is_overdue) return 'far fa-clock';
    if (task.is_untimely) return 'far fa-clock';
    switch (task.status_code) {
        case 'new': return 'far fa-clock';
        case 'started': return 'fas fa-play';
        case 'completed': return 'fas fa-stop';
        case 'canceled': return 'far fa-times-circle';
        default: return '';
    }
}

function getTaskColor(task) {
    // !!! move it to Vuex !!!
    if (task.is_overdue) return '#ff0000';
    if (task.is_untimely) return '#ccaa00';
    switch (task.status_code) {
        case 'new': return '#056bbf';
        case 'started': return '#056bbf'; //#007bff
        case 'completed': return '#007b00';
        case 'canceled': return '#ff0000';
        default: return '';
    }
}

function getTaskStatusText(task) {
    // !!! move it to Vuex !!!
    if (task.is_overdue) {
        return i18n.t('component.Tasks.overdue');
    }
    if (task.is_untimely) {
        return i18n.t('component.Tasks.untimely');
    }
    return task.status_name;
}

export default {
    isTaskRunnable, isTaskCompletable, runTask,
    setTaskStatus, rollbackTaskStatus, setTaskPerformer,
    getTaskIcon, getTaskColor, getTaskStatusText,
}
