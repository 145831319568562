<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-29
-->

<!--
    labelPosition: before | above
    format: null | YYYY-MM-DDTHH:mm (default) | YYYY-MM-DDTHH:mm:ss | YYYY-MM-DDTHH:mm:ss.SSS
-->

<template>
    <div
        :class="{
            'field-required': required,
            'form-group': true,
            'form-inline': labelPosition === 'before',
            'form-label-group': labelPosition === 'float'
        }"
    >
        <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label :for="id" v-if="label && (labelPosition === 'before')" :title="required ? $t('lib.required') : ''" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <div class="field-number__label-spacer" v-if="!label && (labelPosition === 'above')" ></div>

        <!-- <label
            v-if="label && labelPosition != 'before'"
            :title="required ? $t('lib.required') : ''"
            :class="{
                'field__label__above':true,
                'col-form-label':labelPosition === 'before'
            }"
        >
            {{label}}<sup v-if="required" class="required-mark">*</sup>
        </label> -->

        <div class="input-group">

            <div v-if="daysPrevNext" class="input-group-prepend">
                <button v-on:click="onPrev" class="btn btn-outline-secondary" type="button"><i class="fas fa-angle-left"></i></button>
            </div>

            <!-- <label v-if="label && labelPosition === 'before'" :title="required ? $t('lib.required') : ''" class="field-date__label__before" >
                {{label}}<sup v-if="required" class="required-mark">*</sup>
            </label> -->

            <DatePicker
                v-if="!displayonly"
                :id="id"
                type="date"
                :value-type="dateFormat"
                :format="format || 'DD.MM.YYYY'"
                :clearable="displayClearButton"
                :confirm="false"
                v-model="itemData"
                :input-class="{
                    'form-control': true,
                    'is-invalid': !!errorMessage,
                    'field__required-empty': required && (!itemData || itemData == '')
                }"
                :placeholder="placeholder"
                :disabled="disabled || readonly"
                class="form-control"
                :style="`padding: 0; border: none; ${inputStyle}`"
                @input="itemModified($event)"
                @change="itemModified($event)"
                @focus="itemFocused($event)"
            />
            <div v-if="daysPrevNext" class="input-group-append">
                <button v-on:click="onNext" class="btn btn-outline-secondary" type="button"><i class="fas fa-angle-right"></i></button>
            </div>
        </div>

        <span
            v-if="displayonly"
            @click="() => {if (textEmit) $emit(textEmit, itemData); if (textOnClick) eval(textOnClick, itemData);}"
            class="form-control-plaintext"
            :class="textClass"
            :style="textStyle"
        >
            {{ itemPresentation }}
        </span>
        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/ru';

    const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'FieldDate',

        components: { DatePicker },

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: String,
            defaultValue: String,
            format: String,
            placeholder: String,
            disabled: Boolean,
            readonly: Boolean,
            displayonly: Boolean,
            required: Boolean,
            textEmit: String,
            textOnClick: String,
            textClass: String,
            textStyle: String,
            saveState: String,
            saveSessionState: String,
            // showPrevNext: Boolean,
            daysPrevNext: Number,
            displayClearButton: Boolean,
            inputStyle: String,
            errorMessage: String,
        },

        // =============== Data ===============
        data() {
            return {
                itemData: null,
                itemPresentation: null,
                dateFormat: null,
                presentationFormat: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Watch ===============
        watch: {
            value() {
                // console.log(`DEBUG: ${this.$options.name}.watch.value this.value=`, this.value);
                // this.itemData = moment(this.value).format(this.format || moment.HTML5_FMT.DATE);
                this.itemData = this.value;
                // console.log(`DEBUG: ${this.$options.name}.watch.value this.value=${this.value}, this.itemData=${this.itemData}, format=${moment.HTML5_FMT.DATE}`);
            },
            itemData() {
                this.itemPresentation = this.value ? `${moment(this.value).format(this.presentationFormat)}` : '';
            }
        },

        // =============== Methods ===============
        methods: {
            itemModified(e) {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.itemData=`, this.itemData);
                if (this.itemData === '') this.itemData = null;
                if (this.saveState) {
                    // Save current value
                    this.authenticatedAxios.post(`${this.baseUrl}/${userSettingsUrl}`, {code: this.saveState, value: this.itemData});
                }
                if (this.saveSessionState) {
                    sessionStorage.setItem(this.saveSessionState, JSON.stringify(this.itemData));
                }
                this.$emit('update:value', this.itemData);
                this.$emit('modified');
            },

            itemFocused(e) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() e=`, e);
                this.$emit('focus');
            },


            changeDate(days) {
                let oldDate = new Date(this.itemData);
                let newDate = new Date(oldDate.setDate(oldDate.getDate() + days));
                // console.log(`DEBUG: ${this.$options.name}.changeDate() newDate=`, newDate, ', typeof newDate=', typeof newDate);
                this.itemData = newDate.toISOString().split('T')[0];
                // console.log(`DEBUG: ${this.$options.name}.changeDate() this.itemData=`, this.itemData);
            },

            onPrev(e) {
                this.changeDate(-this.daysPrevNext);
                this.itemModified(e);
            },
            onNext(e) {
                this.changeDate(this.daysPrevNext);
                this.itemModified(e);
            },
            onClear(e) {
                this.itemData = null;
                this.itemModified(e);
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.dateFormat = moment.HTML5_FMT.DATE;
            this.presentationFormat = this.format || 'L';

            // this.itemData = moment(this.value).format(this.format || moment.HTML5_FMT.DATE);
            this.itemData = this.value;
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.itemData=`, this.itemData);
            if (!this.itemData && this.saveState) {
                // Restore stored value
                let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() response=`, response);
                let item = response.data.rows[0];
                if (item) this.itemData = item.value;
                this.itemModified(this);
            }
            if (!this.itemData && this.saveSessionState) {
                let sessionData = JSON.parse(sessionStorage.getItem(this.saveSessionState));
                if (sessionData) this.itemData = sessionData;
                this.itemModified(this);
            }
            if (!this.itemData && typeof this.defaultValue != 'undefined' && this.defaultValue !== null) {
                this.itemData = this.defaultValue;
                this.itemModified(this);
            }
        },
    }
</script>

<style>
    /* .field-date__label__before {
        margin-top: .5rem;
        margin-bottom: 0;
        margin-right: 8px;
        font-size: 1rem;
        line-height: 1.5;
        font-weight: bold;
    } */
</style>
