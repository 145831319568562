<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-19
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">

        <template #buttons>
            <button :disabled="reportInProgress" @click.prevent="saveReport()" class="btn btn-sm btn-outline-primary mr-1">
                <i class="fas fa-download"></i> {{ $t('lib.Grid.download') }}
            </button>
            <button :disabled="reportInProgress || !filterData.dateFrom || !filterData.dateTo" @click.prevent="filterModified()" class="btn btn-sm btn-primary mr-1">
                <i class="fas fa-play"></i> {{ $t('app.build') }}
            </button>
        </template>

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldDate
                        :label="$t('attribute.common.date_from')"
                        :value.sync="filterData.dateFrom"
                        :daysPrevNext="1"
                        required
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldDate
                        :label="$t('attribute.common.date_to')"
                        :value.sync="filterData.dateTo"
                        :daysPrevNext="1"
                        required
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldSelect
                        :label="$t('object.core_building')"
                        :value.sync="filterData.core_building_id"
                        source="core_building"
                        showEmptyRow
                        :emptyRowLabel="$t('app.allBuildings')"
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldNumber
                        :label="$t('attribute.common.rows_qty')"
                        :value.sync="filterData.row_qty"
                        :defaultValue="5000"
                        :minValue="10"
                        :maxValue="10000"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-lg-4 col-xl">
                    <Grid ref="report" :config="gridConfig" />
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ReportAdmPatientsPrimary',

        components: {
            TemplatePageStandard,
            FieldDate, FieldSelect, FieldNumber,
            Grid,
        },

        // =============== Data ===============
        data() {
            return {
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.reports') },
                    { text: this.$t('MainMenu.reports-administrators-patients-primary') },
                ],
                filterData: {
                    dateFrom: null,
                    dateTo: null,
                    core_building_id: null,
                    row_qty: null,
                },
                reportInProgress: false,
                gridConfig: {
                    apiUrl: null,
                    readonly: true,
                    hideZero: true,
                    columns: [
                        { type:'string', key:'building_name', header:this.$t('object.core_building') },
                        { type:'string', key:'user_name', header:this.$t('app.employee') },
                        { type:'datetime', key:'time_of', header:'Дата, время звонка' },
                        { type:'string', key:'client_name', header:this.$t('object.core_client') },
                        { type:'string', key:'subscriber_identifier', header:this.$t('crm.subscriber_identifier') },
                        { type:'string', key:'request_reason', header:this.$t('enum.crm_request_reason') },
                        { type:'date', key:'schedule_date', header:'Дата записи на прием' },
                        { type:'string', key:'visit_doctor_name', header:'ФИО доктора' },
                        { type:'number', key:'visit_sum_payment', header:'Сумма приема' },
                        { type:'string', key:'loss_reason', header:'Если не записался - причина' },
                    ],
                    totals: {
                        columns: [
                            { type:'string', key:'building_name', value:this.$t('app.total'), style:'font-weight:bold;' },
                            { type:'number', key:'client_name', style:'font-weight:bold;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum) => sum + 1), 0 );
                                    else return 0;
                                }
                            },
                            { type:'number', key:'sum_payment', $value: data => tools.getArrayFieldSum(data, 'sum_payment'), style:'font-weight:bold;' },
                        ],
                    },
                    // paginationPageSize: 1000,
                    order: [ 'user_name' ],
                    displayToolbar: false,
                },
            };
        },

        // =============== Methods ===============
        methods: {
            async setGridConfig() {
                this.reportInProgress = true;
                this.gridConfig.paginationPageSize = this.filterData.row_qty;
                this.gridConfig.filter = { where:[
                    ['date_of', '>=', this.filterData.dateFrom],
                    ['date_of', '<=', this.filterData.dateTo],
                    { core_building_id:this.filterData.core_building_id },
                ]};
                if (!this.gridConfig.apiUrl) {
                    this.gridConfig.apiUrl = 'report-adm-patients-primary';
                } else {
                    await this.$refs.report.refresh();
                }
                this.reportInProgress = false;
            },

            async filterModified() { await this.setGridConfig() },

            saveReport() {
                this.gridConfig.downloadFileName = (
                    this.breadcrumb[this.breadcrumb.length - 1].text + '_' +
                    moment(this.filterData.dateFrom).format('YYYY-MM-DD') + '_' +
                    moment(this.filterData.dateTo).format('YYYY-MM-DD')
                );
                this.$refs.report.download();
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this=`, this);
            this.filterData.dateFrom = moment().startOf('month').format(moment.HTML5_FMT.DATE);
            this.filterData.dateTo = moment().format(moment.HTML5_FMT.DATE);
        },

        async mounted() {
            // this.setGridConfig();
        },
    }
</script>

<style>
</style>
