/*
    Author:Eduard Grebenyukov
    Date:2021-09-12
*/

import { i18n } from '@/locales';

const AnamnesisVitae = () => import('@/components/pages/med/AnamnesisVitae');
const Consultation = () => import('@/components/pages/med/Consultation');
const ConsultationDental = () => import('@/components/pages/med/ConsultationDental');
const ConsultationAssistant = () => import('@/components/pages/med/ConsultationAssistant');
const MedOphtalmologyExam = () => import('@/components/pages/med/MedOphtalmologyExam');
const RenderedServices = () => import('@/components/pages/med/RenderedServices');
const RenderedServicesDental = () => import('@/components/pages/med/RenderedServicesDental');
const RenderedServiceEdit = () => import('@/components/pages/med/RenderedServiceEdit');
const RenderedServiceEditDental = () => import('@/components/pages/med/RenderedServiceEditDental');
const Referrals = () => import('@/components/pages/med/Referrals');
const MedEstimate = () => import('@/components/pages/med/MedEstimate');
const MedEstimateDental = () => import('@/components/pages/med/MedEstimateDental');
const MedEstimateEdit = () => import('@/components/pages/med/MedEstimateEdit');
const MedEstimateEditDental = () => import('@/components/pages/med/MedEstimateEditDental');
const MedHospitalization = () => import('@/components/pages/med/MedHospitalization');
const MedDischarge = () => import('@/components/pages/med/MedDischarge');
const MedMedications = () => import('@/components/pages/med/MedMedications');
const MedSampling = () => import('@/components/pages/med/MedSampling');

const routes = [
    { name:'visit-anamnesis-vitae',        path:'/client/:clientId/visit/:visitId/anamnesis-vitae',           component:AnamnesisVitae,            meta:{title:i18n.t('VisitMenu.anamnesis-vitae')} },
    { name:'visit-consultation',           path:'/client/:clientId/visit/:visitId/consultation',              component:Consultation,              meta:{title:i18n.t('VisitMenu.consultation')} },
    { name:'visit-consultation-dental',    path:'/client/:clientId/visit/:visitId/consultation-dental',       component:ConsultationDental,        meta:{title:i18n.t('VisitMenu.consultation-dental')} },
    { name:'visit-consultation-assistant', path:'/client/:clientId/visit/:visitId/consultation-assistant',    component:ConsultationAssistant,     meta:{title:i18n.t('VisitMenu.consultation-assistant')} },
    { name:'visit-ophtalmology-exam',      path:'/client/:clientId/visit/:visitId/ophtalmology-exam',         component:MedOphtalmologyExam,       meta:{title:i18n.t('VisitMenu.ophtalmology-exam')} },
    { name:'visit-hospitalization',        path:'/client/:clientId/visit/:visitId/hospitalization',           component:MedHospitalization,        meta:{title:i18n.t('VisitMenu.hospitalization')} },
    { name:'visit-discharge',              path:'/client/:clientId/visit/:visitId/discharge',                 component:MedDischarge,              meta:{title:i18n.t('VisitMenu.discharge')} },
    { name:'visit-medications',            path:'/client/:clientId/visit/:visitId/medications',               component:MedMedications,            meta:{title:i18n.t('VisitMenu.medications')} },
    { name:'visit-sampling',               path:'/client/:clientId/visit/:visitId/sampling',                  component:MedSampling,               meta:{title:i18n.t('VisitMenu.sampling')} },

    { name:'visit-services',               path:'/client/:clientId/visit/:visitId/services',                  component:RenderedServices,          meta:{title:i18n.t('VisitMenu.services')} },
    { name:'visit-services-dental',        path:'/client/:clientId/visit/:visitId/services-dental',           component:RenderedServicesDental,    meta:{title:i18n.t('VisitMenu.services')} },
    { name:'visit-service-edit',           path:'/client/:clientId/visit/:visitId/service/:renderId',         component:RenderedServiceEdit,       meta:{title:i18n.t('VisitMenu.render')} },
    { name:'visit-service-edit-dental',    path:'/client/:clientId/visit/:visitId/service-dental/:renderId',  component:RenderedServiceEditDental, meta:{title:i18n.t('VisitMenu.render')} },
    { name:'visit-referrals',              path:'/client/:clientId/visit/:visitId/referrals',                 component:Referrals,                 meta:{title:i18n.t('VisitMenu.referrals')} },
    { name:'visit-estimates',              path:'/client/:clientId/visit/:visitId/estimates',                 component:MedEstimate,               meta:{title:i18n.t('VisitMenu.estimates')} },
    { name:'visit-estimates-dental',       path:'/client/:clientId/visit/:visitId/estimates-dental',          component:MedEstimateDental,         meta:{title:i18n.t('VisitMenu.estimates')} },
    { name:'visit-estimate-edit',          path:'/client/:clientId/visit/:visitId/estimate/:renderId',        component:MedEstimateEdit,           meta:{title:i18n.t('VisitMenu.estimate')} },
    { name:'visit-estimate-edit-dental',   path:'/client/:clientId/visit/:visitId/estimate-dental/:renderId', component:MedEstimateEditDental,     meta:{title:i18n.t('VisitMenu.estimate')} },
]

export default routes
