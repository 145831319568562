/*
    Author: Eduard Grebenyukov
    Date: 2020-06-28
*/

import { i18n } from '@/locales';

const pageConfigReports = {
    report_price: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.reports') },
            { text: i18n.t('MainMenu.reports-price') }
        ],
        gridConfig: {
            apiUrl: 'report-price',
            readonly: true,
            columns: [
                { type:'string', key:'group_name', header:i18n.t('object.fin_service_group') },
                { type:'string', key:'code', header:i18n.t('attribute.common.code'), style:'white-space:nowrap;' },
                { type:'string', key:'code_public', header:i18n.t('attribute.common.code_public'), style:'white-space:nowrap;' },
                { type:'string', key:'name', header:i18n.t('attribute.common.name') },
                // { type:'number', key:'price', header:i18n.t('attribute.common.price'), align:'right' },
                {
                    type:'number', key:'price', header:i18n.t('attribute.common.price'), align:'right',
                    $value: (entry) => ( entry.is_price_variable ? 'изм.' : entry.price ),
                },
            ],
            order: [ 'group_name', 'name' ],
            saveSessionState: 'report_price',
        }
    },
}

export { pageConfigReports };
