<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-03
-->

<template>
    <StandardRegion :title="$t('component.ReferralTemplate.template')" regionClass="sticky-4">

        <div class="visit-referral-template__buttons">
            <button
                @click.prevent="selectTemplateType('personal')"
                class="btn btn-outline-secondary btn-sm ml-2"
                :class="{ active:templateType == 'personal' }"
                :title="$t('component.ReferralTemplate.personal')"
            ><i class="fas fa-star"></i> {{ $t('component.ReferralTemplate.personal') }}</button>
            <button
                @click.prevent="selectTemplateType('general')"
                class="btn btn-outline-secondary btn-sm ml-2"
                :class="{ active:templateType == 'general' }"
                :title="$t('component.ReferralTemplate.general')"
            ><i class="fas fa-users"></i> {{ $t('component.ReferralTemplate.general') }}</button>
            <!-- <button
                v-if="isEditEnabled"
                @click.prevent="createTemplateFromAttribute"
                class="btn btn-warning btn-sm ml-2"
                :title="$t('Templates.create')"
            ><i class="fas fa-plus"></i></button> -->
            <!-- <button
                v-if="isEditEnabled"
                @click.prevent="createTemplateFromAttribute"
                class="btn btn-warning btn-sm ml-2"
                :title="$t('Templates.createFromAttribute')"
            ><i class="fas fa-angle-right"></i></button> -->
            <!-- <div @click="createTemplateFromDocument" class="btn btn-warning btn-sm ml-2" :title="$t('Templates.createFromDocument')">
                <i class="fas fa-angle-double-right"></i>
            </div> -->
        </div>
        <!-- <div class="visit-referral-template__buttons px-2">
            <div class="input-group">
                <div class="input-group-prepend">
                    <i class="input-group-text fas fa-search" :title="$t('lib.Grid.inputSearch')" ></i>
                </div>
                <button disabled><i class="fas fa-search" :title="$t('lib.Grid.inputSearch')" ></i></button>
                <input class="form-control query-input" name="query" v-model="searchKeybuffer" >
            </div>
        </div> -->
        <div class="visit-referral-template__wrapper">
            <TreeView
                :treeData="referralTemplates"
                :bottom="60"
                folderStyle="font-weight: bold;"
                selectedContextStyle="box-shadow: inset 0 0 5px #007bff88;"
                @select="selectReferral($event)"
                @opencontextmenu="showContextMenu($event)"
            />
            <div v-if="referralTemplates.length == 0">
                {{ $t('Templates.noTemplates') }}
            </div>
        </div>
        <ReferralTemplateEdit
            v-if="isDisplayModalEditor"
            @close-modal="closeModalEditor($event)"
            :templateId="templateId"
            :visitTypeId="visitTypeId"
            :userId="userId"
        />

        <VueContext ref="contextMenu">
            <template v-slot:default="child">
                <li><a id="context_select_item" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.select') }}</a></li>
                <hr class="context-divider">
                <li v-if="!isEditEnabled"><a id="context_add_item_to_personal_template" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.addToPersonalTemplate') }}</a></li>
                <!-- <li><a v-if="isEditEnabled" id="context_edit_add" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.add') }}</a></li> -->
                <li><a v-if="isEditEnabled" id="context_edit_item" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.edit') }}</a></li>
                <li><a v-if="isEditEnabled" id="context_delete_item" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.delete') }}</a></li>
            </template>
        </VueContext>

    </StandardRegion>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import StandardRegion from '@/components/lib/regions/StandardRegion';
    // import NavigationTabs from '@/components/lib/regions/NavigationTabs';
    import ReferralTemplateEdit from '@/components/pages/visits/ReferralTemplateEdit';
    import TreeView from '@/components/lib/regions/TreeView';
    import { VueContext } from 'vue-context';

    export default {
        name: 'ReferralTemplate',

        components: { StandardRegion, ReferralTemplateEdit, TreeView, VueContext },

        // =============== Props ===============
        props: {
            visitTypeId: Number,
            specialityId: Number,
            visitTime: Date,
            // userId: Number,
            // formItemName: String,
            // attributeName: String,
            // title: String,
            // attributeValue: String,
            // formData: {},
        },

        // =============== Data ===============
        data() {
            return {
                referralTemplates: [],
                referralTemplatesPersonal: [],
                referralTemplatesGeneral: [],
                templateType: 'general',
                isDisplayModalEditor: false,
                templateId: null,
                userId: null,
                // attributeValue: null,
                editFormData: {},
                isShowContextMenu: false,
                // searchKeybuffer: '',
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),

            // attributeValue() {
            //     return this.editFormData[this.formItemName];
            // },

            isEditEnabled() {
                return this.templateType === 'personal';
            },
        },

        // =============== Watch ===============
        watch: {
            // formItemName() {
            //     // this.attributeValue = this.editFormData[this.formItemName];
            //     this.getData();
            // },

            templateType() {
                this.chooseTemplates();
            },

            // formData() {
            //     this.editFormData = this.formData;
            //     // console.log(`DEBUG: ${this.$options.name}.watch.formData() this.editFormData=`, this.editFormData);
            // },
        },

        // =============== Methods ===============
        methods: {
            chooseTemplates() {
                if (this.templateType == 'personal') {
                    this.referralTemplates = tools.listToTree(this.referralTemplatesPersonal);
                    this.userId = this.currentUser.id;
                } else {
                    // console.log(`DEBUG: ${this.$options.name}.chooseTemplates() this.referralTemplatesGeneral=`, this.referralTemplatesGeneral);
                    // this.referralTemplates = tools.listToTree(JSON.parse(JSON.stringify(this.referralTemplatesGeneral)));
                    this.referralTemplates = tools.listToTree(this.referralTemplatesGeneral.slice());
                    this.userId = null;
                }
                // console.log(`DEBUG: ${this.$options.name}.chooseTemplates() this.referralTemplates=`, this.referralTemplates);
            },

            async getData() {
                // console.log(`DEBUG: ${this.$options.name}.getData() this.attributeName=${this.attributeName}`);
                let serviceGroupFilterString = tools.getFilterString({ order:[ 'name' ]});
                try {
                    let serviceGroups = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_service_group${serviceGroupFilterString}`)).data.rows;
                    let serviceGroups2 = serviceGroups.map(item => {
                        return {
                            id: `grp-${item.id}`,
                            parent_id: item.parent_id ? `grp-${item.parent_id}` : null,
                            code: item.code,
                            name: item.name,
                            isSelectable: false,
                            isGroup: true,
                        };
                    });
                    // console.log(`DEBUG: ${this.$options.name}.getData() serviceGroups=`, serviceGroups2);

                    if (this.currentUser.id) {
                        let referralFilterPersonalString = tools.getFilterString(
                            {
                                where:[
                                    { core_user_id: this.currentUser.id },
                                    { attribute_name: 'med_referral.med_referral_type_id' },
                                ],
                                order:[ 'sort_order' ],
                            }
                        );
                        this.referralTemplatesPersonal = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_template${referralFilterPersonalString}`)).data.rows;
                    } else {
                        this.referralTemplatesPersonal = [];
                    }
                    let referralFilterString = tools.getFilterString({ order:[ 'service_code' ]});
                    let referralTypeItems = (await this.authenticatedAxios.get(`${this.baseUrl}/v_referral_type${referralFilterString}`)).data.rows;
                    // check if service of referral is archived
                    let referralTypeItemsFiltered = referralTypeItems.filter(item => (
                        (item.date_from === null || moment(item.date_from).isSameOrBefore(this.visitTime)) &&
                        (item.date_to === null || moment(item.date_to).isSameOrAfter(this.visitTime))
                    )).map(item => {
                        return {
                            id: `${item.id}`,
                            parent_id: `grp-${item.fin_service_group_id}`,
                            code: item.service_code,
                            name: item.service_code ? `${item.service_code} / ${item.name}` : item.name,
                            // name: item.name,
                            term: item.term,
                            isSelectable: true,
                            isGroup: false,
                        };
                    });
                    // console.log(`DEBUG: ${this.$options.name}.getData() referralTypeItemsFiltered=`, referralTypeItemsFiltered);

                    this.referralTemplatesGeneral = serviceGroups2.concat(referralTypeItemsFiltered);
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.referralTemplatesGeneral=`, this.referralTemplatesGeneral);

                    if (this.referralTemplatesPersonal.length > 0)
                        this.selectTemplateType('personal');
                    else
                        this.selectTemplateType('general');
                    this.chooseTemplates();
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.referralTemplatesPersonal(${this.userId})=`, this.referralTemplatesPersonal);
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.documentTemplates=`, this.documentTemplates);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            selectTemplateType(templateType) {
                this.templateType = templateType;
            },

            showContextMenu(e) {
                // console.log(`DEBUG: ${this.$options.name}.showContextMenu() e=`, e);
                // this.isShowContextMenu = true;
                if (e.item.isSelectable || typeof e.item.isSelectable == 'undefined')
                    this.$refs.contextMenu.open(e.$event, e.item);
            },
            async onContextMenu(evt, item) {
                // console.log(`DEBUG: ${this.$options.name}.onContextMenu() e.target.id=`, evt.target.id, ', item=', item);
                switch (evt.target.id) {
                    case 'context_select_item':
                        this.selectReferral(item);
                        break;
                    case 'context_edit_item':
                        this.editTemplate(item.id);
                        break;
                    case 'context_delete_item':
                        this.deleteTemplate(item.id);
                        break;
                    case 'context_add_item_to_personal_template':
                        this.addItemToPersonalTemplate(item);
                        break;
                }
            },

            selectReferral(item) {
                // console.log(`DEBUG: ${this.$options.name}.selectReferral() item=`, item);
                if (this.templateType === 'personal') {
                    this.$emit('selected', { id:parseInt(item.template_text) });
                } else {
                    this.$emit('selected', item);
                }
            },

            addTemplate() {
                this.isDisplayModalEditor = true;
            },

            editTemplate(id) {
                this.templateId = id;
                this.isDisplayModalEditor = true;
            },

            // createTemplateFromAttribute() {
            //     // console.log(`DEBUG: ${this.$options.name}.createTemplateFromAttribute() this.visitTypeId=${this.visitTypeId}, this.formItemName=${this.formItemName} this.attributeValue=${this.attributeValue}`);
            //     this.templateId = null;
            //     this.isDisplayModalEditor = true;
            // },

            async addItemToPersonalTemplate(item) {
                // console.log(`DEBUG: ${this.$options.name}.addItemToPersonalTemplate() item=`, item);
                // console.log(`DEBUG: ${this.$options.name}.addItemToPersonalTemplate() currentUser=`, this.currentUser);
                let filter = tools.getFilterString({ where:[
                    { attribute_name: 'med_referral.med_referral_type_id' },
                    { template_text: item.id},
                ]});
                let prevTemplate = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_template${filter}`)).data.rows[0];
                if (!prevTemplate) {
                    let visit_template = {
                        parent_id: null,
                        visit_type_id: null,
                        core_speciality_id: null,
                        core_user_id: this.currentUser.id,
                        attribute_name: 'med_referral.med_referral_type_id',
                        name: item.name,
                        sort_order: null,
                        template_text: item.id,
                    }
                    await this.authenticatedAxios.post(`${this.baseUrl}/visit_template`, visit_template);
                    // console.log(`DEBUG: ${this.$options.name}.addItemToPersonalTemplate() response=`, response);
                    this.getData();
                } else {
                    tools.displayWarning(this, this.$t('component.ReferralTemplate.personalAlreadyExists'));
                }
            },

            async deleteTemplate(id) {
                try {
                    await this.authenticatedAxios.delete(`${this.baseUrl}/visit_template/${id}`);
                    tools.displaySuccessDelete(this.$parent);
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            closeModalEditor(isRefresh) {
                // console.log(`DEBUG: ${this.$options.name}.closeModalEditor()`);
                this.isDisplayModalEditor = false;
                if (isRefresh)
                    this.getData();
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.editFormData = this.formData;
            this.getData();
        },
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.editFormData.complaints=${this.editFormData.complaints}, this.editFormData=`, this.editFormData);
        },
    }
</script>

<style scoped>
    .visit-referral-template__wrapper {
        /* padding: 0.5rem; */
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
    .visit-referral-template__buttons {
        padding: 0.5rem 0;
        border-bottom: 1px solid #dee2e6;
    }
</style>
