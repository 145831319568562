/*
    Author: Eduard Grebenyukov
    Date: 2020-06-12
*/

function isStringEmpty(str) {
    return typeof str === 'undefined' || str === null || str.length == 0;
}

function isNumberEmpty(num) {
    return typeof num === 'undefined' || num === null || isNaN(num);
}

function isBooleanEmpty(val) {
    return typeof val === 'undefined' || val === null;
}

function isStringDecimalInteger(str) {
    if (str.match(/^-?\d+$/)){
        //valid integer (positive or negative)
        return true;
    } else {
        //not valid number
        return false;
    }
}

function isStringDecimalNumeric(str) {
    if (str.match(/^-?\d+$/)){
        //valid integer (positive or negative)
        return true;
    } else if (str.match(/^\d+\.\d+$/)){
        //valid float
        return true;
    } else {
        //not valid number
        return false;
    }
}

function matchWildcard(str, rule) {
    // if (str == 'cashdesk' && rule === 'cashdesk') console.log(`DEBUG: tools.matchWildcard(${str}, ${rule})`);
    if (!rule) return false;
    // var escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    var escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
    return new RegExp("^" + rule.split("*").map(escapeRegex).join(".*") + "$").test(str);
}

function isMainMenuItemGranted(permissions, item) {
    return (
        // !item.accessToken ||
        // item.accessToken === '$everyone' ||
        permissions.filter( p =>
            p.privilege_code === 'main_menu' &&
            (matchWildcard(item.code, p.code) || item.accessToken === p.code)
        ).length > 0
    );
}

function isPrivilegeGranted(permissions, privilegeCode, permissionCode, accessType) {
    // console.log(`DEBUG: tools.isPrivilegeGranted({...}, '${privilegeCode}', '${permissionCode}', '${accessType}' ) permissions=`, permissions);
    let isReadGranted;
    let isWriteGranted;
    switch (accessType) {
        case 'r': isReadGranted = true; isWriteGranted = null; break;
        case 'rw': isReadGranted = true; isWriteGranted = true; break;
        default: isReadGranted = null; isWriteGranted = null; break;
    }
    return (
        // !item.accessToken ||
        // item.accessToken === '$everyone' ||
        permissions.filter( p =>
            p.privilege_code === privilegeCode &&
            (!permissionCode || (matchWildcard(permissionCode, p.code))) &&
            // (!accessType || p.access_type == accessType) &&
            (isReadGranted === null || p.is_read === isReadGranted) &&
            (isWriteGranted === null || p.is_write === isWriteGranted)
        ).length > 0
    );
}

function isEqual(object1, object2) {
    return JSON.stringify(object1) === JSON.stringify(object2);
}

/* eslint-disable no-unused-vars */
function checkUPN(upn) {
    if (isStringDecimalInteger(upn) && upn.length === 16) {
        let sn1 = '';
        // odd positions
        for (let i = upn.length - 2; i >= 0; i -= 2) {
            sn1 += upn[i];
        }
        // console.log(`DEBUG: tools.checkUPN() sn1=`, sn1);
        let sn12 = (parseInt(sn1) * 2).toString();
        // console.log(`DEBUG: tools.checkUPN() sn12=`, sn12);
        let sn2 = '';
        // even positions except last
        for (let i = upn.length - 3; i >= 0; i -= 2) {
            sn2 += upn[i];
        }
        // console.log(`DEBUG: tools.checkUPN() sn2=`, sn2);
        let sn = sn2 + sn12;
        // console.log(`DEBUG: tools.checkUPN() sn=`, sn);
        // let sumD = sn.reduce((sum, d) => (sum + parseInt(d)) , 0);
        let sumD = 0;
        for (let i = 0; i < sn.length; i++) {
            sumD += parseInt(sn[i]);
        }
        // console.log(`DEBUG: tools.checkUPN() sumD=`, sumD);
        let nbn = Math.ceil(sumD / 10) * 10;
        // console.log(`DEBUG: tools.checkUPN() nbn=`, nbn);
        let cn= nbn - sumD;
        // console.log(`DEBUG: tools.checkUPN() cn=`, cn);
        if (upn[15] == cn.toString()) {
            return { status: 'success' };
        }
        return { status: 'error', reason: 'check_upn_wrong_control_sum' };
    } else {
        return { status: 'error', reason: 'check_upn_wrong_format' };
    }
}

export default {
    isStringEmpty, isNumberEmpty, isBooleanEmpty,
    isStringDecimalInteger, isStringDecimalNumeric,
    matchWildcard, isMainMenuItemGranted, isPrivilegeGranted,
    isEqual,
    checkUPN,
}
