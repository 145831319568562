/*
    Author:Eduard Grebenyukov
    Date:2021-09-12
*/

import { i18n } from '@/locales';

import { pageConfig } from '../store/pageConfig';
import { pageSubmenu } from '../store/pageSubmenu';

const SubmenuPage = () => import('@/components/lib/pages/SubmenuPage');
const GridEdit = () => import('@/components/pages/common/GridEdit');

const CRMRequestEdit = () => import('@/components/pages/crm/CRMRequestEdit');
const CRMCalls = () => import('@/components/pages/crm/CRMCalls');

const routes = [
    { name:'crm',              path:'/crm',             component:SubmenuPage,    props:{ config:pageSubmenu.submenu_crm }, meta:{title:i18n.t('MainMenu.crm')} },
    { name:'crm/requests',     path:'/crm/requests',    component:GridEdit,       props:{ config:pageConfig.crm_request },  meta:{title:i18n.t('MainMenu.crm-requests')} },
    { name:'crm/request-new',  path:'/crm/request',     component:CRMRequestEdit, props:true,                               meta:{title:i18n.t('crm.request')} },
    { name:'crm/request-edit', path:'/crm/request/:id', component:CRMRequestEdit, props:true,                               meta:{title:i18n.t('crm.request')} },
    { name:'crm/calls',        path:'/crm/calls',       component:CRMCalls,                                                 meta:{title:i18n.t('MainMenu.crm-calls')} },
]

export default routes
