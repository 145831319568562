<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-09
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
            <ButtonCustom
                v-if="buttons.includes('reversal')"
                buttonClass="btn-outline-primary btn-sm"
                iconClass="fas fa-history"
                :text="$t('component.RenderedServices.doReversal')"
                @click="doReversal()"
            />
        </template>
        <div id="page-content" class="form-float">
            <div v-if="!itemId" class="row">
                <div class="col">
                    <Grid
                        ref="splitsToReversalGrid"
                        :config="splitsToReversalGridConfig"
                        :source="splitsToReversalArray"
                        @refreshed="splitsRefreshed()"
                        @item-selected="splitSelected()"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-sm col-md-4 col-lg-3 col-xl-2">
                    <FieldDatetime
                        :label="$t('component.ReversalEdit.time_of')"
                        :value.sync="editFormData.time_of"
                        @modified="itemModified"
                    />
                </div>
                <div v-if="itemId" class="col-sm col-md-4 col-lg-3 col-xl-2">
                    <FieldString
                        :label="$t('attribute.fin_reversal.reversal_num')"
                        :value.sync="editFormData.reversal_num"
                        disabled
                    />
                </div>
                <div v-if="itemId" class="col-sm col-md-4 col-lg-4 col-xl-3">
                    <FieldString
                        :label="$t('object.core_user')"
                        :value.sync="workplace.user_name"
                        disabled
                    />
                </div>
                <div v-if="!itemId" class="col-sm col-md-4 col-lg-3 col-xl-2">
                    <FieldNumber
                        :label="$t('component.ReversalEdit.sumToReversal')"
                        :value.sync="editFormData.sumToReversal"
                        :decimalPlaces="2"
                        disabled
                    />
                </div>
            </div>
            <div v-if="itemId" class="row">
                <div class="col">
                    <Grid
                        ref="splitsOfReversalGrid"
                        :config="splitsOfReversalGridConfig"
                        :source="splitsOfReversalArray"
                    />
                </div>
            </div>
            <div v-if="!itemId" class="row">
                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="row">
                        <div class="col">
                            <h6>{{ $t('component.ReversalEdit.reversalTotal') }}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <FieldNumber
                                :label="$t('component.ReversalEdit.reversalTotalSum')"
                                :value.sync="editFormData.reversalTotalSum"
                                :decimalPlaces="2"
                                @modified="itemModified('reversalTotalSum')"
                            />
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <FieldNumber
                                :label="$t('component.ReversalEdit.reversalTotalPct')"
                                :value.sync="editFormData.reversalTotalPct"
                                :minValue="0"
                                :maxValue="100"
                                :decimalPlaces="4"
                                @modified="itemModified('reversalTotalPct')"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <FieldText
                        :label="$t('attribute.common.commentary')"
                        :value.sync="editFormData.commentary"
                        @modified="itemModified"
                    />
                </div>
            </div>
        </div>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import FieldDatetime from '@/components/lib/controls/FieldDatetime';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldText from '@/components/lib/controls/FieldText';

    const REVERSAL_CALC_TYPE = {
        TOTAL_SUM: 1,
        TOTAL_PCT: 2,
    }

    export default {
        name: 'ReversalEdit',

        components: {
            TemplatePageStandard,
            Grid,
            ButtonClose, ButtonSave, ButtonDelete, ButtonCustom,
            FieldDatetime, FieldNumber, FieldString, FieldText,
        },

        mixins: [
            tools.warningDeleteMixin,
            tools.warningCloseMixin,
        ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                billId: null,
                buttons: [],
                msgItemModified: '',
                bill: null,
                editFormData: {
                    // id: undefined,
                    core_workplace_id: null,
                    core_client_id: null,
                    visit_rec_id: null,
                    time_of: null,
                    reversal_num: null,
                    commentary: null,
                    //
                    sumToReversal: null,
                    reversalTotalSum: null,
                    reversalTotalPct: null,
                    reversalOnRenderSum: null,
                    reversalShiftSum: null,
                    reversalShiftPct: null,
                    beneficiaryId: null,
                    reversalCalcType: REVERSAL_CALC_TYPE.TOTAL_PCT,
                },
                oldFormData: {},
                workplace: {},
                isDisplayWarningDelete: false,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.finances') },
                    { text: this.$t('MainMenu.finances-reversals'), to: `/finances/reversals` },
                    { text: this.$t('object.fin_reversal') },
                ],
                splitsToReversalArray: [],
                splitsToReversalGridConfig: {
                    title: this.$t('object.fin_split'),
                    readonly: true,
                    columns: [
                        // { type: 'hidden', key:'dummy' },
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px', sortable:false },
                        // { type:'number', key:'divided_id', header:this.$t('attribute.fin_split.divided_id'), align:'right', width:'64px', sortable:false },
                        { type:'number', key:'reversed_id', header:this.$t('attribute.fin_split.reversed_id'), align:'right', width:'64px', sortable:false },
                        { type:'string', key:'payer_name', header:this.$t('attribute.fin_payer.fin_account_id'), sortable:false },
                        // { type:'string', key:'patient_name', header:this.$t(''), sortable:false },
                        // { type:'string', key:'program_name', header:this.$t('object.fin_program'), sortable:false },
                        // { type:'string', key:'bill_num', header:this.$t('attribute.fin_bill.bill_num'), sortable:false },
                        { type:'datetime', key:'time_of', header:this.$t('attribute.fin_bill.time_of'), sortable:false },
                        { type:'string', key:'service_name', header:this.$t('object.fin_service'), sortable:false },
                        { type:'number', key:'pct_value', header:this.$t('attribute.common.pct_value'), align:'right', width:'64px', sortable:false },
                        { type:'number', key:'qty', header:this.$t('attribute.common.qty'), align:'right', width:'64px', sortable:false },
                        // { type:'number', key:'sum_bid', header:this.$t('attribute.common.sum_bid'), align:'right', width:'64px', sortable:false },
                        // { type:'number', key:'sum_discount', header:this.$t('attribute.common.sum_discount'), align:'right', width:'64px', sortable:false },
                        // { type:'number', key:'sum_discount_payment', header:this.$t('attribute.common.sum_discount_payment'), align:'right', width:'64px', sortable:false },
                        { type:'number', key:'sum_payment_final', header:this.$t('attribute.common.sum_payment'), align:'right', width:'100px', sortable:false },
                        { type:'number', key:'sum_paid', header:this.$t('attribute.common.sum_paid'), align:'right', width:'100px', sortable:false },
                        // { type:'number', key:'sum_vat_final', header:this.$t('attribute.common.sum_vat'), align:'right', width:'100px', sortable:false },
                    ],
                    totals: {
                        columns: [
                            { type:'string', key:'service_name', value:this.$t('app.total'), style:'font-weight:bold; font-size:1.1rem;' },
                            { type:'number', key:'pct_value', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.pct_value), 0 ) : 0
                            },
                            // { type:'number', key:'qty', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.qty), 0 ) : 0
                            // },
                            // { type:'number', key:'sum_bid', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_bid), 0 ) : 0
                            // },
                            // { type:'number', key:'sum_discount', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_discount), 0 ) : 0
                            // },
                            // { type:'number', key:'sum_discount_payment', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_discount_payment), 0 ) : 0
                            // },
                            { type:'number', key:'sum_payment_final', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_payment_final), 0 ) : 0
                            },
                            { type:'number', key:'sum_paid', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_paid), 0 ) : 0
                            },
                            // { type:'number', key:'sum_vat_final', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_vat_final), 0 ) : 0
                            // },
                        ],
                    },
                    row: {
                        $selectorCondition: entry => ( !entry.is_reversed && !entry.reversed_id ),
                        // $selectorCondition: entry => ( entry.reversed_id === null ),
                        // $style:(entry) => entry.sum_paid !== 0 ? 'background-color:#eee' : '',
                        $style:(entry) => {
                            if (entry.is_reversed) return 'background-color:#eeffff';
                            if (entry.fin_reversal_id) return 'background-color:#ffffee';
                            return '';
                        },
                    },
                    buttons: [ 'row-selector' ],
                    displaySearch: true,
                    displayPagination: false,
                    displayToolbar: true,
                },
                splitsOfReversalArray: [],
                splitsOfReversalGridConfig: {
                    title: this.$t('object.fin_split'),
                    readonly: true,
                    columns: [
                        // { type: 'hidden', key:'dummy' },
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px', sortable:false },
                        // { type:'number', key:'divided_id', header:this.$t('attribute.fin_split.divided_id'), align:'right', width:'64px', sortable:false },
                        { type:'number', key:'reversed_id', header:this.$t('attribute.fin_split.reversed_id'), align:'right', width:'64px', sortable:false },
                        { type:'string', key:'payer_name', header:this.$t('attribute.fin_payer.fin_account_id'), sortable:false },
                        // { type:'string', key:'patient_name', header:this.$t(''), sortable:false },
                        // { type:'string', key:'program_name', header:this.$t('object.fin_program'), sortable:false },
                        // { type:'string', key:'bill_num', header:this.$t('attribute.fin_bill.bill_num'), sortable:false },
                        { type:'datetime', key:'time_of', header:this.$t('attribute.fin_bill.time_of'), sortable:false },
                        { type:'string', key:'service_name', header:this.$t('object.fin_service'), sortable:false },
                        { type:'number', key:'pct_value', header:this.$t('attribute.common.pct_value'), align:'right', width:'64px', sortable:false },
                        // { type:'number', key:'qty', header:this.$t('attribute.common.qty'), align:'right', width:'64px', sortable:false },
                        // { type:'number', key:'sum_bid', header:this.$t('attribute.common.sum_bid'), align:'right', width:'64px', sortable:false },
                        // { type:'number', key:'sum_discount', header:this.$t('attribute.common.sum_discount'), align:'right', width:'64px', sortable:false },
                        // { type:'number', key:'sum_discount_payment', header:this.$t('attribute.common.sum_discount_payment'), align:'right', width:'64px', sortable:false },
                        { type:'number', key:'sum_payment_final', header:this.$t('attribute.common.sum_payment'), align:'right', width:'100px', sortable:false },
                        // { type:'number', key:'sum_paid', header:this.$t('attribute.common.sum_paid'), align:'right', width:'100px', sortable:false },
                        // { type:'number', key:'sum_vat_final', header:this.$t('attribute.common.sum_vat'), align:'right', width:'100px', sortable:false },
                    ],
                    totals: {
                        columns: [
                            { type:'string', key:'service_name', value:this.$t('app.total'), style:'font-weight:bold; font-size:1.1rem;' },
                            { type:'number', key:'pct_value', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.pct_value), 0 ) : 0
                            },
                            // { type:'number', key:'qty', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.qty), 0 ) : 0
                            // },
                            // { type:'number', key:'sum_bid', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_bid), 0 ) : 0
                            // },
                            // { type:'number', key:'sum_discount', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_discount), 0 ) : 0
                            // },
                            // { type:'number', key:'sum_discount_payment', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_discount_payment), 0 ) : 0
                            // },
                            { type:'number', key:'sum_payment_final', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_payment_final), 0 ) : 0
                            },
                            // { type:'number', key:'sum_paid', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_paid), 0 ) : 0
                            // },
                            // { type:'number', key:'sum_vat_final', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_vat_final), 0 ) : 0
                            // },
                        ],
                    },
                    row: {
                        // $selectorCondition: entry => ( entry.sum_paid === 0 ),
                        // $style:(entry) => entry.sum_paid !== 0 ? 'background-color:#eee' : '',
                        // $style:(entry) => entry.fin_reversal_id ? 'background-color:#ffffee' : '',
                    },
                    displaySearch: true,
                    displayPagination: false,
                    displayToolbar: true,
                },
                programArray: [],
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),
        },

        // =============== Methods ===============
        methods: {
            ...mapActions([]),

            checkButtons() {
                if (this.itemId) {
                    this.buttons = [ 'save', 'delete', 'close' ];
                } else {
                    this.buttons = [ 'close' ];
                    if (this.editFormData.reversalTotalSum) this.buttons.push('reversal');
                }
            },

            // setBreadcrumb() {
            //     this.breadcrumb[this.breadcrumb.length - 1].text = this.itemId ? this.itemId.toString() : this.$t('component.ReversalEdit.createNewDocument');
            // },

            splitsRefreshed() {
                // this.$refs.splitsToReversalGrid.selectAll();
                this.splitSelected();
            },

            splitSelected() {
                // console.log(`DEBUG: ${this.$options.name}.splitSelected(), this.$refs.splitsToReversalGrid.getSelectedRows()=`, this.$refs.splitsToReversalGrid.getSelectedRows());
                this.editFormData.sumToReversal = this.$refs.splitsToReversalGrid.getSelectedRows().reduce( ((sum, item) => sum + (item.sum_payment_final )), 0 );
                this.itemModified('sumToReversal');
                this.checkButtons();
                // console.log(`DEBUG: ${this.$options.name}.splitSelected(), this.editFormData.sumToReversal=`, this.editFormData.sumToReversal);
            },

            gridModified(evt) {
                // console.log(`DEBUG: ${this.$options.name}.gridModified(), evt=`, evt);
                // this.isServicesInSetpriceGridModified = evt;
                // this.checkModified();
            },

            async getReversalSplits() {
                try {
                    let filterString = tools.getFilterString({
                        where: [
                            { fin_reversal_id: this.itemId },
                        ],
                        order: [ 'id' ],
                    });
                    this.splitsOfReversalArray = (await this.authenticatedAxios.get(`${this.baseUrl}/v_split/${filterString}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.getReversalSplits(), this.splitsOfReversalArray=`, this.splitsOfReversalArray);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getWorkplace() {
                if (this.editFormData.core_workplace_id) {
                    try {
                        this.workplace = (await this.authenticatedAxios.get(`${this.baseUrl}/v_workplace/${this.editFormData.core_workplace_id}`)).data;
                        // console.log(`DEBUG: ${this.$options.name}.getWorkplace(), this.workplace=`, this.workplace);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
            },

            async getData() {
                try {
                    this.editFormData = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_reversal/${this.itemId}`)).data;
                    this.isModified = false;
                    this.msgItemModified = '';
                    this.itemId = this.editFormData.id;
                    this.getReversalSplits();
                    this.getWorkplace();
                    this.checkButtons();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getBill() {
                try {
                    this.bill = (await this.authenticatedAxios.get(`${this.baseUrl}/v_bill/${this.billId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getBill(), this.bill=`, this.bill);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getSplitsToReversal() {
                try {
                    let filterString = tools.getFilterString({
                        order: [ 'id' ],
                    });
                    this.splitsToReversalArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-bill-splits/${this.billId}${filterString}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.getSplitsToReversal(), this.splitsToReversalArray=`, this.splitsToReversalArray);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getProgramArray() {
                try {
                    let filterString = tools.getFilterString({
                        where: [
                            { core_client_id: this.bill.core_client_id },
                            { date_of: this.bill.date_of },
                        ],
                        order: [ 'id' ],
                    });
                    this.programArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-client-programs${filterString}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.getProgramArray(), this.programArray=`, this.programArray);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            let finReversal = {
                                id: this.editFormData.id,
                                core_workplace_id: this.editFormData.core_workplace_id,
                                core_client_id: this.editFormData.core_client_id,
                                visit_rec_id: this.editFormData.visit_rec_id,
                                time_of: this.editFormData.time_of,
                                reversal_num: this.editFormData.reversal_num,
                                commentary: this.editFormData.commentary,
                            }
                            // Update reversal
                            // let response = await this.authenticatedAxios.patch(this.apiUrl, this.editFormData);
                            await this.authenticatedAxios.put(`${this.baseUrl}/fin_reversal/${this.editFormData.id}`, finReversal);
                            tools.displaySuccessSave(this);
                            this.getData();
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
            },

            // recalcVariables() {},

            async itemModified(name) {
                // console.log(`DEBUG: ${this.$options.name}.itemModified(${name}), this.editFormData=`, this.editFormData);
                switch (name) {
                    case 'sumToReversal':
                        // if (this.editFormData.reversalTotalPct === null) {
                        this.editFormData.reversalTotalPct = 100;
                        this.editFormData.reversalTotalSum = this.editFormData.sumToReversal;
                        this.editFormData.reversalOnRenderSum = null;
                        this.editFormData.reversalCalcType = REVERSAL_CALC_TYPE.TOTAL_PCT;
                        // }
                        break;
                    case 'reversalTotalSum':
                        await this.$nextTick();
                        if (this.editFormData.reversalTotalSum > this.editFormData.sumToReversal) this.editFormData.reversalTotalSum = this.editFormData.sumToReversal;
                        if (this.editFormData.reversalTotalSum < 0) this.editFormData.reversalTotalSum = 0;
                        this.editFormData.reversalTotalPct = tools.round(100 * this.editFormData.reversalTotalSum / this.editFormData.sumToReversal, 4);
                        this.editFormData.reversalOnRenderSum = null;
                        this.editFormData.reversalCalcType = REVERSAL_CALC_TYPE.TOTAL_SUM;
                        break;
                    case 'reversalTotalPct':
                        await this.$nextTick();
                        if (this.editFormData.reversalTotalPct > 100) this.editFormData.reversalTotalPct = 100;
                        if (this.editFormData.reversalTotalPct < 0) this.editFormData.reversalTotalPct = 0;
                        this.editFormData.reversalTotalSum = tools.round(this.editFormData.reversalTotalPct * this.editFormData.sumToReversal / 100, 2);
                        this.editFormData.reversalOnRenderSum = null;
                        // console.log(`DEBUG: ${this.$options.name}.itemModified(${name}), this.editFormData.reversalTotalPct=`, this.editFormData.reversalTotalPct);
                        this.editFormData.reversalCalcType = REVERSAL_CALC_TYPE.TOTAL_PCT;
                        break;
                    // case 'reversalOnRenderSum':
                    //     this.editFormData.reversalTotalPct = null;
                    //     this.editFormData.reversalTotalSum = null;
                    //     this.editFormData.reversalShiftPct = null;
                    //     // this.editFormData.reversalCalcType = ???;
                    //     break;
                    // case 'reversalShiftSum': break;
                    // case 'reversalShiftPct':
                    //     await this.$nextTick();
                    //     if (this.editFormData.reversalShiftPct > 100) this.editFormData.reversalShiftPct = 100;
                    //     if (this.editFormData.reversalShiftPct < 0) this.editFormData.reversalShiftPct = 0;
                    //     break;
                    // case 'beneficiaryId':
                    //     // do nothing
                    //     break;
                    default: //
                }
                if (this.editFormData.reversalTotalSum && this.editFormData.reversalShiftPct) {
                    this.editFormData.reversalShiftSum = tools.round(this.editFormData.reversalShiftPct * this.editFormData.reversalTotalSum / 100, 2);
                } else {
                    this.editFormData.reversalShiftSum = null;
                }
                // console.log(`DEBUG: ${this.$options.name}.itemModified(), this.editFormData.reversalShiftSum=`, this.editFormData.reversalShiftSum);
                if (JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData) ) {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.isModified = false;
                    this.msgItemModified = '';
                }
            },

            async doReversal() {
                // console.log(`DEBUG: ${this.$options.name}.doReversal()`);
                const reversalData = {
                    core_workplace_id: this.currentUser.currentWorkplace.id,
                    time_of: this.editFormData.time_of,
                    fin_bill_id: this.billId,
                    fin_beneficiary_id: this.editFormData.beneficiaryId,
                    fin_program_id: null,
                    sum_to_reversal: this.editFormData.sumToReversal,
                    reversal_sum: (this.editFormData.reversalCalcType === REVERSAL_CALC_TYPE.TOTAL_SUM ? this.editFormData.reversalTotalSum : null),
                    // reversal_pct: (this.editFormData.reversalCalcType === REVERSAL_CALC_TYPE.TOTAL_PCT ? this.editFormData.reversalTotalPct : null),
                    reversal_pct: this.editFormData.reversalTotalPct,
                    on_render_sum: this.editFormData.reversalOnRenderSum,
                    shift_pct: this.editFormData.reversalShiftPct,
                    fin_split_id_array: this.$refs.splitsToReversalGrid.getSelectedRows().map(split => ( split.id )),
                    commentary: this.editFormData.commentary,
                }
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-create-reversal`, reversalData);
                    tools.displaySuccessSave(this);
                    this.isModified = false;
                    this.msgItemModified = '';
                    this.$router.go(-1);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.baseUrl}/fin_reversal/${this.itemId}`);
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemId = parseInt(this.$route.params.reversalId);
            this.billId = parseInt(this.$route.query.billId);
            // if (!this.itemId) this.editFormData.time_of = (new Date()).toISOString();
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.$route.params.billId=`, this.$route.params.billId);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.currentUser=`, this.currentUser);
            if (this.itemId) {
                await this.getData();
            } else {
                // this.setBreadcrumb();
                if (this.billId) {
                    // this.editFormData.time_of = (new Date()).toISOString();
                    this.editFormData.time_of = moment().format('YYYY-MM-DD HH:mm');
                    await this.getBill();
                    await this.getSplitsToReversal();
                    await this.getProgramArray();
                }
                this.checkButtons();
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
        },
    }
</script>

<style>
</style>
