<!--
    Author: Eduard Grebenyukov
    Date: 2019-12-29
-->

<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: 'App',
        title: 'Heamis'
    }
</script>

<style lang="scss">
    // !!! this import does not work
    // @import 'assets/variables.scss';
    // @import 'assets/custom.scss';
    // @import '~bootstrap/scss/bootstrap.scss';
    // @import '~bootstrap-vue/src/index.scss';

</style>
