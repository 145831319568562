<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-06
-->

<template>
    <div>
        <Grid
            v-if="upcomingVisitArray.length > 0"
            :source="upcomingVisitArray"
            :config="upcomingVisitsGridConfig"
            @select-visit="visitSelected($event)"
        />

        <VisitContextMenu mode="client" ref="visitContextMenu" @refresh-status="getData()" />

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import Grid from '@/components/lib/regions/Grid';
    import VisitContextMenu from '@/components/pages/visits/VisitContextMenu';

    export default {
        name: 'UpcomingVisits',
    
        components: { Grid, VisitContextMenu },

        // =============== Data ===============
        data() {
            return {
                upcomingVisitArray: [],
                upcomingVisitsGridConfig: {
                    title: this.$t('component.UpcomingVisits.upcomingVisits'),
                    readonly: true,
                    columns: [
                        {
                            type:'icon', key:'icon', sortable:false,
                            $title:(entry) => { return entry.status_name }, align:'center',
                            $icon: entry => tools.getVisitIcon(entry),
                            $cellStyle: entry => `color:${tools.getVisitColor(entry)};`,
                        },
                        { type:'datetime', key:'time_of', header:this.$t('attribute.common.datetime_of') },
                        { type:'string', key:'status_name_short', header:this.$t('attribute.common.status'), },
                        {
                            type:'string', key:'client_fullname', header:this.$t('object.core_client'),
                            $cellStyle:(entry) => (entry.is_client ? 'color: #056bbf;' : null),
                            emit: 'select-visit',
                            $title:(entry) => ( entry.is_client ? '' : 'Не оформлен' ),
                            // $textTitle:(entry) => ( entry.is_client ? '' : 'Не оформлен' ),
                        },
                        { type:'string', key:'visit_type_name', header:this.$t('object.visit_type') },
                        { type:'string', key:'commentary', header:this.$t('attribute.common.commentary') },
                    ],
                    row: {
                        $style:(entry) => ( entry.is_client ? null : 'color:red;' ),
                        $onRightClick: (visit, evt) => {
                            this.showContextMenu(evt, visit);
                        },
                    },
                    order: [{ key:'time_of' }],
                    displayToolbar: false,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['setLastClientId', 'setLastVisitId']),

            async visitSelected(visit) {
                // console.log(`DEBUG: ${this.$options.name}.visitSelected() visit=`, visit);
                if (visit.core_client_id) {
                    await this.setLastClientId(visit.core_client_id);
                    await this.setLastVisitId(visit.id);
                    this.$router.push({ name: 'client-visits', params: { clientId: visit.core_client_id } });
                }
            },

            async getData() {
                try {
                    let currentDateStr = moment().format(this.format || moment.HTML5_FMT.DATE);
                    let visitFilter = tools.getFilterString({
                        where:[{ core_user_id:this.currentUser.id }, { visit_date:currentDateStr }],
                        order:['time_of'],
                    });
                    this.upcomingVisitArray = (await this.authenticatedAxios.get(`${this.baseUrl}/v_visit${visitFilter}`)).data.rows;
                    this.$emit('refreshed', this.upcomingVisitArray.length > 0);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            showContextMenu(evt, visit) {
                this.$refs.visitContextMenu.show(evt, visit);
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            this.getData();
        }
    }
</script>

<style>
</style>
