<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-12
-->

<template>
    <transition name="modal">
        <div class="modal-mask" ref="modalMask" @keydown.stop="onKey($event)" tabindex="0" >
            <div ref="modalWrapper" class="modal modal-open show modal-draggable" tabindex="-1" role="dialog" aria-modal="true">
                <div
                    class="modal-dialog modal-dialog-scrollable"
                    role="document"
                    :style="{ minWidth:minWidth, maxWidth:maxWidth, width:width, minHeight:minHeight, maxHeight:maxHeight, height:height}"
                >
                    <div ref="draggableContainer" class="modal-content" v-resize:debounce.50="onResizeModal">
                        <div class="modal-header" @mousedown="dragMouseDown">
                            <h5 class="modal-title"><slot name="header">{{header}}</slot></h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancel')" >
                                <span class="fas fa-times" aria-hidden="true"></span>
                            </button>
                        </div>
                        <!-- <form> -->
                            <div class="modal-body">
                                <div class="modal-body-spacer"></div>
                                <slot></slot>
                            </div>
                            <div class="modal-footer flex-row-reverse justify-content-start">
                                <button v-if="buttons && buttons.includes('close')" @click.prevent="$emit('cancel')" data-dismiss="modal" class="btn btn-sm btn-outline-secondary mr-1">{{ $t('lib.buttons.close') }}</button>
                                <button v-if="buttons && buttons.includes('cancel')" @click.prevent="$emit('cancel')" data-dismiss="modal" class="btn btn-sm btn-outline-secondary mr-1">{{ $t('lib.buttons.cancel') }}</button>
                                <button v-if="buttons && buttons.includes('ok')" @click.prevent="$emit('ok')" class="btn btn-sm btn-primary mr-1">{{ $t('lib.buttons.ok') }}</button>
                                <button v-if="buttons && buttons.includes('no')" @click.prevent="$emit('no')" class="btn btn-sm btn-outline-secondary mr-1">{{ $t('lib.buttons.no') }}</button>
                                <button v-if="buttons && buttons.includes('yes')" @click.prevent="$emit('yes')" class="btn btn-sm btn-primary mr-1">{{ $t('lib.buttons.yes') }}</button>
                                <button v-if="buttons && buttons.includes('save')" @click.prevent="$emit('save')" class="btn btn-sm btn-primary mr-1">{{ $t('lib.buttons.save') }}</button>
                                <button v-if="buttons && buttons.includes('delete')" @click.prevent="$emit('delete')" class="btn btn-sm btn-outline-danger mr-1">{{ $t('lib.buttons.delete') }}</button>
                                <button type="button" v-for="button in customButtons" @click.prevent="$emit(button.event)" :class="button.class" :style="button.style" :key="button.key" :disabled="button.disabled" >{{button.label}}</button>
                                <slot name="buttons"></slot>
                            </div>
                        <!-- </form> -->
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import resize from 'vue-resize-directive';

    export default {
        name: 'ModalWindow',

        props: {
            header: String,
            minWidth: String,
            maxWidth: String,
            minHeight: String,
            maxHeight: String,
            width: String,
            height: String,
            buttons: Array,
        },

        // =============== Directives ===============
        directives: {
            resize,
        },

        // =============== Data ===============
        data() {
            return {
                dragPosition: {
                    clientX: undefined,
                    clientY: undefined,
                    movementX: 0,
                    movementY: 0
                }
            }
        },

        // =============== Computed ===============
        computed: {
            customButtons() {
                if (!this.buttons)
                    return [];
                let b = [];
                this.buttons.forEach((button) => {
                    if (typeof button === 'object') {
                        b.push(button);
                    }
                });
                return b;
            }
        },

        // =============== Methods ===============
        methods: {
            refocusParentModal() {
                // focus on parent ModalWindow to allow catch key events
                let ptr = this;
                while(ptr.$parent) {
                    ptr = ptr.$parent;
                    if (ptr.$options.name === this.$options.name) {
                        // found parent ModalWindow
                        ptr.focusModal();
                    }
                }
            },

            onKey(evt) {
                // console.log(`DEBUG: ${this.$options.name}.onKey(${this._uid}, ${evt.key})`);
                if (evt.key === 'Escape') {
                    this.refocusParentModal();
                    this.$emit('cancel');
                }
                if (evt.key === 'Enter') {
                    this.refocusParentModal();
                    this.$emit('ok');
                }
            },

            focusModal() {
                // console.log(`DEBUG: ${this.$options.name}.focusModal(${this._uid})`);
                this.$refs.modalMask.focus();
                // console.log(`DEBUG: ${this.$options.name}.mounted(${this._uid}) focused:`, document.activeElement);
            },

            dragMouseDown(event) {
                event.preventDefault();
                // get the mouse cursor position at startup:
                this.dragPosition.clientX = event.clientX;
                this.dragPosition.clientY = event.clientY;
                document.onmousemove = this.elementDrag;
                document.onmouseup = this.closeDragElement;
            },
            elementDrag(event) {
                event.preventDefault()
                this.dragPosition.movementX = this.dragPosition.clientX - event.clientX;
                this.dragPosition.movementY = this.dragPosition.clientY - event.clientY;
                this.dragPosition.clientX = event.clientX;
                this.dragPosition.clientY = event.clientY;
                // set the element's new position:
                this.$refs.draggableContainer.style.top = `${this.$refs.draggableContainer.offsetTop - this.dragPosition.movementY}px`;
                this.$refs.draggableContainer.style.left = `${this.$refs.draggableContainer.offsetLeft - this.dragPosition.movementX}px`;
            },
            closeDragElement() {
                document.onmouseup = null;
                document.onmousemove = null;
            },
            centerModal() {
                // centering by js because if centering by css, it stops dragging
                let windowHeight = window.innerHeight;
                let modalHeight = this.$refs.draggableContainer.clientHeight;
                this.$refs.draggableContainer.style.top = `calc(${(windowHeight - modalHeight) / 2}px - 1.75rem)`; // minus modalWrapper.marginTop
            },
            onResizeModal() {
                // console.log(`DEBUG: ${this.$options.name}.onResizeModal()`);
                this.centerModal();
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(${this._uid})`, this);
            // console.log(`DEBUG: ${this.$options.name}.mounted(${this._uid})`, this.buttons);
            this.centerModal();
            this.focusModal();
            setTimeout(this.focusModal, 0);
            // this.$nextTick(() => {
            //     this.focusModal();
            // });
            // setTimeout(() => {
            //         console.log(`DEBUG: ${this.$options.name}.mounted(${this._uid}) focused:`, document.activeElement);
            //     },
            //     1000
            // );
            // document.body.addEventListener('keyup', evt => {
            // document.addEventListener('keydown', evt => {
            //     console.log(`evt.key=`, evt.key);
            //     // if (evt.keyCode === 27) {
            //     if (evt.key === 'Escape') {
            //         evt.stopPropagation();
            //         this.$emit('cancel');
            //     }
            //     // if (evt.keyCode === 13) {
            //     if (evt.key === 'Enter') {
            //         evt.stopPropagation();
            //         this.$emit('ok');
            //     }
            // });
        },
    }
</script>

<style>
    .modal-mask {
        position: fixed;
        z-index: 2000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .2s linear;
    }

    .modal.show {
        display: block;
    }

    .modal-draggable .modal-content {
        position: absolute;
    }

    .modal-header {
        background-color: #e9ecef;
        cursor: move;
    }
    .modal-title {
        font-weight: bold;
    }

    .modal-header .close:hover {
        color: #dc3545;
        opacity: 1;
    }

    .modal-draggable .modal-body {
        padding-top: 0;
    }

    .modal-body-spacer {
        height: 1rem;
    }

    .modal-footer {
        background-color: #e9ecef;
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-content,
    .modal-leave-active .modal-content {
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
</style>
