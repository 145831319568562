<!--
    Author: Eduard Grebenyukov
    Date: 2020-02-22
-->

<template>
    <ModalWindow
        width="90%"
        max-width="600px"
        :buttons="buttons"
        @cancel="closeModal"
        @delete="askDeleteItem"
    >
        <span slot="header" >{{ $t('app.edit') }}</span>

        <div class="container">
            <div class="row">
                <div class="col-12-sm col-md">
                    <FieldSelect :label="$t('object.core_building')" :value.sync="editFormData.core_building_id" readonly required source="core_building" />
                </div>
            </div>
        </div>

        <WarningDelete v-if="isDisplayWarningDelete" @process="$event ? processDeleteItem() : cancelDeleteItem()" />

    </ModalWindow>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import WarningDelete from '@/components/lib/regions/WarningDelete';
    import FieldSelect from '@/components/lib/controls/FieldSelect';

    export default {
        name: 'ClientBuildingEdit',

        components: {
            ModalWindow, WarningDelete,
            FieldSelect
        },

        // =============== Props ===============
        props: {
            id: Number,
            clientId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                editFormData: {
                    // id: null,
                    core_client_id: null,
                    core_building_id: null,
                },
                buttons: [ 'delete', 'close' ],
                isDisplayWarningDelete: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Methods ===============
        methods: {
            async getData() {
                try {
                    let response = await this.authenticatedAxios.get(`${this.baseUrl}/core_client_building/${this.itemId}`);
                    // console.log(`DEBUG: ${this.$options.name}.getData() data=`, response.data);
                    this.editFormData = response.data;

                    this.isModified = false;
                    this.msgItemModified = '';
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            askDeleteItem() {
                this.isDisplayWarningDelete = true;
            },
            async processDeleteItem() {
                try {
                    await this.authenticatedAxios.delete(`${this.baseUrl}/core_client_building/${this.editFormData.id}`);
                    tools.displaySuccessDelete(this.$parent);
                    this.$emit('close-modal', true);
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },
            cancelDeleteItem() { this.isDisplayWarningDelete = false },

            closeModal() {
                this.processClose();
            },
            processClose() { this.$emit('close-modal', false) },
        },

        // =============== Life cycle ===============
        beforeMount() {
            this.itemId = this.id;
            this.editFormData.core_client_id = this.clientId;
        },

        async mounted() {
            if (this.itemId) {
                await this.getData();
                this.buttons = [ 'delete', 'close' ];
            } else {
                this.buttons = [ 'close' ];
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
        },
    }
</script>

<style>
</style>
