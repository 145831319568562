/*
    Author: Eduard Grebenyukov
    Date: 2020-08-11
*/

import { baseUrl } from '@/store/constants';

async function setVisitStatus(ctx, rec_id, status_code) {
    // !!! move it to Vuex !!!
    // console.log(`DEBUG: tools.setVisitStatus(status_code)`);
    return await ctx.authenticatedAxios.post(`${ctx.baseUrl}/schedule-set-status`, { visit_rec_id: rec_id, status_code: status_code });
}

async function rollbackVisitStatus(ctx, rec_id, status_code) {
    // !!! move it to Vuex !!!
    return await ctx.authenticatedAxios.post(`${ctx.baseUrl}/schedule-rollback-status`, { visit_rec_id: rec_id, status_code: status_code });
}

function getVisitIcon(visit) {
    // !!! move it to Vuex !!!
    if (visit.visit_event_type_is_inner) {
        return 'fas fa-procedures';
    }
    switch (visit.status_code) {
        case 'planned': return '';
        case 'confirmed': return 'fas fa-user-check';
        case 'arrived': return 'fas fa-user-circle';
        case 'started': return 'fas fa-play';
        case 'completed': return 'fas fa-stop';
        case 'closed': return 'fas fa-check-square';
        case 'canceled': return 'fas fa-times-circle';
        default: return '';
    }
}

function getVisitColor(visit) {
    // !!! move it to Vuex !!!
    switch (visit.status_code) {
        case 'planned': return '';
        case 'confirmed': return '#057b6b'; //#a5a56c, #667b66
        case 'arrived': return '#007bff';
        case 'started': return '#056bbf'; //#007bff
        case 'completed': return '#007b00';
        case 'closed': return '#007b00';
        case 'canceled': return '#ff0000';
        default: return '';
    }
}

async function getVisitStatus(ctx, visitId) {
    return (await ctx.authenticatedAxios.get(`${baseUrl}/visit_rec/${visitId}/status`)).data;
}

function isScheduleEditable(visitStatusCode) {
    return ['planned', 'confirmed', 'arrived'].includes(visitStatusCode);
}

function getVisitFromExtended(extendedVisit) {
    return {
        id: extendedVisit.id,
        base_visit_id: extendedVisit.base_visit_id,
        core_workplace_id: extendedVisit.core_workplace_id,
        core_client_id: extendedVisit.core_client_id,
        visit_type_id: extendedVisit.visit_type_id,
        // visit_event_id: extendedVisit.visit_event_id,
        time_of: extendedVisit.time_of,
        duration: extendedVisit.duration,
        lastname: extendedVisit.lastname,
        firstname: extendedVisit.firstname,
        patronymic: extendedVisit.patronymic,
        commentary: extendedVisit.commentary,
        // workplace_final_id: extendedVisit.workplace_final_id,
        phone_num: extendedVisit.phone_num,
    }
}

async function moveScheduleRec(ctx, visit, slot) {
    // !!! this function can be sync !!!
    let newRec = getVisitFromExtended(visit);
    let clipboardContent = {
        method: 'cut',
        objectName: 'schedule',
        objectData: newRec,
    };
    ctx.$router.push({ name: 'schedule-new', params: {
        clipboard: clipboardContent,
        coreWorkplaceId: slot.coreWorkplaceId,
        timeOf: slot.timeOf
    }});
}

async function openClientVisitList(ctx, clientId, visitId) {
    await ctx.$store.dispatch('setLastClientId', clientId);
    if (visitId) await ctx.$store.dispatch('setLastVisitId', visitId);
    ctx.$router.push({ name: 'client-visits', params: { clientId: clientId } });
}

export default {
    setVisitStatus, rollbackVisitStatus,
    getVisitIcon, getVisitColor,
    getVisitStatus, isScheduleEditable,
    getVisitFromExtended, moveScheduleRec,
    openClientVisitList,
}
