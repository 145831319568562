<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-15
-->

<template>
    <ModalWindow
        width="90%"
        max-width="1200px"
        :buttons="buttons"
        @cancel="closeModal"
        @save="saveData"
        @delete="askDeleteItem"
    >
        <span slot="header" :title="msgItemModified">{{ $t('object.fin_beneficiary') }} {{ isModified? '*' : '' }}</span>

        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <FieldPopupList
                        id="fin_program_id"
                        :label="$t('object.fin_program')"
                        :value.sync="editFormData.fin_program_id"
                        source="fin_program"
                        showEmptyRow
                        maxWidth="600px"
                        :listConfig="programListConfig"
                        @modified="itemModified"
                    />
                </div>
                <div class="col-sm-12 col-md-3">
                    <FieldString :label="$t('attribute.fin_beneficiary.beneficiary_num')" :value.sync="editFormData.beneficiary_num" @modified="itemModified" />
                </div>
                <div class="col-sm-12 col-md-3">
                    <FieldNumber :label="$t('attribute.fin_beneficiary.beneficiary_limit_sum')" :value.sync="editFormData.beneficiary_limit_sum" @modified="itemModified" />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <FieldDate :label="$t('attribute.common.date_from')" :value.sync="editFormData.date_from" @modified="itemModified" required />
                </div>
                <div class="col-sm-12 col-md-4">
                    <FieldDate :label="$t('attribute.common.date_to')" :value.sync="editFormData.date_to" @modified="itemModified" />
                </div>
                <div v-if="editFormData.id" class="col-sm-12 col-md-4">
                    <FieldDate :label="$t('attribute.common.date_cancel')" :value.sync="editFormData.date_cancel" @modified="itemModified" />
                </div>
            </div>
        </div>

        <WarningClose v-if="isDisplayWarningClose" @process="$event ? processClose() : cancelClose()" />
        <WarningDelete v-if="isDisplayWarningDelete" @process="$event ? processDeleteItem() : cancelDeleteItem()" />

    </ModalWindow>
</template>

<script>
    import { mapGetters } from 'vuex';
    //import { mapState, mapActions } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import WarningClose from '@/components/lib/regions/WarningClose';
    import WarningDelete from '@/components/lib/regions/WarningDelete';
    import FieldPopupList from '@/components/lib/controls/FieldPopupList';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldNumber from '@/components/lib/controls/FieldNumber';

    export default {
        name: 'ClientBeneficiaryEdit',

        components: {
            ModalWindow, WarningClose, WarningDelete,
            FieldPopupList, FieldDate, FieldString, FieldNumber,
        },

        // =============== Props ===============
        props: {
            id: Number,
            clientId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                isModified: false,
                msgItemModified: '',
                editFormData: {
                    // id: null,
                    core_client_id: null,
                    fin_program_id: null,
                    date_from: null,
                    date_to: null,
                    date_cancel: null,
                    beneficiary_limit_sum: null,
                    beneficiary_num: null,
                },
                programListConfig: {
                    readonly: true,
                    columns: [
                        { type:'string', key:'name', header:this.$t('object.fin_program') },
                     ],
                    order: [ 'name' ],
                },
                oldFormData: {},
                isDisplayWarningDelete: false,
                isDisplayWarningClose: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Methods ===============
        methods: {
            async getData() {
                try {
                    this.editFormData = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_beneficiary/${this.itemId}`)).data;
                    this.isModified = false;
                    this.msgItemModified = '';
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                if (this.isModified) {
                    try {
                        if (this.editFormData.id) {
                            // Update
                            await this.authenticatedAxios.put(`${this.baseUrl}/fin_beneficiary/${this.editFormData.id}`, this.editFormData);
                        } else {
                            // New
                            await this.authenticatedAxios.post(`${this.baseUrl}/fin_beneficiary`, this.editFormData);
                        }
                        tools.displaySuccessSave(this.$parent);
                        this.$emit('close-modal', true);
                    } catch (err) {
                        tools.displayApiError(this.$parent, err);
                    }
                } else {
                    // no changes
                    this.$emit('close-modal', false);
                }
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified()`);
                if (JSON.stringify(this.oldFormData) === JSON.stringify(this.editFormData)) {
                    this.isModified = false;
                    this.msgItemModified = '';
                } else {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                }
            },

            askDeleteItem() {
                this.isDisplayWarningDelete = true;
            },
            async processDeleteItem() {
                try {
                    await this.authenticatedAxios.delete(`${this.baseUrl}/fin_beneficiary/${this.itemId}`);
                    tools.displaySuccessDelete(this.$parent);
                    this.$emit('close-modal', true);
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },
            cancelDeleteItem() { this.isDisplayWarningDelete = false },

            closeModal() {
                if (this.isModified) {
                    this.isDisplayWarningClose = true;
                } else {
                    this.processClose();
                }
            },
            processClose() { this.$emit('close-modal', false) },
            cancelClose() { this.isDisplayWarningClose = false },

        },

        // =============== Life cycle ===============
        beforeMount() {
            this.itemId = this.id;
            this.editFormData.core_client_id = this.clientId;
        },

        async mounted() {
            if (this.itemId) {
                await this.getData();
                this.buttons = ['save', 'delete', 'close'];
            } else {
                this.buttons = ['save', 'close'];
                this.editFormData.date_from = moment().format('YYYY-MM-DD');
                this.editFormData.date_to = this.editFormData.date_from;
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
        },
    }
</script>

<style>
</style>
