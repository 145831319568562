<!--
    Author: Eduard Grebenyukov
    Date: 2020-03-22
-->

<!--
    keyId
    keyLabel
-->

<template>
    <ul class="nav nav-tabs">
        <li v-for="listItem in tabsData" :key="`navigation-tabs-` + listItem[keyId||'id']" class="nav-item" >
            <router-link :to="listItem.to || listItem.href" :class="{ 'nav-link':true, active:listItem[keyId||'id'] == itemData, disabled:disabled }" >
                {{listItem[keyLabel||'name']}}
            </router-link>
        </li>
    </ul>
</template>

<script>
    import { mapGetters } from 'vuex';
    import tools from '@/components/lib/tools';

    // const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'NavigationTabs',

        // =============== Props ===============
        props: {
            id: String,
            value: [String, Number],
            defaultValue: [String, Number],
            source: [String, Array],
            keyId: String,
            keyLabel: String,
            // textEmit: String,
            // textOnClick: String,
            // textClass: String,
            // textStyle: String,
            // saveState: String,
            disabled: Boolean,
        },

        // =============== Data ===============
        data() {
            return {
                controlId: null, // internal control ID, can be overwritten by props.id
                tabsData: [],
                itemData: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Watch ===============
        watch: {
            value() {
                this.itemData = this.value;
                // console.log(`DEBUG: NavigationTabs.watch.value this.value=`, this.value);
            },
            source() {
                this.getData();
            }
        },

        // =============== Methods ===============
        methods: {
            async getData() {
                if (typeof this.source === 'string') {
                    // console.log(`DEBUG: NavigationTabs.getData url=`, `${this.baseUrl}/${this.source}`);
                    try {
                        this.tabsData = (await this.authenticatedAxios.get(`${this.baseUrl}/${this.source}`)).data.rows;
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else if (typeof this.source === 'object' && Array.isArray(this.source)) {
                    this.tabsData = this.source;
                } else {
                    // Unknown object, set empty array
                    this.tabsData = [];
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemData = this.value;
            // // console.log(`DEBUG: NavigationTabs.beforeMount() this.itemData=`, this.itemData, ', this.defaultValue=', this.defaultValue);
            // if (!this.itemData && this.saveState) {
            //     // Restore stored value
            //     try {
            //         let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
            //         // console.log(`DEBUG: NavigationTabs.beforeMount() response=`, response);
            //         let item = response.data.rows[0];
            //         if (item) this.itemSelected(item.value);
            //     } catch(err) {
            //         tools.displayApiError(this);
            //     }
            // }
            // if (!this.itemData && typeof this.defaultValue != 'undefined' && this.defaultValue !== null) {
            //     this.itemSelected(this.defaultValue);
            //     // console.log(`DEBUG: NavigationTabs.beforeMount() after default this.itemData=`, this.itemData, ', this.defaultValue=', this.defaultValue);
            // }
        },
        async mounted() {
            this.getData();
        },
    }
</script>

<style>
</style>
