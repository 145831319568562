<!--
    Author: Eduard Grebenyukov
    Date: 2020-12-15
-->

<template>
    <div>
        <div class="btn-toolbar" style="padding: 0 4px 4px 0;" role="toolbar">
            <div class="h6">{{ $t('Contacts.phones') }}</div>
            <div @click="addItem()" class="btn btn-warning btn-sm ml-2" :title="$t('app.add')">
                <i class="fas fa-plus"></i>
            </div>
        </div>
        <Grid ref="grid" :config="gridConfig" @add-item="addItem()" @edit-item="editItem($event.id)" />
        <PersonPhoneEdit v-if="isDisplayModalEdit" @close-modal="closeModalEdit($event)" :id="phoneId" :personId="personId" />
    </div>
</template>

<script>
    import Grid from '@/components/lib/regions/Grid';
    import PersonPhoneEdit from './PersonPhoneEdit';

    export default {
        name: 'PersonPhones',

        components: {
            Grid, PersonPhoneEdit,
        },

        // =============== Props ===============
        props: {
            personId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                isDisplayModalEdit: false,
                phoneId: null,
                gridConfig: {
                    apiUrl: null,
                    filter: null,
                    // title: this.$t('Contacts.phones'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'core_person_id', header:this.$t('object.core_client'), required:true },
                        { type:'string', key:'phone_num', header:this.$t('attribute.common.phone') },
                        {
                            type: 'icon', key:'is_notification_sign', style:"color: #056bbf;",
                            $icon:(entry) => (entry.is_notification ? 'fas fa-bell' : ''),
                            title: this.$t('Contacts.is_notification'),
                        },
                    ],
                    order: [ 'id' ],
                    displayToolbar: false,
                    buttons: ['edit'],
                    displayPagination: false,
                },
            }
        },

        // =============== Watch ===============
        watch: {
            personId() {
                this.setGridSettings();
            },
        },

        // =============== Methods ===============
        methods: {
            setGridSettings() {
                let filterPerson = { where:[
                    { core_person_id:(this.personId ? this.personId : null) },
                    { core_org_id:null},
                    { core_workplace_id:null},
                ]};
                this.gridConfig.filter = filterPerson;
                this.gridConfig.apiUrl = `core_phone`;
                this.$refs.grid.refresh();
            },

            addItem() {
                this.phoneId = null;
                this.isDisplayModalEdit = true;
            },

            editItem(phoneId) {
                // console.log(`DEBUG: ${this.$options.name}.editItem(${phoneId})`);
                this.phoneId = phoneId;
                this.isDisplayModalEdit = true;
            },

            closeModalEdit(isRefresh) {
                // console.log(`DEBUG: ${this.$options.name}.closeModalEdit()`);
                this.isDisplayModalEdit = false;
                if (isRefresh) this.$refs.grid.refresh();
            },

            async refresh() {
                await this.$refs.grid.refresh();
            },

        },

        // =============== Life cycle ===============
        async mounted() {
            this.setGridSettings();
        },
    }
</script>

<style>
</style>
