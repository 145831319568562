<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-09
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>
        <!-- <form> -->
        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm-12 col-xl-8">
                    <ModalWindow v-if="isDisplayWarningDelete" minWidth="300px" :buttons="['ok', 'cancel']" @ok="processDeleteItem(true)" @cancel="processDeleteItem(false)" >
                        {{ $t('component.TaskEdit.confirmDelete') }}
                    </ModalWindow>

                    <div class="form-row">
                        <div class="col-sm">
                            <FieldString :label="$t('attribute.common.status')" :value.sync="otherData.status_name" disabled />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm">
                            <FieldString id='initiator' :label="$t('attribute.common.initiator')" disabled :value.sync="otherData.initiator_name" />
                        </div>
                        <div class="col-md-6">
                            <FieldString id='performer' :label="$t('attribute.common.performer')" disabled :value.sync="otherData.performer_name" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12 col-lg-7">
                            <FieldPopupList
                                id="core_client_id"
                                :label="$t('object.core_client')"
                                :value.sync="editFormData.core_client_id"
                                source="v_client"
                                keyLabel="fullname"
                                showEmptyRow
                                :listConfig="clientListConfig"
                                :readonly="!isFormEditable"
                                maxWidth="1000px"
                                @modified="itemModified"
                            >
                                <button slot="buttons"
                                    v-if="editFormData.core_client_id"
                                    class="btn btn-outline-secondary"
                                    :title="$t('Visit.open_visit')"
                                    @click.prevent="openClient()"
                                >
                                    <i class="fas fa-id-card"></i>
                                </button>
                            </FieldPopupList>
                        </div>
                        <div class="col-md-12 col-lg-5">
                            <FieldSelect
                                :label="$t('object.core_building')"
                                :value.sync="editFormData.core_building_id"
                                source="core_building"
                                required
                                :readonly="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12 col-lg-6">
                            <FieldSelect
                                :label="$t('enum.task_type')"
                                :value.sync="editFormData.task_type_id"
                                source="enum/task_type"
                                required
                                :readonly="!isFormEditable"
                                @modified="taskTypeModified"
                            />
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3">
                            <FieldDatetime
                                :label="$t('attribute.common.time_start')"
                                :value.sync="editFormData.time_start"
                                required
                                :readonly="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3">
                            <FieldDatetime
                                :label="$t('attribute.common.time_end')"
                                :value.sync="editFormData.time_end"
                                required
                                :readonly="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm">
                            <FieldString
                                :label="$t('attribute.common.name')"
                                :value.sync="editFormData.name"
                                required
                                :readonly="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm">
                            <FieldText
                                :label="$t('attribute.common.description')"
                                :value.sync="editFormData.description"
                                :readonly="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm">
                            <FieldText
                                :label="$t('attribute.task_rec.solution')"
                                :value.sync="editFormData.solution"
                                :readonly="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                    </div>
                    <div class="row" v-if="editFormData.core_client_id">
                        <div class="col">
                            <ClientBeneficiary :clientId="editFormData.core_client_id" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-xl-4">
                    <!-- Right sidebar -->
                    <div class="row" v-if="editFormData.id">
                        <div class="col">
                            <button v-if="isTaskRunnable()" @click.prevent="runTask()" class="btn btn-outline-success btn-block">{{ $t('component.TaskEdit.startTask') }}</button>
                            <button v-if="isTaskCompletable()" @click.prevent="endTask()" class="btn btn-outline-success btn-block">{{ $t('component.TaskEdit.endTask') }}</button>

                            <button @click.prevent="isDisplayUserList = true" class="btn btn-outline-primary btn-block">{{ $t('component.TaskEdit.assignToUser') }}</button>
                            <button @click.prevent="isDisplayRoleList = true" class="btn btn-outline-primary btn-block">{{ $t('component.TaskEdit.assignToRole') }}</button>

                            <button v-if="editFormData.core_client_id" @click.prevent="createSchedule()" class="btn btn-warning btn-block">{{ $t('component.TaskEdit.createSchedule') }}</button>
                            <!-- <button v-if="editFormData.core_client_id" @click.prevent="saveData()" class="btn btn-outline-secondary btn-block">{{ $t('component.TaskEdit.showClientSchedule') }}</button> -->
                        </div>
                    </div>
                    <div class="row mt-2" v-if="editFormData.core_client_id" >
                        <div class="col">
                            <ClientInfo :clientId="editFormData.core_client_id" />
                        </div>
                    </div>
                    <div class="row mt-2" v-if="editFormData.id">
                        <div class="col">
                            <Grid :config="taskHistoryConfig" :source="taskHistoryArray" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- </form> -->

        <ModalWindow v-if="isDisplayUserList" width="600px" max-width="600px" max-height="90vh" @cancel="isDisplayUserList = false" :header="$t('object.core_user')" >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="userGrid" :config="userGridConfig" @item-selected="userSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>

        <ModalWindow v-if="isDisplayRoleList" width="600px" max-width="600px" max-height="90vh" @cancel="isDisplayRoleList = false" :header="$t('object.core_role')" >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="roleGrid" :config="roleGridConfig" @item-selected="roleSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    // import { mapActions } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';
    import ClientInfo from '@/components/pages/clients/ClientInfo';
    import ClientBeneficiary from '@/components/pages/clients/ClientBeneficiary';
    import ModalWindow from '@/components/lib/regions/ModalWindow';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldString from '@/components/lib/controls/FieldString';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldDatetime from '@/components/lib/controls/FieldDatetime';
    import FieldPopupList from '@/components/lib/controls/FieldPopupList';
    import FieldText from '@/components/lib/controls/FieldText';

    export default {
        name: 'TaskEdit',

        components: {
            TemplatePageStandard, Grid, ClientInfo, ClientBeneficiary, ModalWindow,
            ButtonClose, ButtonSave, ButtonDelete,
            FieldString, FieldSelect, FieldDatetime, FieldPopupList, FieldText,
        },

        mixins: [ tools.warningCloseMixin ],

        // // =============== Props ===============
        // props: {
        //     clientId: Number,
        //     visitId: Number,
        // },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                // clientId: null,
                // visitId: null,
                buttons: [],
                msgItemModified: '',
                editFormData: {
                    // id: null,
                    parent_id: null,
                    task_type_id: null,
                    core_client_id: null,
                    visit_rec_id: null,
                    core_building_id: null,
                    initiator_user_id: null,
                    initiator_workplace_id: null,
                    time_start: null,
                    time_end: null,
                    name: null,
                    description: null,
                    solution: null,
                },
                oldFormData: {},
                otherData: {
                    // performer_role_id: null,
                    // performer_user_id: null,
                    // performer_workplace_id: null,
                    initiator_name: null,
                    performer_name: null,
                    // client_fullname: null,
                    // client_shortname: null,
                    status_code: null,
                    status_name: null,
                    status_name_short: null,
                },
                isDisplayWarningDelete: false,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.tasks'), to: '/tasks' },
                    { text: this.$t('object.task_rec') },
                ],
                clientListConfig: {
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'string', key:'code', header:this.$t('attribute.common.code') },
                        { type:'string', key:'fullname', header:this.$t('object.core_client') },
                        { type:'select', key:'sex', header:this.$t('attribute.core_person.sex'), source: this.sexKind, keyId: 'code', keyLabel: 'name_short' },
                        { type:'date', key:'dob', header:this.$t('attribute.core_person.dob') },
                        { type:'string', key:'phones', header:this.$t('Contacts.phones') },
                        { type:'string', hidden:true, key:'phones_stripped', header:this.$t('Contacts.phones') },
                     ],
                    order: [ 'fullname' ],
                    displayToolbar: true,
                },
                taskHistoryArray: [],
                taskHistoryConfig: {
                    title: this.$t('app.history'),
                    readonly: true,
                    columns: [
                        {
                            type:'icon', key:'icon', sortable:false,
                            $title:(entry) => { return entry.status_name }, align:'center',
                            $icon: entry => tools.getTaskIcon(entry),
                            $cellStyle: entry => `color:${tools.getTaskColor(entry)};`,
                        },
                        { type:'datetime', key:'time_from', header:this.$t('attribute.common.datetime_of'), sortable:false },
                        { type:'string', key:'performer_name', header:this.$t('attribute.common.performer'), sortable:false },
                        { type:'string', key:'status_name', header:this.$t('attribute.common.status'), sortable:false },
                     ],
                    // order: [ 'fullname' ],
                    displayToolbar: false,
                },
                isDisplayUserList: false,
                userGridConfig: {
                    apiUrl: 'v_user',
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' },
                        { type:'string', key:'fullname', header:this.$t('attribute.core_person.fullname') },
                    ],
                    order: [ 'fullname' ],
                    row: { emit: "item-selected", style: "cursor: pointer;" },
                },
                isDisplayRoleList: false,
                roleGridConfig: {
                    apiUrl: null,
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                    ],
                    order: [ 'name' ],
                    row: { emit: "item-selected", style: "cursor: pointer;" },
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),
            isFormEditable() {
                return [null, 'new', 'started'].includes(this.otherData.status_code);
            },
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['setClientToSchedule', 'setLastClientId', 'setLastVisitId']),

            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            getPerformerRoles() {
                this.roleGridConfig.apiUrl = `task-type-performer-roles/${this.editFormData.task_type_id}`;
                if (this.$refs.roleGrid) this.$refs.roleGrid.refresh();
            },

            async getData() {
                try {
                    this.editFormData = (await this.authenticatedAxios.get(`${this.baseUrl}/task_rec/${this.itemId}`)).data;
                    this.otherData = (await this.authenticatedAxios.get(`${this.baseUrl}/v_task/${this.itemId}`)).data;
                    this.taskHistoryArray = (await this.authenticatedAxios.get(`${this.baseUrl}/task-history/${this.itemId}`)).data.rows;
                    this.isModified = false;
                    this.msgItemModified = '';
                    // this.breadcrumb[this.breadcrumb.length - 1].text = response.data.name;
                    this.itemId = this.editFormData.id;
                    this.getPerformerRoles();
                    this.checkButtons();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update task
                            // let response = await this.authenticatedAxios.patch(this.apiUrl, this.editFormData);
                            await this.authenticatedAxios.put(`${this.baseUrl}/task_rec/${this.editFormData.id}`, this.editFormData);
                            tools.displaySuccessSave(this);
                            this.getData();
                        } else {
                            // New task created
                            // console.log(`DEBUG: ${this.$options.name}.saveData() new task`);
                            let response = await this.authenticatedAxios.post(`${this.baseUrl}/task`, this.editFormData); // not task_rec because of task_status!
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response.data=`, response.data);
                            this.itemId = response.data.id;
                            tools.displaySuccessSave(this);
                            await this.getData();
                            this.$router.replace({ name: 'tasks/task-edit', params: { taskId:this.itemId } });
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified(), this.editFormData=`, this.editFormData);
                if (JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData) ) {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.isModified = false;
                    this.msgItemModified = '';
                }
            },

            taskTypeModified() {
                this.getPerformerRoles();
                this.itemModified();
            },

            askDeleteItem() {
                this.isDisplayWarningDelete = true;
            },
            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.baseUrl}/task/${this.itemId}`); // not task_rec because of task_status!
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },
            // cancelDeleteItem() { this.isDisplayWarningDelete = false },

            async openClient() {
                await this.setLastClientId(this.editFormData.core_client_id);
                if (this.editFormData.visit_rec_id) {
                    await this.setLastVisitId(this.editFormData.visit_rec_id);
                    this.$router.push({ name: 'client-visits', params: { clientId:this.editFormData.core_client_id } });
                } else {
                    this.$router.push({ name: 'client-edit', params: { id:this.editFormData.core_client_id } });
                }
            },

            isTaskRunnable() {
                return tools.isTaskRunnable(this.otherData, this.currentUser);
            },

            isTaskCompletable() {
                return tools.isTaskCompletable(this.otherData, this.currentUser);
            },

            async runTask() {
                // console.log(`DEBUG: ${this.$options.name}.runTask(${this.editFormData.id}) this.currentUser=`, this.currentUser);
                try {
                    tools.runTask(this, this.editFormData.id, this.currentUser);
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async endTask() {
                // console.log(`DEBUG: ${this.$options.name}.endTask(${this.editFormData.id}) this.currentUser=`, this.currentUser);
                try {
                    tools.setTaskStatus(this, this.editFormData.id, 'completed');
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async userSelected(user) {
                try {
                    tools.setTaskPerformer(this, this.editFormData.id, { performer_user_id:user.id });
                    tools.displaySuccessSave(this);
                    this.isDisplayUserList = false;
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async roleSelected(role) {
                try {
                    tools.setTaskPerformer(this, this.editFormData.id, { performer_role_id:role.id });
                    tools.displaySuccessSave(this);
                    this.isDisplayRoleList = false;
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async createSchedule() {
                await this.saveData();
                await this.setClientToSchedule({
                    id:this.editFormData.core_client_id,
                    // lastname:null,
                    // firstname:null,
                    // patronymic:null,
                });
                this.$router.push({ name: 'schedules' });
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemId = parseInt(this.$route.params.taskId);
            if (this.$route.params.clientId) this.editFormData.core_client_id = parseInt(this.$route.params.clientId);
            if (this.$route.params.visitId) this.editFormData.visit_rec_id = parseInt(this.$route.params.visitId);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.$route.params=`, this.$route.params);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.editFormData=`, this.editFormData);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.currentUser=`, this.currentUser);
            if (this.itemId) {
                await this.getData();
            } else {
                this.editFormData.initiator_user_id = this.currentUser.id;
                this.editFormData.initiator_workplace_id = this.currentUser.currentWorkplace ? this.currentUser.currentWorkplace.id : null;
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
            this.checkButtons();
        },
    }
</script>

<style>
</style>
