/*
    Author: Eduard Grebenyukov
    Date: 2020-04-04
*/

import { i18n } from '@/locales';
import { mainMenu } from "./mainMenu";

function submenu(menu, code) {
    let sm = menu.find( item => { return item.code === code } );
    return sm ? sm.child : [];
}

const pageSubmenu = {
    submenu_finances: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.finances') },
        ],
        items: submenu(mainMenu, 'finances'),
    },
    submenu_crm: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.crm') },
        ],
        items: submenu(mainMenu, 'crm'),
    },
    submenu_store: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.store') },
        ],
        items: submenu(mainMenu, 'store'),
    },
    submenu_reports: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.reports') },
        ],
        items: submenu(mainMenu, 'reports'),
    },
    submenu_settings: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
        ],
        items: submenu(mainMenu, 'settings'),
    },
    submenu_settings_general: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings'), to: '/settings' },
            { text: i18n.t('MainMenu.settings-general') }
        ],
        items: submenu(submenu(mainMenu, 'settings'), 'settings.general'),
    },
    submenu_settings_rights: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings'), to: '/settings' },
            { text: i18n.t('MainMenu.settings-rights') }
        ],
        items: submenu(submenu(mainMenu, 'settings'), 'settings.rights'),
    },
    submenu_settings_visits: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings'), to: '/settings' },
            { text: i18n.t('MainMenu.settings-visits') }
        ],
        items: submenu(submenu(mainMenu, 'settings'), 'settings.visits'),
    },
    submenu_settings_med: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings'), to: '/settings' },
            { text: i18n.t('MainMenu.settings-med') }
        ],
        items: submenu(submenu(mainMenu, 'settings'), 'settings.med'),
    },
    submenu_settings_finance: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings'), to: '/settings' },
            { text: i18n.t('MainMenu.settings-finance') }
        ],
        items: submenu(submenu(mainMenu, 'settings'), 'settings.finance'),
    },
}

export { pageSubmenu };
