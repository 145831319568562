<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-11
-->

<template>
    <b-breadcrumb :items="items"/>
</template>

<script>
    export default {
        props: ['items'],
    }
</script>

<style>
    .breadcrumb {
        margin-bottom: 0;
        width: 100%;
    }

    .breadcrumb-item a {
        color: #6c757d;
    }
    .breadcrumb-item.active {
        font-weight: bold;
        color: #2c3e50;
    }
</style>