var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        'field-required': _vm.required,
        'form-group': true,
        'form-inline': _vm.labelPosition === 'before',
        'form-label-group': _vm.labelPosition === 'float'
    }},[(_vm.label && (_vm.labelPosition === 'above'))?_c('label',{staticClass:"field__label__above",attrs:{"for":_vm.id,"title":_vm.required ? _vm.$t('lib.required') : ''}},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('sup',{staticClass:"required-mark"},[_vm._v("*")]):_vm._e()]):_vm._e(),(_vm.label && (_vm.labelPosition === 'before'))?_c('label',{staticClass:"field__label__before",attrs:{"for":_vm.id,"title":_vm.required ? _vm.$t('lib.required') : ''}},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('sup',{staticClass:"required-mark"},[_vm._v("*")]):_vm._e()]):_vm._e(),(!_vm.label && (_vm.labelPosition === 'above'))?_c('div',{staticClass:"field-number__label-spacer"}):_vm._e(),_c('div',{staticClass:"input-group"},[(_vm.daysPrevNext)?_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button"},on:{"click":_vm.onPrev}},[_c('i',{staticClass:"fas fa-angle-left"})])]):_vm._e(),(!_vm.displayonly)?_c('DatePicker',{staticClass:"form-control",style:(("padding: 0; border: none; " + _vm.inputStyle)),attrs:{"id":_vm.id,"type":"date","value-type":_vm.dateFormat,"format":_vm.format || 'DD.MM.YYYY',"clearable":_vm.displayClearButton,"confirm":false,"input-class":{
                'form-control': true,
                'is-invalid': !!_vm.errorMessage,
                'field__required-empty': _vm.required && (!_vm.itemData || _vm.itemData == '')
            },"placeholder":_vm.placeholder,"disabled":_vm.disabled || _vm.readonly},on:{"input":function($event){return _vm.itemModified($event)},"change":function($event){return _vm.itemModified($event)},"focus":function($event){return _vm.itemFocused($event)}},model:{value:(_vm.itemData),callback:function ($$v) {_vm.itemData=$$v},expression:"itemData"}}):_vm._e(),(_vm.daysPrevNext)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button"},on:{"click":_vm.onNext}},[_c('i',{staticClass:"fas fa-angle-right"})])]):_vm._e()],1),(_vm.displayonly)?_c('span',{staticClass:"form-control-plaintext",class:_vm.textClass,style:(_vm.textStyle),on:{"click":function () {if (_vm.textEmit) { _vm.$emit(_vm.textEmit, _vm.itemData); } if (_vm.textOnClick) { _vm.eval(_vm.textOnClick, _vm.itemData); }}}},[_vm._v(" "+_vm._s(_vm.itemPresentation)+" ")]):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.errorMessage}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }