<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-01
-->

<template>
    <RenderedServices :isDental="true" />
</template>

<script>
    import RenderedServices from './RenderedServices';

    export default {
        name: 'RenderedServicesDental',
        components: { RenderedServices },
    }
</script>

<style scoped>

</style>
