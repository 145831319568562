<!--
    Author: Eduard Grebenyukov
    Date: 2022-01-27
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>
        <div id="page-content" class="form-float">
            <div class="form-row">
                <div class="col-sm col-md-3">
                    <FieldString
                        :label="$t('attribute.common.code')"
                        :value.sync="editFormData.code"
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldString
                        :label="$t('attribute.common.name')"
                        :value.sync="editFormData.name"
                        required
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldSelect
                        :label="$t('object.visit_event_type')"
                        :value.sync="editFormData.visit_event_type_id"
                        source="visit_event_type"
                        :showEmptyRow="true"
                        @modified="checkModified"
                    />
                </div>
            </div>
            <!-- <div class="form-row">
            </div> -->
            <!-- <div class="form-row">
                <div class="col-sm">
                    <FieldText :label="$t('Settings.subscribe')" :value.sync="editFormData.subscribe" @modified="checkModified" />
                </div>
            </div> -->
            <template v-if="displayGrids">
                <div class="row">
                    <div class="col-sm">
                        <Grid ref="visitStageGrid" :config="visitStageGridConfig" @modified="visitStageGridModified($event)" />
                    </div>
                <!-- </div>
                <div class="row"> -->
                    <div class="col-sm">
                        <Grid ref="visitStageDependencyGrid" :config="visitStageDependencyGridConfig" @modified="visitStageDependencyGridModified($event)"/>
                    </div>
                <!-- </div>
                <div class="row"> -->
                    <!-- <div class="col-sm">
                        <Grid ref="visitStageWorkplaceGrid" :config="visitStageWorkplaceGridConfig" @modified="visitStageWorkplaceGridModified($event)"/>
                    </div> -->
                </div>
            </template>
        </div>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldString from '@/components/lib/controls/FieldString';
    import FieldSelect from '@/components/lib/controls/FieldSelect';

    import Grid from '@/components/lib/regions/Grid';

    const componentUrl = 'visit_route';

    export default {
        name: 'VisitRouteEdit',

        components: {
            TemplatePageStandard,
            ButtonClose, ButtonSave, ButtonDelete,
            FieldString, FieldSelect,
            Grid,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                isFormModified: false,
                isVisitStageGridModified: false,
                isVisitStageDependencyGridModified: false,
                // isVisitStageWorkplaceGridModified: false,
                editFormData: {
                    code: null,
                    name: null,
                    visit_event_type_id: null,
                    // is_archived: false,
                },
                oldFormData: {},
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-visits') },
                    { text: this.$t('MainMenu.settings-visits-routes'), to: '/settings/visits/routes' },
                    { text: this.$t('object.visit_route') },
                ],
                visitStageGridConfig: {
                    apiUrl: 'visit_stage',
                    filter: null,
                    title: this.$t('component.VisitRouteEdit.stages'),
                    columns: [
                        { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'visit_route_id', header:'Route', source:'visit_route', required:true  },
                        { type:'select', key:'visit_type_id', header:this.$t('object.visit_type'), source:'visit_type', required:true },
                        { type:'string', key:'code', header:this.$t('attribute.common.code') },
                        { type:'string', key:'name', header:this.$t('attribute.common.name'), required:true },
                    ],
                    order: [ 'code' ],
                    displayToolbar: true,
                    displaySearch: true,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                    saveSessionState: `${this.$options.name}.visit_stage`,
                },
                visitStageDependencyGridConfig: {
                    apiUrl: null, // visit-route-dependency
                    filter: null,
                    title: this.$t('component.VisitRouteEdit.visitStageDependency'),
                    columns: [
                        { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        { type:'select', key:'visit_stage_prev_id', header:this.$t('attribute.visit_stage_dependency.visit_stage_prev_id'), source:null, required:true },
                        { type:'select', key:'visit_stage_next_id', header:this.$t('attribute.visit_stage_dependency.visit_stage_next_id'), source:null, required:true },
                        { type:'number', key:'sort_order', header:this.$t('attribute.common.sort_order') },
                    ],
                    order: [ 'sort_order', 'visit_stage_prev_id', 'visit_stage_next_id' ],
                    displayToolbar: true,
                    displaySearch: true,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                    saveSessionState: `${this.$options.name}.visit_stage_dependency`,
                },
                // visitStageWorkplaceGridConfig: {
                //     apiUrl: null, // visit-route-stage-workplace
                //     filter: null,
                //     title: this.$t('component.VisitRouteEdit.visitStageWorkplace'),
                //     columns: [
                //         { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                //         { type:'select', key:'visit_stage_id', header:this.$t('object.visit_stage'), source:null, required:true },
                //         // { type:'select', key:'core_speciality_id', header:this.$t('object.core_speciality'), source:'core_speciality' },
                //         { type:'select', key:'core_workplace_id', header:this.$t('object.core_workplace'), source:'v_workplace', keyLabel:'workplace_name' },
                //         { type:'number', key:'duration', header:this.$t('attribute.common.duration'), required:true },
                //     ],
                //     order: [ 'visit_stage_id', /* 'core_speciality_id', */ 'core_workplace_id' ],
                //     displayToolbar: true,
                //     displaySearch: true,
                //     buttons: [ 'row-selector', 'add', 'delete' ],
                //     saveSessionState: `${this.$options.name}.visit_stage_workplace`,
                // },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            apiUrl() { return `${this.baseUrl}/${componentUrl}` },
            displayGrids() { return typeof this.editFormData.id !='undefined' },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = [ 'save', 'delete', 'close' ];
                } else {
                    this.buttons = [ 'save', 'close' ];
                }
            },

            setGridFilter() {
                let filter = { where:[
                    { visit_route_id: this.itemId },
                ]};
                let filterString = tools.getFilterString(filter);
                this.visitStageGridConfig.filter = filter;
                this.visitStageGridConfig.columns.find(x => x.key === 'visit_route_id').defaultValue = this.itemId;
                // this.visitStageDependencyGridConfig.columns.find(x => x.key === 'stage_prev_id').source = `${visit-route-stages}/${this.itemId}`;
                // this.visitStageDependencyGridConfig.columns.find(x => x.key === 'stage_prev_id').filter = { where:[{ visit_route_id: this.itemId }]};
                this.visitStageDependencyGridConfig.columns.find(x => x.key === 'visit_stage_prev_id').source = `visit_stage${filterString}`;
                this.visitStageDependencyGridConfig.columns.find(x => x.key === 'visit_stage_next_id').source = `visit_stage${filterString}`;
                this.visitStageDependencyGridConfig.apiUrl = `visit-stage-dependency/${this.itemId}`;
                // this.visitStageWorkplaceGridConfig.columns.find(x => x.key === 'visit_stage_id').source = `visit_stage${filterString}`;
                // this.visitStageWorkplaceGridConfig.apiUrl = `visit-stage-workplace/${this.itemId}`;
            },

            gotData() {
                this.isFormModified = false;
                this.msgItemModified = '';
                this.breadcrumb[this.breadcrumb.length - 1].text = this.editFormData.name;
                this.itemId = this.editFormData.id;
                this.oldFormData = tools.deepCopy(this.editFormData);
                this.setGridFilter();
                this.checkButtons();
            },

            async getData() {
                try {
                    this.editFormData = (await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.editFormData=`, this.editFormData);
                    this.gotData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isFormModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update user
                            await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData);
                            tools.displaySuccessSave(this);
                            this.gotData();
                        } else {
                            // New user created
                            this.editFormData = (await this.authenticatedAxios.post(this.apiUrl, this.editFormData)).data;
                            tools.displaySuccessSave(this);
                            this.gotData();
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
                // try {
                    if (this.isVisitStageGridModified) {
                        this.$refs.visitStageGrid.$emit('save');
                        this.isVisitStageGridModified = false;
                    }
                    if (this.isVisitStageDependencyGridModified) {
                        this.$refs.visitStageDependencyGrid.$emit('save');
                        this.isVisitStageDependencyGridModified = false;
                    }
                    // if (this.isVisitStageWorkplaceGridModified) {
                    //     this.$refs.visitStageWorkplaceGrid.$emit('save');
                    //     this.isVisitStageWorkplaceGridModified = false;
                    // }
                // } catch (err) {
                //     tools.displayApiError(this, err);
                // }
                this.checkModified();
            },

            checkModified() {
                // console.log(`DEBUG: ${this.$options.name}.checkModified()`);
                this.isFormModified = !tools.isEqual(this.oldFormData, this.editFormData);
                // console.log(`DEBUG: ${this.$options.name}.checkModified(), this.isFormModified=${this.isFormModified}, this.isVisitStageGridModified=${this.isVisitStageGridModified}`);
                // console.log(`DEBUG: ${this.$options.name}.checkModified() this.oldFormData=`, this.oldFormData);
                // console.log(`DEBUG: ${this.$options.name}.checkModified() this.editFormData=`, this.editFormData);
                this.isModified = (
                    this.isFormModified ||
                    this.isVisitStageGridModified ||
                    this.isVisitStageDependencyGridModified
                    // this.isVisitStageWorkplaceGridModified
                );
                if (this.isModified) {
                    this.msgItemModified = this.$t('component.VisitRouteEdit.itemModified');
                } else {
                    this.msgItemModified = '';
                }
            },

            async processDeleteItem (doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                        this.isFormModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },

            // Grids

            visitStageGridModified(modified) {
                // console.log(`DEBUG: ${this.$options.name}.visitStageGridModified(${modified})`);
                this.isVisitStageGridModified = modified;
                this.checkModified();
            },

            visitStageDependencyGridModified(modified) {
                this.isVisitStageDependencyGridModified = modified;
                this.checkModified();
            },

            // visitStageWorkplaceGridModified(modified) {
            //     this.isVisitStageWorkplaceGridModified = modified;
            //     this.checkModified();
            // },

            // visitStageGridEdit(id) {
            //     this.$router.push({ name: this.visitStageGridConfig.onEdit, params: { id } });
            // },
        },

        // =============== Life cycle ===============
        beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.id=`, this.id);
            this.itemId = parseInt(this.$route.params.id);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.itemId=`, this.itemId);
            if (this.itemId) {
                await this.getData();
                this.setGridFilter();
            }
            this.checkButtons();
            this.oldFormData = tools.deepCopy(this.editFormData);
        },
    }
</script>

<style>
</style>
