/*
    Author:Eduard Grebenyukov
    Date:2021-09-12
*/

import { i18n } from '@/locales';

import { pageConfig } from '../store/pageConfig';
import { pageSubmenu } from '../store/pageSubmenu';

const SubmenuPage = () => import('@/components/lib/pages/SubmenuPage');
const GridEdit = () => import('@/components/pages/common/GridEdit');

const MatBalance = () => import('@/components/pages/mat/MatBalance');
const MatDocArrivals = () => import('@/components/pages/mat/MatDocArrivals');
const MatDocArrivalEdit = () => import('@/components/pages/mat/MatDocArrivalEdit');
// const MatDocArrivalItemEdit = () => import('@/components/pages/mat/MatDocArrivalItemEdit');
const MatDocSupplierReturns = () => import('@/components/pages/mat/MatDocSupplierReturns');
const MatDocSupplierReturnEdit = () => import('@/components/pages/mat/MatDocSupplierReturnEdit');
const MatDocTransfers = () => import('@/components/pages/mat/MatDocTransfers');
const MatDocWriteoffs = () => import('@/components/pages/mat/MatDocWriteoffs');
const MatDocInventories = () => import('@/components/pages/mat/MatDocInventories');

const MatItemEdit = () => import('@/components/pages/mat/MatItemEdit');
const MatSuppliers = () => import('@/components/pages/mat/MatSuppliers');

const routes = [
    { name:'store',                      path:'/store',                     component:SubmenuPage,       props:{ config:pageSubmenu.submenu_store }, meta:{title:i18n.t('MainMenu.store')} },
    { name:'store/balance',              path:'/store/balance',             component:MatBalance,                                                    meta:{title:i18n.t('MainMenu.store-balance')} }, // 'Остатки на складах'
    // { name:'store/supplier-orders', path:'/store/supplier-orders', component:GridEdit,          props:{ config:pageConfig.mat_doc },        meta:{title:i18n.t('MainMenu.store')} }, // 'Заказы поставщикам'
    { name:'store/arrivals',             path:'/store/arrivals',            component:MatDocArrivals,                                                meta:{title:i18n.t('MainMenu.store-arrivals')} }, // 'Поступления'
    { name:'store/arrival-new',          path:'/store/arrival',             component:MatDocArrivalEdit, props:true,                                 meta:{title:i18n.t('component.MatDocArrivalEdit.title')} },
    { name:'store/arrival-edit',         path:'/store/arrival/:id',         component:MatDocArrivalEdit, props:true,                                 meta:{title:i18n.t('component.MatDocArrivalEdit.title')} },
    // { name:'store/arrival/item-new', path:'/store/arrival/item', component:MatDocArrivalItemEdit, props:true, meta:{title:i18n.t('MainMenu.store-arrival-item')} },
    // { name:'store/arrival/item-edit', path:'/store/arrival/item/:id', component:MatDocArrivalItemEdit, props:true, meta:{title:i18n.t('MainMenu.store-arrival-item')} },
    { name:'store/supplier-returns',     path:'/store/supplier-returns',    component:MatDocSupplierReturns,                                                 meta:{title:i18n.t('MainMenu.store-supplier-returns')} }, // 'Возвраты поставщикам'
    { name:'store/supplier-return-new',  path:'/store/supplier-return',     component:MatDocSupplierReturnEdit,  props:true,                                 meta:{title:i18n.t('component.MatDocSupplierReturnEdit.title')} },
    { name:'store/supplier-return-edit', path:'/store/supplier-return/:id', component:MatDocSupplierReturnEdit,  props:true,                                 meta:{title:i18n.t('component.MatDocSupplierReturnEdit.title')} },
    { name:'store/transfer-requests',    path:'/store/transfer-requests',   component:GridEdit,                  props:{ config:pageConfig.mat_doc },        meta:{title:i18n.t('MainMenu.store-transfer-requests')} }, // 'Заявки на перемещение'
    { name:'store/transfers',            path:'/store/transfers',           component:MatDocTransfers,           props:{ config:pageConfig.mat_doc },        meta:{title:i18n.t('MainMenu.store-transfers')} }, // 'Перемещения'
    { name:'store/writeoffs',            path:'/store/writeoffs',           component:MatDocWriteoffs,           props:{ config:pageConfig.mat_doc },        meta:{title:i18n.t('MainMenu.store-writeoffs')} }, // 'Списание'
    { name:'store/inventories',          path:'/store/inventories',         component:MatDocInventories,         props:{ config:pageConfig.mat_doc },        meta:{title:i18n.t('MainMenu.store-inventory')} }, // 'Инвентаризация'
    // { name:'store/revaluations',    path:'/store/revaluation',       component:GridEdit,          props:{ config:pageConfig.mat_doc },        meta:{title:i18n.t('MainMenu.store-revaluation')} }, // 'Переоценка'
    // store dict
    { name:'store/stores',      path:'/store/stores',       component:GridEdit,                props:{ config:pageConfig.mat_store },      meta:{title:i18n.t('MainMenu.store-stores')} }, // 'Склады'
    { name:'store/units',       path:'/store/units',        component:GridEdit,                props:{ config:pageConfig.mat_unit },       meta:{title:i18n.t('MainMenu.store-units')} }, // 'Единицы измерения'
    // { name:'store/packs', path:'/store/packs', component:GridEdit, props:{ config:pageConfig.mat_pack }, meta:{title:i18n.t('MainMenu.store')} }, // 'Упаковка'
    { name:'store/pharmgroups', path:'/store/pharmgroups',  component:GridEdit,                props:{ config:pageConfig.mat_pharmgroup }, meta:{title:i18n.t('MainMenu.store-pharmgroups')} }, // 'Фармгруппы'
    { name:'store/groups',      path:'/store/groups',       component:GridEdit,                props:{ config:pageConfig.mat_group },      meta:{title:i18n.t('MainMenu.store-groups')} }, // 'Группы материалов'
    { name:'store/pricegroups', path:'/store/pricegroups',  component:GridEdit,                props:{ config:pageConfig.mat_pricegroup }, meta:{title:i18n.t('MainMenu.store-pricegroups')} }, // 'Ценовые группы'
    { name:'store/items',       path:'/store/items',        component:GridEdit,                props:{ config:pageConfig.mat_item },       meta:{title:i18n.t('MainMenu.store-items')} }, // 'Номенклатура'
    { name:'store/item-new',    path:'/store/item',         component:MatItemEdit,             props:true,                                 meta:{title:i18n.t('object.mat_item')} },
    { name:'store/item-edit',   path:'/store/item/:id',     component:MatItemEdit,             props:true,                                 meta:{title:i18n.t('object.mat_item')} },
    { name:'store/suppliers',   path:'/store/suppliers',    component:MatSuppliers,                                                        meta:{title:i18n.t('MainMenu.store-suppliers')} },
]

export default routes
