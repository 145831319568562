<!--
    Author: Eduard Grebenyukov
    Date: 2018-12-15
-->

<!--
    labelPosition: before | above
    keyId
    keyLabel
    showEmptyRow
    emptyRowLabel
    order: String | Boolean
-->

<template>
            <!-- 'row':labelPosition === 'before', -->
    <div
        :class="{
            'field-required':required,
            'form-group': true,
            'form-inline': labelPosition === 'before',
            'form-label-group': labelPosition === 'float'
        }"
    >
        <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label :for="id" v-if="label && (labelPosition === 'before')" :title="required ? $t('lib.required') : ''" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <!-- <label :title="required ? $t('lib.required') : ''" :class="{ 'col-form-label':labelPosition === 'before' }">
            {{label}}<sup v-if="required" class="required-mark">*</sup>
        </label> -->

        <div class="input-group" v-if="!readonly">
                    <!-- 'col-sm-10': labelPosition === 'before', -->
            <select
                v-model="itemData"
                :disabled="disabled"
                :readonly="readonly"
                :class="{
                    'form-control': true,
                    'custom-select': true,
                    'field-select': true,
                    'is-invalid': isInvalid || errorMessage,
                    'is-valid': isValid,
                    'field__readonly': readonly || disabled,
                    'field__disabled': disabled,
                    'field__required-empty': required && (!itemData || itemData == '')
                }"
                :style="`${inputStyle}`"
                @change="itemModified()"
            >
                <option v-if="showEmptyRow" :key="`field-select-${_uid}-empty_row`" :value="null">{{ emptyRowLabel }}</option>
                <!-- <option v-for="listItem in selectListData" :key="`field-select-${_uid}-${listItem[keyId||'id']}`" :value="listItem[keyId||'id']">{{listItem[keyLabel||'name']}}</option> -->
                <option v-for="(listItem, index) in selectListData" :key="`field-select-${_uid}-${index}-${listItem[keyId||'id']}`" :value="listItem[keyId||'id']">{{ listItem[keyLabel||'name'] }}</option>
            </select>

            <div class="input-group-append">
                <slot name="buttons"></slot>
            </div>
        </div>

        <!-- <b-form-select v-model="selected" :options="selectListData" :value-field="keyId||'id'" :text-field="keyLabel||'name'" /> -->

        <span
            v-if="readonly"
            @click="() => {if (textEmit) $emit(textEmit, itemData); if (textOnClick) eval(textOnClick, itemData);}"
            class="field__readonly"
            :class="{textClass, 'form-control':labelPosition != 'plain', 'col-sm-10':labelPosition === 'before' }" :style="textStyle"
        >
            {{ itemPresentation }}
        </span>

        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>

    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'FieldSelect',

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: [String, Number],
            defaultValue: [String, Number],
            placeholder: String,
            readonly: Boolean,
            disabled: Boolean,
            required: Boolean,
            source: [String, Array],
            keyId: String,
            keyLabel: String,
            textEmit: String,
            textOnClick: String,
            textClass: String,
            textStyle: String,
            showEmptyRow: Boolean,
            emptyRowLabel: String,
            order: String, //order: [String, Array],
            saveState: String,
            saveSessionState: String,
            isInvalid: Boolean,
            isValid: Boolean,
            inputStyle: String,
            errorMessage: String,
        },

        // =============== Data ===============
        data() {
            return {
                controlId: null, // internal control ID, can be overwritten by props.id
                selectListData: [],
                itemData: null,
                itemPresentation: null,
                // labelPositionCode: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Watch ===============
        watch: {
            value() {
                this.itemData = this.value;
                // this.itemModified();
                this.setPresentation();
                // console.log(`DEBUG: ${this.$options.name}.watch.value this.value=`, this.value);
            },
            source() {
                // console.log(`DEBUG: ${this.$options.name}.watch.source this.source=`, this.source);
                this.getSelectValues();
            }
        },

        // =============== Methods ===============
        methods: {
            eval(exp, entry) {
                return eval(exp);
            },

            getPresentation() {
                return this.itemPresentation;
            },

            setPresentation() {
                // this.itemPresentation = this.selectListData.find(x => {console.log('x=', x); return x[this.keyId||'id'] === this.itemData})[this.keyLabel||'name'];
                let currentListItem = this.selectListData.find(x => { return x[this.keyId||'id'] === this.itemData });
                if (currentListItem) {
                    this.itemPresentation = currentListItem[this.keyLabel||'name'];
                } else {
                    this.itemPresentation = this.emptyRowLabel || '';
                }
                // if (this.id == 'core_workplace_id') {
                //     console.log(`DEBUG: ${this.$options.name}.getSelectValues(${this.id})`);
                //     console.log(`DEBUG: (${this.id}) this.selectListData=`, this.selectListData);
                //     console.log(`DEBUG: (${this.id}) currentListItem=`, currentListItem);
                //     console.log(`DEBUG: (${this.id}) this.keyId=`, this.keyId);
                //     console.log(`DEBUG: (${this.id}) this.keyLabel=`, this.keyLabel);
                //     console.log(`DEBUG: (${this.id}) this.itemData=`, this.itemData);
                //     console.log(`DEBUG: (${this.id}) this.itemPresentation=`, this.itemPresentation);
                // }
            },

            async getSelectValues() {
                // console.log(`DEBUG: ${this.$options.name}.getSelectValues() this.id=${this.id}, source=`, this.source);
                let listData = [];
                if (typeof this.source === 'string') {
                    // console.log(`DEBUG: ${this.$options.name}.getSelectValues() url=`, `${this.baseUrl}/${this.source}`);
                    listData = (await this.authenticatedAxios.get(`${this.baseUrl}/${this.source}`)).data.rows;
                } else if (typeof this.source === 'object' && Array.isArray(this.source)) {
                    listData = this.source;
                } else {
                    // Unknown object, set empty array
                    listData = [];
                }
                if (!listData) listData = [];

                // Do sort select items
                // console.log(`DEBUG: ${this.$options.name}.getSelectValues(${this.source}) this.order=`, this.order);
                // if (this.order && Array.isArray(this.order)) {
                //     listData = listData.slice().sort((a, b) => {
                //         let aa = a[this.order[0]];
                //         let bb = b[this.order[0]];
                //         return (aa === bb ? 0 : aa > bb ? 1 : -1);
                //     });
                // } else {
                // if (typeof this.order === 'boolean') {
                //     if (this.order) {
                //         listData = listData.slice().sort((a, b) => {
                //             let aa = a[this.keyLabel || 'name'];
                //             let bb = b[this.keyLabel || 'name'];
                //             return (aa === bb ? 0 : aa > bb ? 1 : -1);
                //         });
                //     }
                // } else {
                //     listData = listData.slice().sort((a, b) => {
                //         let aa = a[this.order || this.keyLabel || 'name'];
                //         let bb = b[this.order || this.keyLabel || 'name'];
                //         return (aa === bb ? 0 : aa > bb ? 1 : -1);
                //     });
                // }
                // }
                listData = listData.slice().sort((a, b) => {
                    let aa = a[this.order || this.keyLabel || 'name'];
                    let bb = b[this.order || this.keyLabel || 'name'];
                    return (aa === bb ? 0 : aa > bb ? 1 : -1);
                });

                this.selectListData = listData;

                this.setPresentation();
                // console.log(`DEBUG: ${this.$options.name}.getSelectValues(${this.source}) this.selectListData=`, this.selectListData);
            },

            itemModified() {
                if (this.saveState) {
                    // Save current value
                    // console.log(`DEBUG: ${this.$options.name}.itemModified(this.itemData)`);
                    this.authenticatedAxios.post(`${this.baseUrl}/${userSettingsUrl}`, {code: this.saveState, value: this.itemData});
                }
                if (this.saveSessionState) {
                    sessionStorage.setItem(this.saveSessionState, JSON.stringify(this.itemData));
                }
                // this.$emit('update:value', e.target.value.trim());
                this.$emit('update:value', this.itemData);
                this.$emit('modified');
            },

            // this methods are for calling from parent component (omg! wrong arcitecture, sinner!)
            getAllRows() {
                // console.log(`DEBUG: ${this.$options.name}.getTotalRowsQty(), this.dataArray.length=`, this.dataArray.length);
                return this.selectListData;
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // !!!! if select is read-only, do not get all list elements, get only current !!!!
            // console.log(`DEBUG: ${this.$options.name}(${this.source}).beforeMount() this=`, this);
            // console.log(`DEBUG: ${this.$options.name}(${this.id}).beforeMount() this.value=`, this.value);
            this.itemData = this.value;
            if (!this.itemData && this.saveState) {
                // Restore stored value
                let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() url response=`, response);
                let item = response.data.rows[0];
                let oldValue = this.itemData;
                if (item) {
                    if (item.value !== oldValue) {
                        this.itemData = item.value;
                        this.itemModified();
                    }
                }
            }
            if (!this.itemData && this.saveSessionState) {
                let sessionData = JSON.parse(sessionStorage.getItem(this.saveSessionState));
                if (sessionData) this.itemData = sessionData;
                this.itemModified(this);
            }
            if (!this.itemData && typeof this.defaultValue != 'undefined' && this.defaultValue !== null) {
                this.itemData = this.defaultValue;
                this.itemModified();
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() after default this.itemData=`, this.itemData, ', this.defaultValue=', this.defaultValue);
            }
            // !!!!!!!! if current value not in select list, do select first value from list
            //          or provide this behavior by param condition
        },
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted()`);
            // this.labelPositionCode = (this.labelPosition ? this.labelPosition : 'above');
            // console.log('this.labelPositionCode=',this.labelPositionCode);
            await this.getSelectValues();
            this.$emit('mounted');
        },
    }
</script>

<style>
    .field-select option {
        /* min-height: 48px;
        height: 48px;
        line-height: 200%; */
        font-size: 1.1rem;
    }

    /* .custom-select-readonly {
        overflow: auto;
        background-color: #e9ecef;
    } */
</style>
