<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-11
-->

<template>
    <MainMenuHorizontal id="main-menu-horizontal" :menuItems="mainMenu" :currentMenu="currentMenu" />
</template>

<script>
    import { mapGetters } from 'vuex';
    import MainMenuHorizontal from '@/components/lib/regions/MainMenuHorizontal';

    export default {
        components: { MainMenuHorizontal },
        props: ['currentMenu'],

        // =============== Data ===============
        data() {
            return {
                // menu: [],
                // client: null,
            };
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                mainMenu: 'mainMenu',
                // lastClient: 'lastClient',
            }),
        },
    }
</script>

<style>
</style>
