/*
    Author: Eduard Grebenyukov
    Date: 2020-04-13
*/

import { i18n } from '@/locales';

const visitMenu= [
    { code:'visit-edit',                   title:i18n.t('VisitMenu.visit-edit'),             name:i18n.t('VisitMenu.visit-edit'),             href:'/client/:clientId/visit/:visitId/visit-edit',          alias:[] },
    { code:'visit-anamnesis-vitae',        title:i18n.t('VisitMenu.anamnesis-vitae'),        name:i18n.t('VisitMenu.anamnesis-vitae'),        href:'/client/:clientId/visit/:visitId/anamnesis-vitae',     alias:[] },
    { code:'visit-diagnoses',              title:i18n.t('VisitMenu.diagnoses'),              name:i18n.t('VisitMenu.diagnoses'),              href:'/client/:clientId/visit/:visitId/diagnoses',           alias:[] },
    { code:'visit-consultation',           title:i18n.t('VisitMenu.consultation'),           name:i18n.t('VisitMenu.consultation'),           href:'/client/:clientId/visit/:visitId/consultation',        alias:[] },
    { code:'visit-consultation-dental',    title:i18n.t('VisitMenu.consultation-dental'),    name:i18n.t('VisitMenu.consultation-dental'),    href:'/client/:clientId/visit/:visitId/consultation-dental', alias:[] },
    { code:'visit-consultation-assistant', title:i18n.t('VisitMenu.consultation-assistant'), name:i18n.t('VisitMenu.consultation-assistant'), href:'/client/:clientId/visit/:visitId/consultation-assistant', alias:[] },
    { code:'visit-ophtalmology-exam',      title:i18n.t('VisitMenu.ophtalmology-exam'),      name:i18n.t('VisitMenu.ophtalmology-exam'),      href:'/client/:clientId/visit/:visitId/ophtalmology-exam',   alias:[] },
    { code:'visit-hospitalization',        title:i18n.t('VisitMenu.hospitalization'),        name:i18n.t('VisitMenu.hospitalization'),        href:'/client/:clientId/visit/:visitId/hospitalization',     alias:[] },
    { code:'visit-discharge',              title:i18n.t('VisitMenu.discharge'),              name:i18n.t('VisitMenu.discharge'),              href:'/client/:clientId/visit/:visitId/discharge',           alias:[] },

    { code:'visit-services',               title:i18n.t('VisitMenu.services'),               name:i18n.t('VisitMenu.services'),               href:'/client/:clientId/visit/:visitId/services',            alias:[] },
    { code:'visit-services-dental',        title:i18n.t('VisitMenu.services'),               name:i18n.t('VisitMenu.services-dental'),        href:'/client/:clientId/visit/:visitId/services-dental',     alias:[] },
    { code:'visit-referrals',              title:i18n.t('VisitMenu.referrals'),              name:i18n.t('VisitMenu.referrals'),              href:'/client/:clientId/visit/:visitId/referrals',           alias:[] },
    { code:'visit-medications',            title:i18n.t('VisitMenu.medications_short'),      name:i18n.t('VisitMenu.medications'),            href:'/client/:clientId/visit/:visitId/medications',         alias:[] },
    { code:'visit-sampling',               title:i18n.t('VisitMenu.sampling'),               name:i18n.t('VisitMenu.sampling'),               href:'/client/:clientId/visit/:visitId/sampling',            alias:[] },
    { code:'visit-estimates',              title:i18n.t('VisitMenu.estimates'),              name:i18n.t('VisitMenu.estimates'),              href:'/client/:clientId/visit/:visitId/estimates',           alias:[] },
    { code:'visit-estimates-dental',       title:i18n.t('VisitMenu.estimates'),              name:i18n.t('VisitMenu.estimates-dental'),       href:'/client/:clientId/visit/:visitId/estimates-dental',    alias:[] },

    { code:'visit-files',                  title:i18n.t('VisitMenu.files'),                  name:i18n.t('VisitMenu.files'),                  href:'/client/:clientId/visit/:visitId/files',               alias:['/client/*/visit/*/file/*'] },
    { code:'visit-tasks',                  title:i18n.t('VisitMenu.tasks'),                  name:i18n.t('VisitMenu.tasks'),                  href:'/client/:clientId/visit/:visitId/tasks',               alias:['/client/*/visit/*/task/*'] },
    // { code:'visit', title:i18n.t('VisitMenu.visit'), href:'/client/:clientId/visit/:visitId/visit', alias:[] },
];

export { visitMenu };
