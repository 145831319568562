/*
    Author:Eduard Grebenyukov
    Date:2021-09-12
*/

import { i18n } from '@/locales';

import { pageSubmenu } from '../store/pageSubmenu';

const SubmenuPage = () => import('@/components/lib/pages/SubmenuPage');

const ReportPrice = () => import('@/components/pages/reports/ReportPrice');
const ReportDoctorPersonal = () => import('@/components/pages/reports/ReportDoctorPersonal');
const ReportVisits = () => import('@/components/pages/reports/ReportVisits');
const ReportDoctors = () => import('@/components/pages/reports/ReportDoctors');
const ReportDoctorsPatients = () => import('@/components/pages/reports/ReportDoctorsPatients');
const ReportAdm = () => import('@/components/pages/reports/ReportAdm');
const ReportAdmPatients = () => import('@/components/pages/reports/ReportAdmPatients');
const ReportAdmPatientsPrimary = () => import('@/components/pages/reports/ReportAdmPatientsPrimary');
const ReportDaily = () => import('@/components/pages/reports/ReportDailyGrid');
const ReportDailyDoctors = () => import('@/components/pages/reports/ReportDailyDoctors');
// const ReportDailyPivot = () => import('@/components/pages/reports/ReportDailyPivot');
const ReportDeposit = () => import('@/components/pages/reports/ReportDeposit');
const ReportDoctorsEff = () => import('@/components/pages/reports/ReportDoctorsEff');
const ReportServicesRendered = () => import('@/components/pages/reports/ReportServicesRendered');
const ReportRevenue = () => import('@/components/pages/reports/ReportRevenue');
const ReportWageDoctors = () => import('@/components/pages/reports/ReportWageDoctors');
const ReportWageAssistants = () => import('@/components/pages/reports/ReportWageAssistants');
const ReportLaboServices = () => import('@/components/pages/reports/ReportLaboServices');
const ReportStudies = () => import('@/components/pages/reports/ReportStudies');
const ReportPatients = () => import('@/components/pages/reports/ReportPatients');
const ReportDebts = () => import('@/components/pages/reports/ReportDebts');

const routes = [
    { name:'reports/', path:'/reports', component:SubmenuPage, props:{ config:pageSubmenu.submenu_reports }, meta:{title:i18n.t('MainMenu.reports')} },
    { name:'reports/price', path:'/reports/price', component:ReportPrice, meta:{title:i18n.t('MainMenu.reports-price')} },
    { name:'reports/doctor-personal', path:'/reports/doctor-personal', component:ReportDoctorPersonal, meta:{title:i18n.t('MainMenu.reports-doctor-personal')} },
    { name:'reports/visits', path:'/reports/visits', component:ReportVisits, meta:{title:i18n.t('MainMenu.reports-visits')} },
    { name:'reports/doctors', path:'/reports/doctors', component:ReportDoctors, meta:{title:i18n.t('MainMenu.reports-doctors')} },
    { name:'reports/doctors-patients', path:'/reports/doctors-patients', component:ReportDoctorsPatients, meta:{title:i18n.t('MainMenu.reports-doctors-patients')} },
    { name:'reports/adm', path:'/reports/adm', component:ReportAdm, meta:{title:i18n.t('MainMenu.reports-adm')} },
    { name:'reports/adm-patients', path:'/reports/adm-patients', component:ReportAdmPatients, meta:{title:i18n.t('MainMenu.reports-adm-patients')} },
    { name:'reports/adm-patients-primary', path:'/reports/adm-patients-primary', component:ReportAdmPatientsPrimary, meta:{title:i18n.t('MainMenu.reports-adm-patients-primary')} },
    { name:'reports/daily', path:'/reports/daily', component:ReportDaily, meta:{title:i18n.t('MainMenu.reports-daily')} },
    { name:'reports/daily-doctors', path:'/reports/daily-doctors', component:ReportDailyDoctors, meta:{title:i18n.t('MainMenu.reports-daily-doctors')} },
    // { name:'reports/daily-pivot', path:'/reports/daily-pivot', component:ReportDailyPivot, meta:{title:i18n.t('MainMenu.reports-daily')} },
    { name:'reports/deposit', path:'/reports/deposit', component:ReportDeposit, meta:{title:i18n.t('MainMenu.reports-deposit')} },
    { name:'reports/doctors-eff', path:'/reports/doctors-eff', component:ReportDoctorsEff, meta:{title:i18n.t('MainMenu.reports-doctors-effectiveness')} },
    { name:'reports/services-rendered', path:'/reports/services-rendered', component:ReportServicesRendered, meta:{title:i18n.t('MainMenu.reports-services-rendered')} },
    { name:'reports/revenue', path:'/reports/revenue', component:ReportRevenue, meta:{title:i18n.t('MainMenu.reports-revenue')} },
    { name:'reports/wage-doctors', path:'/reports/wage-doctors', component:ReportWageDoctors, meta:{title:i18n.t('MainMenu.reports-wage-doctors')} },
    { name:'reports/wage-assistants', path:'/reports/wage-assistants', component:ReportWageAssistants, meta:{title:i18n.t('MainMenu.reports-wage-assistants')} },
    { name:'reports/labo-services', path:'/reports/labo-services', component:ReportLaboServices, meta:{title:i18n.t('MainMenu.reports-labo-services')} },
    { name:'reports/studies', path:'/reports/studies', component:ReportStudies, meta:{title:i18n.t('MainMenu.reports-studies')} },
    { name:'reports/patients', path:'/reports/patients', component:ReportPatients, meta:{title:i18n.t('MainMenu.reports-patients')} },
    { name:'reports/debts', path:'/reports/debts', component:ReportDebts, meta:{title:i18n.t('MainMenu.reports-debts')} },
]

export default routes
