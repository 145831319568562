<!--
    Author: Eduard Grebenyukov
    Date: 2020-10-17
-->

<template>
    <button :class="`btn ${buttonClass}`" :title="title ? title : text" :disabled="disabled" @click.exact.prevent="$emit('click')" @click.ctrl.prevent="$emit('ctrl-click')">
        <slot>
            <i :class="iconClass"></i> {{ text }}
        </slot>
    </button>
</template>

<script>
    export default {
        name: 'ButtonCustom',

        // =============== Props ===============
        props: {
            buttonClass: String,
            iconClass: String,
            title: String,
            text: String,
            disabled: Boolean,
        },
    }
</script>
