<!--
    Author: Eduard Grebenyukov
    Date: 2020-09-27
-->

<template>
    <button @click.prevent="$emit('click')" :disabled="disabled" class="btn btn-outline-danger btn-sm ml-1" :title="$t('app.delete')">
        <slot>
            <i class="fas fa-trash"></i> {{ $t('lib.buttons.delete') }}
        </slot>
    </button>
</template>

<script>
    export default {
        name: 'ButtonDelete',

        // =============== Props ===============
        props: {
            disabled: Boolean,
        },
    }
</script>
