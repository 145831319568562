<!--
    Author: Eduard Grebenyukov
    Date: 2020-03-21
-->

<!--
config: {
    title: String,
    breadcrumb: [],
}
-->

<template>
    <div id="page-wrap" class="page-container">
        <Navbar>
            <IncomingCall slot="alarm" />
        </Navbar>
        <MainMenuHorizontal/>
        <div id="body-row" class="row-offcanvas row-offcanvas-left">
            <MainMenuVertical id="sidebar" role="navigation" class="sticky" />
            <div id="page-main">
                <div class="d-flex justify-content-between flex-wrap page__toolbar toolbar">
                    <div class="pl-0">
                        <breadcrumb id="breadcrumb" :items="config.breadcrumb" :class="{ modified: isModified }" />
                    </div>
                    <div class="flex-grow-1"></div>
                    <div class="button-bar flex-row-reverse justify-content-start">
                        <slot name="buttons"></slot>
                    </div>
                </div>
                <!-- body -->
                <slot></slot>
            </div>
        </div>

        <PageFooter :key="$route.fullPath" :displayLogout="true" />

        <WarningClose v-if="isDisplayWarningClose" @process="$emit('process-close', $event)" />
        <WarningDelete v-if="isDisplayWarningDelete" @process="$emit('process-delete', $event)" />

    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';

    import Navbar from '@/components/lib/regions/Navbar';
    import Breadcrumb from '@/components/lib/regions/Breadcrumb';
    import WarningClose from '@/components/lib/regions/WarningClose';
    import WarningDelete from '@/components/lib/regions/WarningDelete';
    import IncomingCall from '@/components/pages/crm/IncomingCall';
    import MainMenuHorizontal from '@/components/pages/common/MainMenu';
    import MainMenuVertical from '@/components/pages/common/MainMenuSidebar';
    import PageFooter from '@/components/pages/common/PageFooter';

    export default {
        name: 'TemplatePageStandard',

        components: {
            Navbar, IncomingCall, MainMenuHorizontal, MainMenuVertical, Breadcrumb, PageFooter,
            WarningClose, WarningDelete,
        },

        // =============== Props ===============
        props: {
            config: { required: true },
            isModified: { type:Boolean, default:false },
            isDisplayWarningClose: { type:Boolean, default:false },
            isDisplayWarningDelete: { type:Boolean, default:false },
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // tools.refreshOutdatedPage(this); // no wait
            this.authenticatedAxios.post(`${this.baseUrl}/page`, { url:this.$route.fullPath, build:tools.getFrontendBuild(this) }); // no wait, build:tools.getFrontendBuild(this) })).data;
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() build=`, build);
            // window.addEventListener('beforeunload', this.beforePageDestroyed);
        },
    }
</script>

<style>
    .page__toolbar {
        background-color: #e9ecef;
    }

    .page__toolbar .button-bar {
        padding: 0.25rem 0.75rem;
    }
</style>
