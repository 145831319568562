<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-15
-->

<template>
    <ModalWindow
        minWidth="300px"
        :buttons="['yes', 'no']"
        @yes="$emit('process', true)"
        @ok="$emit('process', true)"
        @no="$emit('process', false)"
        @cancel="$emit('process', false)"
    >
        <slot>
            {{ $t('app.confirmClose') }}
        </slot>
    </ModalWindow>
</template>

<script>
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    export default {
        name: 'WarningClose',
        components: { ModalWindow },
    }
</script>

<style>
</style>
