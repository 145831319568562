<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-06
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">

        <template #buttons>
            <button :disabled="reportInProgress" @click.prevent="saveReport()" class="btn btn-sm btn-outline-primary mr-1">
                <i class="fas fa-download"></i> {{ $t('lib.Grid.download') }}
            </button>
            <button :disabled="reportInProgress" @click.prevent="filterModified()" class="btn btn-sm btn-primary mr-1">
                <i class="fas fa-play"></i> {{ $t('app.build') }}
            </button>
        </template>

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm">
                    <Grid ref="report" :config="gridConfig">
                        <template #buttons>
                            <FieldNumberPlain
                                :label="$t('attribute.common.rows_qty')"
                                labelPosition="before"
                                :value.sync="filterData.row_qty"
                                :defaultValue="20"
                                :minValue="10"
                                :maxValue="10000"
                            />
                        </template>
                    </Grid>
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    // import { mapGetters } from 'vuex';
    import moment from 'moment';

    // import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import FieldNumberPlain from '@/components/lib/controls/FieldNumberPlain';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ReportPrice',

        components: {
            TemplatePageStandard,
            FieldNumberPlain,
            Grid,
        },

        // =============== Data ===============
        data() {
            return {
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.reports') },
                    { text: this.$t('MainMenu.reports-price') },
                ],
                filterData: {
                    dateFrom: null,
                    dateTo: null,
                    core_building_id: null,
                    row_qty: null,
                },
                reportInProgress: false,
                gridConfig: {
                    apiUrl: 'report-price',
                    readonly: true,
                    columns: [
                        { type:'string', key:'group_name', header:this.$t('object.fin_service_group') },
                        { type:'string', key:'code', header:this.$t('attribute.common.code'), style:'white-space:nowrap;' },
                        { type:'string', key:'code_public', header:this.$t('attribute.common.code_public'), style:'white-space:nowrap;' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        // { type:'number', key:'price', header:this.$t('attribute.common.price'), align:'right' },
                        {
                            type:'number', key:'price', header:this.$t('attribute.common.price'), align:'right',
                            $value: (entry) => ( entry.is_price_variable ? 'изм.' : entry.price ),
                        },
                    ],
                    order: [ 'group_name', 'name' ],
                    saveSessionState: 'report_price',
                },
            };
        },

        // =============== Methods ===============
        methods: {
            async setGridConfig() {
                this.reportInProgress = true;
                this.gridConfig.paginationPageSize = this.filterData.row_qty;
                // this.gridConfig.apiUrl = `report-price`;
                await this.$refs.report.refresh();
                this.reportInProgress = false;
            },

            async filterModified() { await this.setGridConfig() },

            saveReport() {
                this.gridConfig.downloadFileName = (
                    this.breadcrumb[this.breadcrumb.length - 1].text + '_' + moment().format('YYYY-MM-DD')
                );
                this.$refs.report.download();
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
        },

        async mounted() {
            // this.setGridConfig();
        },
    }
</script>

<style>
</style>
