<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-09
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
            <ButtonCustom
                v-if="buttons.includes('commit')"
                buttonClass="btn-warning btn-sm ml-1"
                iconClass="fas fa-check-square fa-fw"
                :text="$t('app.commit')"
                @click="CommitDocument()"
            />
            <ButtonCustom
                v-if="buttons.includes('rollback')"
                buttonClass="btn-outline-danger btn-sm ml-1"
                iconClass="fas fa-square fa-fw"
                :text="$t('app.rollback')"
                @click="isDisplayWarningRollback = true"
            />
            <ButtonCustom
                v-if="buttons.includes('change-prices')"
                buttonClass="btn-outline-success btn-sm ml-1"
                iconClass="fas fa-percentage fa-fw"
                :text="$t('component.SetpriceEdit.changePrices')"
                @click="isDisplayPriceParameters = true"
            />
            <!-- <template v-if="buttons.includes('rollback')">{{ $t('attribute.common.is_commited') }}</template> -->
        </template>

        <div id="page-content" class="form-float">
            <b-alert variant="success" :show="editFormData.is_commited">
                <i class="fas fa-check-square"></i> {{ $t('attribute.common.is_commited') }}
            </b-alert>

            <div class="row">
                <div class="col">

                    <div class="form-row">
                        <div class="col-sm">
                            <FieldNumber
                                :label="$t('attribute.common.doc_num')"
                                :value.sync="editFormData.id"
                                disabled
                            />
                        </div>
                        <div class="col-sm">
                            <FieldDate
                                :label="$t('attribute.fin_setprice.date_from')"
                                :value.sync="editFormData.date_from"
                                required
                                :disabled="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                        <div class="col-sm">
                            <FieldSelect
                                :label="$t('object.fin_pricelist')"
                                :value.sync="editFormData.fin_pricelist_id"
                                required
                                source="fin_pricelist"
                                :disabled="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm">
                            <FieldText
                                :label="$t('attribute.common.commentary')"
                                :value.sync="editFormData.commentary"
                                :disabled="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="editFormData.id" class="row">
                <div class="col">
                    <Grid ref="servicesInSetpriceGrid" :key="$route.fullPath" :config="servicesInSetpriceGridConfig" @modified="gridModified($event)" />
                </div>
                <div class="shuttle__buttons">
                    <h6>&nbsp;</h6>
                    <div class="row">
                        <div class="col">
                            <ButtonCustom
                                :disabled="!isFormEditable"
                                buttonClass="btn-outline-secondary"
                                iconClass="fas fa-angle-left fa-fw"
                                :title="$t('app.add')"
                                @click="addService"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <ButtonCustom
                                :disabled="!isFormEditable"
                                buttonClass="btn-outline-secondary"
                                iconClass="fas fa-angle-right fa-fw"
                                :title="$t('app.delete')"
                                @click="removeService"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <ButtonCustom
                                :disabled="!isFormEditable"
                                buttonClass="btn-outline-secondary"
                                iconClass="fas fa-angle-double-right fa-fw"
                                :title="$t('app.deleteAll')"
                                @click="removeServiceAll"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <ButtonCustom
                                :disabled="!isFormEditable"
                                buttonClass="btn-outline-secondary"
                                iconClass="fas fa-sitemap fa-fw"
                                :title="$t('component.SetpriceEdit.addByGroup')"
                                @click="displayServiceGroups"
                            />
                        </div>
                    </div>
                    <div v-if="this.contractTypeId" class="row">
                        <div class="col">
                            <ButtonCustom
                                :disabled="!isFormEditable"
                                buttonClass="btn-outline-secondary"
                                iconClass="fas fa-file-alt fa-fw"
                                :title="$t('component.SetpriceEdit.addByProgram')"
                                @click="displayPrograms"
                            />
                        </div>
                    </div>
                </div>
                <div class="col">
                    <Grid ref="servicesOutOfSetpriceGrid" :key="$route.fullPath" :config="servicesOutOfSetpriceGridConfig" />
                </div>
            </div>
        </div>

        <ModalWindow v-if="isDisplayWarningRollback" minWidth="300px" :buttons="['yes', 'no']" @yes="processRollback" @no="isDisplayWarningRollback = false" >
            {{ $t('app.confirmRollback') }}
        </ModalWindow>

        <ModalWindow
            v-if="isDisplayPriceParameters"
            :header="$t('component.SetpriceEdit.changePrices')"
            width="400px"
            :buttons="['ok', 'cancel']"
            @ok="processChangePrice"
            @cancel="isDisplayPriceParameters = false"
        >
            <div class="form-row">
                <div class="col">
                    <FieldNumber
                        :label="$t('component.SetpriceEdit.percentage')"
                        :value.sync="editFormPriceParameters.percentage"
                        :saveState="`${$options.name}.percentage`"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <FieldRadiobutton
                        :label="$t('component.SetpriceEdit.rounding')"
                        :value.sync="editFormPriceParameters.rounding"
                        :source="roundingValueList"
                        order="none"
                        :saveState="`${$options.name}.rounding`"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="col-sm">
                    {{ $t('app.example') }}: {{ exampleValue }} =&gt; {{ exampleRounded }}
                </div>
            </div>
        </ModalWindow>

        <ModalWindow
            v-if="isDisplayServiceGroups"
            :header="$t('component.SetpriceEdit.addByGroup')"
            minWidth="360px"
            width="80%"
            maxWidth="1024px"
            :buttons="[]"
            @cancel="isDisplayServiceGroups = false"
        >
            <div class="form-row">
                <div class="col">
                    <TreeView
                        :treeData="serviceGroupTree"
                        :displayEmptyGroups="true"
                        :bottom="60"
                        @select="addServicesByGroup($event)"
                    />
                </div>
            </div>
        </ModalWindow>

        <ModalWindow
            v-if="isDisplayPrograms"
            :header="$t('component.SetpriceEdit.addByProgram')"
            minWidth="360px"
            width="80%"
            maxWidth="1024px"
            :buttons="[]"
            @cancel="isDisplayPrograms = false"
        >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid
                            ref="requests"
                            :source="programs"
                            :config="programGridConfig"
                            @item-selected="addServicesByProgram($event)"
                        />
                    </div>
                </div>
            </div>
        </ModalWindow>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import Grid from '@/components/lib/regions/Grid';
    import TreeView from '@/components/lib/regions/TreeView';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldRadiobutton from '@/components/lib/controls/FieldRadiobutton';
    import FieldText from '@/components/lib/controls/FieldText';

    export default {
        name: 'SetpriceEdit',

        components: {
            TemplatePageStandard, ModalWindow, Grid, TreeView,
            ButtonClose, ButtonSave, ButtonDelete, ButtonCustom,
            FieldNumber, FieldDate, FieldSelect, FieldRadiobutton, FieldText,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                editFormData: {
                    // id: null,
                    fin_pricelist_id: null,
                    date_from: null,
                    is_commited: false,
                    commentary: null,
                },
                oldFormData: {},
                editFormPriceParameters: {
                    percentage: 0,
                    rounding: 0,
                },
                exampleValue: 12345.67,
                roundingValueList: [
                    { id:-3, name:'1000' },
                    { id:-2, name:'100' },
                    { id:-1, name:'10' },
                    { id:0, name:'0' },
                    { id:1, name:'0.1' },
                    { id:2, name:'0.01' },
                ],
                isFormModified: false,
                isDisplayWarningRollback: false,
                isDisplayPriceParameters: false,
                isDisplayServiceGroups: false,
                isDisplayPrograms: false,
                serviceGroupTree: [],
                programs: [],
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.finances') },
                    { text: this.$t('MainMenu.finances-setprices'), to: `/finances/setprices` },
                    { text: this.$t('object.fin_setprice') },
                ],
                servicesInSetpriceGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title: this.$t('component.SetpriceEdit.servicesInSetprice'),
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), required: true, readonly:true, align:'right', width:'50px' },
                        { type:'string', key:'service_code', header:this.$t('attribute.common.code'), readonly: true, style:'white-space:nowrap;' },
                        { type:'string', key:'service_name', header:this.$t('attribute.common.name'), readonly: true },
                        { type:'number', key:'price_initial', header:this.$t('attribute.fin_setprice_detail.price_initial'), readonly: true },
                        {
                            type:'number', key:'price_new', header:this.$t('attribute.fin_setprice_detail.price_new'),
                            $cellStyle:(entry) => ( entry.price_new === entry.price_initial ? 'background-color: #fffbe5;' : '' ),
                            // $style:(entry) => ( entry.price_new === entry.price_initial ? 'background-color: #fffbe5;' : '' ),
                        },
                    ],
                    order: [ 'service_code' ],
                    displayToolbar: true,
                    buttons: [ 'row-selector' ],
                    paginationPageSize: 15,
                },
                isServicesInSetpriceGridModified: false,
                servicesOutOfSetpriceGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title: this.$t('component.SetpriceEdit.servicesOutOfSetprice'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), required: true,readonly:true, align:'right', width:'50px' },
                        { type:'string', key:'code', header:this.$t('attribute.common.code'), style:'white-space:nowrap;' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        { type:'number', key:'price', header:this.$t('attribute.common.price') },
                    ],
                    order: [ 'code' ],
                    displayToolbar: true,
                    buttons: [ 'row-selector' ],
                    paginationPageSize: 15,
                },
                contractTypeId: null,
                programGridConfig: {
                    // title: this.$t('component.SetpriceEdit.servicesOutOfSetprice'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right' },
                        { type:'string', key:'contract_name', header:this.$t('object.fin_contract') },
                        { type:'string', key:'contract_type', header:this.$t('enum.contract_type') },
                        { type:'string', key:'name', header:this.$t('object.fin_program') },
                        { type:'date', key:'date_from', header:this.$t('attribute.common.date_from') },
                        { type:'date', key:'date_to', header:this.$t('attribute.common.date_to') },
                        { type:'string', key:'pricelist_name', header:this.$t('object.fin_pricelist') },
                        // { type:'select', key:'core_org_id', header:this.$t('object.core_org'), source:'core_org' },
                        // { type:'select', key:'contract_type_id', header:this.$t('Fin.contract_type'), source:'enum/contract_type' },
                        // { type:'select', key:'fin_currency_id', header:this.$t('object.fin_currency'), source:'fin_currency', keyLabel:'name_short', align:'center;' },
                        // { type:'checkbox', key:'is_deposit', header:this.$t('attribute.fin_contract.is_deposit'), width:'50px', align:'center;' },
                    ],
                    order: [ 'contract_name', 'name' ],
                    displayToolbar: true,
                    // paginationPageSize: 15,
                    row: { emit:'item-selected', style:'cursor: pointer;' },
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            isFormEditable() {
                return !this.editFormData.is_commited;
            },
            exampleRounded() {
                // console.log(`DEBUG: ${this.$options.name}.exampleRounded(), typeof this.editFormPriceParameters.rounding=`, typeof this.editFormPriceParameters.rounding);
                return tools.round(this.exampleValue * (100 + this.editFormPriceParameters.percentage) / 100, this.editFormPriceParameters.rounding);
            },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                this.buttons = [];
                if (this.itemId) {
                    // console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.editFormData=`, this.editFormData);
                    this.buttons.push('close');
                    this.buttons.push('save');
                    // console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.$refs.servicesInSetpriceGrid=`, this.$refs.servicesInSetpriceGrid);
                    // if (this.$refs.servicesInSetpriceGrid) console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.$refs.servicesInSetpriceGrid.getTotalRowsQty()=`, this.$refs.servicesInSetpriceGrid.getTotalRowsQty());
                    if (this.$refs.servicesInSetpriceGrid && this.$refs.servicesInSetpriceGrid.getTotalRowsQty() > 0) {
                        if (this.editFormData.is_commited) {
                            this.buttons.push('rollback');
                            this.servicesInSetpriceGridConfig.readonly = true;
                            this.servicesInSetpriceGridConfig.buttons = [];
                        } else {
                            this.buttons.push('commit');
                            this.buttons.push('change-prices');
                            this.servicesInSetpriceGridConfig.readonly = false;
                            this.servicesInSetpriceGridConfig.buttons = [ 'row-selector' ];
                        }
                    } else {
                        this.buttons.push('delete');
                    }
                    if (this.$refs.servicesOutOfSetpriceGrid) {
                        if (this.editFormData.is_commited) {
                            this.servicesOutOfSetpriceGridConfig.buttons = [];
                        } else {
                            this.servicesOutOfSetpriceGridConfig.buttons = [ 'row-selector' ];
                        }
                    }
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            checkModified() {
                this.isModified = this.isFormModified || this.isServicesInSetpriceGridModified;
            },

            refreshGrids() {
                // console.log(`DEBUG: ${this.$options.name}.refreshGrids(), this.editFormData=`, this.editFormData);
                if (this.itemId) {
                    this.servicesInSetpriceGridConfig.apiUrl = `fin-services-in-setprice/${this.itemId}`;
                    this.servicesOutOfSetpriceGridConfig.apiUrl = `fin-services-out-of-setprice/${this.itemId}`;
                    if (this.$refs.servicesInSetpriceGrid) this.$refs.servicesInSetpriceGrid.refresh();
                    if (this.$refs.servicesOutOfSetpriceGrid) this.$refs.servicesOutOfSetpriceGrid.refresh();
                }
            },

            async getData() {
                try {
                    this.contractTypeId = await tools.getEnumId(this, 'contract_type', 'insurance');
                    // console.log(`DEBUG: ${this.$options.name}.getData() contractTypeId=`, this.contractTypeId);
                } catch (err) {
                    // it's ok, do nothing
                }
                try {
                    this.editFormData = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_setprice/${this.itemId}`)).data;
                    this.isFormModified = false;
                    this.msgItemModified = '';
                    // this.breadcrumb[this.breadcrumb.length - 1].text = response.data.name;
                    this.itemId = this.editFormData.id;
                    this.checkButtons();
                    this.oldFormData = tools.shallowCopy(this.editFormData);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                this.checkModified();
                this.refreshGrids();
            },

            async saveData() {
                if (this.isFormModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update document
                            // let response = await this.authenticatedAxios.patch(this.apiUrl, this.editFormData);
                            await this.authenticatedAxios.put(`${this.baseUrl}/fin_setprice/${this.editFormData.id}`, this.editFormData);
                            tools.displaySuccessSave(this);
                            this.getData();
                        } else {
                            // New document created
                            let response = await this.authenticatedAxios.post(`${this.baseUrl}/fin_setprice`, this.editFormData);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response.data=`, response.data);
                            this.itemId = response.data.id;
                            tools.displaySuccessSave(this);
                            await this.getData();
                            this.$router.replace({ name: 'finances/setprice-edit', params: { setpriceId:this.itemId } });
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                if (this.$refs.servicesInSetpriceGrid) this.$refs.servicesInSetpriceGrid.$emit('save');
            },

            gridModified(evt) {
                // console.log(`DEBUG: ${this.$options.name}.gridModified(), evt=`, evt);
                this.isServicesInSetpriceGridModified = evt;
                this.checkModified();
                this.checkButtons();
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified(), this.editFormData=`, this.editFormData);
                if (
                    JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData)
                ) {
                    this.isFormModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.isFormModified = false;
                    this.msgItemModified = '';
                }
                this.checkModified();
                this.refreshGrids();
            },

            async addService() {
                let payload = this.$refs.servicesOutOfSetpriceGrid.getSelectedRows().map( item => item.id);
                // console.log(`DEBUG: ${this.$options.name}.addService(), payload=`, payload);
                try {
                    await this.saveData();
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-setprice-add-services/${this.itemId}`, payload);
                    tools.displaySuccessSave(this.$parent);
                    this.refreshGrids();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            // async addServiceAll() {
            //     try {
            //         await this.authenticatedAxios.post(`${this.baseUrl}/fin-setprice-add-services/${this.itemId}`);
            //         tools.displaySuccessSave(this.$parent);
            //         this.refreshGrids();
            //     } catch (err) {
            //         tools.displayApiError(this, err);
            //     }
            // },

            async displayServiceGroups() {
                try {
                    let groupFilterString = tools.getFilterString({ order:[ 'name' ]});
                    let serviceGroups = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_service_group${groupFilterString}`)).data.rows;
                    serviceGroups.map(item => (item.isGroup = true));
                    this.serviceGroupTree = tools.listToTree(serviceGroups);
                    // console.log(`DEBUG: ${this.$options.name}.displayServiceGroups(), serviceGroups=`, serviceGroups);
                    // console.log(`DEBUG: ${this.$options.name}.displayServiceGroups(), this.serviceGroupTree=`, this.serviceGroupTree);
                    this.isDisplayServiceGroups = true;
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async displayPrograms() {
                try {
                    // let groupFilterString = tools.getFilterString({
                    //     where: [{ contract_type_id:this.contractTypeId }],
                    //     order: [ 'name' ],
                    // });
                    this.programs = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-setprice-get-programs`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.displayServiceGroups(), this.programs=`, this.programs);
                    this.isDisplayPrograms = true;
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async addServicesByGroup(serviceGroup) {
                // console.log(`DEBUG: ${this.$options.name}.addServicesByGroup(), serviceGroup=`, serviceGroup);
                this.isDisplayServiceGroups = false;
                let payload = { fin_service_group_id: serviceGroup.id };
                try {
                    await this.saveData();
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-setprice-add-services-by-group/${this.itemId}`, payload);
                    tools.displaySuccessSave(this.$parent);
                    this.refreshGrids();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async addServicesByProgram(program) {
                // console.log(`DEBUG: ${this.$options.name}.addServicesByProgram(), program=`, program);
                this.isDisplayPrograms = false;
                this.editFormData.fin_pricelist_id = program.fin_pricelist_id;
                this.itemModified();
                let payload = { fin_program_id: program.id };
                try {
                    await this.saveData();
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-setprice-add-services-by-program/${this.itemId}`, payload);
                    tools.displaySuccessSave(this.$parent);
                    this.refreshGrids();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async removeService() {
                let payload = this.$refs.servicesInSetpriceGrid.getSelectedRows().map( item => item.id);
                // console.log(`DEBUG: ${this.$options.name}.removeService(), payload=`, payload);
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-setprice-delete-services/${this.itemId}`, payload);
                    tools.displaySuccessSave(this.$parent);
                    this.refreshGrids();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async removeServiceAll() {
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-setprice-delete-services/${this.itemId}`);
                    tools.displaySuccessSave(this.$parent);
                    this.refreshGrids();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async CommitDocument() {
                await this.saveData();
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-setprice-commit/${this.itemId}`);
                    tools.displaySuccessSave(this);
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async processRollback() {
                this.isDisplayWarningRollback = false;
                await this.saveData();
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-setprice-rollback/${this.itemId}`);
                    tools.displaySuccessSave(this);
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async processChangePrice() {
                this.isDisplayPriceParameters = false;
                // console.log(`DEBUG: ${this.$options.name}.removeService(), this.editFormPriceParameters=`, this.editFormPriceParameters);
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-setprice-change-prices/${this.itemId}`, this.editFormPriceParameters);
                    tools.displaySuccessSave(this);
                    this.refreshGrids();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.baseUrl}/fin_setprice/${this.itemId}`);
                        this.isFormModified = false;
                        this.checkModified();
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemId = parseInt(this.$route.params.setpriceId);
            // if (this.itemId) this.breadcrumb[3].text = `#${this.itemId}`;
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.$route.params=`, this.$route.params);
        },

        async mounted() {
            if (this.itemId) {
                await this.getData();
            }
            this.oldFormData = tools.shallowCopy(this.editFormData);
            this.checkButtons();
        },
    }
</script>

<style>
    .shuttle__buttons h6 {
        height: 18px;
    }

    .shuttle__buttons .row {
        margin-bottom:4px;
    }

    .shuttle__buttons button {
        width:64px;
    }
</style>
