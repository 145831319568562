/*
    Author: Eduard Grebenyukov
    Date: 2020-04-22
*/

import { i18n } from '@/locales';
// import { sexKind } from './constants';
import { visitMenu } from "./visitMenu";

const pageConfigVisit = {
    visit_type: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            // { text: i18n.t('MainMenu.settings-visits') },
            { text: i18n.t('MainMenu.settings-visits-types') }
        ],
        gridConfig: {
            apiUrl: 'visit_type',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'select', key:'core_role_id', header:i18n.t('object.core_role'), source:'core_role', showEmptyRow:true },
                { type:'number', key:'duration', header:i18n.t('attribute.common.duration') },
                { type:'color', key:'bgcolor', header:i18n.t('attribute.common.bgcolor') },
                { type:'color', key:'color', header:i18n.t('attribute.common.color') },
                {
                    type:'html', key:'$colorExample', header:i18n.t('app.example'), readonly:true, value: i18n.t('app.example'),
                    $cellStyle: entry => `background-color:${entry.bgcolor}; color:${entry.color}`, sortable: false
                },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete', 'edit' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'visit_type',
            // onAdd: () => { router.push({ name: 'settings/visits/visit-type-edit', params: { id: null }}); },
            // onEdit: (id) => { router.push({ name: 'settings/visits/visit-type-edit', params: { id }}); },
            onEdit: 'settings/visits/visit-type-edit'
        }
    },
    visit_type_page: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            // { text: i18n.t('MainMenu.settings-visits') },
            { text: i18n.t('MainMenu.settings-visits-type-pages') }
        ],
        gridConfig: {
            apiUrl: 'visit_type_page',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'select', key:'visit_type_id', header:i18n.t('object.visit_type'), source:'visit_type', showEmptyRow:true },
                { type:'select', key:'code', header:i18n.t('attribute.visit_type_page.code'), required:true, source:visitMenu, keyId:'code' }, // keyLabel - name: for admin, title: for end user
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'visit_type_id' ],
            displayToolbar: true,
            saveSessionState: 'visit_type_page',
        }
    },
    visit_group: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            // { text: i18n.t('MainMenu.settings-visits') },
            { text: i18n.t('MainMenu.settings-visits-groups') }
        ],
        gridConfig: {
            apiUrl: 'visit_group',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'time', key:'time_from', header:i18n.t('attribute.common.time_from') },
                { type:'time', key:'time_to', header:i18n.t('attribute.common.time_to') },
                { type:'number', key:'duration', header:i18n.t('Settings.interval') },
                { type:'number', key:'scale', header:i18n.t('Settings.scale') },
                { type:'select', key:'core_org_id', header:i18n.t('object.core_org'), required:true, source:'v_org?filter={"where":[{"org_type_code":"medical"}]}', showEmptyRow:true },
                { type:'select', key:'core_building_id', header:i18n.t('object.core_building'), required:true, source:'core_building', showEmptyRow:true },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete', 'edit' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'visit_group',
            onEdit: 'settings/visits/visit-group-workplaces'
        }
    },
    visit_lock_type: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            // { text: i18n.t('MainMenu.settings-visits') },
            { text: i18n.t('MainMenu.settings-visits-lock-types') }
        ],
        gridConfig: {
            apiUrl: 'visit_lock_type',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'color', key:'bgcolor', header:i18n.t('attribute.common.bgcolor') },
                { type:'color', key:'color', header:i18n.t('attribute.common.color') },
                // { type:'string', key:'$colorExample', header:i18n.t('app.example'), readonly:true, value: i18n.t('app.example'), $cellStyle: '`background-color:${entry.bgcolor}; color:${entry.color}`', sortable: false },
                {
                    type:'string', key:'$colorExample', header:i18n.t('app.example'), readonly:true, value: i18n.t('app.example'),
                    $cellStyle: entry => `background-color:${entry.bgcolor}; color:${entry.color}`, sortable: false
                },
                { type:'checkbox', key:'is_user_available', header:i18n.t('Settings.is_user_available'), defaultValue:false, align:'center' },
                { type:'checkbox', key:'is_block', header:i18n.t('Settings.is_block'), defaultValue:false, align:'center' },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'visit_lock_type',
        }
    },
    visit_visitor_group: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-visits') },
            { text: i18n.t('MainMenu.settings-visits-visitor-groups') }
        ],
        gridConfig: {
            apiUrl: 'visit_visitor_group',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'select', key:'visit_route_id', header:i18n.t('object.visit_route'), source:'visit_route', showEmptyRow:true },
                { type:'string', key:'letter', header:i18n.t('attribute.visit_visitor_group.letter') },
                { type:'checkbox', key:'is_display_to_visitor', header:i18n.t('attribute.visit_visitor_group.is_display_to_visitor'), defaultValue:false, align:'center' },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete', 'edit' ],
            order: [ 'letter', 'code' ],
            displayToolbar: true,
            saveSessionState: 'visit_visitor_group',
        }
    },
    visit_route: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-visits') },
            { text: i18n.t('MainMenu.settings-visits-routes') }
        ],
        gridConfig: {
            apiUrl: 'visit_route',
            readonly: true,
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'select', key:'visit_event_type_id', header:i18n.t('object.visit_event_type'), source:'visit_event_type', showEmptyRow:true },
            ],
            buttons: [ 'edit', 'add', 'save' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'visit_route',
            onAdd:'settings/visits/route-new',
            onEdit:'settings/visits/route-edit',
        }
    },
}

export { pageConfigVisit };
