<!--
    Author: Eduard Grebenyukov
    Date: Date: 2021-02-11
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <div id="page-content">
            <Grid ref="grid" :key="$route.fullPath" :config="gridConfig" />
        </div>
    </TemplatePageStandard>
</template>

<script>
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'MatDocArrivals',

        components: { TemplatePageStandard, Grid },

        // =============== Data ===============
        data() {
            return {
                isHideMissed: true,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.store') },
                    { text: this.$t('MainMenu.store-arrivals') },
                ],
                gridConfig: {
                    apiUrl: 'mat-doc-arrival',
                    readonly: true,
                    columns: [
                        {
                            type:'icon', key:'is_commited',
                            header:this.$t('attribute.common.is_commited_short'), headerTitle:this.$t('attribute.common.is_commited'),
                            width:'50px', align:'center;',
                            $icon: entry => entry.is_commited ? 'fas fa-check-square' : '',
                            style:'color:green;'
                        },
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right' },
                        { type:'string', key:'doc_num', header:this.$t('attribute.mat_doc.doc_num') },
                        { type:'datetime', key:'time_of', header:this.$t('app.datetime') },
                        { type:'string', key:'base_doc_num', header:this.$t('attribute.mat_doc.base_doc_num') },
                        { type:'date', key:'base_doc_date', header:this.$t('attribute.mat_doc.base_doc_date') },
                        // { type:'string', key:'org_name', header:this.$t('object.core_org') },
                        { type:'string', key:'contractor_name', header:this.$t('attribute.mat_doc.contractor_id') },
                        { type:'string', key:'store_dest_name', header:this.$t('attribute.mat_doc.store_dest_id') },
                        // { type:'string', key:'dept_dest_name', header:this.$t('attribute.mat_doc.dept_dest_id') },
                        { type:'number', key:'unit_qty', header:this.$t('attribute.common.qty'), align:'right' },
                        { type:'number', key:'sum_net', header:this.$t('attribute.common.sum'), align:'right' },
                        { type:'number', key:'sum_with_vat', header:this.$t('attribute.common.sum_with_vat'), align:'right' },
                        { type:'string', key:'user_modified_shortname', header:this.$t('attribute.common.employee') },
                    ],
                    order: [{ key:'time_of', direction:'desc' }],
                    buttons: [ 'add', 'edit' ],
                    saveSessionState: `${this.$options.name}`,
                    $onAdd: () => { this.$router.push({ name:'store/arrival-new', params: { id: null } })},
                    $onEdit: (ctx, item) => { this.$router.push({ name:'store/arrival-edit', params: { id:item.id } })},
                },
            }
        },
    }
</script>

<style>
</style>