<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-07
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <div id="page-content">
            <div class="row">
                <div class="col">
                    <Grid ref="servicesInSetGrid" :key="$route.fullPath" :config="servicesInSetGridConfig" @modified="gridModified($event)" />
                </div>
                <div class="shuttle__buttons">
                    <h6>&nbsp;</h6>
                    <div class="row">
                        <div class="col">
                            <button v-on:click="addService" class="btn btn-outline-secondary" type="button" :title="$t('app.add')">
                                <i class="fas fa-angle-left"></i>
                            </button>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col">
                            <button v-on:click="addServiceAll" class="btn btn-outline-secondary" type="button" :title="$t('app.addAll')">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col">
                            <button v-on:click="removeService" class="btn btn-outline-secondary" type="button" :title="$t('app.delete')">
                                <i class="fas fa-angle-right"></i>
                            </button>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col">
                            <button v-on:click="removeServiceAll" class="btn btn-outline-secondary" type="button" :title="$t('app.deleteAll')">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>
                    </div> -->
                </div>
                <div class="col">
                    <Grid ref="servicesOutOfSetGrid" :key="$route.fullPath" :config="servicesOutOfSetGridConfig" />
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    import $ from 'jquery';
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'FinSetEdit',
        components: {
            TemplatePageStandard, Grid,
        },

        // =============== Data ===============
        data() {
            return {
                id: Number,
                isModified: Boolean,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-finance') },
                    { text: this.$t('MainMenu.settings-finance-service-sets'), to:'/settings/finance/sets' },
                    { text: '' },
                ],
                servicesInSetGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title: this.$t('component.FinSetEdit.servicesInSet'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), required: true,readonly:true, align:'right', width:'50px' },
                        // { type:'hidden', key:'fin_set_id', header:this.$t('object.fin_set'), required:true, source:'fin_set' },
                        // { type:'select', key:'fin_service_id', header:this.$t('object.fin_service'), required:true, source:'fin_service' },
                        { type:'string', key:'service_code', header:this.$t('attribute.common.code'), style:'white-space:nowrap;' },
                        { type:'string', key:'service_name', header:this.$t('attribute.common.name') },
                    ],
                    order: [ 'service_code' ],
                    displayToolbar: true,
                    buttons: [ 'row-selector' ],
                    paginationPageSize: 15,
                },
                servicesOutOfSetGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title: this.$t('component.FinSetEdit.servicesOutOfSet'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), required: true,readonly:true, align:'right', width:'50px' },
                        { type:'string', key:'code', header:this.$t('attribute.common.code'), style:'white-space:nowrap;' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                    ],
                    order: [ 'code' ],
                    displayToolbar: true,
                    buttons: [ 'row-selector' ],
                    paginationPageSize: 15,
                },
                // servicesOutOfSetArray: [],
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Watch ===============
        watch: {
            isModified() {
                if (this.isModified) {
                    $('#breadcrumb').addClass('modified');
                } else {
                    $('#breadcrumb').removeClass('modified');
                }
            },
        },

        // =============== Methods ===============
        methods: {
            async getData() {
                try {
                    let response = await this.authenticatedAxios.get(`${this.baseUrl}/fin_set/${this.itemId}`);
                    this.breadcrumb[this.breadcrumb.length - 1].text = response.data.name;

                    // this.servicesOutOfSetGridConfig.apiUrl = `fin-get-services-out-of-set/${this.itemId}`;
                    // this.$refs.servicesOutOfSetGrid.refresh();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            gridModified(e) {
                this.isModified = e;
            },

            async addService() {
                let payload = this.$refs.servicesOutOfSetGrid.getSelectedRows().map( item => item.id);
                // console.log(`DEBUG: ${this.$options.name}.addService(), payload=`, payload);
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-set-add-services/${this.itemId}`, payload);
                    tools.displaySuccessSave(this.$parent);
                    this.$refs.servicesOutOfSetGrid.refresh();
                    this.$refs.servicesInSetGrid.refresh();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            // async addServiceAll() {
            //     try {
            //         await this.authenticatedAxios.post(`${this.baseUrl}/fin-set-add-services/${this.itemId}`);
            //         tools.displaySuccessSave(this.$parent);
            //         this.$refs.servicesOutOfSetGrid.refresh();
            //         this.$refs.servicesInSetGrid.refresh();
            //     } catch (err) {
            //         tools.displayApiError(this, err);
            //     }
            // },

            async removeService() {
                let payload = this.$refs.servicesInSetGrid.getSelectedRows().map( item => item.id);
                // console.log(`DEBUG: ${this.$options.name}.removeService(), payload=`, payload);
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-set-delete-services/${this.itemId}`, payload);
                    tools.displaySuccessSave(this.$parent);
                    this.$refs.servicesOutOfSetGrid.refresh();
                    this.$refs.servicesInSetGrid.refresh();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            // async removeServiceAll() {
            //     try {
            //         await this.authenticatedAxios.post(`${this.baseUrl}/fin-set-delete-services/${this.itemId}`);
            //         tools.displaySuccessSave(this.$parent);
            //         this.$refs.servicesOutOfSetGrid.refresh();
            //         this.$refs.servicesInSetGrid.refresh();
            //     } catch (err) {
            //         tools.displayApiError(this, err);
            //     }
            // },

        },

        // =============== Life cycle ===============
        beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.$route.params.id=`, this.$route.params.id);
            this.itemId = parseInt(this.$route.params.id);
            // this.servicesInSetGridConfig.filter = { where:[{ fin_set_id:this.itemId }]};
            // this.servicesInSetGridConfig.columns.find(x => x.key === 'fin_set_id').defaultValue = this.itemId;
            this.servicesInSetGridConfig.apiUrl = `fin-services-in-set/${this.itemId}`;
            this.servicesOutOfSetGridConfig.apiUrl = `fin-services-out-of-set/${this.itemId}`;
        },

        async mounted() {
            await this.getData();
        },

    }
</script>

<style>
    .shuttle__buttons h6 {
        height: 18px;
    }

    .shuttle__buttons .row {
        margin-bottom:4px;
    }

    .shuttle__buttons button {
        width:64px;
    }
</style>
