/*
    Author: Eduard Grebenyukov
    Date: 2020-04-22
*/

import { i18n } from '@/locales';

const pageConfigFinance = {
    fin_source: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-finance') },
            { text: i18n.t('MainMenu.settings-finance-sources') }
        ],
        gridConfig: {
            apiUrl: 'fin_source',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'fin_source',
        }
    },
    fin_pricelist: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-finance') },
            { text: i18n.t('MainMenu.settings-finance-pricelists') }
        ],
        gridConfig: {
            apiUrl: 'fin_pricelist',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'fin_pricelist',
        }
    },
    fin_price: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-finance') },
            { text: i18n.t('MainMenu.settings-finance-prices') }
        ],
        gridConfig: {
            apiUrl: 'fin_price',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'select', key:'fin_pricelist_id', header:i18n.t('object.fin_pricelist'), required:true, source:'fin_pricelist' },
                { type:'popuplist', key:'fin_service_id', header:i18n.t('object.fin_service'), required:true, source:'v_service', keyLabel:'code_name',
                    listConfig: {
                        readonly:true,
                        columns: [
                            { type:'number', key:'id', header:i18n.t('attribute.common.id'), align:'right', width:'50px' },
                            { type:'string', key:'code_name', header:i18n.t('object.fin_service') },
                        ],
                        order: [ 'code_name' ],
                    },
                },
                { type:'date', key:'date_from', header:i18n.t('attribute.common.date_from'), required:true },
                { type:'date', key:'date_to', header:i18n.t('attribute.common.date_to') },
                { type:'number', key:'price', header:i18n.t('attribute.common.price'), required:true },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'fin_pricelist_id' ],
            displayToolbar: true,
            saveSessionState: 'fin_price',
        }
    },
    fin_vat: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-finance') },
            { text: i18n.t('MainMenu.settings-finance-vats') }
        ],
        gridConfig: {
            apiUrl: 'fin_vat',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'number', key:'pct_value', header:i18n.t('attribute.common.pct_value') },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'fin_vat',
        }
    },
    fin_currency: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-finance') },
            { text: i18n.t('MainMenu.settings-finance-currencies') }
        ],
        gridConfig: {
            apiUrl: 'fin_currency',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'code_numeric', header:i18n.t('Settings.code_numeric'), required:true },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'string', key:'name_short', header:i18n.t('attribute.common.name_short') },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'fin_currency',
        }
    },
    fin_service_group: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-finance') },
            { text: i18n.t('MainMenu.settings-finance-service-groups') }
        ],
        gridConfig: {
            apiUrl: 'fin_service_group',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'select', key:'parent_id', header:i18n.t('attribute.common.parent_id'), source:'fin_service_group', showEmptyRow:true, order:'code', cellStyle:'max-width:20rem;' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code'), cellStyle:'max-width:5rem;' },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true, cellStyle:'min-width:20rem;' },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'fin_service_group',
        }
    },
    fin_service: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-finance') },
            { text: i18n.t('MainMenu.settings-finance-services') }
        ],
        gridConfig: {
            apiUrl: 'fin_service',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'select', key:'fin_service_group_id', header:i18n.t('object.fin_service_group'), required:true, source:'fin_service_group', cellStyle:'max-width:20rem;' },
                { type:'select', key:'service_category_id', header:i18n.t('enum.service_category'), required:true, source:'enum/service_category' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code'), required:true, cellStyle:'max-width:6rem;' },
                { type:'string', key:'code_public', header:i18n.t('attribute.common.code_public'), required:true, cellStyle:'max-width:6rem;' },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true, cellStyle:'min-width:20rem;' },
                { type:'select', key:'fin_vat_id', header:i18n.t('object.fin_vat'), required:true, source:'fin_vat', cellStyle:'max-width:10rem;' },
                { type:'date', key:'date_from', header:i18n.t('attribute.common.date_from'), required:true },
                { type:'date', key:'date_to', header:i18n.t('attribute.common.date_to') },
                // { type:'checkbox', key:'is_discount_disabled', header:i18n.t('attribute.fin_service.is_discount_disabled'), defaultValue:false, width:'50px', align:'center;' },
                { type:'checkbox', key:'is_price_variable', header:i18n.t('attribute.fin_service.is_price_variable'), defaultValue:false, width:'50px', align:'center;' },
                // { type:'checkbox', key:'is_price_composite', header:i18n.t('attribute.fin_service.is_price_composite'), defaultValue:false, width:'50px', align:'center;' },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'fin_service',
        }
    },
    fin_set: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-finance') },
            { text: i18n.t('MainMenu.settings-finance-service-sets') }
        ],
        gridConfig: {
            apiUrl: 'fin_set',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
            ],
            buttons: [ 'row-selector', 'add', 'edit', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'fin_set',
            $onEdit: (ctx, item) => { ctx.$router.push({ path:`/settings/finance/set/${item.id}` }) },
        }
    },
    fin_discount: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-finance') },
            { text: i18n.t('MainMenu.settings-finance-discounts') }
        ],
        gridConfig: {
            apiUrl: 'fin_discount',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'number', key:'pct_value', header:i18n.t('attribute.common.pct_value'), required:true },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'pct_value' ],
            displayToolbar: true,
            saveSessionState: 'fin_discount',
        }
    },
    fin_payment_type: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-finance') },
            { text: i18n.t('MainMenu.settings-finance-payment-types') }
        ],
        gridConfig: {
            apiUrl: 'fin_payment_type',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'select', key:'fin_discount_id', header:i18n.t('Fin.discount_bid'), required:true, source:'fin_discount' },
                // { type:'number', key:'pct_value_max', header:i18n.t('attribute.common.pct_value_max'), width:'50px' },
                { type:'checkbox', key:'is_cash', header:i18n.t('Fin.is_cash'), defaultValue:false, width:'50px', align:'center;' },
                { type:'checkbox', key:'is_bank_card', header:i18n.t('Fin.is_bank_card'), defaultValue:false, width:'50px', align:'center;' },
                { type:'checkbox', key:'is_offset', header:i18n.t('Fin.is_offset'), defaultValue:false, width:'50px', align:'center;' },
                { type:'checkbox', key:'is_refund', header:i18n.t('Fin.is_refund'), defaultValue:false, width:'50px', align:'center;' },
                { type:'checkbox', key:'is_archived', header:i18n.t('attribute.common.is_archived'), defaultValue:false, width:'50px', align:'center;' },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'fin_payment_type',
        }
    },
    fin_contract: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.finances') },
            { text: i18n.t('MainMenu.finances-contracts') }
        ],
        gridConfig: {
            apiUrl: 'fin_contract',
            readonly: true,
            columns: [
                { type:'hidden', key:'id', header:i18n.t('attribute.common.id'), align:'right' },
                { type:'string', key:'name', header:i18n.t('attribute.common.name') },
                { type:'date', key:'date_from', header:i18n.t('attribute.common.date_from') },
                { type:'date', key:'date_to', header:i18n.t('attribute.common.date_to') },
                { type:'select', key:'core_org_id', header:i18n.t('object.core_org'), source:'core_org' },
                { type:'select', key:'contract_type_id', header:i18n.t('Fin.contract_type'), source:'enum/contract_type' },
                { type:'select', key:'fin_currency_id', header:i18n.t('object.fin_currency'), source:'fin_currency', keyLabel:'name_short', align:'center;' },
                { type:'checkbox', key:'is_deposit', header:i18n.t('attribute.fin_contract.is_deposit'), width:'50px', align:'center;' },
                // { type:'number', key:'min_balance', header:i18n.t('attribute.fin_contract.min_balance') },
                // { type:'number', key:'contract_limit_sum', header:i18n.t('attribute.fin_contract.contract_limit_sum') },
                // { type:'hidden', key:'fin_source_id', header:i18n.t('object.fin_currency'), source:'fin_currency' },
            ],
            buttons: [ 'add', 'edit' ],
            order: [ 'name' ],
            saveSessionState: 'fin_contract',
            $onAdd: (grid) => { grid.$router.push({ name: 'finances/contract-new' }) },
            $onEdit: (grid, item) => { grid.$router.push({ name: 'finances/contract-edit', params: { contractId: item.id } }) },
        }
    },
    fin_bill: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.finances') },
            { text: i18n.t('MainMenu.finances-bills') }
        ],
        gridConfig: {
            apiUrl: 'v_bill',
            readonly: true,
            columns: [
                {
                    type:'icon', key:'view_services_icon', header:'', icon:'fas fa-search', title:i18n.t('app.open'),
                    $onClick: (entry, evt, ctx) => {
                        // !!! need to select right services page, dental or not-dental !!!
                        ctx.$router.push({ name: 'visit-services-dental', params: { clientId:entry.core_client_id, visitId:entry.visit_rec_id } });
                    },
                },
                { type:'hidden', key:'id', header:i18n.t('attribute.common.id'), align:'right' },
                // { type:'select', key:'core_client_id', header:i18n.t('object.core_client'), keyLabel:"fullname", source:'v_client' },
                { type:'string', key:'client_fullname', header:i18n.t('object.core_client') },
                { type:'string', key:'bill_num', header:i18n.t('attribute.fin_bill.bill_num'), align:'right' },
                { type:'date', key:'date_of', header:i18n.t('attribute.common.date_of') },
                { type:'select', key:'core_workplace_id', header:i18n.t('app.employee'), source:'v_workplace', keyLabel:'workplace_name' },
                { type:'number', key:'sum_payment', header:i18n.t('component.Payers.sum_payment') }, // !!! wrong, look at v_bill view! payment discount not taken in account
                // { type:'number', key:'sum_paid', header:i18n.t('component.Payers.sum_paid') },
                // { type:'number', key:'sum_unpaid_person', header:i18n.t('component.Payers.sum_unpaid') },
            ],
            order: [ 'bill_num' ],
            saveSessionState: 'fin_bill',
        }
    },
    fin_reversal: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.finances') },
            { text: i18n.t('MainMenu.finances-reversals') }
        ],
        gridConfig: {
            apiUrl: 'v_reversal',
            readonly: true,
            columns: [
                {
                    type:'icon', key:'view_services_icon', header:'', icon:'fas fa-search', title:i18n.t('app.open'),
                    $onClick: (entry, evt, ctx) => {
                        // !!! need to select right services page, dental or not-dental !!!
                        ctx.$router.push({ name: 'finances/reversal-edit', params: { reversalId:entry.id } });
                    },
                },
                { type:'hidden', key:'id', header:i18n.t('attribute.common.id'), align:'right' },
                { type:'string', key:'reversal_num', header:i18n.t('attribute.fin_reversal.reversal_num'), align:'right' },
                { type:'datetime', key:'time_of', header:i18n.t('attribute.common.time_of') },
                { type:'string', key:'client_fullname', header:i18n.t('object.core_client') },
                // { type:'select', key:'core_workplace_id', header:i18n.t('app.employee'), source:'v_workplace', keyLabel:'workplace_name' },
                { type:'number', key:'sum_payment', header:i18n.t('attribute.common.sum') }, // !!! wrong, look at v_bill view! payment discount not taken in account
                // { type:'number', key:'sum_paid', header:i18n.t('component.Payers.sum_paid') },
                // { type:'number', key:'sum_unpaid_person', header:i18n.t('component.Payers.sum_unpaid') },
                { type:'string', key:'user_shortname', header:i18n.t('object.core_user') },
                { type:'string', key:'commentary', header:i18n.t('attribute.common.commentary') },
            ],
            order: [ 'reversal_num' ],
            saveSessionState: 'fin_reversal',
        }
    },
    fin_setprice: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.finances') },
            { text: i18n.t('MainMenu.finances-setprices') }
        ],
        gridConfig: {
            apiUrl: 'fin-get-setprices',
            readonly: true,
            columns: [
                {
                    type:'icon', key:'is_commited',
                    header:i18n.t('attribute.common.is_commited_short'),  headerTitle:i18n.t('attribute.common.is_commited'),
                    width:'50px', align:'center;',
                    $icon: entry => entry.is_commited ? 'fas fa-check-square' : '',
                    style:'color:green;'
                },
                { type:'date', key:'date_from', header:i18n.t('attribute.common.date_of') },
                { type:'number', key:'id', header:i18n.t('attribute.common.doc_num'), align:'right' },
                { type:'select', key:'fin_pricelist_id', header:i18n.t('object.fin_pricelist'), source:'fin_pricelist' },
                { type:'string', key:'commentary', header:i18n.t('attribute.common.commentary') },
                { type:'string', key:'user_modified_shortname', header:i18n.t('attribute.common.employee') },
                { type:'datetime', key:'time_modified', header:i18n.t('attribute.common.time_modified') },
            ],
            buttons: [ 'add', 'edit' ],
            order: [{ key:'date_from', direction:'desc' }],
            saveSessionState: 'fin_setprice',
            $onAdd: (grid) => { grid.$router.push({ name: 'finances/setprice-new' }) },
            $onEdit: (grid, item) => { grid.$router.push({ name: 'finances/setprice-edit', params: { setpriceId: item.id } }) },
        }
    },
}

export { pageConfigFinance };
