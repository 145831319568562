<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-11
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <div id="page-content" class="form-float">
            <div class="row">
                <div :class=" isShowLastClients ? 'col-sm-12 col-xl-9' : 'col' ">
                    <div class="row" :style=" isShowUpcomingVisits ? '' : 'display:none;'">
                        <div class="col">
                            <UpcomingVisits @refreshed="isShowUpcomingVisits = $event" />
                        </div>
                    </div>
                    <div class="row" :style=" isShowUserTasks ? '' : 'display:none;'">
                        <div class="col">
                            <UserTasks @refreshed="isShowUserTasks = $event" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-xl-3" :style=" isShowLastClients ? '' : 'display:none;'" >
                    <!-- Right sidebar -->
                    <LastClients @refreshed="isShowLastClients = $event" />
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    import tools from '@/components/lib/tools';

    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import LastClients from '@/components/pages/clients/LastClients';
    import UpcomingVisits from '@/components/pages/visits/UpcomingVisits';
    import UserTasks from '@/components/pages/tasks/UserTasks';

    export default {
        name: 'HomePage',
    
        components: {
            TemplatePageStandard, UserTasks, UpcomingVisits, LastClients,
        },

        // =============== Data ===============
        data() {
            return {
                breadcrumb: [{ text: this.$t('MainMenu.home') }],
                isShowUpcomingVisits: false,
                isShowUserTasks: false,
                isShowLastClients: false,
            }
        },

        // =============== Life cycle ===============
        async beforeMount() {
            tools.refreshOutdatedPage(this); // no wait
        },
    }
</script>

<style>
</style>
