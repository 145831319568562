<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-09
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">

        <template #buttons v-if="true">
            <button :disabled="reportInProgress" @click.prevent="saveReport()" class="btn btn-sm btn-outline-primary mr-1">
                <i class="fas fa-download"></i> {{ $t('lib.Grid.download') }}
            </button>
        </template>

        <div id="page-content">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
                    <FieldDate
                        :label="$t('attribute.common.date_from')"
                        :value.sync="filterData.date_from"
                        displayClearButton
                        :saveSessionState="`${$options.name}-date_from`"
                        @modified="filterModified"
                    />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
                    <FieldDate
                        :label="$t('attribute.common.date_to')"
                        :value.sync="filterData.date_to"
                        displayClearButton
                        :saveSessionState="`${$options.name}-date_to`"
                        @modified="filterModified"
                    />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
                    <FieldSelect
                        :label="$t('object.core_building')"
                        :value.sync="filterData.core_building_id"
                        source="core_building"
                        keyLabel="name_short"
                        showEmptyRow
                        :saveSessionState="`${$options.name}-core_building_id`"
                        @modified="filterModified"
                    />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
                    <FieldPopupList
                        id="core_client_id"
                        :label="$t('object.core_client')"
                        :value.sync="filterData.core_client_id"
                        source="v_client"
                        keyLabel="fullname"
                        showEmptyRow
                        maxWidth="1000px"
                        :listConfig="clientListConfig"
                        :saveSessionState="`${$options.name}-core_client_id`"
                        @modified="filterModified"
                    />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
                    <FieldPopupList
                        id="performer_user_id"
                        :label="$t('attribute.common.performer')"
                        :value.sync="filterData.performer_user_id"
                        source="v_user"
                        keyLabel="shortname"
                        showEmptyRow
                        maxWidth="600px"
                        :listConfig="userListConfig"
                        :saveSessionState="`${$options.name}-performer_user_id`"
                        @modified="filterModified"
                    />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
                    <FieldPopupList
                        id="initiator_user_id"
                        :label="$t('attribute.common.initiator')"
                        :value.sync="filterData.initiator_user_id"
                        source="v_user"
                        keyLabel="shortname"
                        showEmptyRow
                        maxWidth="600px"
                        :listConfig="userListConfig"
                        :saveSessionState="`${$options.name}-initiator_user_id`"
                        @modified="filterModified"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <Grid
                        ref="taskGrid"
                        :config="gridConfig"
                        @add-item="addItem"
                        @edit-item="editItem($event.id)"
                        @open-client="openClient($event)"
                    >
                        <!-- @open-visit="openVisit($event)" -->
                        <template #buttons>
                            <FieldSwitch
                                id="isHideClosed"
                                :label="$t('component.Tasks.isHideClosed')"
                                labelPosition="after"
                                size="small"
                                :value.sync="filterData.isHideClosed"
                                :defaultValue="true"
                                :saveSessionState="`${$options.name}-isHideClosed`"
                                @modified="filterModified"
                            />
                            <FieldSwitch
                                id="isHideUntimely"
                                :label="$t('component.Tasks.isHideUntimely')"
                                labelPosition="after"
                                size="small"
                                :value.sync="filterData.isHideUntimely"
                                :defaultValue="true"
                                :saveSessionState="`${$options.name}-isHideUntimely`"
                                @modified="filterModified"
                            />
                            <FieldNumberPlain
                                v-if="true"
                                :label="$t('attribute.common.rows_qty')"
                                labelPosition="before"
                                :value.sync="filterData.row_qty"
                                :defaultValue="20"
                                :minValue="10"
                                :maxValue="10000"
                                :saveSessionState="`${$options.name}-row_qty`"
                                @modified="filterModified"
                            />
                            <!-- <b-form-checkbox v-model="isHideClosed" inline >{{ $t('component.Tasks.isHideClosed') }}</b-form-checkbox> -->
                            <!-- <b-form-checkbox v-model="isHideUntimely" inline >{{ $t('component.Tasks.isHideUntimely') }}</b-form-checkbox> -->
                        </template>
                    </Grid>
                </div>
            </div>
            <TaskContextMenu ref="taskContextMenu" @refresh-status="$refs.taskGrid.refresh()" />
        </div>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldPopupList from '@/components/lib/controls/FieldPopupList';
    import FieldNumberPlain from '@/components/lib/controls/FieldNumberPlain';
    import TaskContextMenu from '@/components/pages/tasks/TaskContextMenu';

    export default {
        name: 'Tasks',
        components: {
            TemplatePageStandard, Grid,
            FieldSwitch, FieldDate, FieldSelect, FieldPopupList, FieldNumberPlain,
            TaskContextMenu,
        },

        // =============== Data ===============
        data() {
            return {
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.tasks') },
                ],
                filterData: {
                    date_from: null,
                    date_to: null,
                    core_building_id: null,
                    core_client_id: null,
                    performer_user_id: null,
                    initiator_user_id: null,
                    isHideClosed: true,
                    isHideUntimely: true,
                    row_qty: 20,
                },
                reportInProgress: false,
                gridConfig: {
                    apiUrl: null,
                    readonly: true,
                    columns: [
                        {
                            type:'icon', key:'icon', sortable:false, align:'center',
                            $title:(entry) => tools.getTaskStatusText(entry), 
                            $icon: entry => tools.getTaskIcon(entry),
                            $cellStyle: entry => `color:${tools.getTaskColor(entry)};`,
                        },
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), required:true, align:'right' },
                        { type:'datetime', key:'time_start', header:this.$t('attribute.common.time_start') },
                        { type:'datetime', key:'time_end', header:this.$t('attribute.common.term') },
                        { type:'select', key:'task_type_id', header:this.$t('enum.task_type'), required:true, source:'enum/task_type' },
                        {
                            type:'string', key:'client_shortname', header:this.$t('object.core_client'), class:'link', style:'white-space:nowrap; color: #056bbf;',
                            emit:'open-client'
                            // $textOnClick: async (entry, evt, ctx) => {
                            //     // console.log('entry=', entry);
                            //     await this.setLastClientId(entry.core_client_id);
                            //     this.$router.push({ name: 'client-edit', params: { id:entry.core_client_id } });
                            // }
                        },
                        // {
                        //     type:'number', key:'visit_rec_id', header:this.$t('object.visit_rec'), class:'link', style:'white-space:nowrap; color: #056bbf;',
                        //     emit:'open-visit'
                        //     // $value:(entry) => `#${entry.visit_rec_id}`,
                        //     // $textOnClick: async (entry, evt, ctx) => {
                        //     //     // console.log('entry=', entry);
                        //     //     await this.setLastClientId(entry.core_client_id);
                        //     //     await thiss.setLastVisitId(entry.visit_rec_id);
                        //     //     this.$router.push({ name: 'client-visits', params: { clientId:entry.core_client_id } });
                        //     // }
                        // },
                        { type:'string', key:'building_name_short', header:this.$t('object.core_building') },
                        { type:'string', key:'performer_name', header:this.$t('attribute.common.performer') },
                        { type:'string', key:'status_name_short', header:this.$t('attribute.common.status') },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        // { type:'string', key:'description', header:this.$t('attribute.common.description') },
                        { type:'string', key:'initiator_name', header:this.$t('attribute.common.initiator') },
                    ],
                    row: {
                        $onRightClick: (visit, evt) => {
                            this.showContextMenu(evt, visit);
                        },
                    },
                    buttons: [ 'add', 'edit' ],
                    order: [ 'time_start' ],
                    saveSessionState: `${this.$options.name}`,
                },
                clientListConfig: {
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'string', key:'code', header:this.$t('attribute.common.code') },
                        { type:'string', key:'fullname', header:this.$t('object.core_client') },
                        { type:'select', key:'sex', header:this.$t('attribute.core_person.sex'), source: this.sexKind, keyId: 'code', keyLabel: 'name_short' },
                        { type:'date', key:'dob', header:this.$t('attribute.core_person.dob') },
                        { type:'string', key:'phones', header:this.$t('Contacts.phones') },
                        { type:'string', hidden:true, key:'phones_stripped', header:this.$t('Contacts.phones') },
                     ],
                    order: [ 'fullname' ],
                    displayToolbar: true,
                },
                userListConfig: {
                    apiUrl: 'v_user',
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' },
                        { type:'string', key:'fullname', header:this.$t('attribute.core_person.fullname') },
                    ],
                    order: [ 'fullname' ],
                    row: { emit: "item-selected", style: "cursor: pointer;" },
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),
        },

        // =============== Watch ===============
        // watch: {
        //     isHideClosed() {
        //         this.setGridConfig();
        //     },
        //     isHideUntimely() {
        //         this.setGridConfig();
        //     },
        // },

        // =============== Methods ===============
        methods: {
            ...mapActions(['setLastClientId', 'setLastVisitId']),

            addItem() {
                this.$router.push({ name: 'tasks/task-new', params: { taskId:null } });
            },

            editItem(taskId) {
                this.$router.push({ name: 'tasks/task-edit', params: { taskId:taskId } });
            },

            async openClient(task) {
                // console.log(`DEBUG: ${this.$options.name}.selectClient() task=`, task);
                await this.setLastClientId(task.core_client_id);
                if (task.visit_rec_id) {
                    await this.setLastVisitId(task.visit_rec_id);
                    this.$router.push({ name: 'client-visits', params: { clientId:task.core_client_id } });
                } else {
                    this.$router.push({ name: 'client-edit', params: { id:task.core_client_id } });
                }
            },

            showContextMenu(evt, task) {
                this.$refs.taskContextMenu.show(evt, task);
            },

            setGridConfig() {
                // console.log(`DEBUG: ${this.$options.name}.setGridConfig() this.filterData.isHideClosed=`, this.filterData.isHideClosed);
                if (this.$refs.taskGrid) {
                    this.reportInProgress = true;
                    let whereArray = [];

                    if (this.filterData.date_from) whereArray.push([ 'date_end', '>=', this.filterData.date_from ]);
                    if (this.filterData.date_to) whereArray.push([ 'date_end', '<=', this.filterData.date_to ]);
                    if (this.filterData.core_building_id) whereArray.push({ core_building_id: this.filterData.core_building_id });
                    if (this.filterData.core_client_id) whereArray.push({ core_client_id: this.filterData.core_client_id });
                    if (this.filterData.performer_user_id) whereArray.push({ performer_user_id: this.filterData.performer_user_id });
                    if (this.filterData.initiator_user_id) whereArray.push({ initiator_user_id: this.filterData.initiator_user_id });
                    if (this.filterData.isHideClosed) whereArray.push({ is_open: true });
                    if (this.filterData.isHideUntimely) whereArray.push({ is_untimely: false });

                    if (whereArray.length > 0) {
                        this.gridConfig.filter = { where: whereArray };
                    } else {
                        this.gridConfig.filter = null;
                    }

                    this.gridConfig.paginationPageSize = this.filterData.row_qty;

                    this.$refs.taskGrid.refresh();

                    this.reportInProgress = false;
                }
            },

            filterModified() {
                this.setGridConfig();
            },

            // async openVisit(task) {
            //     // console.log(`DEBUG: ${this.$options.name}.selectVisit() task=`, task);
            //     await this.setLastClientId(task.core_client_id);
            //     await this.setLastVisitId(task.visit_rec_id);
            //     this.$router.push({ name: 'client-visits', params: { clientId:task.core_client_id } });
            // },

            saveReport() {
                this.gridConfig.downloadFileName = (
                    this.breadcrumb[this.breadcrumb.length - 1].text + '_' + moment().format('YYYY-MM-DD')
                );
                this.$refs.taskGrid.download();
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.currentUser=`, this.currentUser);
            this.setGridConfig();
            this.gridConfig.apiUrl = `task-visible/${this.currentUser.currentWorkplace.id}`;
        },
    }
</script>

<style>
</style>
