<!--
    Author: Eduard Grebenyukov
    Date: 2021-05-16
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" :disabled="!editFormData.core_rubric_id" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>

        <VisitDescription />

        <form>
            <div id="page-content" class="form-float">
                <div class="form-row">
                    <div class="col-sm">
                        <FieldSelect id='core_rubric_id' :label="$t('object.core_rubric')" :value.sync="editFormData.core_rubric_id" @modified="itemModified" required source="core_rubric" />
                    </div>
                    <!-- <div class="col-sm">
                        <FieldSelect id='core_rubric_id' :label="$t('attribute.common.core_rubric_id')" :value.sync="editFormData.core_rubric_id" @modified="itemModified" required sourc="core_rubric" />
                    </div> -->
                <!-- </div>
                <div class="form-row"> -->
                    <!-- <div class="col-sm">
                        <FieldString id='filename' :label="$t('attribute.common.filename')" :value.sync="editFormData.filename" @modified="itemModified" required readonly />
                    </div> -->
                    <div class="col-sm">
                        <FieldFile id='file' :label="$t('attribute.common.file')" :value.sync="file" :filename.sync="editFormData.filename" @modified="fileSelected" required />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-sm">
                        <FieldString :label="$t('attribute.common.description')" :value.sync="editFormData.description" @modified="itemModified" />
                    </div>
                </div>
            </div>
        </form>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import VisitDescription from '@/components/pages/visits/VisitDescription';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldFile from '@/components/lib/controls/FieldFile';
    // import FieldText from '@/components/lib/controls/FieldText';

    const componentUrl = 'core_file';

    export default {
        name: 'VisitFileEdit',

        components: {
            TemplatePageStandard, VisitDescription,
            ButtonClose, ButtonSave, ButtonDelete,
            FieldSelect, FieldString, FieldFile,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                clientId: null,
                visitId: null,
                buttons: [],
                msgItemModified: '',
                editFormData: {
                    core_rubric_id: null,
                    core_client_id: null,
                    visit_rec_id: null,
                    filename: null,
                    filetime: null,
                    mimetype: null,
                    charset: null,
                    description: null,
                    file_data: null,
                },
                oldFormData: {},
                file: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                lastClient: 'lastClient',
            }),
            apiUrl() { return `${this.baseUrl}/${componentUrl}` },
            displayGrids() { return typeof this.editFormData.id !='undefined' },
            breadcrumb() {
                return [
                    ...tools.getEhrBreadcrumb(this, {
                        text: this.$t('VisitMenu.files'),
                        to: `/client/${this.clientId}/visit/${this.visitId}/files`
                    }),
                    { text: `${this.editFormData.filename ? this.editFormData.filename : '#'}` },
                ];
            },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            gotData() {
                this.itemId = this.editFormData.id;
                this.editFormData.file_data = null;
                this.isModified = false;
                this.msgItemModified = '';
                //this.breadcrumb[this.breadcrumb.length - 1].text = this.editFormData.code;
                // this.breadcrumb[4].text = this.editFormData.filename;
                this.checkButtons();
            },

            async getData() {
                try {
                    this.editFormData = (await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getData() data=`, response.data);
                    this.gotData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    //this.$v.$touch();
                    //if (!this.$v.$invalid) {
                    // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // console.log('Update...');
                            // Update
                            if (this.file) {
                                this.editFormData.file_data = await tools.readFileAsync(this.file);
                            }
                            this.editFormData = (await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData)).data;
                            tools.displaySuccessSave(this);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response=`, response);
                            this.gotData();
                        } else {
                            // console.log('Create new...');
                            // Create new
                            if (this.file) {
                                this.editFormData.file_data = await tools.readFileAsync(this.file);
                            }
                            this.editFormData = (await this.authenticatedAxios.post(this.apiUrl, this.editFormData)).data;
                            tools.displaySuccessSave(this);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                            this.gotData();
                            this.$router.replace({ name: 'client-file-edit', params: { clientId:this.clientId, id:this.itemId } });
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.editFormData=`, this.editFormData);
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
            },

            fileSelected() {
                // console.log(`DEBUG: ${this.$options.name}.fileSelected() file=`, this.file);
                // let formData = new FormData();
                // console.log(`DEBUG: ${this.$options.name}.fileSelected formData=`, formData);
                if (this.file) {
                    this.editFormData.filename = this.file.name;
                    this.editFormData.mimetype = this.file.type;
                    // this.editFormData.filetime = this.file.lastModifiedDate;
                    this.editFormData.filetime = new Date();
                }
                this.itemModified();
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },
        },

        // =============== Life cycle ===============
        beforeMount() {
            this.itemId = parseInt(this.$route.params.id);
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            this.editFormData.core_client_id = this.clientId;
            this.editFormData.visit_rec_id = this.visitId;
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.itemId=${this.itemId}, this.clientId=${this.clientId}`);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.itemId=${this.itemId}, this.visitId=${this.visitId}`);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.itemId=${this.itemId}`);
            if (this.itemId) {
                await this.getData();
            }
            this.checkButtons();
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
        },
    }
</script>

<style>

</style>
