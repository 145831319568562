/*
    Author: Eduard Grebenyukov
    Date: 2022-02-15
*/

// import Axios from 'axios';
import { appCode } from './constants';

// window.localStorage.getItem(`${appCode}:language`);

export default {
    state: {
        selectedCashRegisterId: null,
    },
    getters: {
        selectedCashRegisterId(state) { return state.selectedCashRegisterId; },
    },

    mutations: {
        onSelectCashRegisterId(state, cashRegisterId) {
            state.selectedCashRegisterId = cashRegisterId;
        },
    },

    actions: {
        async selectCashRegisterId(ctx, cashRegisterId) {
            ctx.commit('onSelectCashRegisterId', cashRegisterId);
            localStorage.setItem(`${appCode}:cashRegisterId`, cashRegisterId);
        },
    }
}
