<!--
    Author: Eduard Grebenyukov
    Date: 2020-10-19
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">

        <template #buttons>
            <button :disabled="reportInProgress" @click.prevent="saveReport()" class="btn btn-sm btn-outline-primary mr-1">
                <i class="fas fa-download"></i> {{ $t('lib.Grid.download') }}
            </button>
            <button :disabled="reportInProgress || !filterData.dateFrom || !filterData.dateTo" @click.prevent="filterModified()" class="btn btn-sm btn-primary mr-1">
                <i class="fas fa-play"></i> {{ $t('app.build') }}
            </button>
        </template>

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldDate
                        :label="$t('attribute.common.date_from')"
                        :value.sync="filterData.dateFrom"
                        :daysPrevNext="1"
                        required
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldDate
                        :label="$t('attribute.common.date_to')"
                        :value.sync="filterData.dateTo"
                        :daysPrevNext="1"
                        required
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldSelect
                        :label="$t('object.core_building')"
                        :value.sync="filterData.core_building_id"
                        source="core_building"
                        showEmptyRow
                        :emptyRowLabel="$t('app.allBuildings')"
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldNumber
                        :label="$t('attribute.common.rows_qty')"
                        :value.sync="filterData.row_qty"
                        :defaultValue="5000"
                        :minValue="10"
                        :maxValue="10000"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <Grid ref="report" :config="gridConfig" />
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    // import { mapGetters } from 'vuex';
    import moment from 'moment';

    // import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ReportWageDoctors',

        components: {
            TemplatePageStandard,
            FieldDate, FieldSelect, FieldNumber,
            Grid,
        },

        // =============== Data ===============
        data() {
            return {
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.reports') },
                    { text: this.$t('MainMenu.reports-wage-doctors') },
                ],
                filterData: {
                    dateFrom: null,
                    dateTo: null,
                    core_building_id: null,
                    row_qty: null,
                },
                reportInProgress: false,
                gridConfig: {
                    apiUrl: null,
                    readonly: true,
                    hideZero: true,
                    columns: [
                        { type:'string', key:'service_name', header:'Сотрудник / Специальность / Услуга', sortable:false },
                        { type:'number', key:'service_qty', header:'Кол-во услуг', sortable:false },
                        { type:'number', key:'sum_payment', header:this.$t('attribute.common.sum'), sortable:false },
                        { type:'number', key:'sum_paid', header:this.$t('component.Payers.sum_paid'), sortable:false },
                        // { type:'number', key:'sum_unpaid_person', header:this.$t('component.Payers.sum_unpaid'), sortable:false },
                        { type:'string', hidden:true, key:'user_shortname', header:this.$t('app.employee') },
                        { type:'string', hidden:true, key:'is_day_string', header:'Д/Н' },
                        { type:'string', hidden:true, key:'speciality_name', header:this.$t('object.core_speciality') },
                    ],
                    subtotals: {
                        position: 'above',
                        columns: [
                            'user_shortname',
                            'is_day_string',
                            'speciality_name',
                        ],
                    },
                    totals: {
                        columns: [
                            { type:'string', key:'service_name', value:this.$t('app.total'), style:'font-weight:bold;' },
                            { type:'number', key:'service_qty', style:'font-weight:bold;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.service_qty), 0 );
                                    else return 0;
                                }
                            },
                            { type:'number', key:'sum_payment', style:'font-weight:bold;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_payment), 0 );
                                    else return 0;
                                }
                            },
                            { type:'number', key:'sum_paid', style:'font-weight:bold;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_paid), 0 );
                                    else return 0;
                                }
                            },
                        ],
                    },
                    // paginationPageSize: 1000,
                    order: [ 'building_name' ],
                    displayToolbar: false,
                },
            };
        },

        // =============== Methods ===============
        methods: {
            async setGridConfig() {
                this.reportInProgress = true;
                this.gridConfig.paginationPageSize = this.filterData.row_qty;
                this.gridConfig.filter = { where:[
                    [ 'visit_date', '>=', this.filterData.dateFrom ],
                    [ 'visit_date', '<=', this.filterData.dateTo ],
                    { core_building_id:this.filterData.core_building_id },
                ]};
                if (!this.gridConfig.apiUrl) {
                    this.gridConfig.apiUrl = 'report-wage-doctors';
                } else {
                    await this.$refs.report.refresh();
                }
                this.reportInProgress = false;
            },

            async filterModified() { await this.setGridConfig() },

            saveReport() {
                this.gridConfig.downloadFileName = (
                    this.breadcrumb[this.breadcrumb.length - 1].text + '_' +
                    moment(this.filterData.dateFrom).format('YYYY-MM-DD') + '_' +
                    moment(this.filterData.dateTo).format('YYYY-MM-DD')
                );
                this.$refs.report.download();
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this=`, this);
            this.filterData.dateFrom = moment().startOf('month').format(moment.HTML5_FMT.DATE);
            this.filterData.dateTo = moment().format(moment.HTML5_FMT.DATE);
        },

        async mounted() {
            // this.setGridConfig();
        },
    }
</script>

<style>
</style>
