<!--
    Author: Eduard Grebenyukov
    Date: 2020-02-26
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <!-- <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" /> -->
        </template>
        <form>
            <div id="page-content" class="form-float">
                <div v-if="displayGrids" class="row">
                    <div class="col-sm">
                        <Grid ref="workplaceGrid" :config="workplaceGridConfig" @modified="workplaceGridModified" />
                    </div>
                </div>
                <div v-if="editFormData.id" class="row">
                    <div class="col">
                        <Grid ref="referralsInVisitTypeGrid" :config="referralsInVisitTypeGridConfig" @modified="gridModified($event)" />
                    </div>
                    <div class="shuttle__buttons">
                        <h6>&nbsp;</h6>
                        <div class="row">
                            <div class="col">
                                <ButtonCustom
                                    buttonClass="btn-outline-secondary"
                                    iconClass="fas fa-angle-left fa-fw"
                                    :title="$t('app.add')"
                                    @click="addReferral()"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <ButtonCustom
                                    buttonClass="btn-outline-secondary"
                                    iconClass="fas fa-angle-right fa-fw"
                                    :title="$t('app.delete')"
                                    @click="removeReferral()"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <ButtonCustom
                                    buttonClass="btn-outline-secondary"
                                    iconClass="fas fa-angle-double-right fa-fw"
                                    :title="$t('app.deleteAll')"
                                    @click="removeReferralAll()"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <ButtonCustom
                                    buttonClass="btn-outline-secondary"
                                    iconClass="fas fa-sitemap fa-fw"
                                    :title="$t('component.SetpriceEdit.addByGroup')"
                                    @click="displayServiceGroups()"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <Grid ref="referralsOutOfVisitTypeGrid" :config="referralsOutOfVisitTypeGridConfig" />
                    </div>
                </div>
            </div>
        </form>

        <ModalWindow
            v-if="isDisplayServiceGroups"
            :header="$t('component.SetpriceEdit.addByGroup')"
            minWidth="360px"
            width="80%"
            maxWidth="1024px"
            :buttons="[]"
            @cancel="isDisplayServiceGroups = false"
        >
            <div class="form-row">
                <div class="col">
                    <TreeView
                        :treeData="serviceGroupTree"
                        :displayEmptyGroups="true"
                        :bottom="60"
                        @select="addReferralsByServiceGroup($event)"
                    />
                </div>
            </div>
        </ModalWindow>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    //import { mapState, mapActions } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import Grid from '@/components/lib/regions/Grid';
    import TreeView from '@/components/lib/regions/TreeView';

    const componentUrl = 'visit_type';
    const workplacesUrl = 'visit_type_workplace';

    export default {
        name: 'VisitTypeEdit',

        components: {
            TemplatePageStandard, ModalWindow,
            ButtonClose, ButtonSave, ButtonCustom,
            Grid, TreeView,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                isFormModified: false,
                isDisplayWarningDelete: false,
                isWorkplaceGridModified: false,
                isDisplayServiceGroups: false,
                serviceGroupTree: [],
                editFormData: {
                    // id: undefined,
                },
                oldFormData: {},
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-visits-types'), to: '/settings/visits/types' },
                    { text: 'visit-type' },
                ],
                workplaceGridConfig: {
                    apiUrl: workplacesUrl,
                    filter: null,
                    title: this.$t('component.UserEdit.workplaces'),
                    columns: [
                        { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'visit_type_id', header:'Visit type', required: true },
                        // { type:'select', key:'core_workplace_id', header:this.$t('object.core_workplace'), required:true, source:'v_workplace', keyLabel:'workplace_name' },
                        {
                            type:'popuplist', key:'core_workplace_id', header:this.$t('object.core_workplace'), required:true,
                            source:'v_workplace',
                            keyLabel:'workplace_name',
                            listConfig: {
                                // filter:{ where:[{ is_schedule:true }]},
                                readonly:true,
                                columns: [
                                    // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'50px' },
                                    // { type:'string', key:'name', header:this.$t('attribute.name') },
                                    { type:'string', key:'building_name', header:this.$t('object.core_building') },
                                    { type:'string', key:'dept_name', header:this.$t('object.core_dept') },
                                    { type:'string', key:'speciality_name', header:this.$t('object.core_speciality') },
                                    { type:'string', key:'user_name', header:this.$t('app.employee') },
                                    { type:'string', key:'room_name', header:this.$t('object.core_room') },
                                    // { type:'checkbox', key:'is_schedule', header:this.$t('attribute.core_workplace.is_schedule') },
                                ],
                                order: [ 'building_name', 'dept_name', 'speciality_name', 'user_name' ],
                            },
                        },
                    ],
                    buttons: [ 'row-selector', 'add', 'delete' ],
                    order: [ 'id' ],
                    saveSessionState: `${this.$options.name}`,
                },
                referralsInVisitTypeGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title: this.$t('component.VisitTypeEdit.referralsInVisitType'),
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), required: true, readonly:true, align:'right', width:'50px' },
                        { type:'string', key:'referral_code', header:this.$t('attribute.common.code'), readonly: true, style:'white-space:nowrap;' },
                        { type:'string', key:'referral_name', header:this.$t('attribute.common.name'), readonly: true },
                    ],
                    order: [ 'referral_code' ],
                    displayToolbar: true,
                    buttons: [ 'row-selector' ],
                    paginationPageSize: 15,
                },
                isReferralsInVisitTypeGridModified: false,
                referralsOutOfVisitTypeGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title: this.$t('component.VisitTypeEdit.referralsOutOfVisitType'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), required: true,readonly:true, align:'right', width:'50px' },
                        { type:'string', key:'referral_code', header:this.$t('attribute.common.code'), style:'white-space:nowrap;' },
                        { type:'string', key:'referral_name', header:this.$t('attribute.common.name') },
                    ],
                    order: [ 'referral_code' ],
                    displayToolbar: true,
                    buttons: [ 'row-selector' ],
                    paginationPageSize: 15,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            apiUrl() { return `${this.baseUrl}/${componentUrl}` },
            displayGrids() { return typeof this.editFormData.id !='undefined' },
        },

        // =============== Validations ===============
        // validations: {
        //     editFormData: {
        //         username: { required },
        //         email: { required }
        //     }
        // },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            setGridFilter() {
                this.workplaceGridConfig.filter = { where:[
                    { visit_type_id:this.itemId },
                ]};
                this.workplaceGridConfig.columns.find(x => x.key === 'visit_type_id').defaultValue = this.itemId;
                // !!! why its works without .refresh()???
            },

            checkModified() {
                this.isModified = this.isFormModified || this.isReferralsInVisitTypeGridModified;
            },

            refreshGrids() {
                // console.log(`DEBUG: ${this.$options.name}.refreshGrids(), this.editFormData=`, this.editFormData);
                if (this.itemId) {
                    this.referralsInVisitTypeGridConfig.apiUrl = `referrals-in-visit-type/${this.itemId}`;
                    this.referralsOutOfVisitTypeGridConfig.apiUrl = `referrals-out-of-visit-type/${this.itemId}`;
                    if (this.$refs.referralsInVisitTypeGrid) this.$refs.referralsInVisitTypeGrid.refresh();
                    if (this.$refs.referralsOutOfVisitTypeGrid) this.$refs.referralsOutOfVisitTypeGrid.refresh();
                }
            },

            async getData() {
                try {
                    let response = await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`);
                    this.editFormData = response.data;
                    this.isFormModified = false;
                    this.msgItemModified = '';
                    this.breadcrumb[this.breadcrumb.length - 1].text = response.data.name;
                    this.breadcrumb[this.breadcrumb.length - 1].to = `settings/visits/visit-type/${this.itemId}`;
                    this.itemId = response.data.id;
                    this.checkButtons();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                this.checkModified();
                this.refreshGrids();
            },

            async saveData() {
                if (this.isModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update user
                            await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData);
                            this.isModified = false;
                            tools.displaySuccessSave(this);
                        } else {
                            // New user created
                            let response = await this.authenticatedAxios.post(this.apiUrl, this.editFormData);
                            this.isModified = false;
                            this.itemId = response.data.id;
                            this.editFormData.id = this.itemId;
                            this.setGridFilter();
                            this.getData();
                            tools.displaySuccessSave(this);
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }

                if (this.isWorkplaceGridModified) {
                    this.$refs.workplaceGrid.$emit('save');
                    this.isWorkplaceGridModified = false;
                }
                // this.$emit('close-modal', true);
            },

            gridModified(evt) {
                // console.log(`DEBUG: ${this.$options.name}.gridModified(), evt=`, evt);
                this.isReferralsInVisitTypeGridModified = evt;
                this.checkModified();
                this.checkButtons();
            },

            itemModified() {
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                if (
                    this.isModified ||
                    this.isWorkplaceGridModified
                ) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
            },

            async addReferral() {
                let payload = this.$refs.referralsOutOfVisitTypeGrid.getSelectedRows().map( item => item.id);
                // console.log(`DEBUG: ${this.$options.name}.addReferral(), payload=`, payload);
                try {
                    await this.saveData();
                    await this.authenticatedAxios.post(`${this.baseUrl}/visit-type-add-referrals/${this.itemId}`, payload);
                    tools.displaySuccessSave(this.$parent);
                    this.refreshGrids();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async addReferralsByServiceGroup(serviceGroup) {
                // console.log(`DEBUG: ${this.$options.name}.addReferralsByServiceGroup(), serviceGroup=`, serviceGroup);
                this.isDisplayServiceGroups = false;
                let payload = { fin_service_group_id: serviceGroup.id };
                try {
                    await this.saveData();
                    await this.authenticatedAxios.post(`${this.baseUrl}/visit-type-add-referrals-by-service-group/${this.itemId}`, payload);
                    tools.displaySuccessSave(this.$parent);
                    this.refreshGrids();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async removeReferral() {
                let payload = this.$refs.referralsInVisitTypeGrid.getSelectedRows().map( item => item.id);
                // console.log(`DEBUG: ${this.$options.name}.removeReferral(), payload=`, payload);
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/visit-type-delete-referrals/${this.itemId}`, payload);
                    tools.displaySuccessSave(this.$parent);
                    this.refreshGrids();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async removeReferralAll() {
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/visit-type-delete-referrals/${this.itemId}`);
                    tools.displaySuccessSave(this.$parent);
                    this.refreshGrids();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async displayServiceGroups() {
                try {
                    let groupFilterString = tools.getFilterString({ order:[ 'name' ]});
                    let serviceGroups = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_service_group${groupFilterString}`)).data.rows;
                    serviceGroups.map(item => (item.isGroup = true));
                    this.serviceGroupTree = tools.listToTree(serviceGroups);
                    // console.log(`DEBUG: ${this.$options.name}.displayServiceGroups(), serviceGroups=`, serviceGroups);
                    // console.log(`DEBUG: ${this.$options.name}.displayServiceGroups(), this.serviceGroupTree=`, this.serviceGroupTree);
                    this.isDisplayServiceGroups = true;
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            askDeleteItem() {
                this.isDisplayWarningDelete = true;
            },
            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },

            // Grids
            workplaceGridModified() {
                this.isWorkplaceGridModified = true;
            },

        },

        // =============== Life cycle ===============
        beforeMount() {
            this.itemId = parseInt(this.$route.params.id);

        },

        async mounted() {
            if (this.itemId) {
                await this.getData();

                this.setGridFilter();
            }
            this.checkButtons();
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
        },
    }
</script>

<style>
    .shuttle__buttons h6 {
        height: 18px;
    }

    .shuttle__buttons .row {
        margin-bottom:4px;
    }

    .shuttle__buttons button {
        width:64px;
    }
</style>
