var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        'field-required': _vm.required,
        'form-group': true,
        'form-inline': _vm.labelPosition === 'before',
        'form-label-group': _vm.labelPosition === 'float'
    }},[(_vm.label && (_vm.labelPosition === 'above'))?_c('label',{staticClass:"field__label__above",attrs:{"for":_vm.id,"title":_vm.required ? _vm.$t('lib.required') : ''}},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('sup',{staticClass:"required-mark"},[_vm._v("*")]):_vm._e()]):_vm._e(),(_vm.label && (_vm.labelPosition === 'before'))?_c('label',{staticClass:"field__label__before",attrs:{"for":_vm.id,"title":_vm.required ? _vm.$t('lib.required') : ''}},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('sup',{staticClass:"required-mark"},[_vm._v("*")]):_vm._e()]):_vm._e(),(!_vm.label && (_vm.labelPosition === 'above'))?_c('div',{staticClass:"field-string__label-spacer"}):_vm._e(),(!_vm.displayonly)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemData),expression:"itemData"}],class:{
            'form-control': !_vm.readonly,
            'form-control-plaintext': _vm.readonly,
            'is-invalid': _vm.isInvalid || _vm.errorMessage,
            'is-valid': _vm.isValid,
            'field__readonly': _vm.readonly || _vm.disabled,
            'field__disabled': _vm.disabled,
            'field__required-empty': _vm.required && (!_vm.itemData || _vm.itemData == '')
        },style:(("" + _vm.inputStyle)),attrs:{"id":_vm.id,"type":"text","required":_vm.required,"placeholder":_vm.placeholder || (_vm.labelPosition == 'float' ? _vm.label : _vm.placeholder),"disabled":_vm.disabled,"readonly":_vm.readonly,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete},domProps:{"value":(_vm.itemData)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.itemData=$event.target.value},function($event){return _vm.onInput($event)}],"change":function($event){return _vm.onChange($event)},"focus":function($event){return _vm.itemFocused($event)}}}):_vm._e(),(_vm.displayonly)?_c('span',[_vm._v(_vm._s(_vm.value))]):_vm._e(),(_vm.labelPosition === 'float')?_c('label',{attrs:{"for":_vm.id,"title":_vm.required ? _vm.$t('lib.required') : ''}},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('sup',{staticClass:"required-mark"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.errorMessage}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }