/*
    Author: Eduard Grebenyukov
    Date: 2020-07-15
*/

let warningDeleteMixin = {
    // =============== Data ===============
    data() {
        return {
            isDisplayWarningDelete: false,
        }
    },

    // =============== Methods ===============
    methods: {
        askDeleteItem() {
            // console.log(`DEBUG: tools.mixins.askDeleteItem()`);
            this.isDisplayWarningDelete = true;
        },
    },
}

let warningCloseMixin = {
    // =============== Data ===============
    data() {
        return {
            isModified: false,
            isDisplayWarningClose: false,
        }
    },

    // =============== Watch ===============
    // watch: {
    //     $route(to, from) {
    //         console.log(`DEBUG: tools.mixins.watch.$route(${to}, ${from}), this=`, this);
    //         // this.show = false;
    //     }
    // },

    // =============== Methods ===============
    methods: {
        beforePageDestroyed(event) {
            // not fired in RenderedServiceEdit because of unknow reason
            // console.log(`DEBUG: tools.mixins.beforePageDestroyed(isModified=${this.isModified}), this=`, this);
            if (this.isModified) {
                event.preventDefault();
                event.returnValue = '';
            }
        },

        closePressed() {
            this.askCloseItem(null);
        },

        askCloseItem(next) {
            // console.log(`DEBUG: tools.mixins.askCloseItem() next=`, next);
            // console.log(`DEBUG: tools.mixins.askCloseItem() this.isModified=`, this.isModified);
            this.next = next;
            if (this.isModified) {
                this.isDisplayWarningClose = true;
            } else {
                this.processClose(true);
            }
        },

        processClose(doClose) {
            // console.log(`DEBUG: tools.mixins.processClose(${doClose}) this.next=`, this.next);
            this.isDisplayWarningClose = false;
            window.removeEventListener('beforeunload', this.beforePageDestroyed);
            if (doClose) {
                this.isModified = false;
                if (this.next) {
                    this.next();
                } else {
                    // console.log(`DEBUG: tools.mixins.processClose(${doClose}) go`);
                    this.$router.go(-1);
                }
            }
        },
    },

    // =============== Life cycle ===============
    beforeMount() {
        // not fired in RenderedServiceEdit because of unknow reason
        window.addEventListener('beforeunload', this.beforePageDestroyed);
    },
    beforeRouteLeave(to, from , next) {
        // console.log(`DEBUG: tools.mixins.beforeRouteLeave(), to=`, to);
        this.askCloseItem(next);
    },
    // beforeRouteUpdate(to, from , next) {
    //     console.log(`DEBUG: tools.mixins.beforeRouteUpdate(), to=`, to);
    //     this.askCloseItem(next);
    // },
    // beforeRouteEnter(to, from , next) {
    //     console.log(`DEBUG: tools.mixins.beforeRouteLeave(), to=`, to);
    //     // this.askCloseItem(next);
    // },
    // beforeDestroy() {
    //     console.log(`DEBUG: tools.mixins.beforeDestroy()`);
    // },
}

export default {
    warningDeleteMixin,
    warningCloseMixin,
}
