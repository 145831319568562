<!--
    Author: Eduard Grebenyukov
    Date: Date: 2021-02-11
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >

        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" :disabled="!isValidated" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
            <ButtonCustom
                v-if="buttons.includes('commit')"
                buttonClass="btn-warning btn-sm ml-1"
                iconClass="fas fa-check-square fa-fw"
                :text="$t('app.commit')"
                @click="CommitDocument()"
            />
            <ButtonCustom
                v-if="buttons.includes('rollback')"
                buttonClass="btn-outline-danger btn-sm ml-1"
                iconClass="fas fa-square fa-fw"
                :text="$t('app.rollback')"
                @click="isDisplayWarningRollback = true"
            />
        </template>

        <!-- <form> -->
        <div id="page-content" class="form-float">
            <b-alert variant="success" :show="editFormData.is_commited">
                <i class="fas fa-check-square"></i> {{ $t('attribute.common.is_commited') }}
            </b-alert>

            <div class="row">
                <div class="col">
                    <div class="form-row">
                        <div class="col-sm-3">
                            <FieldString
                                id='doc_num'
                                :label="$t('attribute.mat_doc.doc_num')"
                                :value.sync="editFormData.doc_num"
                                :disabled="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                        <div class="col-sm-3">
                            <FieldDatetime
                                id='time_of'
                                :label="$t('app.datetime')"
                                :value.sync="editFormData.time_of"
                                required
                                :disabled="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                        <div class="col-sm-3">
                            <FieldString
                                id='base_doc_num'
                                :label="$t('attribute.mat_doc.base_doc_num')"
                                :value.sync="editFormData.base_doc_num"
                                :disabled="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                        <div class="col-sm-3">
                            <FieldDate
                                id='base_doc_date'
                                :label="$t('attribute.mat_doc.base_doc_date')"
                                :value.sync="editFormData.base_doc_date"
                                :disabled="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-6">
                            <FieldSelect
                                id='store_dest_id'
                                ref="storeDestId"
                                :label="$t('attribute.mat_doc.store_dest_id')"
                                :value.sync="editFormData.store_dest_id"
                                source='mat_store?filter={"where":[{"is_arrival":true}]}'
                                order="name"
                                required
                                :disabled="!isFormEditable"
                                @modified="itemModified"
                                @mounted="destStoreMounted()"
                            />
                        </div>
                        <div class="col-sm-6">
                            <FieldSelect
                                id='contractor_id'
                                :label="$t('attribute.mat_doc.contractor_id')"
                                :value.sync="editFormData.contractor_id"
                                source='v_org?filter={"where":[{"org_type_code":"supplier"}]}'
                                order="name"
                                required
                                :disabled="!isFormEditable"
                                @modified="itemModified"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <Grid ref="gridMatTran" :config="matTranGridConfig" :source="editFormData.matTranArray" @modified="gridModified($event)" />
                </div>
            </div>
        </div>
        <!-- </form> -->

        <MatDocArrivalItemEditModal
            v-if="isDisplayModalEdit"
            :matDocId="itemId"
            :matTran="matTran"
            :barCode="scanBarCode"
            @delete="deleteRow($event)"
            @close-modal="closeModalEdit($event)"
        />

        <ModalWindow v-if="isDisplayWarningRollback" minWidth="300px" :buttons="['yes', 'no']" @yes="processRollback" @no="isDisplayWarningRollback = false" >
            {{ $t('app.confirmRollback') }}
        </ModalWindow>

    </TemplatePageStandard>
</template>

<script>
    // import $ from 'jquery';
    import { mapGetters } from 'vuex';
    // import moment from 'moment';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import Grid from '@/components/lib/regions/Grid';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import FieldDatetime from '@/components/lib/controls/FieldDatetime';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldSelect from '@/components/lib/controls/FieldSelect';

    import MatDocArrivalItemEditModal from './MatDocArrivalItemEditModal';

    const componentUrl = 'mat-doc-arrival';

    export default {
        name: 'MatDocArrivalEdit',

        components: {
            TemplatePageStandard, ModalWindow, Grid,
            ButtonClose, ButtonSave, ButtonDelete, ButtonCustom,
            FieldDatetime, FieldDate, FieldString, FieldSelect,
            MatDocArrivalItemEditModal,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Props ===============
        props: {
            id: [String, Number],
        },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                // fieldModified: false,
                // gridModified: false,
                msgItemModified: '',
                isDisplayWarningRollback: false,
                editFormData: {
                    // id: null,
                    doc_type_id: null,
                    // store_src_id: null,
                    store_dest_id: null,
                    // dept_src_id: null,
                    // dept_dest_id: null,
                    contractor_id: null,
                    // workplace_dest_id: null,
                    // visit_rec_id: null,
                    time_of: null,
                    doc_num: null,
                    base_doc_date: null,
                    base_doc_num: null,
                    // date_plan: null,
                    is_commited: false,
                    matTranArray: [],
                },
                oldFormData: {},
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.store') },
                    { text: this.$t('MainMenu.store-arrivals'), to: '/store/arrivals' },
                    { text: '#', to: '#' },
                ],
                // sourceOrg: [],
                isDisplayModalEdit: false,
                matTran: null,
                scanBarCode: null,
                matTranGridConfig: {
                    source: [],
                    readonly: true,
                    columns: [
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), sortable:false, align:'right' },
                        // { type:'number', key:'mat_doc_id', header:'mat_doc_id', align:'right' },
                        { type:'popuplist', key:'mat_item_id', header:this.$t('object.mat_item'), source:'mat_item', sortable:false, 
                            listConfig: {
                                readonly:true,
                                columns: [
                                    { type:'number', key:'id', header:this.$t('attribute.common.id'), sortable:false, align:'right', width:'50px' },
                                    { type:'string', key:'name', header:this.$t('attribute.common.name') },
                                ],
                                order: [ 'name' ],
                            },
                        },
                        { type:'number', key:'unit_qty', header:this.$t('attribute.common.qty'), sortable:false, align:'right' },
                        { type:'select', key:'mat_pack_id', header:this.$t('object.mat_unit'), source:'mat_pack', sortable:false },
                        { type:'number', key:'unit_price', header:this.$t('attribute.common.price'), sortable:false, align:'right' },
                        { type:'number', key:'sum_net', header:this.$t('attribute.common.sum_net'), sortable:false, align:'right' },
                        { type:'select', key:'fin_vat_id', header:this.$t('object.fin_vat'), source:'fin_vat', sortable:false },
                        { type:'number', key:'sum_vat', header:this.$t('attribute.common.sum_vat'), sortable:false, align:'right' },
                        // { type:'number', key:'sum_with_vat', header:this.$t('attribute.common.sum_with_vat'), align:'right' },
                    ],
                    order: [ 'id' ],
                    buttons: [ 'add', 'edit' ],
                    // $onAdd: () => { this.$router.push({ name:'store/arrival/item-new', params: { id: null, matDocId:this.itemId } })},
                    // $onEdit: (ctx, item) => { this.$router.push({ name:'store/arrival/item-edit', params: { id: item.id } })},
                    $onAdd: () => this.openModalEdit(null, null),
                    $onEdit: (ctx, item) => this.openModalEdit(item, null),
                },
                scanBuffer: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            apiUrl() { return `${this.baseUrl}/${componentUrl}` },
            isFormEditable() {
                return !this.editFormData.is_commited;
            },
            isValidated() {
                // console.log(`DEBUG: ${this.$options.name}.isValidated() this.editFormData=`, this.editFormData);
                return (
                    !tools.isNumberEmpty(this.editFormData.doc_type_id) &&
                    !tools.isNumberEmpty(this.editFormData.store_dest_id) &&
                    !tools.isNumberEmpty(this.editFormData.contractor_id) &&
                    this.editFormData.time_of //&&
                    // this.editFormData.matTranArray.length > 0
                );
            },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                this.buttons = [];
                this.buttons.push('close');
                this.buttons.push('save');
                if (this.itemId) {
                    // console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.editFormData=`, this.editFormData);
                    // console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.$refs.matTranGridConfig=`, this.$refs.matTranGridConfig);
                    // if (this.$refs.matTranGridConfig) console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.$refs.matTranGridConfig.getTotalRowsQty()=`, this.$refs.matTranGridConfig.getTotalRowsQty());
                    if (this.editFormData.is_commited) {
                        this.buttons.push('rollback');
                        this.matTranGridConfig.buttons = [];
                    } else {
                        if (this.$refs.gridMatTran && this.$refs.gridMatTran.getTotalRowsQty() > 0 && !this.editFormData.is_commited) {
                            this.buttons.push('commit');
                            this.matTranGridConfig.buttons = [ 'add', 'edit' ];
                        } else {
                            this.buttons.push('delete');
                        }
                    }
                }
                // console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.buttons=`, this.buttons);
            },

            setBreadcrumb() {
                this.breadcrumb[this.breadcrumb.length - 1].text = this.itemId ? this.itemId.toString() : this.$t('component.MatDocArrivalEdit.createNewDocument');
            },

            gridModified(evt) {
                // console.log(`DEBUG: ${this.$options.name}.gridModified(), evt=`, evt);
                // this.isServicesInSetpriceGridModified = evt;
                // this.checkModified();
                this.checkButtons();
            },

            gotData(data) {
                this.editFormData = data;
                this.isModified = false;
                // this.isModified = this.fieldModified = this.gridModified = false;
                this.msgItemModified = '';
                this.itemId = data.id;
                this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));

                this.setBreadcrumb();
                this.checkButtons();
            },

            async getData() {
                try {
                    let response = await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`);
                    // console.log(`DEBUG: ${this.$options.name}.getData() data=`, response.data);
                    this.gotData(response.data);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    // console.log(`DEBUG: ${this.$options.name}.saveData() Update this.editFormData=`, this.editFormData);
                    let saveData = JSON.parse(JSON.stringify(this.editFormData));
                    // console.log(`DEBUG: ${this.$options.name}.saveData() Update saveData=`, saveData);
                    // delete rows
                    for (let tran of saveData.matTranArray) {
                        if (tran.$isDeleted) {
                            const index = saveData.matTranArray.indexOf(tran);
                            if (index >= 0) saveData.matTranArray.splice(index, 1);
                        }
                    }
                    // clear marks
                    for (let tran of saveData.matTranArray) {
                        for (const key of Object.keys(tran)) {
                            // console.log(`DEBUG: ${this.$options.name}.saveData() key=${key}`);
                            if (key.substring(0, 1) == '$' ) {
                                delete tran[key];
                            }
                        }
                    }
                    try {
                        if (typeof saveData.id != 'undefined') {
                            // Update
                            let response = (await this.authenticatedAxios.put(`${this.apiUrl}/${saveData.id}`, saveData));
                            this.gotData(response.data);
                            tools.displaySuccessSave(this);
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveData() saveData=`, saveData);
                            let response = await this.authenticatedAxios.post(this.apiUrl, saveData);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response.data=`, response.data);
                            this.gotData(response.data);
                            this.$router.replace({ name: 'store/arrival-edit', params: { id:this.itemId } });
                            tools.displaySuccessSave(this);
                        }
                    } catch(err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() evt=`, evt);
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.oldFormData=`, JSON.stringify(this.oldFormData));
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.editFormData=`, JSON.stringify(this.editFormData));
                // this.fieldModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                // this.isModified = this.fieldModified || this.gridModified;
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData); // included gridMatTran
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
                this.setBreadcrumb();
            },

            deleteRow(evt) {
                // console.log(`DEBUG: ${this.$options.name}.deleteRow() evt=`, evt);
                if (evt.id) {
                    evt.$isDeleted = true;
                    // this.gridModified = true;
                } else {
                    // not saved yet, just delete it
                    const index = this.editFormData.matTranArray.indexOf(evt);
                    if (index >= 0) this.editFormData.matTranArray.splice(index, 1);
                    // for (let el of this.editFormData.matTranArray) {
                    //     if (el === evt) {
                    //         this.editFormData.matTranArray.push(evt);
                    //     }
                    // }
                }
                this.$refs.gridMatTran.refresh();
                this.itemModified();
            },

            openModalEdit(matTran, scanBarCode) {
                this.matTran = matTran;
                this.scanBarCode = scanBarCode && scanBarCode != '' ? scanBarCode.slice() : null;
                this.isDisplayModalEdit = true;
            },

            closeModalEdit(evt) {
                // console.log(`DEBUG: ${this.$options.name}.closeModalEdit() evt=`, evt);
                // console.log(`DEBUG: ${this.$options.name}.closeModalEdit() typeof evt=`, typeof evt);
                this.isDisplayModalEdit = false;
                if (evt === null) {
                    // do nothing
                } else {
                    if (typeof evt === 'object') {
                        if (tools.isNumberEmpty(evt.id)) {
                            // new row
                            evt.$isNew = true;
                            this.editFormData.matTranArray.push(evt);
                            // console.log(`DEBUG: ${this.$options.name}.closeModalEdit() this.editFormData.matTranArray=`, this.editFormData.matTranArray);
                            // this.gridModified = true;
                        } else {
                            // update row
                            // console.log(`DEBUG: ${this.$options.name}.closeModalEdit() evt=`, evt);
                            // console.log(`DEBUG: ${this.$options.name}.closeModalEdit() this.editFormData.matTranArray=`, this.editFormData.matTranArray);
                            evt.$isModified = true;
                            // this.gridModified = true;
                            this.$refs.gridMatTran.refresh();
                        }
                    }
                    this.itemModified();
                }
            },

            async scanHandler(evt) {
                if (evt.target.tagName !== "INPUT") {
                    if (!this.scanBuffer) this.scanBuffer = '';
                    if (this.isDisplayModalEdit) {
                        // if modal is open, just clear the buffer, do not process scanned data
                        this.scanBuffer = '';
                    } else {
                        if (evt.key === 'Enter') {
                            // await console.log(this.scanBuffer);
                            // $onAdd: () => { this.matTran = null; this.isDisplayModalEdit = true; },
                            // $onEdit: (ctx, item) => { this.matTran = item; this.isDisplayModalEdit = true; },
                            this.openModalEdit(null, this.scanBuffer);
                            this.scanBuffer = '';
                        } else {
                            this.scanBuffer = this.scanBuffer + evt.key;
                        }
                    }
                // } else {
                //     console.log(`tagName=${evt.target.tagName}`);
                }
            },

            destStoreMounted() {
                // console.log(`DEBUG: ${this.$options.name}.destStoreMounted()`, this.$refs.storeDestId.getAllRows());
                if (this.$refs.storeDestId && this.$refs.storeDestId.getAllRows().length === 1) {
                    this.editFormData.store_dest_id = this.$refs.storeDestId.getAllRows()[0].id;
                }
            },

            async CommitDocument() {
                await this.saveData();
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/mat-doc-arrival-commit/${this.itemId}`);
                    tools.displaySuccessSave(this);
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async processRollback() {
                this.isDisplayWarningRollback = false;
                await this.saveData();
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/mat-doc-arrival-rollback/${this.itemId}`);
                    tools.displaySuccessSave(this);
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemId = parseInt(this.id);
            this.editFormData.time_of = this.timeOf || (new Date()).toISOString();
            try {
                this.editFormData.doc_type_id = await tools.getEnumId(this, 'mat_doc_type', 'arrival');
                // let org_type_id = await tools.getEnumId(this, 'org_type', 'supplier');
                // this.sourceOrg = 'core_org' + tools.getFilterString({ where:[{ org_type_id:org_type_id }]});
            } catch (err) {
                tools.displayApiError(this, err);
            }
        },

        async mounted() {
            if (this.itemId) {
                await this.getData();
            } else {
                this.setBreadcrumb();
                this.checkButtons();
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));

            document.addEventListener("keypress", this.scanHandler);
            // $(document).bind('textInput', function(e) {
            //     alert('testing');
            // });
            // $(document).bind('keyup', function(e) {
            //     alert('testing');
            // });
            // document.addEventListener('textInput', function(e) {
            //     if (e.data.length >= 6) {
            //         console.log('IR scan textInput', e.data);
            //         e.preventDefault();
            //     }
            // });
            // document.addEventListener("keypress", async function(e) {
            //     if (e.target.tagName !== "INPUT") {
            //         if (!this.scanBuffer) this.scanBuffer = '';
            //         if (e.key === 'Enter') {
            //             await console.log(this.scanBuffer);
            //             this.scanBuffer = '';
            //         } else {
            //             this.scanBuffer = this.scanBuffer + e.key;
            //         }
            //         // alert(`char=(${e.key}), charcode=${e.key.charCodeAt(0)}`);
            //         // var input = document.querySelector(".my-input");
            //         // input.focus();
            //         // input.value = e.key;
            //         // e.preventDefault();
            //     } else {
            //         console.log(`tagName=${e.target.tagName}`);
            //     }
            // });
        },

        beforeDestroy() {
            // console.log(`DEBUG: ${this.$options.name}.beforeDestroy()`);
            window.removeEventListener('keypress', this.scanHandler);
        },
    }
</script>

<style>
</style>
