/*
    Author: Eduard Grebenyukov
    Date: 2021-06-26
*/

import { i18n } from '@/locales';

const pageConfigQuestionaire = {
    qst_item: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-questionaire-items') }
        ],
        gridConfig: {
            apiUrl: 'qst_item',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.qst_item.name') },
                { type:'checkbox', key:'is_archived', header:i18n.t('attribute.qst_item.is_archived'), defaultValue:false, align:'center' },
            ],
            buttons: [ 'add', 'save', 'delete', 'edit' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'qst_item',
        }
    },
}

export { pageConfigQuestionaire };
