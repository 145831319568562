/*
    Author:Eduard Grebenyukov
    Date:2021-09-12
*/

import { i18n } from '@/locales';

const Schedule = () => import('@/components/pages/visits/Schedule');
const ScheduleEdit = () => import('@/components/pages/visits/ScheduleEdit');
const Visits = () => import('@/components/pages/visits/Visits');
const VisitTasks = () => import('@/components/pages/tasks/VisitTasks');
const ClientTasks = () => import('@/components/pages/tasks/ClientTasks');
const TaskEdit = () => import('@/components/pages/tasks/TaskEdit');

const VisitEdit = () => import('@/components/pages/visits/VisitEdit');
const VisitFiles = () => import('@/components/pages/visits/VisitFiles');
const VisitFileEdit = () => import('@/components/pages/visits/VisitFileEdit');

const routes = [
    // visits
    { name:'schedules',              path:'/schedules',                           component:Schedule,                 meta:{title:i18n.t('MainMenu.schedule')} },
    { name:'schedule-new',           path:'/schedule',                            component:ScheduleEdit, props:true, meta:{title:i18n.t('MainMenu.schedule')} },
    { name:'schedule-edit',          path:'/schedule/:id',                        component:ScheduleEdit, props:true, meta:{title:i18n.t('MainMenu.schedule')} },
    { name:'client-visits',          path:'/client/:clientId/visits',             component:Visits,                   meta:{title:i18n.t('MainMenu.clients-visits')} },
    { name:'client-tasks',           path:'/client/:clientId/tasks',              component:ClientTasks,              meta:{title:i18n.t('MainMenu.clients-tasks')} },
    { name:'client-tasks/task-new',  path:'/client/:clientId/tasks/task',         component:TaskEdit,                 meta:{title:i18n.t('object.task_rec')} },
    { name:'client-tasks/task-edit', path:'/client/:clientId/tasks/task/:taskId', component:TaskEdit,                 meta:{title:i18n.t('object.task_rec')} },

    // visit
    { name:'visit-edit',                   path:'/client/:clientId/visit/:visitId/visit-edit',                component:VisitEdit,                 meta:{title:i18n.t('VisitMenu.visit-edit')} },
    { name:'visit-files',                  path:'/client/:clientId/visit/:visitId/files',                     component:VisitFiles,                meta:{title:i18n.t('VisitMenu.files')} },
    { name:'visit-file-new',               path:'/client/:clientId/visit/:visitId/file',                      component:VisitFileEdit,             meta:{title:i18n.t('VisitMenu.files')} },
    { name:'visit-file-edit',              path:'/client/:clientId/visit/:visitId/file/:id',                  component:VisitFileEdit,             meta:{title:i18n.t('object.core_file')} },
    { name:'visit-tasks',                  path:'/client/:clientId/visit/:visitId/tasks',                     component:VisitTasks,                meta:{title:i18n.t('VisitMenu.tasks')} },
    { name:'visit-tasks/task-new',         path:'/client/:clientId/visit/:visitId/tasks/task',                component:TaskEdit,                  meta:{title:i18n.t('object.task_rec')} },
    { name:'visit-tasks/task-edit',        path:'/client/:clientId/visit/:visitId/tasks/task/:taskId',        component:TaskEdit,                  meta:{title:i18n.t('object.task_rec')} },
]

export default routes
