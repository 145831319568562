/*
    Author: Eduard Grebenyukov
    Date: 2020-04-22
*/

import { i18n } from '@/locales';

const pageConfigStore = {
    mat_store: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.store') },
            { text: i18n.t('MainMenu.store-stores') }
        ],
        gridConfig: {
            apiUrl: 'mat_store',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'select', key:'store_type_id', header:i18n.t('attribute.mat_store.store_type_id'), required:true, source:'enum/store_type' },
                { type:'select', key:'core_room_id', header:i18n.t('object.core_room'), source:'core_room', showEmptyRow:true },
                { type:'select', key:'core_dept_id', header:i18n.t('object.core_dept'), source:'core_dept', showEmptyRow:true },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'checkbox', key:'is_arrival', header:i18n.t('attribute.mat_store.is_arrival'), defaultValue:false },
                { type:'checkbox', key:'is_archived', header:i18n.t('attribute.common.is_archived'), defaultValue:false },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'store_type_id', 'code', 'name' ],
            displayToolbar: true,
            saveSessionState: 'mat_store',
        }
    },
    mat_unit: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.store') },
            { text: i18n.t('MainMenu.store-units') }
        ],
        gridConfig: {
            apiUrl: 'mat_unit',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'string', key:'name_full', header:i18n.t('attribute.common.name_full') },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'mat_unit',
        }
    },
    // mat_pack: {
    //     breadcrumb: [
    //         { text: i18n.t('MainMenu.home'), to: '/' },
    //         { text: i18n.t('MainMenu.store') },
    //         { text: i18n.t('MainMenu.store-packs') }
    //     ],
    //     gridConfig: {
    //         apiUrl: 'mat_pack',
    //         columns: [
    //             { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
    //             { type:'select', key:'parent_id', header:i18n.t('attribute.common.parent_id'), source:'mat_unit', showEmptyRow:true },
    //             { type:'select', key:'mat_item_id', header:i18n.t('object.mat_item'), source:'mat_item', required:true, showEmptyRow:true },
    //             { type:'select', key:'mat_unit_id', header:i18n.t('object.mat_unit'), source:'mat_unit', required:true, showEmptyRow:true },
    //             { type:'number', key:'unit_factor', header:i18n.t('attribute.mat_unit.unit_factor'), required:true },
    //             { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
    //             { type:'string', key:'name_full', header:i18n.t('attribute.common.name_full'), required:true },
    //         ],
    //         order: [ 'code' ],
    //         displayToolbar: true,
    //         buttons: [ 'row-selector', 'add', 'save', 'delete' ],
    //     }
    // },
    mat_pharmgroup: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.store') },
            { text: i18n.t('MainMenu.store-pharmgroups') }
        ],
        gridConfig: {
            apiUrl: 'mat_pharmgroup',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'select', key:'parent_id', header:i18n.t('attribute.common.parent_id'), source:'mat_pharmgroup', showEmptyRow:true },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'mat_pharmgroup',
        }
    },
    mat_group: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.store') },
            { text: i18n.t('MainMenu.store-groups') }
        ],
        gridConfig: {
            apiUrl: 'mat_group',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'select', key:'parent_id', header:i18n.t('attribute.common.parent_id'), source:'mat_group', showEmptyRow:true },
                // { type:'select', key:'fin_service_id', header:i18n.t('object.fin_service'), source:'fin_service', showEmptyRow:true },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'mat_group',
        }
    },
    mat_pricegroup: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.store') },
            { text: i18n.t('MainMenu.store-pricegroups') }
        ],
        gridConfig: {
            apiUrl: 'mat_pricegroup',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'mat_pricegroup',
        }
    },
    mat_item: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.store') },
            { text: i18n.t('MainMenu.store-items') }
        ],
        gridConfig: {
            apiUrl: 'mat_item',
            readonly: true,
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'string', key:'vendor_code', header:i18n.t('attribute.mat_item.vendor_code') },
                { type:'select', key:'mat_group_id', header:i18n.t('object.mat_group'), source:'mat_group', required:true },
                // { type:'select', key:'mat_pharmgroup_id', header:i18n.t('object.mat_pharmgroup'), source:'mat_pharmgroup', showEmptyRow:true },
                // { type:'select', key:'mat_pricegroup_id', header:i18n.t('object.mat_pricegroup'), source:'mat_pricegroup', showEmptyRow:true },
                { type:'select', key:'base_unit_id', header:i18n.t('attribute.common.base_unit_id'), source:'mat_unit' },
                // { type:'select', key:'fin_service_id', header:i18n.t('object.fin_service'), source:'fin_service', showEmptyRow:true },
                { type:'select', key:'fin_vat_id', header:i18n.t('object.fin_vat'), source:'fin_vat', showEmptyRow:true },
                // { type:'popuplist', key:'fin_service_id', header:i18n.t('object.fin_service'), source:'fin_service', showEmptyRow:true, listConfig: {
                //     columns: [
                //         // { type:'number', key:'id', header:i18n.t('attribute.common.id'), align:'right' },
                //         { type:'string', key:'name', header:i18n.t('attribute.common.name') },
                //     ],
                // }},
                // { type:'select', key:'med_medicine_id', header:i18n.t('object.med_medicine'), source:'med_medicine', showEmptyRow:true },
                { type:'checkbox', key:'is_vital', header:i18n.t('attribute.mat_item.is_vital'), defaultValue:false },
            ],
            buttons: [ 'add', 'edit' ],
            order: [ 'name', 'code' ],
            displayToolbar: true,
            saveSessionState: 'mat_item',
            onAdd: 'store/item-new',
            onEdit: 'store/item-edit',
        }
    },
}

export { pageConfigStore };
