<!--
    Author: Eduard Grebenyukov
    Date: 2020-05-13
-->

<template>
    <ModalWindow
        width="90%"
        max-width="600px"
        :buttons="buttons"
        @cancel="closeModal"
        @save="savePassword"
    >
        <span slot="header">{{ $t('component.UserPasswordEdit.changeUserPassword') }}</span>

        <form class="container" novalidate>
            <div class="row">
                <div class="col-12-sm col-md">
                    <FieldString
                        :label="$t('attribute.common.username')"
                        :value.sync="editFormData.username"
                        required
                        disabled
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12-sm col-md">
                    <FieldPassword
                        :label="$t('component.UserPasswordEdit.password_new')"
                        :value.sync="editFormData.password_new"
                        :errorMessage="msgInvalidNewPassword"
                        required
                    />
                </div>
                <div class="col-12-sm col-md">
                    <FieldPassword
                        :label="$t('component.UserPasswordEdit.password_new2')"
                        :value.sync="editFormData.password_new2"
                        :class="{ isInvalid: !isSecondPasswordsMatch }"
                        :errorMessage="msgInvalidSecondPassword"
                        required
                    />
                </div>
            </div>
        </form>
        <div v-if="msgPasswordChangeError" class="alert alert-danger" role="alert">
            {{ $t('component.UserPasswordEdit.passwordChangeError') }}
        </div>
    </ModalWindow>
</template>

<script>
    import { mapGetters } from 'vuex';
    //import { mapState, mapActions } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldPassword from '@/components/lib/controls/FieldPassword';

    export default {
        name: 'UserPasswordEdit',

        components: {
            ModalWindow, FieldString, FieldPassword
        },

        // =============== Props ===============
        props: {
            userId: { type: Number, required: true },
            username: { type: String, required: true },
        },

        // =============== Data ===============
        data() {
            return {
                editFormData: {
                    username: null,
                    password_new: null,
                    password_new2: null,
                },
                msgPasswordChangeError: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            // apiUrl() { return `${this.baseUrl}/${componentUrl}` },

            buttons() {
                let buttonSave = { key:'button_save', event:'save', class:"btn btn-sm btn-primary ml-1", label:this.$t('lib.buttons.save') };
                // if (!this.isSaveEnabled) buttonSave.class += ' disabled';
                if (!this.isSaveEnabled) buttonSave.disabled = true;
                return [buttonSave];
            },

            isNewPasswordFilled() {
                return this.editFormData.password_new && this.editFormData.password_new.length >= 8;
            },

            isSecondPasswordsMatch() {
                return this.editFormData.password_new === this.editFormData.password_new2;
            },

            msgInvalidNewPassword() {
                if (this.isNewPasswordFilled) {
                    return null;
                } else {
                    return this.$t('component.UserPasswordEdit.passwordTooShort');
                }
            },
            msgInvalidSecondPassword() {
                if (this.isSecondPasswordsMatch) {
                    return null;
                } else {
                    return this.$t('component.UserPasswordEdit.passwordsMismatch');
                }
            },

            isSaveEnabled() {
                return (
                    this.isNewPasswordFilled && 
                    this.isSecondPasswordsMatch
                );
            },
        },

        // =============== Validations ===============
        // validations: {
        //     editFormData: {
        //         username: { required },
        //         email: { required },
        //         organization_id: {required}
        //     }
        // },

        // =============== Methods ===============
        methods: {
            async savePassword() {
                try {
                    let response = await this.authenticatedAxios.put(`${this.baseUrl}/v_user/${this.userId}/password`, this.editFormData);
                    if (response.data.success) {
                        tools.displaySuccessSave(this, this.$t('component.UserPasswordEdit.passwordChanged'));
                        this.$emit('close-modal', true);
                    } else {
                        this.msgPasswordChangeError = this.$t('component.UserPasswordEdit.passwordChangeError');
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            closeModal() {
                this.processClose();
            },
            processClose() { this.$emit('close-modal', false) },
            cancelClose() { this.isDisplayWarningClose = false },

        },

        // =============== Life cycle ===============
        beforeMount() {
            this.editFormData.username = this.username;
        },
    }
</script>

<style>

</style>
