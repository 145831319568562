/*
    Author:Eduard Grebenyukov
    Date:2021-09-12
*/

import { i18n } from '@/locales';

import { pageSubmenu } from '../store/pageSubmenu';

const SubmenuPage = () => import('@/components/lib/pages/SubmenuPage');

const Payers = () => import('@/components/pages/cashdesk/Payers');
const AccountCashdesk = () => import('@/components/pages/cashdesk/AccountCashdesk');
const Payment = () => import('@/components/pages/cashdesk/Payment');
const Deposit = () => import('@/components/pages/cashdesk/Deposit');
const Refund = () => import('@/components/pages/cashdesk/Refund');
const CashRegisterControl = () => import('@/components/pages/cashdesk/CashRegisterControl');
const Transactions = () => import('@/components/pages/cashdesk/Transactions');
const TransactionEdit = () => import('@/components/pages/cashdesk/TransactionEdit');

const routes = [
    { name:'cashdesk',                  path:'/cashdesk',                                        component:SubmenuPage,         props:{ config:pageSubmenu.submenu_finances }, meta:{title:i18n.t('MainMenu.cashdesk')} },
    { name:'cashdesk/payers',           path:'/cashdesk/payers',                                 component:Payers,                                                             meta:{title:i18n.t('MainMenu.cashdesk-payers')} },
    { name:'cashdesk/account',          path:'/cashdesk/account/:accountId',                     component:AccountCashdesk,                                                    meta:{title:i18n.t('object.fin_account')} },
    { name:'cashdesk/transactions',     path:'/cashdesk/account/:accountId/transactions',        component:Transactions,                                                       meta:{title:i18n.t('objects.fin_cash')} },
    { name:'cashdesk/transaction-edit', path:'/cashdesk/account/:accountId/transaction/:cashId', component:TransactionEdit,                                                    meta:{title:i18n.t('object.fin_cash')} },
    { name:'cashdesk/payment',          path:'/cashdesk/payment',                                component:Payment,             props:true,                                    meta:{title:i18n.t('component.Payment.paymentTitle')} },
    { name:'cashdesk/deposit',          path:'/cashdesk/account/:accountId/deposit',             component:Deposit,                                                            meta:{title:i18n.t('component.Deposit.depositTitle')} },
    { name:'cashdesk/refund',           path:'/cashdesk/account/:accountId/refund',              component:Refund,                                                             meta:{title:i18n.t('component.Refund.refundTitle')} },
    { name:'cashdesk/control',          path:'/cashdesk/control',                                component:CashRegisterControl,                                                meta:{title:i18n.t('MainMenu.cashdesk-control')} },
]

export default routes
