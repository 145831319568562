<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-06
-->

<template>
    <Grid
        v-if="lastClientArray.length"
        :source="lastClientArray"
        :config="lastClientGridConfig"
        @edit-item="openClient($event)"
    />
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import tools from '@/components/lib/tools';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'LastClients',
    
        components: { Grid },

        // =============== Data ===============
        data() {
            return {
                lastClientArray: [],
                lastClientGridConfig: {
                    filter: null,
                    title: this.$t('component.LastClients.lastClients'),
                    readonly: true,
                    columns: [
                        // { type:'hidden', key:'id' },
                        { type:'hidden', key:'core_client_id', header:"Client ID" },
                        { type:'string', key:'shortname', header:this.$t('attribute.core_person.shortname'), sortable: false, textEmit:'edit-item', textClass:'link', textStyle:'color:#056bbf;' },
                        { type:'date', key:'dob', header:this.$t('attribute.core_person.dob'), sortable: false },
                    ],
                    order: [{ key:'none' }],
                    displayToolbar: false,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['setLastClientId']),
            async openClient(client) {
                // console.log(`DEBUG: ${this.$options.name}.openClient() client=`, client);
                await this.setLastClientId(client.core_client_id);
                // this.$router.push({ name: 'client-edit', params: { id: client.core_client_id } });
                this.$router.push({ name: 'client-visits', params: { clientId: client.core_client_id } });
            },

            async getData() {
                try {
                    this.lastClientArray = (await this.authenticatedAxios.get(`${this.baseUrl}/core_user_settings/user.last_client`)).data.rows;
                    this.$emit('refreshed', this.lastClientArray.length > 0);
                    // console.log(`DEBUG: ${this.$options.name}.getData() lastClients=`, lastClients);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            this.getData();
        }
    }
</script>

<style>
</style>
