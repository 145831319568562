<!--
    Author: Eduard Grebenyukov
    Date: 2020-05-20
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose @click="closePressed()" />
        </template>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-sm-12 col-xl-8">
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('attribute.fin_cash.fin_payment_type_id')"
                                    :value.sync="editFormData.fin_payment_type_id"
                                    :source="paymentTypeSourceString"
                                    order="name"
                                    required
                                    @modified="modifiedPaymentType"
                                />
                            </div>
                            <div class="col-sm" :style="(paymentType && paymentType.is_bank_card ? 'display:block;' : 'display:none;')">
                                <FieldString
                                    :label="$t('attribute.fin_cash.bank_card_num')"
                                    :value.sync="editFormData.bank_card_num"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    :label="$t('attribute.fin_cash.cheque_num')"
                                    :value.sync="editFormData.cheque_num"
                                    :disabled="!!selectedCashRegisterId"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('component.Payment.sum_to_pay')"
                                    :value.sync="editFormData.sum_to_pay"
                                    text-style="font-size:2rem;height:4rem;"
                                    :decimalPlaces="2"
                                    readonly
                                    required
                                    @modified="calcTotal"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('component.Payment.sum_discount')"
                                    :value.sync="editFormData.sum_discount"
                                    text-style="font-size:2rem;height:4rem;"
                                    :decimalPlaces="2"
                                    readonly
                                    @modified="calcTotal"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('component.Payment.sum_transaction')"
                                    :value.sync="editFormData.sum_transaction"
                                    text-style="font-size:2rem;height:4rem;"
                                    :decimalPlaces="2"
                                    readonly
                                    required
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('component.Payment.sum_cash')"
                                    :value.sync="editFormData.sum_cash"
                                    :minValue="0"
                                    :maxValue="cashMaxValue"
                                    input-style="font-size:2rem;height:4rem;"
                                    required
                                    @modified="calcTotal"
                                />
                            </div>
                            <div class="col-sm" :style="(paymentType && paymentType.is_cash ? 'display:block;' : 'display:none;')">
                                <FieldNumber
                                    :label="$t('component.Payment.sum_change')"
                                    :value="sumChange"
                                    text-style="font-size:2rem;height:4rem;"
                                    :decimalPlaces="2"
                                    readonly
                                    required
                                />
                            </div>
                            <div class="col-sm">
                                <button :disabled="!isOperationEnabled" @click.prevent="doPayment()" class="btn btn-primary btn-lg btn-payment" >
                                    {{ $t('component.Payment.makePayment') }}
                                </button>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.common.commentary')"
                                    :value.sync="editFormData.commentary"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <Grid ref="serviceGrid" :source="splitsToPay" :config="serviceGridConfig" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-4">
                        <!-- Right sidebar -->
                        <AccountInfo ref="accountInfo" :accountId="accountId" />
                        <CashdeskInfo />
                    </div>
                </div>
            </div>
        </form>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldText from '@/components/lib/controls/FieldText';

    import Grid from '@/components/lib/regions/Grid';

    import AccountInfo from '@/components/pages/common/AccountInfo';
    import CashdeskInfo from '@/components/pages/cashdesk/CashdeskInfo';

    export default {
        name: 'Payment',

        components: {
            TemplatePageStandard,
            ButtonClose, FieldString, FieldNumber, FieldSelect, FieldText,
            Grid,
            AccountInfo, CashdeskInfo,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Props ===============
        props: {
            accountId: Number,
            splitsToPay: Array,
        },

        // =============== Data ===============
        data() {
            return {
                // itemId: undefined,
                buttons: [],
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.cashdesk') },
                    // { text: this.$t('MainMenu.finances-accounts'), to: '/finances/accounts' },
                    { text: this.$t('component.Payment.paymentTitle'), to: '#' },
                ],
                paymentTypeSourceString: 'fin_payment_type?filter={"where":[{"is_archived":false}]}',
                isOperationInProgress: false,
                editFormData: {
                    fin_payment_type_id: null,
                    cash_register_id: null,
                    pos_terminal_id: null,
                    bank_card_num: null,
                    cheque_num: null,
                    sum_to_pay: null,
                    sum_discount: 0,
                    sum_transaction: null,
                    sum_cash: null,
                    commentary: null,
                },
                paymentType: null,
                cashMaxValue: undefined,
                serviceGridConfig: {
                    // apiUrl: `fin-get-services-to-pay/${this.accountId}`,
                    // source: this.splitsToPay,
                    filter: null,
                    title: this.$t('component.Account.services'),
                    readonly: true,
                    columns: [
                        // { type:'hidden', key:'id', header:'id', align:'right', width:'100px' },
                        { type:'string', key:'bill_num', header:this.$t('attribute.fin_bill.bill_num'), align:'right' },
                        { type:'date', key:'time_of', header:this.$t('attribute.common.date_of') },
                        { type:'string', key:'service_name', header:this.$t('object.fin_service') },
                        // { type:'number', key:'sum_payment', header:this.$t('component.Account.sum_payment'), align:'right' },
                        // { type:'number', key:'sum_paid', header:this.$t('component.Account.sum_paid'), align:'right' },
                        // { type:'number', key:'sum_to_pay', header:this.$t('component.Account.sum_to_pay'), align:'right', $value:entry => entry.sum_payment - entry.sum_paid },
                        { type:'number', key:'sum_unpaid', header:this.$t('component.Account.sum_to_pay'), align:'right' },
                    ],
                    order: [ 'id' ],
                    displayToolbar: false,
                    buttons: [],
                    totals: {
                        columns: [
                            { type:'string', key:'service_name', value:this.$t('app.total'), style:'font-weight:bold; font-size:1.1rem;' },
                            // {
                            //     type:'number', key:'sum_payment', style:'font-weight:bold;',
                            //     $value: (data) => data && data.length > 0 ? data.reduce( ((sum, item) => sum + item.sum_payment), 0 ) : 0
                            // },
                            // {
                            //     type:'number', key:'sum_paid', style:'font-weight:bold;',
                            //     $value: (data) => data && data.length > 0 ? data.reduce( ((sum, item) => sum + item.sum_paid), 0 ) : 0
                            // },
                            // {
                            //     type:'number', key:'sum_to_pay', style:'font-weight:bold;',
                            //     $value: (data) => data && data.length > 0 ? data.reduce( ((sum, item) => sum + item.sum_payment - item.sum_paid), 0 ) : 0
                            // },
                            {
                                type:'number', key:'sum_unpaid', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => data && data.length > 0 ? data.reduce( ((sum, item) => sum + item.sum_unpaid), 0 ) : 0
                            },
                        ],
                    },
                },
                accountData: {
                    // id: null,
                    sum_account: null,
                },
                sumChange: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                selectedCashRegisterId: 'selectedCashRegisterId',
            }),
            // sumChange() { return Math.max(tools.round(this.editFormData.sum_cash - this.editFormData.sum_transaction, 2), 0); },
            isOperationEnabled() {
                return (
                    // this.editFormData.sum_cash >= this.editFormData.sum_transaction &&
                    this.editFormData.sum_cash > 0 &&
                    (this.editFormData.fin_payment_type_id || this.editFormData.fin_payment_type_id === 0) &&
                    !this.isOperationInProgress
                );
            },
        },

        // =============== Methods ===============
        methods: {
            calcSumTransaction() {
                if (!this.editFormData.sum_discount) this.editFormData.sum_discount = 0;
                this.editFormData.sum_transaction = tools.round(this.editFormData.sum_to_pay - this.editFormData.sum_discount, 2);
            },
            resetSumCash() {
                this.calcSumTransaction();
                // if (this.editFormData.sum_cash < 0 || !this.editFormData.sum_cash) this.editFormData.sum_cash = 0;
                if (this.editFormData.sum_transaction) this.editFormData.sum_cash = this.editFormData.sum_transaction;
            },

            calcTotal() {
                // console.log(`DEBUG: ${this.$options.name}.calcTotal() this.editFormData.sum_cash1=`, this.editFormData.sum_cash);
                // console.log(`DEBUG: ${this.$options.name}.calcTotal() this.paymentType=`, this.paymentType);
                this.calcSumTransaction();

                if (this.editFormData.sum_cash < 0 || !this.editFormData.sum_cash) this.editFormData.sum_cash = 0;
                if (this.editFormData.sum_transaction && !this.editFormData.sum_cash) this.editFormData.sum_cash = this.editFormData.sum_transaction;

                // console.log(`DEBUG: ${this.$options.name}.calcTotal() this.editFormData.sum_cash2=`, this.editFormData.sum_cash);
                // console.log(`DEBUG: ${this.$options.name}.calcTotal() this.editFormData.sum_transaction=`, this.editFormData.sum_transaction);
                if (
                    this.paymentType &&
                    !this.paymentType.is_cash &&
                    this.editFormData.sum_cash > this.editFormData.sum_transaction
                ) {
                    this.cashMaxValue = this.editFormData.sum_transaction;
                    this.editFormData.sum_cash = this.cashMaxValue;
                    // console.log(`DEBUG: ${this.$options.name}.calcTotal() this.editFormData.sum_cash3=`, this.editFormData.sum_cash);
                }
                if (
                    this.paymentType &&
                    this.paymentType.is_offset &&
                    // this.editFormData.sum_cash > (this.accountData.sum_account - this.accountData.sum_discount)
                    this.editFormData.sum_cash > this.accountData.sum_account
                ) {
                    // this.cashMaxValue = (this.accountData.sum_account - this.accountData.sum_discount);
                    this.cashMaxValue = this.accountData.sum_account;
                    this.editFormData.sum_cash = this.cashMaxValue;
                }
                if (this.editFormData.sum_cash) {
                    this.sumChange = Math.max(tools.round(this.editFormData.sum_cash - this.editFormData.sum_transaction, 2), 0);
                } else {
                    this.sumChange = 0;
                }
                // console.log(`DEBUG: ${this.$options.name}.calcTotal() ${this.editFormData.sum_cash} - ${this.editFormData.sum_transaction}`);
            },

            async modifiedPaymentType() {
                try {
                    this.paymentType = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_payment_type/${this.editFormData.fin_payment_type_id}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getDiscount() fin_payment_type=`, fin_payment_type);
                    if (this.paymentType && this.paymentType.fin_discount_id) {
                        let fin_discount = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_discount/${this.paymentType.fin_discount_id}`)).data;
                        // console.log(`DEBUG: ${this.$options.name}.getDiscount() fin_discount=`, fin_discount);
                        this.editFormData.sum_discount = - tools.round(this.editFormData.sum_to_pay * fin_discount.pct_value / 100, 2);
                    } else {
                        this.editFormData.sum_discount = 0;
                    }
                    this.resetSumCash();
                    this.calcTotal();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async doPayment() {
                // console.log(`DEBUG: ${this.$options.name}.doPayment() sum_cash=`, this.editFormData.sum_cash);
                this.isOperationInProgress = true;
                if (this.paymentType) {
                    try {
                        let { ...payload } = this.editFormData;
                        payload.fin_account_id = this.accountData.id;
                        payload.core_workplace_id = this.currentUser.currentWorkplace.id;
                        payload.fin_split_id_array = this.splitsToPay.map(split => ( split.id ));
                        payload.cash_register_id = this.selectedCashRegisterId;

                        if (this.paymentType.is_offset) {
                            await this.authenticatedAxios.post(`${this.baseUrl}/fin-create-offset`, payload);
                        } else {
                            payload.fin_split = this.splitsToPay.map(split => ({ id:split.id, sum_unpaid:split.sum_unpaid }));
                            // console.log(`DEBUG: ${this.$options.name}.doPayment() payload=`, payload);
                            await this.authenticatedAxios.post(`${this.baseUrl}/fin-create-payment`, payload);
                            // console.log(`DEBUG: ${this.$options.name}.doPayment() response=`, response);
                        }
                        tools.displaySuccessSave(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                this.isOperationInProgress = false;
            },

            async getData() {
                // console.log(`DEBUG: ${this.$options.name}.getData() this.workplace=`, this.workplace);
                try {
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-get-account/${this.accountId}`)).data;
                    if (data) {
                        this.accountData = data;
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() params=`, this.$route.params);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(${this.accountId}) splitsToPay=`, this.splitsToPay);
            if (!this.accountId || this.splitsToPay.length === 0) { this.$router.go(-1); return; }
            this.editFormData.sum_to_pay = this.splitsToPay.reduce( ((sum, item) => (sum + item.sum_unpaid)), 0 );
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.contractId=${this.contractId}`);
            if (this.accountId) {
                await this.getData();
                this.calcTotal();
            }
        },
    }
</script>

<style>
    .btn.btn-payment {
        margin-top: 25px;
        height:4rem;
        width: 100%;
    }
</style>
