<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-14
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonPrint v-if="buttons.includes('printTreatmentPlan') && printforms.includes('treatment_plan')" @click="printTreatmentPlan()">
                <i class="fas fa-file-export"></i> {{ $t('component.ConsultationDental.printTreatmentPlan') }}
            </ButtonPrint>
            <ButtonPrint v-if="buttons.includes('print')" @click="printItem()" />
            <!-- <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" /> -->
        </template>

        <VisitDescription />

        <VisitTabs currentTab="visit-consultation-dental" />

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-sm-12 col-xl-8">
                        <div class="dental-formula">
                            <div class="form-row">
                                <div class="col-6 quadrant-5">
                                    <div class="d-flex mt-2">
                                        <FieldString label="55" :value.sync="editFormData.d55" @focus="itemFocused('d55', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="54" :value.sync="editFormData.d54" @focus="itemFocused('d54', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="53" :value.sync="editFormData.d53" @focus="itemFocused('d53', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="52" :value.sync="editFormData.d52" @focus="itemFocused('d52', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="51" :value.sync="editFormData.d51" @focus="itemFocused('d51', 'med_dental.formula.')" @modified="itemModified()" />
                                    </div>
                                </div>
                                <div class="col-6 quadrant-6">
                                    <div class="d-flex mt-2">
                                        <FieldString label="61" :value.sync="editFormData.d61" @focus="itemFocused('d61', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="62" :value.sync="editFormData.d62" @focus="itemFocused('d62', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="63" :value.sync="editFormData.d63" @focus="itemFocused('d63', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="64" :value.sync="editFormData.d64" @focus="itemFocused('d64', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="65" :value.sync="editFormData.d65" @focus="itemFocused('d65', 'med_dental.formula.')" @modified="itemModified()" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-6 quadrant-1">
                                    <div class="d-flex mt-2">
                                        <FieldString label="18" :value.sync="editFormData.d18" @focus="itemFocused('d18', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="17" :value.sync="editFormData.d17" @focus="itemFocused('d17', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="16" :value.sync="editFormData.d16" @focus="itemFocused('d16', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="15" :value.sync="editFormData.d15" @focus="itemFocused('d15', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="14" :value.sync="editFormData.d14" @focus="itemFocused('d14', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="13" :value.sync="editFormData.d13" @focus="itemFocused('d13', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="12" :value.sync="editFormData.d12" @focus="itemFocused('d12', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="11" :value.sync="editFormData.d11" @focus="itemFocused('d11', 'med_dental.formula.')" @modified="itemModified()" />
                                    </div>
                                </div>
                                <div class="col-6 quadrant-2">
                                    <div class="d-flex mt-2">
                                        <FieldString label="21" :value.sync="editFormData.d21" @focus="itemFocused('d21', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="22" :value.sync="editFormData.d22" @focus="itemFocused('d22', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="23" :value.sync="editFormData.d23" @focus="itemFocused('d23', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="24" :value.sync="editFormData.d24" @focus="itemFocused('d24', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="25" :value.sync="editFormData.d25" @focus="itemFocused('d25', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="26" :value.sync="editFormData.d26" @focus="itemFocused('d26', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="27" :value.sync="editFormData.d27" @focus="itemFocused('d27', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="28" :value.sync="editFormData.d28" @focus="itemFocused('d28', 'med_dental.formula.')" @modified="itemModified()" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-6 quadrant-4">
                                    <div class="d-flex mt-2">
                                        <FieldString label="48" :value.sync="editFormData.d48" @focus="itemFocused('d48', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="47" :value.sync="editFormData.d47" @focus="itemFocused('d47', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="46" :value.sync="editFormData.d46" @focus="itemFocused('d46', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="45" :value.sync="editFormData.d45" @focus="itemFocused('d45', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="44" :value.sync="editFormData.d44" @focus="itemFocused('d44', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="43" :value.sync="editFormData.d43" @focus="itemFocused('d43', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="42" :value.sync="editFormData.d42" @focus="itemFocused('d42', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="41" :value.sync="editFormData.d41" @focus="itemFocused('d41', 'med_dental.formula.')" @modified="itemModified()" />
                                    </div>
                                </div>
                                <div class="col-6 quadrant-3">
                                    <div class="d-flex mt-2">
                                        <FieldString label="31" :value.sync="editFormData.d31" @focus="itemFocused('d31', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="32" :value.sync="editFormData.d32" @focus="itemFocused('d32', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="33" :value.sync="editFormData.d33" @focus="itemFocused('d33', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="34" :value.sync="editFormData.d34" @focus="itemFocused('d34', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="35" :value.sync="editFormData.d35" @focus="itemFocused('d35', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="36" :value.sync="editFormData.d36" @focus="itemFocused('d36', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="37" :value.sync="editFormData.d37" @focus="itemFocused('d37', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="38" :value.sync="editFormData.d38" @focus="itemFocused('d38', 'med_dental.formula.')" @modified="itemModified()" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-6 quadrant-8">
                                    <div class="d-flex mt-2">
                                        <FieldString label="85" :value.sync="editFormData.d85" @focus="itemFocused('d85', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="84" :value.sync="editFormData.d84" @focus="itemFocused('d84', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="83" :value.sync="editFormData.d83" @focus="itemFocused('d83', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="82" :value.sync="editFormData.d82" @focus="itemFocused('d82', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="81" :value.sync="editFormData.d81" @focus="itemFocused('d81', 'med_dental.formula.')" @modified="itemModified()" />
                                    </div>
                                </div>
                                <div class="col-6 quadrant-7">
                                    <div class="d-flex mt-2">
                                        <FieldString label="71" :value.sync="editFormData.d71" @focus="itemFocused('d71', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="72" :value.sync="editFormData.d72" @focus="itemFocused('d72', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="73" :value.sync="editFormData.d73" @focus="itemFocused('d73', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="74" :value.sync="editFormData.d74" @focus="itemFocused('d74', 'med_dental.formula.')" @modified="itemModified()" />
                                        <FieldString label="75" :value.sync="editFormData.d75" @focus="itemFocused('d75', 'med_dental.formula.')" @modified="itemModified()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.complaints')"
                                    :value.sync="editFormData.complaints"
                                    @focus="itemFocused('complaints', 'med_dental.complaints')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.illness_history')"
                                    :value.sync="editFormData.illness_history"
                                    @focus="itemFocused('illness_history', 'med_dental.illness_history')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.physical_exam')"
                                    :value.sync="editFormData.physical_exam"
                                    @focus="itemFocused('physical_exam', 'med_dental.physical_exam')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.instrumental_exam')"
                                    :value.sync="editFormData.instrumental_exam"
                                    @focus="itemFocused('instrumental_exam', 'med_dental.instrumental_exam')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <!-- <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.diff_diag')"
                                    :value.sync="editFormData.diff_diag"
                                    @focus="itemFocused('diff_diag', 'med_dental.diff_diag')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div> -->
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.exam_plan')"
                                    :value.sync="editFormData.exam_plan"
                                    @focus="itemFocused('exam_plan', 'med_dental.exam_plan')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.diagnosis')"
                                    :value.sync="editFormData.diagnosis"
                                    @focus="itemFocused('diagnosis', 'med_dental.diagnosis')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.treatment')"
                                    :value.sync="editFormData.treatment"
                                    @focus="itemFocused('treatment', 'med_dental.treatment')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <!-- <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_consultation.regime')"
                                    :value.sync="editFormData.regime"
                                    @focus="itemFocused('regime', 'med_dental.regime')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div> -->
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('attribute.med_consultation.assistant_user_id')"
                                    :value.sync="editFormData.assistant_user_id"
                                    :source="`consultation_assistants/${editFormData.visit_rec_id}`"
                                    keyLabel="fullname"
                                    order="fullname"
                                    showEmptyRow
                                    @focus="itemFocused('assistant_user_id', 'med_dental.assistant_user_id')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <PlanNextVisit :clientId="clientId" :visitId="visitId" />
                            </div>
                        </div>
                        <!-- <div v-if="true" class="form-row">
                            <div class="col-sm">
                                <VisitTasks :clientId="clientId" :visitId="visitId" />
                            </div>
                        </div> -->
                    </div>
                    <div class="col-sm-12 col-xl-4">
                        <!-- Right sidebar -->
                        <VisitFormTemplate
                            :title="currentTitle || $t('Templates.templates')"
                            :visitTypeId="visitRec.visit_type_id"
                            :specialityId="visitRec.core_speciality_id"
                            :formItemName="currentFormItem"
                            :attributeName="currentAttribute"
                            :formData.sync="editFormData"
                            @modified="itemModified()"
                        />
                    </div>
                </div>
            </div>
        </form>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonPrint from '@/components/lib/controls/ButtonPrint';

    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldText from '@/components/lib/controls/FieldText';
    import VisitFormTemplate from '@/components/pages/visits/VisitFormTemplate';

    import PlanNextVisit from '@/components/pages/visits/PlanNextVisit';
    // import VisitTasks from '@/components/pages/visits/VisitTasks';

    export default {
        name: 'ConsultationDental',

        components: {
            TemplatePageStandard, VisitDescription, VisitTabs,
            ButtonClose, ButtonSave, ButtonPrint,
            FieldSelect, FieldString, FieldText, VisitFormTemplate,
            PlanNextVisit, //VisitTasks,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                visitRec: {},
                teeth: [],
                printforms: [],
                currentFormItem: null,
                currentAttribute: null,
                currentTitle: null,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.consultation-dental') }),
                editFormData: {
                    // id: null,
                    visit_rec_id: null,
                    assistant_user_id: null,
                    complaints: null,
                    illness_history: null,
                    physical_exam: null,
                    instrumental_exam: null,
                    diff_diag: null,
                    exam_plan: null,
                    diagnosis: null,
                    treatment: null,
                    regime: null,

                    d51: null, d52: null, d53: null, d54: null, d55: null,
                    d61: null, d62: null, d63: null, d64: null, d65: null,
                    d11: null, d12: null, d13: null, d14: null, d15: null, d16: null, d17: null, d18: null,
                    d21: null, d22: null, d23: null, d24: null, d25: null, d26: null, d27: null, d28: null,
                    d41: null, d42: null, d43: null, d44: null, d45: null, d46: null, d47: null, d48: null,
                    d31: null, d32: null, d33: null, d34: null, d35: null, d36: null, d37: null, d38: null,
                    d81: null, d82: null, d83: null, d84: null, d85: null,
                    d71: null, d72: null, d73: null, d74: null, d75: null,
                    
                },
                oldFormData: {},
                // isDisplayWarningDelete: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                jwt: 'jwt',
            }),
        },

        // =============== Watch ===============
        // watch: {
        //     clientId() {
        //         console.log(`DEBUG: ${this.$options.name}.watch.clientId this.breadcrumb=`, this.breadcrumb);
        //         let b = this.breadcrumb.slice();
        //         b[b - 2].to = `/client/${this.clientId}`;
        //         this.breadcrumb = b;
        //         // this.breadcrumb[this.breadcrumb.length - 2].to = `/client/${this.clientId}`;
        //     },
        // },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = [ 'save', 'delete', 'close', 'print', 'printTreatmentPlan' ];
                } else {
                    this.buttons = [ 'save', 'close' ];
                }
            },

            setBreadcrumb() {
            },

            setConsultationData(data) {
                if (data) {
                    // this.editFormData = data;
                    this.editFormData.id = data.id;
                    this.editFormData.visit_rec_id = data.visit_rec_id;
                    this.editFormData.assistant_user_id = data.assistant_user_id;
                    this.editFormData.complaints = data.complaints;
                    this.editFormData.illness_history = data.illness_history;
                    this.editFormData.physical_exam = data.physical_exam;
                    this.editFormData.instrumental_exam = data.instrumental_exam;
                    this.editFormData.diff_diag = data.diff_diag;
                    this.editFormData.exam_plan = data.exam_plan;
                    this.editFormData.diagnosis = data.diagnosis;
                    this.editFormData.treatment = data.treatment;
                    this.editFormData.regime = data.regime;
                    this.itemId = this.editFormData.id;
                } else {
                    this.itemId = null;
                }
            },

            setTeethData(data) {
                if (data) {
                    for (let tooth of this.teeth) {
                        let itemName = `d${tooth.code}`;
                        this.editFormData[itemName] = data[itemName];
                    }
                    this.teethId = data.id;
                }
            },

            gotData() {
                this.isModified = false;
                this.msgItemModified = '';
                this.oldFormData = tools.deepCopy(this.editFormData);

                this.setBreadcrumb();
                this.checkButtons();
            },

            async getTeeth() {
                try {
                    this.teeth = (await this.authenticatedAxios.get(`${this.baseUrl}/med_tooth`)).data.rows;
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getConsultationData() {
                try {
                    this.visitRec = (await this.authenticatedAxios.get(`${this.baseUrl}/visit/${this.visitId}`)).data;
                    // this.workplace = (await this.authenticatedAxios.get(`${this.baseUrl}/core_workplace/${this.visitRec.core_workplace_id}`)).data.rows[0];
                    let filterString = tools.getFilterString({ where:[{ visit_rec_id:this.editFormData.visit_rec_id }]});
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/med_consultation${filterString}`)).data.rows[0];
                    this.setConsultationData(data);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getTeethData(currentVisit) {
                // console.log(`DEBUG: ${this.$options.name}.getTeethData(id=${currentVisit.id})`);
                try {
                    let teethData;
                    let filterString1;
                    if (currentVisit.id) {
                        let filterString = tools.getFilterString({ where:[{ visit_rec_id:currentVisit.id }]});
                        teethData = (await this.authenticatedAxios.get(`${this.baseUrl}/med_teeth${filterString}`)).data.rows[0];
                    }
                    if (teethData) {
                        this.setTeethData(teethData);
                    } else {
                        // get from previous visit
                        if (currentVisit.id) {
                            filterString1 = tools.getFilterString({
                                where:[
                                    { core_client_id: currentVisit.core_client_id },
                                    [ 'time_of', '<', currentVisit.time_of ],
                                ],
                                order: [{ key:'time_of', direction:'desc' }],
                            });
                        } else {
                            // ??? is it possible???
                            filterString1 = tools.getFilterString({
                                where:[
                                    { core_client_id: currentVisit.core_client_id },
                                ],
                                order: [{ key:'time_of', direction:'desc' }],
                            });
                        }
                        let previousVisit = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_rec${filterString1}`)).data.rows[0];
                        if (previousVisit) await this.getTeethData(previousVisit);
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveConsultation() {
                let consultationData = {
                    id: this.editFormData.id,
                    visit_rec_id: this.editFormData.visit_rec_id,
                    assistant_user_id: this.editFormData.assistant_user_id,
                    complaints: this.editFormData.complaints,
                    illness_history: this.editFormData.illness_history,
                    physical_exam: this.editFormData.physical_exam,
                    instrumental_exam: this.editFormData.instrumental_exam,
                    diff_diag: this.editFormData.diff_diag,
                    exam_plan: this.editFormData.exam_plan,
                    diagnosis: this.editFormData.diagnosis,
                    treatment: this.editFormData.treatment,
                    regime: this.editFormData.regime,

                };
                //this.$v.$touch();
                //if (!this.$v.$invalid) {
                if (typeof this.editFormData.id != 'undefined') {
                    // Update
                    // console.log(`DEBUG: ${this.$options.name}.saveData() Update this.editFormData=`, this.editFormData);
                    // catch exception in caller method
                    let data = (await this.authenticatedAxios.put(`${this.baseUrl}/med_consultation/${this.editFormData.id}`, consultationData)).data;
                    this.setConsultationData(data);
                } else {
                    // New record
                    // console.log(`DEBUG: ${this.$options.name}.saveData() New this.editFormData=`, this.editFormData);
                    let data = (await this.authenticatedAxios.post(`${this.baseUrl}/med_consultation`, consultationData)).data;
                    // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                    this.setConsultationData(data);
                }
            },

            async saveTeeth() {
                // let teethData = {
                //     d51: this.editFormData.d51,
                //     d52: d52, d53: d53, d54: d54, d55: d55,
                //     d61: d61, d62: d62, d63: d63, d64: d64, d65: d65,
                //     d11: d11, d12: d12, d13: d13, d14: d14, d15: d15, d16: d16, d17: d17, d18: d18,
                //     d21: d21, d22: d22, d23: d23, d24: d24, d25: d25, d26: d26, d27: d27, d28: d28,
                //     d41: d41, d42: d42, d43: d43, d44: d44, d45: d45, d46: d46, d47: d47, d48: d48,
                //     d31: d31, d32: d32, d33: d33, d34: d34, d35: d35, d36: d36, d37: d37, d38: d38,
                //     d81: d81, d82: d82, d83: d83, d84: d84, d85: d85,
                //     d71: d71, d72: d72, d73: d73, d74: d74, d75: d75,
                // }
                let filterString = tools.getFilterString({ where:[{ visit_rec_id:this.visitId }]});
                let previousTeethData = (await this.authenticatedAxios.get(`${this.baseUrl}/med_teeth${filterString}`)).data.rows[0];
                let teethData = {
                    visit_rec_id: this.visitId,
                };
                for (let tooth of this.teeth) {
                    let itemName = `d${tooth.code}`;
                    teethData[itemName] = this.editFormData[itemName];
                }
                if (previousTeethData) {
                    let data = (await this.authenticatedAxios.put(`${this.baseUrl}/med_teeth/${previousTeethData.id}`, teethData)).data;
                    this.setTeethData(data);
                } else {
                    let data = (await this.authenticatedAxios.post(`${this.baseUrl}/med_teeth`, teethData)).data;
                    // console.log(`DEBUG: ${this.$options.name}.saveTeeth() data=`, data);
                    this.setTeethData(data);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isModified=${this.isModified}, this.editFormData=`, this.editFormData);
                if (this.isModified) {
                    try {
                        await this.saveConsultation();
                        await this.saveTeeth();
                        this.gotData();
                        tools.displaySuccessSave(this);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
            },

            itemFocused(item, templateName) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() item=${item}`);
                this.currentFormItem = item;
                // this.currentAttribute = templateName ? templateName : item;
                this.currentAttribute =
                    templateName ? (
                        templateName[templateName.length - 1] == '.' ?
                        templateName.substring(0, templateName.length - 1) :
                        templateName
                    ) : item;
                this.currentTitle =this.$t('VisitDetails.' + (templateName[templateName.length - 1] == '.' ? templateName + item : templateName));
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() this.currentTitle=${this.currentTitle}`);
                // this.currentFormItemValue = this.editFormData[this.currentFormItem.split('.')[1]];
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() this.currentFormItemValue=${this.currentFormItemValue}`);
            },
            
            itemModified() {
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.isModified=`, this.isModified);
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.oldFormData=`, JSON.stringify(this.oldFormData));
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.editFormData=`, JSON.stringify(this.editFormData));
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
                this.setBreadcrumb();
            },

            // askDeleteItem() {
            //     this.isDisplayWarningDelete = true;
            // },
            // async processDeleteItem(doDelete) {
            //     if (doDelete) {
            //         try {
            //         await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
            //         this.isModified = false;
            //         tools.displaySuccessDelete(this.$parent);
            //         this.$router.go(-1);
            //     } else {
            //         this.isDisplayWarningDelete = false;
            //     }
            // },

            async getPrintForms() {
                try {
                    this.printforms = (await this.authenticatedAxios.get(`${this.baseUrl}/core_printform`)).data.rows.map( item => item.code );
                    // console.log(`DEBUG: ${this.$options.name}.getPrintForms() this.printforms=`, this.printforms);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async printItem() {
                this.saveData();
                tools.printForm(
                    this,
                    {
                        // taskId: `print.${this.$options.name}`,
                        template: 'consultation_dental',
                        consultation: JSON.parse(JSON.stringify(this.editFormData)),
                        visit: { visit_rec_id: this.visitId },
                    }
                );
                // console.log(`DEBUG: ${this.$options.name}.printItem() printData=`, printData);
            },

            async printTreatmentPlan() {
                this.saveData();
                // tools.printForm(
                //     this,
                //     {
                //         // taskId: `print.${this.$options.name}`,
                //         template: 'treatment_plan',
                //         consultation: JSON.parse(JSON.stringify(this.editFormData)),
                //         visit: { visit_rec_id: this.visitId },
                //     }
                // );
                // console.log(`DEBUG: ${this.$options.name}.printTreatmentPlan() printData=`, printData);
                // this.authenticatedAxios.get(`${this.baseUrl}/exports/treatment-plan/${this.visitId}`, { responseType: 'arraybuffer' }).then( response => {
                // // this.authenticatedAxios.get(`${this.baseUrl}/exports/treatment-plan/${this.visitId}`).then( response => {
                //     console.log(`DEBUG: ${this.$options.name}.printTreatmentPlan() response=`, response);
                //     // let fileContent = new Blob([ response.data.content.data ], { type: response.data.mimetype });
                //     // let link = document.createElement('a');
                //     // link.href = window.URL.createObjectURL(fileContent);
                //     // link.download = response.data.filename;
                //     // link.click();
                //     // window.URL.revokeObjectURL(link);

                //     // let fileContent = new Blob([ response.data ], { type: 'application/msword' });
                //     // let url = window.URL.createObjectURL(fileContent);
                //     // window.open(url, '_blank');

                //     // let fileContent = new Blob([ response.data ], { type: 'application/msword' });
                //     // let link = document.createElement('a');
                //     // link.href = window.URL.createObjectURL(fileContent);
                //     // link.download = 'somename.xml';
                //     // // link.attr("href", 'somename.xml');
                //     // console.log(`DEBUG: ${this.$options.name}.printTreatmentPlan() link=`, link);
                //     // link.click();
                //     // window.URL.revokeObjectURL(link);
                // });

                // // document.cookie = `jwt=${this.jwt}`;
                // let link = document.createElement('a');
                // // link.href = `${this.baseUrl}/exports/treatment_plan_${this.visitId}.doc`;
                // link.href = `${this.baseUrl}/exports/treatment_plan/${this.visitId}`;
                // // console.log(`DEBUG: ${this.$options.name}.printTreatmentPlan() link=`, link);
                // // link.target = '_blank';
                // link.click();
                // window.URL.revokeObjectURL(link);

                tools.redirectPost(
                    `${this.baseUrl}/exports/treatment_plan/${this.visitId}`,
                    { jwt: this.jwt },
                );
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            this.editFormData.visit_rec_id = this.visitId;
        },

        async mounted() {
            await this.getTeeth();
            if (this.visitId) {
                await this.getConsultationData();
            } else {
                this.setBreadcrumb();
                this.checkButtons();
            }
            await this.getTeethData(this.visitRec); // if current visit is not found, this function get teeth data from previous visit
            this.gotData();
            this.getPrintForms(); // no necessary to wait
            // this.oldFormData = tools.deepCopy(this.editFormData);
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.clientId=${this.clientId}, this.visitId=${this.visitId}`);
        },
    }
</script>

<style lang="scss">
    .dental-formula {
        .quadrant-1, .quadrant-2, .quadrant-3, .quadrant-4  {
            background-color: #ffeeee;
            text-align: center;
        }
        .quadrant-5, .quadrant-6, .quadrant-7, .quadrant-8 {
            background-color: #eeffee;
            text-align: center;
        }
        .quadrant-5, .quadrant-6 {
            border-top: 1px dotted #aaa;
        }
        .quadrant-8, .quadrant-7 {
            border-bottom: 1px dotted #aaa;
            margin-bottom: 1rem;
        }
        .quadrant-5, .quadrant-1, .quadrant-4, .quadrant-8 {
            border-right: 1px dotted #aaa;
            border-left: 1px dotted #aaa;
        }
        .quadrant-6, .quadrant-2, .quadrant-3, .quadrant-7 {
            border-right: 1px dotted #aaa;
        }
        .quadrant-5, .quadrant-6, .quadrant-1, .quadrant-2, .quadrant-4, .quadrant-3 {
            border-bottom: 1px dotted #aaa;
        }
        .form-group {
            margin-bottom: 5px;
        }
    }
</style>
