<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-18
-->

<template>
    <div>
        <div class="pb-2">
            <div class="input-group input-group-sm" v-if="this.displaySearch || typeof this.displaySearch === 'undefined'">
                <div class="input-group-prepend">
                    <i class="fas fa-search input-group-text" :title="$t('lib.TreeView.inputSearch')" ></i>
                </div>
                <!-- <button disabled><i class="fas fa-search" :title="$t('lib.TreeView.inputSearch')" ></i></button> -->
                <input
                    ref="inputSearch"
                    v-model="searchKeybuffer"
                    class="form-control query-input"
                    name="query"
                    @input="debounceSearch"
                    @keyup.enter="askSearch()"
                >
                <div class="input-group-append">
                    <button type="button" @click="searchKeybuffer = '';$refs.inputSearch.focus();askSearch()" class="btn btn-outline-secondary btn-sm" :title="$t('lib.TreeView.clearSearch')" >
                        <i class="fas fa-times" ></i>
                    </button>
                    <button @click.prevent="expandAll()" class="btn btn-outline-secondary btn-sm" :title="$t('lib.TreeView.buttonExpand')">
                        <i class="fas fa-angle-double-down"></i>
                    </button>
                    <button @click.prevent="collapseAll()" class="btn btn-outline-secondary btn-sm" :title="$t('lib.TreeView.buttonCollapse')">
                        <i class="fas fa-angle-double-up"></i>
                    </button>
                </div>
            </div>
        </div>

        <div ref="itemsWrapper" class="tree-view__items-wrapper" :style="`max-height: ${maximalHeight};`">
            <ul class="fa-ul">
                <template v-for="(item, index) in filteredData">
                    <TreeViewItem
                        ref="items"
                        v-if="!item.isGroup || (item.children && item.children.length > 0) || displayEmptyGroups"
                        :filter="searchQuery"
                        :key="index"
                        :class="{ 'item': item.isSelectable || typeof item.isSelectable == 'undefined' }"
                        :item="item"
                        :itemSelected="itemSelected"
                        :selectedContextItem="selectedContextItem"
                        :displayEmptyGroups="displayEmptyGroups"
                        :folderStyle="folderStyle"
                        :selectedStyle="selectedStyle"
                        :selectedContextStyle="selectedContextStyle"
                        @select="select($event)"
                        @opencontextmenu="openContextMenu($event)"
                    />
                </template>
            </ul>
        </div>
    </div>
</template>>

<script>
    import tools from '@/components/lib/tools';
    import TreeViewItem from './TreeViewItem';

    export default {
        name: 'TreeView',
        components: { TreeViewItem },

        // =============== Props ===============
        props: {
            treeData: Array,
            selectedItem: Object,
            maxHeight: String,
            bottom: Number,
            displayEmptyGroups: { type:Boolean, default: () => { return false }},
            folderStyle: String,
            selectedStyle: String,
            selectedContextStyle: String,
        },

        // =============== Data ===============
        data() {
            return {
                searchKeybuffer: '',
                searchQuery: '',
                debounceTimeout: 600,
                debounce: null,
                displaySearch: true,
                maximalHeight: null,
                itemSelected: null,
                selectedContextItem: null,
                // filteredData: [],
            }
        },

        // =============== Computed ===============
        computed: {
            filteredData() { return tools.filterTree(this.treeData, this.searchQuery) },
            // filteredData() { return tools.filterTree(JSON.parse(JSON.stringify(this.treeData)), this.searchQuery) },
            // filterLowered() { return this.searchKeybuffer.toLowerCase() },
        },

        // =============== Watch ===============
        watch: {
            selectedItem() {
                this.itemSelected = this.selectedItem;
            },
        },

        // =============== Methods ===============
        methods: {
            expandAll() {
                for (let item of this.$refs.items) {
                    item.expandAll();
                }
            },
            collapseAll() {
                for (let item of this.$refs.items) {
                    item.collapseAll();
                }
            },

            onResize() {
                if (this.$refs.itemsWrapper) {
                    let wrapperTop = this.$refs.itemsWrapper.getBoundingClientRect().top;
                    this.maximalHeight = this.bottom ? `calc(${window.innerHeight - wrapperTop - this.bottom}px - 1rem)` : this.maxHeight;
                    // console.log(`DEBUG: ${this.$options.name}.onResize() maximalHeight=${this.maximalHeight}`);
                }
            },

            // applyFilter() {
            //     this.filteredData = tools.filterTree(this.treeData, this.searchQuery);
            // },

            debounceSearch(event) {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.searchQuery = event.target.value;
                    // this.applyFilter();
                }, this.debounceTimeout);
            },

            askSearch() {
                this.searchQuery = this.searchKeybuffer;
                // this.applyFilter();
            },

            select(item) {
                this.itemSelected = item;
                this.$emit('select', item);
            },

            openContextMenu(evt) {
                this.selectedContextItem = evt.item;
                this.$emit('opencontextmenu', evt);
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this=`, this);
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            });
            this.onResize();
            // this.applyFilter();
        },
    }
</script>>

<style>
    .tree-view__items-wrapper {
        overflow-y: auto;
    }
</style>