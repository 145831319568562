<!--
    Author: Eduard Grebenyukov
    Date: 2020-09-28
-->

<template>
    <ModalWindow
        minWidth="300px"
        :buttons="['yes', 'no']"
        @yes="$emit('process', true)"
        @ok="$emit('process', true)"
        @no="$emit('process', false)"
        @cancel="$emit('process', false)"
    >
        <slot>
            {{ text ? text : $t('app.confirmDelete') }}
        </slot>
    </ModalWindow>
</template>

<script>
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    export default {
        name: 'WarningDelete',
        components: { ModalWindow },

        // =============== Props ===============
        props: {
            text: String,
        },
    }
</script>

<style>
</style>
