<!--
    Author: Eduard Grebenyukov
    Date: 2021-06-17
-->

<template>
    <StandardRegion :title="$t('object.fin_account')">
        <div class="account-info__wrapper">
            <table class="account-info__header">
                <tr>
                    <th class="account-info__title">{{ $t('component.Account.payer') + ':' }}</th>
                    <td class="account-info__value">{{ accountData.payer_name }}</td>
                </tr>
                <tr v-if="accountData.contract_name">
                    <th class="account-info__title">{{ $t('object.fin_contract') + ':' }}</th>
                    <td class="account-info__value">{{ accountData.contract_name }}</td>
                </tr>
                <tr>
                    <th class="account-info__title">{{ $t('attribute.fin_account.sum_account') + ':' }}</th>
                    <td class="account-info__value">{{ accountData.sum_account }} {{ accountData.currency_name }}</td>
                </tr>
                <tr v-if="accountData.sum_discount">
                    <th class="account-info__title">{{ $t('attribute.fin_account.sum_discount') + ':' }}</th>
                    <td class="account-info__value">{{ accountData.sum_discount }} {{ accountData.currency_name }}</td>
                </tr>
            </table>
        </div>
    </StandardRegion>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import StandardRegion from '@/components/lib/regions/StandardRegion';

    export default {
        name: 'AccountInfo',

        components: { StandardRegion },

        // =============== Props ===============
        props: {
            accountId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                accountData: {
                    // id: null,
                    fin_contract_id: null,
                    fin_currency_id: null,
                    payer_name: null,
                    contract_name: null,
                    currency_name: null,
                    sum_account: null,
                    sum_discount: null,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Methods ===============
        methods: {
            async getData() {
                // console.log(`DEBUG: ${this.$options.name}.getData() this.workplace=`, this.workplace);
                try {
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-get-account/${this.accountId}`)).data;
                    if (data) {
                        this.accountData = data;
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
            async refresh() {
                await this.getData();
            }
        },

        // =============== Life cycle ===============
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.contractId=${this.contractId}`);
            if (this.accountId) {
                await this.getData();
            }
        },
    }

</script>

<style scoped>
    .account-info__wrapper {
        padding-bottom: 1rem;
        /* background-color: rgb(252, 242, 213); */
        /* background-color: #fff8e5; */
    }
    .account-info__title {
        padding: .375rem 0.5rem;
        white-space: nowrap;
        vertical-align: top;
        font-size: 0.875rem;
        font-weight: bold;
    }
    .account-info__header {
        width: 100%;
    }
    .account-info__header .buttons {
        text-align: right;
    }
    .account-info__header .account-info__title {
        text-align: right;
    }
    .account-info__details .account-info__title {
        margin-top: 0.5rem;
    }
    .account-info__value {
        padding-right: 0.5rem;
        font-size: 0.875rem;
    }
    .account-info__details .account-info__value {
        padding-left: 0.5rem;
    }
</style>
