<!--
    Author: Eduard Grebenyukov
    Date: 2021-09-19
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonPrint v-if="buttons.includes('print')" @click="printMedications()">
                <i class="fas fa-print"></i> {{ $t('component.MedMedications.printMedications') }}
            </ButtonPrint>
            <!-- <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" /> -->
        </template>

        <VisitDescription />

        <div class="referrals__navbar-container">

            <VisitTabs currentTab="visit-medications" />

            <div class="navbar">
                <ButtonSwitch
                    :value.sync="isDisplayRightSidebar"
                    :defaultValue="true"
                    :saveState="`${$options.name}.display_sidebar`"
                    :title="$t('app.hideShow')"
                />
            </div>
        </div>

        <div id="page-content" class="form-float">
            <div class="row">
                <div :class="isDisplayRightSidebar ? 'referrals__referrals col-sm-12 col-lg-7 col-xl-7' : 'referrals__referrals col'">
                    <Grid
                        ref="referrals"
                        :key="$route.fullPath"
                        :config="gridConfig"
                        @edit-item="editMedication($event)"
                        @refreshed="medicationsRefreshed()"
                    />
                </div>
                <div class="referrals__right-sidebar col-sm-12 col-lg-5 col-xl-5" :style="isDisplayRightSidebar ? '' : 'display:none;'">
                    <!-- Right sidebar -->
                    <MedMedicationTemplate
                        :visitTypeId="lastVisit.visit_type_id"
                        :specialityId="lastVisit.core_speciality_id"
                        :visitTime="new Date(lastVisit.time_of)"
                        :formItemName="currentFormItem"
                        :attributeName="currentAttribute"
                        @selected="addMedication($event)"
                    />
                </div>
            </div>
        </div>

        <MedMedicationEdit
            v-if="isDisplayModalMedMedicationEdit"
            :id="medicationId"
            :medicineId="medicineId"
            :visitId="visitId"
            @close-modal="closeModalMedMedicationEdit($event)"
        />
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';

    import ButtonSwitch from '@/components/lib/controls/ButtonSwitch';
    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonPrint from '@/components/lib/controls/ButtonPrint';

    import Grid from '@/components/lib/regions/Grid';
    import MedMedicationTemplate from '@/components/pages/med/MedMedicationTemplate';
    import MedMedicationEdit from './MedMedicationEdit';

    export default {
        name: 'MedMedications',

        components: {
            TemplatePageStandard, VisitDescription, VisitTabs,
            ButtonSwitch, ButtonClose, ButtonSave, ButtonPrint,
            Grid, MedMedicationTemplate, MedMedicationEdit
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                medicationId: null,
                medicineId: null,
                medicationsQty: 0,
                buttons: [],
                msgItemModified: '',
                isDisplayRightSidebar: null,
                currentFormItem: null,
                currentAttribute: null,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.medications') }),
                gridConfig: {
                    apiUrl: null,
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'50px' },
                        { type:'string', key:'medicine_fullname', header:this.$t('object.med_medicine') },
                        { type:'number', key:'medication_dose', header:this.$t('attribute.med_medication_det.dose'), sortable:false, align:'right', width:'64px' },
                        {
                            type:'string', key:'$schema', header:this.$t('component.MedMedications.schema'), 
                            $value: item => {
                                if (item.is_on_necessity) {
                                    return this.$sprintf(this.$t('component.MedMedications.on_necessity'));
                                }
                                if (item.repeat_every === 1) {
                                    return this.$sprintf(this.$t('component.MedMedications.schema1'), item.frequency);
                                }
                                return this.$sprintf(this.$t('component.MedMedications.schemaN'), item.frequency, item.repeat_every, item.time_unit_short);
                            }
                        },
                        { type:'date', key:'date_from', header:this.$t('attribute.common.date_from') },
                        { type:'date', key:'date_to', header:this.$t('attribute.common.date_to') },
                        { type:'string', key:'commentary', header:this.$t('attribute.common.commentary') },
                    ],
                    order: [ 'medicine_fullname' ],
                    buttons: ['edit'],
                    saveSessionState: `${this.$options.name}`,
                },
                isDisplayModalMedMedicationEdit: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                lastClient: 'lastClient',
                lastVisit: 'lastVisit',
                // currencyAccounting: 'currencyAccounting',
            }),
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                // console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.$refs.referrals.qty=`, this.$refs.referrals.getTotalRowsQty());
                if (this.medicationsQty) {
                    this.buttons = ['print'];
                } else {
                    this.buttons = [];
                }
            },

            async addMedication(item) {
                // console.log(`DEBUG: ${this.$options.name}.addMedication(), item=`, item);
                this.medicationId = null;
                this.medicineId = item.id;
                this.isDisplayModalMedMedicationEdit = true;
            },

            editMedication(item) {
                // console.log(`DEBUG: ${this.$options.name}.editMedication(), item=`, item);
                // this.$router.push({ name:'referral-edit', params:{ clientId:this.clientId, visitId:this.visitId, visitReferralId:item.id }});
                this.medicationId = item.id;
                this.medicineId = null;
                this.isDisplayModalMedMedicationEdit = true;
            },

            closeModalMedMedicationEdit(isRefresh) {
                this.isDisplayModalMedMedicationEdit = false;
                if (isRefresh) this.$refs.referrals.refresh();
            },

            medicationsRefreshed() {
                this.medicationsQty = this.$refs.referrals.getTotalRowsQty();
                // console.log(`DEBUG: ${this.$options.name}.medicationsRefreshed(), this.$refs.services.qty=`, this.$refs.services.getTotalRowsQty());
                this.checkButtons();
            },

            async printMedications() {
                try {
                    // console.log(`DEBUG: ${this.$options.name}.printMedications()`);
                    let services = this.$refs.referrals.getAllRows();
                    let sumTotal = services.reduce(((sum, item) => sum + item.sum_payment), 0);
                    let currencyCode = (await tools.getSetting(this, 'currency_accounting')).value;
                    // console.log(`DEBUG: ${this.$options.name}.printMedications() currencyCode=`, currencyCode);
                    let filterStringCurrency = tools.getFilterString({ where:[{ code:currencyCode }]});
                    let currency = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_currency${filterStringCurrency}`)).data.rows[0];
                    // let filterStringBill = tools.getFilterString({ where:[{ visit_rec_id:this.visitId }]});
                    // let bill = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_bill${filterStringBill}`)).data.rows[0];

                    // let currency = this.currencyAccounting;
                    // console.log(`DEBUG: ${this.$options.name}.printMedications() currency=`, currency);
                    let o = {
                        // taskId: `print.${this.$options.name}`,
                        template: 'medications',
                        referrals: {
                            visit_date: moment(this.lastVisit.time_of).format('L'),
                            total_sum: sumTotal,
                            total_sum_company: 0,
                            total_sum_payer: 0,
                            total_sum_patient: sumTotal,
                            visit_rec_id: this.visitId,
                            currency: currency.name_short,
                            services: services
                        },
                        visit: { visit_rec_id: this.visitId },
                    };
                    // console.log(`DEBUG: ${this.$options.name}.printMedications() o=`, o, ', typeof o.referrals.sumTotal=', typeof o.referrals.sumTotal);
                    tools.printForm(this, o);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this=`, this);
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            this.gridConfig.apiUrl = `v_medication`;
            this.gridConfig.filter = { where:[{ visit_rec_id:this.visitId }]};
        },

        async mounted() {
            this.checkButtons();
        },
    }
</script>

<style>
    .referrals__navbar-container {
        display: flex;
    }
    .referrals__referrals {
        overflow-x: auto;
    }
    /* .referrals__right-sidebar {
    } */
    /* .referrals__referrals .grid-renders.grid-container .btn-toolbar {
        min-width: 810px;
    } */
</style>
