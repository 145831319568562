<!--
    Author: Eduard Grebenyukov
    Date: Date: 2021-05-23
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldSelect
                        :label="$t('object.core_org')"
                        :value.sync="filterData.core_org_id"
                        source='v_org?filter={"where":[{"org_type_code":"medical"}]}'
                        :saveState="`${$options.name}.core_org_id`"
                        showEmptyRow
                        :emptyRowLabel="$t('app.allOrgs')"
                        @modified="filterModified('core_org_id')"
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldSelect
                        :label="$t('object.core_building')"
                        :value.sync="filterData.core_building_id"
                        source="core_building"
                        :saveState="`${$options.name}.core_building_id`"
                        showEmptyRow
                        :emptyRowLabel="$t('app.allBuildings')"
                        @modified="filterModified('core_building_id')"
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldSelect
                        :label="$t('object.core_dept')"
                        :value.sync="filterData.core_dept_id"
                        source="core_dept"
                        :saveState="`${$options.name}.core_dept_id`"
                        showEmptyRow
                        :emptyRowLabel="$t('app.allDepts')"
                        @modified="filterModified('core_dept_id')"
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldSelect
                        :label="$t('object.mat_store')"
                        :value.sync="filterData.mat_store_id"
                        source="mat_store"
                        :saveState="`${$options.name}.mat_store_id`"
                        showEmptyRow
                        :emptyRowLabel="$t('app.allStores')"
                        @modified="filterModified('mat_store_id')"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <Grid ref="grid" :key="$route.fullPath" :config="gridConfig" />
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    import FieldSelect from '@/components/lib/controls/FieldSelect';

    export default {
        name: 'MatBalance',

        components: {
            TemplatePageStandard, Grid,
            FieldSelect,
        },

        // =============== Data ===============
        data() {
            return {
                isHideMissed: true,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.store') },
                    { text: this.$t('MainMenu.store-balance') },
                ],
                filterData: {
                    core_org_id: null,
                    core_building_id: null,
                    core_dept_id: null,
                    mat_store_id: null,
                    row_qty: null,
                },
                reportInProgress: false,
                gridConfig: {
                    apiUrl: 'mat-balance',
                    filter: null,
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right' },
                        { type:'string', key:'org_name_short', header:this.$t('object.core_org') },
                        { type:'string', key:'building_name_short', header:this.$t('object.core_building') },
                        { type:'string', key:'dept_name_short', header:this.$t('object.core_dept') },
                        { type:'string', key:'store_name', header:this.$t('object.mat_store') },
                        { type:'string', key:'item_name', header:this.$t('object.mat_item') },
                        { type:'string', key:'serial_num', header:this.$t('component.MatBalance.serial_num') },
                        { type:'date', key:'expiration_date', header:this.$t('component.MatBalance.expiration_date') },
                        { type:'number', key:'unit_qty', header:this.$t('attribute.common.qty'), align:'right' },
                        { type:'string', key:'pack_name', header:this.$t('object.mat_pack') },
                        { type:'number', key:'base_qty', header:this.$t('component.MatBalance.base_qty'), align:'right' },
                        { type:'string', key:'unit_name', header:this.$t('component.MatBalance.base_unit') },
                    ],
                    order: [ 'store_name', 'item_name' ],
                    buttons: [ ],
                    saveSessionState: `${this.$options.name}`,
                },
            }
        },

        // =============== Methods ===============
        methods: {
            async setGridConfig(filterName) {
                // console.log(`DEBUG: ${this.$options.name}.setGridConfig('${filterName}')`);
                this.reportInProgress = true;
                // this.gridConfig.paginationPageSize = this.filterData.row_qty;
                let where = [];
                if (this.filterData.core_org_id) where.push({ core_org_id:this.filterData.core_org_id });
                if (this.filterData.core_building_id) where.push({ core_building_id:this.filterData.core_building_id });
                if (this.filterData.core_dept_id) where.push({ core_dept_id:this.filterData.core_dept_id });
                if (this.filterData.mat_store_id) where.push({ mat_store_id:this.filterData.mat_store_id });
                if (where.length > 0) {
                    this.gridConfig.filter = { where };
                } else {
                    this.gridConfig.filter = null;
                }
                // if (!this.gridConfig.apiUrl) {
                //     this.gridConfig.apiUrl = 'mat-balance';
                // } else {
                //     await this.$refs.grid.refresh();
                // }
                await this.$refs.grid.refresh();
                this.reportInProgress = false;
            },

            async filterModified(filterName) { await this.setGridConfig(filterName) },

            // saveReport() {
            //     this.gridConfig.downloadFileName = (
            //         this.breadcrumb[this.breadcrumb.length - 1].text + '_' +
            //         moment(this.filterData.dateFrom).format('YYYY-MM-DD') + '_' +
            //         moment(this.filterData.dateTo).format('YYYY-MM-DD')
            //     );
            //     this.$refs.report.download();
            // },
        },

        // =============== Life cycle ===============
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted()`);
            // this.setGridConfig();
        },
    }
</script>

<style>
</style>
