<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-14
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()"/>
            <ButtonSave v-if="buttons.includes('save')" :disabled="!isSaveEnabled" @click="saveData()" />
            <ButtonPrint v-if="buttons.includes('print')" @click="printBill()">
                <i class="fas fa-print"></i> {{ $t('component.RenderedServices.printBill') }}
            </ButtonPrint>
            <ButtonCustom
                v-if="isReversalGranted && renderQty"
                buttonClass="btn-outline-secondary btn-sm"
                iconClass="fas fa-history"
                :text="$t('component.RenderedServices.doReversal')"
                @click="doReversal()"
            />
            <!-- <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" /> -->
        </template>
        <VisitDescription />

        <div class="rendered-services__navbar-container">

            <VisitTabs :currentTab="isDental ? 'visit-services-dental' : 'visit-services'" />

            <div class="navbar">
                <ButtonSwitch
                    :value.sync="isDisplayRightSidebar"
                    :defaultValue="true"
                    :saveState="`${$options.name}.display_sidebar`"
                    :title="$t('app.hideShow')"
                />
            </div>
        </div>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div :class="isDisplayRightSidebar ? 'rendered-services__renders col-sm-12 col-lg-8 col-xl-8' : 'rendered-services__renders col'">
                        <div class="row">
                            <div class="col">
                                <Grid
                                    ref="renders"
                                    :key="$route.fullPath"
                                    :config="gridConfig"
                                    class="grid-renders"
                                    @edit-item="editRender($event)"
                                    @refreshed="rendersRefreshed()"
                                    @item-modified="debounceModify($event)"
                                    @item-selected="renderSelected($event)"
                                    @delete-items="deleteRenders($event)"
                                >
                                    <template slot="buttons">
                                        <button v-if="renderSelectedQty > 0" class="btn btn-sm btn-outline-secondary" @click.prevent="shiftToProgram()">
                                            {{ $t('component.RenderedServices.shiftToProgram') }}
                                        </button>
                                        <button v-if="renderSelectedQty > 0" class="btn btn-sm btn-outline-secondary" @click.prevent="shiftToClient()">
                                            {{ $t('component.RenderedServices.shiftToClient') }}
                                        </button>
                                        <button v-if="renderSelectedQty > 0" class="btn btn-sm btn-warning" @click.prevent="isDisplayDiscountList = true">
                                            {{ $t('component.RenderedServices.addDiscount') }}
                                        </button>
                                        <button v-if="renderSelectedQty > 0" class="btn btn-sm btn-outline-danger" @click.prevent="deleteDiscount()">
                                            {{ $t('component.RenderedServices.deleteDiscount') }}
                                        </button>
                                        <button
                                            v-if="referralsWithoutRender.length > 0 && renderSelectedQty <= 0"
                                            class="btn btn-sm btn-warning"
                                            @click.prevent="isDisplayReferralList = true"
                                        >
                                            {{ $t('component.RenderedServices.createByReferrals') }}
                                        </button>
                                    </template>
                                </Grid>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <FieldText
                                    v-if="editFormData.id !== null"
                                    :label="$t('attribute.common.commentary')"
                                    :value.sync="editFormData.commentary"
                                    @modified="itemCommentaryModified()"
                                    @changed="itemCommentaryChanged()"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="rendered-services__right-sidebar col-sm-12 col-lg-4 col-xl-4" :style="isDisplayRightSidebar ? '' : 'display:none;'">
                        <!-- Right sidebar -->
                        <VisitServiceTemplate
                            :visitTypeId="lastVisit.visit_type_id"
                            :specialityId="lastVisit.core_speciality_id"
                            :visitTime="new Date(lastVisit.time_of)"
                            :formItemName="currentFormItem"
                            :attributeName="currentAttribute"
                            @selected="serviceSelected($event.id)"
                        />
                    </div>
                </div>
            </div>
        </form>

        <ModalWindow
            v-if="isDisplayDiscountList"
            :header="$t('Fin.discount_bid')"
            width="600px" max-width="600px" max-height="90vh"
            @cancel="isDisplayDiscountList = false"
        >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="discountList" :config="discountGridConfig" @item-selected="discountSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>

        <ModalWindow
            v-if="isDisplayDiscountInput"
            width="600px" max-width="600px" max-height="90vh"
            :header="$t('component.RenderedServices.enterDiscountPct')"
            :buttons="[ 'ok', 'cancel' ]"
            @ok="variableDiscountPct ? variableDiscountEntered() : null"
            @cancel="isDisplayDiscountInput = false"
        >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <FieldNumber
                            :label="$t('component.RenderedServices.discount')"
                            labelPosition="before"
                            :minValue="0"
                            :maxValue="100"
                            :value.sync="variableDiscountPct"
                            required
                        />
                    </div>
                </div>
            </div>
        </ModalWindow>

        <ModalWindow
            v-if="isDisplayProgramList"
            width="600px" max-width="600px" max-height="90vh"
            :header="$t('objects.fin_program')"
            @cancel="isDisplayProgramList = false"
        >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="programList" :source="programArray" :config="programGridConfig" @item-selected="programSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>

        <ModalWindow
            v-if="isDisplayReferralList"
            width="100%" max-width="1920px" max-height="90vh"
            :header="$t('component.RenderedServices.referrals')"
            :buttons="[ 'ok', 'cancel' ]"
            @ok="referralsSelected()"
            @cancel="isDisplayReferralList = false"
        >
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <Grid ref="referralsList" :source="referralsWithoutRender" :config="referralGridConfig" />
                    </div>
                </div>
            </div>
        </ModalWindow>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';

    import ButtonSwitch from '@/components/lib/controls/ButtonSwitch';
    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonPrint from '@/components/lib/controls/ButtonPrint';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import Grid from '@/components/lib/regions/Grid';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';
    import VisitServiceTemplate from '@/components/pages/visits/VisitServiceTemplate';
    import FieldText from '@/components/lib/controls/FieldText';
    import FieldNumber from '@/components/lib/controls/FieldNumber';

    export default {
        name: 'RenderedServices',

        components: {
            TemplatePageStandard, ModalWindow,
            ButtonSwitch, ButtonClose, ButtonSave, ButtonPrint, ButtonCustom,
            VisitDescription, VisitTabs, Grid, VisitServiceTemplate,
            FieldText, FieldNumber,
        },

        // =============== Props ===============
        props: {
            isDental: { type:Boolean, defalult: false },
        },

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                serviceId: null,
                renderQty: 0,
                renderSelectedQty: 0,
                buttons: [],
                msgItemModified: '',
                isDisplayRightSidebar: null,
                emptyFormData: {
                    id: null,
                    core_workplace_id: null,
                    core_client_id: null,
                    visit_rec_id: null,
                    time_of: null,
                    bill_num: null,
                    commentary: null,
                },
                editFormData: {},
                oldFormData: {},
                currentFormItem: null,
                currentAttribute: null,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.services') }),
                debounceTimeout: 600,
                debounce: null,
                gridConfig: {
                    apiUrl: null, // v_render
                    readonly: true,
                    columns: [
                        {
                            type: 'icon',
                            style: 'padding-top: 6px;',
                            $title: (entry) => (entry.is_reversed ? this.$t('component.RenderedServices.is_reversed') : ''),
                            $icon: (entry) => (entry.is_reversed ? 'fas fa-history' : ''),
                            $hidden: (data) => (data.some(entry => (entry.is_reversed))),
                        },
                        // { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'50px' }, // first hidden col need to hide Grid.total title
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'64px' },
                        // { type:'string', key:'service_code', hidden:true, readonly:true }, // to search working
                        { type:'string', key:'service_name', hidden:true, readonly:true }, // to search working
                        { type:'string', key:'service_code', header:this.$t('object.fin_service'), $value: entry => `${entry.service_code} / ${entry.service_name}` },
                        // { type:'number', key:'price', header:this.$t('attribute.common.price'), align:'right', width:'64px' },
                        { type:'number', key:'qty', header:this.$t('attribute.common.qty'), $readonly: entry => (entry.sum_paid > 0), sortable:false, align:'right', width:'64px' },
                        { type:'number', key:'sum_discount_final', header:this.$t('attribute.common.sum_discount'), sortable:false, align:'right', width:'64px' },
                        {
                            type:'number', key:'sum_payment_final', header:this.$t('attribute.common.sum'), sortable:false, align:'right', width:'64px',
                            $cellStyle: entry => (
                                entry.sum_payment_final === 0 ? 'background-color:#ffdddd;' :
                                entry.is_price_variable ? 'color:#007bff;' :
                                ''
                            ),
                            $cellTitle: entry => (
                                entry.is_price_variable ? this.$t('attribute.fin_service.is_price_variable') : ''
                            ),
                            $title: entry => (
                                entry.is_price_variable ? this.$t('attribute.fin_service.is_price_variable') : ''
                            ),
                        },
                        { type:'number', key:'sum_program', header:this.$t('attribute.common.sum_program'), sortable:false, align:'right', width:'64px' },
                        { type:'number', key:'sum_patient', header:this.$t('attribute.common.sum_patient'), sortable:false, align:'right', width:'64px' },
                        // {
                        //     type:'icon', key:'is_paid', header:this.$t('attribute.common.is_paid'), width:'32px', style:'color:green;', align:'center',
                        //     $icon: (entry) => entry.is_paid ? 'fas fa-check-circle' : ''
                        // },
                        {
                            type:'number', key:'sum_paid', header:this.$t('attribute.common.sum_paid'), sortable:false, align:'right', width:'64px',
                            $cellStyle: (entry) => (entry.is_paid ? 'background-color:#aaffaa44;' : 'background-color:#ffffaa44;'),
                        },
                        { type:'string', key:'program_name', header:this.$t('object.fin_program'), sortable:false, readonly:true },
                        { type:'string', key:'teeth', header:this.$t('objects.med_tooth'), readonly:true, hidden:!this.isDental, sortable:false },
                    ],
                    totals: {
                        columns: [
                            { type:'string', key:'service_code', value:this.$t('app.total'), style:'font-weight:bold; font-size:1.1rem;' },
                            { type:'number', key:'sum_discount_final', $value: data => tools.getArrayFieldSum(data, 'sum_discount_final'), style:'font-weight:bold; font-size:1.1rem;' },
                            { type:'number', key:'sum_payment_final', $value: data => tools.getArrayFieldSum(data, 'sum_payment_final'), style:'font-weight:bold; font-size:1.1rem;' },
                            { type:'number', key:'sum_program', $value: data => tools.getArrayFieldSum(data, 'sum_program'), style:'font-weight:bold; font-size:1.1rem; color:#6f6f6f;' },
                            { type:'number', key:'sum_patient', $value: data => tools.getArrayFieldSum(data, 'sum_patient'), style:'font-weight:bold; font-size:1.1rem; color:#6f6f6f;' },
                            {
                                type: 'number',
                                key: 'sum_paid',
                                $value: data => tools.getArrayFieldSum(data, 'sum_paid'),
                                style: 'font-weight:bold; font-size:1.1rem; color:#6f6f6f;',
                                $cellStyle: data => (data.every( item => item.is_paid) ? 'background-color:#aaffaa44;' : 'background-color:#ffffaa44;'),
                            },
                        ],
                    },
                    buttons: [ 'row-selector', 'edit', 'delete' ],
                    order: [ 'id' ],
                    saveSessionState: `${this.$options.name}`,
                },
                isDisplayDiscountList: false,
                discountGridConfig: {
                    apiUrl: 'fin_discount',
                    // title: this.$t('object.fin_discount'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        { type:'number', key:'pct_value', header:this.$t('attribute.common.pct_value'), align:'right', width:'100px' },
                    ],
                    order: [ 'pct_value' ],
                    row: { emit: "item-selected", style: "cursor: pointer;" },
                    displayToolbar: false,
                    displayPagination: false,
                },
                selectedDiscount: null,
                isDisplayDiscountInput: false,
                variableDiscountPct: null,
                isDisplayProgramList: false,
                programSelectorMode: null,
                programArray: [],
                programGridConfig: {
                    // title: this.$t('object.fin_program'),
                    readonly: true,
                    columns: [
                        // { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' },
                        { type:'string', key:'program_name', header:this.$t('object.fin_program') },
                        { type:'string', key:'contract_name', header:this.$t('object.fin_contract') },
                        { type:'number', key:'price', header:this.$t('attribute.common.price'), align:'right', width:'100px' },
                    ],
                    order: [ 'price' ],
                    row: { emit: "item-selected", style: "cursor: pointer;" },
                    displayToolbar: false,
                    displayPagination: false,
                },
                // answeredReferralsWithoutRender: [],
                isDisplayReferralList: false,
                referralsWithoutRender: [],
                referralGridConfig: {
                    apiUrl: null,
                    // filter: null,
                    // title: this.$t('component.VisitEdit.referrals'),
                    // readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', headStyle:'max-width:100px;' },
                        { type:'string', key:'referral_code', header:this.$t('attribute.common.code'), readonly: true },
                        { type:'string', key:'referral_name', header:this.$t('attribute.common.name'), readonly: true },
                        { type:'datetime', key:'visit_datetime_of', header: this.$t('attribute.common.datetime_of'), readonly: true },
                        { type:'date', key:'date_from', header: this.$t('attribute.common.date_from'), readonly: true },
                        { type:'date', key:'date_to', header: this.$t('attribute.common.date_to'), readonly: true },
                        { type:'string', key:'user_shortname', header:this.$t('object.core_user'), readonly: true },
                        { type:'string', key:'speciality_name', header:this.$t('object.core_speciality'), readonly: true },
                        { type:'number', key:'assigned_qty', header:this.$t('component.VisitEdit.assigned_qty'), readonly: true, sortable:false },
                        { type:'number', key:'rendered_qty', header:this.$t('component.VisitEdit.rendered_qty'), readonly: true, sortable:false },
                        { type:'number', key:'left_qty', header:this.$t('component.VisitEdit.left_qty'), readonly: true, sortable:false },
                        // { type:'number', key:'left_qty', header:this.$t('attribute.common.qty'), readonly: true, sortable:false },
                        // { type:'checkbox', key:'is_cito', header:this.$t('attribute.med_referral.is_cito'), readonly: true, sortable:false },
                        {
                            type: 'icon',
                            key: 'is_cito',
                            sortable: false,
                            style: 'color:red;',
                            header: this.$t('attribute.med_referral.is_cito'),
                            title: this.$t('attribute.med_referral.is_cito'),
                            $icon: (entry) => (entry.is_cito ? 'fas fa-exclamation-circle' : ''),
                        },
                    ],
                    row: {
                        $backgroundColor: (referral) => {
                            if (referral.is_visit_reply) {
                                return '#c9fcdc';
                            }
                            return '';
                        },
                    },
                    buttons: [ 'row-selector' ],
                    // order: [{ key:'time_of', direction:'desc' }],
                    order: [ 'referral_code' ],
                    displayToolbar: true,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                lastClient: 'lastClient',
                lastVisit: 'lastVisit'
                // currencyAccounting: 'currencyAccounting',
            }),
            isSaveEnabled() { return JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData) },
            isReversalGranted() {
                return tools.isPrivilegeGranted(this.currentUser.permissions, 'reversal');
            },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                // console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.$refs.renders.qty=`, this.$refs.renders.getTotalRowsQty());
                if (this.renderQty) {
                    this.buttons = [ 'print', 'reversal' ];
                    
                } else {
                    this.buttons = [];
                }
            },

            async programSelected(selectedProgram) {
                // console.log(`DEBUG: ${this.$options.name}.programSelected(${this.programSelectorMode}), selectedProgram=`, selectedProgram);
                try {
                    if (this.programSelectorMode === 'add-service') {
                        await this.addRender(this.serviceId, selectedProgram.fin_program_id, selectedProgram.fin_beneficiary_id);
                        this.serviceId = null;
                    }
                    if (this.programSelectorMode === 'change-service') {
                        const renders = this.$refs.renders.getSelectedRows();
                        // console.log(`DEBUG: ${this.$options.name}.programSelected(${this.programSelectorMode}), renders=`, renders);
                        for (const render of renders) {
                            if (!render.sum_paid) {
                                await this.updateRender(this.composeRender(render, selectedProgram.fin_program_id, selectedProgram.fin_beneficiary_id));
                            }
                        }
                        tools.displaySuccessSave(this);
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                this.isDisplayProgramList = false;
                this.programSelectorMode = null;
                await this.$refs.renders.refresh();
                this.checkButtons();
            },

            async serviceSelected(serviceId) {
                this.serviceId = parseInt(serviceId);
                // console.log(`DEBUG: ${this.$options.name}.serviceSelected(${serviceId}) renders=`, this.$refs.renders.getAllRows());
                // check if this service was before
                const existingRender = this.$refs.renders.getAllRows().find(item => item.fin_service_id === this.serviceId);
                // console.log(`DEBUG: ${this.$options.name}.serviceSelected(${serviceId}) existingRender=`, existingRender);
                if (existingRender) {
                    existingRender.qty++;
                    await this.renderModified(existingRender);
                } else {
                    const filterString = tools.getFilterString({ where:[
                        { visit_rec_id: this.visitId },
                        { fin_service_id: this.serviceId },
                    ]});
                    try {
                        this.programArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-visit-service-programs${filterString}`)).data.rows;
                        // console.log(`DEBUG: ${this.$options.name}.serviceSelected(${serviceId}), programs this.programArray=`, this.programArray);
                        if (this.programArray.length === 0) await this.addRender(this.serviceId, null, null);
                        if (this.programArray.length === 1) await this.addRender(this.serviceId, this.programArray[0].fin_program_id, this.programArray[0].fin_beneficiary_id);
                        if (this.programArray.length > 1) {
                            this.programGridConfig.columns.find(col => col.key === 'price').hidden = false;
                            this.isDisplayProgramList = true;
                            this.programSelectorMode = 'add-service';
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
            },

            async getReferrals() {
                this.referralsWithoutRender = (
                    (await this.authenticatedAxios.get(`${this.baseUrl}/referrals-for-visit/${this.visitId}`))
                    .data
                    .rows
                    // .filter(item => item.is_visit_reply && !item.reply_render_id)
                    .filter(item => !item.reply_render_id)
                );
                // console.log(`DEBUG: ${this.$options.name}.getReferrals(), this.referralsWithoutRender=`, this.referralsWithoutRender);
            },

            async getData() {
                const filterString = tools.getFilterString({ where:[{ visit_rec_id:this.visitId }]});
                try {
                    const data = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_bill${filterString}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.getData(), programs data=`, data);
                    if (data && data.length > 0) {
                        this.editFormData = data[0];
                        this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                        this.isModified = false;
                    } else {
                        this.editFormData = JSON.parse(JSON.stringify(this.emptyFormData));
                    }
                    await this.getReferrals();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    try {
                        if (this.editFormData.id != null) {
                            // Update bill
                            await this.authenticatedAxios.put(`${this.baseUrl}/fin_bill/${this.editFormData.id}`, this.editFormData);
                            await this.getData();
                            tools.displaySuccessSave(this);
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.$refs.addresses=`, this.$refs.addresses);
            },

            async addRender(serviceId, programId, beneficiaryId) {
                // console.log(`DEBUG: ${this.$options.name}.addRender(), this.currentUser=`, this.currentUser);
                const render = {
                    core_user_id: this.currentUser.id,
                    core_client_id: this.clientId,
                    fin_service_id: serviceId,
                    visit_rec_id: this.visitId,
                    fin_beneficiary_id: beneficiaryId,
                    fin_program_id: programId,
                    date_of: null,
                    price: null,
                    qty: 1,
                    med_reply_id: null,
                };
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-create-render`, render);
                    await this.$refs.renders.refresh();
                    this.getData();
                    this.checkButtons();
                    tools.displaySuccessSave(this);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async createByReferrals() {
                // console.log(`DEBUG: ${this.$options.name}.createByReferrals(), this.referralsWithoutRender=`, this.referralsWithoutRender);
                for (const referral of this.referralsWithoutRender) {
                    this.serviceId = referral.fin_service_id;
                    const filterString = tools.getFilterString({ where:[{ visit_rec_id: this.visitId }, { fin_service_id: this.serviceId }]});
                    try {
                        this.programArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-visit-service-programs${filterString}`)).data.rows;
                        // console.log(`DEBUG: ${this.$options.name}.serviceSelected(${serviceId}), programs this.programArray=`, this.programArray);
                        if (this.programArray.length === 0) await this.addRender(this.serviceId, null, null);
                        if (this.programArray.length >= 1) await this.addRender(this.serviceId, this.programArray[0].fin_program_id, this.programArray[0].fin_beneficiary_id);
                        // !!! can not work with dialogue in loop !!!
                        // if (this.programArray.length > 1) {
                        //     this.programGridConfig.columns.find(col => col.key === 'price').hidden = false;
                        //     this.isDisplayProgramList = true;
                        //     this.programSelectorMode = 'add-service';
                        // }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
            },

            async referralsSelected() {
                const selectedReferrals = tools.deepCopy(this.$refs.referralsList.getSelectedRows());
                // console.log(`DEBUG: ${this.$options.name}.referralsSelected(), selectedReferrals=`, selectedReferrals);
                this.isDisplayReferralList = false;

                for (const referral of selectedReferrals) {
                    if (!referral.is_visit_reply) {
                        // await this.authenticatedAxios.post(`${this.baseUrl}/referrals-for-visit/${this.visitId}`, referral);
                        await this.authenticatedAxios.post(`${this.baseUrl}/med_reply`, {
                            visit_rec_id: this.visitId,
                            med_referral_id: referral.med_referral_id,
                            qty: 1,
                        });
                    }
                    // this.serviceId = referral.fin_service_id;
                    const filterString = tools.getFilterString({ where:[{ visit_rec_id: this.visitId }, { fin_service_id: referral.fin_service_id }]});
                    try {
                        this.programArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-visit-service-programs${filterString}`)).data.rows;
                        // console.log(`DEBUG: ${this.$options.name}.serviceSelected(${serviceId}), programs this.programArray=`, this.programArray);
                        if (this.programArray.length === 0) await this.addRender(referral.fin_service_id, null, null);
                        if (this.programArray.length >= 1) await this.addRender(referral.fin_service_id, this.programArray[0].fin_program_id, this.programArray[0].fin_beneficiary_id);
                        // !!! can not work with dialogue in loop !!!
                        // if (this.programArray.length > 1) {
                        //     this.programGridConfig.columns.find(col => col.key === 'price').hidden = false;
                        //     this.isDisplayProgramList = true;
                        //     this.programSelectorMode = 'add-service';
                        // }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
            },

            composeRender(render, programId, beneficiaryId) {
                return {
                    core_user_id: this.currentUser.id,
                    fin_render_id: render.id,
                    fin_service_id: render.fin_service_id,
                    visit_rec_id: this.visitId,
                    fin_beneficiary_id: beneficiaryId,
                    fin_program_id: programId,
                    date_of: null,
                    price: render.price,
                    qty: render.qty,
                    is_forced: true,
                };
            },

            async updateRender(renderData) {
                // errors handled at callers because their different
                await this.authenticatedAxios.post(`${this.baseUrl}/fin-update-render`, renderData);
            },

            async shiftToProgram() {
                const renders = this.$refs.renders.getSelectedRows();
                try {
                    /*
                        !!! replace to fin-client-programs !!!
                    */
                    this.programArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-visit-programs/${this.visitId}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.shiftToProgram(), this.programArray=`, this.programArray);
                    // if (this.programArray.length === 0) return; // do nothing
                    if (this.programArray.length === 0) {
                        tools.displayWarning(this, this.$t('component.RenderedServices.noProgramsToShift'));
                    }
                    if (this.programArray.length === 1) {
                        for (const render of renders) {
                            if (!render.sum_paid) {
                                await this.updateRender(this.composeRender(render, this.programArray[0].fin_program_id, this.programArray[0].fin_beneficiary_id));
                            }
                        }
                        tools.displaySuccessSave(this);
                    }
                    if (this.programArray.length > 1) {
                        this.programGridConfig.columns.find(col => col.key === 'price').hidden = true;
                        this.isDisplayProgramList = true;
                        this.programSelectorMode = 'change-service';
                        return; // do not refresh renders, else grid checkbox state be lost
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                await this.$refs.renders.refresh();
                this.checkButtons();
            },

            async shiftToClient() {
                const renders = this.$refs.renders.getSelectedRows();
                // console.log(`DEBUG: ${this.$options.name}.shiftToClient(), renders=`, renders);
                try {
                    for (const render of renders) {
                        if (!render.sum_paid) {
                            await this.updateRender(this.composeRender(render, null, null));
                        }
                    }
                    tools.displaySuccessSave(this);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                await this.$refs.renders.refresh();
                this.checkButtons();
            },

            async processCreateDiscount() {
                    const renders = this.$refs.renders.getSelectedRows();
                    // console.log(`DEBUG: ${this.$options.name}.processCreateDiscount() this.selectedDiscount=`, this.selectedDiscount);
                    try {
                        for (const render of renders) {
                            if (!render.sum_paid && !render.sum_discount) {
                                const data = {
                                    fin_render_id: render.id,
                                    fin_discount_id: this.selectedDiscount.id,
                                    pct_value: this.selectedDiscount.pct_value === null ? -this.variableDiscountPct : null,
                                };
                                // await this.processCreateDiscount(data);
                                await this.authenticatedAxios.post(`${this.baseUrl}/fin-create-render-discount`, data);
                            }
                        }
                        tools.displaySuccessSave(this);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                    await this.$refs.renders.refresh();
                    this.checkButtons();
            },

            async discountSelected(discount) {
                this.selectedDiscount = discount;
                if (this.selectedDiscount.pct_value === null) {
                    this.variableDiscountPct = null;
                    this.isDisplayDiscountInput = true;
                } else {
                    await this.processCreateDiscount();
                }
                this.isDisplayDiscountList = false;
            },

            async variableDiscountEntered() {
                await this.processCreateDiscount();
                this.isDisplayDiscountInput = false;
            },

            async deleteDiscount() {
                const renders = this.$refs.renders.getSelectedRows();
                try {
                    for (const render of renders) {
                        if (!render.sum_paid && render.sum_discount) {
                            const filterRenderDiscount = tools.getFilterString({ where:[{ fin_render_id: render.id }] });
                            const renderDiscountArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_render_discount${filterRenderDiscount}`)).data.rows;
                            const renderData = {
                                fin_render_id: render.id,
                                fin_render_discount: renderDiscountArray.map( item => item.id ),
                            };
                            await this.authenticatedAxios.post(`${this.baseUrl}/fin-delete-render-discount`, renderData);
                        }
                    }
                    tools.displaySuccessSave(this);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                await this.$refs.renders.refresh();
                // this.getData();
                this.checkButtons();
            },

            async deleteRenders(renderArray) {
                try {
                    for (const render of renderArray) {
                        if (!render.sum_paid) {
                            await this.authenticatedAxios.post(`${this.baseUrl}/fin-delete-render/${render.id}`);
                        }
                    }
                    await this.$refs.renders.refresh();
                    tools.displaySuccessSave(this);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            editRender(item) {
                // console.log(`DEBUG: ${this.$options.name}.editRender(), item=`, item);
                if (this.isDental) {
                    this.$router.push({ name:'visit-service-edit-dental', params:{ clientId:this.clientId, visitId:this.visitId, renderId:item.id }});
                } else {
                    this.$router.push({ name:'visit-service-edit', params:{ clientId:this.clientId, visitId:this.visitId, renderId:item.id }});
                }
            },

            rendersRefreshed() {
                // console.log(`DEBUG: ${this.$options.name}.rendersRefreshed(), this.$refs.renders.qty=`, this.$refs.renders.getTotalRowsQty());
                this.renderQty = this.$refs.renders.getTotalRowsQty();
                this.renderSelectedQty = this.$refs.renders.getSelectedRows().length;
                // if (this.renderQty > 0) {
                //     this.billId = this.$refs.renders.getAllRows()[0].fin_bill_id;
                //     console.log(`DEBUG: ${this.$options.name}.rendersRefreshed(), this.billId=`, this.billId);
                // }
                this.checkButtons();
                this.getReferrals();
            },

            renderSelected() {
                this.renderSelectedQty = this.$refs.renders.getSelectedRows().length;
                // console.log(`DEBUG: ${this.$options.name}.renderSelected(), this.renderSelectedQty=`, this.renderSelectedQty);
            },

            async renderModified(item) {
                // console.log(`DEBUG: ${this.$options.name}.renderModified(), item=`, item);
                try {
                    await this.updateRender(this.composeRender(item, item.fin_program_id, item.fin_beneficiary_id));
                    tools.displaySuccessSave(this);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                this.$refs.renders.refresh();
            },

            debounceModify(item) {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.renderModified(item);
                }, this.debounceTimeout);
            },

            async itemCommentaryModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemCommentaryModified(), this.oldFormData=`, this.oldFormData);
                // console.log(`DEBUG: ${this.$options.name}.itemCommentaryModified(), this.editFormData=`, this.editFormData);
                if (this.isSaveEnabled) {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.isModified = false;
                    this.msgItemModified = '';
                }
            },

            async itemCommentaryChanged() {
                await this.saveData();
            },

            async printBill() {
                try {
                    // console.log(`DEBUG: ${this.$options.name}.printBill()`);
                    const renders = this.$refs.renders.getAllRows();
                    const sumTotal = renders.reduce(((sum, item) => sum + item.sum_payment_final), 0);
                    const currencyCode = (await tools.getSetting(this, 'currency_accounting')).value;
                    // console.log(`DEBUG: ${this.$options.name}.printBill() currencyCode=`, currencyCode);
                    const filterStringCurrency = tools.getFilterString({ where:[{ code:currencyCode }]});
                    const currency = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_currency${filterStringCurrency}`)).data.rows[0];
                    const filterStringBill = tools.getFilterString({ where:[{ visit_rec_id:this.visitId }]});
                    const bill = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_bill${filterStringBill}`)).data.rows[0];

                    // const currency = this.currencyAccounting;
                    // console.log(`DEBUG: ${this.$options.name}.printBill() currency=`, currency);
                    const obj = {
                        // taskId: `print.${this.$options.name}`,
                        template: 'bill',
                        bill: {
                            bill_num: bill.bill_num,
                            bill_date: moment(bill.time_of).format('L'),
                            total_sum: sumTotal,
                            total_sum_company: 0,
                            total_sum_payer: 0,
                            total_sum_patient: sumTotal,
                            visit_rec_id: this.visitId,
                            currency: currency.name_short,
                            // services: JSON.parse(JSON.stringify(renders)),
                            services: renders
                        },
                        visit: { visit_rec_id: this.visitId },
                    };
                    // console.log(`DEBUG: ${this.$options.name}.printBill() obj=`, obj, ', typeof obj.bill.sumTotal=', typeof obj.bill.sumTotal);
                    tools.printForm(this, obj);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async doReversal() {
                // console.log(`DEBUG: ${this.$options.name}.doReversal() this.id=`, this.editFormData.id);
                // this.$router.push({ name:'finances/reversal-new', params:{ billId:this.editFormData.id }});
                this.$router.push({ name:'finances/reversal-new', query:{ billId:this.editFormData.id }});
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this=`, this);
            this.editFormData = JSON.parse(JSON.stringify(this.emptyFormData));
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            // this.gridConfig.apiUrl = `visit/${this.visitId}/services`;
            this.gridConfig.apiUrl = `v_render`;
            this.gridConfig.filter = { where:[{ visit_rec_id:this.visitId }]};
            await this.getData();
        },

        async mounted() {
            this.checkButtons();
        },
    }
</script>

<style>
    .rendered-services__navbar-container {
        display: flex;
    }
    .rendered-services__renders {
        overflow-x: auto;
    }
    /* .rendered-services__right-sidebar {
    } */
    .rendered-services__renders .grid-renders.grid-container .btn-toolbar {
        min-width: 810px;
    }
</style>
