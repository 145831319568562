<!--
    Author: Eduard Grebenyukov
    Date: Date: 2020-10-17
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <template #buttons>
            <ButtonClose @click="closePressed()" />
            <ButtonCustom buttonClass="btn-primary btn-sm ml-1" iconClass="fas fa-compress-alt" :text="$t('app.merge')" :disabled="!isMergeEnabled" @click="doMerge()" />
        </template>
        <div id="page-content">
            <Grid ref="grid" :key="$route.fullPath" :config="gridConfig" :source="fieldRows" />
        </div>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'MergeClients',

        components: { TemplatePageStandard, ButtonClose, ButtonCustom, Grid },
        // props: ['config'],

        // =============== Data ===============
        data() {
            return {
                values1: {},
                values2: {},
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.clients'), to: '/clients' },
                    { text: this.$t('MainMenu.clients-to-merge'), to: '/clients-to-merge' },
                    { text: this.$t('MainMenu.clients-merge'), to: '/clients-merge' },
                ],
                fieldRows: [
                    // { key:'id', name:this.$t('attribute.common.id') },
                    { key:'code', name:this.$t('attribute.common.code') },
                    // { key:'client_person_id', name:this.$t('attribute.') },
                    { key:'lastname', name:this.$t('attribute.core_person.lastname') },
                    { key:'firstname', name:this.$t('attribute.core_person.firstname') },
                    { key:'patronymic', name:this.$t('attribute.core_person.patronymic') },
                    { key:'sex', name:this.$t('attribute.core_person.sex') },
                    { key:'dob', name:this.$t('attribute.core_person.dob') },
                    { key:'social_number', name:this.$t('attribute.core_person.social_number') },
                    { key:'upn', name:this.$t('attribute.core_person.upn') },
                    { key:'email', name:this.$t('attribute.core_person.email') },
                    { key:'document_type_id', name:this.$t('attribute.core_person.document_type_id') },
                    { key:'document_serial', name:this.$t('attribute.core_person.document_serial') },
                    { key:'document_num', name:this.$t('attribute.core_person.document_num') },
                    { key:'document_date', name:this.$t('attribute.core_person.document_date') },
                    { key:'document_issuer', name:this.$t('attribute.core_person.document_issuer') },
                    { key:'job_place', name:this.$t('attribute.core_person.job_place') },
                    { key:'job_position', name:this.$t('attribute.core_person.job_position') },
                    { key:'pob', name:this.$t('attribute.core_person.pob') },
                    { key:'commentary', name:this.$t('attribute.common.commentary') },
                ],
                gridConfig: {
                    apiUrl: null,
                    // source: null,
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'sort_order' },
                        // { type:'string', key:'key', header:this.$t('attribute.common.code') },
                        {
                            type:'string', key:'name', header:this.$t('component.MergeClients.field'), sortable:false,
                            style:"font-weight:bold;", cellStyle:"text-align:right;", headStyle:"text-align:right;",
                        },
                        {
                            type:'string', key:'value1',
                            header:this.$t('component.MergeClients.value1'),
                            title:this.$t('component.MergeClients.choose'),
                            sortable:false,
                            style:'color: #056bbf;',
                            $cellStyle: (entry) => entry.value1 === entry.value2 ? 'background-color:#eeffee;' : 'background-color:#ffeeee;',
                            $textOnClick: (entry) => { this.chooseValue(entry, entry.value1) },
                        },
                        {
                            type:'string', key:'value2',
                            header:this.$t('component.MergeClients.value2'),
                            title:this.$t('component.MergeClients.choose'),
                            sortable:false,
                            style:'color: #056bbf;',
                            $cellStyle: (entry) => entry.value1 === entry.value2 ? 'background-color:#eeffee;' : 'background-color:#ffeeee;',
                            $textOnClick: (entry) => { this.chooseValue(entry, entry.value2) },
                        },
                        {
                            type:'string', key:'value',
                            header:this.$t('component.MergeClients.choosenValue'),
                            sortable:false,
                            style:'color: #056bbf;',
                            $cellStyle: (entry) => {
                                if (
                                    entry.value ||
                                    (entry.value === entry.value1 && entry.value === entry.value2) ||
                                    (tools.isStringEmpty(entry.value1) && tools.isStringEmpty(entry.value2))
                                ) {
                                    return 'background-color:#eeffee;'
                                } else {
                                    return 'background-color:#ffeeee;';
                                }
                            },
                        },
                    ],
                    order: [ 'sort_order' ],
                    displayToolbar: false,
                }
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            isMergeEnabled() {
                return this.fieldRows.every( (entry) => {
                    return entry.value || (tools.isStringEmpty(entry.value1) && tools.isStringEmpty(entry.value2));
                });
            },
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['setLastClientId']),

            transposeValues(values, fieldName) {
                for (let f of this.fieldRows) {
                    f[fieldName] = values[f.key];
                }
                // console.log(`DEBUG: ${this.$options.name}.transposeValues('values', '${fieldName}'), this.fieldRows=`, this.fieldRows);
            },

            processValues() {
                this.transposeValues(this.values1, 'value1');
                this.transposeValues(this.values2, 'value2');
                for (let row of this.fieldRows) {
                    if (row.key === 'id') row.value = row.value1;
                    if (row.key === 'code') row.value = row.value1;
                    if (row.value1 === row.value2) row.value = row.value1;
                    if (!tools.isStringEmpty(row.value1) && tools.isStringEmpty(row.value2)) row.value = row.value1;
                    if (tools.isStringEmpty(row.value1) && !tools.isStringEmpty(row.value2)) row.value = row.value2;
                }
                this.fieldRows = JSON.parse(JSON.stringify(this.fieldRows));
                // this.$refs.grid.refresh();
            },

            async getData() {
                let id1 = parseInt(this.$route.params.id1);
                let id2 = parseInt(this.$route.params.id2);
                try {
                    this.values1 = (await this.authenticatedAxios.get(`${this.baseUrl}/v_client/${id1}`)).data;
                    this.values2 = (await this.authenticatedAxios.get(`${this.baseUrl}/v_client/${id2}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getData('${id1}', '${id2}'), this.values1=`, this.values1);
                    this.processValues();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
            chooseValue(entry, value) {
                // console.log(`DEBUG: ${this.$options.name}.chooseValue(), entry=`, entry);
                entry.value = value;
                this.fieldRows = JSON.parse(JSON.stringify(this.fieldRows));
            },

            async doMerge() {
                let mergeData = { keep_id:this.values1.id, remove_id:this.values2.id, values:{} };
                for (let row of this.fieldRows) {
                    mergeData.values[row.key] = row.value;
                }
                try {
                    // console.log(`DEBUG: ${this.$options.name}.doMerge(), mergeData=`, mergeData);
                    let clientId = (await this.authenticatedAxios.post(`${this.baseUrl}/merge-clients`, mergeData)).data.id;
                    // console.log(`DEBUG: ${this.$options.name}.doMerge() response.data=`, response.data);
                    tools.displaySuccessSave(this);
                    await this.setLastClientId(clientId);
                    this.$router.replace({ name: 'client-edit', params: { id:clientId } });
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
            closePressed() {
                this.$router.go(-1);
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
        },

        async mounted() {
            this.getData();
        },
    }
</script>

<style>
</style>
