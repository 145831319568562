<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-12
-->

<template>
     <TemplatePageStandard :config="{ breadcrumb }">
        <div id="page-content">
            <Grid ref="grid" :key="$route.fullPath" :config="gridConfig" @add-item="addItem" @edit-item="editItem($event.id)" >
                <template #buttons>
                    <FieldSwitch
                        id="isShowActiveOnly"
                        :label="$t('component.Users.isShowActiveOnly')"
                        labelPosition="after"
                        size="small"
                        :value.sync="isShowActiveOnly"
                        :defaultValue="true"
                        :saveSessionState="`${$options.name}-isShowActiveOnly`"
                    />
                    <!-- <div class="custom-control custom-switch" style="padding-top:6px;">
                        <input type="checkbox" class="custom-control-input" id="is_only_debt" v-model="isShowActiveOnly" >
                        <label class="custom-control-label" for="is_only_debt">{{ $t('component.Users.isShowActiveOnly') }}</label>
                    </div> -->
                </template>
            </Grid>
        </div>
    </TemplatePageStandard>
</template>

<script>
    // import { mapGetters } from 'vuex';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';

    export default {
        name: 'Users',

        components: {
            TemplatePageStandard, Grid, FieldSwitch,
        },
        props: ['config'],

        // =============== Data ===============
        data() {
            return {
                isShowActiveOnly: true,
                breadcrumb: [
                    { text:this.$t('MainMenu.home'), to:'/' },
                    { text:this.$t('MainMenu.settings') },
                    { text:this.$t('MainMenu.settings-rights-users') }
                ],
                gridConfig: {
                    apiUrl: null,
                    filter: null,
                    readonly: true,
                    columns: [
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'50px' },
                        { type:'string', key:'fullname', header:this.$t('Settings.user') },
                        { type:'string', key:'username', header:this.$t('attribute.common.username') },
                        { type:'string', key:'email', header:this.$t('Settings.email') },
                        { type:'checkbox', key:'is_curator', header:this.$t('component.UserEdit.is_curator'), width:'50px', align:'center;' },
                        // { type:'checkbox', key:'is_password', header:this.$t('attribute.common.password'), width:'50px', align:'center;' },
                        { type:'icon', key:'is_password', header:this.$t('attribute.common.password'), width:'50px', align:'center;',
                            $icon:(entry) => entry.is_password ? 'fas fa-key' : 'fas fa-ban',
                            // $cellStyle:(entry) => 'cursor:pointer; ' + (entry.is_password ? 'color:green;' : 'color:red;'),
                            $cellStyle:(entry) => entry.is_password ? 'color:green;' : 'color:red;',
                            $title:(entry) => entry.is_password ? this.$t('component.Users.passwordDefined') : this.$t('component.Users.noPasswordDefined'),
                        },
                        { type:'icon', key:'$user_status', header:this.$t('attribute.common.status'), width:'50px', align:'center;',
                            $icon:(entry) => entry.is_active ? 'fas fa-lock-open' : 'fas fa-ban',
                            $cellStyle:(entry) => entry.is_active ? 'color:green;' : 'color:red;',
                            $title:(entry) => entry.is_active ? this.$t('component.Users.active') : this.$t('component.Users.archived'),
                        },
                        // { type:'select', key:'organization_id', header:this.$t('Settings.organization'), source:'organizations' }
                    ],
                    row: { $style:(entry) => entry.is_active ? '' : 'background-color:#eee;color:#888' },
                    buttons: [ 'add', 'edit' ],
                    order: [ 'id' ],
                    displayToolbar: true,
                    saveSessionState: `${this.$options.name}`,
                },
            }
        },

        // =============== Watch ===============
        watch: {
            isShowActiveOnly() {
                this.setGridConfig();
            },
        },

        // =============== Methods ===============
        methods: {
            setGridConfig() {
                this.gridConfig.apiUrl = `v_user`;
                this.gridConfig.filter = this.isShowActiveOnly ? { where:[{ is_active: true }]} : {};
                this.$refs.grid.refresh();
            },

            addItem() {
                this.$router.push({ name: 'settings/rights/user-new', params: { id: null } });
            },
            editItem(id) {
                // console.log(`DEBUG: ${this.$options.name}.editItem(), id=${id}`);
                this.$router.push({ name: 'settings/rights/user-edit', params: { id } });
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            this.setGridConfig();
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
