<!--
    Author: Eduard Grebenyukov
    Date: Date: 2020-11-15
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <div id="page-content">
            <Grid ref="grid" :key="$route.fullPath" :config="gridConfig" >
                <template #buttons>
                    <FieldSwitch
                        id="isHideMissed"
                        :label="$t('component.CRMCalls.isHideMissed')"
                        labelPosition="after"
                        size="small"
                        :value.sync="isHideMissed"
                        :defaultValue="true"
                        :saveSessionState="`${$options.name}-isHideMissed`"
                    />
                    <!-- <div class="custom-control custom-switch" style="padding-top:6px;">
                        <input type="checkbox" class="custom-control-input" id="is_hide_missed" v-model="isHideMissed" >
                        <label class="custom-control-label" for="is_only_debt">{{ $t('component.CRMCalls.hideMissed') }}</label>
                    </div> -->
                </template>
            </Grid>
        </div>
    </TemplatePageStandard>
</template>

<script>
    import { mapActions } from 'vuex';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';

    export default {
        name: 'CRMCalls',

        components: {
            TemplatePageStandard, Grid, FieldSwitch,
        },

        // =============== Data ===============
        data() {
            return {
                isHideMissed: true,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.crm') },
                    { text: this.$t('MainMenu.crm-calls') },
                ],
                gridConfig: {
                    apiUrl: null, // v_crm_call
                    readonly: true,
                    columns: [
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right' },
                        { type:'datetime', key:'time_of', header:this.$t('app.datetime') },
                        { type:'number', key:'call_identifier', header:'Call_' + this.$t('attribute.common.id'), align:'right' },
                        { type:'string', key:'caller_number', header:this.$t('attribute.crm_call.caller_number') },
                        // { type:'html', key:'caller_number', header:this.$t('attribute.crm_call.caller_number'), $value: entry => tools.formatPhoneNumber(entry.caller_number) },
                        { type:'string', key:'called_number', header:this.$t('attribute.crm_call.called_number') },
                        // { type:'html', key:'called_number', header:this.$t('attribute.crm_call.called_number'), $value: entry => tools.formatPhoneNumber(entry.called_number) },
                        { type:'string', key:'answered_number', header:this.$t('attribute.crm_call.answered_number') },
                        { type:'number', key:'waiting_time', header:this.$t('attribute.crm_call.waiting_time'), align:'right' },
                        { type:'number', key:'duration', header:this.$t('attribute.crm_call.duration'), align:'right' },
                        { type:'select', key:'status_code', header:this.$t('attribute.common.status'), source:'enum/crm_call_status', keyId:'code' },
                        // { type:'string', key:'call_record_url', header:this.$t('attribute.crm_call.call_record_url') },
                        {
                            type:'icon', key:'crm_request', header:this.$t('object.crm_request'), style:'color: #056bbf;',
                            $icon: entry => entry.request_array.length === 0 ? 'fas fa-plus' : 'far fa-file-alt',
                            $title: entry => entry.request_array.length === 0 ? this.$t('app.create') : this.$t('app.open'),
                            $onClick: (entry, evt, ctx) => {
                                if (entry.request_array.length) {
                                    // open
                                    ctx.$router.push({ name: 'crm/request-edit', params: { id: entry.request_array[0] } }); // !!! let user select request if array size > 1
                                } else {
                                    // create new
                                    ctx.$router.push({ name: 'crm/request-new', params: { id: null, phoneNum:entry.caller_number, callId:entry.id } });
                                }
                            },
                        },
                        {
                            type:'html', key:'call_record_url', header:this.$t('attribute.crm_call.call_record_url_short'), sortable:false,
                            $value: entry => (
                                entry.call_record_url ?
                                `<a href="${entry.call_record_url}" target="_blank"><i class="fas fa-volume-up" style="color: #056bbf;"></i></a>`:
                                ``
                            ),
                            cellClass:'grid__icon text-center',
                        },
                    ],
                    order: [{ key:'time_of', direction:'desc' }],
                    saveSessionState: `${this.$options.name}`,
                },
            }
        },

        // =============== Watch ===============
        watch: {
            isHideMissed() {
                this.setGridConfig();
            },
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['setLastClientId']),

            setGridConfig() {
                this.gridConfig.apiUrl = `v_crm_call`;
                this.gridConfig.filter = this.isHideMissed ? { where:[['status_code', '<>', 'missed' ]]} : {};
                this.$refs.grid.refresh();
                // console.log(`DEBUG: ${this.$options.name}.setGridConfig(${this.isHideMissed}) this.gridConfig.filter=`, this.gridConfig.filter);
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            this.setGridConfig();
        },
    }
</script>

<style scoped>

</style>
