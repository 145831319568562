<!--
    Author: Eduard Grebenyukov
    Date: 2020-03-09
-->

<template>
    <!-- <div>
        <b-form-checkbox
            :id="id"
            v-model="status"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
        >
        I accept the terms and use
        </b-form-checkbox>
    </div> -->
    <div class="form-check form-group" :class="{ 'field-required': required, 'form-check-label-above': (label && (labelPosition === 'above')) }" >
        <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label :for="id" v-if="label && (labelPosition === 'before')" :title="required ? $t('lib.required') : ''" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>
        <!-- <div v-if="labelPosition === 'above'" class="form-check-label field__label__above" style="height:2rem;" >
            {{ label }}
        </div>

        <label v-if="labelPosition === 'before'" class="form-check-label mr-2" :for="id" >
            {{ label }}
        </label> -->

        <input
            :id="id"
            type="checkbox"
            :checked="value"
            :required="required"
            :disabled="disabled || readonly || displayonly"
            :style="`${inputStyle}`"
            @change="itemModified($event)"
        >
        <label :for="id" v-if="labelPosition == 'float'" :title="required ? $t('lib.required') : ''" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label v-if="labelPosition === 'after'" class="form-check-label ml-1" :for="id" >
            {{ label }}
        </label>

        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    export default {
        name: 'FieldCheckbox',

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: Boolean,
            placeholder: String,
            disabled: Boolean,
            readonly: Boolean,
            displayonly: Boolean,
            required: Boolean,
            inputStyle: String,
            errorMessage: String,
        },

        // =============== Data ===============
        data() {
            return {
                controlId: null, // internal control ID, can be overwritten by props.id
            }
        },

        // =============== Methods ===============
        methods: {
            itemModified(evt) {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() evt.target.checked=`, evt.target.checked);
                this.$emit('update:value', evt.target.checked);
                this.$emit('modified');
            }
        }
    }
</script>

<style>
</style>
