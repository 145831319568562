/*
    Author:Eduard Grebenyukov
    Date:2021-09-12
*/

import { i18n } from '@/locales';

const Tasks = () => import('@/components/pages/tasks/Tasks');
const TaskEdit = () => import('@/components/pages/tasks/TaskEdit');

const routes = [
    { name:'tasks',           path:'/tasks',              component:Tasks,    meta:{title:i18n.t('MainMenu.tasks')} },
    { name:'tasks/task-new',  path:'/tasks/task',         component:TaskEdit, meta:{title:i18n.t('object.task_rec')} },
    { name:'tasks/task-edit', path:'/tasks/task/:taskId', component:TaskEdit, meta:{title:i18n.t('object.task_rec')} },
]

export default routes
