<!--
    Author: Eduard Grebenyukov
    Date: 2021-09-19
-->

<template>
    <ModalWindow
        width="90%"
        max-width="1200px"
        :buttons="buttons"
        @cancel="closeModal"
        @save="saveData"
        @delete="askDeleteItem"
    >
        <span slot="header" :title="msgItemModified">{{ $t('object.med_medication') }} {{ isModified? '*' : '' }}</span>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <FieldPopupList
                        :label="$t('object.med_medicine')"
                        :value.sync="editMedicationDetFormData.med_medicine_id"
                        :listConfig="medicineListConfig"
                        source="v_medicine"
                        keyLabel="name_full"
                        required
                        readonly
                        maxWidth="1000px"
                        @modified="itemModified()"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <FieldNumber
                        :label="$t('attribute.med_medication_det.dose')"
                        :value.sync="editMedicationDetFormData.dose"
                        required
                        @modified="itemModified()"
                    />
                </div>
                <div class="col-6">
                    <FieldSelect
                        :label="$t('object.mat_unit')"
                        :value.sync="editMedicationDetFormData.mat_unit_id"
                        source="mat_unit"
                        required
                        order="name"
                        @modified="itemModified()"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 col-md-4 col-lg">
                    <FieldSwitch
                        id="is_longterm"
                        :label="$t('attribute.med_medication.is_longterm')"
                        :value.sync="editMedicationFormData.is_longterm"
                        @modified="itemModified()"
                    />
                </div>
                <div class="col-sm-6 col-md-6 col-lg">
                    <FieldDate
                        :label="$t('attribute.common.date_from')"
                        :value.sync="editMedicationFormData.date_from"
                        required
                        @modified="itemModified()"
                    />
                </div>
                <div v-if="!editMedicationFormData.is_longterm" class="col-sm-6 col-md-6 col-lg">
                    <FieldDate
                        :label="$t('attribute.common.date_to')"
                        :value.sync="editMedicationFormData.date_to"
                        required
                        @modified="itemModified()"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2 col-md-2 col-lg">
                    <FieldSwitch
                        id="is_on_necessity"
                        :label="$t('attribute.med_medication.is_on_necessity')"
                        :value.sync="editMedicationFormData.is_on_necessity"
                        @modified="itemModified()"
                    />
                </div>
                <div v-if="!editMedicationFormData.is_on_necessity" class="col-sm-3 col-md-3 col-lg">
                    <FieldNumber
                        :label="$t('attribute.med_medication.frequency')"
                        :value.sync="editMedicationFormData.frequency"
                        required
                        @modified="itemModified()"
                    />
                </div>
                <div v-if="!editMedicationFormData.is_on_necessity" class="col-sm-3 col-md-3 col-lg">
                    <FieldNumber
                        :label="$t('attribute.med_medication.repeat_every')"
                        :value.sync="editMedicationFormData.repeat_every"
                        required
                        @modified="itemModified()"
                    />
                </div>
                <div v-if="!editMedicationFormData.is_on_necessity" class="col-sm-3 col-md-3 col-lg">
                    <FieldSelect
                        :label="$t('attribute.med_medication.time_unit_id')"
                        :value.sync="editMedicationFormData.time_unit_id"
                        source="enum/time_unit"
                        order="sort_order"
                        required
                        @modified="itemModified()"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <FieldText
                        :label="$t('attribute.common.commentary')"
                        :value.sync="editMedicationFormData.commentary"
                        @modified="itemModified()"
                    />
                </div>
            </div>
            <!-- <div v-if="editMedicationDetFormData.is_canceled" class="row">
                <div class="col">
                    <FieldText :label="$t('attribute.med_referral.cancellation_reason')" :value.sync="editMedicationDetFormData.cancellation_reason" @modified="itemModified" />
                </div>
            </div> -->
        </div>

        <WarningClose v-if="isDisplayWarningClose" @process="$event ? processClose() : cancelClose()" />
        <WarningDelete v-if="isDisplayWarningDelete" @process="$event ? processDeleteItem() : cancelDeleteItem()" />

    </ModalWindow>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import WarningClose from '@/components/lib/regions/WarningClose';
    import WarningDelete from '@/components/lib/regions/WarningDelete';

    import FieldPopupList from '@/components/lib/controls/FieldPopupList';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import FieldText from '@/components/lib/controls/FieldText';

    export default {
        name: 'MedMedicationEdit',

        components: {
            ModalWindow, WarningClose, WarningDelete,
            FieldPopupList, FieldSelect, FieldDate, FieldSwitch, FieldText, FieldNumber, 
        },

        // =============== Props ===============
        props: {
            id: Number,
            visitId: Number,
            medicineId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                isModified: false,
                msgItemModified: '',
                editMedicationFormData: {
                    // id: null,
                    visit_rec_id: null,
                    medication_route_id: null,
                    time_unit_id: null,
                    date_from: null,
                    date_to: null,
                    date_cancel: null,
                    frequency: null,
                    repeat_every: null,
                    medication_speed: null,
                    is_longterm: false,
                    is_on_necessity: false,
                    is_to_treatment_room: false,
                    medication_duration: null,
                    indications: null,
                    particularity: null,
                    commentary: null,
                },
                oldMedicationFormData: {},
                editMedicationDetFormData: {
                    // id: null,
                    med_medicine_id: null,
                    med_medication_id: null,
                    mat_item_id: null,
                    mat_unit_id: null,
                    dose: null,
                },
                oldMedicationDetFormData: {},
                isDisplayWarningDelete: false,
                isDisplayWarningClose: false,
                medicineListConfig: {
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'string', key:'fullname', header:this.$t('attribute.common.name') },
                        { type:'string', key:'substance', header:this.$t('attribute.med_medicine.substance') },
                        { type:'string', key:'release_forms', header:this.$t('attribute.med_medicine.release_forms') },
                     ],
                    order: [ 'name' ],
                    displayToolbar: true,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Methods ===============
        methods: {
            async getData() {
                try {
                    this.editMedicationFormData = (await this.authenticatedAxios.get(`${this.baseUrl}/med_medication/${this.itemId}`)).data;
                    this.editMedicationDetFormData = (await this.authenticatedAxios.get(`${this.baseUrl}/med_medication_det/${this.itemId}`)).data;

                    this.isModified = false;
                    this.msgItemModified = '';
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData(${this.isModified})`,);
                if (this.isModified) {
                    try {
                        if (typeof this.editMedicationFormData.id === 'undefined') {
                            // create item
                            this.editMedicationFormData = (await this.authenticatedAxios.post(`${this.baseUrl}/med_medication`, this.editMedicationFormData)).data;
                            // console.log(`DEBUG: ${this.$options.name}.saveData() this.editMedicationFormData=`, this.editMedicationFormData);
                            this.itemId = this.editMedicationFormData.id;
                        } else {
                            // update item
                            this.editMedicationFormData = (await this.authenticatedAxios.put(`${this.baseUrl}/med_medication/${this.editMedicationFormData.id}`, this.editMedicationFormData)).data;
                        }
                        if (typeof this.editMedicationDetFormData.id === 'undefined') {
                            // New item created
                            this.editMedicationDetFormData.med_medication_id = this.editMedicationFormData.id;
                            this.editMedicationDetFormData = (await this.authenticatedAxios.post(`${this.baseUrl}/med_medication_det`, this.editMedicationDetFormData)).data;
                            this.itemId = this.editMedicationDetFormData.id;
                        } else {
                            // update item
                            this.editMedicationDetFormData = (await this.authenticatedAxios.put(`${this.baseUrl}/med_medication_det/${this.editMedicationDetFormData.id}`, this.editMedicationDetFormData)).data;
                        }

                        this.isModified = false;
                        this.msgItemModified = '';

                        tools.displaySuccessSave(this.$parent);
                        this.$emit('close-modal', true);
                    } catch (err) {
                        tools.displayApiError(this.$parent, err);
                    }
                } else {
                    // no changes
                    this.$emit('close-modal', true);
                }
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified()`);
                if (
                    JSON.stringify(this.oldMedicationFormData) === JSON.stringify(this.editMedicationFormData) &&
                    JSON.stringify(this.oldMedicationDetFormData) === JSON.stringify(this.editMedicationDetFormData)
                ) {
                    this.isModified = false;
                    this.msgItemModified = '';
                } else {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                }
            },

            askDeleteItem() {
                this.isDisplayWarningDelete = true;
            },
            async processDeleteItem() {
                try {
                    await this.authenticatedAxios.delete(`${this.baseUrl}/med_medication_det/${this.editMedicationDetFormData.id}`);
                    await this.authenticatedAxios.delete(`${this.baseUrl}/med_medication/${this.editMedicationFormData.id}`);
                    tools.displaySuccessDelete(this.$parent);
                    this.$emit('close-modal', true);
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },
            cancelDeleteItem() { this.isDisplayWarningDelete = false },

            closeModal() {
                if (this.isModified) {
                    this.isDisplayWarningClose = true;
                } else {
                    this.processClose();
                }
            },
            processClose() { this.$emit('close-modal', false) },
            cancelClose() { this.isDisplayWarningClose = false },

        },

        // =============== Life cycle ===============
        beforeMount() {
            this.itemId = this.id;
            this.editMedicationFormData.visit_rec_id = this.visitId;
            this.editMedicationDetFormData.med_medication_id = this.itemId;
            this.editMedicationDetFormData.med_medicine_id = this.medicineId;
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.medicineId=`, this.medicineId);
        },

        async mounted() {
            if (this.itemId) {
                await this.getData();
                this.buttons = ['save', 'delete', 'close'];
            } else {
                this.buttons = ['save', 'close'];
            }
            this.oldMedicationDetFormData = JSON.parse(JSON.stringify(this.editMedicationDetFormData));
        },
    }
</script>

<style>

</style>
