/*
    Author: Eduard Grebenyukov
    Date: 2020-01-08
*/

import { i18n } from '@/locales';
import { appCode } from './appCode';
import moment from 'moment';

// console.log(`DEBUG: /store/translate pre-initLang definition, i18n.locale=`, i18n.locale);
function initLang() {
    // console.log(`DEBUG: /store/translate.initLang()`);
    // let lang = (window.localStorage.getItem(appCode) ? JSON.parse(window.localStorage.getItem(appCode)).language : null) || window.navigator.language || 'en';
    let lang =
        window.localStorage.getItem(`${appCode}:language`) ||
        (window.navigator.language ? window.navigator.language.substring(0, 2) : null);
        // ||'ru';
    // console.log('lang=', lang);
    // console.log('window.localStorage.getItem(appCode).language=', window.localStorage.getItem(appCode).language, 'window.navigator.language=', window.navigator.language);
    // console.log('JSON.parse(window.localStorage.getItem(appCode))=', JSON.parse(window.localStorage.getItem(appCode)), 'JSON.parse(window.localStorage.getItem(appCode)).language=', JSON.parse(window.localStorage.getItem(appCode)).language);
    i18n.locale = lang;
    // console.log(`DEBUG: /store/translate.initLang, lang=`, lang);
    moment.locale(lang);
    return lang;
}
initLang();
// console.log(`DEBUG: /store/translate post-initLang definition, i18n.locale=`, i18n.locale);

// const LANG_KEY = 'language';

// const updateLocale = (() => {
//     moment.updateLocale('ru', {
//         longDateFormat : {
//             LT : 'HH:mm'
//         }
//     });
// })();


// export const i18n i18n;
// export const initLang initLang;
// module.exports.i18n = i18n;
// module.exports.initLang = initLang;
// module.exports = {i18n, initLang};
export {i18n, initLang};