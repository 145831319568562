<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-29
-->

<template>
    <div class="form-group" :class="{ 'field-required': required }">
        <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label :for="id" v-if="label && (labelPosition === 'before')" :title="required ? $t('lib.required') : ''" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>
        <!-- <label :for="id" v-if="labelPosition != 'float'" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{label}}<sup v-if="required" class="required-mark">*</sup>
        </label> -->
        <!-- <label v-if="required" :title="required ? $t('lib.required') : ''" >{{label}}<sup class="required-mark">*</sup></label>
        <label v-if="!required">{{label}}</label> -->
        <input
            :id="id"
            type="password"
            :value="value"
            :required="required"
            @keyup="itemModified($event)"
            :placeholder="placeholder"
            :disabled="disabled"
            :autocomplete="autocomplete"
            :class="{
                'form-control': !readonly,
                'form-control-plaintext': readonly,
                'is-invalid': !!errorMessage,
                'field__required-empty': required && (!value || value == '')
            }"
            :style="`${inputStyle}`"
        />
        <label :for="id" v-if="labelPosition == 'float'" :title="required ? $t('lib.required') : ''" >{{label}}<sup v-if="required" class="required-mark">*</sup></label>

        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    export default {
        name: 'FieldPassword',

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: String,
            placeholder: String,
            disabled: Boolean,
            required: Boolean,
            readonly: Boolean,
            autocomplete: String,
            inputStyle: String,
            errorMessage: String,
        },

        // =============== Data ===============
        data() {
            return {
            }
        },

        // =============== Methods ===============
        methods: {
            itemModified(e) {
                this.$emit('update:value', e.target.value.trim());
                this.$emit('modified');
            }
        }
    }
</script>

<style >
</style>
