<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-19
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">

        <template #buttons>
            <button :disabled="reportInProgress" @click.prevent="saveReport()" class="btn btn-sm btn-outline-primary mr-1">
                <i class="fas fa-download"></i> {{ $t('lib.Grid.download') }}
            </button>
            <button :disabled="reportInProgress || !filterData.dateFrom || !filterData.dateTo" @click.prevent="filterModified()" class="btn btn-sm btn-primary mr-1">
                <i class="fas fa-play"></i> {{ $t('app.build') }}
            </button>
        </template>

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldDate
                        :label="$t('attribute.common.date_from')"
                        :value.sync="filterData.dateFrom"
                        :daysPrevNext="1"
                        required
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldDate
                        :label="$t('attribute.common.date_to')"
                        :value.sync="filterData.dateTo"
                        :daysPrevNext="1"
                        required
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldSelect
                        :label="$t('object.core_building')"
                        :value.sync="filterData.core_building_id"
                        source="core_building"
                        showEmptyRow
                        :emptyRowLabel="$t('app.allBuildings')"
                    />
                </div>
                <div class="col-sm-6 col-lg-4 col-xl">
                    <FieldNumber
                        :label="$t('attribute.common.rows_qty')"
                        :value.sync="filterData.row_qty"
                        :defaultValue="5000"
                        :minValue="10"
                        :maxValue="10000"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <Grid ref="report" :config="gridConfig" />
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ReportDailyDoctors',

        components: {
            TemplatePageStandard,
            FieldDate, FieldSelect, FieldNumber,
            Grid,
        },

        // =============== Data ===============
        data() {
            return {
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.reports') },
                    { text: this.$t('MainMenu.reports-daily-doctors') },
                ],
                filterData: {
                    // dateOf: null,
                    dateFrom: null,
                    dateTo: null,
                    core_building_id: null,
                    row_qty: null,
                },
                reportInProgress: false,
                gridConfig: {
                    apiUrl: null,
                    readonly: true,
                    hideZero: true,
                    columns: [
                        // { type:'string', key:'user_shortname', header:'Специальность / Пациент' },
                        { type:'string', key:'client_fullname', header:'Врач / Пациент' },
                        // { type:'string', key:'client_fullname', header:this.$t('object.core_client') },
                        { type:'string', key:'is_primary_string', header:'Перв./Пост.', $cellStyle:entry => entry.is_primary ? 'color:#0a0;' : '' },
                        { type:'string', key:'is_day_string', header:'Д/Н' },
                        { type:'number', key:'sum_discount', header:'Скидка', sortable:false },
                        {
                            type:'number', key:'sum_discount_pct', header:'%%', sortable:false,
                            $value: data => (
                                data.sum_discount && data.sum_payment && (data.sum_payment - data.sum_discount) ?
                                tools.round(data.sum_discount / (data.sum_payment - data.sum_discount) * 100, 2) + '%' :
                                0
                            ),
                        },
                        { type:'number', key:'sum_uds', header:'ЮДС', sortable:false },
                        { type:'number', key:'sum_vhi', header:'ДМС', sortable:false },
                        { type:'number', key:'sum_barter', header:'Бартер', sortable:false },
                        { type:'number', key:'sum_corporate', header:'Корп.', sortable:false },
                        { type:'number', key:'sum_person', header:'Пац.', sortable:false },
                        { type:'number', key:'sum_payment', header:'Всего', sortable:false },
                        { type:'number', key:'sum_paid', header:this.$t('attribute.common.sum_paid'), sortable:false },
                        { type:'number', key:'sum_paid_cash', header:'Нал.', sortable:false },
                        { type:'number', key:'sum_paid_cashless', header:'Безнал.', sortable:false },
                        { type:'number', key:'sum_paid_mobile', header:'Моб.', sortable:false },
                        { type:'number', key:'sum_debt', header:'Долг', sortable:false },
                        // { type:'string', key:'visit_commentary', header:this.$t('attribute.common.commentary') },
                        // { type:'string', key:'payment_commentary', header:this.$t('attribute.common.commentary') },
                        { type:'string', key:'bill_commentary', header:this.$t('attribute.common.commentary'), sortable:false },
                        { type:'string', hidden:true, key:'user_shortname', header:this.$t('object.core_speciality'), sortable:false }, // column definition needs to sorting
                    ],
                    subtotals: {
                        position: 'above',
                        columns: [
                            'user_shortname',
                        ]
                    },
                    totals: {
                        columns: [
                            { type:'total', key:'client_fullname', value:this.$t('app.total'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_discount', $value: data => tools.getArrayFieldSum(data, 'sum_discount'), style:'font-weight:bold;' },
                            {
                                type:'number', key:'sum_discount_pct',
                                $value: data => (
                                    tools.getArrayFieldSum(data, 'sum_discount') &&
                                    tools.getArrayFieldSum(data, 'sum_payment') &&
                                    (tools.getArrayFieldSum(data, 'sum_payment') - tools.getArrayFieldSum(data, 'sum_discount')) ?
                                    tools.round(
                                        tools.getArrayFieldSum(data, 'sum_discount') /
                                        (tools.getArrayFieldSum(data, 'sum_payment') - tools.getArrayFieldSum(data, 'sum_discount')) *
                                        100,
                                        2
                                    ) + '%' :
                                    0
                                ),
                                style:'font-weight:bold;'
                            },
                            { type:'number', key:'sum_uds', $value: data => tools.getArrayFieldSum(data, 'sum_uds'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_vhi', $value: data => tools.getArrayFieldSum(data, 'sum_vhi'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_barter', $value: data => tools.getArrayFieldSum(data, 'sum_barter'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_corporate', $value: data => tools.getArrayFieldSum(data, 'sum_corporate'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_person', $value: data => tools.getArrayFieldSum(data, 'sum_person'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_payment', $value: data => tools.getArrayFieldSum(data, 'sum_payment'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_paid', $value: data => tools.getArrayFieldSum(data, 'sum_paid'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_paid_cash', $value: data => tools.getArrayFieldSum(data, 'sum_paid_cash'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_paid_cashless', $value: data => tools.getArrayFieldSum(data, 'sum_paid_cashless'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_paid_mobile', $value: data => tools.getArrayFieldSum(data, 'sum_paid_mobile'), style:'font-weight:bold;' },
                            { type:'number', key:'sum_debt', $value: data => tools.getArrayFieldSum(data, 'sum_debt'), style:'font-weight:bold;' },
                        ],
                    },
                    // paginationPageSize: 1000,
                    order: [ 'client_fullname' ],
                    displayToolbar: false,
                },
            };
        },

        // =============== Methods ===============
        methods: {
            async setGridConfig() {
                this.reportInProgress = true;
                this.gridConfig.paginationPageSize = this.filterData.row_qty;
                // this.gridConfig1.apiUrl = `report-daily`;
                // this.gridConfig1.filter = {
                //     where: [
                //         [ 'visit_date', '>=', this.filterData.dateFrom ],
                //         [ 'visit_date', '<=', this.filterData.dateTo ],
                //         { core_building_id:this.filterData.core_building_id },
                //     ],
                //     order: [
                //         'visit_type_name',
                //         'client_shortname',
                //     ],
                // };
                // console.log(`DEBUG: ${this.$options.name}.setGridConfig() this.$refs.reportDate.itemPresentation=`, this.$refs.reportDate.itemPresentation);
                this.gridConfig.filter = {
                    where: [
                        // { visit_date: this.filterData.dateOf },
                        [ 'visit_date', '>=', this.filterData.dateFrom ],
                        [ 'visit_date', '<=', this.filterData.dateTo ],
                        { core_building_id:this.filterData.core_building_id },
                    ],
                    // order: [
                    //     'speciality_name',
                    //     'client_shortname',
                    // ],
                };
                if (!this.gridConfig.apiUrl) {
                    this.gridConfig.apiUrl = 'report-daily-doctors';
                } else {
                    await this.$refs.report.refresh();
                }
                // this.gridConfig = JSON.parse(JSON.stringify(this.gridConfig));
                // console.log(`DEBUG: ${this.$options.name}.setGridConfig() this.gridConfig.filter=`, this.gridConfig.filter);
                // this.gridConfig.filter = JSON.parse(JSON.stringify(this.gridConfig.filter));
                // this.$refs.report1.refresh();
                this.reportInProgress = false;
            },

            async filterModified() { await this.setGridConfig() },

            saveReport() {
                this.gridConfig.downloadFileName = (
                    this.breadcrumb[this.breadcrumb.length - 1].text + '_' +
                    // moment(this.filterData.dateOf).format('YYYY-MM-DD') + '_' +
                    moment(this.filterData.dateFrom).format('YYYY-MM-DD') + '_' +
                    moment(this.filterData.dateTo).format('YYYY-MM-DD')
                );
                this.$refs.report.download();
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this=`, this);
            // this.filterData.dateFrom = moment().startOf('month').format(moment.HTML5_FMT.DATE);
            // this.filterData.dateOf = moment().format(moment.HTML5_FMT.DATE);
            this.filterData.dateFrom = moment().format(moment.HTML5_FMT.DATE);
            this.filterData.dateTo = moment().format(moment.HTML5_FMT.DATE);
        },

        async mounted() {
            // this.setGridConfig();
        },
    }
</script>

<style>
</style>
