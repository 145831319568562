<!--
    Author: Eduard Grebenyukov
    Date: 2020-03-27
-->

<!--
    labelPosition: before | above
    format: null | YYYY-MM-DDTHH:mm (default) | YYYY-MM-DDTHH:mm:ss | YYYY-MM-DDTHH:mm:ss.SSS
-->

<template>
    <div :class="{ 'field-required': required, 'form-group': labelPosition == 'above', 'form-label-group': labelPosition == 'float' }">
        <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label :for="id" v-if="label && (labelPosition === 'before')" :title="required ? $t('lib.required') : ''" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <!-- <label
            v-if="label && labelPosition != 'before'"
            :title="required ? $t('lib.required') : ''"
            :class="{
                'field__label__above':true,
                'col-form-label':labelPosition === 'before'
            }"
        >
            {{label}}<sup v-if="required" class="required-mark">*</sup>
        </label> -->

        <div class="input-group">

            <!-- <label v-if="label && labelPosition === 'before'" :title="required ? $t('lib.required') : ''" class="field-datetime__label__before" >
                {{label}}<sup v-if="required" class="required-mark">*</sup>
            </label> -->

            <DatePicker
                v-if="!displayonly"
                :id="id"
                type="datetime"
                :value-type="datetimeFormat"
                :format="format || 'DD.MM.YYYY HH:mm'"
                :clearable="displayClearButton"
                :confirm="true"
                v-model="itemData"
                @input="itemModified($event)"
                @change="itemModified($event)"
                :input-class="{
                    'form-control': true,
                    'is-invalid': !!errorMessage,
                    'field__required-empty': required && (!itemData || itemData == '')
                }"
                :placeholder="placeholder"
                :disabled="disabled || readonly"
                :hour-step="hourStep"
                :minute-step="minuteStep"
                :second-step="secondStep"
                class="form-control"
                :style="`padding: 0; border: none; ${inputStyle}`"
            />
            <!-- <div v-if="displayClearButton" class="input-group-append">
                <button v-on:click="onClear" class="btn btn-outline-secondary" type="button"><i class="fas fa-times"></i></button>
            </div> -->
        </div>
        <span
            v-if="displayonly"
            @click="() => {if (textEmit) $emit(textEmit, itemData); if (textOnClick) eval(textOnClick, itemData);}"
            class="form-control-plaintext"
            :class="textClass"
            :style="textStyle"
        >
            {{ itemPresentation }}
        </span>
        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/ru';

    const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'FieldDatetime',

        components: { DatePicker },

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: String,
            defaultValue: String,
            format: String,
            placeholder: String,
            disabled: Boolean,
            readonly: Boolean,
            displayonly: Boolean,
            required: Boolean,
            textEmit: String,
            textOnClick: String,
            textClass: String,
            textStyle: String,
            saveState: String,
            saveSessionState: String,
            // showPrevNext: Boolean,
            daysPrevNext: Number,
            displayClearButton: Boolean,
            hourStep: Number,
            minuteStep: Number,
            secondStep: Number,
            inputStyle: String,
            errorMessage: String,
        },

        // =============== Data ===============
        data() {
            return {
                itemData: null,
                itemPresentation: null,
                datetimeFormat: null,
                presentationFormat: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Watch ===============
        watch: {
            value() {
                this.itemData = moment(this.value).format(moment.HTML5_FMT.DATETIME_LOCAL);
                // console.log(`DEBUG: ${this.$options.name}.watch.value(${this.value}) this.itemData=${this.itemData}, format=${moment.HTML5_FMT.DATETIME_LOCAL}`);
                // console.log(`DEBUG: ${this.$options.name}.watch.value ${new Date(this.itemData).toISOString()}`);
            },
            itemData() {
                this.itemPresentation = this.value ? `${moment(this.value).format(this.presentationFormat)}` : '';
                // console.log(`DEBUG: ${this.$options.name}.watch.itemData this.itemPresentation=${this.itemPresentation}`);
            }
        },

        // =============== Methods ===============
        methods: {
            itemModified(e) {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.itemData=`, this.itemData);
                if (this.itemData === '') this.itemData = null;
                if (this.saveState) {
                    // Save current value
                    this.authenticatedAxios.post(`${this.baseUrl}/${userSettingsUrl}`, {code: this.saveState, value: this.itemData});
                }
                if (this.saveSessionState) {
                    sessionStorage.setItem(this.saveSessionState, JSON.stringify(this.itemData));
                }
                this.$emit('update:value', this.itemData ? new Date(this.itemData).toISOString() : null);
                this.$emit('modified');
            },
            onClear(e) {
                this.itemData = null;
                this.itemModified(e);
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.datetimeFormat = moment.HTML5_FMT.DATETIME_LOCAL;
            this.presentationFormat = this.format || 'L LT';

            // this.itemData = moment(this.value).format(this.format || this.datetimeFormat);
            this.itemData = moment(this.value).format(this.datetimeFormat);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount('${this.value}') this.itemData=`, this.itemData);
            if (!this.itemData && this.saveState) {
                // Restore stored value
                let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() response=`, response);
                let item = response.data.rows[0];
                if (item) this.itemData = item.value;
                this.itemModified(this);
            }
            if (!this.itemData && this.saveSessionState) {
                let sessionData = JSON.parse(sessionStorage.getItem(this.saveSessionState));
                if (sessionData) this.itemData = sessionData;
                this.itemModified(this);
            }
            if (!this.itemData && typeof this.defaultValue != 'undefined' && this.defaultValue !== null) {
                this.itemData = this.defaultValue;
                this.itemModified(this);
            }
        },
    }
</script>

<style scoped>
    /* .field-datetime__label__before {
        margin-top: .5rem;
        margin-bottom: 0;
        margin-right: 8px;
        font-size: 1rem;
        line-height: 1.5;
        font-weight: bold;
    } */
</style>
