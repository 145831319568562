<!--
    Author: Eduard Grebenyukov
    Date: 2020-05-20
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose @click="closePressed()" />
            <button @click.prevent="showTransactions()" class="btn btn-outline-secondary btn-sm mr-1">
                {{ $t('component.Account.transactions') }}
            </button>
        </template>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-sm-12 col-xl-8">
                        <div class="form-row">
                            <div class="col-sm">
                                <Grid ref="serviceGrid" :config="serviceGridConfig" @refreshed="$refs.accountInfo.refresh()" >
                                    <template #buttons>
                                        <FieldSwitch
                                            id="isShowDebtOnly"
                                            :label="$t('component.Payers.onlyDebt')"
                                            :value.sync="isShowDebtOnly"
                                            :defaultValue="false"
                                            labelPosition="after"
                                            size="small"
                                        />
                                        <!-- <div class="custom-control custom-switch" style="padding-top:6px;">
                                            <input type="checkbox" class="custom-control-input" id="is_only_debt" v-model="isShowDebtOnly" >
                                            <label class="custom-control-label" for="is_only_debt">{{ $t('component.Payers.onlyDebt') }}</label>
                                        </div> -->
                                    </template>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-4">
                        <!-- Right sidebar -->
                        <AccountInfo ref="accountInfo" :accountId="itemId" />
                    </div>
                </div>
            </div>
        </form>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import Grid from '@/components/lib/regions/Grid';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import AccountInfo from '@/components/pages/common/AccountInfo';

    export default {
        name: 'AccountFin',

        components: {
            TemplatePageStandard, ButtonClose, Grid, FieldSwitch, AccountInfo,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                sumToPay: 0,
                buttons: [],
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.finances') },
                    { text: this.$t('MainMenu.finances-accounts'), to:'/finances/accounts' },
                    { text: '#', to: '#' },
                ],
                isShowDebtOnly: false,
                serviceGridConfig: {
                    apiUrl: null, // v_split
                    filter: null,
                    title: this.$t('component.Account.services'),
                    readonly: true,
                    columns: [
                        // { type:'hidden', key:'id', header:'id', align:'right', width:'100px' },
                        { type:'string', key:'bill_num', header:this.$t('attribute.fin_bill.bill_num'), align:'right' },
                        { type:'date', key:'time_of', header:this.$t('attribute.common.date_of') },
                        { type:'string', key:'service_name', header:this.$t('object.fin_service') },
                        // { type:'number', key:'sum_gross', header:this.$t('attribute.common.sum'), align:'right' },
                        // { type:'number', key:'sum_discount_final', header:this.$t('attribute.common.sum_discount'), align:'right' },
                        { type:'number', key:'sum_payment_final', header:this.$t('attribute.common.sum'), align:'right' },
                        { type:'number', key:'sum_paid', header:this.$t('attribute.common.sum_paid'), align:'right' },
                        { type:'number', key:'sum_unpaid', header:this.$t('component.Account.sum_to_pay'), align:'right' },
                        // { type:'number', key:'sum_to_pay', header:this.$t('component.Account.sum_to_pay'), align:'right', $value:entry => entry.sum_payment - entry.sum_paid },
                    ],
                    totals: {
                        columns: [
                            { type:'string', key:'service_name', value:this.$t('app.total'), style:'font-weight:bold; font-size:1.1rem;' },
                            // { type:'number', key:'sum_gross', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => {
                            //         if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_gross), 0 );
                            //         else return 0;
                            //     }
                            // },
                            // { type:'number', key:'sum_discount_final', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => {
                            //         if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_discount_final), 0 );
                            //         else return 0;
                            //     }
                            // },
                            { type:'number', key:'sum_payment_final', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_payment_final), 0 );
                                    else return 0;
                                }
                            },
                            { type:'number', key:'sum_paid', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_paid), 0 );
                                    else return 0;
                                }
                            },
                            { type:'number', key:'sum_unpaid', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_unpaid), 0 );
                                    else return 0;
                                }
                            },
                            // { type:'number', key:'sum_to_pay', style:'font-weight:bold; font-size:1.1rem;',
                            //     $value: (data) => {
                            //         if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_payment - item.sum_paid), 0 );
                            //         else return 0;
                            //     }
                            // },
                        ],
                    },
                    order: [ 'time_of' ],
                    paginationPageSize: 500,
                },
                selectedRows: [],
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Watch ===============
        watch: {
            itemId() {
                this.getServicesUrl();
            },
            isShowDebtOnly() {
                this.getServicesUrl();
            },
        },

        // =============== Methods ===============
        methods: {
            getServicesUrl() {
                if (this.itemId) {
                    // this.serviceGridConfig.apiUrl = `fin-get-services-to-pay/${this.itemId}/${ this.isShowDebtOnly ? 'debt' : 'all' }`;
                    this.serviceGridConfig.apiUrl = `v_split`;
                    this.serviceGridConfig.filter =
                        this.isShowDebtOnly ?
                        { where:[{ fin_account_id:this.itemId }, ['sum_unpaid', '>', 0] ]} :
                        { where:[{ fin_account_id:this.itemId }]}
                    ;
                    this.$refs.serviceGrid.refresh();
                } else {
                    this.serviceGridConfig.apiUrl = null;
                }
            },

            showTransactions() {
                this.$router.push({ name: 'finances/transactions', params: { accountId:this.itemId } });
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() params=`, this.$route.params);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.$route=`, this.$route);
            this.itemId = parseInt(this.$route.params.accountId);
            this.breadcrumb[3].text = `${this.itemId}`;
            // this.breadcrumb[3].to = `/finances/account/${this.itemId}`;
        },
    }
</script>

<style>
</style>
