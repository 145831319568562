<!--
    Author: Eduard Grebenyukov
    Date: 2022-01-27
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>
        <div id="page-content" class="form-float">
            <div class="form-row">
                <div class="col-sm col-md-3">
                    <FieldSelect
                        :label="$t('enum.device_type')"
                        :value.sync="editFormData.device_type_id"
                        source="enum/device_type"
                        required
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldString
                        :label="$t('attribute.common.code')"
                        :value.sync="editFormData.code"
                        required
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldString
                        :label="$t('attribute.common.name')"
                        :value.sync="editFormData.name"
                        required
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldString
                        :label="$t('attribute.common.name_short')"
                        :value.sync="editFormData.name_short"
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldString
                        :label="$t('attribute.core_device.ip_address')"
                        :value.sync="editFormData.ip_address"
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldString
                        :label="$t('attribute.core_device.ip_port')"
                        :value.sync="editFormData.ip_port"
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldString
                        :label="$t('attribute.core_device.aetitle')"
                        :value.sync="editFormData.aetitle"
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldString
                        :label="$t('attribute.core_device.serial_num')"
                        :value.sync="editFormData.serial_num"
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldSelect
                        :label="$t('object.core_org')"
                        :value.sync="editFormData.core_org_id"
                        source="core_org"
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldSelect
                        :label="$t('object.core_dept')"
                        :value.sync="editFormData.core_dept_id"
                        source="core_dept"
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldSelect
                        :label="$t('object.core_room')"
                        :value.sync="editFormData.core_room_id"
                        source="core_room"
                        @modified="checkModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldSelect
                        :label="$t('object.core_driver')"
                        :value.sync="editFormData.core_driver_id"
                        source="core_driver"
                        @modified="checkModified"
                    />
                </div>
            </div>
            <template v-if="displayGrids">
                <div class="row">
                    <div class="col-sm">
                        <Grid ref="linkedDeviceGrid" :config="linkedDeviceGridConfig" @modified="linkedDeviceGridModified($event)" />
                    </div>
                <!-- </div>
                <div class="row"> -->
                    <div class="col-sm">
                        <Grid ref="linkedToDeviceGrid" :config="linkedToDeviceGridConfig" @modified="linkedToDeviceGridModified($event)"/>
                    </div>
                </div>
            </template>
            <div class="form-row">
                <div class="col-sm">
                    <FieldCodeEditor
                        :label="$t('attribute.core_device.settings')"
                        :value.sync="editFormData.settings"
                        syntax="json"
                        @modified="checkModified"
                    />
                </div>
            </div>
        </div>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldString from '@/components/lib/controls/FieldString';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldCodeEditor from '@/components/lib/controls/FieldCodeEditor';

    import Grid from '@/components/lib/regions/Grid';

    const componentUrl = 'core_device';

    export default {
        name: 'DeviceEdit',

        components: {
            TemplatePageStandard,
            ButtonClose, ButtonSave, ButtonDelete,
            FieldString, FieldSelect, FieldCodeEditor,
            Grid,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                isFormModified: false,
                isLinkedDeviceGridModified: false,
                isLinkedToDeviceGridModified: false,
                editFormData: {
                    device_type_id: null,
                    core_org_id: null,
                    core_dept_id: null,
                    core_room_id: null,
                    core_driver_id: null,
                    code: null,
                    name: null,
                    name_short: null,
                    ip_address: null,
                    ip_port: null,
                    aetitle: null,
                    serial_num: null,
                    settings: null,
                },
                oldFormData: {},
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    // { text: this.$t('MainMenu.settings-general') },
                    { text: this.$t('MainMenu.settings-general-devices'), to: '/settings/general/devices' },
                    { text: this.$t('object.core_device') },
                ],
                linkedDeviceGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title: this.$t('component.DeviceEdit.linkedDevices'),
                    columns: [
                        { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        { type:'select', key:'device_link_type_id', header:this.$t('attribute.core_device_link.device_link_type_id'), source:'enum/device_link_type', required:true  },
                        { type:'hidden', key:'core_device_id', header:this.$t('attribute.core_device_link.core_device_id'), source:'core_device', required:true },
                        { type:'select', key:'linked_device_id', header:this.$t('attribute.core_device_link.linked_device_id'), source:'core_device', required:true },
                    ],
                    order: [ 'device_link_type_id' ],
                    displayToolbar: true,
                    displaySearch: true,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                    saveSessionState: `${this.$options.name}.linkedDevices`,
                },
                linkedToDeviceGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title: this.$t('component.DeviceEdit.linkedToDevice'),
                    columns: [
                        { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        { type:'select', key:'device_link_type_id', header:this.$t('attribute.core_device_link.device_link_type_id'), source:'enum/device_link_type', required:true  },
                        { type:'select', key:'core_device_id', header:this.$t('attribute.core_device_link.core_device_id'), source:'core_device', required:true },
                        { type:'hidden', key:'linked_device_id', header:this.$t('attribute.core_device_link.linked_device_id'), source:'core_device', required:true },
                    ],
                    order: [ 'device_link_type_id' ],
                    displayToolbar: true,
                    displaySearch: true,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                    saveSessionState: `${this.$options.name}.linkedToDevice`,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            apiUrl() { return `${this.baseUrl}/${componentUrl}` },
            displayGrids() { return typeof this.editFormData.id !='undefined' },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = [ 'save', 'delete', 'close' ];
                } else {
                    this.buttons = [ 'save', 'close' ];
                }
            },

            setGridFilter() {
                let filterLinkedDevice = { where:[
                    { core_device_id: this.itemId },
                ]};
                this.linkedDeviceGridConfig.columns.find(x => x.key === 'core_device_id').defaultValue = this.itemId;
                this.linkedDeviceGridConfig.filter = filterLinkedDevice;
                this.linkedDeviceGridConfig.apiUrl = 'core_device_link';

                let filterLinkedToDevice = { where:[
                    { linked_device_id: this.itemId },
                ]};
                this.linkedToDeviceGridConfig.columns.find(x => x.key === 'linked_device_id').defaultValue = this.itemId;
                this.linkedToDeviceGridConfig.filter = filterLinkedToDevice;
                this.linkedToDeviceGridConfig.apiUrl = 'core_device_link';
            },

            gotData() {
                this.isFormModified = false;
                this.msgItemModified = '';
                this.breadcrumb[this.breadcrumb.length - 1].text = this.editFormData.name;
                this.itemId = this.editFormData.id;
                this.oldFormData = tools.deepCopy(this.editFormData);
                this.setGridFilter();
                this.checkButtons();
            },

            async getData() {
                try {
                    this.editFormData = (await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.editFormData=`, this.editFormData);
                    this.gotData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isFormModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update user
                            await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData);
                            tools.displaySuccessSave(this);
                            this.gotData();
                        } else {
                            // New user created
                            this.editFormData = (await this.authenticatedAxios.post(this.apiUrl, this.editFormData)).data;
                            tools.displaySuccessSave(this);
                            this.gotData();
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
                // try {
                    if (this.isLinkedDeviceGridModified) {
                        this.$refs.linkedDeviceGrid.$emit('save');
                        this.isLinkedDeviceGridModified = false;
                    }
                    if (this.isLinkedToDeviceGridModified) {
                        this.$refs.linkedToDeviceGrid.$emit('save');
                        this.isLinkedToDeviceGridModified = false;
                    }
                // } catch (err) {
                //     tools.displayApiError(this, err);
                // }
                this.checkModified();
            },

            checkModified() {
                // console.log(`DEBUG: ${this.$options.name}.checkModified()`);
                this.isFormModified = !tools.isEqual(this.oldFormData, this.editFormData);
                // console.log(`DEBUG: ${this.$options.name}.checkModified(), this.isFormModified=${this.isFormModified}, this.isLinkedDeviceGridModified=${this.isLinkedDeviceGridModified}`);
                // console.log(`DEBUG: ${this.$options.name}.checkModified() this.oldFormData=`, this.oldFormData);
                // console.log(`DEBUG: ${this.$options.name}.checkModified() this.editFormData=`, this.editFormData);
                this.isModified = (
                    this.isFormModified ||
                    this.isLinkedDeviceGridModified ||
                    this.isLinkedToDeviceGridModified
                );
                if (this.isModified) {
                    this.msgItemModified = this.$t('component.VisitRouteEdit.itemModified');
                } else {
                    this.msgItemModified = '';
                }
            },

            async processDeleteItem (doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                        this.isFormModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },

            // Grids

            linkedDeviceGridModified(modified) {
                // console.log(`DEBUG: ${this.$options.name}.linkedDeviceGridModified(${modified})`);
                this.isLinkedDeviceGridModified = modified;
                this.checkModified();
            },

            linkedToDeviceGridModified(modified) {
                this.isLinkedToDeviceGridModified = modified;
                this.checkModified();
            },

            // linkedDeviceGridEdit(id) {
            //     this.$router.push({ name: this.linkedDeviceGridConfig.onEdit, params: { id } });
            // },
        },

        // =============== Life cycle ===============
        beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.id=`, this.id);
            this.itemId = parseInt(this.$route.params.id);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.itemId=`, this.itemId);
            if (this.itemId) {
                await this.getData();
                this.setGridFilter();
            }
            this.checkButtons();
            this.oldFormData = tools.deepCopy(this.editFormData);
        },
    }
</script>

<style>
</style>
