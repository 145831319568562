/*
    Author:Eduard Grebenyukov
    Date:2021-09-12
*/

import { i18n } from '@/locales';

const Clients = () => import('@/components/pages/clients/Clients');
const ClientEdit = () => import('@/components/pages/clients/ClientEdit');
const ClientsToMerge = () => import('@/components/pages/clients/ClientsToMerge');
const MergeClients = () => import('@/components/pages/clients/MergeClients');
const LabResults = () => import('@/components/pages/med/LabResults');

const ClientFiles = () => import('@/components/pages/clients/ClientFiles');
const ClientFileEdit = () => import('@/components/pages/clients/ClientFileEdit');

const routes = [
    { name:'clients',             path:'/clients',                      component:Clients,        meta:{title:i18n.t('MainMenu.clients')} },
    { name:'client-new',          path:'/client',                       component:ClientEdit,     meta:{title:i18n.t('object.core_client')} },
    { name:'client-edit',         path:'/client/:id',                   component:ClientEdit,     meta:{title:i18n.t('object.core_client')} },
    { name:'client-files',        path:'/client/:clientId/files',       component:ClientFiles,    meta:{title:i18n.t('MainMenu.clients-files')} },
    { name:'client-file-new',     path:'/client/:clientId/file',        component:ClientFileEdit, meta:{title:i18n.t('MainMenu.clients-files')} },
    { name:'client-file-edit',    path:'/client/:clientId/file/:id',    component:ClientFileEdit, meta:{title:i18n.t('MainMenu.clients-files')} },
    { name:'clients-to-merge',    path:'/clients-to-merge',             component:ClientsToMerge, meta:{title:i18n.t('MainMenu.clients-to-merge')} },
    { name:'clients-merge',       path:'/clients-merge/:id1/:id2',      component:MergeClients,   meta:{title:i18n.t('MainMenu.clients-merge')} },
    { name:'clients-lab-results', path:'/client/:clientId/lab-results', component:LabResults,     meta:{title:i18n.t('MainMenu.clients-lab-results')} },
]

export default routes
