<!--
    Author: Eduard Grebenyukov
    Date: 2020-06-17
-->

<template>
    <b-nav-item @click="openCrmRequest()" v-if="isReady" :disabled="!isActive" :title="title" >
        <i class="fas fa-phone-volume incoming-call__sign" :class="{ 'incoming-call__active':isActive }"></i>
        {{ isActive ? phoneNumFormatted : '' }}
    </b-nav-item>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import tools from '@/components/lib/tools';

    export default {
        name: 'IncomingCall',

        // =============== Props ===============
        // props: ['menuItems', 'currentMenu'],

        // =============== Data ===============
        data() {
            return {
                refreshTimerId: null,
                isReady: false,
                pollingInterval: null,
                callOpenRequest: false,
            }
        },

        // =============== Filters ===============
        // filters: {
        // },

        // =============== Watch ===============
        watch: {
            isActive() { 
                // console.log(`DEBUG: ${this.$options.name}.watch.isActive(${this.isActive})=${this.callOpenRequest}`);
                if (this.callOpenRequest && this.isActive) this.openCrmRequest();
                // if (this.isActive) {
                    // tools.beep();
                // }
            },
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                answeredCall: 'answeredCall',
            }),
            isActive() { return !!this.answeredCall },
            phoneNum() { return this.isActive && this.answeredCall.caller_number },
            phoneNumFormatted() { return tools.formatPhoneNumber(this.phoneNum) },
            title() { return this.isActive ? this.$t('component.IncomingCall.talking') : this.$t('component.IncomingCall.available') },
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['getAnsweredCall']),

            async getData() {
                // console.log(`DEBUG: ${this.$options.name}.getData() this=`, this);
                await this.getAnsweredCall();
            },

            openCrmRequest() {
                // console.log(`DEBUG: ${this.$options.name}.openCrmRequest(), typeof this.answeredCall=`, this.answeredCall);
                this.$router.push({ name: 'crm/request-new', params: { id: null, phoneNum:this.phoneNum, callId:this.answeredCall.id } });
                // let routeData = this.$router.resolve({ name: 'crm/request-new', params: { id: null, phoneNum:this.phoneNum } });
                // window.open(routeData.href, '_blank');
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), currentUser=`, this.currentUser);
            if (this.refreshTimerId)
                clearInterval(this.refreshTimerId);
            if (this.currentUser.roles.find( el => el.code === 'calls')) {
                try {
                    this.pollingInterval = parseInt((await tools.getSetting(this, 'call_polling_interval')).value);
                    this.callOpenRequest = (await tools.getSetting(this, 'call_open_request')).value === 'true';
                } catch(err) {
                    // Do nothing
                }
                // console.log(`DEBUG: ${this.$options.name}.mounted(), this.pollingInterval=`, this.pollingInterval);
                if (this.pollingInterval > 0) {
                    this.isReady = true;
                    this.refreshTimerId = setInterval(this.getData, this.pollingInterval);
                }
            } else {
                this.isReady = false;
            }
            //this.currentUser.currentWorkplace
        },

        beforeDestroy() {
            if (this.refreshTimerId)
                clearInterval(this.refreshTimerId);
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .incoming-call__sign {
        transform: rotate(-30deg);
    }
    .incoming-call__active {
        color: yellowgreen;
        font-size: 1.5rem!important;
        animation: incoming-call__blink 1s linear infinite;
        /* animation: incoming-call__shake 2s linear infinite; */
        /* animation: incoming-call__turn 1s linear infinite; */
    }
    @keyframes incoming-call__blink {
        50% { opacity: 1; }
        50% { opacity: 0.2; }
    }
    @keyframes incoming-call__shake {
        10% { opacity: 1; }
        10% { opacity: 0.2; }
        10% { opacity: 1; }
        10% { transform: rotate(-30deg); }
        10% { transform: rotate(30deg); }
        10% { transform: rotate(-30deg); }
        10% { transform: rotate(30deg); }
    }
    @keyframes incoming-call__turn {
        50% { transform: rotate(-30deg); }
        50% { transform: rotate(30deg); }
    }
</style>
