<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-03
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonPrint v-if="buttons.includes('print')" @click="printReferrals()">
                <i class="fas fa-print"></i> {{ $t('component.Referrals.printReferrals') }}
            </ButtonPrint>
            <!-- <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" /> -->
        </template>

        <VisitDescription />

        <div class="referrals__navbar-container">

            <VisitTabs currentTab="visit-referrals" />

            <div class="navbar">
                <ButtonSwitch
                    :value.sync="isDisplayRightSidebar"
                    :defaultValue="true"
                    :saveState="`${$options.name}.display_sidebar`"
                    :title="$t('app.hideShow')"
                />
            </div>
        </div>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div :class="isDisplayRightSidebar ? 'referrals__referrals col-sm-12 col-lg-8 col-xl-8' : 'referrals__referrals col'">
                        <Grid
                            ref="referrals"
                            :key="$route.fullPath"
                            :config="gridConfig"
                            @edit-item="editReferral($event)"
                            @refreshed="referralsModified()"
                        />
                    </div>
                    <div class="referrals__right-sidebar col-sm-12 col-lg-4 col-xl-4" :style="isDisplayRightSidebar ? '' : 'display:none;'">
                        <!-- Right sidebar -->
                        <ReferralTemplate
                            :visitTypeId="lastVisit.visit_type_id"
                            :specialityId="lastVisit.core_speciality_id"
                            :visitTime="new Date(lastVisit.time_of)"
                            :formItemName="currentFormItem"
                            :attributeName="currentAttribute"
                            @selected="addReferral($event)"
                        />
                    </div>
                </div>
            </div>
        </form>
        <ReferralEdit v-if="isDisplayModalReferralEdit" @close-modal="closeModalReferralEdit($event)" :id="referralId" />
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';

    import ButtonSwitch from '@/components/lib/controls/ButtonSwitch';
    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonPrint from '@/components/lib/controls/ButtonPrint';

    import Grid from '@/components/lib/regions/Grid';
    import ReferralTemplate from '@/components/pages/visits/ReferralTemplate';
    import ReferralEdit from './ReferralEdit';

    export default {
        name: 'Referrals',

        components: {
            TemplatePageStandard, VisitDescription, VisitTabs,
            ButtonSwitch, ButtonClose, ButtonSave, ButtonPrint,
            Grid, ReferralTemplate, ReferralEdit
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                referralId: null,
                referralsQty: 0,
                buttons: [],
                msgItemModified: '',
                isDisplayRightSidebar: null,
                currentFormItem: null,
                currentAttribute: null,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.referrals') }),
                gridConfig: {
                    apiUrl: null,
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'50px' },
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'64px' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name'), readonly:true },
                        { type:'date', key:'date_from', header:this.$t('attribute.common.date_from'), readonly:true },
                        { type:'date', key:'date_to', header:this.$t('attribute.common.date_to'), readonly:true },
                        // { type:'html', key:'referral', header:this.$t('object.med_referral_type'), $value: entry => `${entry.service_code} / ${entry.service_name}` },
                        // { type:'number', key:'price', header:this.$t('attribute.common.price'), align:'right', width:'64px' },
                        { type:'number', key:'qty', header:this.$t('attribute.common.qty'), sortable:false, align:'right', width:'64px' },
                        { type:'number', key:'sum_payment', header:this.$t('component.Referrals.sum_payment'), sortable:false, align:'right', width:'64px' },
                        // {
                        //     type:'checkbox', key:'is_cito', header:this.$t('attribute.med_referral.is_cito'), width:'50px', align:'center;',
                        //     $cellStyle:(entry) => (entry.is_cito ? 'background-color:#ffcccc;' : '') },
                        {
                            type: 'icon',
                            key: 'is_cito',
                            sortable: false,
                            style: 'color:red;',
                            header: this.$t('attribute.med_referral.is_cito'),
                            title: this.$t('attribute.med_referral.is_cito'),
                            $icon: (entry) => (entry.is_cito ? 'fas fa-exclamation-circle' : ''),
                        },
                        {
                            type: 'icon',
                            key: 'is_canceled',
                            sortable: false,
                            style: 'color:red;',
                            header: this.$t('attribute.med_referral.is_canceled'),
                            title: this.$t('attribute.med_referral.is_canceled'),
                            $icon: (entry) => (entry.is_canceled ? 'fas fa-times-circle' : ''),
                        },
                        // { type:'checkbox', key:'is_canceled', header:this.$t('attribute.med_referral.is_canceled'), width:'50px', align:'center;' },
                    ],
                    buttons: ['edit'],
                    saveSessionState: `${this.$options.name}`,
                },
                isDisplayModalReferralEdit: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                lastClient: 'lastClient',
                lastVisit: 'lastVisit',
                // currencyAccounting: 'currencyAccounting',
            }),
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                // console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.$refs.referrals.qty=`, this.$refs.referrals.getTotalRowsQty());
                if (this.referralsQty) {
                    this.buttons = ['print'];
                } else {
                    this.buttons = [];
                }
            },

            async addReferral(item) {
                try {
                    let visit = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_rec/${this.visitId}`)).data;
                    let data = {
                        med_referral_type_id: parseInt(item.id),
                        core_client_id: this.lastClient.id,
                        visit_rec_id: this.visitId,
                        med_pack_id: null,
                        fin_estimate_id: null,
                        date_from: moment(visit.time_of).format(moment.HTML5_FMT.DATE),
                        date_to: moment(visit.time_of).add(item.term, 'days').format(moment.HTML5_FMT.DATE),
                        qty: 1,
                        is_cito: false,
                    };
                    // console.log(`DEBUG: ${this.$options.name}.addReferral(), item=`, item);
                    // console.log(`DEBUG: ${this.$options.name}.addReferral(), visit=`, visit);
                    // console.log(`DEBUG: ${this.$options.name}.addReferral(), data=`, data);
                    await this.authenticatedAxios.post(`${this.baseUrl}/med_referral`, data);
                    await this.$refs.referrals.refresh();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            editReferral(item) {
                // console.log(`DEBUG: ${this.$options.name}.editReferral(), item=`, item);
                // this.$router.push({ name:'referral-edit', params:{ clientId:this.clientId, visitId:this.visitId, visitReferralId:item.id }});
                this.referralId = item.id;
                this.isDisplayModalReferralEdit = true;
            },

            closeModalReferralEdit(isRefresh) {
                this.isDisplayModalReferralEdit = false;
                if (isRefresh) this.$refs.referrals.refresh();
            },

            referralsModified() {
                this.referralsQty = this.$refs.referrals.getTotalRowsQty();
                // console.log(`DEBUG: ${this.$options.name}.referralsModified(), this.$refs.services.qty=`, this.$refs.services.getTotalRowsQty());
                this.checkButtons();
            },

            async printReferrals() {
                try {
                    // console.log(`DEBUG: ${this.$options.name}.printReferrals()`);
                    let services = this.$refs.referrals.getAllRows();
                    let sumTotal = services.reduce(((sum, item) => sum + item.sum_payment), 0);
                    let currencyCode = (await tools.getSetting(this, 'currency_accounting')).value;
                    // console.log(`DEBUG: ${this.$options.name}.printReferrals() currencyCode=`, currencyCode);
                    let filterStringCurrency = tools.getFilterString({ where:[{ code:currencyCode }]});
                    let currency = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_currency${filterStringCurrency}`)).data.rows[0];
                    // let filterStringBill = tools.getFilterString({ where:[{ visit_rec_id:this.visitId }]});
                    // let bill = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_bill${filterStringBill}`)).data.rows[0];

                    // let currency = this.currencyAccounting;
                    // console.log(`DEBUG: ${this.$options.name}.printReferrals() currency=`, currency);
                    let o = {
                        // taskId: `print.${this.$options.name}`,
                        template: 'referrals',
                        referrals: {
                            visit_date: moment(this.lastVisit.time_of).format('L'),
                            total_sum: sumTotal,
                            total_sum_company: 0,
                            total_sum_payer: 0,
                            total_sum_patient: sumTotal,
                            visit_rec_id: this.visitId,
                            currency: currency.name_short,
                            services: services
                        },
                        visit: { visit_rec_id: this.visitId },
                    };
                    // console.log(`DEBUG: ${this.$options.name}.printReferrals() o=`, o, ', typeof o.referrals.sumTotal=', typeof o.referrals.sumTotal);
                    tools.printForm(this, o);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this=`, this);
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            this.gridConfig.apiUrl = `v_referral`;
            this.gridConfig.filter = { where:[{ visit_rec_id: this.visitId }]};
        },

        async mounted() {
            this.checkButtons();
        },
    }
</script>

<style>
    .referrals__navbar-container {
        display: flex;
    }
    .referrals__referrals {
        overflow-x: auto;
    }
    /* .referrals__right-sidebar {
    } */
    /* .referrals__referrals .grid-renders.grid-container .btn-toolbar {
        min-width: 810px;
    } */
</style>
