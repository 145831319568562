<!--
    Author: Eduard Grebenyukov
    Date: 2020-03-22
-->

<!--
    keyId
    keyLabel
-->

<template>
    <ul class="nav nav-tabs schedule-tabs">
        <li v-for="listItem in tabsData" :key="`navigation-tabs-` + listItem[keyId||'id']" class="nav-item" >
            <router-link to="" @click.native="itemSelected(listItem[keyId||'id'])" :class="{ 'nav-link':true, active:listItem[keyId||'id'] == itemData }" >
                {{listItem[keyLabel||'name']}}
            </router-link>
            <!-- <a href="#" @click="itemSelected(listItem[keyId||'id'])" class="nav-link" :class="{ active: listItem[keyId||'id'] == itemData}" >{{listItem[keyLabel||'name']}}</a> -->
        </li>
    </ul>
</template>

<script>
    import { mapGetters } from 'vuex';
    import tools from '@/components/lib/tools';

    const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'TabSwitch',

        // =============== Props ===============
        props: {
            id: String,
            value: [String, Number],
            defaultValue: [String, Number],
            source: [String, Array],
            keyId: String,
            keyLabel: String,
            textEmit: String,
            // textOnClick: String,
            // textClass: String,
            // textStyle: String,
            saveState: String,
            saveSessionState: String,
        },

        // =============== Data ===============
        data() {
            return {
                controlId: null, // internal control ID, can be overwritten by props.id
                tabsData: [],
                itemData: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Watch ===============
        watch: {
            value() {
                this.itemData = this.value;
                // console.log(`DEBUG: ${this.$options.name}.watch.value this.value=`, this.value);
            },
            source() {
                this.getData();
            }
        },

        // =============== Methods ===============
        methods: {
            async getData() {
                if (typeof this.source === 'string') {
                    // console.log(`DEBUG: ${this.$options.name}.getData() url=`, `${this.baseUrl}/${this.source}`);
                    try {
                        this.tabsData = (await this.authenticatedAxios.get(`${this.baseUrl}/${this.source}`)).data.rows;
                    } catch (err) {
                        tools.displayApiError(this.$parent, err);
                    }
                } else if (typeof this.source === 'object' && Array.isArray(this.source)) {
                    this.tabsData = this.source;
                } else {
                    // Unknown object, set empty array
                    this.tabsData = [];
                }
            },

            itemSelected(id) {
                this.itemData = id;
                // console.log(`DEBUG: ${this.$options.name}.itemSelected() this.itemData=`, this.itemData);
                if (this.saveState) {
                    // Save current value
                    // console.log(`DEBUG: ${this.$options.name}.itemSelected() this.itemData=`, this.itemData);
                    this.authenticatedAxios.post(`${this.baseUrl}/${userSettingsUrl}`, {code: this.saveState, value: this.itemData});
                }
                if (this.saveSessionState) {
                    sessionStorage.setItem(this.saveSessionState, JSON.stringify(this.itemData));
                }
                // this.$emit('update:value', e.target.value.trim());
                this.$emit('update:value', this.itemData);
                this.$emit('modified');
            }
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemData = this.value;
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.itemData=`, this.itemData, ', this.defaultValue=', this.defaultValue);
            if (!this.itemData && this.saveState) {
                // Restore stored value
                let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() response=`, response);
                let item = response.data.rows[0];
                if (item) this.itemSelected(item.value);
            }
            if (!this.itemData && this.saveSessionState) {
                let sessionData = JSON.parse(sessionStorage.getItem(this.saveSessionState));
                if (sessionData) this.itemSelected(sessionData);
            }
            if (!this.itemData && typeof this.defaultValue != 'undefined' && this.defaultValue !== null) {
                this.itemSelected(this.defaultValue);
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() after default this.itemData=`, this.itemData, ', this.defaultValue=', this.defaultValue);
            }
        },
        async mounted() {
            await this.getData();
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.tabsData=`, this.tabsData);
            // this.itemPresentation = this.tabsData.find(x => {console.log('x=', x); return x[this.keyId||'id'] === this.itemData})[this.keyLabel||'name'];
            let currentListItem = this.tabsData.find(x => { return x[this.keyId||'id'] === this.itemData });
            // console.log(`DEBUG: ${this.$options.name}.mounted() o=`, o);
            if (currentListItem) {
                this.itemPresentation = currentListItem[this.keyLabel||'name'];
            } else {
                this.itemPresentation = '';
            }
        },
    }
</script>

<style>
</style>
