/*
    Author:Eduard Grebenyukov
    Date:2021-09-12
*/

import { i18n } from '@/locales';

import { pageConfig } from '../store/pageConfig';
import { pageSubmenu } from '../store/pageSubmenu';

const SubmenuPage = () => import('@/components/lib/pages/SubmenuPage');
const GridEdit = () => import('@/components/pages/common/GridEdit');


const EnumEdit = () => import('@/components/pages/settings/EnumEdit');
const PrintformEdit = () => import('@/components/pages/settings/PrintformEdit');
const DeviceEdit = () => import('@/components/pages/settings/DeviceEdit');
const ComponentEdit = () => import('@/components/pages/settings/ComponentEdit');

const Users = () => import('@/components/pages/settings/Users');
const UserEdit = () => import('@/components/pages/settings/UserEdit');
const Roles = () => import('@/components/pages/settings/Roles');
const RoleEdit = () => import('@/components/pages/settings/RoleEdit');
const OpenPeriod = () => import('@/components/pages/settings/OpenPeriod');

const WorkplaceEdit = () => import('@/components/pages/settings/WorkplaceEdit');
const VisitTypeEdit = () => import('@/components/pages/settings/VisitTypeEdit');
const VisitGroupWorkplaces = () => import('@/components/pages/settings/VisitGroupWorkplaces');
const Worktime = () => import('@/components/pages/settings/Worktime');
const WorktimeEdit = () => import('@/components/pages/settings/WorktimeEdit');
// const ReferralVisitTypes = () => import('@/components/pages/settings/ReferralVisitTypes');
const VisitRouteEdit = () => import('@/components/pages/settings/VisitRouteEdit');
const VisitQueueMonitor = () => import('@/components/pages/settings/VisitQueueMonitor');

const FinSetEdit = () => import('@/components/pages/settings/FinSetEdit');

// const MapEdit = () => import('@/components/pages/settings/MapEdit');

const SystemLog = () => import('@/components/pages/settings/SystemLog');
const SystemErrors = () => import('@/components/pages/settings/SystemErrors');

const routes = [
    // settings
    { name:'settings', path:'/settings', component:SubmenuPage, props:{ config:pageSubmenu.submenu_settings }, meta:{title:i18n.t('MainMenu.settings')} },

    // settings-general
    { name:'settings/general',                path:'/settings/general',               component:SubmenuPage, props:{ config:pageSubmenu.submenu_settings_general }, meta:{title:i18n.t('MainMenu.settings-general')} },
    { name:'settings/general/system',         path:'/settings/general/system',        component:GridEdit,    props:{ config:pageConfig.core_setting },              meta:{title:i18n.t('MainMenu.settings-general-system')} },
    { name:'settings/general/enum_group',     path:'/settings/general/enum_group',    component:GridEdit,    props:{ config:pageConfig.core_enum_group },           meta:{title:i18n.t('MainMenu.settings-general-enum')} },
    { name:'settings/general/enum',           path:'/settings/general/enum/:id',      component:EnumEdit,                                                           meta:{title:i18n.t('MainMenu.settings-general-enum')} },
    { name:'settings/general/organizations',  path:'/settings/general/organizations', component:GridEdit,    props:{ config:pageConfig.core_org },                  meta:{title:i18n.t('MainMenu.settings-general-organizations')} },
    { name:'settings/general/buildings',      path:'/settings/general/buildings',     component:GridEdit,    props:{ config:pageConfig.core_building },             meta:{title:i18n.t('MainMenu.settings-general-buildings')} },
    { name:'settings/general/departments',    path:'/settings/general/departments',   component:GridEdit,    props:{ config:pageConfig.core_department },           meta:{title:i18n.t('MainMenu.settings-general-departments')} },
    { name:'settings/general/rooms',          path:'/settings/general/rooms',         component:GridEdit,    props:{ config:pageConfig.core_room },                 meta:{title:i18n.t('MainMenu.settings-general-rooms')} },
    { name:'settings/general/countries',      path:'/settings/general/countries',     component:GridEdit,    props:{ config:pageConfig.core_country },              meta:{title:i18n.t('MainMenu.settings-general-countries')} },
    { name:'settings/general/specialities',   path:'/settings/general/specialities',  component:GridEdit,    props:{ config:pageConfig.core_speciality },           meta:{title:i18n.t('MainMenu.settings-general-specialities')} },
    { name:'settings/general/devices',        path:'/settings/general/devices',       component:GridEdit,    props:{ config:pageConfig.core_device },               meta:{title:i18n.t('MainMenu.settings-general-devices')} },
    { name:'settings/general/device-new',     path:'/settings/general/device',        component:DeviceEdit,                                                         meta:{title:i18n.t('object.core_device')} },
    { name:'settings/general/device-edit',    path:'/settings/general/device/:id',    component:DeviceEdit,                                                         meta:{title:i18n.t('object.core_device')} },
    { name:'settings/general/printforms',     path:'/settings/general/printforms',    component:GridEdit,    props:{ config:pageConfig.core_printform },            meta:{title:i18n.t('MainMenu.settings-general-printforms')} },
    { name:'settings/general/printform-new',  path:'/settings/general/printform',     component:PrintformEdit,                                                      meta:{title:i18n.t('object.core_printform')} },
    { name:'settings/general/printform-edit', path:'/settings/general/printform/:id', component:PrintformEdit,                                                      meta:{title:i18n.t('object.core_printform')} },
    // { name:'settings/general/component-types', path:'/settings/general/component-types', component:GridEdit, props:{ config:pageConfig.core_component_type }, meta:{title:i18n.t('MainMenu.settings-general-component-types')} },
    { name:'settings/general/components',     path:'/settings/general/components',    component:GridEdit,    props:{ config:pageConfig.core_component },            meta:{title:i18n.t('MainMenu.settings-general-components')} },
    { name:'settings/general/component-new',  path:'/settings/general/component',     component:ComponentEdit,                                                      meta:{title:i18n.t('object.core_component')} },
    { name:'settings/general/component-edit', path:'/settings/general/component/:id', component:ComponentEdit,                                                      meta:{title:i18n.t('object.core_component')} },

    // settings-rights
    { name:'settings/rights',              path:'/settings/rights',              component:SubmenuPage, props:{ config:pageSubmenu.submenu_settings_rights }, meta:{title:i18n.t('MainMenu.settings-rights')} },
    { name:'settings/rights/users',        path:'/settings/rights/users',        component:Users,                                                             meta:{title:i18n.t('MainMenu.settings-rights-users')} },
    { name:'settings/rights/user-new',     path:'/settings/rights/user',         component:UserEdit,                                                          meta:{title:i18n.t('object.core_user')} },
    { name:'settings/rights/user-edit',    path:'/settings/rights/user/:id',     component:UserEdit,                                                          meta:{title:i18n.t('object.core_user')} },
    // { name:'settings/rights/roles', path:'/settings/rights/roles', component:Roles, meta:{title:'Roles'} },
    { name:'settings/rights/roles',        path:'/settings/rights/roles',        component:Roles,       props:{ config:pageConfig.core_role },                meta:{title:i18n.t('MainMenu.settings-rights-roles')} },
    { name:'settings/rights/role',         path:'/settings/rights/role/:id',     component:RoleEdit,                                                          meta:{title:i18n.t('object.core_role')} },
    { name:'settings/rights/menu',         path:'/settings/rights/menu',         component:GridEdit,    props:{ config:pageConfig.settings_menu },            meta:{title:'Roles'} },
    { name:'settings/rights/permissions',  path:'/settings/rights/permissions',  component:GridEdit,    props:{ config:pageConfig.core_permission },          meta:{title:i18n.t('MainMenu.settings-rights-permissions')} },
    { name:'settings/rights/open-periods', path:'/settings/rights/open-periods', component:OpenPeriod,                                                        meta:{title:i18n.t('MainMenu.settings-rights-open-periods')} },

    // settings-visits
    { name:'settings/visits',               path:'/settings/visits',                                 component:SubmenuPage, props:{ config:pageSubmenu.submenu_settings_visits }, meta:{title:i18n.t('MainMenu.settings-visits')} },
    { name:'settings/visits/workplaces',    path:'/settings/visits/workplaces',                      component:GridEdit,    props:{ config:pageConfig.core_workplace },           meta:{title:i18n.t('MainMenu.settings-visits-workplaces')} },
    { name:'settings/visits/types',         path:'/settings/visits/types',                           component:GridEdit,    props:{ config:pageConfig.visit_type },               meta:{title:i18n.t('MainMenu.settings-visits-types')} },
    { name:'settings/visits/worktimes',     path:'/settings/visits/worktimes',                       component:Worktime,    props:{ config:pageConfig.visit_type },               meta:{title:i18n.t('MainMenu.settings-visits-worktime')} },
    { name:'settings/visits/worktime-new',  path:'/settings/visits/worktime/new/:core_workplace_id', component:WorktimeEdit,                                                      meta:{title:i18n.t('object.visit_worktime')} },
    { name:'settings/visits/worktime-edit', path:'/settings/visits/worktime/:id',                    component:WorktimeEdit,                                                      meta:{title:i18n.t('object.visit_worktime')} },
    { name:'settings/visits/lock-types',    path:'/settings/visits/lock-types',                      component:GridEdit,    props:{ config:pageConfig.visit_lock_type },          meta:{title:i18n.t('MainMenu.settings-visits-lock-types')} },
    { name:'settings/visits/groups',        path:'/settings/visits/groups',                          component:GridEdit,    props:{ config:pageConfig.visit_group },              meta:{title:i18n.t('MainMenu.settings-visits-groups')} },
    { name:'settings/visits/type-pages',    path:'/settings/visits/type-pages',                      component:GridEdit,    props:{ config:pageConfig.visit_type_page },          meta:{title:i18n.t('MainMenu.settings-visits-type-pages')} },
    { name:'settings/visits/visit-type-edit',        path:'/settings/visits/visit-type/:id',             component:VisitTypeEdit,        meta:{title:i18n.t('objects.visit_type_workplace')} },
    { name:'settings/visits/visit-group-workplaces', path:'/settings/visits/visit-group-workplaces/:id', component:VisitGroupWorkplaces, meta:{title:i18n.t('objects.visit_group_det')} },
    // { name:'settings/visits/workplace-new',    path:'/settings/visits/workplace',                  component:WorkplaceEdit,        meta:{title:'Workplace Visit Types'} },
    { name:'settings/visits/visitor-groups', path:'/settings/visits/visitor-groups', component:GridEdit,      props:{ config:pageConfig.visit_visitor_group }, meta:{title:i18n.t('MainMenu.settings-visits-visitor-groups')} },
    { name:'settings/visits/workplace-edit', path:'/settings/visits/workplace/:id',  component:WorkplaceEdit,                                                  meta:{title:'Workplace Visit Types'} },
    { name:'settings/visits/routes',         path:'/settings/visits/routes',         component:GridEdit,      props:{ config:pageConfig.visit_route },         meta:{title:i18n.t('MainMenu.settings-visits-routes')} },
    { name:'settings/visits/route-new',      path:'/settings/visits/route',          component:VisitRouteEdit,                                                 meta:{title:i18n.t('object.visit_route')} },
    { name:'settings/visits/route-edit',     path:'/settings/visits/route/:id',      component:VisitRouteEdit,                                                 meta:{title:i18n.t('object.visit_route')} },
    { name:'settings/visits/queue-monitor',  path:'/settings/visits/queue-monitor',  component:VisitQueueMonitor,                                              meta:{title:i18n.t('MainMenu.settings-visits-queue-monitor')} },

    // settings-med
    // { path:'/settings/med', component:SubmenuPage, props:{ config:pageSubmenu.submenu_settings_med }, meta:{title:i18n.t('MainMenu.settings-med')} },
    { name:'settings/med/referral-types',         path:'/settings/med/referral-types',         component:GridEdit, props:{ config:pageConfig.med_referral_type }, meta:{title:i18n.t('MainMenu.settings-med-referral-types')} },
    // { name:'settings/med/referral-visit-types',   path:'/settings/med/referral-visit-types',   component:ReferralVisitTypes,                                     meta:{title:i18n.t('MainMenu.settings-med-referral-types')} },
    { name:'settings/med/templates',              path:'/settings/med/templates',              component:GridEdit, props:{ config:pageConfig.visit_template },    meta:{title:i18n.t('MainMenu.settings-med-templates')} },
    { name:'settings/med/event-types',            path:'/settings/med/event-types',            component:GridEdit, props:{ config:pageConfig.visit_event_type },  meta:{title:i18n.t('MainMenu.settings-med-event-types')} },
    { name:'settings/med/beds',                   path:'/settings/med/beds',                   component:GridEdit, props:{ config:pageConfig.med_bed },           meta:{title:i18n.t('MainMenu.settings-med-beds')} },

    // settings-finance
    { name:'settings/finance',                path:'/settings/finance',                component:SubmenuPage, props:{ config:pageSubmenu.submenu_settings_finance }, meta:{title:i18n.t('MainMenu.settings-finance')} },
    { name:'settings/finance/sources',        path:'/settings/finance/sources',        component:GridEdit,    props:{ config:pageConfig.fin_source },                meta:{title:i18n.t('MainMenu.settings-finance-sources')} },
    { name:'settings/finance/pricelists',     path:'/settings/finance/pricelists',     component:GridEdit,    props:{ config:pageConfig.fin_pricelist },             meta:{title:i18n.t('MainMenu.settings-finance-pricelists')} },
    { name:'settings/finance/prices',         path:'/settings/finance/prices',         component:GridEdit,    props:{ config:pageConfig.fin_price },                 meta:{title:i18n.t('MainMenu.settings-finance-prices')} },
    { name:'settings/finance/vats',           path:'/settings/finance/vats',           component:GridEdit,    props:{ config:pageConfig.fin_vat },                   meta:{title:i18n.t('MainMenu.settings-finance-vats')} },
    { name:'settings/finance/currencies',     path:'/settings/finance/currencies',     component:GridEdit,    props:{ config:pageConfig.fin_currency },              meta:{title:i18n.t('MainMenu.settings-finance-currencies')} },
    { name:'settings/finance/service-groups', path:'/settings/finance/service-groups', component:GridEdit,    props:{ config:pageConfig.fin_service_group },         meta:{title:i18n.t('MainMenu.settings-finance-service-groups')} },
    { name:'settings/finance/services',       path:'/settings/finance/services',       component:GridEdit,    props:{ config:pageConfig.fin_service },               meta:{title:i18n.t('MainMenu.settings-finance-services')} },
    { name:'settings/finance/sets',           path:'/settings/finance/sets',           component:GridEdit,    props:{ config:pageConfig.fin_set },                   meta:{title:i18n.t('MainMenu.settings-finance-service-sets')} },
    { name:'settings/finance/set',            path:'/settings/finance/set/:id',        component:FinSetEdit,                                                         meta:{title:i18n.t('MainMenu.settings-finance-service-sets')} },
    { name:'settings/finance/discounts',      path:'/settings/finance/discounts',      component:GridEdit,    props:{ config:pageConfig.fin_discount },              meta:{title:i18n.t('MainMenu.settings-finance-discounts')} },
    { name:'settings/finance/payment-types',  path:'/settings/finance/payment-types',  component:GridEdit,    props:{ config:pageConfig.fin_payment_type },          meta:{title:i18n.t('MainMenu.settings-finance-payment-types')} },

    // settings-navigation
    { name:'settings/navigation',             path:'/settings/navigation',             component:GridEdit, props:{ config:pageSubmenu.submenu_settings_navigation }, meta:{title:i18n.t('MainMenu.settings-navigation')} },
    { name:'settings/navigation/maps',        path:'/settings/navigation/maps',        component:GridEdit, props:{ config:pageConfig.nav_map },                      meta:{title:i18n.t('MainMenu.settings-navigation-maps')} },
    // { name:'settings/navigation/map-new',     path:'/settings/navigation/map',         component:MapEdit,                                                            meta:{title:i18n.t('object.nav_map')} },
    // { name:'settings/navigation/map-edit',    path:'/settings/navigation/map/:id',     component:MapEdit,                                                            meta:{title:i18n.t('object.nav_map')} },
    { name:'settings/navigation/points',      path:'/settings/navigation/points',      component:GridEdit, props:{ config:pageConfig.nav_point },                    meta:{title:i18n.t('MainMenu.settings-navigation-points')} },
    { name:'settings/navigation/connections', path:'/settings/navigation/connections', component:GridEdit, props:{ config:pageConfig.nav_connection },               meta:{title:i18n.t('MainMenu.settings-navigation-connections')} },

    // settings-questionaire
    { name:'settings/questionaire',       path:'/settings/questionaire',       component:GridEdit, props:{ config:pageSubmenu.submenu_settings_questionaire }, meta:{title:i18n.t('MainMenu.settings-questionaire')} },
    { name:'settings/questionaire/items', path:'/settings/questionaire/items', component:GridEdit, props:{ config:pageConfig.qst_item },                       meta:{title:i18n.t('MainMenu.settings-questionaire-items')} },

    // settings-system
    { name:'settings/system',             path:'/settings/system',             component:SubmenuPage,  props:{ config:pageSubmenu.submenu_settings_system }, meta:{title:i18n.t('MainMenu.settings-system')} },
    { name:'settings/system/sessions',    path:'/settings/system/sessions',    component:GridEdit,     props:{ config:pageConfig.core_session },             meta:{title:i18n.t('MainMenu.settings-system-sessions')} },
    { name:'settings/system/log',         path:'/settings/system/log',         component:SystemLog,                                                          meta:{title:i18n.t('MainMenu.settings-system-log')} },
    { name:'settings/system/errors',      path:'/settings/system/errors',      component:SystemErrors,                                                       meta:{title:i18n.t('MainMenu.settings-system-errors')} },
    { name:'settings/system/performance', path:'/settings/system/performance', component:GridEdit,     props:{ config:pageConfig.system_performance_sql },   meta:{title:i18n.t('MainMenu.settings-system-performance')} },

    // { path:'/', name:'home', component:Home },
    // // route level code-splitting
    // // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    // { path:'/about', name:'about', component:() => import(/* webpackChunkName:'about' */ '../views/About.vue') }
]

export default routes
