<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-11
-->

<template>
    <b-navbar toggleable="md" type="dark" variant="dark">

        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
        <b-navbar-brand href="/" class="navbar__logo">{{ $t('logo') }}</b-navbar-brand>
        <b-collapse is-nav id="nav_collapse">
            <b-navbar-nav>
                <template v-for="item in menuItems">
                    <!-- Item -->
                    <b-nav-item exact v-if="!item.child || item.child.length == 0" :key="item.code" :class="{'active':(currentMenu === item.code)}" :to="item.href">{{item.title}}</b-nav-item>
                    <!--b-nav-item exact v-if="item.child.length == 0" :key="item.code" :to="item.href">{{item.title}}</b-nav-item-->

                    <!-- Submenu1 -->
                    <b-nav-item-dropdown v-if="item.child && item.child.length > 0" :key="item.code" :class="{'bg-dark':true, 'active':(currentMenu === item.code)}">
                        <template slot="button-content" :class="{'bg-dark':true}">{{item.title}}</template>

                        <!-- 2nd level submenu-->
                        <template v-for="subitem in item.child" :class="{'bg-dark':true}">

                            <!-- Item -->
                            <b-dropdown-item v-if="!subitem.child || subitem.child.length == 0" :key="subitem.code" :to="subitem.href" :class="{'bg-dark':true}">{{subitem.title}}</b-dropdown-item>

                            <!-- Submenu2 -->
                            <li v-if="subitem.child && subitem.child.length > 0" :key="subitem.code" class="dropdown-submenu">
                                <a class="nav-link dropdown-item dropdown-toggle bg-dark" href="#">{{subitem.title}}</a>
                                <ul class="dropdown-menu bg-dark">
                                    <!-- 3rd level menu items -->
                                    <template v-for="sub2item in subitem.child" :class="{'bg-dark':true}">

                                        <!-- Item -->
                                        <b-dropdown-item v-if="!sub2item.child || sub2item.child.length == 0" :key="sub2item.code" :to="sub2item.href" :class="{'bg-dark':true}">{{sub2item.title}}</b-dropdown-item>
                                    </template>
                                </ul>
                            </li>
                        </template>
                    </b-nav-item-dropdown>
                </template>
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">
                <!-- <b-nav-item :to="'/help'">Help</b-nav-item> -->
                <b-nav-item v-if="workplaceName" @click="isDisplayWorkplaceList = true" :title="$t('app.changeWorkplace')">
                    {{ workplaceName }}
                </b-nav-item>
                <b-nav-item v-if="!workplaceName" @click="isDisplayWorkplaceList = true" :title="$t('app.changeWorkplace')">
                    <span class="red">{{ $t('lib.error.userHasNoWorkplace') }}</span>
                </b-nav-item>
                <b-nav-item-dropdown :text="currentUser.shortname" right class="bg-dark">
                    <b-dropdown-item @click="isDisplayModalChangePassword = true">{{ $t('app.changePassword') }}</b-dropdown-item>
                    <b-dropdown-item @click="isDisplayModalSessionInfo = true">{{ $t('app.sessionInfo') }}</b-dropdown-item>
                    <b-dropdown-item to="/logout">{{ $t('app.logout') }}</b-dropdown-item>
                </b-nav-item-dropdown>
                <!-- <b-nav-item @click="isDisplayModalChangePassword = true" :title="$t('component.UserSettings.userSettings')">
                    {{ currentUser.shortname }}
                </b-nav-item> -->
                <!-- <b-nav-item-dropdown :text="currentUser.shortname" right :class="{'bg-dark':true}">
                    <b-dropdown-item :to="'/account'" :class="{'bg-dark':true}">Settings</b-dropdown-item>
                    <b-dropdown-item :to="'/logout'" :class="{'bg-dark':true}">{{ $t('lib.logout') }}</b-dropdown-item>
                </b-nav-item-dropdown> -->
            </b-navbar-nav>
        </b-collapse>

        <ModalWindow v-if="isDisplayWorkplaceList" width="100%" max-height="90vh" @cancel="isDisplayWorkplaceList = false" :header="$t('object.core_workplace')" >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="workplaces" :config="workplaceGridConfig" @item-selected="workplaceSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>
        <UserChangePassword v-if="isDisplayModalChangePassword" @close-modal="isDisplayModalChangePassword = false" />
        <UserSessionInfo v-if="isDisplayModalSessionInfo" @close-modal="isDisplayModalSessionInfo = false" />

    </b-navbar>
</template>

<script>
    import $ from 'jquery';
    import { mapGetters, mapActions } from 'vuex';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import Grid from '@/components/lib/regions/Grid';
    import UserChangePassword from '@/components/lib/pages/UserChangePassword';
    import UserSessionInfo from '@/components/lib/pages/UserSessionInfo';

    export default {
        name: 'MainMenuHorizontal',

        components: { ModalWindow, Grid, UserChangePassword, UserSessionInfo },

        // =============== Props ===============
        props: ['menuItems', 'currentMenu'],

        // =============== Data ===============
        data() {
            return {
                isDisplayWorkplaceList: false,
                isDisplayModalChangePassword: false,
                isDisplayModalSessionInfo: false,
                workplaceGridConfig: {
                    apiUrl: null,
                    filter: null,
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'html', key:'workplace', header:'', $value: (entry) => {
                            // let wpName = this.workplaceName;
                            let nameArray = [];
                            // let nameArray = [ this.currentUser.shortname ];
                            // console.log(`DEBUG: ${this.$options.name}.workplaceName() this.$store.state.auth=`, this.$store.state.auth);
                            if (entry.dept_name) nameArray.push(entry.dept_name);
                            if (entry.speciality_name) nameArray.push(entry.speciality_name);
                            if (entry.room_name) nameArray.push(entry.room_name);
                            if (entry.device_name) nameArray.push(entry.device_name);
                            // console.log(`DEBUG: ${this.$options.name}.workplaceName() nameArray=`, nameArray);
                            let wpName = nameArray.join(' / ');
                            return `
                                <div class="card">
                                    <div class="card-body">
                                        <p class="card-text">${wpName}</p>
                                    </div>
                                </div>
                            `;
                            // `${entry.service_code} / ${entry.service_name}` 
                        } }
                        // { type:'string', key:'user_name', header:this.$t('app.employee') },
                        // { type:'string', key:'dept_name', header:this.$t('object.core_dept') },
                        // { type:'string', key:'speciality_name', header:this.$t('object.core_speciality') },
                        // { type:'string', key:'room_name', header:this.$t('object.core_room') },
                        // { type:'string', key:'device_name', header:this.$t('object.core_device') },
                        // { type:'string', key:'position', header:this.$t('attribute.common.position') },
                    ],
                    order: [ 'user_name' ],
                    displayToolbar: true,
                    row: { emit:'item-selected', style:'cursor: pointer;' },
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),
            workplaceName() {
                let nameArray = [];
                if (this.currentUser.currentWorkplace) {
                    // let nameArray = [ this.currentUser.shortname ];
                    // console.log(`DEBUG: ${this.$options.name}.workplaceName() this.$store.state.auth=`, this.$store.state.auth);
                    if (this.currentUser.currentWorkplace.dept_name)       nameArray.push(this.currentUser.currentWorkplace.dept_name);
                    if (this.currentUser.currentWorkplace.speciality_name) nameArray.push(this.currentUser.currentWorkplace.speciality_name);
                    if (this.currentUser.currentWorkplace.room_name)       nameArray.push(this.currentUser.currentWorkplace.room_name);
                    if (this.currentUser.currentWorkplace.device_name)     nameArray.push(this.currentUser.currentWorkplace.device_name);
                    // console.log(`DEBUG: ${this.$options.name}.workplaceName() nameArray=`, nameArray);
                }
                if (nameArray.length === 0) return null;
                return nameArray.join(' / ');
            },
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['getCurrentUser']),

            async workplaceSelected(workplace) {
                // console.log(`DEBUG: ${this.$options.name}.workplaceSelected(), workplace=`, workplace);
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/core_user_settings`, {code: 'user.current_workplace', value: workplace.id});
                    await this.getCurrentUser(); // to refresh workplace in navbar/menu
                    this.isDisplayWorkplaceList = false;
                    tools.displaySuccessSave(this, this.$t('component.ChangeWorkplace.workplaceChanged') + ': ' + this.workplaceName);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() menuItems=`, this.menuItems);
            // console.log('jwt=', this.$store.state.auth.jwt);
            // console.log('authenticated=', this.$store.state.authenticated);
            // console.log('state=', this.$store.state);
            // console.log('store=', this.$store);
            this.workplaceGridConfig.apiUrl = `final_user_workplaces/${this.currentUser.id}`;
            this.$nextTick(function() {
                //console.log('setting handler on mounted next tick...');
                $('.dropdown-submenu a.dropdown-toggle').on("click", function(e) {
                    //console.log('handler fired');

                    $('.dropdown-submenu a.dropdown-toggle').next().hide();
                    $(this).next().toggle();

                    e.stopPropagation();
                    e.preventDefault();
                });
            });
        }
    }
</script>

<style>
    /* .bg-dark .dropdown-menu {
        background-color: #343a40;
    }

    .navbar-dark .navbar-nav .dropdown-item {
        color: rgba(255,255,255,.5);
    }

    .dropdown-submenu {
        position: relative;
    }

    .dropdown-submenu .dropdown-menu {
        top: 0;
        left: 99%;
        margin-top: -1px;
    } */

    /* @media (min-width: 768px) */
    /* .dropdown-menu .dropdown-submenu .dropdown-toggle {
        padding-right: 1.5rem;
    }

    .dropdown-submenu a::after {
        transform: rotate(-90deg);
        position: absolute;
        right: 6px;
        top: .8em;
    } */
</style>
