<!--
    Author: Eduard Grebenyukov
    Date: 2021-09-19
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonPrint
                @click="printReferrals()"
                :disabled="referralsSelectedQty <= 0"
            >
                <i class="fas fa-print"></i> {{ $t('component.MedSampling.printReferrals') }}
            </ButtonPrint>
            <ButtonPrint
                :disabled="referralsSelectedQty <= 0"
                @click="printBarcode()"
            >
                <i class="fas fa-barcode"></i> {{ $t('component.MedSampling.printBarcode') }}
            </ButtonPrint>
            <!-- <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" /> -->
        </template>

        <VisitDescription />

        <div class="referrals__navbar-container">

            <VisitTabs currentTab="visit-sampling" />

            <div class="navbar">
                <ButtonSwitch
                    :value.sync="isDisplayRightSidebar"
                    :defaultValue="true"
                    :saveState="`${$options.name}.display_sidebar`"
                    :title="$t('app.hideShow')"
                />
            </div>
        </div>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div :class="false ? 'referrals__referrals col-sm-12 col-lg-8 col-xl-8' : 'referrals__referrals col'">
                        <Grid
                            ref="referralsGrid"
                            :key="$route.fullPath"
                            :source="referrals"
                            :config="referralGridConfig"
                            @edit-item="editReferral($event)"
                            @refreshed="referralsRefreshed()"
                            @item-selected="referralsSelected($event)"
                        >
                            <template #buttons>
                                <ButtonCustom
                                    v-if="true"
                                    buttonClass="btn-warning btn-sm"
                                    iconClass="fas fa-tags"
                                    :text="$t('component.MedSampling.setOrderCode')"
                                    :disabled="referralsSelectedQty <= 0"
                                    @click="setOrderCode()"
                                />
                                <ButtonCustom
                                    v-if="true"
                                    buttonClass="btn-outline-danger btn-sm"
                                    iconClass="fas fa-tags"
                                    :text="$t('component.MedSampling.unsetOrderCode')"
                                    :disabled="referralsSelectedQty <= 0"
                                    @click="unsetOrderCode()"
                                />
                                <ButtonCustom
                                    v-if="true"
                                    buttonClass="btn-warning btn-sm"
                                    iconClass="fas fa-share-square"
                                    :text="$t('component.MedSampling.sendOrders')"
                                    :disabled="referralsSelectedQty <= 0"
                                    @click="sendOrders()"
                                />
                                <ButtonCustom
                                    v-if="true"
                                    buttonClass="btn-outline-danger btn-sm"
                                    iconClass="fas fa-share-square fa-flip-horizontal"
                                    :text="$t('component.MedSampling.unsendOrders')"
                                    :disabled="referralsSelectedQty <= 0"
                                    @click="unsendOrders()"
                                />
                            </template>
                        </Grid>
                    </div>
                </div>
            </div>
        </form>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';

    import ButtonSwitch from '@/components/lib/controls/ButtonSwitch';
    import ButtonPrint from '@/components/lib/controls/ButtonPrint';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'MedSampling',

        components: {
            TemplatePageStandard, VisitDescription, VisitTabs,
            ButtonSwitch, ButtonPrint, ButtonCustom,
            Grid,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                referralId: null,
                referralsQty: 0,
                referralsSelectedQty: 0,
                buttons: [],
                msgItemModified: '',
                isDisplayRightSidebar: null,
                currentFormItem: null,
                currentAttribute: null,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.sampling') }),
                referrals: [],
                referralGridConfig: {
                    apiUrl: null,
                    // filter: null,
                    // title: this.$t('component.MedSampling.referrals'),
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', headStyle:'max-width:100px;' },
                        { type:'string', key:'service_code', header:this.$t('attribute.common.code'), readonly: true },
                        { type:'string', key:'service_name', header:this.$t('attribute.common.name'), readonly: true },
                        { type:'string', key:'laboratory_name', header:this.$t('component.MedSampling.laboratory_name'), readonly: true },
                        { type:'number', key:'rendered_current_qty', header:this.$t('attribute.common.qty'), readonly: true, sortable:false },
                        { type:'number', key:'duration', header:this.$t('component.MedSampling.duration'), readonly: true, sortable:false },
                        { type:'string', key:'sample_type_name', header:this.$t('component.MedSampling.sample_type'), readonly: true },
                        { type:'string', key:'container_name', header:this.$t('component.MedSampling.container'), readonly: true },
                        { type:'string', key:'lab_order_code', header:this.$t('component.MedSampling.lab_order_code'), readonly: true },
                        { type:'datetime', key:'sample_datetime', header: this.$t('component.MedSampling.sample_datetime'), readonly: true },
                        { type:'string', key:'status_name', header:this.$t('attribute.common.status'), readonly: true },
                        // { type:'datetime', key:'visit_datetime_of', header: this.$t('attribute.common.datetime_of'), readonly: true },
                        // { type:'date', key:'date_from', header: this.$t('attribute.common.date_from'), readonly: true },
                        // { type:'date', key:'date_to', header: this.$t('attribute.common.date_to'), readonly: true },
                        // { type:'string', key:'user_shortname', header:this.$t('object.core_user'), readonly: true },
                        // { type:'string', key:'speciality_name', header:this.$t('object.core_speciality'), readonly: true },
                        // { type:'number', key:'left_qty', header:this.$t('component.MedSampling.left_qty'), readonly: true, sortable:false },
                        // { type:'number', key:'left_qty', header:this.$t('attribute.common.qty'), readonly: true, sortable:false },
                        // { type:'checkbox', key:'is_cito', header:this.$t('attribute.med_referral.is_cito'), readonly: true, sortable:false },
                        {
                            type: 'icon', key:'is_cito', sortable:false, style:'color:red;',
                            header: this.$t('attribute.med_referral.is_cito'),
                            title: this.$t('attribute.med_referral.is_cito'),
                            $icon: (entry) => (entry.is_cito ? 'fas fa-exclamation-circle' : ''),
                        },
                    ],
                    row: {
                        $backgroundColor: (referral) => {
                            switch (referral.status_code) {
                                case 'sampled': return '#fbfcc9';
                                case 'sent': return '#c9d0fc';
                                case 'received': return '#9ca9fa';
                                case 'completed': return '#c9fcdc';
                                default: return '';
                            }
                        },
                    },
                    buttons: [ 'row-selector' ],
                    // order: [{ key:'time_of', direction:'desc' }],
                    order: [ 'referral_code' ],
                    displayToolbar: true,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                lastClient: 'lastClient',
                lastVisit: 'lastVisit',
                // currencyAccounting: 'currencyAccounting',
            }),
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                // console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.$refs.referralsGrid.qty=`, this.$refs.referralsGrid.getTotalRowsQty());
                if (this.referralsSelectedQty) {
                    this.buttons = ['print'];
                } else {
                    this.buttons = [];
                }
            },

            referralsRefreshed() {
                this.referralsQty = this.$refs.referralsGrid.getTotalRowsQty();
                this.referralsSelectedQty = this.$refs.referralsGrid.getSelectedRows().length;
                // console.log(`DEBUG: ${this.$options.name}.referralsModified(), this.$refs.services.qty=`, this.$refs.services.getTotalRowsQty());
                this.checkButtons();
            },

            referralsSelected() {
                this.referralsSelectedQty = this.$refs.referralsGrid.getSelectedRows().length;
            },

            async setOrderCode() {
                const selectedReferrals = this.$refs.referralsGrid.getSelectedRows();
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/set-lab-order-code/${this.visitId}`, selectedReferrals);
                    await this.$refs.referralsGrid.refresh();
                    tools.displaySuccessSave(this.$parent);
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            async unsetOrderCode() {
                const selectedReferrals = this.$refs.referralsGrid.getSelectedRows();
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/unset-lab-order-code/${this.visitId}`, selectedReferrals);
                    await this.$refs.referralsGrid.refresh();
                    tools.displaySuccessSave(this.$parent);
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            async sendOrders() {
                const selectedReferrals = this.$refs.referralsGrid.getSelectedRows();
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/send-lab-samples/${this.visitId}`, selectedReferrals);
                    await this.$refs.referralsGrid.refresh();
                    tools.displaySuccessSave(this.$parent);
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            async unsendOrders() {
                const selectedReferrals = this.$refs.referralsGrid.getSelectedRows();
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/unsend-lab-samples/${this.visitId}`, selectedReferrals);
                    await this.$refs.referralsGrid.refresh();
                    tools.displaySuccessSave(this.$parent);
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            async printReferrals() {
                try {
                    // console.log(`DEBUG: ${this.$options.name}.printReferrals()`);
                    let services = this.$refs.referralsGrid.getAllRows();
                    let sumTotal = services.reduce(((sum, item) => sum + item.sum_payment), 0);
                    let currencyCode = (await tools.getSetting(this, 'currency_accounting')).value;
                    // console.log(`DEBUG: ${this.$options.name}.printReferrals() currencyCode=`, currencyCode);
                    let filterStringCurrency = tools.getFilterString({ where:[{ code:currencyCode }]});
                    let currency = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_currency${filterStringCurrency}`)).data.rows[0];
                    // let filterStringBill = tools.getFilterString({ where:[{ visit_rec_id:this.visitId }]});
                    // let bill = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_bill${filterStringBill}`)).data.rows[0];

                    // let currency = this.currencyAccounting;
                    // console.log(`DEBUG: ${this.$options.name}.printReferrals() currency=`, currency);
                    let o = {
                        // taskId: `print.${this.$options.name}`,
                        template: 'lab_orders',
                        referrals: {
                            visit_date: moment(this.lastVisit.time_of).format('L'),
                            total_sum: sumTotal,
                            total_sum_company: 0,
                            total_sum_payer: 0,
                            total_sum_patient: sumTotal,
                            visit_rec_id: this.visitId,
                            currency: currency.name_short,
                            services: services
                        },
                        visit: { visit_rec_id: this.visitId },
                    };
                    // console.log(`DEBUG: ${this.$options.name}.printReferrals() o=`, o, ', typeof o.referrals.sumTotal=', typeof o.referrals.sumTotal);
                    tools.printForm(this, o);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async printBarcode() {},
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this=`, this);
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            this.referralGridConfig.apiUrl = `referrals-for-sampling/${this.visitId}`;
            // this.referralGridConfig.filter = { where:[{ visit_rec_id:this.visitId }]};
        },

        async mounted() {
            // await this.getReferrals();
            this.checkButtons();
        },
    }
</script>

<style>
    .referrals__navbar-container {
        display: flex;
    }
    .referrals__referrals {
        overflow-x: auto;
    }
    /* .referrals__right-sidebar {
    } */
    /* .referrals__referrals .grid-renders.grid-container .btn-toolbar {
        min-width: 810px;
    } */
</style>
