<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-13
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <!-- <ButtonClose @click="closePressed()" /> -->
            <ButtonSave @click="saveData()" />
            <!-- <ButtonDelete @click="askDeleteItem()" :disabled="!isEditable" /> -->
        </template>

        <VisitDescription />

        <VisitTabs currentTab="visit-edit" />

        <div id="page-content" class="form-float">
            <div v-if="false" class="form-row">
                <div class="col-sm">
                    <FieldSelect
                        ref="workplace_id"
                        id="workplace_id"
                        :label="$t('object.core_workplace')"
                        :value.sync="editFormData.workplace_id"
                        source='v_workplace'
                        keyLabel='workplace_name'
                        disabled
                        @modified="itemModified"
                    />
                </div>
                <!-- <div class="col-sm">
                    <FieldSelect
                        ref="core_workplace_id"
                        id="core_workplace_id"
                        :label="$t('object.core_workplace')"
                        :value.sync="editFormData.core_workplace_id"
                        :source='`v_workplace?filter={"where":[{"core_user_id":${currentUser.id}}]}`'
                        keyLabel='workplace_name'
                        disabled
                        @modified="itemModified"
                    />
                </div> -->
                <!-- <div class="col-sm">
                    <FieldSelect
                        ref="workplace_final_id"
                        id="workplace_final_id"
                        :label="$t('object.core_workplace')"
                        :value.sync="editFormData.workplace_final_id"
                        :source='`v_workplace?filter={"where":[{"core_user_id":${currentUser.id}},"is_final":true]}`'
                        keyLabel='workplace_name'
                        disabled
                        @modified="itemModified"
                    />
                </div> -->
            </div>
            <div v-if="false" class="form-row">
                <div class="col-sm col-md-6">
                    <FieldSelect
                        id='visit_type_id'
                        :label="$t('object.visit_type')"
                        :value.sync="editFormData.visit_type_id"
                        :source="editFormData.workplace_id?`workplace-visit_type/${editFormData.workplace_id}`:[]"
                        showEmptyRow
                        disabled
                        @modified="itemVisitTypeModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldDatetime
                        id='time_of'
                        :label="$t('app.time')"
                        :value.sync="editFormData.time_of"
                        required
                        disabled
                        @modified="itemModified"
                    />
                </div>
                <div class="col-sm col-md-3">
                    <FieldNumber
                        id='duration'
                        :label="$t('attribute.common.duration')"
                        :value.sync="editFormData.duration"
                        @modified="itemModified"
                        required
                        disabled
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="col-sm">
                    <FieldSelect
                        ref="visit_event_id"
                        id="visit_event_id"
                        :label="$t('object.visit_event')"
                        :value.sync="editFormData.visit_event_id"
                        :source='`v_event?filter={"where":[{"core_client_id":${editFormData.core_client_id}}]}`'
                        showEmptyRow
                        disabled
                        @modified="itemModified"
                    />
                </div>
            </div>
            <div v-if="false" class="form-row">
                <div class="col-sm">
                    <FieldText
                        :label="$t('attribute.core_person.commentary')"
                        :value.sync="editFormData.commentary"
                        disabled
                        @modified="itemModified"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <Grid
                        ref="referralsGrid"
                        :config="referralsGridConfig"
                        @refreshed="gridRefreshed()"
                    >
                        <!-- <template #buttons>
                            <ButtonCustom
                                :disabled="!isValidated"
                                buttonClass="btn-primary btn-sm ml-1"
                                :text="$t('lib.buttons.ok')"
                                @click="saveData"
                            />
                        </template> -->
                    </Grid>
                </div>
                <!-- <div class="col-sm-12 col-xl-4"> -->
                    <!-- Right sidebar -->
                    <!-- <StandardRegion :title="$t('app.details')">
                    </StandardRegion> -->
                <!-- </div> -->
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { mapActions } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';
    import Grid from '@/components/lib/regions/Grid';

    import ButtonSave from '@/components/lib/controls/ButtonSave';

    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldDatetime from '@/components/lib/controls/FieldDatetime';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldText from '@/components/lib/controls/FieldText';

    export default {
        name: 'VisitEdit',
        components: {
            TemplatePageStandard, VisitDescription, VisitTabs,
            ButtonSave,
            FieldSelect, FieldDatetime, FieldNumber, FieldText,
            Grid,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                buttons: [],
                isModified: false,
                msgItemModified: '',
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.visit-edit') }),
                editFormData: {
                    // id: null,
                    base_visit_id: null,
                    core_workplace_id: null,
                    workplace_final_id: null,
                    workplace_id: null,
                    core_client_id: null,
                    // lastname: null,
                    // firstname: null,
                    // patronymic: null,
                    visit_type_id: null,
                    visit_event_id: null,
                    time_of: null,
                    duration: null,
                    commentary: null,
                },
                oldFormData: {},
                referralsGridConfig: {
                    apiUrl: null,
                    // filter: null,
                    title: this.$t('component.VisitEdit.referrals'),
                    // readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', headStyle:'max-width:100px;' },
                        { type:'string', key:'referral_code', header:this.$t('attribute.common.code'), readonly: true },
                        { type:'string', key:'referral_name', header:this.$t('attribute.common.name'), readonly: true },
                        { type:'datetime', key:'visit_datetime_of', header: this.$t('attribute.common.datetime_of'), readonly: true },
                        { type:'date', key:'date_from', header: this.$t('attribute.common.date_from'), readonly: true },
                        { type:'date', key:'date_to', header: this.$t('attribute.common.date_to'), readonly: true },
                        { type:'string', key:'user_shortname', header:this.$t('object.core_user'), readonly: true },
                        { type:'string', key:'speciality_name', header:this.$t('object.core_speciality'), readonly: true },
                        { type:'number', key:'assigned_qty', header:this.$t('component.VisitEdit.assigned_qty'), readonly: true, sortable:false },
                        { type:'number', key:'rendered_qty', header:this.$t('component.VisitEdit.rendered_qty'), readonly: true, sortable:false },
                        { type:'number', key:'left_qty', header:this.$t('component.VisitEdit.left_qty'), readonly: true, sortable:false },
                        // { type:'number', key:'left_qty', header:this.$t('attribute.common.qty'), readonly: true, sortable:false },
                        // { type:'checkbox', key:'is_cito', header:this.$t('attribute.med_referral.is_cito'), readonly: true, sortable:false },
                        {
                            type: 'icon',
                            key: 'is_cito',
                            sortable: false,
                            style: 'color:red;',
                            header: this.$t('attribute.med_referral.is_cito'),
                            title: this.$t('attribute.med_referral.is_cito'),
                            $icon: (entry) => (entry.is_cito ? 'fas fa-exclamation-circle' : ''),
                        },
                    ],
                    row: {
                        $backgroundColor: (referral) => {
                            if (referral.is_visit_reply) {
                                return '#c9fcdc';
                            }
                            return '';
                        },
                    },
                    buttons: [ 'row-selector' ],
                    // order: [{ key:'time_of', direction:'desc' }],
                    order: [ 'referral_code' ],
                    displayToolbar: true,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                lastClient: 'lastClient',
                lastVisit: 'lastVisit',
            }),
            // apiUrl() { return `${this.baseUrl}/${componentUrl}` },
            displayGrids() {return typeof this.editFormData.id !='undefined' },
        },

        // =============== Methods ===============
        methods: {
            // checkButtons() {
            //     if (this.itemId) {
            //         this.buttons = ['save', 'delete', 'close'];
            //     } else {
            //         this.buttons = ['save', 'close'];
            //     }
            // },

            async getReferrals() {
                if (this.visitId) {
                    this.referralsGridConfig.apiUrl = `referrals-for-visit/${this.visitId}`;
                }
            },

            async getData() {
                try {
                    this.editFormData = (await this.authenticatedAxios.get(`${this.baseUrl}/v_visit/${this.visitId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.editFormData=`, this.editFormData);
                    this.isModified = false;
                    this.msgItemModified = '';
                    await this.getReferrals();
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            async saveData() {
                await this.saveReferrals();
            },

            async saveReferrals() {
                try {
                    let referrals = this.$refs.referralsGrid.getAllRows();
                    // console.log(`DEBUG: ${this.$options.name}.saveReferrals() getAllRows=`, referrals);
                    await this.authenticatedAxios.post(`${this.baseUrl}/referrals-for-visit/${this.visitId}`, referrals);
                    await this.$refs.referralsGrid.refresh();
                    tools.displaySuccessSave(this.$parent);
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            async itemVisitTypeModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemVisitTypeModified visit_type_id=', this.editFormData.visit_type_id);
                if (this.editFormData.visit_type_id) {
                    try {
                        // let filterString = tools.getFilterString({ where:[{ id:this.editFormData.visit_type_id }]});
                        let response = await this.authenticatedAxios.get(`${this.baseUrl}/visit_type/${this.editFormData.visit_type_id}`);
                        // console.log(`DEBUG: ${this.$options.name}.itemVisitTypeModified data=', response.data);
                        this.editFormData.duration = response.data.duration;
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                this.itemModified();
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified()`);
                if (JSON.stringify(this.oldFormData) === JSON.stringify(this.editFormData)) {
                    this.isModified = false;
                    this.msgItemModified = '';
                } else {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                }
            },

            gridRefreshed() {
                // console.log(`DEBUG: ${this.$options.name}.gridRefreshed()`);
                for (let referral of this.$refs.referralsGrid.getAllRows()) {
                    if (referral.is_visit_reply) {
                        referral.$isSelected = true;
                        // this.$refs.referralsGrid.selectItem(referral);
                    }
                }
            }

        },

        // =============== Life cycle ===============
        beforeMount() {
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            // console.log(`DEBUG: Visits.beforeMount(), this.clientId=`, this.clientId);
            // console.log(`DEBUG: Visits.beforeMount(), this.visitId=`, this.visitId);
        },
        async mounted() {
            if (this.visitId) {
                await this.getData();
            }
        },
    }
</script>

<style>
</style>
