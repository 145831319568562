<!--
    Author: Eduard Grebenyukov
    Date: 2020-02-03
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" :disabled="!isSaveEnabled" @click="saveData()" />
            <ButtonPrint v-if="buttons.includes('printContract') && printforms.includes('client_contract')" @click="printContract()">
                <i class="fas fa-print"></i> {{ $t('component.ClientEdit.printContract') }}
            </ButtonPrint>
            <ButtonPrint v-if="buttons.includes('printTitle') && printforms.includes('client-title')" @click="printTitle()">
                <i class="fas fa-print"></i> {{ $t('component.ClientEdit.printTitle') }}
            </ButtonPrint>
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>
        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div :class="isDisplayRightSidebar ? 'col-sm-12 col-xl-8' : 'col'">
                        <ModalWindow v-if="isDisplayWarningDelete" minWidth="300px" :buttons="['ok', 'cancel']" @ok="processDeleteItem(true)" @cancel="processDeleteItem(false)" >
                            {{ $t('component.ClientEdit.confirmDelete') }}
                        </ModalWindow>

                        <div class="form-row">
                            <div class="col-sm">
                                <FieldString
                                    id='lastname'
                                    :label="$t('attribute.core_person.lastname')"
                                    :value.sync="editFormData.lastname"
                                    required
                                    :errorMessage="msgEmptyLastname"
                                    labelPosition="above"
                                    @modified="itemModified"
                                />
                                <!-- @change="onChange" -->
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    id='firstname'
                                    :label="$t('attribute.core_person.firstname')"
                                    :value.sync="editFormData.firstname"
                                    required
                                    :errorMessage="msgEmptyFirstname"
                                    labelPosition="above"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    id='patronymic'
                                    :label="$t('attribute.core_person.patronymic')"
                                    :value.sync="editFormData.patronymic"
                                    labelPosition="above"
                                    @modified="itemModified"
                                />
                            </div>
                            <div v-if="!editFormData.id" class="col-sm">
                                <FieldString id='code' :label="$t('attribute.core_phone.phone_num')" :value.sync="phoneNum" @modified="itemModified" labelPosition="above" />
                            </div>
                        </div>
                        <div class="form-row">
                            <div v-if="editFormData.id" class="col-sm">
                                <FieldString id='code' :label="$t('attribute.common.code')" :value.sync="editFormData.code" @modified="itemModified" labelPosition="above" />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    :label="$t('attribute.core_person.social_number')"
                                    :value.sync="editFormData.social_number"
                                    placeholder="XXX-XXX-XXX YY"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    :label="$t('attribute.core_person.upn')"
                                    :value.sync="editFormData.upn"
                                    :maxlength="16"
                                    :errorMessage="checkUpnErrorMessage"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 col-lg-4">
                                <FieldSelect
                                    :label="$t('attribute.core_person.sex')"
                                    :value.sync="editFormData.sex"
                                    :source="sexKind"
                                    keyId="code"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <FieldDate
                                    :label="$t('attribute.core_person.dob')"
                                    :value.sync="editFormData.dob"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <FieldEmail
                                    :label="$t('attribute.core_person.email')"
                                    :value.sync="editFormData.email"
                                    placeholder="name@example.com"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6 col-lg-5">
                                <FieldSelect
                                    :label="$t('attribute.core_person.document_type_id')"
                                    :value.sync="editFormData.document_type_id"
                                    source="enum/document_type"
                                    order="sort_order"
                                    showEmptyRow
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-md-3 col-lg-2">
                                <FieldString
                                    :label="$t('attribute.core_person.document_serial')"
                                    :value.sync="editFormData.document_serial"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-md-3 col-lg-2">
                                <FieldString
                                    :label="$t('attribute.core_person.document_num')"
                                    :value.sync="editFormData.document_num"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-md-4 col-lg-3">
                                <FieldDate
                                    :label="$t('attribute.core_person.document_date')"
                                    :value.sync="editFormData.document_date"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-md col-lg">
                                <FieldString
                                    :label="$t('attribute.core_person.document_issuer')"
                                    :value.sync="editFormData.document_issuer"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm-6">
                                <FieldString :label="$t('attribute.core_person.job_place')" :value.sync="editFormData.job_place" @modified="itemModified" />
                            </div>
                            <div class="col-sm-6">
                                <FieldString :label="$t('attribute.core_person.job_position')" :value.sync="editFormData.job_position" @modified="itemModified" />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldString :label="$t('attribute.core_person.pob')" :value.sync="editFormData.pob" @modified="itemModified" />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText :label="$t('attribute.core_person.commentary')" :value.sync="editFormData.commentary" @modified="itemModified" />
                            </div>
                        </div>
                        <div class="row" v-if="editFormData.id">
                            <div class="col">
                                <ClientBeneficiary :clientId="itemId" />
                            </div>
                        </div>
                        <div class="row" v-if="editFormData.id">
                            <div class="col">
                                <ClientAccounts :clientId="itemId" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-4">
                        <!-- Right sidebar -->
                        <div v-if="editFormData.id" class="row">
                            <div class="col">
                                <PersonAddresses ref="addresses" :personId="editFormData.client_person_id" />
                            </div>
                        </div>
                        <div v-if="editFormData.id" class="row">
                            <div class="col">
                                <PersonPhones ref="phones" :personId="editFormData.client_person_id" />
                            </div>
                        </div>
                        <div v-if="editFormData.id && otherIdsArray.length > 0" class="row" >
                            <div class="col">
                                <div class="btn-toolbar" style="padding: 0 4px 4px 0;" role="toolbar">
                                    <div class="h6">{{ $t('app.otherIds') }}</div>
                                </div>
                                <Grid ref="otherIds" :config="otherIdsGridConfig" :source="otherIdsArray" />
                            </div>
                        </div>
                        <div v-if="editFormData.id" class="row" >
                            <div class="col">
                                <ClientBuildings ref="buildings" :clientId="itemId" />
                            </div>
                        </div>
                        <div class="row" >
                            <div class="col">
                                <SimilarOfClient ref="similarClients" :clientToCompare="editFormData" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonPrint from '@/components/lib/controls/ButtonPrint';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldString from '@/components/lib/controls/FieldString';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldEmail from '@/components/lib/controls/FieldEmail';
    import FieldText from '@/components/lib/controls/FieldText';

    import Grid from '@/components/lib/regions/Grid';

    import ClientBeneficiary from './ClientBeneficiary';
    import ClientAccounts from './ClientAccounts';
    import PersonAddresses from './PersonAddresses';
    import PersonPhones from './PersonPhones';
    import ClientBuildings from './ClientBuildings';
    import SimilarOfClient from './SimilarOfClient';

    export default {
        name: 'ClientEdit',

        components: {
            TemplatePageStandard, ModalWindow,
            ButtonClose, ButtonSave, ButtonPrint, ButtonDelete,
            FieldString, FieldSelect, FieldDate, FieldEmail, FieldText,
            Grid,
            ClientBeneficiary, ClientAccounts, PersonAddresses, PersonPhones, ClientBuildings,
            SimilarOfClient,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                printforms: [],
                debounceTimeout: 600,
                debounce: null,
                msgItemModified: '',
                checkUpnErrorMessage: null,
                editFormData: {
                    client_person_id: null,
                    lastname: null,
                    firstname: null,
                    patronymic: null,
                    code: null,
                    sex: null,
                    dob: null,
                    social_number: null,
                    upn: null,
                    email: null,
                    document_type_id: null,
                    document_serial: null,
                    document_num: null,
                    document_date: null,
                    document_issuer: null,
                    job_place: null,
                    job_position: null,
                    pob: null,
                    commentary: null,
                },
                phoneNum: null,
                visitId: null,
                emptyFormData: {},
                oldFormData: {},
                isDisplayWarningDelete: false,
                isDisplayRightSidebar: true,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.clients'), to: '/clients' },
                    { text: this.$t('object.core_client') },
                ],
                otherIdsArray: [],
                otherIdsGridConfig: {
                    readonly: true,
                    columns: [
                        // { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        { type:'select', key:'core_extsystem_id', header:this.$t('object.core_extsystem'), source:'core_extsystem' },
                        { type:'string', key:'external_id', header:this.$t('component.ClientEdit.ehrNum') },
                    ],
                    order: [ 'core_extsystem_id' ],
                    displayToolbar: false,
                    displayPagination: false,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                sexKind: 'sexKind',
                currentUser: 'currentUser',
            }),
            apiUrl() { return `${this.baseUrl}/core_client` },
            isSaveEnabled() {
                return (
                    !tools.isStringEmpty(this.editFormData.lastname) &&
                    !tools.isStringEmpty(this.editFormData.firstname)
                    // !tools.isStringEmpty(this.editFormData.sex) &&
                    // !tools.isStringEmpty(this.editFormData.dob)
                );
            },
            msgEmptyLastname() { return tools.isStringEmpty(this.editFormData.lastname) ? this.$t('lib.validation.fieldIsEmpty') : null },
            msgEmptyFirstname() { return tools.isStringEmpty(this.editFormData.firstname) ? this.$t('lib.validation.fieldIsEmpty') : null },
        },

        // =============== Validations ===============
        // validations: {
        //     editFormData: {
        //         username: { required },
        //         email: { required }
        //     }
        // },

        // =============== Watch ===============
        watch: {
            $route(to, from) {
                // react to route changes... 
                if (to !== from) {
                    // location.reload();
                    // console.log(`DEBUG: ${this.$options.name}.watch.$route this.$route.params=`, this.$route.params);
                    this.isDisplayWarningClose = false;
                    this.initForm();
                    // this.findDuplicates(); // to clear duplicates on previous page
                    // if (!this.itemId) location.reload();
                    //if (this.itemId) await this.setLastClientId(this.itemId);
                    
                }
            }
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['setLastClientId']),

            async initForm() {
                if (this.$route.params.id) {
                    this.itemId = parseInt(this.$route.params.id);
                    await this.getData();
                } else {
                    this.itemId = null;
                    this.editFormData = JSON.parse(JSON.stringify(this.emptyFormData));
                    this.oldFormData = JSON.parse(JSON.stringify(this.emptyFormData));
                    this.editFormData.lastname = this.$route.params.lastname ? this.$route.params.lastname : null;
                    this.editFormData.firstname = this.$route.params.firstname ? this.$route.params.firstname : null;
                    this.editFormData.patronymic = this.$route.params.patronymic ? this.$route.params.patronymic : null;
                    this.phoneNum = this.$route.params.phoneNum ? this.$route.params.phoneNum : null;
                    this.visitId = this.$route.params.visitId ? parseInt(this.$route.params.visitId) : null;
                    if (
                        this.$route.params.lastname ||
                        this.$route.params.firstname ||
                        this.$route.params.patronymic ||
                        this.$route.params.phoneNum
                    ) {
                        this.itemModified();
                    }
                    // this.findDuplicates();
                }
                this.checkButtons();
            },

            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'printContract', 'printTitle', /* 'delete', */ 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            async getOtherIds() {
                let filter = tools.getFilterString({ where:[
                    { tablename: 'ehr_num' },
                    { row_id: this.itemId },
                ]});
                this.otherIdsArray = (await this.authenticatedAxios.get(`${this.baseUrl}/core_extdata/${filter}`)).data.rows;
            },

            async getData() {
                try {
                    let response = await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`);
                    // console.log(`DEBUG: ${this.$options.name}.getData() data=`, response.data);
                    this.editFormData = response.data;
                    this.isModified = false;
                    this.msgItemModified = '';
                    this.breadcrumb[this.breadcrumb.length - 1].text = response.data.fullname;
                    this.itemId = response.data.id;
                    this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                    // this.setGridSettings();
                    // this.isDisplayRightSidebar = true; // display allways
                    this.checkButtons();
                    this.getOtherIds(); // no need to await
                    this.clearDuplicates();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update client
                            // let response = await this.authenticatedAxios.patch(this.apiUrl, this.editFormData);
                            await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData);
                            tools.displaySuccessSave(this);
                            await this.getData();
                        } else {
                            // New client created
                            // console.log(`DEBUG: ${this.$options.name}.saveData() new client`);
                            let responseClient = await this.authenticatedAxios.post(this.apiUrl, this.editFormData);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() responseClient.data=`, responseClient.data);
                            this.itemId = responseClient.data.id;
                            await this.setLastClientId(this.itemId);
                            // this.editFormData.id = this.itemId;
                            tools.displaySuccessSave(this);
                            // this.isDisplayRightSidebar = true; // display allways
                            await this.getData();
                            if (!tools.isStringEmpty(this.phoneNum)) {
                                let phoneData = {
                                    core_person_id:this.editFormData.client_person_id,
                                    phone_type_id:null,
                                    phone_num:this.phoneNum,
                                    is_notification:true, //??? true or false?!!!
                                };
                                await this.authenticatedAxios.post(`${this.baseUrl}/core_phone`, phoneData);
                                await this.$refs.phones.refresh();
                            }
                            if (this.visitId) {
                                let visitData = {
                                    core_client_id:this.editFormData.id,
                                }
                                await this.authenticatedAxios.put(`${this.baseUrl}/visit_rec/${this.visitId}`, visitData);
                            }
                            // this.isModified = false;
                            this.$router.replace({ name: 'client-edit', params: { id:this.itemId } });
                            return;
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.$refs.addresses=`, this.$refs.addresses);
            },

            debounceSearch() {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.findDuplicates();
                }, this.debounceTimeout);
            },

            async findDuplicates() {
                // console.log(`DEBUG: ${this.$options.name}.findDuplicates(), this.editFormData=`, this.editFormData);
                if (this.$refs.similarClients) this.$refs.similarClients.refresh();
            },

            async clearDuplicates() {
                if (this.$refs.similarClients) this.$refs.similarClients.clear();
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified(), this.editFormData=`, this.editFormData);
                // console.log(`DEBUG: ${this.$options.name}.itemModified(), this.oldFormData=`, this.oldFormData);
                if (JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData)) {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.isModified = false;
                    this.msgItemModified = '';
                }
                this.debounceSearch();
                if (this.editFormData.upn) {
                    const checkUpnResult = tools.checkUPN(this.editFormData.upn);
                    this.checkUpnErrorMessage = checkUpnResult.status === 'error' ? this.$t(`lib.messages.${checkUpnResult.reason}`) : null;
                    // console.log(`DEBUG: ${this.$options.name}.itemModified(), checkUpnResult=`, checkUpnResult);
                } else {
                    this.checkUpnErrorMessage = null;
                }
            },

            askDeleteItem() {
                this.isDisplayWarningDelete = true;
            },
            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },
            // cancelDeleteItem() { this.isDisplayWarningDelete = false },

            async getPrintForms() {
                try {
                    this.printforms = (await this.authenticatedAxios.get(`${this.baseUrl}/core_printform`)).data.rows.map( item => item.code );
                    // console.log(`DEBUG: ${this.$options.name}.getPrintForms() this.printforms=`, this.printforms);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async printContract() {
                try {
                    // console.log(`DEBUG: ${this.$options.name}.printContract()`);
                    let obj = {
                        template: 'client_contract',
                        data: {},
                    };
                    tools.printForm(this, obj);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async printTitle() {
                try {
                    let obj = {
                        template: 'client-title',
                        client: {
                            core_client_id: this.itemId,
                        },
                        workplace: {
                            core_workplace_id: this.currentUser.currentWorkplace.id,
                        },
                    };
                    tools.printForm(this, obj);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // this.editFormData = JSON.parse(JSON.stringify(this.emptyFormData));
            this.emptyFormData = JSON.parse(JSON.stringify(this.editFormData));
            // await this.initForm();
            // if (this.itemId) await this.setLastClientId(this.itemId);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.$route.params=`, this.$route.params);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.itemId=`, this.itemId);
            this.getPrintForms(); // no necessary to wait
            await this.initForm();
        },
    }
</script>

<style>
</style>
