<!--
    Author: Eduard Grebenyukov
    Date: Date: 2021-04-05
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >
        <template #buttons>
            <!-- <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" /> -->
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
            <ButtonCustom
                v-if="selectedItem && selectedItem.id"
                buttonClass="btn-warning btn-sm ml-1"
                iconClass="fas fa-file-medical fa-fw"
                :text="$t('component.Services.addService')"
                @click="addService"
            />
            <ButtonCustom
                v-if="selectedItem && selectedItem.id && selectedItem.isGroup"
                buttonClass="btn-warning btn-sm ml-1"
                iconClass="fas fa-folder-plus fa-fw"
                :text="$t('component.Services.addSubgroup')"
                @click="addSubgroup"
                @ctrl-click="addRootGroup"
            />
            <ButtonCustom
                buttonClass="btn-warning btn-sm ml-1"
                iconClass="fas fa-folder-plus fa-fw"
                :text="$t('component.Services.addRootGroup')"
                @click="addRootGroup"
            />
        </template>

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm-12 col-xl-4">
                    <!-- Left tree bar -->
                    <StandardRegion :title="$t('attribute.common.services')" regionClass="sticky-4">
                        <div class="services-template__wrapper">
                            <TreeView
                                ref="treeView"
                                :treeData="serviceTree"
                                :selectedItem="selectedItem"
                                :displayEmptyGroups="true"
                                folderStyle="font-weight: bold;"
                                selectedStyle="background-color:#cde8ff;"
                                :bottom="60"
                                @select="itemSelected($event)"
                            />
                        </div>
                    </StandardRegion>
                </div>
                <div class="col-sm-12 col-xl-8">
                    <template v-if="selectedItem && selectedItem.isGroup">
                        <StandardRegion :title="$t('object.fin_service_group')" regionBodyClass="p-2">
                            <div v-if="false" class="form-row">
                                <div class="col-sm">
                                    <FieldNumber
                                        :label="$t('attribute.common.id')"
                                        :value.sync="editFormServiceGroup.id"
                                        readonly
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-sm">
                                    <FieldPopupList
                                        id="fin_service_group__parent_id"
                                        :label="$t('attribute.common.parent_id')"
                                        :value.sync="editFormServiceGroup.parent_id"
                                        source="fin_service_group"
                                        readonly
                                        showEmptyRow
                                        maxWidth="1000px"
                                        :listConfig="serviceGroupListConfig"
                                        @modified="itemModified"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-sm">
                                    <FieldString
                                        :label="$t('attribute.common.code')"
                                        :value.sync="editFormServiceGroup.code"
                                        @modified="itemModified"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-sm">
                                    <FieldString
                                        :label="$t('attribute.common.name')"
                                        :value.sync="editFormServiceGroup.name"
                                        required
                                        @modified="itemModified"
                                    />
                                </div>
                            </div>
                        </StandardRegion>
                    </template>
                    <template v-if="selectedItem && !selectedItem.isGroup">
                        <StandardRegion :title="$t('object.fin_service')" regionBodyClass="p-2">
                            <div class="form-row">
                                <div class="col-sm">
                                    <FieldPopupList
                                        id="fin_service_group_id"
                                        :label="$t('object.fin_service_group')"
                                        :value.sync="editFormService.fin_service_group_id"
                                        :listConfig="serviceGroupListConfig"
                                        source="fin_service_group"
                                        required
                                        readonly
                                        maxWidth="1000px"
                                        @modified="itemModified"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-sm">
                                    <FieldString
                                        :label="$t('attribute.common.code')"
                                        :value.sync="editFormService.code"
                                        required
                                        @modified="itemModified"
                                    />
                                </div>
                                <div class="col-sm">
                                    <FieldString
                                        :label="$t('attribute.common.code_public')"
                                        :value.sync="editFormService.code_public"
                                        @modified="itemModified"
                                    />
                                </div>
                                <div class="col-sm">
                                    <FieldSelect
                                        :label="$t('enum.service_category')"
                                        :value.sync="editFormService.service_category_id"
                                        source="enum/service_category"
                                        required
                                        @modified="itemModified"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-sm">
                                    <FieldString
                                        :label="$t('attribute.common.name')"
                                        :value.sync="editFormService.name"
                                        required
                                        @modified="itemModified"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-sm">
                                    <FieldDate
                                        :label="$t('attribute.common.date_from')"
                                        :value.sync="editFormService.date_from"
                                        required
                                        @modified="itemModified"
                                    />
                                </div>
                                <div class="col-sm">
                                    <FieldDate
                                        :label="$t('attribute.common.date_to')"
                                        :value.sync="editFormService.date_to"
                                        @modified="itemModified"
                                    />
                                </div>
                                <div class="col-sm">
                                    <FieldSelect
                                        :label="$t('object.fin_vat')"
                                        :value.sync="editFormService.fin_vat_id"
                                        source="fin_vat"
                                        required
                                        @modified="itemModified"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-sm">
                                    <FieldSwitch
                                        id="is_discount_disabled"
                                        :label="$t('attribute.fin_service.is_discount_disabled')"
                                        :value.sync="editFormService.is_discount_disabled"
                                        :defaultValue="false"
                                        @modified="itemModified"
                                    />
                                </div>
                                <div class="col-sm">
                                    <FieldSwitch
                                        id="is_price_variable"
                                        :label="$t('attribute.fin_service.is_price_variable')"
                                        :value.sync="editFormService.is_price_variable"
                                        :defaultValue="false"
                                        @modified="itemModified"
                                    />
                                </div>
                                <!-- <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                    <FieldSwitch
                                        id="is_price_composite"
                                        :label="$t('attribute.fin_service.is_price_composite')"
                                        :value.sync="editFormService.is_price_composite"
                                        :defaultValue="false"
                                        @modified="itemModified"
                                    />
                                </div> -->
                            </div>
                        </StandardRegion>
                            <div class="form-row">
                                <div class="col-sm">
                                    <Grid
                                        :key="editFormService.id"
                                        ref="serviceSetGrid"
                                        :config="serviceSetGridConfig"
                                        @edit-item="editServiceSet($event)"
                                        @modified="gridModified($event)"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-sm">
                                    <Grid ref="pricesGrid" :config="pricesGridConfig" />
                                </div>
                            </div>
                    </template>
                </div>
            </div>
        </div>

        <ModalWindow
            v-if="isDisplayWarningSelection" 
            minWidth="300px"
            :buttons="['yes', 'no']"
            @yes="processSelection(true)"
            @ok="processSelection(true)"
            @no="processSelection(false)"
            @cancel="processSelection(false)"
        >
            {{ $t('component.Services.confirmSelection') }}
        </ModalWindow>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import StandardRegion from '@/components/lib/regions/StandardRegion';
    import TreeView from '@/components/lib/regions/TreeView';
    import Grid from '@/components/lib/regions/Grid';

    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import FieldPopupList from '@/components/lib/controls/FieldPopupList';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';

    const OBJECT_TYPE_SERVICE_GROUP = 'service_group';
    const OBJECT_TYPE_SERVICE = 'service';

    export default {
        name: 'Services',

        components: {
            TemplatePageStandard, ModalWindow, StandardRegion, TreeView,
            ButtonSave, ButtonDelete, ButtonCustom,
            FieldPopupList, FieldSelect, FieldString, FieldDate, FieldSwitch,
            FieldNumber,
            Grid,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Data ===============
        data() {
            return {
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.finances') },
                    { text: this.$t('MainMenu.finances-services') },
                ],
                buttons: [ 'save', 'delete' ],
                serviceTree: [],
                preSelectedObjectType: null,
                preSelectedObjectId: null,
                selectedItem: null,
                itemToSelect: null,
                isServiceSetGridModified: false,
                editFormServiceGroup: {},
                //     id: null,
                //     parent_id: null,
                //     code: null,
                //     name: null,
                // },
                oldFormServiceGroup: {},
                editFormService: {},
                //     id: null,
                //     fin_service_group_id: null,
                //     fin_vat_id: null,
                //     code: null,
                //     code_public: null,
                //     name: null,
                //     date_from: null,
                //     date_to: null,
                //     is_discount_disabled: null,
                //     is_price_variable: null,
                //     is_price_composite: null,
                // },
                oldFormService: {},
                isDisplayWarningSelection: false,
                serviceGroupListConfig: {
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'string', key:'code', header:this.$t('attribute.common.code') },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                     ],
                    order: [ 'name' ],
                    displayToolbar: true,
                },
                // saveSessionState: ``;
                serviceSetGridConfig: {
                    apiUrl: null, // 'fin_set_detail',
                    filter: null,
                    title: this.$t('component.Services.serviceSets'),
                    // readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'fin_service_id' },
                        { type:'select', key:'fin_set_id', header:this.$t('object.fin_set'), source:`fin_set`, required:true },
                    ],
                    order: [ 'fin_set_id' ],
                    // displayToolbar: false,
                    // buttons: [],
                    buttons: [ 'row-selector', 'add', 'delete' ],
                },
                pricesGridConfig: {
                    apiUrl: null, // 'fin_price',
                    filter: null,
                    title: this.$t('component.Services.prices'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'hidden', key:'fin_service_id' },
                        { type:'select', key:'fin_pricelist_id', header:this.$t('object.fin_pricelist'), source:`fin_pricelist` },
                        { type:'date', key:'date_from', header:this.$t('attribute.common.date_from') },
                        { type:'date', key:'date_to', header:this.$t('attribute.common.date_to') },
                        { type:'number', key:'price', header:this.$t('attribute.common.price') },
                    ],
                    order: [ 'fin_pricelist_id', 'date_from' ],
                    displayToolbar: false,
                    // buttons: [ 'row-selector', 'add', 'delete' ],
                    buttons: [],
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            // isModified() { return this.isFormModified || this.isServiceSetGridModified },
            isFormModified() {
                return (
                    !tools.isEqual(this.oldFormServiceGroup, this.editFormServiceGroup) ||
                    !tools.isEqual(this.oldFormService, this.editFormService)
                );
            },
        },

        // =============== Methods ===============
        methods: {
            checkModified() {
                this.isModified = this.isFormModified || this.isServiceSetGridModified;
            },

            itemModified() {
                this.checkModified();
            },

            gridModified(evt) {
                // console.log(`DEBUG: ${this.$options.name}.gridModified(${evt}, '${gridName}')`);
                this.isServiceSetGridModified = evt;
                this.checkModified();
            },

            async getItemData(item) {
                this.selectedItem = item;
                if (this.selectedItem && this.selectedItem.isGroup) {
                    await this.getServiceGroupData(item.id);
                }

                if (this.selectedItem && !this.selectedItem.isGroup) {
                    await this.getServiceData(item.id);
                }
                this.checkModified();
            },

            async processSelection(doProcess) {
                if (doProcess) {
                    this.editFormServiceGroup = tools.shallowCopy(this.oldFormServiceGroup);
                    this.editFormService = tools.shallowCopy(this.oldFormService);
                    this.checkModified();
                    await this.getItemData(this.itemToSelect);
                } else {
                    if (this.itemToSelect != this.selectedItem) {
                        // console.log(`DEBUG: ${this.$options.name}.processSelection(${doProcess}) this.itemToSelect=`, this.itemToSelect);
                        // copy old value back to reset isFormModified
                        this.$refs.treeView.select(this.selectedItem);
                    }
                }
                this.isDisplayWarningSelection = false;
            },

            async itemSelected(item) {
                // console.log(`DEBUG: ${this.$options.name}.itemSelected() this.selectedItem=`, this.selectedItem);
                // console.log(`DEBUG: ${this.$options.name}.itemSelected() item=`, item);
                if (this.isModified) {
                    this.isDisplayWarningSelection = true;
                    this.itemToSelect = item;
                    return;
                }
                await this.getItemData(item);
            },

            async getData() {
                try {
                    let groupFilterString = tools.getFilterString({ order:[ 'name' ]});
                    let serviceGroups = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_service_group${groupFilterString}`)).data.rows;
                    let serviceGroups2 = serviceGroups.map(item => {
                        let obj = {
                            id: item.id,
                            parent_id: item.parent_id,
                            linkId: `grp-${item.id}`,
                            linkParentId: item.parent_id ? `grp-${item.parent_id}` : null,
                            code: item.code,
                            name: item.name,
                            isSelectable: true,
                            isGroup: true,
                        };
                        if (this.preSelectedObjectType === OBJECT_TYPE_SERVICE_GROUP && this.preSelectedObjectId === item.id) this.selectedItem = obj;
                        return obj;
                    });

                    let serviceFilterString = tools.getFilterString({ order:[ 'code' ]});
                    let serviceItems = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_service${serviceFilterString}`)).data.rows;
                    let serviceItemsPrepared = serviceItems.map(item => {
                        let obj = {
                            id: item.id,
                            parent_id: item.parent_id,
                            linkId: `${item.id}`,
                            linkParentId: `grp-${item.fin_service_group_id}`,
                            code: item.code,
                            name: `${item.code} / ${item.name}`,
                            isSelectable: true,
                            isGroup: false,
                            style: (
                                (item.date_from === null || moment(item.date_from).isSameOrBefore(this.visitTime)) &&
                                (item.date_to === null || moment(item.date_to).isSameOrAfter(this.visitTime))
                                // ? null : 'background-color: #e9ecef;'
                                ? null : 'text-decoration: line-through;'
                            ),
                        };
                        if (this.preSelectedObjectType === OBJECT_TYPE_SERVICE && this.preSelectedObjectId === item.id) this.selectedItem = obj;
                        return obj;
                    });
                    this.serviceTree = tools.listToTree(serviceGroups2.concat(serviceItemsPrepared), 'linkId','linkParentId');
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.selectedItem=`, this.selectedItem);
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.serviceTree=`, this.serviceTree);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getServiceGroupData(serviceGroupId) {
                try {
                    this.editFormServiceGroup = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_service_group/${serviceGroupId}`)).data;
                    this.oldFormServiceGroup = tools.shallowCopy(this.editFormServiceGroup);
                    this.checkModified();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getServiceData(serviceId) {
                try {
                    this.editFormService = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_service/${serviceId}`)).data;
                    this.oldFormService = tools.shallowCopy(this.editFormService);

                    let filter = { where:[
                        { fin_service_id: serviceId },
                    ]};
                    this.serviceSetGridConfig.apiUrl = 'fin_set_detail';
                    this.serviceSetGridConfig.filter = filter;
                    this.serviceSetGridConfig.columns.find(x => x.key === 'fin_service_id').defaultValue = serviceId;
                    await this.$refs.serviceSetGrid.refresh();

                    this.pricesGridConfig.apiUrl = 'fin_price';
                    this.pricesGridConfig.filter = filter;
                    await this.$refs.pricesGrid.refresh();
                    this.checkModified();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveServiceGroupData() {
                // console.log(`DEBUG: ${this.$options.name}.saveServiceGroupData() this.oldFormServiceGroup=`, this.oldFormServiceGroup);
                // console.log(`DEBUG: ${this.$options.name}.saveServiceGroupData() this.editFormServiceGroup=`, this.editFormServiceGroup);
                // console.log(`DEBUG: ${this.$options.name}.saveServiceGroupData() this.isFormModified=`, this.isFormModified);
                if (this.isFormModified) {
                    try {
                        if (this.editFormServiceGroup.id) {
                            // Update item
                            await this.authenticatedAxios.put(`${this.baseUrl}/fin_service_group/${this.editFormServiceGroup.id}`, this.editFormServiceGroup);
                            await this.getServiceGroupData(this.editFormServiceGroup.id);
                        } else {
                            // Create new item
                            let itemId = (await this.authenticatedAxios.post(`${this.baseUrl}/fin_service_group`, this.editFormServiceGroup)).data.id;
                            await this.getServiceGroupData(itemId);
                        }
                        tools.displaySuccessSave(this);
                        this.oldFormServiceGroup = tools.shallowCopy(this.editFormServiceGroup);
                        this.preSelectedObjectType = OBJECT_TYPE_SERVICE_GROUP;
                        this.preSelectedObjectId = this.editFormServiceGroup.id;
                        await this.getData();
                        // this.$refs.treeView.select(this.selectedItem);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
            },

            async saveServiceData() {
                // console.log(`DEBUG: ${this.$options.name}.saveServiceData() this.editFormService=`, this.editFormService);
                if (this.isFormModified) {
                    try {
                        if (this.editFormService.id) {
                            // Update item
                            await this.authenticatedAxios.put(`${this.baseUrl}/fin_service/${this.editFormService.id}`, this.editFormService);
                            await this.getServiceData(this.editFormService.id);
                        } else {
                            // Create new item
                            let itemId = (await this.authenticatedAxios.post(`${this.baseUrl}/fin_service`, this.editFormService)).data.id;
                            await this.getServiceData(itemId);
                        }
                        tools.displaySuccessSave(this);
                        this.oldFormService = tools.shallowCopy(this.editFormService);
                        this.preSelectedObjectType = OBJECT_TYPE_SERVICE;
                        this.preSelectedObjectId = this.editFormService.id;
                        await this.getData();
                        // this.$refs.treeView.select(this.selectedItem);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.selectedItem=`, this.selectedItem);
                if (this.selectedItem && this.selectedItem.isGroup) {
                    await this.saveServiceGroupData();
                }

                if (this.selectedItem && !this.selectedItem.isGroup) {
                    await this.saveServiceData();
                }
                if (this.$refs.serviceSetGrid) this.$refs.serviceSetGrid.$emit('save');
                this.checkModified();
            },

            addSubgroup() {
                // await this.saveData();
                this.editFormServiceGroup.id = null;
                this.editFormServiceGroup.parent_id = this.oldFormServiceGroup.id;
                this.editFormServiceGroup.code = null;
                this.editFormServiceGroup.name = null;
                this.selectedItem = { isGroup:true };
                this.oldFormServiceGroup = tools.shallowCopy(this.editFormServiceGroup);
            },

            addRootGroup() {
                // console.log(`DEBUG: ${this.$options.name}.addRootGroup()`);
                this.editFormServiceGroup = {
                    id: null,
                    parent_id: null,
                    code: null,
                    name: null,
                };
                this.selectedItem = { isGroup:true };
                this.oldFormServiceGroup = tools.shallowCopy(this.editFormServiceGroup);
            },

            addService() {
                // console.log(`DEBUG: ${this.$options.name}.addService() this.selectedItem=`, this.selectedItem);
                this.editFormService.id = null;
                // this.editFormService.fin_service_group_id = null;
                // this.editFormService.fin_vat_id = null; // left previous
                this.editFormService.code = null;
                this.editFormService.code_public = null;
                this.editFormService.name = null;
                this.editFormService.date_from = moment().format(moment.HTML5_FMT.DATE);
                this.editFormService.date_to = null;
                // this.editFormService.is_discount_disabled = null; // left previous
                // this.editFormService.is_price_variable = null; // left previous
                // this.editFormService.is_price_composite = null; // left previous

                if (this.selectedItem && this.selectedItem.isGroup) {
                    this.editFormService.fin_service_group_id = this.selectedItem.id;
                }

                // if (this.selectedItem && !this.selectedItem.isGroup) {
                //     // this.editFormService.fin_service_group_id = this.selectedItem.fin_service_group_id; // left previous
                // }

                this.selectedItem = { isGroup:false };
                this.oldFormService = tools.shallowCopy(this.editFormService);
                // console.log(`DEBUG: ${this.$options.name}.addService() this.editFormService=`, this.editFormService);
            },

            async deleteServiceGroup() {
                try {
                    await this.authenticatedAxios.delete(`${this.baseUrl}/fin_service_group/${this.editFormServiceGroup.id}`);
                    this.editFormServiceGroup = {};
                    this.oldFormServiceGroup = tools.shallowCopy(this.editFormServiceGroup);
                    this.selectedItem = null;
                    tools.displaySuccessDelete(this.$parent);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async deleteService() {
                try {
                    await this.authenticatedAxios.delete(`${this.baseUrl}/fin_service/${this.editFormService.id}`);
                    this.editFormService = {};
                    this.oldFormService = tools.shallowCopy(this.editFormService);
                    this.selectedItem = null;
                    tools.displaySuccessDelete(this.$parent);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    if (this.selectedItem && this.selectedItem.isGroup) {
                        await this.deleteServiceGroup();
                    }

                    if (this.selectedItem && !this.selectedItem.isGroup) {
                        await this.deleteService();
                    }
                    this.isDisplayWarningDelete = false;
                    await this.getData();
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },
        },
        // =============== Life cycle ===============
        beforeMount() {
            // to avoid blink "modified" status while page loads
            this.oldFormServiceGroup = tools.shallowCopy(this.editFormServiceGroup);
            this.oldFormService = tools.shallowCopy(this.editFormService);
        },
        async mounted() {
            await this.getData();
            // if some changes occured while getData() executes
            this.oldFormServiceGroup = tools.shallowCopy(this.editFormServiceGroup);
            this.oldFormService = tools.shallowCopy(this.editFormService);
        },
    }
</script>

<style scoped>
    .services-template__wrapper {
        /* padding: 0.5rem; */
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
</style>
