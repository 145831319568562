<!--
    Author: Eduard Grebenyukov
    Date: 2020-12-15
-->

<template>
    <div>
        <div class="btn-toolbar" style="padding: 0 4px 4px 0;" role="toolbar">
            <div class="h6">{{ $t('app.clientBuilding') }}</div>
            <div @click="addItem()" class="btn btn-warning btn-sm ml-2" :title="$t('app.add')">
                <i class="fas fa-plus"></i>
            </div>
        </div>
        <Grid ref="clientBuildings" :config="gridConfig" @add-item="addItem()" @edit-item="editItem($event.id)" />
        <ClientBuildingEdit v-if="isDisplayModalEdit" @close-modal="closeModalEdit($event)" :id="clientBuildingId" :clientId="clientId" />
        <ModalWindow v-if="isDisplayBuildingsList" width="90%" max-width="600px" max-height="90vh" @cancel="isDisplayBuildingsList = false" :header="$t('app.clientBuilding')" >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="buildings" :config="buildingsGridConfig" @item-selected="buildingSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import Grid from '@/components/lib/regions/Grid';
    import ClientBuildingEdit from './ClientBuildingEdit';

    export default {
        name: 'ClientBuildings',

        components: {
            ModalWindow, Grid, ClientBuildingEdit,
        },

        // =============== Props ===============
        props: {
            clientId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                isDisplayModalEdit: false,
                clientBuildingId: null,
                gridConfig: {
                    apiUrl: null,
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'50px' },
                        { type:'select', key:'core_building_id', header:this.$t('object.core_building'), source:'core_building' },
                        { type:'hidden', key:'core_client_id', header:this.$t('object.core_client') },
                        // { type:'icon', key:'delete', icon:'fas fa-times', style:'color:red;' },
                    ],
                    order: [ 'core_building_id' ],
                    buttons: [ 'edit' ],
                    displayToolbar: false,
                    displayPagination: false,
                },
                isDisplayBuildingsList: false,
                buildingsGridConfig: {
                    apiUrl: 'core_building',
                    readonly: true,
                    columns: [
                        { type:'string', key:'name', header:this.$t('object.core_building') },
                    ],
                    order: [ 'name' ],
                    row: { emit: 'item-selected', style: 'cursor: pointer;' },
                    displayToolbar: false,
                    displayPagination: false,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Watch ===============
        watch: {
            personId() {
                this.setGridSettings();
            },
        },

        // =============== Methods ===============
        methods: {
            setGridSettings() {
                let filterClient = { where:[
                    { core_client_id: this.clientId },
                ]};
                this.gridConfig.apiUrl = 'core_client_building';
                this.gridConfig.filter = filterClient;
                this.gridConfig.columns.find(x => x.key === 'core_client_id').defaultValue = this.clientId;
            },

            addItem() {
                this.isDisplayBuildingsList = true;
            },

            editItem(clientBuildingId) {
                // console.log(`DEBUG: ${this.$options.name}.editItem(${clientBuildingId})`);
                this.clientBuildingId = clientBuildingId;
                this.isDisplayModalEdit = true;
            },

            async buildingSelected(newBuilding) {
                // console.log(`DEBUG: ${this.$options.name}.buildingSelected() newBuilding=`, newBuilding);
                // console.log(`DEBUG: ${this.$options.name}.buildingSelected() this.$refs.clientBuildings.getAllRows()=`, this.$refs.clientBuildings.getAllRows());
                let sameBuilding = this.$refs.clientBuildings.getAllRows().find( item => item.core_building_id === newBuilding.id );
                // console.log(`DEBUG: ${this.$options.name}.buildingSelected() sameBuilding=`, sameBuilding);
                if (!sameBuilding) {
                    let newRow = { core_client_id:this.clientId, core_building_id:newBuilding.id };
                    try {
                        await this.authenticatedAxios.post(`${this.baseUrl}/core_client_building`, newRow);
                        // let response = await this.authenticatedAxios.post(`${this.baseUrl}/core_client_building`, newRow);
                        // console.log(`DEBUG: ${this.$options.name}.buildingSelected() response.data=`, response.data);
                        this.$refs.clientBuildings.refresh();
                        tools.displaySuccessSave(this);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                    this.isDisplayBuildingsList = false;
                }
            },

            closeModalEdit(isRefresh) {
                // console.log(`DEBUG: ${this.$options.name}.closeModalEdit()`);
                this.isDisplayModalEdit = false;
                if (isRefresh) this.$refs.clientBuildings.refresh();
            },

            async refresh() {
                await this.$refs.clientBuildings.refresh();
            },

        },

        // =============== Life cycle ===============
        async mounted() {
            this.setGridSettings();
        },
    }
</script>

<style>
</style>
