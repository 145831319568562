/*
    Author: Eduard Grebenyukov
    Date: 2020-08-11
*/

const sprintf = require('sprintf-js').sprintf;
import { i18n } from '@/locales';
const autoHideDelay = 5000;

function displaySuccess(ctx, message, title) {
    ctx.$bvToast.toast( message, {
        variant: 'success',
        autoHideDelay: autoHideDelay,
        title: title,
        solid: true,
        appendToast: false,
        toaster: 'b-toaster-bottom-right'
    });
}

function displaySuccessSave(ctx, message) {
    // if (!message) message = ctx.$t('lib.messages.successSave');
    displaySuccess(ctx, (message ? message : ctx.$t('lib.messages.successSave')), ctx.$t('lib.messages.successSave'));
}

function displaySuccessDelete(ctx, message) {
    // if (!message) message = ctx.$t('lib.messages.successDelete');
    displaySuccess(ctx, (message ? message : ctx.$t('lib.messages.successDelete')), ctx.$t('lib.messages.successDelete'));
}

function displayWarning(ctx, message, title) {
    // if (!title) title = ctx.$t('lib.messages.warning');
    ctx.$bvToast.toast( message, {
        variant:'warning',
        autoHideDelay: autoHideDelay,
        title:(title ? title : ctx.$t('lib.messages.warning')),
        solid:true,
        toaster:'b-toaster-bottom-right'
    });
}

function displayError(ctx, message, title) {
    // console.log(`DEBUG: tools.displayError(message)=`, message);
    let msg = '';
    if (typeof message === 'object') {
        msg = message.toString();
    } else {
        msg = message;
    }
    // if (!title) title = ctx.$t('lib.messages.error');
    ctx.$bvToast.toast( msg, {
        variant:'danger',
        autoHideDelay: autoHideDelay,
        title:(title ? title : ctx.$t('lib.messages.error')),
        solid:true,
        toaster:'b-toaster-bottom-right'
    });
}

function displayErrorServerNotAvailable(ctx) {
    displayError(ctx, ctx.$t('lib.error.serverNotAvailable'));
}

function dbErrorInterpretation(error) {
    // console.log(`DEBUG: tools.dbErrorInterpretation(error)=`, error);
    let tableName = error.table ? i18n.t(`object.${error.table}`) : '';
    let errorConditionText = null;
    if (error.constraint) {
        errorConditionText = sprintf(i18n.t(`lib.db.constraint.${error.constraint}`), tableName);
    } else {
        let columnNameCode = error.table && error.column ? `attribute.${error.table}.${error.column}` : null;
        let columnName = columnNameCode ? i18n.t(columnNameCode) : null;
        if (columnName == columnNameCode) {
            columnNameCode = error.column ? `attribute.common.${error.column}` : null;
            columnName = columnNameCode ? i18n.t(columnNameCode) : null;
            if (columnName == columnNameCode) {
                columnName = i18n.t(`${error.column}`);
            }
        }
        errorConditionText = sprintf(i18n.t(`lib.db.error.${error.code}`), tableName, columnName ? columnName : '');
    }
    return `${errorConditionText}`;
}

function displayApiError(ctx, error) {
    // message, config, code, request, response
    // console.log(`DEBUG: tools.displayApiError(error.response)=`, error.response);
    if (error.response) {
        switch (error.response.status) {
            case 401: displayError(ctx, ctx.$t('lib.error.401')); /* setTimeout(() => { ctx.$router.push({ name:'logout' })}, 60 * 1000); */ break;
            case 403: displayError(ctx, ctx.$t('lib.error.403')); break;
            case 404: displayError(ctx, ctx.$t('lib.error.404')); break;
            case 405: displayError(ctx, ctx.$t('lib.error.405')); break;
            case 500: displayError(ctx, ctx.$t('lib.error.500')); break;
            // case 422: displayError(ctx, ctx.$t('lib.error.422')); break;
            case 422: {
                if (error.response.data && error.response.data.error) {
                    displayError(ctx, dbErrorInterpretation(error.response.data.error));
                } else {
                    displayError(ctx, ctx.$t('lib.error.unknown'));
                }
                break;
            }
            default: displayErrorServerNotAvailable(ctx);
        }
    } else {
        displayError(ctx, error);
    }
}

export default {
    displaySuccess, displaySuccessSave, displaySuccessDelete, displayWarning,
    displayError, displayErrorServerNotAvailable, dbErrorInterpretation, displayApiError,
}
