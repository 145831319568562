import { render, staticRenderFns } from "./CashdeskInfo.vue?vue&type=template&id=20c82ec0&scoped=true&"
import script from "./CashdeskInfo.vue?vue&type=script&lang=js&"
export * from "./CashdeskInfo.vue?vue&type=script&lang=js&"
import style0 from "./CashdeskInfo.vue?vue&type=style&index=0&id=20c82ec0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c82ec0",
  null
  
)

export default component.exports