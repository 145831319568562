<!--
    Author: Eduard Grebenyukov
    Date: 2021-01-09
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose @click="closePressed()" />
        </template>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-sm-12 col-xl-8">
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('attribute.fin_cash.fin_payment_type_id')"
                                    :value.sync="editFormData.fin_payment_type_id"
                                    :source="paymentTypeSourceString"
                                    order="name"
                                    required
                                    @modified="modifiedPaymentType"
                                />
                            </div>
                            <div class="col-sm" :style="(paymentType && paymentType.is_bank_card ? 'display:block;' : 'display:none;')">
                                <FieldString
                                    :label="$t('attribute.fin_cash.bank_card_num')"
                                    :value.sync="editFormData.bank_card_num"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    :label="$t('attribute.fin_cash.cheque_num')"
                                    :value.sync="editFormData.cheque_num"
                                    :disabled="!!selectedCashRegisterId"
                                />
                            </div>
                        </div>
                        <!-- <div class="form-row">
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('component.Deposit.sum_discount')"
                                    :value.sync="editFormData.sum_discount"
                                    text-style="font-size:2rem;height:4rem;"
                                    :decimalPlaces="2"
                                    readonly
                                    @modified="calcTotal"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('component.Deposit.sum_transaction')"
                                    :value.sync="editFormData.sum_transaction"
                                    text-style="font-size:2rem;height:4rem;"
                                    :decimalPlaces="2"
                                    readonly
                                    required
                                />
                            </div>
                        </div> -->
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('component.Deposit.sum_to_deposit')"
                                    :value.sync="editFormData.sum_to_pay"
                                    input-style="font-size:2rem;height:4rem;"
                                    :decimalPlaces="2"
                                    required
                                    @modified="modifiedSumToPay"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('component.Deposit.sum_cash')"
                                    :value.sync="editFormData.sum_cash"
                                    input-style="font-size:2rem;height:4rem;"
                                    required
                                    @modified="modifiedSumCash"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('component.Deposit.sum_change')"
                                    :value="sumChange"
                                    text-style="font-size:2rem;height:4rem;"
                                    :decimalPlaces="2"
                                    readonly
                                    required
                                />
                            </div>
                            <div class="col-sm">
                                <button :disabled="!isOperationEnabled" @click.prevent="doPayment()" class="btn btn-primary btn-lg btn-payment" >
                                    {{ $t('component.Deposit.makeDeposit') }}
                                </button>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.common.commentary')"
                                    :value.sync="editFormData.commentary"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-4">
                        <!-- Right sidebar -->
                        <AccountInfo ref="accountInfo" :accountId="accountId" />
                        <CashdeskInfo />
                    </div>
                </div>
            </div>
        </form>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldText from '@/components/lib/controls/FieldText';

    import AccountInfo from '@/components/pages/common/AccountInfo';
    import CashdeskInfo from '@/components/pages/cashdesk/CashdeskInfo';

    export default {
        name: 'Deposit',

        components: {
            TemplatePageStandard,
            ButtonClose, FieldString, FieldNumber, FieldSelect, FieldText,
            AccountInfo, CashdeskInfo,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                // itemId: undefined,
                accountId: null,
                buttons: [],
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.cashdesk') },
                    // { text: this.$t('MainMenu.finances-accounts'), to: '/finances/accounts' },
                    { text: this.$t('component.Deposit.depositTitle'), to: '#' },
                ],
                paymentTypeSourceString: 'fin_payment_type?filter={"where":[{"is_archived":false},{"is_offset":false}]}',
                isOperationInProgress: false,
                editFormData: {
                    fin_payment_type_id: null,
                    cash_register_id: null,
                    pos_terminal_id: null,
                    bank_card_num: null,
                    cheque_num: null,
                    sum_to_pay: null,
                    sum_discount: null,
                    sum_transaction: null,
                    sum_cash: null,
                    commentary: null,
                },
                paymentType: null,
                sumChange: null,
                discountPct: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                selectedCashRegisterId: 'selectedCashRegisterId',
            }),
            // sumChange() { return Math.max(tools.round(this.editFormData.sum_cash - this.editFormData.sum_transaction, 2), 0); },
            isOperationEnabled() {
                return (
                    // this.editFormData.sum_cash >= this.editFormData.sum_transaction &&
                    this.editFormData.sum_cash > 0 &&
                    this.editFormData.sum_cash >= this.editFormData.sum_to_pay &&
                    (this.editFormData.fin_payment_type_id || this.editFormData.fin_payment_type_id === 0) &&
                    !this.isOperationInProgress
                );
            },
        },

        // =============== Methods ===============
        methods: {
            async modifiedPaymentType() {
                this.paymentType = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_payment_type/${this.editFormData.fin_payment_type_id}`)).data;
                this.editFormData.sum_discount = 0;
                this.modifiedSumToPay();
            },

            modifiedSumToPay() {
                this.editFormData.sum_cash = this.editFormData.sum_to_pay;
                this.editFormData.sum_transaction = tools.round(this.editFormData.sum_to_pay - this.editFormData.sum_discount, 2);
                this.modifiedSumCash();
            },

            modifiedSumCash() {
                // this.calcDiscount();
                // this.editFormData.sum_transaction = tools.round(this.editFormData.sum_to_pay - this.editFormData.sum_discount, 2);
                if (this.editFormData.sum_to_pay && this.editFormData.sum_cash) {
                    this.sumChange = Math.max(tools.round(this.editFormData.sum_cash - this.editFormData.sum_transaction, 2), 0);
                } else {
                    this.sumChange = 0;
                }
                // console.log(`DEBUG: ${this.$options.name}.modifiedSumCash() this.editFormData.sum_transaction=`, this.editFormData.sum_transaction);
            },

            async doPayment() {
                this.isOperationInProgress = true;
                try {
                    let { ...payload } = this.editFormData;
                    payload.core_user_id = this.currentUser.id;
                    payload.core_workplace_id = this.currentUser.currentWorkplace.id;
                    payload.fin_account_id = this.accountId;
                    payload.cash_register_id = this.selectedCashRegisterId;

                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-create-deposit`, payload);
                    tools.displaySuccessSave(this.$parent);
                    // console.log(`DEBUG: ${this.$options.name}.doPayment response=`, response);
                    this.$router.go(-1);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                this.isOperationInProgress = false;
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.accountId = parseInt(this.$route.params.accountId);
            if (!this.accountId) this.$router.go(-1);
        },
    }
</script>

<style>
    .btn.btn-payment {
        margin-top: 25px;
        height: 4rem;
        width: 100%;
    }
</style>
