<!--
    Author: Eduard Grebenyukov
    Date: 2020-03-14
-->

<template>
    <!-- <div :style="`width:${canvas.width}px;`"> -->
    <!-- <div class="schedule-canvas" :style="`height:${canvas.height}px;`" :style="`height:calc(100vh - 150px);`"> -->
    <div ref="scheduleCanvas" class="schedule-canvas" :style="`height: ${maximalHeight};`">

        <div class="schedule-header-container">
            <div class="schedule-cell schedule-header schedule-time" :style="`height:${heightHeader}px;width:${widthTime}px;`">
                <div :class="{ 'weekday':true, 'weekend':isWeekend }" >{{ visitDate | formatWeekday() }}</div>
                {{ visitDate | formatDateShort() }}
            </div>

            <template v-for="wp in workplaces">
                <template v-if="!wp.is_offtime || isShowOfftime">
                    <div
                        :key="`workplace-${wp.id}`"
                        class="schedule-cell schedule-header"
                        :style="`left:${wp.x}px; top:${wp.y}px; width:${wp.dx}px; height:${wp.dy}px;`"
                        v-html="wp.workplace_name.replace(/ \/ /g, '<br>')"
                        :title="wp.workplace_name.replace(/ \/ /g, '\n')"
                    >
                    </div>
                </template>
            </template>
        </div>

        <div
            v-for="ts in timeSlots"
            :key="`time-${ts.minute}`"
            class="schedule-cell schedule-time"
            :class=" ts.is_now ? 'current-hour': '' "
            :style="`left:${ts.x}px; top:${ts.y}px; width:${ts.dx}px; height:${ts.dy}px;`"
        >{{ ts.time }}</div>

        <template v-for="wp in workplaces">
            <template v-if="!wp.is_offtime || isShowOfftime">
                <div
                    v-for="grid in wp.grid"
                    :key="`grid-${wp.id}-${grid.minute}`"
                    class="schedule-cell schedule-grid"
                    :style="`left:${grid.x}px; top:${grid.y}px; width:${grid.dx}px; height:${grid.dy}px;`"
                >{{ grid.time }}</div>
                <div
                    v-for="slot in wp.slots"
                    :key="`slot-${wp.id}-${slot.visitWorktimeId}-${slot.minute}`"
                    class="schedule-cell schedule-slot"
                    :style="`left:${slot.x}px; top:${slot.y}px; width:${slot.dx}px; height:${slot.dy}px;`"
                    @click="scheduleNew($event, slot)"
                    @contextmenu.prevent="showContextMenu($event, null, slot)"
                    @drop='onDrop($event, slot)'
                    @dragover.prevent
                    @dragenter.prevent
                >{{ slot.time }}</div>
                <div
                    v-for="lock in wp.lockedSlots"
                    :key="`lock-${wp.id}-${lock.minute}-${lock.id}`"
                    :class="'schedule-cell schedule-lock' + (lock.isRecordEnabled ? ' open' : '')"
                    :style="`left:${lock.x}px; top:${lock.y}px; width:${lock.dx}px; height:${lock.dy}px; background-color:${lock.bgcolor};`"
                    @click="scheduleNew($event, lock)"
                >{{ lock.name }}</div>
                <template v-for="rec in wp.records">
                    <div
                        v-if="rec.status_code != 'canceled' || isShowCanceled"
                        :key="`rec-${rec.id}`"
                        class="schedule-cell schedule-rec"
                        :style="`left:${rec.x}px; top:${rec.y}px; width:${rec.dx}px; height:${rec.dy}px; background-color:${rec.visit_type_bgcolor}; color:${rec.visit_type_color}`"
                        :title="getRecordTitle(rec)"
                        @click="scheduleEdit($event, rec)"
                        @contextmenu.prevent="showContextMenu($event, rec, null)"
                        draggable
                        @dragstart="startDrag($event, rec)"
                    >
                        <i :class="`schedule-cell-icon ${rec.icon_class} glow`" :style="`color:${rec.icon_color};`" :title="rec.status_name"></i>
                        <i v-if="rec.sum_unpaid_client" class="fas fa-coins icon-debt glow" :title="`${$t('attribute.common.sum_unpaid')}: ${rec.sum_unpaid_client}`"></i>
                        <i
                                v-if="!rec.is_client_has_workplace_building"
                                class="far fa-calendar-times icon-no-building glow"
                                :title="`${$t('component.Schedule.client_building_absent')}: ${rec.building_name_short}`"
                            ></i>
                        <span :class="`schedule-cell-name ${rec.brightness > 140 && rec.is_client ? 'glow-dark' : 'glow'} ${rec.is_client ? '' : 'not-client'}`">
                            {{ rec.client_fullname }}
                        </span>
                        <div v-if="rec.sum_unpaid_client" class="schedule-cell-debt" >
                            {{$t('attribute.common.sum_unpaid')}}: {{ rec.sum_unpaid_client }}
                        </div>
                        <div class="schedule-cell-visit-type" >{{ rec.visit_type_name }}</div>
                        <div class="schedule-cell-visit-commentary" >{{ rec.commentary }}</div>
                    </div>
                </template>
            </template>
        </template>

        <VisitContextMenu mode="schedule" ref="visitContextMenu" @refresh-status="getData()" />

        <div v-if="isDisplaySpinner" class="spinner-holder">
            <b-spinner label="Loading..."></b-spinner>
        </div>

    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import VisitContextMenu from './VisitContextMenu';

    export default {
        name: 'ScheduleDay',

        components: { VisitContextMenu },

        mixins: [ tools.visibilityMixin ],

        // =============== Props ===============
        props: {
            // dateFrom: Date,
            // dateTo: Date,
            visitGroupId: Number,
            coreWorkplaceId: Number,
            visitDate: Date,
            refreshInterval: Number,
            isShowOfftime: Boolean,
            isShowCanceled: Boolean,
        },

        // =============== Data ===============
        data() {
            return {
                isDisplaySpinner: false,
                // canvas: {
                //     width: 0,
                //     height: 0,
                // },
                maximalHeight: null,
                workplaces: [],
                visitGroup: {},
                timeSlots: [],
                // grid: [],
                // cells: [],
                // groupId: this.visitGroupId,
                dayNumber: null,
                isWeekend: false,
                refreshTimerId: null,
                isTimeToRefreshPassed: false,
                // const
                heightHeader: 61,
                widthTime: 61,
                // widthCell: 151,
                widthCell: 201,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Watch ===============
        watch: {
            visitGroupId(newValue, oldValue) {
                // console.log(`DEBUG: ${this.$options.name}.watch.visitGroupId(${newValue}, ${oldValue})`);
                if (newValue !== oldValue) this.getData();
            },
            visitDate(newValue, oldValue) {
                // console.log(`DEBUG: ${this.$options.name}.watch.visitDate(${newValue}, ${oldValue})`);
                // console.log(`DEBUG: ${this.$options.name}.watch.visitDate(${newValue}, ${oldValue}) typeof newValue=`, typeof newValue);
                if (!moment(newValue).isSame(oldValue, 'day')) this.getData();
            },
            refreshInterval() {
                // console.log(`DEBUG: ${this.$options.name}.watch.refreshInterval this.refreshInterval=`, this.refreshInterval);
                this.setTimer(this.refreshInterval);
            },
        },

        // =============== Filters ===============
        filters: {
            formatDate(d) {
                if (!d) return '';
                return moment(d).format('L');
            },
            formatDateShort(d) {
                if (!d) return '';
                // console.log(`DEBUG: ${this.$options.name}.filters.formatDateShort this=`, this);
                return moment(d).format('L');
                // let fmt = moment.localeData().longDateFormat('L').replace('/[,\/-/.]*\s*Y+\s*/', '');
                // let fmt = moment.localeData().longDateFormat('L').replace('YYYY', '').trim('.');
                // console.log(`DEBUG: ${this.$options.name}.filters.formatDateShort fmt=`, fmt);
                // return moment(d).format(fmt);
            },
            formatWeekday(d) {
                if (!d) return '';
                return moment(d).format('ddd').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
            },
        },

        // =============== Methods ===============
        methods: {
            scheduleNew(e, slot) {
                // console.log(`DEBUG: ${this.$options.name}.scheduleNew slot=`, slot);
                if (slot.isRecordEnabled) {
                    this.$router.push({ name: 'schedule-new', params: { coreWorkplaceId: slot.coreWorkplaceId, timeOf: slot.timeOf } });
                }
            },

            scheduleEdit(evt, visit) {
                this.$router.push({ name: 'schedule-edit', params: { id: visit.id } });
            },

            showContextMenu(evt, visit, slot) {
                this.$refs.visitContextMenu.show(evt, visit, slot);
            },

            clearTimer() {
                if (this.refreshTimerId) {
                    // console.log(`DEBUG: ${this.$options.name}.clearTimer() clear interval`);
                    clearInterval(this.refreshTimerId);
                }
            },

            setTimer(interval) {
                // console.log(`DEBUG: ${this.$options.name}.setTimer(${interval}) this.refreshTimerId=`, this.refreshTimerId);
                this.clearTimer();
                if (interval) {
                    // console.log(`DEBUG: ${this.$options.name}.setTimer(${interval})`);
                    this.refreshTimerId = setInterval(this.getData, interval * 1000);
                }
            },

            getRecordTitle(rec) {
                return (
                    rec.time + ' ' + rec.client_fullname + ' # ' + rec.client_code + '\n' +
                    (rec.sum_unpaid_client ? this.$t('attribute.common.sum_unpaid') + ': ' + rec.sum_unpaid_client + '\n' : '') +
                    this.$t('attribute.common.phone_short') + ' ' + (rec.client_phones ?? '-') + '\n' +
                    rec.visit_type_name + '\n' +
                    (rec.commentary ? (rec.commentary + '\n') : '') +
                    (rec.time_created ? this.$t('app.created') + moment(rec.time_created).format('llll') + ', ' + rec.user_created_fullname + '\n' : '') +
                    (
                        rec.time_modified && (rec.time_modified != rec.time_created) ?
                        this.$t('app.modified') + moment(rec.time_modified).format('llll') + ', ' + rec.user_modified_fullname :
                        ''
                    )
                );
            },

            startDrag(evt, visit) {
                if (tools.isScheduleEditable(visit.status_code)) {
                    // console.log(`DEBUG: ${this.$options.name}.startDrag() visit=`, visit);
                    evt.dataTransfer.dropEffect = 'move';
                    evt.dataTransfer.effectAllowed = 'move';
                    evt.dataTransfer.setData('visit', JSON.stringify(visit));
                } else {
                    evt.preventDefault();
                }
            },

            async onDrop(evt, slot) {
                const visit = JSON.parse(evt.dataTransfer.getData('visit'));
                // console.log(`DEBUG: ${this.$options.name}.onDrop() evt=`, evt);
                // console.log(`DEBUG: ${this.$options.name}.onDrop() slot=`, slot);
                // console.log(`DEBUG: ${this.$options.name}.onDrop() visit=`, visit);
                await tools.moveScheduleRec(this, visit, slot);
            },

            calcSchedule() {
                // console.log(`DEBUG: ${this.$options.name}.calcSchedule() this.visitDate=`, this.visitDate);
                // console.log(`DEBUG: ${this.$options.name}.calcSchedule() this.widthCell=`, this.widthCell);
                // console.log(`DEBUG: ${this.$options.name}.calcSchedule() this.canvas=`, this.canvas);

                this.dayNumber = moment(this.visitDate).format('E');
                let dayEvenOdd = moment(this.visitDate).get('date') % 2;
                this.isWeekend = [6, 7].includes(parseInt(this.dayNumber));

                let x0 = 0;
                let y0 = 0;
                // let widthTime = 61;
                // let widthCell = 151;
                // let heightHeader = 41;

                let minMinutes = parseInt(this.visitGroup.time_from.split(':')[0]) * 60 + parseInt(this.visitGroup.time_from.split(':')[1]);
                let maxMinutes = parseInt(this.visitGroup.time_to.split(':')[0]) * 60 + parseInt(this.visitGroup.time_to.split(':')[1]);
                let groupDuration = parseInt(this.visitGroup.duration);
                // let scale = parseInt(this.visitGroup.scale);
                let nowMinutes = moment().hour() * 60 + moment().minutes();

                // this.canvas.width = x0 + widthTime + this.widthCell * this.workplaces.length;
                // this.canvas.width = Math.min(this.$refs.scheduleCanvas.clientWidth, x0 + this.widthTime + this.widthCell * this.workplaces.length);
                // this.canvas.height = maxMinutes - minMinutes + groupDuration;

                this.timeSlots = [];
                for (let i = minMinutes - groupDuration; i < maxMinutes + groupDuration; i += groupDuration) {
                    this.timeSlots.push({
                        minute: i,
                        time: `${Math.trunc(i / 60).toString().padStart(2, '0')}:${(i % 60).toString().padStart(2, '0')}`,
                        x: x0,
                        y: y0 + i - minMinutes + groupDuration + this.heightHeader - 1,
                        dx: this.widthTime,
                        dy: groupDuration + 1,
                        is_now: nowMinutes >= i && nowMinutes < i + groupDuration,
                    });
                }

                let visibleWorkplaceNum = 0;
                this.workplaces.forEach((wp, currentWorkplaceNum) => {
                    // console.log(`DEBUG: ${this.$options.name}.calcSchedule(${wp.user_name}, ) wp=`, wp);
                    wp.x = x0 + (this.widthTime - 1) + (this.widthCell - 1) * visibleWorkplaceNum;
                    wp.y = y0;
                    wp.is_offtime = true;
                    wp.dx = this.widthCell;
                    wp.dy = this.heightHeader;

                    wp.grid = [];
                    for (let currentGridMinute = minMinutes - groupDuration; currentGridMinute < maxMinutes + groupDuration; currentGridMinute += wp.duration) {
                        wp.grid.push({
                            minute: currentGridMinute,
                            time: `${Math.trunc(currentGridMinute / 60).toString().padStart(2, '0')}:${(currentGridMinute % 60).toString().padStart(2, '0')}`,
                            x: wp.x,
                            y: y0 + currentGridMinute - minMinutes + groupDuration + this.heightHeader - 1,
                            dx: this.widthCell,
                            dy: wp.duration + 1
                        });
                    }

                    wp.slots = [];
                    wp.worktime.forEach(wt => {
                        // console.log(`DEBUG: ${this.$options.name}.calcSchedule(day=${this.dayNumber}, wt=`, wt);
                        wt.days = [wt.is_monday, wt.is_tuesday, wt.is_wednesday, wt.is_thursday, wt.is_friday, wt.is_saturday, wt.is_sunday];
                        wt.enabled =
                            wt.days[this.dayNumber - 1] &&
                            (wt.day_even === dayEvenOdd || wt.day_even === null) &&
                            moment(wt.date_from).isSameOrBefore(this.visitDate, 'day') &&
                            (moment(wt.date_to).isSameOrAfter(this.visitDate, 'day') || !wt.date_to)
                        ;
                        // console.log(`DEBUG: ${this.$options.name}.calcSchedule(day=${this.dayNumber}, currentDate=${this.visitDate} wt=`, wt);

                        if (wt.enabled) {
                            let cellMinMinutes = parseInt(wt.time_from.split(':')[0]) * 60 + parseInt(wt.time_from.split(':')[1]);
                            let cellMaxMinutes = parseInt(wt.time_to.split(':')[0]) * 60 + parseInt(wt.time_to.split(':')[1]);
                            let cellDuration = parseInt(wt.duration || 30); // !!! hardcode

                            for (let currentSlotMinute = cellMinMinutes; currentSlotMinute < cellMaxMinutes; currentSlotMinute += cellDuration) {
                                let timeString = `${Math.trunc(currentSlotMinute / 60).toString().padStart(2, '0')}:${(currentSlotMinute % 60).toString().padStart(2, '0')}`;
                                wp.is_offtime = false;
                                wp.slots.push({
                                    coreWorkplaceId: wp.id,
                                    visitWorktimeId: wt.id,
                                    isRecordEnabled: true,
                                    // timeOf: new Date(`${moment(this.visitDate).format('YYYY-MM-DD')} ${timeString}`),
                                    timeOf: moment(`${moment(this.visitDate).format('YYYY-MM-DD')} ${timeString}`).toDate(),
                                    minute: currentSlotMinute,
                                    time: timeString,
                                    x: wp.x,
                                    y: y0 + currentSlotMinute - minMinutes + groupDuration + this.heightHeader - 1,
                                    dx: this.widthCell,
                                    dy: cellDuration + 1
                                });
                                // console.log(`DEBUG: `, `${Math.trunc(currentSlotMinute / 60).toString().padStart(2, '0')}:${(currentSlotMinute % 60).toString().padStart(2, '0')}`);
                            }
                        }
                    });

                    wp.lockedSlots = [];
                    for (const wl of wp.locks) {
                        wl.days = [wl.is_monday, wl.is_tuesday, wl.is_wednesday, wl.is_thursday, wl.is_friday, wl.is_saturday, wl.is_sunday];
                        wl.enabled =
                            wl.days[this.dayNumber - 1] &&
                            (wl.day_even === dayEvenOdd || wl.day_even === null) &&
                            moment(wl.date_from).isSameOrBefore(this.visitDate, 'day') &&
                            (moment(wl.date_to).isSameOrAfter(this.visitDate, 'day') || !wl.date_to)
                        ;
                        if (wl.enabled) {
                            let cellMinMinutes = Math.max(minMinutes - groupDuration, parseInt(wl.time_from.split(':')[0]) * 60 + parseInt(wl.time_from.split(':')[1]));
                            let cellMaxMinutes = Math.min(maxMinutes + groupDuration, parseInt(wl.time_to.split(':')[0]) * 60 + parseInt(wl.time_to.split(':')[1]));
                            let cellDuration = parseInt(wl.duration || 30); // !!! hardcode

                            for (let currentLockMinute = cellMinMinutes; currentLockMinute < cellMaxMinutes; currentLockMinute += cellDuration) {
                                let timeString = `${Math.trunc(currentLockMinute / 60).toString().padStart(2, '0')}:${(currentLockMinute % 60).toString().padStart(2, '0')}`;
                                wp.lockedSlots.push({
                                    id: wl.id,
                                    coreWorkplaceId: wp.id,
                                    isRecordEnabled: !wl.is_block,
                                    // timeOf: new Date(`${moment(this.visitDate).format('YYYY-MM-DD')} ${timeString}`),
                                    timeOf: moment(`${moment(this.visitDate).format('YYYY-MM-DD')} ${timeString}`).toDate(),
                                    minute: currentLockMinute,
                                    time: timeString,
                                    x: wp.x,
                                    y: y0 + currentLockMinute - minMinutes + groupDuration + this.heightHeader - 1,
                                    dx: this.widthCell,
                                    dy: cellDuration + 1,
                                    bgcolor: wl.bgcolor,
                                    color: wl.color,
                                    name: currentLockMinute == cellMinMinutes ? `${Math.trunc(currentLockMinute / 60).toString().padStart(2, '0')}:${(currentLockMinute % 60).toString().padStart(2, '0')} ${wl.lock_name}` : ''
                                });
                            }
                        }
                    }

                    wp.records.forEach(rec => {
                        wp.is_offtime = false; // if any record exists, do not hide this workplace despite it is offtime
                        rec.timeOf = new Date(rec.time_of);
                        rec.minute = rec.timeOf.getHours() * 60 + rec.timeOf.getMinutes();
                        // rec.minute = parseInt(rec.visit_time.split(':')[0]) * 60 + parseInt(rec.visit_time.split(':')[1]);
                        rec.time = `${Math.trunc(rec.minute / 60).toString().padStart(2, '0')}:${(rec.minute % 60).toString().padStart(2, '0')}`;
                        rec.y = y0 + rec.minute - minMinutes + groupDuration + this.heightHeader - 1;
                        rec.dy = rec.duration + 1;
                        rec.icon_class = tools.getVisitIcon(rec);
                        rec.icon_color = tools.getVisitColor(rec);
                        rec.brightness = tools.getBrightness(rec.visit_type_color);
                    });

                    // check visits for overlap
                    wp.records.forEach(rec => {
                        let overlapQty = 0;
                        let overlapLessQty = 0;
                        // let overlapMoreQty = 0;
                        wp.records.forEach(rec2 => {
                            // if (rec.id != rec2.id) {
                            //     // console.log(`DEBUG: ${this.$options.name}.calcSchedule() checking...`);
                            //     console.log(`${wp.user_name}: ${rec.client_fullname}(${rec.minute}+${rec.duration}) vs =${rec.client_fullname}(${rec2.minute}+${rec2.duration})`);
                            // }
                            if (
                                rec.id != rec2.id &&
                                ((rec.status_code != 'canceled' && rec2.status_code != 'canceled') || this.isShowCanceled) &&
                                (
                                    (rec.minute <= rec2.minute && rec.minute + rec.duration > rec2.minute) ||
                                    (rec.minute >= rec2.minute && rec.minute < rec2.minute + rec2.duration)
                                )
                            ) {
                                // Overlap found
                                overlapQty += 1;
                                if (rec2.id < rec.id) overlapLessQty += 1;
                                // if (rec2.id > rec.id) overlapMoreQty += 1;
                            }
                        });

                        if (overlapQty) {
                            // console.log(`DEBUG: ${this.$options.name}.calcSchedule()`);
                            // console.log(`user=${wp.user_name}, client=${rec.client_fullname}, time=${rec.time}`);
                            // console.log(`overlapQty=${overlapQty}, overlapLessQty=${overlapLessQty}, overlapMoreQty=${overlapMoreQty}`);
                            rec.dx = this.widthCell / (overlapQty + 1);
                            rec.x = wp.x + rec.dx * overlapLessQty;
                        } else {
                            rec.x = wp.x;
                            rec.dx = this.widthCell;
                        }
                    });

                    if (!wp.is_offtime || this.isShowOfftime) visibleWorkplaceNum += 1;

                    // console.log(`DEBUG: ${this.$options.name}.calcSchedule() ${wp.user_name}=`, wp);
                });
                // console.log(`DEBUG: ${this.$options.name}.calcSchedule() this.dayNumber=`, this.dayNumber);
            },

            async getData() {
                // console.log(`DEBUG: ${this.$options.name}.getData() now is ${new Date()}`);
                // console.log(`DEBUG: ${this.$options.name}.getData() document.hidden=`, document.hidden);
                // console.log(`DEBUG: ${this.$options.name}.getData() this.getPageHidden()=`, this.getPageHidden());
                if (!this.getPageHidden()) {
                    // console.log(`DEBUG: ${this.$options.name}.getData() ${(new Date())} refresh started`);
                    if (this.visitGroupId) {
                        try {
                            this.isDisplaySpinner = true;
                            // const dateISOString = this.visitDate.toISOString().split('T')[0];
                            const dateISOString = moment(this.visitDate).format(moment.HTML5_FMT.DATE);
                            const url = `${this.baseUrl}/schedule?visitGroup=${this.visitGroupId}&dateFrom=${dateISOString}&dateTo=${dateISOString}`;
                            const response = await this.authenticatedAxios.get(url);
                            // console.log(`DEBUG: ${this.$options.name}.getData url=${url}, data=`, response.data);
                            this.workplaces = response.data.workplaces;
                            this.visitGroup = response.data.visitGroup;
                            this.calcSchedule();
                        } catch (err) {
                            tools.displayApiError(this, err);
                        } finally {
                            this.isDisplaySpinner = false;
                        }
                    }
                    this.isTimeToRefreshPassed = false;
                } else {
                    // its time to refresh but page is hidden
                    // set the flag
                    // console.log(`DEBUG: ${this.$options.name}.getData() ${(new Date())} refresh skipped`);
                    this.isTimeToRefreshPassed = true;
                }
            },

            async onVisibilityChanged(evt) {
                // console.log(`DEBUG: ${this.$options.name}.onVisibilityChanged() evt=`, evt);
                // console.log(`DEBUG: ${this.$options.name}.onVisibilityChanged() now is ${new Date()}`);
                // console.log(`DEBUG: ${this.$options.name}.onVisibilityChanged() document.hidden=`, document.hidden);
                // console.log(`DEBUG: ${this.$options.name}.onVisibilityChanged() this.getPageHidden()=`, this.getPageHidden());
                if (this.isTimeToRefreshPassed && !this.getPageHidden()) {
                    // console.log(`DEBUG: ${this.$options.name}.onVisibilityChanged() ${(new Date())} force refresh`);
                    await this.getData();
                    this.setTimer(this.refreshInterval); // includes clearTimer()
                }
            },

            onResize() {
                if (this.$refs.scheduleCanvas) {
                    let wrapperTop = this.$refs.scheduleCanvas.getBoundingClientRect().top;
                    this.maximalHeight = `calc(${window.innerHeight - wrapperTop - 40}px - 0.5rem)`;
                    // console.log(`DEBUG: ${this.$options.name}.onResize() maximalHeight=${this.maximalHeight}`);
                }
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this=`, this);
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            });
            this.onResize();
            await this.getData();
            this.setTimer(this.refreshInterval);
        },
        beforeDestroy() {
            this.clearTimer();
        }
    }
</script>

<style lang="scss" src="@/assets/schedule.scss"></style>
