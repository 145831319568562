<!--
    Author: Eduard Grebenyukov
    Date: 2020-02-02
-->

<!--
    labelPosition: after | above
-->

<template>
    <div :class="(size === 'large' ? 'switch-lg' : 'switch-sm') + ' ' + (labelPosition === 'above' ? 'form-group' : 'mr-3') + ' ' + (required ? 'field-required' : '')">
        <div v-if="labelPosition === 'above'" style="height:1.8rem;">{{label}}</div>
        <div v-if="labelPosition === 'above'" class="custom-control custom-switch">
            <input
                :id="id"
                type="checkbox"
                class="custom-control-input"
                v-model="itemData"
                :required="required"
                :disabled="disabled || readonly"
                @change="itemModified($event)"
                :style="`${inputStyle}`"
            >
            <label :for="id" class="custom-control-label"></label>
        </div>
        <div v-if="labelPosition === 'after'" class="custom-control custom-switch switch-inline" :class="{ 'field-required': required }" >
            <input
                :id="id"
                type="checkbox"
                class="custom-control-input"
                v-model="itemData"
                :required="required"
                :disabled="disabled || readonly"
                @change="itemModified($event)"
                :style="`${inputStyle}`"
            >
            <label class="custom-control-label" :for="id">{{label}}</label>
        </div>
        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import tools from '@/components/lib/tools';

    const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'FieldSwitch',

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' }},
            size: { type: String, default: () => { return 'large' }},
            value: Boolean,
            defaultValue: Boolean,
            disabled: Boolean,
            readonly: Boolean,
            required: Boolean,
            saveState: String,
            saveSessionState: String,
            inputStyle: String,
            errorMessage: String,
        },

        // =============== Data ===============
        data() {
            return {
                itemData: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Watch ===============
        watch: {
            value() {
                this.itemData = this.value;
                // console.log(`DEBUG: ${this.$options.name}.watch.value this.value=`, this.value);
                if (tools.isBooleanEmpty(this.itemData)) this.setDefaultValue();
            },
        },

        // =============== Methods ===============
        methods: {
            itemModified(e) {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() e.target.checked=`, e.target.checked);
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.itemData=`, this.itemData);
                if (this.saveState) {
                    // Save current value
                    this.authenticatedAxios.post(`${this.baseUrl}/${userSettingsUrl}`, { code: this.saveState, value: (this.itemData ? 'true' : 'false') });
                }
                if (this.saveSessionState) {
                    // console.log(`DEBUG: ${this.$options.name}.itemModified() this.itemData=`, this.itemData);
                    sessionStorage.setItem(this.saveSessionState, (this.itemData ? 'true' : 'false'));
                }
                // this.$emit('update:value', e.target.checked);
                this.$emit('update:value', this.itemData);
                this.$emit('modified');
            },

            // setValue(value) {
            //     let checkbox = document.getElementById(this.id);
            //     checkbox.checked = value;
            // },

            async setDefaultValue() {
                if (this.saveState) {
                    // Restore stored value
                    let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
                    // console.log(`DEBUG: ${this.$options.name}.setDefaultValue() response=`, response);
                    let item = response.data.rows[0];
                    if (item) this.itemData = (item.value === 'true');
                    this.itemModified(this);
                }
                if (this.saveSessionState) {
                    // let sessionData = JSON.parse(sessionStorage.getItem(this.saveSessionState));
                    let sessionData = sessionStorage.getItem(this.saveSessionState);
                    // console.log(`DEBUG: ${this.$options.name}.setDefaultValue() sessionData=`, sessionData);
                    if (sessionData === 'true') this.itemData = true;
                    if (sessionData === 'false') this.itemData = false;
                    // if (!tools.isBooleanEmpty(sessionData)) this.itemData = sessionData === 'true';
                    this.itemModified(this);
                }
                if (tools.isBooleanEmpty(this.itemData) && !tools.isBooleanEmpty(this.defaultValue)) {
                    // console.log(`DEBUG: ${this.$options.name}.setDefaultValue() this.defaultValue=`, this.defaultValue);
                    this.itemData = this.defaultValue;
                    this.itemModified(this);
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemData = this.value;
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.itemData=`, this.itemData);
            await this.setDefaultValue();
        },
    }
</script>

<style scoped>

    .required-mark {
        color:red;
        /* font-size: 1.1rem; */
    }

    .switch-lg .custom-switch {
        padding-left: 2.25rem;
        padding-bottom: 1rem;
    }

    .switch-lg .custom-control-label {
        padding-top: 0.5rem;
        padding-left: 2rem;
        padding-bottom: 0.1rem;
    }

    .switch-lg .custom-switch .custom-control-label::before {
        left: -2.25rem;
        height: 2rem;
        width: 3.5rem;
        pointer-events: all;
        border-radius: 1rem;
    }

    .switch-lg .custom-switch .custom-control-label::after {
        top: calc(0.25rem + 2px);
        left: calc(-2.25rem + 2px);
        width: calc(2rem - 4px);
        height: calc(2rem - 4px);
        background-color: #adb5bd;
        border-radius: 2rem;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    }
    @media (prefers-reduced-motion: reduce) {
        .custom-switch .custom-control-label::after {
            transition: none;
        }
    }

    .switch-lg .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
        background-color: #fff;
        -webkit-transform: translateX(1.5rem);
        transform: translateX(1.5rem);
    }

    .switch-inline label {
        padding-top: 0.1em;
        /* font-size: 1rem; */
    }

</style>
