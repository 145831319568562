<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-04
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >

        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div :class="editFormData.id || editFormData.core_client_id ? 'col-sm-12 col-xl-8': 'col'">
                        <div class="form-row">
                            <!-- <div class="col-sm">
                                <FieldNumber
                                    id='crm_call_id'
                                    :label="$t('component.CRMRequestEdit.crm_call_id')"
                                    :value.sync="editFormData.crm_call_id"
                                    readonly
                                    @modified="itemModified"
                                />
                            </div> -->
                            <div class="col-sm">
                                <FieldSelect
                                    ref="channel_id"
                                    id="channel_id"
                                    :label="$t('crm.channel')"
                                    :value.sync="editFormData.channel_id"
                                    readonly
                                    source="enum/crm_channel"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    id='subscriber_identifier'
                                    :label="$t('crm.subscriber_identifier')"
                                    :value.sync="editFormData.subscriber_identifier"
                                    required
                                    @modified="itemModified"
                                />
                            </div>
                        </div> 
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldDatetime
                                    id='time_of'
                                    :label="$t('app.datetime')"
                                    :value.sync="editFormData.time_of"
                                    required
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldSelect
                                    ref="core_workplace_id"
                                    id="core_workplace_id"
                                    :label="$t('object.core_user')"
                                    :value.sync="editFormData.core_workplace_id"
                                    source="v_workplace"
                                    keyLabel="user_name"
                                    readonly
                                />
                            </div>
                        <!-- </div>
                        <div class="form-row"> -->
                        </div> 
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldPopupList
                                    id="core_client_id"
                                    :label="$t('object.core_client')"
                                    :value.sync="editFormData.core_client_id"
                                    source="v_client"
                                    :listConfig="clientListConfig"
                                    keyLabel="fullname"
                                    showEmptyRow
                                    maxWidth="1000px"
                                    @modified="itemModified"
                                >
                                    <button slot="buttons"
                                        v-if="editFormData.core_client_id"
                                        @click.prevent="openVisit()"
                                        class="btn btn-outline-secondary"
                                        :title="$t('Visit.open_visit')"
                                    >
                                        <i class="fas fa-id-card"></i>
                                    </button>
                                </FieldPopupList>
                            </div>
                        </div>
                        <div v-if="!editFormData.core_client_id" class="form-row">
                            <div class="col-sm">
                                <FieldString
                                    id='lastname'
                                    :label="$t('attribute.core_person.lastname')"
                                    :value.sync="editFormData.lastname"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    id='firstname'
                                    :label="$t('attribute.core_person.firstname')"
                                    :value.sync="editFormData.firstname"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    id='patronymic'
                                    :label="$t('attribute.core_person.patronymic')"
                                    :value.sync="editFormData.patronymic"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm-4">
                                <FieldSelect
                                    id='request_reason_id'
                                    :label="$t('crm.request_reason')"
                                    :value.sync="editFormData.request_reason_id"
                                    source="enum/crm_request_reason"
                                    showEmptyRow
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm-8">
                                <FieldString
                                    id='request_reason_text'
                                    :label="$t('crm.request_reason_text')"
                                    :value.sync="editFormData.request_reason_text"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm-4">
                                <FieldSelect
                                    id='request_result_id'
                                    :label="$t('crm.request_result')"
                                    :value.sync="editFormData.request_result_id"
                                    source="enum/crm_request_result"
                                    order="sort_order"
                                    showEmptyRow
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm-8">
                                <FieldString
                                    id='request_result_text'
                                    :label="$t('crm.request_result_text')"
                                    :value.sync="editFormData.request_result_text"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm-4">
                                <FieldSelect
                                    id='request_source_id'
                                    :label="$t('crm.request_source')"
                                    :value.sync="editFormData.request_source_id"
                                    source="enum/crm_request_source"
                                    order="sort_order"
                                    showEmptyRow
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm-8">
                                <FieldString
                                    id='request_source_text'
                                    :label="$t('crm.request_source_text')"
                                    :value.sync="editFormData.request_source_text"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.core_person.commentary')"
                                    :value.sync="editFormData.commentary"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-if="editFormData.id || editFormData.core_client_id" class="col-sm-12 col-xl-4">
                        <!-- Right sidebar -->
                        <div class="row mt-2" v-if="clientArray && clientArray.length > 0 && !this.editFormData.core_client_id">
                            <div class="col">
                                <Grid ref="clients" :source="clientArray" :config="clientGridConfig" @select-client="clientSelected($event)" />
                            </div>
                        </div>
                        <div class="row mt-2" v-if="editFormData.core_client_id" >
                            <div class="col">
                                <ClientInfo :clientId="editFormData.core_client_id" />
                            </div>
                        </div>
                        <div class="row mt-2" v-if="editFormData.id">
                            <div class="col">
                                <div class="btn-toolbar" style="padding: 0 4px 4px 0;" role="toolbar">
                                    <div class="h6">{{ $t('component.CRMRequestEdit.linkedSchedules') }}</div>
                                    <ButtonCustom
                                        :title="$t('component.CRMRequestEdit.createSchedule')"
                                        buttonClass="btn-warning btn-sm ml-1"
                                        iconClass="fas fa-plus fa-fw"
                                        @click="createSchedulePressed()"
                                    />
                                </div>
                                <Grid ref="schedules" :source="schedulesArray" :config="schedulesGridConfig" @select-visit="visitSelected($event)" />
                            </div>
                        </div>
                        <div class="row mt-4" v-if="editFormData.id">
                            <div class="col">
                                <div class="btn-toolbar" style="padding: 0 4px 4px 0;" role="toolbar">
                                    <div class="h6">{{ $t('component.CRMRequestEdit.linkedCall') }}</div>
                                    <ButtonCustom
                                        v-if="!editFormData.crm_call_id"
                                        :title="$t('component.CRMRequestEdit.addCrmCallLink')"
                                        buttonClass="btn-warning btn-sm ml-1"
                                        iconClass="fas fa-link fa-fw"
                                        @click="linkToCrmCallPressed()"
                                    />
                                    <ButtonCustom
                                        v-if="requestCrmCallArray && requestCrmCallArray.length > 0"
                                        :title="$t('component.CRMRequestEdit.deleteCrmCallLink')"
                                        buttonClass="btn-outline-danger btn-sm ml-1"
                                        iconClass="fas fa-unlink fa-fw"
                                        @click="deleteCrmCallLinkPressed()"
                                    />
                                </div>
                                <Grid ref="requestCall" :source="requestCrmCallArray" :config="requestCrmCallGridConfig" />
                            </div>
                        </div>
                        <!-- <div class="row mt-4" v-if="phoneCallsArray && phoneCallsArray.length > 0">
                            <div class="col">
                                <Grid ref="phoneCalls" :source="phoneCallsArray" :config="phoneCallsGridConfig" @select-client="phoneCallSelected($event)" />
                            </div>
                        </div> -->
                        <!-- <div class="row mt-4" v-if="clientRequestsArray && clientRequestsArray.length > 0">
                            <div class="col">
                                <Grid ref="clientRequests" :source="clientRequestsArray" :config="clientRequestsGridConfig" />
                            </div>
                        </div> -->
                        <!-- /Right sidebar -->
                    </div>
                </div>
            </div>
        </form>

        <ModalWindow
            v-if="isDisplayCrmCallList"
            width="900px" max-width="90%" max-height="90vh"
            :header="$t('component.CRMRequestEdit.addCrmCallLink')"
            @cancel="isDisplayCrmCallList = false"
        >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="calls" :source="crmCallToLinkArray" :config="crmCallToLinkGridConfig" @item-selected="crmCallToLinkSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>

        <WarningDelete
            v-if="isDisplayWarningDeleteCrmCallLink"
            :text="$t('component.CRMRequestEdit.confirmDeleteCrmCallLink')"
            @process="deleteCrmCallLink($event)"
        />

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    // import moment from 'moment';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import WarningDelete from '@/components/lib/regions/WarningDelete';
    import ClientInfo from '@/components/pages/clients/ClientInfo';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldPopupList from '@/components/lib/controls/FieldPopupList';
    import FieldDatetime from '@/components/lib/controls/FieldDatetime';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldText from '@/components/lib/controls/FieldText';
    // import FieldNumber from '@/components/lib/controls/FieldNumber';
    import Grid from '@/components/lib/regions/Grid';

    const componentUrl = 'crm_request';

    export default {
        name: 'CRMRequestEdit',

        components: {
            TemplatePageStandard, ModalWindow, WarningDelete, ClientInfo,
            ButtonClose, ButtonSave, ButtonDelete, ButtonCustom,
            FieldSelect, FieldPopupList, FieldDatetime, FieldString, FieldText, //FieldNumber,
            Grid,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Props ===============
        props: {
            id: [String, Number],
            // timeOf: String,
            timeOf: Date,
            phoneNum: String,
            callId: Number,
            visitToLink: Object,
        },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                editFormData: {
                    // id: null,
                    channel_id: null,
                    core_workplace_id: null,
                    core_client_id: null,
                    crm_call_id: null,
                    request_reason_id: null,
                    request_result_id: null,
                    request_source_id: null,
                    time_of: null,
                    subscriber_identifier: null,
                    request_reason_text: null,
                    request_result_text: null,
                    request_source_text: null,
                    lastname: null,
                    firstname: null,
                    patronymic: null,
                    commentary: null,
                },
                oldFormData: {},
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.crm-requests'), to: '/crm/requests' },
                    { text: '#', to: '#' },
                ],
                clientListConfig: {
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'string', key:'code', header:this.$t('attribute.common.code') },
                        { type:'string', key:'fullname', header:this.$t('object.core_client') },
                        { type:'select', key:'sex', header:this.$t('attribute.core_person.sex'), source: this.sexKind, keyId: 'code', keyLabel: 'name_short' },
                        { type:'date', key:'dob', header:this.$t('attribute.core_person.dob') },
                        { type:'string', key:'phones', header:this.$t('Contacts.phones') },
                        { type:'string', hidden:true, key:'phones_stripped', header:this.$t('Contacts.phones') },
                     ],
                    order: [ 'fullname' ],
                    displayToolbar: true,
                },
                clientArray: null,
                clientGridConfig: {
                    apiUrl: null,
                    title: this.$t('component.CRMRequestEdit.clients'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        // { type:'icon', key:'select_client', icon:'fas fa-chevron-left', title:this.$t('component.CRMRequestEdit.selectClient') },
                        { type:'string', key:'client_name', header:this.$t('object.core_client'), emit:'select-client', class:'link' },
                        // { type:'hidden', key:'core_person_id', header:this.$t('Settings.user'), required:true, source:'users', keyLabel:'username' },
                        // { type:'string', key:'phone_num', header:this.$t('attribute.common.phone') },
                    ],
                    order: [ 'client_name' ],
                    displayToolbar: false,
                    displayPagination: false,
                },
                schedulesArray: null,
                schedulesGridConfig: {
                    apiUrl: null,
                    // title: this.$t('component.CRMRequestEdit.linkedSchedules'),
                    readonly: true,
                    columns: [
                        {
                            type:'icon', key:'icon', sortable:false,
                            $title:(entry) => { return entry.status_name }, align:'center',
                            $icon: entry => tools.getVisitIcon(entry),
                            $cellStyle: entry => `color:${tools.getVisitColor(entry)};`,
                        },
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        { type:'datetime', key:'visit_time_of', header:this.$t('attribute.common.datetime_of'), emit:'select-visit', style:"color: #056bbf;" },
                        // { type:'string', key:'dept_name', header:this.$t('object.core_dept') },
                        { type:'string', key:'room_name', header:this.$t('object.core_room') },
                        { type:'string', key:'user_name', header:this.$t('app.employee') },
                    ],
                    order: [ 'visit_time_of' ],
                    displayToolbar: false,
                    displayPagination: false,
                },
                requestCrmCallArray: null,
                requestCrmCallGridConfig: {
                    apiUrl: null,
                    // title: this.$t('component.CRMRequestEdit.linkedCall'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'crm_call_id', header:this.$t('attribute.common.id'), readonly:true, align:'right' },
                        { type:'datetime', key:'call_time_of', header:this.$t('attribute.common.datetime_of') },
                        { type:'string', key:'caller_number', header:this.$t('attribute.common.phone') },
                        { type:'number', key:'duration', header:this.$t('attribute.common.dur') },
                        {
                            type:'html', key:'call_record_url', header:this.$t('attribute.crm_call.call_record_url_short'), sortable:false,
                            $value: entry => (entry.call_record_url ? `<a href="${entry.call_record_url}" target="_blank"><i class="fas fa-volume-up"></i></a>` : ``),
                            cellClass:'grid__icon text-center',
                            // align:'center', style:'font-size:1.2rem; padding: 0.25rem 0.5rem;'
                        },
                    ],
                    order: [ 'client_name' ],
                    displayToolbar: false,
                    displayPagination: false,
                },
                isDisplayWarningDeleteCrmCallLink: false,
                isDisplayCrmCallList: false,
                crmCallToLinkArray: null,
                crmCallToLinkGridConfig: {
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'crm_request_id', header:this.$t('attribute.common.id'), readonly:true, align:'right' },
                        { type:'number', key:'crm_call_id', header:this.$t('attribute.common.id'), readonly:true, align:'right' },
                        { type:'datetime', key:'call_time_of', header:this.$t('attribute.common.datetime_of') },
                        { type:'string', key:'caller_number', header:this.$t('attribute.common.phone') },
                        { type:'number', key:'duration', header:this.$t('attribute.common.duration') },
                    ],
                    displayToolbar: false,
                    row: { emit:'item-selected', style:'cursor: pointer;' },
                },
                // phoneCallsArray: null,
                // phoneCallsGridConfig: {
                //     apiUrl: null,
                //     title: this.$t('component.CRMRequestEdit.phoneCalls'),
                //     readonly: true,
                //     columns: [
                //         { type:'hidden', key:'core_client_id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                //         // { type:'icon', key:'select_client', icon:'fas fa-chevron-left', title:this.$t('component.CRMRequestEdit.selectClient') },
                //         { type:'datetime', key:'time_of', header:this.$t('attribute.common.datetime_of'), emit:'select-client', class:'link' },
                //         { type:'string', key:'client_name', header:this.$t('object.core_client'), emit:'select-client', class:'link' },
                //     ],
                //     order: [{ key:'time_of', direction:'desc' }],
                //     displayToolbar: false,
                //     displayPagination: false,
                // },
                // clientRequestsArray: null,
                // clientRequestsGridConfig: {
                //     apiUrl: null,
                //     title: this.$t('component.CRMRequestEdit.clientRequests'),
                //     readonly: true,
                //     columns: [
                //         { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                //         { type:'datetime', key:'time_of', header:this.$t('attribute.common.datetime_of'), emit:'select-client', class:'link' },
                //         { type:'string', key:'client_name', header:this.$t('object.core_client'), emit:'select-client', class:'link' },
                //     ],
                //     order: [ 'client_name' ],
                //     displayToolbar: false,
                //     displayPagination: false,
                // },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                evenOdd: 'evenOdd',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                sexKind: 'sexKind'
            }),
            apiUrl() { return `${this.baseUrl}/${componentUrl}` },
            displayGrids() {return typeof this.editFormData.id !='undefined' },
        },

        // =============== Watch ===============
        watch: {
            'editFormData.subscriber_identifier': {
                async handler() {
                    // let strippedPhone = tools.stripPhoneNumber(this.editFormData.subscriber_identifier);
                    // console.log(`DEBUG: ${this.$options.name}.watch(editFormData.subscriber_identifier)='${strippedPhone}' (${strippedPhone.length})`);
                    if (this.editFormData.subscriber_identifier && this.editFormData.subscriber_identifier.length > 0) {
                        try {
                            this.clientArray = (await this.authenticatedAxios.get(`${this.baseUrl}/get-phone-clients/${this.editFormData.subscriber_identifier}`)).data.rows;
                            // this.phoneCallsArray = (await this.authenticatedAxios.get(`${this.baseUrl}/get-phone-calls/${this.editFormData.subscriber_identifier}`)).data.rows;
                            // console.log(`DEBUG: ${this.$options.name}.watch(editFormData.subscriber_identifier) this.clientArray=`, this.clientArray);
                        } catch(err) {
                            // console.log(`DEBUG: ${this.$options.name}.watch(editFormData.subscriber_identifier) err=`, err);
                        }
                    } else {
                        // console.log(`DEBUG: ${this.$options.name}.watch(editFormData.subscriber_identifier)='${this.editFormData.subscriber_identifier}' (${this.editFormData.subscriber_identifier.length})`);
                        this.clientArray = null;
                        // this.phoneCallsArray = null;
                    }
                },
            },
            // 'editFormData.core_client_id': {
            //     async handler() {
            //         // console.log(`DEBUG: ${this.$options.name}.watch(editFormData.core_client_id)`);
            //         if (this.editFormData.core_client_id) {
            //             try {
            //                 this.clientRequestsArray = (await this.authenticatedAxios.get(`${this.baseUrl}/get-client-calls/${this.editFormData.core_client_id}`)).data.rows;
            //             } catch(err) {
            //                 // console.log(`DEBUG: ${this.$options.name}.watch(editFormData.core_client_id) err=`, err);
            //             }
            //         } else {
            //             this.clientRequestsArray = null;
            //         }
            //     },
            // },
            itemId: {
                async handler() {
                    // console.log(`DEBUG: ${this.$options.name}.watch(itemId)`);
                    if (this.itemId) {
                        try {
                            let filterString = tools.getFilterString({ where:[{ crm_request_id: this.itemId }]});
                            this.schedulesArray = (await this.authenticatedAxios.get(`${this.baseUrl}/get-request-visits${filterString}`)).data.rows;
                            // console.log(`DEBUG: ${this.$options.name}.watch(this.itemId) this.schedulesArray=`, this.schedulesArray);
                            await this.refreshRequestCrmCallArray();
                        } catch(err) {
                            // console.log(`DEBUG: ${this.$options.name}.watch(this.itemId) err=`, err);
                        }
                    } else {
                        this.schedulesArray = null;
                        this.requestCrmCallArray = null;
                    }
                },
            },
        },

        // =============== Validations ===============
        // validations: {
        //     editFormData: {
        //         username: { required },
        //         email: { required }
        //     }
        // },

        // =============== Methods ===============
        methods: {
            ...mapActions([ 'setClientToSchedule' ]),

            getTimeArray(fromHour, toHour, stepMinutes) {
                let a = [];
                for (let i = (fromHour || 0); i <= (toHour || 24); i++) {
                    let t = i.toString().padStart(2, '0') + ':00:00';
                    a.push({id: t, name: t});
                }
                return a;
            },

            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            setBreadcrumb() {
                this.breadcrumb[this.breadcrumb.length - 1].text = this.itemId ? this.itemId.toString() : this.$t('component.CRMRequestEdit.createNewRequest');
            },

            clientSelected(client) {
                // console.log(`DEBUG: ${this.$options.name}.clientSelected() client=`, client);
                this.editFormData.core_client_id = client.id;
            },

            phoneCallSelected(call) {
                // console.log(`DEBUG: ${this.$options.name}.phoneCallSelected() call=`, call);
                this.editFormData.core_client_id = call.core_client_id;
            },

            visitSelected(visit) {
                this.$router.push({ name: 'schedule-edit', params: { id: visit.visit_rec_id } });
            },

            async createSchedulePressed() {
                await this.saveData();
                await this.setClientToSchedule({
                    id: this.editFormData.core_client_id,
                    lastname: this.editFormData.lastname,
                    firstname: this.editFormData.firstname,
                    patronymic: this.editFormData.patronymic,
                    phoneNum: this.editFormData.subscriber_identifier,
                    crmRequestId: this.editFormData.id,
                });
                this.$router.push({ name: 'schedules' });
            },

            async linkToCrmCallPressed() {
                await this.saveData();
                try {
                    let filterString = tools.getFilterString({ where:[{ crm_request_id:this.itemId }], order:[{ key:'call_time_of', direction:'desc' }]});
                    this.crmCallToLinkArray = (await this.authenticatedAxios.get(`${this.baseUrl}/get-request-calls-to-link${filterString}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.linkToCrmRequestPressed() this.crmCallToLinkArray=`, this.crmCallToLinkArray);
                    this.isDisplayCrmCallList = true;
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async refreshRequestCrmCallArray() {
                let filterString = tools.getFilterString({ where:[{ crm_request_id: this.itemId }]});
                this.requestCrmCallArray = (await this.authenticatedAxios.get(`${this.baseUrl}/get-request-call${filterString}`)).data.rows;
                // console.log(`DEBUG: ${this.$options.name}.watch(this.itemId) this.requestCrmCallArray=`, this.requestCrmCallArray);
            },

            async crmCallToLinkSelected(crmCall) {
                this.isDisplayCrmCallList = false;
                this.editFormData.crm_call_id = crmCall.crm_call_id;
                this.itemModified();
                await this.saveData();
                await this.refreshRequestCrmCallArray();
            },

            deleteCrmCallLinkPressed() {
                this.isDisplayWarningDeleteCrmCallLink = true;
            },

            async deleteCrmCallLink(process) {
                this.isDisplayWarningDeleteCrmCallLink = false;
                if (process) {
                    this.editFormData.crm_call_id = null;
                    this.itemModified();
                    await this.saveData();
                    await this.refreshRequestCrmCallArray();
                }
            },

            async openVisit() {
                tools.openClientVisitList(this, this.editFormData.core_client_id, null);
            },

            gotData(data) {
                this.editFormData = data;
                this.isModified = false;
                this.msgItemModified = '';
                this.itemId = data.id;
                this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));

                this.setBreadcrumb();
                this.checkButtons();
            },

            async getData() {
                try {
                    let response = await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`);
                    // console.log(`DEBUG: ${this.$options.name}.getData() data=`, response.data);
                    this.gotData(response.data);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async linkToSchedule() {
                // console.log(`DEBUG: ${this.$options.name}.linkToCrmRequest(${this.crmRequestId}, ${this.editFormData.id})`);
                if (this.visitToLink) {
                    let rowData = { crm_request_id:this.itemId, visit_rec_id:this.visitToLink.visitRecId };
                    await this.authenticatedAxios.post(`${this.baseUrl}/crm_request_visit`, rowData);
                    // console.log(`DEBUG: ${this.$options.name}.linkToCrmRequest() response.data=`, response.data);
                }
            },

            async saveData() {
                if (this.isModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            // console.log(`DEBUG: ${this.$options.name}.saveData() Update this.editFormData=`, this.editFormData);
                            let response = (await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData));
                            this.gotData(response.data);
                            // this.isModified = false;
                            tools.displaySuccessSave(this);
                            // console.log('this.$refs=', this.$refs);
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveData() New this.editFormData=`, this.editFormData);
                            let response = await this.authenticatedAxios.post(this.apiUrl, this.editFormData);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response.data=`, response.data);
                            this.gotData(response.data);
                            this.$router.replace({ name: 'crm/request-edit', params: { id:this.itemId } });
                            // this.getData();
                            await this.linkToSchedule();
                            tools.displaySuccessSave(this);
                        }
                    } catch(err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },
            
            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() evt=`, evt);
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
                this.setBreadcrumb();
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(${this.id}, ${this.phoneNum}), id=`, this.id);
            // console.log(`DEBUG: this.id=`, this.id);
            // console.log(`DEBUG: this.timeOf=`, this.timeOf);
            // console.log(`DEBUG: typeof this.callId=`, typeof this.callId);

            this.itemId = parseInt(this.id);
            if (this.phoneNum)
                this.editFormData.subscriber_identifier = this.phoneNum;
            if (this.callId)
                this.editFormData.crm_call_id = this.callId;
            // this.editFormData.time_of = this.timeOf || `${thisTime.format('L')} ${thisTime.format('LTS')}`;
            // this.editFormData.time_of = this.timeOf || moment().format(moment.HTML5_FMT.DATETIME_LOCAL);
            this.editFormData.time_of = this.timeOf || (new Date()).toISOString();
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(${this.id}, ${this.phoneNum}), this.editFormData.time_of=`, this.editFormData.time_of);
            this.editFormData.core_workplace_id = this.currentUser.currentWorkplace.id;
            try {
                this.editFormData.channel_id = await tools.getEnumId(this, 'crm_channel', 'phone');
            } catch (err) {
                tools.displayApiError(this, err);
            }
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this=`, this);
            if (this.itemId) {
                await this.getData();
            } else {
                this.setBreadcrumb();
                this.checkButtons();
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));

            if (!this.itemId) {
                if (this.visitToLink) {
                    // this.visitRecId = this.visitToLink.visitRecId;
                    this.editFormData.core_client_id = this.visitToLink.coreClientId;
                    this.editFormData.lastname = this.visitToLink.lastname;
                    this.editFormData.firstname = this.visitToLink.firstname;
                    this.editFormData.patronymic = this.visitToLink.patronymic;
                    this.editFormData.subscriber_identifier = this.visitToLink.phoneNum;
                }
                this.itemModified();
            }
        },
    }
</script>

<style scoped>
</style>
