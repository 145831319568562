<!--
    Author: Eduard Grebenyukov
    Date: 2020-02-26
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>
        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-md-12 col-xl-9">
                        <!-- <h4>{{editFormData.name}} </h4> -->
                        <!-- <h6>{{$t('object.core_workplace')}}: {{editFormData.dept_name}} / {{editFormData.speciality_name }}</h6> -->
                    </div>
                </div>
                <div v-if="displayGrids" class="row">
                    <div class="col">
                        <Grid ref="visitTypeGrid" :config="visitTypeGridConfig" @modified="gridModified" />
                    </div>
                    <div v-if="editFormData.is_schedule" class="col">
                        <Grid ref="visitGroupGrid" :config="visitGroupGridConfig" @modified="gridModified" />
                    </div>
                    <div class="col">
                        <Grid ref="phoneGrid" :config="phoneGridConfig" @modified="gridModified" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <Grid ref="worktimeGrid" :config="worktimeGridConfig" @add-item="addWorktime" @edit-item="editWorktime($event.id)" />
                    </div>
                </div>
            </div>
        </form>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    //import { mapState, mapActions } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'WorkplaceEdit',

        components: {
            TemplatePageStandard,
            ButtonClose, ButtonSave, ButtonDelete,
            Grid,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Props ===============
        props: ['callerPage'],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                workplace: {},
                buttons: [],
                msgItemModified: '',
                isVisitTypeGridModified: false,
                isVisitGroupGridModified: false,
                isPhoneGridModified: false,
                editFormData: {},
                oldFormData: {},
                isDisplayWarningDelete: false,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-rights-users'), to: '/settings/rights/users' },
                    { text: 'user', to: '#' },
                    // { text: 'workplace', to: '#' },
                    // { text: this.$t('component.UserEdit.workplaces'), to: '#' },
                    { text: this.$t('object.core_workplace') },
                ],
                visitTypeGridConfig: {
                    apiUrl: 'visit_type_workplace',
                    filter: null,
                    title: this.$t('MainMenu.settings-visits-types'),
                    columns: [
                        { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'core_workplace_id', header:'Workplace', required: true, source:'users' },
                        { type:'select', key:'visit_type_id', header:this.$t('object.visit_type'), required:true, source:'visit_type' },
                        { type:'checkbox', key:'is_self_recording', header:this.$t('attribute.visit_type_workplace.is_self_recording'), defaultValue:false },
                        // { type:'string', key:'visit_type_display', header:this.$t('object.visit_type'), readonly:true },
                    ],
                    order: [ 'visit_type_id' ],
                    displayToolbar: true,
                    displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                },
                visitGroupGridConfig: {
                    apiUrl: 'visit_group_det',
                    filter: null,
                    title: this.$t('MainMenu.settings-visits-groups'),
                    columns: [
                        { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'core_workplace_id', header:'Workplace', required: true, source:'v_workplace', keyLabel:'workplace_name' },
                        { type:'select', key:'visit_group_id', header:this.$t('object.visit_group'), required:true, source:'visit_group' },
                        // { type:'string', key:'visit_type_display', header:this.$t('object.visit_type'), readonly:true },
                    ],
                    order: [ 'visit_group_id' ],
                    displayToolbar: true,
                    displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                },
                phoneGridConfig: {
                    apiUrl: 'core_phone',
                    filter: null,
                    title: this.$t('Contacts.phones'),
                    columns: [
                        { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'core_workplace_id', header:'Workplace', required:true, source:'v_workplace', keyLabel:'workplace_name' },
                        { type:'select', key:'phone_type_id', header:this.$t('Contacts.phone_type'), required:true, source:'enum/phone_type' },
                        { type:'string', key:'phone_num', header:this.$t('object.core_phone'), required:true },
                    ],
                    order: [ 'phone_num' ],
                    displayToolbar: true,
                    displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                },
                worktimeGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title: this.$t('MainMenu.settings-visits-worktime'),
                    readonly: true,
                    // core_workplace_id: null,
                    columns: [
                        { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'core_workplace_id', header:'Workplace', required: true },
                        { type:'select', key:'visit_lock_type_id', header:this.$t('object.visit_lock_type'), source:'visit_lock_type' },
                        { type:'date', key:'date_from', header:this.$t('attribute.common.date_from'), required:true },
                        { type:'date', key:'date_to', header:this.$t('attribute.common.date_to') },
                        { type:'string', key:'time_from', header:this.$t('attribute.common.time_from'), required:true },
                        { type:'string', key:'time_to', header:this.$t('attribute.common.time_to'), required:true },
                        { type:'number', key:'duration', header:this.$t('Settings.interval') },
                        { type:'html', key:'weekdays', header:this.$t('app.weekdays'), $value:this.getWeekdaysString },
                        { type:'select', key:'day_even', header:(this.$t('app.even') + ' / ' + this.$t('app.odd')), source:null },
                    ],
                    order: [ 'date_from' ],
                    displayToolbar: true,
                    displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'edit', 'delete' ],
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                evenOdd: 'evenOdd',
            }),
            apiUrl() { return `${this.baseUrl}/v_workplace` },
            displayGrids() { return typeof this.editFormData.id !='undefined' },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', /* 'delete', */ 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            getWeekdaysString(entry) {
                let aDays = [];
                if (entry.is_monday) aDays.push(this.$t('app.monday_short'));
                if (entry.is_tuesday) aDays.push(this.$t('app.tuesday_short'));
                if (entry.is_wednesday) aDays.push(this.$t('app.wednesday_short'));
                if (entry.is_thursday) aDays.push(this.$t('app.thursday_short'));
                if (entry.is_friday) aDays.push(this.$t('app.friday_short'));
                if (entry.is_saturday) aDays.push(this.$t('app.saturday_short'));
                if (entry.is_sunday) aDays.push(this.$t('app.sunday_short'));

                return aDays.join(', ');
            },

            async setGridFilter() {
                // console.log(`DEBUG: ${this.$options.name}.setGridFilter this.itemId=`, this.itemId);
                let filter = { where:[
                    { core_workplace_id:this.itemId },
                ]};
                this.visitTypeGridConfig.filter = filter;
                this.visitTypeGridConfig.columns.find(x => x.key === 'core_workplace_id').defaultValue = this.itemId;
                this.visitGroupGridConfig.filter = filter;
                this.visitGroupGridConfig.columns.find(x => x.key === 'core_workplace_id').defaultValue = this.itemId;
                this.phoneGridConfig.filter = filter;
                this.phoneGridConfig.columns.find(x => x.key === 'core_workplace_id').defaultValue = this.itemId;
                this.phoneGridConfig.columns.find(x => x.key === 'phone_type_id').defaultValue = await tools.getEnumId(this, 'phone_type', 'work');
                this.worktimeGridConfig.apiUrl = 'visit_worktime';
                this.worktimeGridConfig.filter = filter;
                this.worktimeGridConfig.columns.find(x => x.key === 'core_workplace_id').defaultValue = this.itemId;
                this.worktimeGridConfig.columns.find(x => x.key === 'day_even').source = this.evenOdd;
                // this.$refs.worktimeGrid.refresh();
                // !!! why its works without .refresh()???
                // console.log(`DEBUG: ${this.$options.name}.setGridFilter phone_type_id=`, await tools.getEnumId(this, 'phone_type', 'work'));
            },

            async getData() {
                try {
                    // let response = await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`);
                    let filterString = tools.getFilterString({ where:[{ id:this.itemId }]}); // ???!!!! why non previous variant? because it is not crud, but /view/*
                    this.editFormData = (await this.authenticatedAxios.get(`${this.apiUrl}${filterString}`)).data.rows[0];
                    // console.log(`DEBUG: ${this.$options.name}.getData methods.getData data=`, this.editFormData);
                    // this.editFormData = response;
                    this.isModified = false;
                    this.msgItemModified = '';
                    this.breadcrumb[this.breadcrumb.length - 1].text = this.editFormData.name.replace(`${this.editFormData.user_name} / `, '');
                    this.breadcrumb[this.breadcrumb.length - 2].text = this.editFormData.user_name;
                    this.breadcrumb[this.breadcrumb.length - 2].to = '/settings/rights/user/' + this.editFormData.core_user_id; 
                    // this.breadcrumb[this.breadcrumb.length - 1].text = `${this.editFormData.dept_name} / ${this.editFormData.speciality_name}`;
                    // this.breadcrumb[this.breadcrumb.length - 1].to = '/settings/rights/user/' + this.editFormData.user_id; 
                    this.itemId = this.editFormData.id;
                    this.checkButtons();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                // if (this.isModified) {
                //     //this.$v.$touch();
                //     //if (!this.$v.$invalid) {
                //     try {
                //         if (typeof this.editFormData.id != 'undefined') {
                //             // Update user
                //             await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData);
                //             tools.displaySuccessSave(this);
                //             this.isModified = false;
                //             // console.log(`DEBUG: ${this.$options.name}.saveData() this.$refs=`, this.$refs);
                //         } else {
                //             // New user created
                //             let response = await this.authenticatedAxios.post(this.apiUrl, this.editFormData);
                //             tools.displaySuccessSave(this);
                //             this.isModified = false;
                //             // console.log(`DEBUG: ${this.$options.name}.saveData() response.data.id=`, response.data.id);
                //             this.itemId = response.data.id;
                //             this.editFormData.id = this.itemId;
                //             await this.getData();
                //             await this.setGridFilter();
                //         }
                //     } catch (err) {
                //         tools.displayApiError(this, err);
                //     }
                // } else {
                //     // no changes
                // }

                if (this.isVisitTypeGridModified) {
                    this.$refs.visitTypeGrid.$emit('save');
                    this.isVisitTypeGridModified = false;
                }

                if (this.isVisitGroupGridModified) {
                    this.$refs.visitGroupGrid.$emit('save');
                    this.isVisitGroupGridModified = false;
                }

                if (this.isPhoneGridModified) {
                    this.$refs.phoneGrid.$emit('save');
                    this.isPhoneGridModified = false;
                }
                // this.$emit('close-modal', true);
            },
            
            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified`);
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                if (
                    this.isModified ||
                    this.isVisitTypeGridModified ||
                    this.isVisitGroupGridModified ||
                    this.isPhoneGridModified
                ) {
                    this.msgItemModified = this.$t('component.UserEdit.itemModified');
                } else {
                    this.msgItemModified = '';
                }
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },

            gridModified() {
                this.isVisitTypeGridModified = this.$refs.visitTypeGrid.isModified;
                this.isVisitGroupGridModified = this.$refs.visitGroupGrid.isModified;
                this.isPhoneGridModified = this.$refs.phoneGrid.isModified;
                if (
                    this.isVisitTypeGridModified ||
                    this.isVisitGroupGridModified ||
                    this.isPhoneGridModified
                ) {
                    this.isModified = true;
                } else {
                    this.isModified = false;
                }
            },

            addWorktime() {
                this.$router.push({ name: 'settings/visits/worktime-new', params: { id: null, core_workplace_id: this.itemId } });
            },
            editWorktime(id) {
                // console.log(`DEBUG: ${this.$options.name}.editItem(${id})`);
                this.$router.push({ name: 'settings/visits/worktime-edit', params: { id, core_workplace_id: this.itemId } });
            },
        },

        // =============== Life cycle ===============
        beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.id=`, this.id);
            this.itemId = parseInt(this.$route.params.id);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.itemId=`, this.itemId);
            if (this.itemId) {
                await this.getData();
                await this.setGridFilter();
            }
            this.checkButtons();
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
        },
    }
</script>

<style>
</style>
