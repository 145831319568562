<!--
Author: Eduard Grebenyukov
Date: 2018-12-05
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <div id="page-content">
            <div class="row">
                <div class="col">
                    <Grid :config="userRoleGridConfig" @modified="isUserRoleModified = $event" />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <Grid :config="taskTypeGridConfig" @modified="isTaskTypeModified = $event" />
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    const sprintf = require('sprintf-js').sprintf;
    import $ from 'jquery';
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    const componentUrl = 'core_role';

    export default {
        name: 'RoleEdit',
        components: {
            TemplatePageStandard, Grid,
        },

        // =============== Data ===============
        data() {
            return {
                id: Number,
                /* editFormData: {
                    id: 0,
                    code: null,
                    name: null,
                }, */
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-rights-roles'), to:'/settings/rights/roles' },
                    { text: '' },
                ],
                userRoleGridConfig: {
                    apiUrl: 'core_user_role',
                    filter: null,
                    title: '',
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), required:true, readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'core_role_id', header:'Role', required:true, readonly:true, default:null },
                        { type:'select', key:'core_user_id', header:this.$t('Settings.user'), required:true, source:'v_user', keyLabel:'fullname' },
                    ],
                    order: [ 'core_user_id' ],
                    displayToolbar: true,
                    buttons: [ 'row-selector', 'add', 'save', 'delete' ],
                },
                isUserRoleModified: Boolean,
                taskTypeGridConfig: {
                    apiUrl: 'task_type_role',
                    filter: null,
                    title: '',
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), required:true, readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'core_role_id', header:'Role', required:true, readonly:true, default:null },
                        { type:'select', key:'task_type_id', header:this.$t('enum.task_type'), required:true, source:'enum/task_type' },
                    ],
                    order: [ 'task_type_id' ],
                    displayToolbar: true,
                    buttons: [ 'row-selector', 'add', 'save', 'delete' ],
                },
                isTaskTypeModified: Boolean,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            apiUrl() { return `${this.baseUrl}/${componentUrl}` },
            isModified() { return this.isUserRoleModified || this.isTaskTypeModified },
        },

        // =============== Watch ===============
        watch: {
            isModified() {
                if (this.isModified) {
                    $('#breadcrumb').addClass('modified');
                } else {
                    $('#breadcrumb').removeClass('modified');
                }
            },
        },

        // =============== Methods ===============
        methods: {
            async getData() {
                //console.log('request=', `${this.apiUrl}/${this.id}`);
                try {
                    let response = await this.authenticatedAxios.get(`${this.apiUrl}/${this.id}`);
                    //this.editFormData = response.data;
                    //console.log('response=', response);
                    this.userRoleGridConfig.title = sprintf(this.$t('Role.usersWithRole'), response.data.name);
                    this.taskTypeGridConfig.title = sprintf(this.$t('Role.taskTypes'), response.data.name);
                    this.breadcrumb[this.breadcrumb.length - 1].text = response.data.name;
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.$route.params.id=`, this.$route.params.id);
            this.id = parseInt(this.$route.params.id);
            this.userRoleGridConfig.filter = { where:[
                { core_role_id:this.id },
            ]};
            this.taskTypeGridConfig.filter = { where:[
                { core_role_id:this.id },
            ]};
            this.userRoleGridConfig.columns.find(x => x.key === 'core_role_id').defaultValue = this.id;
            this.taskTypeGridConfig.columns.find(x => x.key === 'core_role_id').defaultValue = this.id;
        },

        async mounted() {
            await this.getData();
        },

        updated() {
            //console.log('Enums updated');
        },

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
