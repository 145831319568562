<!--
    Author: Eduard Grebenyukov
    Date: 2020-09-27
-->

<template>
    <button @click.prevent="$emit('click')" :disabled="disabled" class="btn btn-sm btn-outline-primary ml-1">
        <slot>
            <i class="fas fa-print"></i> {{ $t('lib.buttons.print') }}
        </slot>
    </button>
</template>

<script>
    export default {
        name: 'ButtonPrint',

        // =============== Props ===============
        props: {
            disabled: Boolean,
        },
    }
</script>
