<!--
    Author: Eduard Grebenyukov
    Date: 2021-04-03
-->

<!--
    labelPosition: none | before | above
    keyId
    keyLabel
    showEmptyRow
    emptyRowLabel
    order: String | Boolean
-->

<template>
    <b-form-group
        :label="label"
        v-slot="{ ariaDescribedby }"
        :class="{
            'my-auto': false,
            'form-radio': true,
            'field-radio-button__plain': labelPosition === 'none' || labelPosition === 'before'
        }"
    >
        <b-form-radio-group
            :id="id"
            :name="`FieldRadiobutton-${_uid}`"
            v-model="itemData"
            :options="selectListData"
            :value-field="keyId || 'id'"
            :text-field="keyLabel || 'name'"
            :disabled-field="keyDisabled || 'is_archived'"
            :aria-describedby="ariaDescribedby"
            @input="itemModified($event)"
        />

        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </b-form-group>

    <!-- <div class="custom-control custom-radio" :class="{ 'field-required':required }" >
        <div v-if="labelPosition === 'above'" class="custom-control-label" style="height:2rem;" >
            {{ label }}
        </div>

        <label v-if="labelPosition === 'before'" class="custom-control-label mr-2" :for="id" >
            {{ label }}
        </label>

        <input
            :id="id"
            :name="id"
            type="radio"
            :checked="value"
            :required="required"
            :disabled="disabled || readonly || displayonly"
            class="custom-control-input"
            :style="`${inputStyle}`"
            @change="itemModified($event)"
        >
        <label :for="id" v-if="labelPosition == 'float'" :title="required ? $t('lib.required') : ''" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label v-if="labelPosition === 'after'" class="custom-control-label ml-1" :for="id" >
            {{ label }}
        </label>

        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div> -->
</template>

<script>
    import { mapGetters } from 'vuex';

    const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'FieldRadiobutton',

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: [String, Number],
            defaultValue: [String, Number],
            placeholder: String,
            disabled: Boolean,
            required: Boolean,
            source: [String, Array],
            keyId: String,
            keyLabel: String,
            keyDisabled: String,
            readonly: Boolean,
            textEmit: String,
            textOnClick: String,
            textClass: String,
            textStyle: String,
            showEmptyRow: Boolean,
            emptyRowLabel: String,
            order: String, //order: [String, Array],
            saveState: String,
            saveSessionState: String,
            isInvalid: Boolean,
            isValid: Boolean,
            inputStyle: String,
            errorMessage: String,
        },

        // =============== Data ===============
        data() {
            return {
                // controlId: null, // internal control ID, can be overwritten by props.id
                selectListData: [],
                itemData: null,
                itemPresentation: null,
                // labelPositionCode: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Watch ===============
        watch: {
            value() {
                this.itemData = this.value;
                this.setPresentation();
                // console.log(`DEBUG: ${this.$options.name}.watch.value this.value=`, this.value);
            },
            source() {
                // console.log(`DEBUG: ${this.$options.name}.watch.source this.source=`, this.source);
                this.getSelectValues();
            }
        },

        // =============== Methods ===============
        methods: {
            eval(exp, entry) {
                return eval(exp);
            },

            getPresentation() {
                return this.itemPresentation;
            },

            setPresentation() {
                // this.itemPresentation = this.selectListData.find(x => {console.log('x=', x); return x[this.keyId||'id'] === this.itemData})[this.keyLabel||'name'];
                let currentListItem = this.selectListData.find(x => { return x[this.keyId||'id'] === this.itemData });
                if (currentListItem) {
                    this.itemPresentation = currentListItem[this.keyLabel||'name'];
                } else {
                    this.itemPresentation = this.emptyRowLabel || '';
                }
                // if (this.id == 'core_workplace_id') {
                //     console.log(`DEBUG: ${this.$options.name}.getSelectValues(${this.id})`);
                //     console.log(`DEBUG: (${this.id}) this.selectListData=`, this.selectListData);
                //     console.log(`DEBUG: (${this.id}) currentListItem=`, currentListItem);
                //     console.log(`DEBUG: (${this.id}) this.keyId=`, this.keyId);
                //     console.log(`DEBUG: (${this.id}) this.keyLabel=`, this.keyLabel);
                //     console.log(`DEBUG: (${this.id}) this.itemData=`, this.itemData);
                //     console.log(`DEBUG: (${this.id}) this.itemPresentation=`, this.itemPresentation);
                // }
            },

            async getSelectValues() {
                // console.log(`DEBUG: ${this.$options.name}.getSelectValues() this.id=${this.id}, source=`, this.source);
                let listData = {}
                if (typeof this.source === 'string') {
                    // console.log(`DEBUG: ${this.$options.name}.getSelectValues() url=`, `${this.baseUrl}/${this.source}`);
                    listData = (await this.authenticatedAxios.get(`${this.baseUrl}/${this.source}`)).data.rows;
                } else if (typeof this.source === 'object' && Array.isArray(this.source)) {
                    listData = this.source;
                } else {
                    // Unknown object, set empty array
                    listData = [];
                }

                // Do sort select items
                // console.log(`DEBUG: ${this.$options.name}.getSelectValues(${this.source}) this.order=`, this.order);
                // if (this.order && Array.isArray(this.order)) {
                //     listData = listData.slice().sort((a, b) => {
                //         let aa = a[this.order[0]];
                //         let bb = b[this.order[0]];
                //         return (aa === bb ? 0 : aa > bb ? 1 : -1);
                //     });
                // } else {
                // if (typeof this.order === 'boolean') {
                //     if (this.order) {
                //         listData = listData.slice().sort((a, b) => {
                //             let aa = a[this.keyLabel || 'name'];
                //             let bb = b[this.keyLabel || 'name'];
                //             return (aa === bb ? 0 : aa > bb ? 1 : -1);
                //         });
                //     }
                // } else {
                //     listData = listData.slice().sort((a, b) => {
                //         let aa = a[this.order || this.keyLabel || 'name'];
                //         let bb = b[this.order || this.keyLabel || 'name'];
                //         return (aa === bb ? 0 : aa > bb ? 1 : -1);
                //     });
                // }
                // }
                listData = listData.slice().sort((a, b) => {
                    let aa = a[this.order || this.keyLabel || 'name'];
                    let bb = b[this.order || this.keyLabel || 'name'];
                    return (aa === bb ? 0 : aa > bb ? 1 : -1);
                });

                this.selectListData = listData;

                this.setPresentation();
                // console.log(`DEBUG: ${this.$options.name}.getSelectValues(${this.source}) this.selectListData=`, this.selectListData);
            },

            itemModified(newValue) {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() newValue=`, newValue);
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.itemData=`, this.itemData);
                // this.itemData = newValue;
                if (this.saveState) {
                    // Save current value
                    // console.log(`DEBUG: ${this.$options.name}.itemModified(this.itemData)`);
                    this.authenticatedAxios.post(`${this.baseUrl}/${userSettingsUrl}`, {code: this.saveState, value: this.itemData});
                }
                if (this.saveSessionState) {
                    sessionStorage.setItem(this.saveSessionState, JSON.stringify(this.itemData));
                }
                // this.$emit('update:value', e.target.value.trim());
                this.$emit('update:value', this.itemData);
                this.$emit('modified');
            }
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // !!!! if select is read-only, do not get all list elements, get only current !!!!
            // console.log(`DEBUG: ${this.$options.name}(${this.source}).beforeMount() this=`, this);
            // console.log(`DEBUG: ${this.$options.name}(${this.id}).beforeMount() this.value=`, this.value);
            this.itemData = this.value;
            if (!this.itemData && this.saveState) {
                // Restore stored value
                let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() url response=`, response);
                let item = response.data.rows[0];
                if (item) this.itemData = item.value;
                this.itemModified();
            }
            if (!this.itemData && this.saveSessionState) {
                let sessionData = JSON.parse(sessionStorage.getItem(this.saveSessionState));
                if (sessionData) this.itemData = sessionData;
                this.itemModified(this);
            }
            if (!this.itemData && typeof this.defaultValue != 'undefined' && this.defaultValue !== null) {
                this.itemData = this.defaultValue;
                this.itemModified();
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() after default this.itemData=`, this.itemData, ', this.defaultValue=', this.defaultValue);
            }
            // !!!!!!!! if current value not in select list, do select first value from list
            //          or provide this behavior by param condition
        },
        async mounted() {
            // this.labelPositionCode = (this.labelPosition ? this.labelPosition : 'above');
            // console.log('this.labelPositionCode=',this.labelPositionCode);
            this.getSelectValues();
        },
    }
</script>

<style>
    /* .field-radio-button__plain .custom-radio .custom-control-label {
        font-size: 1rem;
    } */
</style>
