/*
    Author: Eduard Grebenyukov
    Date: 2019-12-30
*/

import Vue from 'vue';
import Vuex from 'vuex';

import { i18n, initLang } from './translate';

import { pageConfig } from './pageConfig';
import { getMainMenu } from './mainMenu';
// import { pageSubmenu } from './pageSubmenu';
import { visitMenu } from './visitMenu';
import { appCode, appName, baseHost, baseUrl, socketUrl, loginUrl, logoutUrl, checkAuthUrl, sexKind, evenOdd } from './constants';

// import moment from 'moment';
import createPersistedState from 'vuex-persistedstate';
import AuthModule from './auth';
import CashModule from './cash';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production', // Set to false in production

    modules: {
        auth: AuthModule,
        cash: CashModule,
    },

    plugins: [
        createPersistedState({ key:appCode, storage:window.sessionStorage })
    ],

    state: {
        language: initLang,
        lastClient: {},
        lastVisit: {},
        // currentWorkplaceId: null, // now stored in db
        answeredCall: null,
        clientToSchedule: null,
    },

    getters: {
        baseHost: () => { return baseHost },
        baseUrl: () => { return baseUrl },
        socketUrl: () => { return socketUrl },
        loginUrl: () => { return loginUrl },
        logoutUrl: () => { return logoutUrl },
        checkAuthUrl: () => { return checkAuthUrl },
        appCode: () => { return appCode },
        appName: () => { return appName },
        // statuses: ()=> { return statuses.map(status => { status.name = i18n.t('Status.' + status.code); return status; }) },
        sexKind: () => { return sexKind },
        evenOdd: () => { return evenOdd },
        language: state => { return state.language },
        lastClient: state => { return state.lastClient },
        lastVisit: state => { return state.lastVisit },
        // currentWorkplaceId: state => { return state.currentWorkplaceId },
        mainMenu: state => { return getMainMenu(state) },
        visitMenu: () => { return visitMenu },
        pageConfig: () => { return pageConfig },
        // pageSubmenu: () => { return pageSubmenu },
        answeredCall: state => { return state.answeredCall },
        clientToSchedule: state => { return state.clientToSchedule },
    },

    mutations: {
        onSetLanguage(state, lang) { state.language = lang },
        onSetLastClient(state, client) { state.lastClient = client; },
        onClearLastClient(state) { state.lastClient = {} },
        onSetLastVisit(state, visit) { state.lastVisit = visit; },
        onClearLastVisit(state) { state.lastVisit = {} },
        onGetAnsweredCall(state, call) { state.answeredCall = call },
        onSetClientToSchedule(state, client) { state.clientToSchedule = client },
    },

    actions: {
        setLanguage(ctx, lang) {
            // console.log(`DEBUG: /store/index actions setLanguage lang=`, lang);
            i18n.locale = lang;
            ctx.commit('onSetLanguage', lang);
            localStorage.setItem(`${appCode}:language`, lang);
        },

        async setLastClientId(ctx, id) {
            // console.log(`DEBUG: /store/index.actions.setLastClientId(${id}) this.state.lastClient.id=`, this.state.lastClient.id);
            if (id != this.state.lastClient.id) {
                let client = {};
                // console.log(`DEBUG: /store/index.actions.setLastClientId(${id}) this.state.lastClient.id=`, this.state.lastClient.id);
                if (id) {
                    client = (await this.getters.authenticatedAxios.get(`${baseUrl}/core_client/${id}`)).data;
                } else {
                    client = null;
                }
                ctx.commit('onSetLastClient', { id:client.id, shortname:client.shortname });
                ctx.commit('onClearLastVisit');
            }
        },

        clearLastClient(ctx) {
            // console.log(`DEBUG: /store/index.actions.clearLastClient() this.state.lastClient.id=`, this.state.lastClient.id);
            ctx.commit('onClearLastClient');
            ctx.commit('onClearLastVisit');
        },

        async setLastVisitId(ctx, visitId) {
            // console.log(`DEBUG: /store/index.actions.setLastVisitId(${visitId}) ctx=`, ctx);
            // if (visitId != this.state.lastVisit.id) {
                // condition removed for possibility ro reload visit data on the same active visit
                // i.e. on status changes
                let visit = {};
                if (visitId) {
                    visit = (await this.getters.authenticatedAxios.get(`${baseUrl}/visit/${visitId}`)).data;
                } else {
                    visit = null;
                }
                // console.log(`DEBUG: /store/index.actions.setLastVisitId(${visitId})=`, visit);
                ctx.commit('onSetLastVisit', visit);
            // } else console.log(`DEBUG: /store/index.actions.setLastVisitId(${visitId})`);
        },

        async getAnsweredCall(ctx) {
            // console.log(`DEBUG: /store/index.actions.getAnsweredCall() ctx=`, ctx);
            // console.log(`DEBUG: /store/index.actions.getAnsweredCall() this.getters.currentUser=`, this.getters.currentUser);
            if (this.getters.currentUser.currentWorkplace) {
                let call = (await this.getters.authenticatedAxios.get(`${baseUrl}/get-active-calls/${this.getters.currentUser.currentWorkplace.id}`)).data.rows[0];
                // console.log(`DEBUG: /store/index.actions.getAnsweredCall()=`, call);
                ctx.commit('onGetAnsweredCall', call);
            }
        },

        setClientToSchedule(ctx, client) {
            ctx.commit('onSetClientToSchedule', client);
        },
    }
})
