<!--
    Author: Eduard Grebenyukov
    Date: 2020-09-27
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDelete($event)"
    >
        <template #buttons>
            <ButtonClose @click="closePressed()" />
            <ButtonSave @click="saveData()" :disabled="!isEditable" />
            <ButtonDelete @click="askDeleteItem()" :disabled="!isEditable" />
        </template>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col col-xl-9">
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldDatetime
                                    :label="$t('attribute.fin_cash.time_of')"
                                    :value.sync="editFormData.time_of"
                                    required
                                    :disabled="!isEditable"
                                    @modified="itemModified"
                                />
                            </div>
                            <!-- <div class="col-sm">
                                <FieldDatetime
                                    :label="$t('attribute.fin_cash.time_inflow')"
                                    :value.sync="editFormData.time_inflow"
                                    required
                                    :disabled="!isEditable"
                                    @modified="itemModified"
                                />
                            </div> -->
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('enum.tran_type')"
                                    :value.sync="editFormData.tran_type_id"
                                    source="enum/tran_type"
                                    disabled
                                    required
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('object.fin_payment_type')"
                                    :value.sync="editFormData.fin_payment_type_id"
                                    source="fin_payment_type"
                                    required
                                    :disabled="!isEditable"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('attribute.fin_cash.core_workplace_id')"
                                    :value.sync="editFormData.core_workplace_id"
                                    source='v_workplace'
                                    keyLabel='workplace_name'
                                    order='name'
                                    required
                                    :disabled="!isEditable"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm col-md-6">
                                <FieldSelect
                                    :label="$t('attribute.fin_cash.cash_register_id')"
                                    :value.sync="editFormData.cash_register_id"
                                    :source='cashRegisterArray'
                                    order='name'
                                    :disabled="!isEditable"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm col-md-6">
                                <FieldSelect
                                    :label="$t('attribute.fin_cash.pos_terminal_id')"
                                    :value.sync="editFormData.pos_terminal_id"
                                    :source='posTerminalArray'
                                    order='name'
                                    :disabled="!isEditable"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('attribute.common.sum_payment')"
                                    :value.sync="editFormData.sum_payment"
                                    required
                                    disabled
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('attribute.common.sum_discount')"
                                    :value.sync="editFormData.sum_discount"
                                    required
                                    disabled
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('attribute.common.sum_paid')"
                                    :value.sync="editFormData.sum_transaction"
                                    required
                                    disabled
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldString
                                    :label="$t('attribute.fin_cash.cheque_num')"
                                    :value.sync="editFormData.cheque_num"
                                    :disabled="!isEditable"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    :label="$t('attribute.fin_cash.bank_card_num')"
                                    :value.sync="editFormData.bank_card_num"
                                    :disabled="!isEditable"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.common.commentary')"
                                    :value.sync="editFormData.commentary"
                                    :disabled="!isEditable"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <Grid v-if="editFormData.tran_type_id !== this.tranTypeRefundId" ref="serviceGrid" :config="serviceGridConfig" />
                                <Grid v-if="editFormData.tran_type_id === this.tranTypeRefundId" ref="refundGrid" :config="refundGridConfig" />
                            </div>
                        </div>
                    </div>
                    <div class="col col-xl-3">
                        <!-- Right sidebar -->
                        <AccountInfo ref="accountInfo" :accountId="accountId" />
                    </div>
                </div>
            </div>
        </form>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldDatetime from '@/components/lib/controls/FieldDatetime';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldText from '@/components/lib/controls/FieldText';
    import Grid from '@/components/lib/regions/Grid';

    import AccountInfo from '@/components/pages/common/AccountInfo';

    export default {
        name: 'TransactionEdit',

        components: {
            TemplatePageStandard, Grid,
            ButtonClose, ButtonSave, ButtonDelete,
            FieldDatetime, FieldSelect, FieldNumber, FieldString, FieldText,
            AccountInfo,
        },

        mixins: [
            tools.warningDeleteMixin,
            tools.warningCloseMixin,
        ],

        // =============== Data ===============
        data() {
            return {
                accountId: undefined,
                itemId: undefined,
                msgItemModified: '',
                isEditable: false,
                breadcrumb: [],
                tranTypeRefundId: null,
                tranTypeShiftId: null,
                tranTypeReversalRenderId: null,
                breadcrumbCashdesk: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.cashdesk') },
                    { text: this.$t('MainMenu.cashdesk-payers'), to: '/cashdesk/payers' },
                    { text: '#', to: '#' },
                    { text: this.$t('objects.fin_cash'), to: '#' },
                    { text: '#', to: '#' },
                ],
                breadcrumbFinances: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.finances') },
                    { text: this.$t('MainMenu.finances-accounts'), to:'/finances/accounts' },
                    { text: '#', to: '#' },
                    { text: this.$t('objects.fin_cash'), to: '#' },
                    { text: '#', to: '#' },
                ],
                cashRegisterArray: [],
                posTerminalArray: [],
                editFormData: {
                    tran_type_id: null,
                    fin_payment_type_id: null,
                    core_workplace_id: null,
                    fin_account_id: null,
                    cash_register_id: null,
                    pos_terminal_id: null,
                    time_of: null,
                    time_inflow: null,
                    sum_payment: null,
                    sum_discount: null,
                    sum_transaction: null,
                    commentary: null,
                    cheque_num: null,
                    bank_card_num: null,
                },
                oldFormData: {},
                serviceGridConfig: {
                    title: this.$t('component.TransactionEdit.services'),
                    apiUrl: null,
                    filter: null,
                    readonly: true,
                    columns: [
                        { type:'number', hidden:true, key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' }, // to sort
                        // { type:'hidden', key:'fin_cash_id', header:'fin_cash_id', defaultValue:null, align:'right', width:'64px' },
                        // { type:'number', key:'fin_split_id', header:this.$t('object.fin_split'), align:'right', width:'64px', headStyle:'white-space:normal;' },
                        { type:'number', key:'fin_split_id', header:'SID', align:'right', width:'64px' },
                        { type:'datetime', key:'time_rendered', header:this.$t('component.TransactionEdit.time_rendered'), style:'white-space:nowrap;' },
                        { type:'string', key:'service_code', header:this.$t('attribute.common.code'), style:'white-space:nowrap;' },
                        { type:'string', key:'service_name', header:this.$t('attribute.common.name') },
                        { type:'number', key:'sum_payment_split', header:this.$t('object.fin_service'), align:'right' },
                        { type:'number', key:'sum_payment', header:this.$t('attribute.common.sum_payment'), align:'right' },
                        { type:'number', key:'sum_discount', header:this.$t('attribute.common.sum_discount'), align:'right' },
                        { type:'number', key:'sum_transaction', header:this.$t('attribute.fin_cash.sum_transaction'), align:'right' },
                        { type:'number', key:'inflow_id', header:this.$t('attribute.fin_cash_item.inflow_id_short'), align:'right', width:'64px' },
                        { type:'string', key:'inflow_payment_type_name', header:this.$t('attribute.fin_cash_item.inflow_payment_type_name') },
                    ],
                    totals: {
                        columns: [
                            // { type:'string', key:'id', value:' ', style:'font-weight:bold; font-size:1.1rem;' }, // to hide default totals label !!!
                            { type:'string', key:'service_code', value:this.$t('app.total'), style:'font-weight:bold; font-size:1.1rem;' },
                            { type:'number', key:'sum_payment', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_payment), 0 );
                                    else return 0;
                                } 
                            },
                            { type:'number', key:'sum_discount', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_discount), 0 );
                                    else return 0;
                                }
                            },
                            { type:'number', key:'sum_transaction', style:'font-weight: bold;font-size:1.1rem;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_transaction), 0 );
                                    else return 0;
                                }
                            },
                        ],
                    },
                    order: [ 'id' ],
                    // buttons: [ 'row-selector', 'add', 'delete' ],
                    // buttons: [ 'row-selector', 'delete' ],
                },
                refundGridConfig: {
                    title: this.$t('component.TransactionEdit.refunds'),
                    apiUrl: null,
                    filter: null,
                    readonly: true,
                    columns: [
                        { type:'number', hidden:true, key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' }, // to sort
                        { type:'number', key:'inflow_id', header:this.$t('attribute.fin_cash_item.inflow_id_short'), align:'right', width:'64px' },
                        { type:'datetime', key:'inflow_datetime', header:this.$t('component.TransactionEdit.inflow_datetime'), style:'white-space:nowrap;' },
                        { type:'string', key:'inflow_payment_type_name', header:this.$t('attribute.fin_cash_item.inflow_payment_type_name') },
                        { type:'number', key:'sum_transaction', header:this.$t('attribute.fin_cash.sum_transaction'), align:'right' },
                    ],
                    totals: {
                        columns: [
                            // { type:'string', key:'id', value:' ', style:'font-weight:bold; font-size:1.1rem;' }, // to hide default totals label !!!
                            { type:'string', key:'service_code', value:this.$t('app.total'), style:'font-weight:bold; font-size:1.1rem;' },
                            { type:'number', key:'sum_transaction', style:'font-weight: bold;font-size:1.1rem;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_transaction), 0 );
                                    else return 0;
                                }
                            },
                        ],
                    },
                    order: [ 'id' ],
                    // buttons: [ 'row-selector', 'add', 'delete' ],
                    // buttons: [ 'row-selector', 'delete' ],
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),
        },

        // =============== Methods ===============
        methods: {
            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.editFormData=`, this.editFormData);
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
            },

            gotData() {
                this.itemId = this.editFormData.id;
                this.isModified = false;
                this.msgItemModified = '';
                // this.breadcrumb[this.breadcrumb.length - 1].text = this.editFormData.code;
                // this.checkButtons();
            },

            async getCashRegisterArray() {
                const deviceTypeId = await tools.getEnumId(this, 'device_type', 'cash_register');
                let filterString = tools.getFilterString({ where:[
                    { device_type_id: deviceTypeId },
                ]});
                this.cashRegisterArray = (await this.authenticatedAxios.get(`${this.baseUrl}/core_device${filterString}`)).data.rows;
            },

            async getPosTerminalArray() {
                const deviceTypeId = await tools.getEnumId(this, 'device_type', 'pos_terminal');
                let filterString = tools.getFilterString({ where:[
                    { device_type_id: deviceTypeId },
                ]});
                this.posTerminalArray = (await this.authenticatedAxios.get(`${this.baseUrl}/core_device${filterString}`)).data.rows;
            },

            async getData() {
                // console.log(`DEBUG: ${this.$options.name}.getData() this.workplace=`, this.workplace);
                try {
                    this.editFormData = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_cash/${this.itemId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.editFormData=`, this.editFormData);
                    // console.log(`DEBUG: ${this.$options.name}.getData() tranTypeShiftId=`, tranTypeShiftId);
                    // if (this.editFormData.tran_type_id === this.tranTypeRefundId) {}
                    if (this.editFormData.tran_type_id === this.tranTypeShiftId || this.editFormData.tran_type_id === this.tranTypeReversalRenderId) {
                        this.serviceGridConfig.columns.find(col => col.key === 'inflow_id').type = 'number';
                        this.serviceGridConfig.columns.find(col => col.key === 'inflow_payment_type_name').type = 'string';
                    } else {
                        this.serviceGridConfig.columns.find(col => col.key === 'inflow_id').type = 'hidden';
                        this.serviceGridConfig.columns.find(col => col.key === 'inflow_payment_type_name').type = 'hidden';
                    }
                    if (this.editFormData.tran_type_id == this.tranTypeReversalRenderId) {
                        this.isEditable = false;
                    }
                    // if (this.$refs.serviceGrid) this.$refs.serviceGrid.refresh();
                    this.gotData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            this.editFormData = (await this.authenticatedAxios.put(`${this.baseUrl}/fin_cash/${this.editFormData.id}`, this.editFormData)).data;
                            tools.displaySuccessSave(this);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response=`, response);
                            this.gotData();
                        } else {
                            // console.log('Create new...');
                            // Create new
                            this.editFormData = (await this.authenticatedAxios.post(`${this.baseUrl}/fin_cash`, this.editFormData)).data;
                            tools.displaySuccessSave(this);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                            this.gotData();
                            this.$router.replace({ name: 'transaction-edit', params: { accountId:this.accountId, cashId:this.itemId } });
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },

            async processDelete (doDelete) {
                if (doDelete) {
                    try {
                        let payload = {
                            core_user_id: this.currentUser.id,
                            fin_cash_id: this.itemId
                        };
                        await this.authenticatedAxios.post(`${this.baseUrl}/fin-delete-transaction`, payload);
                        // await this.authenticatedAxios.delete(`${this.baseUrl}/fin_cash/${this.itemId}`);
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() params=`, this.$route.params);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.$route=`, this.$route);
            this.accountId = parseInt(this.$route.params.accountId);
            this.itemId = parseInt(this.$route.params.cashId);

            if (this.$route.fullPath.startsWith('/cashdesk/')) {
                this.breadcrumb = this.breadcrumbCashdesk;
                this.breadcrumb[3].to = `/cashdesk/account/${this.accountId}`;
                this.breadcrumb[3].text = `${this.accountId}`;
                this.breadcrumb[4].to = `/cashdesk/account/${this.accountId}/transactions`;
                this.breadcrumb[5].to = `/cashdesk/account/${this.itemId}`;
                this.breadcrumb[5].text = `${this.itemId}`;
            }
            if (this.$route.fullPath.startsWith('/finances/')) {
                this.breadcrumb = this.breadcrumbFinances;
                this.breadcrumb[3].to = `/finances/account/${this.accountId}`;
                this.breadcrumb[3].text = `${this.accountId}`;
                this.breadcrumb[4].to = `/finances/account/${this.accountId}/transactions`;
                this.breadcrumb[5].to = `/finances/account/${this.itemId}`;
                this.breadcrumb[5].text = `${this.itemId}`;
            }

            let filter = { where:[
                { fin_cash_id: this.itemId },
            ]};

            this.serviceGridConfig.filter = filter;
            this.serviceGridConfig.apiUrl = 'v_cash_item';
            // // this.serviceGridConfig.columns[1].defaultValue = this.itemId;

            this.refundGridConfig.filter = filter;
            this.refundGridConfig.apiUrl = 'v_cash_item';

            this.tranTypeRefundId = await tools.getEnumId(this, 'tran_type', 'refund');
            this.tranTypeShiftId = await tools.getEnumId(this, 'tran_type', 'offset');
            this.tranTypeReversalRenderId = await tools.getEnumId(this, 'tran_type', 'reversal_render');
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.contractId=${this.contractId}`);
            this.isEditable = tools.isPrivilegeGranted(this.currentUser.permissions, 'page', 'cashdesk/transaction-edit', 'rw');
            this.getCashRegisterArray(); // don't wait
            if (this.itemId) {
                await this.getData();
            // } else {
            //     this.checkButtons();
            }
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.currentUser=`, this.currentUser);
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.isEditable=${this.isEditable}`);
        },
    }

</script>

<style>
</style>
