<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-30
-->

<!--
source: Array,
config: {
    apiUrl: String,
    title: String,
    displayToolbar: Boolean,
    displaySearch: Boolean,
    saveSessionState: String,
    readonly: Boolean,
    hideZero: Boolean,
    buttons: [String | Object], // 'row-selector' | 'add' | 'save' | 'delete' | 'edit' | {}
    columns: [
        {
            hidden: Boolean, $hidden: Function(filteredData) // to display or not table column
            type: String( 'hidden' | 'html' | 'icon' | 'string' | 'number' | 'color' | 'select' | 'popuplist' | 'date' | 'datetime' | 'checkbox' ),
            readonly: Boolean, $readonly: Function,
            key: String,
            required: Boolean,
            sortable: Boolean,
            width: String,
            headStyle: String,
            header: String,
            headerTitle: String,
            cellStyle: String, $cellStyle: Function,
            align: String, $align: Function,
            cellTitle: String, $cellTitle: Function,
            value: String, $value: Function,
            defaultValue: Any,
            icon: String, $icon: Function,
            title: String, $title: Function,
            class: String,
            style: String, $style: Function(entry, evt, ctx),
            textClass: String,
            textStyle: String, $textStyle: Function(entry, evt, ctx),
            //textTitle: String, $textTitle: Function,
            emit: String,
            textEmit: String,
            $onClick: Function(entry, evt, ctx)
            $textOnClick: Function(entry, evt, ctx)
            source: String | Object,
            keyId: String,
            keyLabel: String,
            showEmptyRow: Boolean, // for select
            order: String, // for select, popuplist
        }
    ],
    filter: Object,
    order: Array[ String ] | Array[{ key:String, direction:String( 'asc' | 'desc' ) }],
    row: {
        style: String,
        backgroundColor: String,
        color: String,
        $style: Function,
        $backgroundColor: Function,
        $color: Function,
        emit: String,
        $onClick: Function(entry, evt, ctx),
        $onRightClick: Function(entry, evt, ctx),
        $selectorCondition: Function(entry, evt, ctx)
    },
    subtotals:  {
        position: String('above' | 'below')
        columns: [
            {...}
        ]
    },
    totals: {
        columns: [
            {...}
        ]
    },
    paginationPageSize: Number,
    displayPagination: Boolean,
    $onAdd: Function(ctx),
    $onEdit: Function(ctx, entry),
    $onDelete: Function(ctx, entry),
}

Methods:
    clear()
    refresh()
    save()
    download()
    getFilteredRowsQty()
    getTotalRowsQty()
    getAllRows()
    getSelectedRows()
    setModified(value)
    selectAll()

Emits:
    @modified // on data modified (if not readonly)
    @refreshed // when refresh or save or delete done
    @saved // when save done
    @deleted // when delete done
    @add-item // when add item button pressed if readonly
    @edit-item // when edit item button pressed if readonly
    @delete-items // when items deleted if readonly
    @item-selected // when item checkbox selected
    @updated // when updated life cycle hook
    @search-pressed // when refresh button pressed

Handle events:
    @save

Note: !!! do not use user's input in config.*.$* attributes, it is dangerous !!!

-->

<template>
    <div class="grid-container">
        <h6 v-if="config && config.title" >{{ config ? config.title : ''}}</h6>
        <table ref="reportTable" class="table table-bordered grid-editable sticky-headers">
            <thead class="thead-light">
                <tr class="grid-filters" v-if="config && (config.displayToolbar || typeof config.displayToolbar === 'undefined')">
                    <td :colspan="columnsQty">
                        <div class="btn-toolbar">
                            <div class="btn-group my-auto" v-if="config.displaySearch || typeof config.displaySearch === 'undefined'">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <i class="input-group-text fas fa-search" :title="$t('lib.Grid.inputSearch')" ></i>
                                    </div>
                                    <!-- <button disabled><i class="fas fa-search" :title="$t('lib.Grid.inputSearch')"></i></button> -->
                                    <input
                                        ref="inputSearch"
                                        v-model="searchKeybuffer"
                                        class="form-control query-input"
                                        name="query"
                                        @input="debounceSearch"
                                        @keyup.enter="askSearch()"
                                    >
                                </div>
                                <!-- <button type="button" v-if="config.buttons && config.buttons.includes('refresh')" @click="askRefresh()" :id="`button-refresh-${_uid}`" class="btn btn-outline-secondary btn-sm" :title="$t('lib.Grid.buttonRefresh')">
                                    <i class="fas fa-sync"></i>
                                </button> -->
                                <button
                                    type="button"
                                    :title="$t('lib.Grid.clearSearch')"
                                    class="btn btn-outline-secondary btn-sm"
                                    @click="searchKeybuffer = '';$refs.inputSearch.focus();askSearch()"
                                >
                                    <i class="fas fa-times" ></i>
                                </button>
                                <button
                                    :id="`button-search-${_uid}`"
                                    type="button"
                                    :title="$t('lib.buttons.search')"
                                    class="btn btn-outline-secondary btn-sm"
                                    @click="askSearch()"
                                >
                                    {{ $t('lib.buttons.search') }}
                                </button>
                            </div>
                            <div v-if="config.buttons && config.buttons.includes('actions')">
                                <b-button-group>
                                    <b-dropdown right :text="$t('lib.Grid.actions')" variant="outline-secondary">
                                        <b-dropdown-item v-if="config.buttons && config.buttons.includes('download')" @click="downloadGrid()">
                                            {{ $t('lib.Grid.download') }}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-button-group>
                            </div>
                            <div class="btn-group my-auto" v-if="config.buttons && config.buttons.some(item => [ 'add', 'save', 'delete' ].includes(item))">
                                <button
                                    v-if="config.buttons && config.buttons.includes('add')"
                                    :id="`button-add-${_uid}`"
                                    type="button"
                                    class="btn btn-warning btn-sm"
                                    @click="addRow()"
                                >
                                    <i class="fas fa-plus fa-fw"></i> {{ $t('lib.Grid.buttonAdd') }}
                                </button>
                                <button
                                    v-if="config.buttons && config.buttons.includes('save')"
                                    :id="`button-save-${_uid}`"
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    @click="saveData()"
                                >
                                    <i class="fas fa-save fa-fw"></i> {{ $t('lib.Grid.buttonSave') }}
                                </button>
                                <button
                                    v-if="config.buttons && config.buttons.includes('delete') && isSelected"
                                    :id="`button-delete-${_uid}`"
                                    type="button"
                                    class="btn btn-outline-danger btn-sm"
                                    :class="{'disabled': !isSelected}"
                                    @click="askDeleteItems()"
                                >
                                    <i class="fas fa-trash fa-fw"></i> {{ $t('lib.Grid.buttonDelete') }}
                                </button>
                            </div>
                            <div v-for="button in customButtons" :key="button.key" class="btn-group my-auto ml-1" role="group">
                                <button type="button" @click="onClick(button.emit || button.key, button.$onClick, {}, $event)" :class="button.class">
                                    <i :class="button.iconClass" :style="button.iconStyle"></i>
                                    <span :class="button.textClass" :style="button.textStyle"> {{ button.text }}</span>
                                </button>
                            </div>

                            <div class="extra-buttons btn-group my-auto"><slot name="buttons"></slot></div>

                        </div>
                    </td>
                </tr>

                <tr v-if="filteredData.length > 0">
                    <th v-if="config.buttons && config.buttons.includes('row-selector')" class="grid-header text-center icon">
                        <input
                            :id="`check-${_uid}-all`"
                            type="checkbox"
                            v-model="checkboxSelectAllItems"
                            class="checkbox"
                            :title="$t('lib.Grid.selectAllItems')"
                        >
                    </th>
                    <th v-if="config.buttons && config.buttons.includes('edit')" class="grid-header text-center icon"></th>
                    <template v-for="col in config.columns">
                        <th
                            v-if="isColVisible(col)"
                            :key="'head.' + col.key"
                            :title="(col.headerTitle ? col.headerTitle : '')"
                            class="grid-header"
                            :class="{ active: sortKey == col.key, 'column-required': col.required }"
                            :style="(col.width ? 'width:' + col.width + ';' : '') + (col.headStyle || '')"
                        >
                            <span v-if="col.required" class="required-mark-corner" :title="$t('lib.Grid.required')"></span>
                            <span v-if="col.headerHTML" v-html="col.headerHTML"></span>
                            <template v-if="!col.headerHTML">{{ col.header }}</template>
                            <i
                                v-if="(typeof col.sortable === 'undefined' || col.sortable) && (typeof col.order === 'undefined' || col.order) && (col.type !== 'icon')"
                                @click="sortBy(col.key)"
                                :class="
                                    'fas fa-fw ' + (sortKey == col.key ?
                                        (col.type === 'number' ?
                                            (sortOrders[col.key] > 0 ? 'fa-sort-numeric-up' : 'fa-sort-numeric-down-alt') :
                                            (sortOrders[col.key] > 0 ? 'fa-sort-alpha-up' : 'fa-sort-alpha-down-alt')
                                        ) :
                                        'fa-arrows-alt-v'
                                    ) + ' sort link'
                                "
                            >
                            </i>
                        </th>
                    </template>
                </tr>
            </thead>

            <tbody ref="tBody">
                <!-- Rows ========================================= -->
                <template v-for="(entry, entryIndex) in filteredData" >

                    <!-- Subtotals above ========================================= -->
                    <template v-if="config && config.subtotals && config.subtotals.position === 'above'">
                        <template v-for="(subtotalCol, subtotalIndex) in config.subtotals.columns">
                            <tr v-if="isDisplaySubtotal(subtotalIndex, entryIndex)" :key="`subtotal-tr-${entryIndex}-${subtotalIndex}`">

                                <!-- Special columns -->

                                <td v-if="config.buttons && config.buttons.includes('row-selector')" class="grid-cell" ></td>

                                <td v-if="config.buttons && config.buttons.includes('edit')" class="grid-cell" ></td>

                                <!-- /Special columns -->
                                <template v-for="(col, colIndex) in config.columns">
                                    <td
                                        v-if="isColVisible(col)"
                                        :data-column-name="col.key"
                                        :key="`subtotals-td-${entryIndex}-${colIndex}`"
                                        class="grid-cell"
                                        :class="(col.type === 'icon' ? 'grid__icon ' : '') + (getTotalsCol(col.key).cellClass || '') + (getTotalsCol(col.key).emit || getTotalsCol(col.key).$onClick ? 'link' : '')"
                                        :style="
                                            (getTotalsCol(col.key).cellStyle || '') + (col.align ? ` text-align:${col.align};` : '') +
                                            (getTotalsCol(col.key).$cellStyle ? callFunction(getTotalsCol(col.key).$cellStyle, filteredData) : '') +
                                            (col.$align ? ` text-align:${callFunction(col.$align, filteredData)}` : '') +
                                            (colIndex === 0 && subtotalIndex > 0 ? `padding-left:${subtotalIndex * subtotalLevelPadding}px;` : '')
                                        "
                                        :title="(getTotalsCol(col.key).cellTitle || '') + (getTotalsCol(col.key).$cellTitle ? callFunction(getTotalsCol(col.key).$cellTitle, null) : '')"
                                        :colspan="getTotalsCol(col.key).colspan || 1 /* works bad because tds shifts right */ "
                                    >
                                        <div
                                            v-if="getTotalsCol(col.key).type != 'number' && colIndex === 0"
                                            v-html="`${entry[subtotalCol] ? entry[subtotalCol] : getCol(subtotalCol).header + ': ' + $t('lib.Grid.empty')}`"
                                            @click="onClick(getTotalsCol(col.key).emit, getTotalsCol(col.key).$onClick, null, $event)"
                                            @contextmenu="contextMenu(getTotalsCol(col.key).$onRightClick, null, $event)"
                                            :title="(getTotalsCol(col.key).title || '') + (getTotalsCol(col.key).$title ? callFunction(getTotalsCol(col.key).$title, filteredData) : '') "
                                            class="grid-data-container subtotals"
                                            :class="getTotalsCol(col.key).class"
                                            :style="
                                                (getTotalsCol(col.key).align ? `text-align:${getTotalsCol(col.key).align};` : '') +
                                                (getTotalsCol(col.key).style ? getTotalsCol(col.key).style : '') +
                                                (getTotalsCol(col.key).$style ? callFunction(getTotalsCol(col.key).$style, entry) : '')
                                            "
                                        >
                                        </div>

                                        <div
                                            v-if="getTotalsCol(col.key).type != 'number' && colIndex != 0"
                                            v-html="getSubTotalsValue(colIndex, subtotalIndex, entryIndex)"
                                            @click="onClick(getTotalsCol(col.key).emit, getTotalsCol(col.key).$onClick, null, $event)"
                                            @contextmenu="contextMenu(getTotalsCol(col.key).$onRightClick, null, $event)"
                                            :title="(getTotalsCol(col.key).title || '') + (getTotalsCol(col.key).$title ? callFunction(getTotalsCol(col.key).$title, filteredData) : '') "
                                            class="grid-data-container subtotals"
                                            :class="getTotalsCol(col.key).class"
                                            :style="
                                                (getTotalsCol(col.key).align ? `text-align:${getTotalsCol(col.key).align};` : '') +
                                                (getTotalsCol(col.key).style ? getTotalsCol(col.key).style : '') +
                                                (getTotalsCol(col.key).$style ? callFunction(getTotalsCol(col.key).$style, entry) : '')
                                            "
                                        >
                                        </div>

                                        <div
                                            v-if="getTotalsCol(col.key).type === 'number' && colIndex != 0"
                                            @click="onClick(getTotalsCol(col.key).emit, getTotalsCol(col.key).$onClick, null, $event)"
                                            @contextmenu="contextMenu(getTotalsCol(col.key).$onRightClick, null, $event)"
                                            :title="(getTotalsCol(col.key).title || '') + (getTotalsCol(col.key).$title ? callFunction(getTotalsCol(col.key).$title, filteredData) : '') "
                                            class="grid-data-container subtotals"
                                            :class="getTotalsCol(col.key).class"
                                            :style="
                                                (getTotalsCol(col.key).align ? `text-align:${getTotalsCol(col.key).align};` : 'text-align:right;') +
                                                (getTotalsCol(col.key).style ? getTotalsCol(col.key).style : '') +
                                                (getTotalsCol(col.key).$style ? callFunction(getTotalsCol(col.key).$style, entry) : '')
                                            "
                                        >
                                            {{ formatNumber(getSubTotalsValue(colIndex, subtotalIndex, entryIndex)) }}
                                        </div>

                                    </td>
                                </template>
                            </tr>
                        </template>
                    </template>

                    <!-- Main row ========================================= -->
                    <tr
                        :key="`row-${entryIndex}`"
                        @click="onClick(config.row ? config.row.emit : null, config.row ? config.row.$onClick : null, entry, $event)"
                        @contextmenu="contextMenu(config.row ? config.row.$onRightClick : null, entry, $event)"
                        :class="{ modified: entry.$isModified || entry.$isNew, deleted: entry.$isDeleted }"
                        :style="
                            (config.row?
                                (
                                    (config.row.style ? config.row.style + ' ' : '') +
                                    (config.row.backgroundColor ? 'background-color:' + config.row.backgroundColor + ';' : '') +
                                    (config.row.color ? 'color:' + config.row.color + ';' : '') +
                                    (config.row.$style ? callFunction(config.row.$style, entry) + ' ' : '') +
                                    (config.row.$backgroundColor ? 'background-color:' + callFunction(config.row.$backgroundColor, entry) + ';' : '') +
                                    (config.row.$color ? 'color:' + callFunction(config.row.$color, entry) + ';' : '')
                                )
                                :''
                            )
                        "
                    >
                        <!-- Special columns -->

                        <td v-if="config.buttons && config.buttons.includes('row-selector')" class="text-center">
                            <input
                                :id="`check-${_uid}-${entryIndex}`"
                                type="checkbox"
                                v-if="(config.row && config.row.$selectorCondition ? callFunction(config.row.$selectorCondition, entry) : true)"
                                v-model="entry.$isSelected"
                                @change="selectItem(entry)"
                                class="checkbox grid-data-container"
                            >
                        </td>

                        <td v-if="config.buttons && config.buttons.includes('edit')" class="grid__icon text-center grid__icon__edit">
                            <i class="fas fa-pencil-alt fa-fw link" @click="editItem(entry)" :title="$t('lib.Grid.edit')"></i>
                        </td>

                        <!-- /Special columns -->

                        <template v-for="(col, colIndex) in config.columns">
                            <td
                                v-if="isColVisible(col)"
                                :key="'cell.' + col.key"
                                class="grid-cell"
                                :class="(col.type === 'icon' || col.type === 'checkbox' ? 'grid__icon text-center ' : '') + (col.cellClass || '')"
                                :style="
                                    (col.cellStyle || '') + (col.align ? ` text-align:${col.align};` : '') +
                                    (col.$cellStyle ? callFunction(col.$cellStyle, entry) : '') +
                                    (col.$align ? ` text-align:${callFunction(col.$align, entry)}` : '') +
                                    (
                                        colIndex === 0 && config && config.subtotals && config.subtotals.columns ?
                                        `padding-left:${config.subtotals.columns.length * subtotalLevelPadding}px;` : ''
                                    )
                                "
                                :title="(col.cellTitle || '') + (col.$cellTitle ? callFunction(col.$cellTitle, entry) : '')"
                            >
                                <template v-if="(col.type === 'html')">
                                    <div
                                        v-html="(col.value || null) || (col.$value ? callFunction(col.$value || entry[col.key], entry) : null) || entry[col.key]"
                                        :title="(col.title || '') + (col.$title ? callFunction(col.$title, entry) : '') "
                                        class="grid-data-container"
                                        :class="(col.class ? col.class : '') + (col.emit || col.$onClick ? ' link' : '')"
                                        :style="
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @click="onClick(col.emit, col.$onClick, entry, $event)"
                                        @contextmenu="contextMenu(col.$onRightClick, entry, $event)"
                                    >
                                    </div>
                                </template>

                                <template v-if="(col.type === 'icon')">
                                    <div
                                        class="grid__icon-container"
                                        :class="(col.class ? col.class : '') + (col.emit || col.$onClick ? ' link' : '')"
                                        :style="
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @click="onClick(col.emit, col.$onClick, entry, $event)"
                                        @contextmenu="contextMenu(col.$onRightClick, entry, $event)"
                                    >
                                        <i :class="'fa-fw ' + (col.icon || '') + (col.$icon? callFunction(col.$icon, entry) : '')"
                                            :title="(col.title || '') + (col.$title ? callFunction(col.$title, entry) : '') "
                                        ></i>
                                    </div>
                                </template>

                                <template v-if="col.type === 'string' || col.type === 'time'">
                                    <input
                                        v-if="!isColReadonly(col, entry)"
                                        type="text"
                                        :title="(col.title || '') + (col.$title ? callFunction(col.$title, entry) : '')"
                                        v-model.trim="entry[col.key]"
                                        :id="`input-string-${col.key}-${entryIndex}`"
                                        class="grid-data-container"
                                        :class="{ 'column-required': col.required }"
                                        :style="
                                            (col.align ? `text-align:${col.align};` : '') +
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @change="itemModified($event, entry, col.key)"
                                        @focus="itemFocused($event, entry, col.key)"
                                    />
                                    <div
                                        v-if="isColReadonly(col, entry)"
                                        :title="(col.title || '') + (col.$title ? callFunction(col.$title, entry) : '')"
                                        class="grid-data-container"
                                        :class="col.class"
                                        :style="
                                            (col.align ? `text-align:${col.align};` : '') +
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @click="onClick(col.emit, col.$onClick, entry, $event)"
                                        @contextmenu="contextMenu(col.$onRightClick, entry, $event)"
                                    >
                                        <span
                                            :class="
                                                (col.textClass || '') +
                                                ((col.emit || col.$textOnClick) ? ' link' : '')
                                            "
                                            :style="
                                                (col.textStyle ? col.textStyle : '') +
                                                (col.$textStyle ? callFunction(col.$textStyle, entry) : '')
                                            "
                                            @click="onClick(col.textEmit, col.$textOnClick, entry, $event)"
                                        >
                                            <!-- :title="(col.textTitle || '') + (col.$textTitle ? callFunction(col.$textTitle, entry) : '')" -->
                                            {{ (col.$value ? callFunction(col.$value, entry) : col.value || entry[col.key]) }}
                                        </span>
                                    </div>
                                </template>

                                <template v-if="col.type === 'number'">
                                    <input
                                        v-if="!isColReadonly(col, entry)"
                                        :id="`input-number-${col.key}-${entryIndex}`"
                                        type="number"
                                        :title="(col.title || '') + (col.$title ? callFunction(col.$title, entry) : '')"
                                        v-model.trim="entry[col.key]"
                                        class="grid-data-container"
                                        :class="{ 'column-required': col.required }"
                                        :style="
                                            (col.align ? `text-align:${col.align};` : 'text-align:right;') +
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @change="itemModified($event, entry, col.key)"
                                        @focus="itemFocused($event, entry, col.key)"
                                    />
                                    <div
                                        v-if="isColReadonly(col, entry)"
                                        :title="(col.title || '') + (col.$title ? callFunction(col.$title, entry) : '')"
                                        class="grid-data-container"
                                        :class="col.class"
                                        :style="
                                            (col.align ? `text-align:${col.align};` : 'text-align:right;') +
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @click="onClick(col.emit, col.$onClick, entry, $event)"
                                        @contextmenu="contextMenu(col.$onRightClick, entry, $event)"
                                    >
                                        <span
                                            :class="
                                                (col.textClass || '') +
                                                ((col.emit || col.$textOnClick) ? ' link' : '')
                                            "
                                            :style="
                                                (col.textStyle ? col.textStyle : '') +
                                                (col.$textStyle ? callFunction(col.$textStyle, entry) : '')
                                            "
                                            @click="onClick(col.textEmit, col.$textOnClick, entry, $event)"
                                        >
                                            <!-- :title="(col.textTitle || '') + (col.$textTitle ? callFunction(col.$textTitle, entry) : '')" -->
                                            {{ formatNumber(col.$value ? callFunction(col.$value, entry) : col.value || entry[col.key]) }}
                                        </span>
                                    </div>
                                </template>

                                <template v-if="(col.type === 'color')">
                                    <div class="input-group p-0 grid-data-container" v-if="!isColReadonly(col, entry)">
                                        <input
                                            type="string"
                                            :title="(col.title || '') + (col.$title ? callFunction(col.$title, entry) : '')"
                                            v-model.trim="entry[col.key]"
                                            :id="`input-color-text-${col.key}-${entryIndex}`"
                                            class="form-control"
                                            :class="{ 'column-required': col.required }"
                                            :style="
                                                (col.align ? `text-align:${col.align};` : '') +
                                                (col.style ? col.style : '') +
                                                (col.$style ? callFunction(col.$style, entry) : '')
                                            "
                                            @change="itemModified($event, entry, col.key)"
                                            @focus="itemFocused($event, entry, col.key)"
                                        />
                                        <input
                                            type="color"
                                            :title="(col.title || '') + (col.$title ? callFunction(col.$title, entry) : '')"
                                            v-model.trim="entry[col.key]"
                                            :id="`input-color-${col.key}-${entryIndex}`"
                                            class="grid__color_button btn input-group-append"
                                            :class="{ 'column-required': col.required }"
                                            :style="
                                                (col.align ? `text-align:${col.align};` : '') +
                                                (col.style ? col.style : '') +
                                                (col.$style ? callFunction(col.$style, entry) : '')
                                            "
                                            @change="itemModified($event, entry, col.key)"
                                            @focus="itemFocused($event, entry, col.key)"
                                        />
                                    </div>
                                    <div
                                        v-if="isColReadonly(col, entry)"
                                        :title="(col.title || '') + (col.$title ? callFunction(col.$title, entry) : '')"
                                        class="grid-data-container"
                                        :class="col.class"
                                        :style="
                                            (col.align ? `text-align:${col.align};` : '') +
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @click="onClick(col.emit, col.$onClick, entry, $event)"
                                        @contextmenu="contextMenu(col.$onRightClick, entry, $event)"
                                    >
                                        <span
                                            :class="
                                                (col.textClass || '') +
                                                ((col.emit || col.$textOnClick) ? ' link' : '')
                                            "
                                            :style="
                                                (col.textStyle ? col.textStyle : '') +
                                                (col.$textStyle ? callFunction(col.$textStyle, entry) : '')
                                            "
                                            @click="onClick(col.textEmit, col.$textOnClick, entry, $event)"
                                        >
                                            <!-- :title="(col.textTitle || '') + (col.$textTitle ? callFunction(col.$textTitle, entry) : '')" -->
                                            {{ (col.$value ? callFunction(col.$value, entry) : col.value || entry[col.key]) }}
                                        </span>
                                    </div>
                                </template>

                                <template v-if="(col.type === 'select')">
                                    <select
                                        v-if="!isColReadonly(col, entry)"
                                        :id="`input-select-${col.key}-${entryIndex}`"
                                        v-model="entry[col.key]"
                                        class="grid-data-container"
                                        :class="{ 'column-required': col.required }"
                                        :style="
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @change="itemModified($event, entry, col.key)"
                                    >
                                        <option v-if="col.showEmptyRow" :key="`option.${col.key}.empty_row`" :value="null"></option>
                                        <option v-for="listItem in selectListData[col.key]" :key="`option.${col.key}.${listItem[col.keyId || 'id']}`" :value="listItem[col.keyId || 'id']">{{listItem[col.keyLabel || 'name']}}</option>
                                    </select>
                                    <div
                                        v-if="isColReadonly(col, entry) && selectListData[col.key].find(x => x[col.keyId||'id'] === entry[col.key])"
                                        class="grid-data-container"
                                        :class="col.class"
                                        :style="
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @click="onClick(col.emit, col.$onClick, entry, $event)"
                                        @contextmenu="contextMenu(col.$onRightClick, entry, $event)"
                                    >
                                        <span
                                            :class="
                                                (col.textClass || '') +
                                                ((col.emit || col.$textOnClick) ? ' link' : '')
                                            "
                                            :style="
                                                (col.textStyle ? col.textStyle : '') +
                                                (col.$textStyle ? callFunction(col.$textStyle, entry) : '')
                                            "
                                            @click="onClick(col.textEmit, col.$textOnClick, entry, $event)"
                                        >
                                            {{ listSelectedElement(entry, col) }}
                                        </span>
                                    </div>
                                </template>

                                <template v-if="(col.type === 'popuplist')">
                                    <div class="input-group p-0 grid-data-container" v-if="!isColReadonly(col, entry)">
                                        <span
                                            type="text"
                                            class="form-control"
                                            :style="
                                                (col.style ? col.style : '') +
                                                (col.$style ? callFunction(col.$style, entry) : '')
                                            "
                                        >
                                            {{ listSelectedElement(entry, col) }}
                                        </span>
                                        <div v-if="!!entry[col.key]" class="input-group-append">
                                            <button
                                                type="button"
                                                class="btn btn-outline-secondary btn-sm"
                                                @click.prevent="entry[col.key] = null; itemModified($event, entry, col.key)"
                                            >
                                                <i class="fas fa-times"></i>
                                            </button>
                                        </div>
                                        <div class="input-group-append">
                                            <button
                                                type="button"
                                                class="btn btn-outline-secondary dropdown-toggle btn-sm"
                                                @click.prevent="openPopupList(entry, col)"
                                                aria-haspopup
                                            />
                                        </div>
                                    </div>
                                    <div
                                        v-if="isColReadonly(col, entry) && selectListData[col.key].find(x => x[col.keyId||'id'] === entry[col.key])"
                                        class="grid-data-container"
                                        :class="col.class"
                                        :style="
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @click="onClick(col.emit, col.$onClick, entry, $event)"
                                        @contextmenu="contextMenu(col.$onRightClick, entry, $event)"
                                    >
                                        <span
                                            :class="
                                                (col.textClass || '') +
                                                ((col.emit || col.$textOnClick) ? ' link' : '')
                                            "
                                            :style="
                                                (col.textStyle ? col.textStyle : '') +
                                                (col.$textStyle ? callFunction(col.$textStyle, entry) : '')
                                            "
                                            @click="onClick(col.textEmit, col.$textOnClick, entry, $event)"
                                        >
                                            {{ selectListData[col.key].find(x => {return x[col.keyId || 'id'] === entry[col.key]})[col.keyLabel || 'name'] }}
                                        </span>
                                    </div>
                                </template>

                                <template v-if="(col.type === 'date')">
                                    <!-- <input
                                        v-if="!isColReadonly(col, entry)"
                                        type="date"
                                        v-model.trim="entry[col.key]"
                                        @change="itemModified($event, entry, col.key)"
                                        :id="`input-date-${col.key}-${entryIndex}`"
                                        class="grid-data-container" :class="{ 'column-required': col.required }"
                                        :style="col.style"
                                        :title="(col.title || '') + (col.$title ? callFunction(col.$title, entry) : '')"
                                    /> -->
                                    <DatePicker
                                        v-if="!isColReadonly(col, entry)"
                                        type="date"
                                        :title="(col.title || '') + (col.$title ? callFunction(col.$title, entry) : '')"
                                        :value-type="dateFormat"
                                        :format="col.format || 'DD.MM.YYYY'"
                                        :clearable="false"
                                        :confirm="false"
                                        v-model="entry[col.key]"
                                        :input-class="{ 'grid-data-container':true, 'column-required':col.required }"
                                        :input-style="
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        :input-attr="{ size:9 }"
                                        :placeholder="col.placeholder"
                                        :disabled="col.disabled"
                                        @input="itemModified($event, entry, col.key)"
                                        @change="itemModified($event, entry, col.key)"
                                        @focus="itemFocused($event, entry, col.key)"
                                    />
                                    <div
                                        v-if="isColReadonly(col, entry)"
                                        class="grid-data-container"
                                        :class="{ 'column-required': col.required }"
                                        :style="
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @click="onClick(col.emit, col.$onClick, entry, $event)"
                                        @contextmenu="contextMenu(col.$onRightClick, entry, $event)"
                                    >
                                        <span
                                            :class="
                                                (col.textClass || '') +
                                                ((col.emit || col.$textOnClick) ? ' link' : '')
                                            "
                                            :style="
                                                (col.textStyle ? col.textStyle : '') +
                                                (col.$textStyle ? callFunction(col.$textStyle, entry) : '')
                                            "
                                            @click="onClick(col.textEmit, col.$textOnClick, entry, $event)"
                                        >
                                            {{ entry[col.key] | formatDate }}
                                        </span>
                                    </div>
                                </template>

                                <template v-if="(col.type === 'datetime')">
                                    <div
                                        v-if="isColReadonly(col, entry)"
                                        class="grid-data-container"
                                        :class="{ 'column-required': col.required }"
                                        :style="
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @click="onClick(col.emit, col.$onClick, entry, $event)"
                                        @contextmenu="contextMenu(col.$onRightClick, entry, $event)"
                                    >
                                        <span
                                            :class="
                                                (col.textClass || '') +
                                                ((col.emit || col.$textOnClick) ? ' link' : '')
                                            "
                                            :style="
                                                (col.textStyle ? col.textStyle : '') +
                                                (col.$textStyle ? callFunction(col.$textStyle, entry) : '')
                                            "
                                            @click="onClick(col.textEmit, col.$textOnClick, entry, $event)"
                                        >
                                            {{ entry[col.key] | formatDateTimeShort }}
                                        </span>
                                    </div>
                                </template>

                                <template v-if="(col.type === 'checkbox')">
                                    <input 
                                        type="checkbox"
                                        v-model="entry[col.key]"
                                        :id="`input-checkbox-${col.key}-${entryIndex}`"
                                        :disabled="isColReadonly(col, entry)"
                                        class="checkbox grid-data-container"
                                        :class="{ 'column-required': col.required }"
                                        :style="
                                            (col.style ? col.style : '') +
                                            (col.$style ? callFunction(col.$style, entry) : '')
                                        "
                                        @change="itemModified($event, entry, col.key)"
                                        @click="onClick(col.emit, col.$onClick, entry, $event)"
                                        @contextmenu="contextMenu(col.$onRightClick, entry, $event)"
                                    >
                                </template>
                            </td>
                        </template>
                    </tr>

                    <!-- Subtotals below ========================================= -->
                    <template v-if="config && config.subtotals && config.subtotals.position === 'below'">
                        <template v-for="(subtotalCol, subtotalIndex) in config.subtotals.columns.slice().reverse()">
                            <tr v-if="isDisplaySubtotal(subtotalIndex, entryIndex)" :key="`subtotal-tr-${entryIndex}-${subtotalIndex}`">

                                <!-- Special columns -->

                                <td v-if="config.buttons && config.buttons.includes('row-selector')" class="grid-cell" ></td>

                                <td v-if="config.buttons && config.buttons.includes('edit')" class="grid-cell" ></td>

                                <!-- /Special columns -->

                                <template v-for="(col, colIndex) in config.columns">
                                    <td
                                        v-if="isColVisible(col)"
                                        :key="`subtotals-td-${entryIndex}-${colIndex}`"
                                        class="grid-cell"
                                        :class="(col.type === 'icon' ? 'grid__icon ' : '') + (getTotalsCol(col.key).cellClass || '') + (getTotalsCol(col.key).emit || getTotalsCol(col.key).$onClick ? 'link' : '')"
                                        :style="
                                            (getTotalsCol(col.key).cellStyle || '') +
                                            (col.align ? ` text-align:${col.align};` : '') +
                                            (getTotalsCol(col.key).$cellStyle ? callFunction(getTotalsCol(col.key).$cellStyle, filteredData) : '') +
                                            (col.$align ? ` text-align:${callFunction(col.$align, filteredData)}` : '')
                                        "
                                        :title="(getTotalsCol(col.key).cellTitle || '') + (getTotalsCol(col.key).$cellTitle ? callFunction(getTotalsCol(col.key).$cellTitle, null) : '')"
                                        :colspan="getTotalsCol(col.key).colspan || 1 /* works bad because tds shifts right */ "
                                    >
                                        <div
                                            v-if="getTotalsCol(col.key).type != 'number' && colIndex === 0"
                                            v-html="`${entry[subtotalCol] ? entry[subtotalCol] : getCol(subtotalCol).header + ': ' + $t('lib.Grid.empty')}`"
                                            @click="onClick(getTotalsCol(col.key).emit, getTotalsCol(col.key).$onClick, null, $event)"
                                            @contextmenu="contextMenu(getTotalsCol(col.key).$onRightClick, null, $event)"
                                            :title="(getTotalsCol(col.key).title || '') + (getTotalsCol(col.key).$title ? callFunction(getTotalsCol(col.key).$title, filteredData) : '') "
                                            class="grid-data-container subtotals"
                                            :class="getTotalsCol(col.key).class"
                                            :style="
                                                (getTotalsCol(col.key).align ? `text-align:${getTotalsCol(col.key).align};` : '') +
                                                (getTotalsCol(col.key).style ? getTotalsCol(col.key).style : '') +
                                                (getTotalsCol(col.key).$style ? callFunction(getTotalsCol(col.key).$style, entry) : '')
                                            "
                                        >
                                        </div>

                                        <div
                                            v-if="getTotalsCol(col.key).type != 'number' && colIndex != 0"
                                            v-html="getSubTotalsValue(colIndex, config.subtotals.columns.length - subtotalIndex - 1, entryIndex)"
                                            @click="onClick(getTotalsCol(col.key).emit, getTotalsCol(col.key).$onClick, null, $event)"
                                            @contextmenu="contextMenu(getTotalsCol(col.key).$onRightClick, null, $event)"
                                            :title="(getTotalsCol(col.key).title || '') + (getTotalsCol(col.key).$title ? callFunction(getTotalsCol(col.key).$title, filteredData) : '') "
                                            class="grid-data-container subtotals"
                                            :class="getTotalsCol(col.key).class"
                                            :style="
                                                (getTotalsCol(col.key).align ? `text-align:${getTotalsCol(col.key).align};` : '') +
                                                (getTotalsCol(col.key).style ? getTotalsCol(col.key).style : '') +
                                                (getTotalsCol(col.key).$style ? callFunction(getTotalsCol(col.key).$style, entry) : '')
                                            "
                                        >
                                        </div>

                                        <div
                                            v-if="getTotalsCol(col.key).type === 'number' && colIndex != 0"
                                            @click="onClick(getTotalsCol(col.key).emit, getTotalsCol(col.key).$onClick, null, $event)"
                                            @contextmenu="contextMenu(getTotalsCol(col.key).$onRightClick, null, $event)"
                                            :title="(getTotalsCol(col.key).title || '') + (getTotalsCol(col.key).$title ? callFunction(getTotalsCol(col.key).$title, filteredData) : '') "
                                            class="grid-data-container subtotals"
                                            :class="getTotalsCol(col.key).class"
                                            :style="
                                                (getTotalsCol(col.key).align ? `text-align:${getTotalsCol(col.key).align};` : 'text-align:right;') +
                                                (getTotalsCol(col.key).style ? getTotalsCol(col.key).style : '') +
                                                (getTotalsCol(col.key).$style ? callFunction(getTotalsCol(col.key).$style, entry) : '')
                                            "
                                        >
                                            {{ formatNumber(getSubTotalsValue(colIndex, config.subtotals.columns.length - subtotalIndex - 1, entryIndex)) }}
                                        </div>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </template>

                </template>

                <!-- Totals ========================================= -->
                <tr v-if="config && config.totals && filteredData.length > 0" >
                    <!-- Special columns -->

                    <td v-if="config.buttons && config.buttons.includes('row-selector')" class="grid-cell" ></td>

                    <td v-if="config.buttons && config.buttons.includes('edit')" class="grid-cell" ></td>

                    <!-- /Special columns -->

                    <template v-for="(col) in config.columns">
                        <td
                            v-if="isColVisible(col)"
                            :key="`totals-td-${col.key}`"
                            class="grid-cell"
                            :class="(col.type === 'icon' ? 'grid__icon ' : '') + (getTotalsCol(col.key).cellClass || '') + (getTotalsCol(col.key).emit || getTotalsCol(col.key).$onClick ? 'link' : '')"
                            :style="
                                (getTotalsCol(col.key).cellStyle || '') +
                                (col.align ? ` text-align:${col.align};` : '') +
                                (getTotalsCol(col.key).$cellStyle ? callFunction(getTotalsCol(col.key).$cellStyle, filteredData) : '') +
                                (col.$align ? ` text-align:${callFunction(col.$align, filteredData)}` : '')
                            "
                            :title="(getTotalsCol(col.key).cellTitle || '') + (getTotalsCol(col.key).$cellTitle ? callFunction(getTotalsCol(col.key).$cellTitle, null) : '')"
                            :colspan="getTotalsCol(col.key).colspan || 1 /* works bad because tds shifts right */ "
                        >
                            <div
                                v-if="getTotalsCol(col.key).type != 'number'"
                                v-html="getTotalsValue(col.key)"
                                @click="onClick(getTotalsCol(col.key).emit, getTotalsCol(col.key).$onClick, null, $event)"
                                @contextmenu="contextMenu(getTotalsCol(col.key).$onRightClick, null, $event)"
                                :title="(getTotalsCol(col.key).title || '') + (getTotalsCol(col.key).$title ? callFunction(getTotalsCol(col.key).$title, filteredData) : '') "
                                class="grid-data-container totals"
                                :class="getTotalsCol(col.key).class"
                                :style="
                                    (getTotalsCol(col.key).align ? `text-align:${getTotalsCol(col.key).align};` : '') +
                                    (getTotalsCol(col.key).style ? getTotalsCol(col.key).style : '')
                                "
                            >
                            </div>

                            <div
                                v-if="getTotalsCol(col.key).type === 'number' && getTotalsValue(col.key) != null"
                                @click="onClick(getTotalsCol(col.key).emit, getTotalsCol(col.key).$onClick, null, $event)"
                                @contextmenu="contextMenu(getTotalsCol(col.key).$onRightClick, null, $event)"
                                :title="(getTotalsCol(col.key).title || '') + (getTotalsCol(col.key).$title ? callFunction(getTotalsCol(col.key).$title, filteredData) : '') "
                                class="grid-data-container totals"
                                :class="getTotalsCol(col.key).class"
                                :style="
                                    (getTotalsCol(col.key).align ? `text-align:${getTotalsCol(col.key).align};` : 'text-align:right;') +
                                    (getTotalsCol(col.key).style ? getTotalsCol(col.key).style : '')
                                "
                            >
                                {{ formatNumber(getTotalsValue(col.key)) }}
                            </div>
                        </td>
                    </template>
                </tr>

                <tr v-if="filteredData.length === 0">
                    <td v-bind:colspan="columnsQty" class="grid-cell" ><div class="no-data-found">{{ $t('lib.messages.noDataFound') }}</div></td>
                </tr>
                <!-- <tr v-if="filteredData.length > 0">
                    <td :colspan="columnsQty">
                        <b-pagination v-model="paginationCurrentPage" :total-rows="paginationTotalRows"></b-pagination>
                    </td>
                </tr> -->
            </tbody>
        </table>

        <div class="d-flex" v-if="config && (config.displayPagination || typeof config.displayPagination === 'undefined')">
            <div>
                <b-pagination
                    v-if="paginationTotalRows > config.paginationPageSize"
                    v-model="paginationCurrentPage"
                    :total-rows="paginationTotalRows"
                    :per-page="config.paginationPageSize"
                    first-number
                    last-number
                />
            </div>
            <div class="ml-auto pt-1 mr-2" >{{ paginationPosition() }}</div>
        </div>

        <ModalWindow v-if="isDisplayWarningDelete" minWidth="300px" :header="$t('lib.Grid.warning')" :buttons="['ok', 'cancel']" @ok="processDeleteItems" @cancel="cancelDeleteItems" >
            {{ $t('lib.messages.confirmDelete') }}
        </ModalWindow>

        <ModalWindow v-if="isDisplayWarningRefresh" minWidth="300px" :header="$t('lib.Grid.warning')" :buttons="['ok', 'cancel']" @ok="processRefresh" @cancel="cancelRefresh" >
            {{ $t('lib.messages.confirmRefreshPage') }}
        </ModalWindow>

        <ModalWindow v-if="isDisplayWarningClose" minWidth="300px" :header="$t('lib.Grid.warning')" :buttons="['ok', 'cancel']" @ok="processClose" @cancel="cancelClose" >
            {{ $t('lib.messages.confirmClosePage') }}
        </ModalWindow>

        <ModalWindow v-if="popuplistCol && popuplistCol.key" width="90%" max-width="900px" max-height="90vh" @cancel="popuplistCol = null" :header="popuplistCol ? popuplistCol.header : ''" >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="popuplist" :config="popuplistCol ? popuplistCol.listConfig : null" @item-selected="popuplistSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>

        <div v-if="isDisplaySpinner" class="spinner-holder">
            <b-spinner label="Loading..."></b-spinner>
        </div>

    </div>
</template>

<script>
    /* eslint-disable no-console */

    /* 
    TODOs:
      moving between cells left, right, up, down arrows

    !!!!! NOW every data row MUST have 'id' column !!!!!
    due to key attribute in <tr v-for=entry in filteredData> row

    */

    import moment from 'moment';
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import ModalWindow from './ModalWindow';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/ru';

    export default {
        name: 'Grid',

        components: { ModalWindow, DatePicker },

        // =============== Props ===============
        props: {
            source: Array,
            config: { required: true },
        },

        // =============== Data ===============
        data() {
            var sortOrders = {};
            if (this.config) {
                this.config.columns.forEach(function(col) {
                    sortOrders[col.key] = 1
                });
            }
            return {
                filteredData: [],
                isModified: false,
                isSelected: false,
                newRowsQty: 0,

                isDisplayWarningDelete: false,
                isDisplayWarningRefresh: false,
                isDisplayWarningClose: false,
                isDisplaySpinner: false,

                alertSuccessMessage: '',
                alertErrorMessage: '',
                
                checkboxSelectAllItems: false,
                searchKeybuffer: '',
                searchString: '',
                debounceTimeout: 600,
                debounce: null,
                sortKey: '',
                sortOrders: sortOrders,
                dataArray: [],
                selectListData: {},
                popuplistCol: null,
                popuplistEntry: null,

                dateFormat: null,
                datetimeFormat: null,

                apiRequestId: 0,

                paginationTotalRows: 0,
                paginationCurrentPage: 1,
                paginationPageSizeDefault: 20,

                subtotalLevelPadding: 16,
            };
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            apiUrl() { return this.config && this.config.apiUrl ? `${this.baseUrl}/${this.config.apiUrl}` : null },
            // source() { return this.config && Array.isArray(this.config.source) ? this.config.source : [] },
            // source() { return this.config.source },
            // filter() { return this.config ? this.config.filter : {} },

            /* filteredData2() {
                var sortKey = this.sortKey;
                var filterKey = this.searchString && this.searchString.toLowerCase();
                var order = this.sortOrders[sortKey] || 1;
                var data = this.dataArray;

                if (filterKey) {
                    data = data.filter(function(row) {
                        return Object.keys(row).some(function(key) {
                            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
                        });
                    });
                }
                if (sortKey) {
                    data = data.slice().sort(function(a, b) {
                        a = a[sortKey];
                        b = b[sortKey];
                        return (a === b ? 0 : a > b ? 1 : -1) * order;
                    });
                }
                return data;
            }, */

            columnsQty() {
                if (this.config) {
                    // count all visible (except hidden)
                    let columnQty = this.config.columns.reduce(((qty, col) => this.isColVisible(col) ? qty + 1 : qty), 0);
                    let editQty = this.config.buttons && this.config.buttons.includes('edit') ? 1 : 0;
                    let rowSelectorQty = this.config.buttons && this.config.buttons.includes('row-selector') ? 1 : 0;
                    return columnQty + editQty + rowSelectorQty;
                }
                return 0;
            },

            customButtons() {
                if (this.config && this.config.buttons) {
                    return this.config.buttons.filter(button => {
                        // let config = this.config;
                        return typeof button === 'object' && (button.$condition ? this.callFunction(button.$condition, {}) : true);
                    });
                }
                return [];
            },

        },

        // =============== Watch ===============
        watch: {
            isModified() {
                this.toggleButtons();
                // console.log(`DEBUG: ${this.$options.name}.watch.isModified(${this.isModified}, ${this.newRowsQty})`);
                this.$emit('modified', this.isModified || this.newRowsQty > 0);
            },

            newRowsQty() {
                this.toggleButtons();
                // console.log(`DEBUG: ${this.$options.name}.watch.newRowsQty(${this.isModified}, ${this.newRowsQty})`);
                this.$emit('modified', this.isModified || this.newRowsQty > 0);
            },

            searchKeybuffer() {
                this.filterData();
            },

            apiUrl() {
                // this.paginationCurrentPage = 1;
                // console.log(`DEBUG: ${this.$options.name}.watch.apiUrl =`, this.apiUrl);
                this.getData();
            },
            source() {
                // console.log(`DEBUG: ${this.$options.name}.watch.source =`, this.source);
                // this.paginationCurrentPage = 1;
                this.getData();
            },
            // filter() {
            //     console.log(`DEBUG: ${this.$options.name}.watch.filter =`, this.filter);
            //     // this.paginationCurrentPage = 1;
            //     this.getData();
            // },

            // config() {
            //     await this.getData();
            // },
            // config: {
            //     handler() {
            //         // duplicated with other watches
            //         // console.log(`DEBUG: ${this.$options.name}.watch.config =`, this.config);
            //         // this.paginationCurrentPage = 1;
            //         this.getData();
            //     },
            //     deep: true,
            // },
            // 'config.filter': {
            //     handler() {
            //         // console.log(`DEBUG: ${this.$options.name}.watch.config.filter =`, this.config.filter);
            //         // this.paginationCurrentPage = 1;
            //         this.getData();
            //     },
            //     deep: true,
            // },
            // 'config.filter.where': {
            //     handler() {
            //         // console.log(`DEBUG: ${this.$options.name}.watch.config.filter.where =`, this.config.filter.where);
            //         // this.paginationCurrentPage = 1;
            //         this.getData();
            //     },
            //     deep: true,
            // },
            // 'config.filter.order': {
            //     handler() {
            //         // console.log(`DEBUG: ${this.$options.name}.watch.config.filter.order =`, this.config.filter.order);
            //         this.getData();
            //     },
            //     deep: true,
            // },
            // 'config.filter.pagination': {
            //     handler() { this.getData(); },
            //     deep: true,
            // },

            paginationCurrentPage() {
                // console.log(`DEBUG: ${this.$options.name}.watch.paginationCurrentPage =`, this.paginationCurrentPage);
                this.getData();
            },

            paginationTotalRows() {
                // console.log(`DEBUG: ${this.$options.name}.watch.paginationTotalRows =`, this.paginationTotalRows);
                let start = (this.paginationCurrentPage - 1) * this.config.paginationPageSize + 1;
                if (start < this.paginationTotalRows && this.paginationCurrentPage > 1) {
                    // console.log(`DEBUG: watch.${this.$options.name}.paginationTotalRows page to 1`);
                    this.paginationCurrentPage = 1;
                }
            },

            // sortKey() {
            //     // !!! do not need to getData, because it set in sortBy(called getData()) and orderBy(called by getData()) !!!
            //     // console.log(`DEBUG: ${this.$options.name}.watch.sortKey =`, this.sortKey);
            //     this.getData();
            // },

            sortOrders: {
                handler() {
                    // console.log(`DEBUG: ${this.$options.name}.watch.sortOrders=`, this.sortOrders);
                    this.getData();
                },
                deep: true,
            },

            /*filteredData() {
                this.toggleButtons();
            },*/

            // command() {
            //     // console.log(`DEBUG: ${this.$options.name}.watch.command(), command=`, this.command);
            //     if (!this.command) {
            //         this.command = null;
            //     }
            // },

            checkboxSelectAllItems() {
                this.filteredData.forEach(row => {
                    // (config.row && config.row.$selectorCondition ? callFunction(config.row.$selectorCondition, entry) : true)
                    if (this.config.row && this.config.row.$selectorCondition ? this.callFunction(this.config.row.$selectorCondition, row) : true) {
                        row.$isSelected = this.checkboxSelectAllItems;
                    } else {
                        row.$isSelected = false;
                    }
                });
                this.toggleButtons();
                this.$emit('item-selected', this.selectedData());
            },
        },

        // =============== Filters ===============
        filters: {
            capitalize(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            },
            formatDate(d) {
                if (!d) return '';
                return moment(d).format('L');
            },
            formatDateTimeShort(d) {
                if (!d) return '';
                return moment(d).format('L HH:mm');
            },
            // formatNumber(n) {
            //     // return n /* || n === 0 */ ? n.toLocaleString() : '';
            //     return n || (n === 0 && !(this.config && this.config.hideZero)) ? n.toLocaleString() : '';
            // },
        },

        // =============== Methods ===============
        methods: {
            formatNumber(n) {
                // return n /* || n === 0 */ ? n.toLocaleString() : '';
                return n || (n === 0 && !(this.config && this.config.hideZero)) ? n.toLocaleString() : '';
            },
            paginationPosition() {
                // console.log(`DEBUG: ${this.$options.name}.paginationPosition(${this.paginationTotalRows}, ${this.config.paginationPageSize})`);
                if (this.paginationTotalRows > this.config.paginationPageSize) {
                    let start = (this.paginationCurrentPage - 1) * this.config.paginationPageSize + 1;
                    let end = Math.min(start + this.config.paginationPageSize - 1, this.paginationTotalRows);
                    return `${start} - ${end} / ${this.paginationTotalRows}`;
                } else {
                    return `${this.paginationTotalRows}`;
                }
            },

            isDisplaySubtotal(subtotalIndex, dataIndex) {
                // console.log(`DEBUG: ${this.$options.name}.isDisplaySubtotal(${subtotalIndex}, ${dataIndex})`);
                if (!this.config.subtotals) return false;
                if (subtotalIndex < 0 || subtotalIndex >= this.config.subtotals.columns.length) return false;
                if (this.config.subtotals.position === 'above') {
                    return (
                        dataIndex === 0 ||
                        this.filteredData[dataIndex][this.config.subtotals.columns[subtotalIndex]] != this.filteredData[dataIndex - 1][this.config.subtotals.columns[subtotalIndex]] ||
                        this.isDisplaySubtotal(subtotalIndex - 1, dataIndex)
                        // this.config.subtotals.columns.some( el => this.filteredData[dataIndex][el] != this.filteredData[dataIndex - 1][el])
                    );
                }
                if (this.config.subtotals.position === 'below') {
                    return (
                        dataIndex === this.filteredData.length - 1 ||
                        this.filteredData[dataIndex][this.config.subtotals.columns.slice().reverse()[subtotalIndex]] != this.filteredData[dataIndex + 1][this.config.subtotals.columns.slice().reverse()[subtotalIndex]] ||
                        // this.filteredData[dataIndex][this.config.subtotals.columns.slice().reverse()[subtotalIndex + 1]] != this.filteredData[dataIndex + 1][this.config.subtotals.columns.slice().reverse()[subtotalIndex + 1]]
                        // this.config.subtotals.columns.some( el => this.filteredData[dataIndex][el] != this.filteredData[dataIndex + 1][el]) ||
                        this.isDisplaySubtotal(subtotalIndex + 1, dataIndex)
                    );
                }
                return false;
            },

            getCol(key) {
                if (this.config && this.config.columns) return this.config.columns.find(col => col.key === key ) || {};
                return {};
            },
            getTotalsCol(key) {
                // console.log(`DEBUG: ${this.$options.name}.getTotalsCol(${key}) this.config.totals=`, this.config.totals);
                if (this.config && this.config.totals && this.config.totals.columns) {
                    return this.config.totals.columns.find(totalsCol => totalsCol.key === key ) || {};
                }
                return {};
            },
            getTotalsValue(key) {
                let result1 = this.getTotalsCol(key).value;
                if (result1) return result1;
                return this.getTotalsCol(key).$value ? this.callFunction(this.getTotalsCol(key).$value, this.filteredData, this) : null;
            },
            getSubTotalsValue(colIndex, subtotalIndex, dataIndex) {
                // console.log(`DEBUG: ${this.$options.name}.getSubTotalsValue(${colIndex}, ${subtotalIndex}, ${dataIndex})`);
                if (this.config.columns[colIndex].type != 'number') return null;
                let hArray = this.config.subtotals.columns.slice(0, subtotalIndex + 1);
                // console.log(`DEBUG: ${this.$options.name}.getSubTotalsValue(${colIndex}, ${subtotalIndex}, ${dataIndex}) hArray=`, hArray);
                let result = this.filteredData.reduce( (
                    (sum, item, index) => {
                        if (hArray.some( el => this.filteredData[index][el] != this.filteredData[dataIndex][el])) return sum;
                        return sum + item[this.config.columns[colIndex].key];
                    }
                ), 0 );
                return result;
            },

            isColVisible(col) {
                // console.log(`DEBUG: ${this.$options.name}.isColVisible(${col.key}) hidden=`, col.hidden);
                return (
                    col.type != 'hidden' && 
                    !col.hidden &&
                    (col.$hidden && typeof col.$hidden === 'function' ? col.$hidden(this.filteredData) : true)
                );
            },

            isColReadonly(col, entry) {
                // return col.readonly || this.config.readonly || this.callFunction(col.$readonly, entry);
                return (
                    col.readonly !== false &&
                    this.callFunction(col.$readonly, entry) !== false &&
                    (col.readonly || this.config.readonly || this.callFunction(col.$readonly, entry))
                );
            },

            callFunction(f, entry, evt) {
                // console.log('f=', f);
                // console.log(`DEBUG: ${this.$options.name}.callFunction() entry=`, entry);
                // console.log(`DEBUG: ${this.$options.name}.callFunction() typeof f=`, typeof f);
                // console.log(`DEBUG: ${this.$options.name}.callFunction() f=`, f);
                if (typeof f === 'function') {
                    return f(entry, evt, this);
                }
                // return eval(exp); // !!! potentially dangerous !!!
            },

            contextMenu(f, entry, evt) {
                if (typeof f === 'function') {
                    evt.preventDefault();
                    return f(entry, evt, this);
                }
            },

            listSelectedElement(entry, col) {
                // console.log(`DEBUG: ${this.$options.name}.listSelectedElement(${col.key}) this.selectListData=`, this.selectListData);
                // console.log(`DEBUG: ${this.$options.name}.listSelectedElement(${col.key}) col.key=${col.key} this.selectListData=`, this.selectListData);
                // console.log(`DEBUG: ${this.$options.name}.listSelectedElement(${col.key}) col.key=${col.key} this.selectListData[col.key]=`, this.selectListData[col.key]);
                // console.log(`DEBUG: ${this.$options.name}.listSelectedElement(${col.key}), 'id'=${col.keyId || 'id'} entry=`, entry);
                // console.log(`DEBUG: ${this.$options.name}.listSelectedElement(${col.key}) selectedElement=`, selectedElement);
                let selectedElement = this.selectListData[col.key].find(x => {return x[col.keyId || 'id'] === entry[col.key]});
                return selectedElement ? selectedElement[col.keyLabel || 'name'] : null;
            },

            openPopupList(entry, col) {
                // console.log(`DEBUG: ${this.$options.name}.openPopupList(${col.key}) entry=`, entry);
                // console.log(`DEBUG: ${this.$options.name}.openPopupList(${col.key}) col=`, col);
                this.popuplistCol = col;
                this.popuplistEntry = entry;
                this.popuplistCol.listConfig.row = { emit: "item-selected", style: "cursor: pointer;" };
                if (!this.popuplistCol.listConfig.paginationPageSize) this.popuplistCol.listConfig.paginationPageSize = 10;
            },

            popuplistSelected(entry) {
                // console.log(`DEBUG: ${this.$options.name}.popuplistSelected() entry=`, entry);
                this.popuplistEntry[this.popuplistCol.key] = entry[this.popuplistCol.keyId || 'id'];
                this.itemModified(null, this.popuplistEntry, this.popuplistCol.key);
                this.popuplistCol = null;
                // console.log(`DEBUG: ${this.$options.name}.popuplistSelected() this.popuplistEntry=`, this.popuplistEntry);
            },

            onClick(emit, onClickFunction, entry, evt) {
                if (emit) this.$emit(emit, entry);
                this.callFunction(onClickFunction, entry, evt);
            },

            addSystemColumns(row, isNew) {
                // console.log(`DEBUG: ${this.$options.name}.addSystemColumns() row=`, row);
                if (!row.$isNew) row.$isNew = isNew; // only if $isNew is not set externally
                if (row.$isNew || !row.$isModified) row.$isModified = false; // only if not $isNew and $isModified is not set externally
                row.$isSelected = false;
                // row.$forceSort = (isNew ? 0 : 1);
                // row.$isDeleted = false;
            },

            async getListData() {
                for (let i = 0; i < this.config.columns.length; i++) {
                    if (this.config.columns[i].type === 'select' || this.config.columns[i].type === 'popuplist') {
                        if (this.config.columns[i].type === 'popuplist') {
                            // do some magic
                            // set apiUrl from col.source
                            if (!this.config.columns[i].listConfig.apiUrl && typeof this.config.columns[i].source === 'string') {
                                this.config.columns[i].listConfig.apiUrl = this.config.columns[i].source;
                            }
                        }

                        let listArray = [];
                        if (typeof this.config.columns[i].source === 'string') {
                            // get items from api
                            try {
                                listArray = (await this.authenticatedAxios.get(this.baseUrl + '/' + this.config.columns[i].source)).data.rows;
                                if (!listArray) listArray = [];
                            } catch (err) {
                                tools.displayApiError(this, err);
                                // return; // continue working without this list data
                            }
                        } else if (typeof this.config.columns[i].source === 'object' && Array.isArray(this.config.columns[i].source)) {
                            // get items from array
                            listArray = this.config.columns[i].source;
                        }

                        // Do sort select items
                        listArray = listArray.slice().sort((a, b) => {
                            let aa = a[this.config.columns[i].order || this.config.columns[i].keyLabel || 'name'];
                            let bb = b[this.config.columns[i].order || this.config.columns[i].keyLabel || 'name'];
                            return (aa === bb ? 0 : aa > bb ? 1 : -1);
                        });
                        this.selectListData[this.config.columns[i].key] = listArray;

                        // !!!!! Add value presentation to grid to quick search function
                        // !!!!! Add text representation to Select items for quick search working and to sort by
                        // selectListData
                    }
                }
                // console.log(`this.selectListData=`, JSON.stringify(this.selectListData));
            },

            async getData() {
                // this.checkboxSelectAllItems = false;
                try {
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.apiUrl=${this.apiUrl}, this.config=`, this.config);
                    this.isDisplaySpinner = true;
                    if (!this.config) {
                        this.dataArray = [];
                        this.isModified = false;
                        this.toggleButtons();
                        return;
                    }

                    await this.getListData();

                    if (!this.apiUrl && !(Array.isArray(this.source) && this.source.length > 0)) {
                        this.dataArray = [];
                        this.filterData();
                        this.isModified = false;
                        this.toggleButtons();
                        return;
                    }

                    // get the main grid data
                    // console.log(`DEBUG: ${this.$options.name}.getData 2 this.apiUrl=${this.apiUrl}`);
                    // console.log(`DEBUG: ${this.$options.name}.getData('${this.source}') this.config.filter=`, this.config.filter);
                    let tmpArray = [];
                    let filter = {};
                    try {
                        filter = JSON.parse(JSON.stringify( this.config.filter ? this.config.filter : {} ));
                    } catch(err) {
                        // console.log(`DEBUG: ${this.$options.name}.getData() ERROR!`, err);
                        tools.displayError(this, err.message, 'Filter data error');
                    }
                    // console.log(`DEBUG: ${this.$options.name}.getData() filter=`, filter);

                    if (this.sortKey === '' && this.config.order) {
                        //console.log(`Sorting by (${this.config.order[0].key}, ${this.config.order[0].direction})`);
                        if (typeof this.config.order[0] === 'object') {
                            this.setOrderKeyAndDirection(this.config.order[0].key, this.config.order[0].direction || 'asc');
                        } else {
                            this.setOrderKeyAndDirection(this.config.order[0], 'asc');
                        }
                    }

                    if (this.apiUrl) {
                        try {
                            filter.requestId = ++this.apiRequestId;
                            // pagination
                            filter.pagination = { pageSize:this.config.paginationPageSize || this.paginationPageSizeDefault, currentPage:this.paginationCurrentPage };
                            // if (this.sortKey) {
                            //     // filter.order = `${this.sortKey} ${this.sortOrders[this.sortKey] == 1  ? 'asc' : this.sortOrders[this.sortKey] == -1 ? 'desc' : '' }`;
                            //     filter.order = [{ name:this.sortKey, order:(this.sortOrders[this.sortKey] == 1  ? 'asc' : this.sortOrders[this.sortKey] == -1 ? 'desc' : '') }];
                            // }

                            // search and sort
                            try {
                                filter.gds = {
                                    c: [],
                                }
                                // console.log(`DEBUG: ${this.$options.name}.getData() this.sortKey=${this.sortKey}`);
                                if (this.sortKey) {
                                    // console.log(`DEBUG: ${this.$options.name}.getData() this.sortKey=${this.sortKey}`);
                                    if (this.config && this.config.subtotals) {
                                        filter.gds.o = this.config.subtotals.columns.map( col => ({ n:col, o:'asc' }) );
                                    } else {
                                        filter.gds.o = [];
                                    }
                                    filter.gds.o.push(
                                        { n:this.sortKey, o:(this.sortOrders[this.sortKey] == 1  ? 'asc' : this.sortOrders[this.sortKey] == -1 ? 'desc' : '') }
                                    );
                                    // filter.gds.o = [{ n:this.sortKey, o:(this.sortOrders[this.sortKey] == 1  ? 'asc' : this.sortOrders[this.sortKey] == -1 ? 'desc' : '') }];
                                    // console.log(`DEBUG: ${this.$options.name}.getData() filter.gds.o=`, filter.gds.o);
                                }
                                let suppotedSearchTypes = [ 'string', 'number', 'select', 'popuplist', 'date', 'time', 'datetime', 'checkbox' ];
                                for (let col of this.config.columns) {
                                    if (suppotedSearchTypes.includes(col.type) && col.key[0] !== '$') {
                                        let c = {
                                            n:col.key,
                                            t:col.type,
                                        };
                                        if (col.type === 'date') c.f = moment.localeData().longDateFormat('L');
                                        if (col.type === 'datetime') c.f = `${moment.localeData().longDateFormat('L')} hh24:mi`;
                                        if (['select', 'popuplist'].includes(col.type)) {
                                            c.s = col.source;
                                            if (col.keyId) c.ki = col.keyId;
                                            if (col.keyLabel) c.kl = col.keyLabel;
                                        }
                                        filter.gds.c.push(c);
                                    }
                                }
                                if (this.searchString.length > 0) filter.gds.s = this.searchString;
                            } catch(err) {
                                // console.log(`DEBUG: ${this.$options.name}.getData() err=`, err);
                                tools.displayError(this, err.message, 'Search error');
                            }

                            // console.log(`DEBUG: ${this.$options.name}.getData() this.apiUrl=`, this.apiUrl);
                            // console.log(`DEBUG: ${this.$options.name}.getData() filter=`, filter);
                            let filterString = tools.getFilterString(filter);
                            // console.log(`DEBUG: ${this.$options.name}.getData() url=`, `${this.apiUrl}${filterString}`);
                            let response = await this.authenticatedAxios.get(`${this.apiUrl}${filterString}`);
                            // console.log(`DEBUG: ${this.$options.name}.getData(${this.apiUrl}${filterString}) response=`, response);

                            if (response.data.requestId === this.apiRequestId || !response.data.requestId) {
                                tmpArray = response.data.rows;
                            } else {
                                return;
                            }

                            if (response.data.pagination) {
                                if (response.data.pagination.total) {
                                    this.paginationTotalRows = parseInt(response.data.pagination.total);
                                } else {
                                    this.paginationTotalRows = 0;
                                }
                            } else {
                                this.paginationTotalRows = tmpArray.length;
                            }
                            // console.log(`DEBUG: ${this.$options.name}.getData() this.paginationTotalRows=`, this.paginationTotalRows);
                            // console.log(`DEBUG: ${this.$options.name}.getData() this.config.paginationPageSize=`, this.config.paginationPageSize);
                        } catch (err) {
                            tools.displayApiError(this, err);
                        }
                    } else {
                        if (Array.isArray(this.source)) tmpArray = this.source;
                        this.paginationTotalRows = tmpArray.length;
                        // console.log(`DEBUG: ${this.$options.name}.getData() tmpArray=`, tmpArray);
                    }

                    // console.log(`DEBUG: ${this.$options.name}.getData() tmpArray=`, tmpArray);
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.config.columns=`, this.config.columns);
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.selectListData=`, this.selectListData);

                    // get text presentation of select typed cells
                    tmpArray.forEach( entry => {
                        this.config.columns.forEach( col => {
                            if (col.type === 'select' || col.type === 'popuplist') {
                                // console.log(`DEBUG: ${this.$options.name}.getData() col.key=`,  col.key);
                                let element = this.selectListData[col.key].find(x => {return x[col.keyId||'id'] === entry[col.key]});
                                let value = (element ? element[col.keyLabel || 'name'] : '');
                                entry['$' + col.key + '.' + 'presentation'] = value;
                            }
                            if (col.type === 'date') {
                                // let value = moment(entry[col.key]).format('L');
                                // console.log(`DEBUG: ${this.$options.name}.getData() col=`, col);
                                // console.log(`DEBUG: ${this.$options.name}.getData() date presentation=${value}`);
                                entry['$' + col.key + '.' + 'presentation'] =  moment(entry[col.key]).format('L');
                            }
                            if (col.type === 'datetime') {
                                entry['$' + col.key + '.' + 'presentation'] = moment(entry[col.key]).format('L HH:mm');
                            }
                        });
                        this.addSystemColumns(entry, false);
                    });
                    this.dataArray = tmpArray;
                    this.newRowsQty = 0;
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.dataArray=`, this.dataArray);

                    this.filterData();
                    this.checkboxSelectAllItems = false;

                    this.isModified = false;
                    this.$emit('modified', false);
                    this.$emit('refreshed');
                    this.toggleButtons();

                    // if (this.sortKey === '' && this.config.order) {
                    //     //console.log(`Sorting by (${this.config.order[0].key}, ${this.config.order[0].direction})`);
                    //     if (typeof this.config.order[0] === 'object') {
                    //         this.orderBy(this.config.order[0].key, this.config.order[0].direction);
                    //     } else {
                    //         this.orderBy(this.config.order[0], 'asc');
                    //     }
                    // }
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.dataArray.length=`, this.dataArray.length);
                } finally {
                    // to catch all returns
                    this.isDisplaySpinner = false;
                }
            },

            addRow() {
                if (!this.config) return;
                if (this.config.$onAdd) {
                    // console.log(`type of this.config.$onAdd = ${typeof this.config.$onAdd}`);
                    if (typeof this.config.$onAdd === 'function') {
                        this.config.$onAdd(this);
                    }
                    return;
                }
                // if (this.config.onAdd) {
                //     this.$router.push(this.config.onAdd);
                // }
                if (this.config.readonly) {
                    this.$emit('add-item');
                } else {
                    // !!! focus on _first_new_ row's first editable item !!!
                    let newRow = {};
                    // console.log(`DEBUG: ${this.$options.name}.addRow() newRow1=`, newRow);
                    this.config.columns.forEach(item => {
                        // console.log(`DEBUG: ${this.$options.name}.addRow() item[${item.key}]=`, item);
                        if (typeof item.defaultValue !== 'undefined') {
                            newRow[item.key] = item.defaultValue;
                            // console.log(`DEBUG: ${this.$options.name}.addRow() newRow2[${item.key}]=`, newRow[item.key]);
                        } else {
                            newRow[item.key] = null;
                        }
                    });
                    this.addSystemColumns(newRow, true);
                    this.dataArray.unshift(newRow);
                    this.paginationTotalRows += 1;
                    //this.isModified = true;
                    this.newRowsQty += 1;
                    // this.$emit('add-item');
                    this.filterData();
                }
            },

            editItem(entry) {
                if (!this.config) return;
                if (this.config.$onEdit) {
                    // console.log(`type of this.config.$onEdit = ${typeof this.config.$onEdit}`);
                    if (typeof this.config.$onEdit === 'function') {
                        this.config.$onEdit(this, entry);
                    }
                    return;
                }
                this.$emit('edit-item', entry);
            },

            saveSearchString(searchString) {
                if (this.config && this.config.saveSessionState) {
                    sessionStorage.setItem(`${this.config.saveSessionState}.search-string`, JSON.stringify(searchString));
                }
            },

            restoreSearchString() {
                // console.log(`DEBUG: ${this.$options.name}.restoreSearchString()`);
                if (this.config && this.config.saveSessionState && ((!this.searchKeybuffer) || this.searchKeybuffer.length == 0)) {
                    let searchString = JSON.parse(sessionStorage.getItem(`${this.config.saveSessionState}.search-string`));
                    if (searchString) {
                        this.searchKeybuffer = searchString;
                        this.searchString = searchString;
                    }
                }
            },

            debounceSearch(event) {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.searchString = event.target.value;
                    this.paginationCurrentPage = 1;
                    // because: if change search criteria on 2nd or more page,
                    // but result will be shorter (we dont know in right now), then empty results returned
                    // because request will looks like 'limit 20 offset 20'
                    // console.log(`DEBUG: ${this.$options.name}.debounceSearch() event=`, event);
                    this.saveSearchString(this.searchString);
                    this.getData();
                }, this.debounceTimeout);
            },

            askSearch() {
                this.searchString = this.searchKeybuffer;
                // this.paginationCurrentPage = 1;
                // if (this.paginationTotalRows > this.config.paginationPageSize)
                // console.log(`DEBUG: ${this.$options.name}.askSearch()`);
                this.saveSearchString(this.searchString);
                if (Array.isArray(this.source)) this.$emit('search-pressed');
                this.getData();
            },

            // askRefresh() {
            //     if (this.isModified) {
            //         this.isDisplayWarningRefresh = true;
            //     } else {
            //         this.processRefresh();
            //     }
            // },
            processRefresh() {
                // console.log(`DEBUG: ${this.$options.name}.processRefresh()`);
                this.getData();
                this.isDisplayWarningRefresh = false;
                // this.hideAlertSuccess();
                // this.hideAlertError();
            },
            cancelRefresh() { this.isDisplayWarningRefresh = false },

            toggleButtons() {
                // The Save button must always be enabled
                // The Delete button must be enabled when some rows are selected
                this.isSelected = this.dataArray.some(row => row.$isSelected);
            },

            filterData() {
                if (!this.config) return;
                let sortKey = this.sortKey;
                let filterKey = this.searchKeybuffer && this.searchKeybuffer.toLowerCase();
                this.filteredData = this.dataArray;
                let presentationKey = `$${this.sortKey}.presentation`;
                
                // console.log(`DEBUG: ${this.$options.name}.filterData() sortKey=${sortKey}, this.config.columns=`, this.config.columns);
                let sortCol = this.config.columns.find(x => {return x.key === sortKey});
                if (sortCol && (sortCol.type === 'select' || sortCol.type === 'popuplist')) {
                    sortKey = presentationKey;
                }

                if (filterKey) {
                    this.filteredData = this.filteredData.filter(row => {
                        // console.log('row=', row);
                        if (row.$isNew) return true;
                        return Object.keys(row).some(key => {
                            // console.log(`DEBUG: ${this.$options.name}.filterData() filterKey=${filterKey}, row=`, row);
                            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
                        });
                    });
                }
                // console.log(`DEBUG: ${this.$options.name}.filterData() this.filteredData=`, this.filteredData);

                if (Array.isArray(this.source) && sortKey) {
                    // source is array and sortKey is defined
                    let order = this.sortOrders[this.sortKey] || 1;
                    // console.log(`DEBUG: ${this.$options.name}.filterData() this.sortOrders=`, this.sortOrders);
                    this.filteredData = this.filteredData.slice().sort(function(a, b) {
                        let aa = a[sortKey];
                        let bb = b[sortKey];

                        // New items up. true = 1, false = 0
                        return (a.$isNew === b.$isNew ? ((aa === bb ? 0 : aa > bb ? 1 : -1) * order) : (a.$isNew < b.$isNew ? 1 : -1));
                        // return (aa === bb ? 0 : aa > bb ? 1 : -1) * order;
                    });
                }

                // the sorting is on backend now
                // if (sortKey) {
                //     let order = this.sortOrders[this.sortKey] || 1;
                //     this.filteredData = this.filteredData.slice().sort(function(a, b) {
                //         // console.log(`DEBUG: ${this.$options.name}.filterData() sortKey=`, sortKey, ', a=', a);
                //         // console.log(`DEBUG: ${this.$options.name}.filterData() presentationKey=`, presentationKey, ', typeof=', typeof a[presentationKey]);
                //         // if (this.config.columns[sortKey].type === 'select' || 'popuplist')
                //         // if (typeof a[presentationKey] != 'undefined') sortKey = presentationKey;
                //         let aa = a[sortKey];
                //         let bb = b[sortKey];

                //         // New items up. true = 1, false = 0
                //         return (a.$isNew === b.$isNew ? ((aa === bb ? 0 : aa > bb ? 1 : -1) * order) : (a.$isNew < b.$isNew ? 1 : -1));
                //         // return (aa === bb ? 0 : aa > bb ? 1 : -1) * order;
                //     });
                // }
                // console.log(`DEBUG: ${this.$options.name}.filterData() this.filteredData=`, this.filteredData);
            },

            sortBy(key) {
                // console.log(`DEBUG: ${this.$options.name}.sortBy() this.sortKey=`, this.sortKey, ', key=', key, ', this.sortOrders[key]=', this.sortOrders[key]);
                if (this.sortKey === key) {
                    // if the same key selected, change sort direction
                    this.sortOrders[key] = this.sortOrders[key] * -1;
                }
                this.sortKey = key;
                // this.filterData();
                // commented out because every sort is on server side now. See filterData()
                // if (this.paginationTotalRows > this.config.paginationPageSize) {
                //     console.log(`DEBUG: ${this.$options.name}.sortBy() call getData()`);
                //     this.getData();
                // }
                this.getData();
            },

            setOrderKeyAndDirection(key, direction) {
                this.sortKey = key;
                this.sortOrders[key] = (direction ==='asc' ? 1 : (direction === 'desc' ? -1 : 0));
            },

            // orderBy(key, direction) {
            //     this.sortKey = key;
            //     this.sortOrders[key] = (direction ==='asc' ? 1 : (direction === 'desc' ? -1 : 0));
            //     this.filterData();
            // },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData(${this.isModified}) this.dataArray=`, this.dataArray);
                if (this.isModified) {
                    let rowsInserted = 0;
                    let rowsUpdated = 0;

                    // insert all new rows
                    let rowsToInsert = this.dataArray.filter(row => row.$isNew);
                    let errCount = 0;
                    for (let i = 0; i < rowsToInsert.length; i++) {
                        try {
                            // clear row from service fields
                            let row = {};
                            for (let key in rowsToInsert[i]) {
                                // console.log('key=',key);
                                // Replace empty rows to nulls
                                if (rowsToInsert[i][key] === '') rowsToInsert[i][key] = null;
                                if (key.substring(0, 1) != '$' && key != 'id' )
                                    row[key] = rowsToInsert[i][key];
                            }
                            let response = await this.authenticatedAxios.post(this.apiUrl, row);
                            // console.log(`response[${i}]=`, response);
                            if (response.status === 200) {
                                rowsToInsert[i].id = response.data.id;
                                rowsToInsert[i].$isNew = false;
                                rowsToInsert[i].$isModified = false;
                                this.newRowsQty -= 1;
                                rowsInserted++;
                            } else {
                                // is this code unreachable?
                                // Error occured
                                // console.error('e.response=', response);
                                // this.displayAlertError(this.$t('lib.messages.errorUnableToAddItem'));
                                tools.displayError(this, this.$t('lib.messages.errorUnableToAddItem'));
                                errCount++;
                            }
                        } catch (err) {
                            // console.error(err);
                            // this.displayAlertError(this.$t('lib.messages.errorUnableToAddItem'));
                            // console.log(`err.response=`, err.response);
                            tools.displayApiError(this, err);
                            errCount++;
                        }
                    }

                    // update all modified rows
                    let rowsToUpdate = this.dataArray.filter(row => row.$isModified && !row.$isNew);
                    for (let i = 0; i < rowsToUpdate.length; i++) {
                        try {
                            // clear row from service fields
                            let row = {};
                            for (let key in rowsToUpdate[i]) {
                                // console.log('key=',key);
                                // Replace empty rows to nulls
                                if (rowsToUpdate[i][key] === '') rowsToUpdate[i][key] = null;
                                if (key.substring(0, 1) != '$')
                                    row[key] = rowsToUpdate[i][key];
                            }
                            let response = await this.authenticatedAxios.put(`${this.apiUrl}/${row.id}`, row);
                            if (response.status === 200) {
                                rowsToUpdate[i].$isModified = false;
                                rowsUpdated++;
                            } else {
                                // is this code unreachable?
                                // Error occured
                                // console.error('e.response=', response);
                                // this.displayAlertError(this.$t('lib.messages.errorUnableToUpdateItem'));
                                tools.displayError(this, this.$t('lib.messages.errorUnableToUpdateItem'));
                                errCount++;
                            }
                        } catch (err) {
                            // console.error(err);
                            // this.displayAlertError(this.$t('lib.messages.errorUnableToUpdateItem'));
                            tools.displayApiError(this, err);
                            errCount++;
                        }
                    }

                    if (errCount===0) {
                        this.isModified = false;
                    }
                    if (rowsInserted > 0)
                        tools.displaySuccessSave(this, this.$sprintf(this.$t('lib.messages.rowsInserted'), rowsInserted));
                    if (rowsUpdated > 0)
                        tools.displaySuccessSave(this, this.$sprintf(this.$t('lib.messages.rowsUpdated'), rowsUpdated));
                    // if (rowsInserted > 0 || rowsUpdated > 0) {
                    //     // this.displayAlertSuccess(
                    //     //     `${this.$t('lib.messages.rows')} ${rowsInserted > 0 ? this.$t('lib.messages.inserted') + ' ' + rowsInserted : ''}${(rowsInserted > 0 && rowsUpdated > 0) ? ', ' : ''} ${rowsUpdated ? this.$t('lib.Grid.updated') + ' ' + rowsUpdated : ''}`
                    //     // );
                    // }
                    this.filterData();
                    this.$emit('saved');
                    this.$emit('refreshed');
                }
            },

            askDeleteItems() {
                if (this.isSelected) {
                    this.isDisplayWarningDelete = true;
                }
            },
            async processDeleteItems() {
                if (this.config && this.config.$onDelete) {
                    // console.log(`type of this.config.$onDelete = ${typeof this.config.$onDelete}`);
                    if (typeof this.config.$onDelete === 'function') {
                        this.config.$onDelete(this, this.selectedData());
                    }
                    this.isDisplayWarningDelete = false;
                    return;
                }
                if (this.config.readonly) {
                    this.$emit('delete-items', this.selectedData());
                    this.isDisplayWarningDelete = false;
                    return;
                }
                // no $onDelete defined
                // delete all selected rows
                let indexesToDelete = [];
                let rowsDeleted = 0;
                // let errCount = 0;
                for (let i = 0; i < this.dataArray.length; i++) {
                    if (this.dataArray[i].$isSelected) {
                        // console.log(`DEBUG: ${this.$options.name}.processDeleteItems(), this.dataArray[i]=`, this.dataArray[i]);
                        // if (typeof this.dataArray[i].id === 'undefined' || this.dataArray[i].id === null) {
                        if (this.dataArray[i].$isNew) {
                            // For new rows w/o id
                            // $isNew
                            indexesToDelete.push(i);
                            this.newRowsQty -= 1;
                            rowsDeleted++;
                        } else {
                            // Old items with id
                            try {
                                let response = await this.authenticatedAxios.delete(`${this.apiUrl}/${this.dataArray[i].id}`);
                                if (response.status === 200) {
                                    // remove item from table
                                    indexesToDelete.push(i);
                                    rowsDeleted++;
                                } else {
                                    // is this code unreachable?
                                    // Error occured
                                    // console.error('e.response=', response);
                                    tools.displayError(this, this.$t('lib.messages.errorUnableToDeleteItem'));
                                    // this.displayAlertError(this.$t('lib.messages.errorUnableToDeleteItem'));
                                    // errCount++;
                                }
                            } catch (err) {
                                tools.displayApiError(this, err);
                                // this.displayAlertError(this.$t('lib.messages.errorUnableToDeleteItem'));
                                // errCount++;
                            }
                        }
                    }
                }
                indexesToDelete.sort((a, b) => { return b - a }); // Sort reverse order to remove items from end. Else wrong items will be deleted from dataArray
                indexesToDelete.forEach(i => {
                    this.dataArray.splice(i, 1);
                });

                this.isDisplayWarningDelete = false;
                if (rowsDeleted > 0)
                    tools.displaySuccessSave(this, this.$sprintf(this.$t('lib.messages.rowsDeleted'), rowsDeleted));
                // if (rowsDeleted > 0) {
                //     this.displayAlertSuccess(`${this.$t('lib.messages.rowsDeleted')} ${rowsDeleted}`);
                // }
                this.paginationTotalRows -= rowsDeleted;
                this.filterData();
                this.$emit('deleted');
                this.$emit('refreshed');
            },
            cancelDeleteItems() { this.isDisplayWarningDelete = false },

            itemModified(event, entry, key) {
                entry.$isModified = true;
                this.isModified = true;
                this.toggleButtons();
                // !!! if entry.type === 'datetime' then possible timezone flaw - the time will interpret as in server timezone !!! check it !!!
                // this.$emit('modified', true); // this event is send thru isModified watch
                this.$emit('item-modified', entry);
            },

            itemFocused(event, entry, key) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused(${key}) event=`, event);
                this.$emit('focus', { entry, key, attribute: (this.config.apiUrl ? this.config.apiUrl + '.' : '') + key });
            },

            selectedData() { return this.filteredData.filter( item => item.$isSelected) },

            selectItem(entry) {
                this.toggleButtons();
                this.$emit('item-selected', this.selectedData());
            },

            // async saveWrapper() {
            //     await this.saveData();
            // }
            
            // this methods are for calling from parent component (omg! wrong arcitecture, sinner!)
            async save() {
                await this.saveData();
            },
            clear() {
                this.filteredData = [];
            },
            async refresh() {
                // console.log(`DEBUG: ${this.$options.name}.refresh() this.source=`, this.source);
                await this.getData();
                this.isDisplayWarningRefresh = false;
            },
            getFilteredRowsQty() {
                return this.filteredData.length;
            },
            getTotalRowsQty() {
                // console.log(`DEBUG: ${this.$options.name}.getTotalRowsQty(), this.dataArray.length=`, this.dataArray.length);
                return this.dataArray.length;
            },
            getAllRows() {
                // console.log(`DEBUG: ${this.$options.name}.getTotalRowsQty(), this.dataArray.length=`, this.dataArray.length);
                return this.dataArray;
            },
            getSelectedRows() {
                let selectedRows = this.dataArray.filter( entry => entry.$isSelected );
                // console.log(`DEBUG: ${this.$options.name}.getSelectedRows(), selectedRows=`, selectedRows);
                return selectedRows;
            },
            setModified(value) {
                this.isModified = value;
            },
            selectAll() {
                this.checkboxSelectAllItems = true;
            },

            downloadGrid() {
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), this.$el.innerHTML=`, this.$el.innerHTML);
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), this.$el=`, this.$el);
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), this.$refs.reportTable=`, this.$refs.reportTable);
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), this.filteredData=`, this.filteredData);

                let reportPrefix = `
                    <html><head>
                        <meta charset="utf-8">
                        <style>
                            .subtotals, .totals {
                                font-weight: bold;
                            }
                            .grid-header {
                                color: #495057;
                                background-color: #e9ecef;
                                border:.5pt solid #dee2e6;
                                font-weight: bold;
                            }
                            .grid-cell {
                                border:.5pt solid #dee2e6;
                            }
                        </style>
                    </head><body>
                `;
                let reportSuffix = `
                    </body></html>
                `;
                let parser = new DOMParser();
                let gridTable = parser.parseFromString(this.$refs.reportTable.outerHTML, 'text/html');
                let gridFilters = gridTable.querySelector('.grid-filters');
                if (gridFilters) gridFilters.remove();
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), ${typeof reportBody} gridTable=`, gridTable);

                // let reportBody = this.$refs.reportTable.outerHTML;
                let reportBody = gridTable.querySelector('.grid-editable').outerHTML;
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), ${typeof reportBody} reportBody=`, reportBody);
                reportBody = reportBody.replaceAll('&nbsp;', ' ');
                reportBody = reportBody.replaceAll('<!---->', '');
                reportBody = reportBody.replaceAll(' title=""', '');
                reportBody = reportBody.replaceAll('grid-data-container ', '');
                reportBody = reportBody.replaceAll(' active', '');
                reportBody = reportBody.replaceAll(' column-required', '');
                reportBody = reportBody.replaceAll(' icon', '');
                reportBody = reportBody.replaceAll(' text-center', '');
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), ${typeof reportBody} reportBody=`, reportBody);
                let fileContent = new Blob([ reportPrefix, reportBody, reportSuffix ], { type: 'application/vnd.ms-excel' });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(fileContent);
                link.download = `${ this.config.downloadFileName ? this.config.downloadFileName : 'report' }.xls`;
                link.click();
            },

            download() { this.downloadGrid() },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.config=`, this.config);
            this.restoreSearchString();
            // if (this.config && this.config.paginationPageSize) this.paginationPageSize = this.config.paginationPageSize;
            if (this.config && !this.config.paginationPageSize) this.config.paginationPageSize = this.paginationPageSizeDefault;
            this.dateFormat = moment.HTML5_FMT.DATE;
            this.datetimeFormat = moment.HTML5_FMT.DATETIME_LOCAL;
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() moment.localeData().longDateFormat('L')=`, moment.localeData().longDateFormat('L'));
            this.$on('save', async () => { await this.saveData()} );
        },
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted()`);
            await this.getData();
        },

        updated() {
            // console.log(`DEBUG: ${this.$options.name}.updated()`);
            this.$emit('updated');
        },

    }
</script>

<style>
    /* .grid-container {
        breaks sticky header!!!
        overflow-x: auto;
    } */

    /* .grid-container {
        margin: 16px;
    } */

    .grid-container .btn-toolbar .btn-group, .grid-container .btn-toolbar .btn-group-sm {
        margin-right: 8px;
    }

    .grid-container .btn-toolbar .btn-group button,
    .grid-container .btn-toolbar .btn-group-sm button,
    .grid-container .btn-toolbar .query-input {
        /* min-height: 33.5px; */
        height: 28px;
        min-height: 28px;
    }

    /* .grid-container .input-group-prepend .input-group-text {
        padding-top: 10px;
    } */

    .grid-container .input-group-text {
        padding: .375rem .75rem;
    }

    .grid-container .extra-buttons {
        display: flex;
    }

    .grid-container .btn-toolbar .switch-sm {
        margin-top: auto;
        margin-bottom: auto;
    }

    /* #grid-search {
        margin-bottom: 8px;
    } */

    /* .grid-container .input-group-prepend {
        padding-top: .25rem;
        padding-bottom: .25rem;
    } */
    /* .grid-container .query-input {
        height: 32px;
    } */

    /* table.grid-editable {
        margin: 4px 4px 20px 4px;
    } */

    .grid-container .table .grid-editable thead td, .grid-container .table .grid-editable thead th {
        border-bottom-width: 1px;
    }

    .grid-container .grid-editable thead th {
        font-size: 0.875rem;
        white-space: nowrap;
    }

    .grid-container .grid-header .checkbox {
        margin-top: 0;
    }

    .grid-container .sticky-headers th {
        position: sticky;
        top: 0;
    }

    .grid-container .grid-editable .thead-dark th {
        color: #fff;
        background-color: #505f6d;
        border-color: #5c6770;
    }

    .grid-container .grid-editable .thead-light th {
        background-color: #f8f8f8;
    }

    .grid-container .grid-editable th.icon {
        min-width: 32px;
        max-width: 64px;
    }

    .grid-container .grid-editable > thead > tr > th {
        padding: 8px;
        max-width: 100vh;
    }

    .grid-container table.grid-editable thead td {
        height: 32px;
        padding: 8px;
    }

    .grid-container .grid-editable > tbody > tr, .grid-container .grid-editable > tbody > tr > td {
        height: 32px;
    }

    .grid-container .grid-editable > tbody > tr > td {
        padding: 0;
        max-width: 100vh;
    }

    .grid-container .grid-editable > tbody > tr > td > * {
        width: 100%;
        /* height: 100%; */
        border: none;
        padding: 8px;
    }

    .grid-container .grid-editable > tbody > tr:hover {
        box-shadow: inset 0 0 5px #007bff88;
    }

    /* .grid__icon {
        font-size: 1.2rem;
    } */

    .grid-container .grid-editable > tbody > tr > td.grid__icon > * {
        font-size: 1.2rem;
        padding: 0.25rem 0.5rem;
    }

    .grid-container .grid-editable > tbody > tr > td > input {
        padding: 8px 8px 6px 8px;
    }

    .grid-container .grid-editable > tbody > tr > td.grid__icon > i, .grid-container .grid-editable > tbody > tr > td.grid__icon > span {
        padding: 9px 8px 6px 8px;
    }

    .grid-container .grid__icon__edit {
        color: #056bbf;
    }

    .grid-container .grid-editable > tbody > tr > td > .input-group > span {
        padding: 9px 8px 6px 8px;
    }

    .grid-container .mx-datepicker .mx-input-wrapper input {
        border: none;
    }

    .grid-container .grid-editable > tbody > tr > td > .mx-datepicker {
        padding: 0;
    }

    .grid-editable > tbody > tr > td > .mx-datepicker .mx-input-wrapper input {
        padding: 8px 8px 6px 8px;
    }

    .grid-editable > tbody > tr > td > .mx-datepicker .mx-input-wrapper .mx-icon-calendar {
        right: 8px;
    }

    .grid-container .grid-data-container {
        /* min-height: 32px; */
        font-size: 0.875rem;
    }

    .grid-container .grid-data-container .form-control {
        border: none;
        padding: 8px;
        line-height: 1.25em;
        font-size: 0.875rem;
    }

    .grid-container .grid-data-container .input-group-append .btn-sm {
        height: 31px;
    }

    .grid-container .grid-data-container .btn-outline-secondary {
        border-color: #dee2e6;
        border-top: none;
        border-bottom: none;
    }

    .grid-container .grid-data-container option {
        height: 32px;
        /* font-size: 1rem; */
    }

    .grid-container .grid-data-container.subtotals, .grid-container .grid-data-container.totals {
        font-weight: bold;
    }

    .grid-container .grid-editable .checkbox {
        width: 1rem;
        height: 1rem;
    }

    .grid-container .grid-editable .checkbox.grid-data-container {
        margin-top: 9px;
    }

    .grid-container .link {
        cursor: pointer;
    }

    .grid-container tr.modified {
        background-color: #ecf3ff;
    }

    .grid-container tr.deleted {
        background-color: #ffecec;
        text-decoration: line-through;
    }

    .grid-container .grid-editable > tbody > tr > td > .no-data-found {
        padding: .75rem;
    }

    /* required field marks */
    .grid-container .column-required {
        position: relative;
    }

    .grid-container .required-mark-corner::before, .required-mark-corner::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        border-color: transparent;
        border-style: solid;
    }

    .grid-container .required-mark-corner::after {
        /* border-width: 4px; */
        border-right-color: red;
        border-top-color: red;
    }

    /* sort arrows */
    .grid-container .sort {
        opacity: 0.4;
        margin-left: 5px;
    }

    .grid-container th.active .sort {
        opacity: 1;
    }

    .grid-container .arrow {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 0;
        margin-left: 5px;
        opacity: 0.4;
    }

    .grid-container th.active .arrow {
        opacity: 1;
    }

    /* th.active {
        color: #fff;
    } */

    .grid-container .arrow.asc {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #2c3e50;
    }

    .grid-container .arrow.desc {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #2c3e50;
    }

    .grid-container .grid__color_button {
        height: auto;
    }

    .grid-container .grid-cell input {
        background-color: inherit;
    }
  
</style>
