<!--
    Author: Eduard Grebenyukov
    Date: 2020-09-06
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose @click="closePressed()" />
        </template>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col col-xl-9">
                        <div class="form-row">
                            <div class="col-sm">
                                <Grid ref="serviceGrid" :config="gridConfig" @refreshed="$refs.accountInfo.refresh()" />
                            </div>
                        </div>
                    </div>
                    <div class="col col-xl-3">
                        <!-- Right sidebar -->
                        <AccountInfo ref="accountInfo" :accountId="itemId" />
                    </div>
                </div>
            </div>
        </form>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import Grid from '@/components/lib/regions/Grid';
    import AccountInfo from '@/components/pages/common/AccountInfo';

    export default {
        name: 'Transactions',

        components: {
            TemplatePageStandard, ButtonClose, Grid, AccountInfo,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                sumToPay: 0,
                buttons: [],
                breadcrumb: [],
                breadcrumbCashdesk: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.cashdesk') },
                    { text: this.$t('MainMenu.cashdesk-payers'), to: '/cashdesk/payers' },
                    { text: '#', to: '#' },
                    { text: this.$t('objects.fin_cash'), to: '#' },
                ],
                breadcrumbFinances: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.finances') },
                    { text: this.$t('MainMenu.finances-accounts'), to:'/finances/accounts' },
                    { text: '#', to: '#' },
                    { text: this.$t('objects.fin_cash'), to: '#' },
                ],

                gridConfig: {
                    apiUrl: null,
                    filter: null,
                    // title: this.$t('component.Account.services'),
                    readonly: true,
                    columns: [
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' },
                        { type:'datetime', key:'time_of', header:this.$t('attribute.common.datetime_of') },
                        { type:'select', key:'tran_type_id', header:this.$t('enum.tran_type'), source:'enum/tran_type' },
                        { type:'select', key:'fin_payment_type_id', header:this.$t('object.fin_payment_type'), source:'fin_payment_type' },
                        { type:'select', key:'core_workplace_id', header:this.$t('attribute.fin_cash.core_workplace_id'), source:'v_workplace', keyLabel:'user_name' },
                        // { type:'select', key:'cash_register_id', header:this.$t('object.cash_register'), source:'core_device' },
                        // { type:'select', key:'pos_terminal_id', header:this.$t('object.pos_terminal'), source:'core_device' },
                        { type:'number', key:'sum_payment', header:this.$t('attribute.common.sum_payment'), align:'right' },
                        { type:'number', key:'sum_discount', header:this.$t('attribute.common.sum_discount'), align:'right' },
                        { type:'number', key:'sum_transaction', header:this.$t('attribute.fin_cash.sum_transaction'), align:'right' },
                        // { type:'string', key:'cheque_num', header:this.$t('attribute.fin_cash.cheque_num'), align:'right' },
                        // { type:'string', key:'bank_card_num', header:this.$t('attribute.fin_cash.bank_card_num'), align:'right' },
                        // { type:'string', key:'commentary', header:this.$t('attribute.common.commentary'), align:'right' },
                    ],
                    totals: {
                        columns: [
                            // { type:'string', key:'id', value:' ', style:'font-weight:bold; font-size:1.1rem;' }, // to hide default totals label !!!
                            { type:'string', key:'time_of', value:this.$t('app.total'), style:'font-weight:bold; font-size:1.1rem;' },
                            { type:'number', key:'sum_payment', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_payment), 0 );
                                    else return 0;
                                } 
                            },
                            { type:'number', key:'sum_discount', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_discount), 0 );
                                    else return 0;
                                }
                            },
                            { type:'number', key:'sum_transaction', style:'font-weight: bold;font-size:1.1rem;',
                                $value: (data) => {
                                    if (data && data.length > 0) return data.reduce( ((sum, item) => sum + item.sum_transaction), 0 );
                                    else return 0;
                                }
                            },
                        ],
                    },
                    order: [{ key: 'time_of', direction: 'desc' }],
                    buttons: [ 'edit' ],
                    saveSessionState: `${this.$options.name}`,
                    $onEdit: (ctx, item) => {
                        if (this.$route.fullPath.startsWith('/cashdesk/')) {
                            ctx.$router.push({ name: 'cashdesk/transaction-edit', params: { accountId:this.itemId, cashId:item.id  } });
                        }
                        if (this.$route.fullPath.startsWith('/finances/')) {
                            ctx.$router.push({ name: 'finances/transaction-edit', params: { accountId:this.itemId, cashId:item.id  } });
                        }
                    },
                },
                selectedRows: [],
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() params=`, this.$route.params);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.$route=`, this.$route);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.$route.fullPath=`, this.$route.fullPath);
            this.itemId = parseInt(this.$route.params.accountId);
            let filter = { where:[
                { fin_account_id: this.itemId },
            ]};
            this.gridConfig.filter = filter;
            this.gridConfig.apiUrl = 'fin_cash';
            if (this.$route.fullPath.startsWith('/cashdesk/')) {
                this.breadcrumb = this.breadcrumbCashdesk;
                this.breadcrumb[3].to = `/cashdesk/account/${this.itemId}`;
                this.breadcrumb[3].text = `${this.itemId}`;
            }
            if (this.$route.fullPath.startsWith('/finances/')) {
                this.breadcrumb = this.breadcrumbFinances;
                this.breadcrumb[3].to = `/finances/account/${this.itemId}`;
                this.breadcrumb[3].text = `${this.itemId}`;
            }
        },
    }
</script>

<style>
</style>
