<!--
    Author: Eduard Grebenyukov
    Date: 2020-03-21
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save') && isFormEditable" :disabled="!isSaveEnabled" @click="saveData()" />
        </template>
        <form>
            <div id="page-content" class="form-float">
                <b-alert variant="info" :show="visitStatus.code == 'confirmed'">{{ $t('Visit.visit_confirmed') }}</b-alert>
                <b-alert variant="info" :show="visitStatus.code == 'arrived'">{{ $t('Visit.client_arrived') }}</b-alert>
                <b-alert variant="primary" :show="visitStatus.code == 'started'">{{ $t('Visit.visit_started') }}</b-alert>
                <b-alert variant="success" :show="visitStatus.code == 'completed'">{{ $t('Visit.visit_completed') }}</b-alert>
                <b-alert variant="danger" :show="visitStatus.code == 'canceled'">{{ $t('Visit.visit_canceled') }}</b-alert>
                <b-alert variant="success" :show="visitStatus.code == 'closed'">{{ $t('Visit.visit_closed') }}</b-alert>
                <div class="row">
                    <div :class="editFormData.id || editFormData.core_client_id ? 'col-sm-12 col-xl-8' : 'col'">
                        <!-- <ModalWindow v-if="isDisplayWarningDelete" minWidth="300px" :buttons="['yes', 'no']" @yes="processCancelVisit(true)" @no="processCancelVisit(false)" >
                            {{ $t('Visit.confirmCancel') }}
                        </ModalWindow> -->

                        <div class="form-row">
                            <div class="col-sm">
                                <FieldSelect
                                    ref="core_workplace_id"
                                    id="core_workplace_id"
                                    :label="$t('object.core_workplace')"
                                    :value.sync="editFormData.core_workplace_id"
                                    source="schedule-workplaces"
                                    keyLabel="workplace_name"
                                    labelPosition="above"
                                    :disabled="true"
                                />
                            </div>
                        </div> 
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldPopupList
                                    id="core_client_id"
                                    :label="$t('object.core_client')"
                                    :value.sync="editFormData.core_client_id"
                                    source="v_client"
                                    keyLabel="fullname"
                                    showEmptyRow
                                    :listConfig="clientListConfig"
                                    :paginationPageSize=15
                                    :errorMessage="formErrorMessages.core_client_id"
                                    :disabled="!isFormEditable"
                                    maxWidth="1000px"
                                    @modified="itemModified"
                                >
                                    <button slot="buttons"
                                        v-if="editFormData.core_client_id"
                                        class="btn btn-outline-secondary"
                                        :title="$t('Visit.open_visit')"
                                        @click.prevent="openVisit()"
                                    >
                                        <i class="fas fa-id-card"></i>
                                    </button>
                                </FieldPopupList>
                            </div>
                        </div>
                        <div v-if="!editFormData.core_client_id" class="form-row">
                            <div class="col-sm">
                                <FieldString
                                    id='lastname'
                                    :label="$t('attribute.core_person.lastname')"
                                    :value.sync="editFormData.lastname"
                                    required
                                    labelPosition="above"
                                    :errorMessage="formErrorMessages.lastname"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    id='firstname'
                                    :label="$t('attribute.core_person.firstname')"
                                    :value.sync="editFormData.firstname"
                                    required
                                    labelPosition="above"
                                    :errorMessage="formErrorMessages.firstname"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    id='patronymic'
                                    :label="$t('attribute.core_person.patronymic')"
                                    :value.sync="editFormData.patronymic"
                                    labelPosition="above"
                                    :errorMessage="formErrorMessages.patronymic"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    id='phone_num'
                                    :label="$t('attribute.core_phone.phone_num')"
                                    :value.sync="editFormData.phone_num"
                                    labelPosition="above"
                                    :errorMessage="formErrorMessages.phone_num"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldSelect
                                    id='visit_type_id'
                                    :label="$t('object.visit_type')"
                                    :value.sync="editFormData.visit_type_id"
                                    :source="editFormData.core_workplace_id ? `workplace-visit_type/${editFormData.core_workplace_id}` : []"
                                    showEmptyRow
                                    required
                                    labelPosition="above"
                                    :errorMessage="formErrorMessages.visit_type_id"
                                    :disabled="!isFormEditable"
                                    @modified="itemVisitTypeModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <!-- <FieldDate id='date_of' :label="$t('app.date')" :value.sync="editFormData.date_of" @modified="itemModified" required labelPosition="above" />
                            </div>
                            <div class="col-sm"> -->
                                <FieldDatetime
                                    id='time_of'
                                    :label="$t('app.time')"
                                    :value.sync="editFormData.time_of"
                                    :minuteStep="5"
                                    required
                                    labelPosition="above"
                                    :errorMessage="formErrorMessages.time_of"
                                    :disabled="!isFormEditable"
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldNumber
                                    id='duration'
                                    :label="$t('attribute.common.duration')"
                                    :value.sync="editFormData.duration"
                                    required
                                    labelPosition="above"
                                    :errorMessage="formErrorMessages.duration"
                                    :disabled="!isFormEditable"
                                    @modified="itemModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.core_person.commentary')"
                                    :value.sync="editFormData.commentary"
                                    :disabled="!isFormEditable"
                                    @modified="itemModified"
                                    />
                            </div>
                        </div>
                        <!-- <div class="form-row" v-if="false">
                            <div class="col-sm">
                                <FieldPopupList
                                    id="crm_request_id"
                                    :label="$t('object.crm_request')"
                                    :value.sync="crm_request_id"
                                    source="v_crm_request"
                                    keyLabel="label"
                                    showEmptyRow
                                    :listConfig="crmRequestListConfig"
                                    :paginationPageSize=10
                                    :errorMessage="formErrorMessages.crm_request_id"
                                    :disabled="!isFormEditable"
                                    @modified="itemModified"
                                > -->
                                    <!-- <button slot="buttons"
                                        v-if="editFormData.core_client_id"
                                        class="btn btn-outline-secondary"
                                        :title="$t('Visit.open_visit')"
                                        @click.prevent="openVisit()"
                                    >
                                        <i class="fas fa-id-card"></i>
                                    </button> -->
                                <!-- </FieldPopupList>
                            </div>
                        </div> -->
                        <div class="row" v-if="editFormData.core_client_id">
                            <div class="col">
                                <ClientBeneficiary :clientId="editFormData.core_client_id" />
                            </div>
                        </div>
                    </div>
                    <div v-if="editFormData.id || editFormData.core_client_id" class="col-sm-12 col-xl-4">
                        <!-- Right sidebar -->
                        <div class="row mt-2" v-if="editFormData.core_client_id">
                            <div class="col">
                                <ClientInfo :clientId="editFormData.core_client_id" />
                            </div>
                        </div>
                        <div class="row mt-2" v-if="editFormData.id">
                            <div class="col">
                                <div class="btn-toolbar" style="padding: 0 4px 4px 0;" role="toolbar">
                                    <div class="h6">{{ $t('component.ScheduleEdit.linkedCrmRequests') }}</div>
                                    <ButtonCustom
                                        v-if="!crmRequestArray || crmRequestArray.length == 0"
                                        :title="$t('component.ScheduleEdit.createCrmRequest')"
                                        buttonClass="btn-warning btn-sm ml-1"
                                        iconClass="fas fa-plus fa-fw"
                                        @click="createCrmRequestPressed()"
                                    />
                                    <ButtonCustom
                                        v-if="!crmRequestArray || crmRequestArray.length == 0"
                                        :title="$t('component.ScheduleEdit.addCrmRequestLink')"
                                        buttonClass="btn-warning btn-sm ml-1"
                                        iconClass="fas fa-link fa-fw"
                                        @click="linkToCrmRequestPressed()"
                                    />
                                    <ButtonCustom
                                         v-if="crmRequestArray && crmRequestArray.length > 0"
                                        :title="$t('component.ScheduleEdit.deleteCrmRequestLink')"
                                        buttonClass="btn-outline-danger btn-sm ml-1"
                                        iconClass="fas fa-unlink fa-fw"
                                        @click="deleteCrmRequestLinkPressed()"
                                    />
                                </div>
                                <Grid ref="crmRequest" :source="crmRequestArray" :config="crmRequestGridConfig"  @select-request="requestSelected($event)" />
                            </div>
                        </div>
                        <div class="row mt-2" v-if="editFormData.id">
                            <div class="col">
                                <Grid ref="status" :config="statusGridConfig" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <ModalWindow
            v-if="isDisplayCrmRequestList"
            width="900px" max-width="90%" max-height="90vh" 
            :header="$t('component.ScheduleEdit.addCrmRequestLink')"
            @cancel="isDisplayCrmRequestList = false"
        >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="requests" :source="crmRequestToLinkArray" :config="crmRequestToLinkGridConfig" @item-selected="crmRequestToLinkSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>

        <WarningDelete
            v-if="isDisplayWarningDeleteCrmRequestLink"
            :text="$t('component.CRMRequestEdit.confirmDeleteCrmCallLink')"
            @process="deleteCrmRequestLink($event)"
        />

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import WarningDelete from '@/components/lib/regions/WarningDelete';
    import ClientInfo from '@/components/pages/clients/ClientInfo';
    import ClientBeneficiary from '@/components/pages/clients/ClientBeneficiary';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldPopupList from '@/components/lib/controls/FieldPopupList';
    import FieldDatetime from '@/components/lib/controls/FieldDatetime';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldText from '@/components/lib/controls/FieldText';

    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ScheduleEdit',

        components: {
            TemplatePageStandard, ModalWindow, WarningDelete, ClientInfo, ClientBeneficiary,
            ButtonClose, ButtonSave, ButtonCustom,
            FieldSelect, FieldPopupList, FieldDatetime, FieldNumber, FieldString, FieldText, Grid,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Props ===============
        props: {
            id: [String, Number],
            coreWorkplaceId: Number,
            timeOf: Date,
            clipboard: Object,
        },
        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                editFormData: {
                    // id: null,
                    base_visit_id: null,
                    core_workplace_id: null,
                    core_client_id: null,
                    lastname: null,
                    firstname: null,
                    patronymic: null,
                    phone_num: null,
                    visit_type_id: null,
                    // date_of: null,
                    time_of: null,
                    duration: null,
                    commentary: null,
                },
                oldFormData: {},
                visitStatus: {},
                workplace: {},
                crmRequestId: null,
                // isDisplayWarningDelete: false,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.schedule'), to: '/schedules' },
                    { text: '#' },
                ],
                clientListConfig: {
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'string', key:'code', header:this.$t('attribute.common.code') },
                        { type:'string', key:'fullname', header:this.$t('object.core_client') },
                        { type:'select', key:'sex', header:this.$t('attribute.core_person.sex'), source: this.sexKind, keyId: 'code', keyLabel: 'name_short' },
                        { type:'date', key:'dob', header:this.$t('attribute.core_person.dob') },
                        { type:'string', key:'phones', header:this.$t('Contacts.phones') },
                        { type:'string', hidden:true, key:'phones_stripped', header:this.$t('Contacts.phones') },
                    ],
                    order: [ 'fullname' ],
                    displayToolbar: true,
                },
                // crmRequestListConfig: {
                //     readonly: true,
                //     columns: [
                //         // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                //         { type:'datetime', key:'time_of', header:this.$t('attribute.common.datetime_of') },
                //         { type:'string', key:'user_name', header:this.$t('object.core_user') },
                //         { type:'string', key:'client_name', header:this.$t('object.core_client') },
                //         { type:'string', key:'subscriber_identifier', header:this.$t('attribute.core_phone.phone_num') },
                //         {
                //             type:'string', key:'label',
                //             $value:entry => `${moment(entry.time_of).format('L LT')} / ${entry.client_name} / ${entry.subscriber_identifier}`
                //         },
                //      ],
                //     order: [{ key:'time_of', direction:'desc' }],
                //     displayToolbar: true,
                // },
                statusGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title: this.$t('app.history'),
                    readonly: true,
                    columns: [
                        // { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        // { type:'hidden', key:'core_person_id', header:"Person ID", required:true },
                        // {
                        //     type:'icon', key:'icon', sortable:false,
                        //     $title:(entry) => { return entry.status_name }, align:'center',
                        //     $icon: entry => tools.getVisitIcon(entry),
                        //     $cellStyle: entry => `color:${tools.getVisitColor(entry)};`,
                        // },
                        { type:'select', key:'status_id', header:this.$t('attribute.common.status'), source:'enum/visit_status', keyLabel:'name_short' },
                        { type:'datetime', key:'time_from', header:this.$t('app.time') },
                        { type:'select', key:'core_user_id', header:this.$t('object.core_user'), source:'v_user', keyLabel:'shortname' },
                    ],
                    order: [ 'time_from' ],
                    displayToolbar: false,
                    displayPagination: false,
                },
                crmRequestArray: null,
                crmRequestGridConfig: {
                    apiUrl: null,
                    // title: this.$t('component.ScheduleEdit.linkedCrmRequests'),
                    readonly: true,
                    columns: [
                        { type:'number', key:'crm_request_id', header:this.$t('attribute.common.id'), readonly:true, align:'right' },
                        { type:'datetime', key:'request_time_of', header:this.$t('attribute.common.datetime_of'), emit:'select-request', style:"color: #056bbf;" },
                        { type:'string', key:'subscriber_identifier', header:this.$t('attribute.common.phone') },
                        // { type:'string', key:'dept_name', header:this.$t('object.core_dept') },
                        // { type:'string', key:'room_name', header:this.$t('object.core_room') },
                        // { type:'string', key:'user_name', header:this.$t('app.employee') },
                    ],
                    order: [ 'request_time_of' ],
                    displayToolbar: false,
                    displayPagination: false,
                },
                isDisplayWarningDeleteCrmRequestLink: false,
                isDisplayCrmRequestList: false,
                crmRequestToLinkArray: null,
                crmRequestToLinkGridConfig: {
                    readonly: true,
                    columns: [
                        { type:'number', key:'crm_request_id', header:this.$t('attribute.common.id'), readonly:true, align:'right' },
                        { type:'datetime', key:'request_time_of', header:this.$t('attribute.common.datetime_of') },
                        { type:'string', key:'subscriber_identifier', header:this.$t('attribute.common.phone') },
                    ],
                    displayToolbar: false,
                    row: { emit:'item-selected', style:'cursor: pointer;' },
                },
                formErrorMessages: {},
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                evenOdd: 'evenOdd',
                authenticatedAxios: 'authenticatedAxios',
                sexKind: 'sexKind',
                clientToSchedule: 'clientToSchedule',
            }),
            apiUrl() { return `${this.baseUrl}/visit_rec` },
            isSaveEnabled() { return Object.keys(this.formErrorMessages).length === 0; },
            isFormEditable() {
                return tools.isScheduleEditable(this.visitStatus.code) || typeof this.editFormData.id === 'undefined';
            },
        },

        // =============== Methods ===============
        methods: {
            ...mapActions([
                'setClientToSchedule',
                'setLastClientId',
                'setLastVisitId',
            ]),

            getTimeArray(fromHour, toHour, stepMinutes) {
                let a = [];
                for (let i = (fromHour || 0); i <= (toHour || 24); i++) {
                    const t = i.toString().padStart(2, '0') + ':00:00';
                    a.push({id: t, name: t});
                }
                // Another way: Array.from({ length: 10 }).map((_, i) => i + xxx)
                // console.log(`DEBUG: ${this.$options.name}.getTimeArray(${fromHour}, ${toHour}, ${stepMinutes}) a=`, a);
                return a;
            },

            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close', 'arrived'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            validateForm() {
                // console.log(`DEBUG: ${this.$options.name}.validateForm() this.editFormData=`, this.editFormData);
                /*
                    base_visit_id: null,
                    core_workplace_id: null,
                    core_client_id: null,
                    lastname: null,
                    firstname: null,
                    patronymic: null,
                    phone_num: null,
                    visit_type_id: null,
                    time_of: null,
                    duration: null,
                */
                this.formErrorMessages = {};
                if (
                    this.editFormData.core_client_id === null &&
                    (tools.isStringEmpty(this.editFormData.lastname) || tools.isStringEmpty(this.editFormData.firstname))
                ) {
                    this.formErrorMessages.core_client_id = 'Необходимо либо выбрать пациента, либо заполнить поля "Фамилия" и "Имя"';
                    if (tools.isStringEmpty(this.editFormData.lastname))
                        this.formErrorMessages.lastname =  this.$t('lib.validation.fieldIsEmpty');
                    if (tools.isStringEmpty(this.editFormData.firstname))
                        this.formErrorMessages.firstname = this.$t('lib.validation.fieldIsEmpty');
                }
                if (this.editFormData.visit_type_id === null) this.formErrorMessages.visit_type_id = this.$t('lib.validation.fieldIsEmpty');
                if (this.editFormData.time_of === null) this.formErrorMessages.time_of = this.$t('lib.validation.fieldIsEmpty');
                if (tools.isNumberEmpty(this.editFormData.duration)) this.formErrorMessages.duration = this.$t('lib.validation.fieldIsEmpty');
                if (!(this.editFormData.duration > 0)) this.formErrorMessages.duration = this.$t('lib.validation.fieldMustBePositive');
                // console.log(`DEBUG: ${this.$options.name}.validateForm() this.formErrorMessages=`, this.formErrorMessages);
            },

            gotData(data) {
                // console.log(`DEBUG: ${this.$options.name}.gotData() this.$refs.core_workplace_id=`, this.$refs.core_workplace_id);
                // console.log(`DEBUG: ${this.$options.name}.gotData() this.$refs.core_workplace_id.itemPresentation=`, this.$refs.core_workplace_id.itemPresentation);
                this.editFormData = data;
                this.isModified = false;
                this.msgItemModified = '';
                this.itemId = data.id;

                // this.editFormData.time_of = new Date(data.time_of);

                this.statusGridConfig.apiUrl = 'visit_status';
                this.statusGridConfig.filter = { where: [
                    { visit_rec_id: this.itemId },
                ]};

                // this.crmRequestGridConfig.apiUrl = 'get-request-visits';
                // this.crmRequestGridConfig.filter = { where:[
                //     { visit_rec_id: this.itemId},
                // ]};

                this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));

                this.setBreadcrumb();
                this.checkButtons();
                this.validateForm();
            },

            setBreadcrumb() {
                // console.log(`DEBUG: ${this.$options.name}.setBreadcrumb() core_workplace_id=`, this.$refs.core_workplace_id);
                // this.breadcrumb[this.breadcrumb.length - 1].text =
                //     `${this.$refs.core_workplace_id.itemPresentation} ` +
                //     `(${moment(this.editFormData.time_of).format('L')} ${moment(this.editFormData.time_of).format('LT')})`;
                this.breadcrumb[this.breadcrumb.length - 1].text =
                    `${this.workplace.workplace_name} ` +
                    `(${moment(this.editFormData.time_of).format('L')} ${moment(this.editFormData.time_of).format('LT')})`;
            },

            async getVisitStatus() {
                // this.visitStatus = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_rec/${this.itemId}/status`)).data;
                this.visitStatus = await tools.getVisitStatus(this, this.itemId);
                this.$refs.status.refresh();
                // console.log(`DEBUG: ${this.$options.name}.getVisitStatus() visitStatus=`, this.visitStatus);
            },

            async getWorkplace(workplaceId) {
                if (workplaceId) {
                    this.workplace = (await this.authenticatedAxios.get(`${this.baseUrl}/v_workplace/${workplaceId}`)).data;
                    this.setBreadcrumb();
                } else {
                    this.workplace = {};
                }
            },

            async getCrmRequestArray(visitRecId) {
                const filterString = tools.getFilterString({ where:[{ visit_rec_id:visitRecId }]});
                this.crmRequestArray = (await this.authenticatedAxios.get(`${this.baseUrl}/get-request-visits${filterString}`)).data.rows;
            },

            async getData() {
                try {
                    const response = await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`);
                    // console.log(`DEBUG: ${this.$options.name}.getData() data=`, response.data);
                    await this.getWorkplace(response.data.core_workplace_id);
                    await this.getCrmRequestArray(response.data.id);
                    this.gotData(response.data);
                    this.getVisitStatus();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async openVisit() {
                await this.setLastClientId(this.editFormData.core_client_id);
                await this.setLastVisitId(this.editFormData.id);
                this.$router.push({ name: 'client-visits', params: { clientId: this.editFormData.core_client_id } });
            },

            createCrmRequestPressed() {
                const visitToLink = {
                    visitRecId: this.itemId,
                    coreClientId: this.editFormData.core_client_id,
                    lastname: this.editFormData.lastname,
                    firstname: this.editFormData.firstname,
                    patronymic: this.editFormData.patronymic,
                    phoneNum: this.editFormData.phone_num,
                };
                this.$router.push({ name: 'crm/request-new', params: { visitToLink } });
            },

            async linkToCrmRequestPressed() {
                try {
                    const filterString = tools.getFilterString({ where:[{ visit_rec_id:this.itemId }]});
                    this.crmRequestToLinkArray = (await this.authenticatedAxios.get(`${this.baseUrl}/get-visit-requests-to-link${filterString}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.linkToCrmRequestPressed() this.crmRequestToLinkArray=`, this.crmRequestToLinkArray);
                    this.isDisplayCrmRequestList = true;
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async crmRequestToLinkSelected(crmRequest) {
                this.crmRequestId = crmRequest.crm_request_id;
                await this.linkToCrmRequest(this.itemId);
                this.isDisplayCrmRequestList = false;
            },

            async linkToCrmRequest(visitRecId) {
                // console.log(`DEBUG: ${this.$options.name}.linkToCrmRequest(${this.crmRequestId}, ${visitRecId})`);
                if (this.crmRequestId) {
                    try {
                        const rowData = { crm_request_id:this.crmRequestId, visit_rec_id:visitRecId };
                        await this.authenticatedAxios.post(`${this.baseUrl}/crm_request_visit`, rowData);
                        // console.log(`DEBUG: ${this.$options.name}.linkToCrmRequest() response.data=`, response.data);
                        await this.getCrmRequestArray(visitRecId);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
            },

            deleteCrmRequestLinkPressed() {
                this.isDisplayWarningDeleteCrmRequestLink = true;
            },

            async deleteCrmRequestLink(process) {
                this.isDisplayWarningDeleteCrmRequestLink = false;
                if (process) {
                    await this.authenticatedAxios.delete(`${this.baseUrl}/delete-visit-request/${this.itemId}`);
                    await this.getCrmRequestArray(this.itemId);
                }
            },

            async saveData() {
                if (this.isModified) {
                    //this.$v.$touch();
                    //if (!this.$v.$invalid) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            // console.log(`DEBUG: ${this.$options.name}.saveData() Update this.editFormData=`, this.editFormData);
                            const response = await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData);
                            this.gotData(response.data);
                            tools.displaySuccessSave(this);
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveData() New this.editFormData=`, this.editFormData);
                            const response = await this.authenticatedAxios.post(this.apiUrl, this.editFormData);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response.data=`, response.data);
                            await this.linkToCrmRequest(response.data.id);
                            this.gotData(response.data);
                            this.$router.replace({ name: 'schedule-edit', params: { id:this.itemId } });
                            this.getVisitStatus();
                            tools.displaySuccessSave(this);
                        }
                        // if client was passed via Vuex
                        // clear passed data to avoid fill client data in every new schedule
                        await this.setClientToSchedule(null);

                        // if visit was passed via clipboard cut/paste
                        // mark cutted visit as canceled
                        if (
                            this.clipboard &&
                            this.clipboard.objectName === 'schedule' &&
                            typeof this.clipboard.objectData === 'object' &&
                            this.clipboard.method === 'cut'
                        ) {
                            await tools.setVisitStatus(this, this.clipboard.objectData.id, 'canceled');
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified()`);
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
                this.setBreadcrumb();
                this.validateForm();
            },

            async itemVisitTypeModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemVisitTypeModified() visit_type_id=`, this.editFormData.visit_type_id);
                if (this.editFormData.visit_type_id) {
                    try {
                        // const filterString = tools.getFilterString({ where:[{ id:this.editFormData.visit_type_id }]});
                        const response = await this.authenticatedAxios.get(`${this.baseUrl}/visit_type/${this.editFormData.visit_type_id}`);
                        // console.log(`DEBUG: ${this.$options.name}.itemVisitTypeModified data=`, response.data);
                        this.editFormData.duration = response.data.duration;
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                this.itemModified();
            },

            requestSelected(request) {
                this.$router.push({ name: 'crm/request-edit', params: { id: request.crm_request_id } });
            },

            // askCancelVisit() {
            //     this.isDisplayWarningDelete = true;
            // },
            // async processCancelVisit (doDelete) {
            //     if (doDelete) {
            //         try {
            //             await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
            //             this.isModified = false;
            //             // tools.displaySuccessDelete(this.$parent);
            //             // this.$router.go(-1);
            //             // this.getData();
            //             this.getVisitStatus();
            //             tools.displaySuccessSave(this);
            //         } catch (err) {
            //             tools.displayApiError(this, err);
            //         }
            //     }
            //     this.isDisplayWarningDelete = false;
            // },
        },
        
        // =============== Life cycle ===============
        beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), id=`, this.id);
            // console.log(`DEBUG: this.id=`, this.id);
            // console.log(`DEBUG: this.coreWorkplaceId=`, this.coreWorkplaceId);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.clientToSchedule=`, this.clientToSchedule);

            this.itemId = parseInt(this.id);
            this.editFormData.core_workplace_id = parseInt(this.coreWorkplaceId);
            this.getWorkplace(this.editFormData.core_workplace_id); // not await
            if (this.timeOf) this.editFormData.time_of = this.timeOf.toISOString();
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.timeOf=`, this.timeOf);
            if (this.itemId) {
                await this.getData();
            } else {
                this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                this.setBreadcrumb();
                this.validateForm();
            }
            this.checkButtons();

            if (!this.itemId) {
                // if client passed via Vuex
                if (this.clientToSchedule) {
                    if (this.clientToSchedule.id) {
                        this.editFormData.core_client_id = this.clientToSchedule.id;
                    } else {
                        this.editFormData.lastname = this.clientToSchedule.lastname;
                        this.editFormData.firstname = this.clientToSchedule.firstname;
                        this.editFormData.patronymic = this.clientToSchedule.patronymic;
                        this.editFormData.phone_num = this.clientToSchedule.phoneNum;
                    }
                    this.crmRequestId = this.clientToSchedule.crmRequestId;
                }

                // if visit pasted via clipboard
                if (this.clipboard && this.clipboard.objectName === 'schedule' && typeof this.clipboard.objectData === 'object') {
                    // console.log(`DEBUG: ${this.$options.name}.beforeMount(PASTE_VISIT), this.clipboard=`, this.clipboard);
                    // console.log(`DEBUG: ${this.$options.name}.beforeMount(PASTE_VISIT), visit=`, this.clipboard.objectData);
                    this.editFormData.base_visit_id = this.clipboard.objectData.base_visit_id || this.clipboard.objectData.id;
                    this.editFormData.core_client_id = this.clipboard.objectData.core_client_id;
                    this.editFormData.lastname = this.clipboard.objectData.lastname;
                    this.editFormData.firstname = this.clipboard.objectData.firstname;
                    this.editFormData.patronymic = this.clipboard.objectData.patronymic;
                    this.editFormData.phone_num = this.clipboard.objectData.phone_num;
                    this.editFormData.duration = this.clipboard.objectData.duration;
                    this.editFormData.commentary = this.clipboard.objectData.commentary;
                    // Now check if pasted visit_type_id is accessible to new workplace
                    const visitTypeArray = (await this.authenticatedAxios.get(`${this.baseUrl}/workplace-visit_type/${this.editFormData.core_workplace_id}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.beforeMount(PASTE_VISIT), visitTypeArray=`, visitTypeArray);
                    if (visitTypeArray.find( item => item.id == this.clipboard.objectData.visit_type_id)) {
                        this.editFormData.visit_type_id = this.clipboard.objectData.visit_type_id;
                    }
                }
                this.itemModified();
                this.validateForm();
            }
        },
    }
</script>

<style scoped>
</style>
