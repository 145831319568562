<!--
    Author: Eduard Grebenyukov
    Date: 2018-12-30
-->

<!--
    syntax: html, js, css, sql, text
-->

<template>
    <div class="form-group" :class="{ 'field-required': required }">
        <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label :for="id" v-if="label && (labelPosition === 'before')" :title="required ? $t('lib.required') : ''" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <prism-editor
            :id="id"
            class="field-code-editor"
            :value="value"
            :highlight="highlight"
            line-numbers
            :disabled="readonly || disabled"
            @keyup="itemModified($event)"
            @change="itemChanged($event)"
            @focus="itemFocused($event)"
            @blur="itemBlur($event)"
        />
 
        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    // import CodeEditor from 'simple-code-editor';
    import { PrismEditor } from 'vue-prism-editor';
    import 'vue-prism-editor/dist/prismeditor.min.css';
    import { highlight, languages } from 'prismjs/components/prism-core';
    import 'prismjs/components/prism-clike.min';
    import 'prismjs/components/prism-javascript.min';
    import 'prismjs/components/prism-json.min';
    import 'prismjs/components/prism-markup.min';
    import 'prismjs/components/prism-sql.min';
    import 'prismjs/components/prism-css.min';
    // import 'prismjs/themes/prism-dark.css';
    import 'prismjs/themes/prism-funky.css';

    export default {
        name: 'FieldCodeEditor',

        components: { PrismEditor },

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: String,
            placeholder: String,
            disabled: Boolean,
            readonly: Boolean,
            required: Boolean,
            rows: { type: Number, default: () => { return 3 } },
            maxHeight: Number,
            maxlength: Number,
            adjustHeight: { type: Boolean, default: () => { return true } },
            inputStyle: String,
            errorMessage: String,
            syntax: String,
        },
        
        // =============== Data ===============
        data() {
            return {
                // itemData: null,
                // initialHeight: null,
            }
        },

        // =============== Watch ===============
        watch: {
            // value() {
            //     if (this.itemData !== this.value) this.itemData = this.value;
            //     // console.log(`DEBUG: ${this.$options.name}(${this.label}).watch.value this.value=`, this.value);
            // },
        },

        // =============== Methods ===============
        methods: {
            highlight(text) {
                // console.log(`DEBUG: ${this.$options.name}.highlight() this.syntax=`, this.syntax);
                // console.log(`DEBUG: ${this.$options.name}.highlight() languages=`, languages);
                if (this.syntax && languages[this.syntax]) {
                    return highlight(text, languages[this.syntax]);
                }
                return highlight(text, languages.text);
            },

            itemModified(evt) {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() evt=`, evt);
                // console.log(`DEBUG: ${this.$options.name}.itemModified() evt.target.value=`, evt.target.value);
                // this.$emit('update:value', !e.target.value || e.target.value.trim() == '' ? null : e.target.value.trim()); !!! erroneous removes spaces while typing
                this.$emit('update:value', !evt.target.value || evt.target.value.trim() == '' ? null : evt.target.value);
                this.$emit('modified');
            },

            itemChanged(evt) {
                // this.$emit('update:value', !evt.target.value || evt.target.value.trim() == '' ? null : evt.target.value.trim());
                this.$emit('changed');
            },

            itemFocused(evt) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() evt=`, evt);
                this.$emit('focus');
            },

            itemBlur(evt) {
                // console.log(`DEBUG: ${this.$options.name}.itemBlur() evt=`, evt);
                this.$emit('blur');
            },
        },
    }
</script>

<style>
    .field-code-editor {
        /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
        /* background: #2d2d2d; */
        /* background: #343a40; */
        background: #2a2a2e;
        color: #ccc;

        /* you must provide font-family font-size line-height. Example: */
        /* font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace; */
        font-family: Consolas, Courier, monospace;
        font-size: 1.1rem;
        line-height: 1.5;
        padding: 5px 0;
    }

    .prism-editor__container {
        min-height: 300px;
    }

    /* optional class for removing the outline */
    .prism-editor__textarea:focus {
        outline: none;
    }

    .prism-editor-wrapper {
        border-radius: .25rem;
    }

    .prism-editor__container .prism-editor__editor {
        color:white;
    }
</style>
