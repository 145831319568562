<!--
    Author: Eduard Grebenyukov
    Date: 2021-12-18
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose @click="closePressed()" />
            <!-- <span class="cashdesk-refund__sum-selected">
                {{ $t('component.Refund.selected') }}: {{ sumSelected.toLocaleString() }} {{ accountData.currency_name }}
            </span> -->
        </template>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-sm-12 col-xl-8">
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('component.Refund.fin_payment_type_id')"
                                    :value.sync="editFormData.fin_payment_type_id"
                                    :source="paymentTypeSourceString"
                                    :errorMessage="errorMessagePaymentType"
                                    required
                                    order="name"
                                    @modified="modifiedPaymentType"
                                />
                            </div>
                            <div class="col-sm" :style="(paymentType && paymentType.is_bank_card ? 'display:block;' : 'display:none;')">
                                <FieldString
                                    :label="$t('attribute.fin_cash.bank_card_num')"
                                    :value.sync="editFormData.bank_card_num"
                                    :required="false"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    :label="$t('attribute.fin_cash.cheque_num')"
                                    :value.sync="editFormData.cheque_num"
                                    :required="false"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('component.Refund.sum_to_refund')"
                                    :value.sync="editFormData.sum_to_refund"
                                    :minValue="0"
                                    :maxValue="cashMaxValue"
                                    :errorMessage="errorMessageSumToRefund"
                                    required
                                    input-style="font-size:2rem;height:4rem;"
                                    @modified="sumToRefundModified"
                                />
                            </div>
                            <div class="col-sm">
                                <button :disabled="!isOperationEnabled" @click.prevent="doRefund()" class="btn btn-primary btn-lg btn-refund" >
                                    {{ $t('component.Refund.makeRefund') }}
                                </button>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.common.commentary')"
                                    :value.sync="editFormData.commentary"
                                    :required="false"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <h6>{{ $t('component.Refund.inflows') }}.
                                    {{ $t('component.Refund.selected') }}: {{ sumSelected.toLocaleString() }} {{ accountData.currency_name }}
                                </h6>
                                <Grid
                                    ref="inflowGrid"
                                    :config="inflowGridConfig"
                                    @item-selected="inflowSelected()"
                                    @refreshed="inflowSelected();"
                                >
                                    <template #buttons>
                                        <span style="font-size: 1.5rem;padding: 0 8px;">
                                            {{ $t('component.Refund.selected') }}: {{ sumSelected.toLocaleString() }} {{ accountData.currency_name }}
                                        </span>
                                    </template>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-4">
                        <!-- Right sidebar -->
                        <AccountInfo ref="accountInfo" :accountId="accountId" />
                        <CashdeskInfo />
                    </div>
                </div>
            </div>
        </form>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldText from '@/components/lib/controls/FieldText';

    import Grid from '@/components/lib/regions/Grid';

    import AccountInfo from '@/components/pages/common/AccountInfo';
    import CashdeskInfo from '@/components/pages/cashdesk/CashdeskInfo';

    export default {
        name: 'Refund',

        components: {
            TemplatePageStandard,
            ButtonClose, FieldString, FieldNumber, FieldSelect, FieldText,
            Grid,
            AccountInfo, CashdeskInfo,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                // itemId: undefined,
                accountId: null,
                buttons: [],
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.cashdesk') },
                    // { text: this.$t('MainMenu.finances-accounts'), to: '/finances/accounts' },
                    { text: this.$t('component.Refund.refundTitle'), to: '#' },
                ],
                paymentTypeSourceString: 'fin_payment_type?filter={"where":[{"is_archived":false},{"is_refund":true}]}',
                isOperationInProgress: false,
                editFormData: {
                    fin_payment_type_id: null,
                    cash_register_id: null,
                    pos_terminal_id: null,
                    bank_card_num: null,
                    cheque_num: null,
                    sum_to_refund: null,
                    commentary: null,
                },
                paymentType: null,
                cashMaxValue: undefined,
                inflowGridConfig: {
                    apiUrl: null, // `fin-get-inflow-to-refund/${this.accountId}`,
                    // source: this.splitsToPay,
                    filter: null,
                    // title: this.$t('component.Refund.inflows'),
                    readonly: true,
                    columns: [
                        // { type:'hidden', key:'id', header:'id', align:'right', width:'100px' },
                        { type:'datetime', key:'datetime_inflow', header:this.$t('attribute.common.datetime_of') },
                        { type:'string', key:'tran_type_name', header:this.$t('component.Refund.tran_type_name') },
                        { type:'string', key:'fin_payment_type_name', header:this.$t('component.Refund.fin_payment_type_name') },
                        { type:'number', key:'sum_transaction_in', header:this.$t('component.Refund.sum_transaction_in'), align:'right' },
                        { type:'number', key:'sum_transaction_balance', header:this.$t('component.Refund.sum_transaction_balance'), align:'right' },
                        // { type:'checkbox', key:'fin_payment_type_is_cash', header:this.$t('component.Refund.fin_payment_type_is_cash'), width:'50px', align:'center;' },
                        // { type:'number', key:'sum_to_pay', header:this.$t('component.Account.sum_to_pay'), align:'right', $value:entry => entry.sum_payment - entry.sum_paid },
                    ],
                    order: [ 'datetime_inflow' ],
                    displayToolbar: false,
                    buttons: [ 'row-selector' ],
                    totals: {
                        columns: [
                            { type:'string', key:'datetime_inflow', value:this.$t('app.total'), style:'font-weight:bold; font-size:1.1rem;' },
                            {
                                type:'number', key:'sum_transaction_in', style:'font-weight:bold;',
                                $value: (data) => data && data.length > 0 ? data.reduce( ((sum, item) => sum + item.sum_transaction_in), 0 ) : 0
                            },
                            {
                                type:'number', key:'sum_transaction_balance', style:'font-weight:bold;',
                                $value: (data) => data && data.length > 0 ? data.reduce( ((sum, item) => sum + item.sum_transaction_balance), 0 ) : 0
                            },
                            // {
                            //     type:'number', key:'sum_to_pay', style:'font-weight:bold;',
                            //     $value: (data) => data && data.length > 0 ? data.reduce( ((sum, item) => sum + item.sum_payment - item.sum_paid), 0 ) : 0
                            // },
                        ],
                    },
                },
                accountData: {
                    // id: null,
                    sum_account: null,
                },
                sumSelected: 0,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),

            isOperationEnabled() {
                return (
                    // this.editFormData.sum_to_refund >= this.editFormData.sum_transaction &&
                    this.sumSelected > 0 &&
                    this.editFormData.sum_to_refund > 0 &&
                    this.sumSelected >= this.editFormData.sum_to_refund &&
                    (this.editFormData.fin_payment_type_id || this.editFormData.fin_payment_type_id === 0) &&
                    !this.isOperationInProgress
                );
            },

            errorMessagePaymentType() {
                // if (!this.editFormData.fin_payment_type_id) return this.$t('component.Refund.paymentTypeNotEmpty');
                return null;
            },

            errorMessageSumToRefund() {
                if (this.editFormData.sum_to_refund <= 0) return this.$t('component.Refund.sumToRefundGtZero');
                if (this.sumSelected < this.editFormData.sum_to_refund) return this.$t('component.Refund.sumToRefundLeSelected');
                return null;
            },
        },

        // =============== Methods ===============
        methods: {
            async sumToRefundModified() {
                // console.log(`DEBUG: ${this.$options.name}.sumToRefundModified() this.editFormData.sum_to_refund=`, this.editFormData.sum_to_refund);
                // console.log(`DEBUG: ${this.$options.name}.sumToRefundModified() this.accountData.sum_account=`, this.accountData.sum_account);
                // if (this.editFormData.sum_to_refund > this.sumSelected) {
                //     await this.$nextTick();
                //     this.editFormData.sum_to_refund = this.sumSelected;
                // }
            },

            inflowSelected() {
                this.sumSelected = this.$refs.inflowGrid.getSelectedRows().reduce( ((sum, item) => sum + (item.sum_transaction_balance )), 0 );
                // console.log(`DEBUG: ${this.$options.name}.renderSelected() getSelectedRows=`, this.$refs.inflowGrid.getSelectedRows());
            },

            // calcSumTransaction() {
            //     if (!this.editFormData.sum_discount) this.editFormData.sum_discount = 0;
            //     this.editFormData.sum_transaction = tools.round(this.editFormData.sum_to_pay - this.editFormData.sum_discount, 2);
            // },
            // resetSumCash() {
            //     this.calcSumTransaction();
            //     // if (this.editFormData.sum_cash < 0 || !this.editFormData.sum_cash) this.editFormData.sum_cash = 0;
            //     if (this.editFormData.sum_transaction) this.editFormData.sum_cash = this.editFormData.sum_transaction;
            // },

            // calcTotal() {
            //     // console.log(`DEBUG: ${this.$options.name}.calcTotal() this.editFormData.sum_cash1=`, this.editFormData.sum_cash);
            //     // console.log(`DEBUG: ${this.$options.name}.calcTotal() this.paymentType=`, this.paymentType);
            //     this.calcSumTransaction();

            //     if (this.editFormData.sum_cash < 0 || !this.editFormData.sum_cash) this.editFormData.sum_cash = 0;
            //     if (this.editFormData.sum_transaction && !this.editFormData.sum_cash) this.editFormData.sum_cash = this.editFormData.sum_transaction;

            //     // console.log(`DEBUG: ${this.$options.name}.calcTotal() this.editFormData.sum_cash2=`, this.editFormData.sum_cash);
            //     // console.log(`DEBUG: ${this.$options.name}.calcTotal() this.editFormData.sum_transaction=`, this.editFormData.sum_transaction);
            //     if (
            //         this.paymentType &&
            //         !this.paymentType.is_cash &&
            //         this.editFormData.sum_cash > this.editFormData.sum_transaction
            //     ) {
            //         this.cashMaxValue = this.editFormData.sum_transaction;
            //         this.editFormData.sum_cash = this.cashMaxValue;
            //         // console.log(`DEBUG: ${this.$options.name}.calcTotal() this.editFormData.sum_cash3=`, this.editFormData.sum_cash);
            //     }
            //     if (
            //         this.paymentType &&
            //         this.paymentType.is_offset &&
            //         // this.editFormData.sum_cash > (this.accountData.sum_account - this.accountData.sum_discount)
            //         this.editFormData.sum_cash > this.accountData.sum_account
            //     ) {
            //         // this.cashMaxValue = (this.accountData.sum_account - this.accountData.sum_discount);
            //         this.cashMaxValue = this.accountData.sum_account;
            //         this.editFormData.sum_cash = this.cashMaxValue;
            //     }
            //     if (this.editFormData.sum_cash) {
            //         this.sumChange = Math.max(tools.round(this.editFormData.sum_cash - this.editFormData.sum_transaction, 2), 0);
            //     } else {
            //         this.sumChange = 0;
            //     }
            //     // console.log(`DEBUG: ${this.$options.name}.calcTotal() ${this.editFormData.sum_cash} - ${this.editFormData.sum_transaction}`);
            // },

            async modifiedPaymentType() {
                try {
                    this.paymentType = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_payment_type/${this.editFormData.fin_payment_type_id}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getDiscount() fin_payment_type=`, fin_payment_type);
                    // if (this.paymentType && this.paymentType.fin_discount_id) {
                    //     let fin_discount = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_discount/${this.paymentType.fin_discount_id}`)).data;
                    //     // console.log(`DEBUG: ${this.$options.name}.getDiscount() fin_discount=`, fin_discount);
                    //     // this.editFormData.sum_discount = - tools.round(this.editFormData.sum_to_pay * fin_discount.pct_value / 100, 2);
                    // } else {
                    //     this.editFormData.sum_discount = 0;
                    // }
                    // this.resetSumCash();
                    // this.calcTotal();
                    // this.gotData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async doRefund() {
                // console.log(`DEBUG: ${this.$options.name}.doRefund() sum_cash=`, this.editFormData.sum_cash);
                this.isOperationInProgress = true;
                if (this.paymentType) {
                    try {
                        const inflowToRefund = this.$refs.inflowGrid.getSelectedRows();
                        let { ...payload } = this.editFormData;
                        payload.fin_account_id = this.accountData.id;
                        payload.core_workplace_id = this.currentUser.currentWorkplace.id;
                        payload.fin_cash_id_array = inflowToRefund.map(item => ( item.fin_cash_id ));

                        await this.authenticatedAxios.post(`${this.baseUrl}/fin-create-refund`, payload);
                        // console.log(`DEBUG: ${this.$options.name}.doRefund() response=`, response);
                        tools.displaySuccessSave(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                this.isOperationInProgress = false;
            },

            async getData() {
                // console.log(`DEBUG: ${this.$options.name}.getData() this.workplace=`, this.workplace);
                try {
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-get-account/${this.accountId}`)).data;
                    if (data) {
                        this.accountData = data;
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            setGridConfig() {
                if (this.accountId) {
                    this.inflowGridConfig.apiUrl = `fin-get-inflow-to-refund/${this.accountId}`;
                    // this.inflowGridConfig.apiUrl = `v_split`;
                    // this.inflowGridConfig.filter =
                    //     this.isShowDebtOnly ?
                    //     { where:[{ fin_account_id:this.itemId }, ['sum_unpaid', '>', 0] ]} :
                    //     { where:[{ fin_account_id:this.itemId }]}
                    // ;
                    // this.$refs.inflowGrid.refresh();
                } else {
                    this.inflowGridConfig.apiUrl = null;
                }
            }
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() params=`, this.$route.params);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(${this.accountId}) splitsToPay=`, this.splitsToPay);
            this.accountId = parseInt(this.$route.params.accountId);
            if (!this.accountId) this.$router.go(-1);
            // if (!this.accountId || this.splitsToPay.length === 0) { this.$router.go(-1); return; }
            // this.editFormData.sum_to_pay = this.splitsToPay.reduce( ((sum, item) => (sum + item.sum_unpaid)), 0 );
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.contractId=${this.contractId}`);
            if (this.accountId) {
                await this.setGridConfig();
                await this.getData();
                // this.calcTotal();
            }
        },
    }
</script>

<style>
    .cashdesk-refund__sum-selected {
        font-size: 1.5rem;
        padding: 0 8px;
    }
    .btn.btn-refund {
        margin-top: 25px;
        height:4rem;
        width: 100%;
    }
</style>
