<!--
    Author: Eduard Grebenyukov
    Date: 2018-12-30
-->

<template>
    <div class="form-group" :class="{ 'field-required': required }">
        <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label :for="id" v-if="label && (labelPosition === 'before')" :title="required ? $t('lib.required') : ''" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>
        <!-- <label v-if="required" :title="required ? $t('lib.required') : ''" >
            {{ label }}<sup class="required-mark">*</sup>
        </label>

        <label v-if="!required">
            {{ label }}
        </label> -->

        <!-- <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label> -->

        <textarea
            ref="textarea"
            :id="id"
            type="text"
            :value="value"
            :required="required"
            :placeholder="placeholder"
            :disabled="readonly || disabled"
            :rows="rows"
            :maxlength="maxlength"
            :class="{
                'form-control': true,
                'is-invalid': !!errorMessage,
                'field__required-empty': required && (!value || value === '')
            }"
            :style="`${inputStyle}`"
            @keyup="itemModified($event)"
            @change="itemChanged($event)"
            @focus="itemFocused($event)"
            @blur="itemBlur($event)"
        />

        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    export default {
        name: 'FieldText',

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: String,
            placeholder: String,
            disabled: Boolean,
            readonly: Boolean,
            required: Boolean,
            rows: { type: Number, default: () => { return 3 } },
            maxHeight: Number,
            maxlength: Number,
            adjustHeight: { type: Boolean, default: () => { return true } },
            inputStyle: String,
            errorMessage: String,
        },
        
        // =============== Data ===============
        data() {
            return {
                initialHeight: null,
            }
        },

        // =============== Methods ===============
        methods: {
            adjust() {
                if (this.adjustHeight) {
                    // console.log(`DEBUG: ${this.$options.name}.adjust() this.$refs.textarea=`, this.$refs.textarea.value);
                    // oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
                    let height = Math.max(this.initialHeight, this.$refs.textarea.scrollHeight);
                    if (this.maxHeight) {
                        height = Math.min(this.maxHeight, height);
                    }
                    this.$refs.textarea.style.height = `${height}px`;
                    // console.log(`DEBUG: ${this.$options.name}.adjust() initialHeight=${this.initialHeight}, scrollHeight=${this.$refs.textarea.scrollHeight}`);
                }
            },

            itemModified(e) {
                // this.$emit('update:value', !e.target.value || e.target.value.trim() == '' ? null : e.target.value.trim()); !!! erroneous removes spaces while typing
                this.$emit('update:value', !e.target.value || e.target.value.trim() == '' ? null : e.target.value);
                this.$emit('modified');
                this.adjust();
            },

            itemChanged(e) {
                // this.$emit('update:value', !e.target.value || e.target.value.trim() == '' ? null : e.target.value.trim());
                this.$emit('changed');
            },

            itemFocused(e) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() e=`, e);
                this.$emit('focus');
            },

            itemBlur(e) {
                // console.log(`DEBUG: ${this.$options.name}.itemBlur() e=`, e);
                this.$emit('blur');
            },
        },
        // =============== Life cycle ===============
        beforeMount() {
        },
        mounted() {
            this.initialHeight = this.$refs.textarea.scrollHeight;
            // console.log(`DEBUG: ${this.$options.name}.mounted() initialHeight=${this.initialHeight}, scrollHeight=${this.$refs.textarea.scrollHeight}`);
            // this.adjust();
        },
        updated() {
            this.adjust();
        },
    }
</script>

<style>
</style>
