<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-11
-->

<template>
    <VueContext ref="contextMenu">
        <template  v-slot:default="child">
            <li v-if="showContextTaskStarted">
                <a :id="TASK_STARTED" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('component.TaskContextMenu.task_started') }}</a>
            </li>
            <li v-if="showContextTaskCompleted">
                <a :id="TASK_COMPLETED" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('component.TaskContextMenu.task_completed') }}</a>
            </li>
            <li v-if="showContextTaskCanceled">
                <a :id="TASK_CANCELED" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('component.TaskContextMenu.task_canceled') }}</a>
            </li>
            <li v-if="showContextTaskStartedCancel">
                <a :id="TASK_STARTED_CANCEL" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('component.TaskContextMenu.task_started_cancel') }}</a>
            </li>
            <li v-if="showContextTaskCompletedCancel">
                <a :id="TASK_COMPLETED_CANCEL" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('component.TaskContextMenu.task_completed_cancel') }}</a>
            </li>
            <li v-if="showContextTaskCanceledCancel">
                <a :id="TASK_CANCELED_CANCEL" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('component.TaskContextMenu.task_canceled_cancel') }}</a>
            </li>
            <hr class="context-divider">
            <li v-if="showContextOpenClient"><a :id="OPEN_CLIENT" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('component.TaskContextMenu.open_client') }}</a></li>
            <li v-if="showContextOpenVisit"><a :id="OPEN_VISIT_REC" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('component.TaskContextMenu.open_visit') }}</a></li>
        </template>
    </VueContext>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { VueContext } from 'vue-context';
    import tools from '@/components/lib/tools';

    export default {
        name: 'TaskContextMenu',
        components: { VueContext },

        // =============== Data ===============
        data() {
            return {
                task: null,
                showContextTaskStarted: false,
                showContextTaskCompleted: false,
                showContextTaskCanceled: false,
                showContextTaskStartedCancel: false,
                showContextTaskCompletedCancel: false,
                showContextTaskCanceledCancel: false,
                showContextOpenClient: false,
                showContextOpenVisit: false,
                // codes
                TASK_STARTED:   'context_task_started',
                TASK_COMPLETED: 'context_task_completed',
                TASK_CANCELED:  'context_task_canceled',

                TASK_STARTED_CANCEL:   'context_task_started_cancel',
                TASK_COMPLETED_CANCEL: 'context_task_completed_cancel',
                TASK_CANCELED_CANCEL:  'context_task_canceled_cancel',

                OPEN_CLIENT:    'context_open_client',
                OPEN_VISIT_REC: 'context_open_visit_rec',
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),
        },

        // =============== Methods ===============
        methods: {
            ...mapActions([ 'setLastClientId', 'setLastVisitId', 'clearLastClient' ]),

            show(evt, task) {
                this.task = task;
                // console.log(`DEBUG: ${this.$options.name}.show() this.task=`, this.task);
                if (task) {
                    this.showContextTaskStarted   = this.task.status_code === 'new';
                    this.showContextTaskCompleted = this.task.status_code === 'started';
                    this.showContextTaskCanceled  = this.task.status_code === 'new' || this.task.status_code === 'started';

                    this.showContextTaskStartedCancel   = this.task.status_code === 'started';
                    this.showContextTaskCompletedCancel = this.task.status_code === 'completed';
                    this.showContextTaskCanceledCancel  = this.task.status_code === 'canceled';

                    if (task.core_client_id) this.showContextOpenClient = true;
                    if (task.visit_rec_id) this.showContextOpenVisit = true;
                    if (
                        this.showContextTaskStarted   || this.showContextTaskStartedCancel ||
                        this.showContextTaskCompleted || this.showContextTaskCompletedCancel ||
                        this.showContextTaskCanceled  || this.showContextTaskCanceledCancel ||
                        this.showContextOpenClient    || this.showContextOpenVisit
                    ) {
                        // console.log(`DEBUG: ${this.$options.name}.show() open!!!`);
                        // this.$refs.contextMenu.close();
                        this.$refs.contextMenu.open(evt, this.task);
                    } else {
                        this.$refs.contextMenu.close();
                    }
                }
            },
            async onContextMenu(evt, task) {
                // console.log(`DEBUG: ${this.$options.name}.onContextMenu() id=`, e.target.id, ', task=', task);
                if (
                    [
                        this.TASK_STARTED,   this.TASK_STARTED_CANCEL,
                        this.TASK_COMPLETED, this.TASK_COMPLETED_CANCEL,
                        this.TASK_CANCELED,  this.TASK_CANCELED_CANCEL,
                    ].includes(evt.target.id)
                ) {
                    try {
                        if (evt.target.id === this.TASK_STARTED) await tools.runTask(this, task.id, this.currentUser);
                        if (evt.target.id === this.TASK_COMPLETED) await tools.setTaskStatus(this, task.id, 'completed');
                        if (evt.target.id === this.TASK_CANCELED) await tools.setTaskStatus(this, task.id, 'canceled');

                        if (evt.target.id === this.TASK_STARTED_CANCEL) await tools.rollbackTaskStatus(this, task.id, 'started');
                        if (evt.target.id === this.TASK_COMPLETED_CANCEL) await tools.rollbackTaskStatus(this, task.id, 'completed');
                        if (evt.target.id === this.TASK_CANCELED_CANCEL) await tools.rollbackTaskStatus(this, task.id, 'canceled');
                        // await this.setLastVisitId(task.visit_rec_id); // need to VisitTabs enable tabs on status changed
                        tools.displaySuccessSave(this);
                        this.$emit('refresh-status');
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                if (evt.target.id === this.OPEN_CLIENT) {
                    await this.setLastClientId(task.core_client_id);
                    this.$router.push({ name: 'client-edit', params: { id: task.core_client_id } });
                }
                if (evt.target.id === this.OPEN_VISIT_REC) {
                    await this.setLastClientId(task.core_client_id);
                    await this.setLastVisitId(task.visit_rec_id);
                    this.$router.push({ name: 'client-visits', params: { clientId:  task.core_client_id } });
                }
            },
        },

        // =============== Life cycle ===============
        // async mounted() {
        // }
    }
</script>

<style lang="scss" src="@/assets/context-menu.scss"></style>
