<!--
    Author: Eduard Grebenyukov
    Date: 2020-03-03
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>
        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col">
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldSelect
                                    id='core_workplace_id'
                                    :label="$t('object.core_workplace')"
                                    labelPosition="above"
                                    :value.sync="editFormData.core_workplace_id"
                                    source="v_workplace"
                                    keyLabel='workplace_name'
                                    readonly
                                />
                            </div>
                        </div> 
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldSelect id='visit_lock_type_id' :label="$t('object.visit_lock_type')" :value.sync="editFormData.visit_lock_type_id" @modified="itemModified" source="visit_lock_type" showEmptyRow labelPosition="above" />
                            </div>
                            <div class="col-sm">
                                <FieldDate id='date_from' :label="$t('attribute.common.date_from')" :value.sync="editFormData.date_from" @modified="itemModified" required labelPosition="above" />
                            </div>
                            <div class="col-sm">
                                <FieldDate id='date_to' :label="$t('attribute.common.date_to')" :value.sync="editFormData.date_to" @modified="itemModified" labelPosition="above" />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldNumber
                                    id='duration'
                                    :label="$t('Settings.interval')"
                                    labelPosition="above"
                                    :value.sync="editFormData.duration"
                                    required
                                    @modified="itemModified"
                                />
                            </div>
                            <div class="col-sm">
                                <!-- <FieldSelect id='time_from' :label="$t('attribute.common.time_from')" :value.sync="editFormData.time_from" @modified="itemModified" :source="getTimeArray(0, 24)" required labelPosition="above" /> -->
                                <!-- :options="{start: '00:00', step:'00:15' , end: '23:30', format: 'HH:mm' }" -->
                                <FieldTime
                                    id='time_from'
                                    :label="$t('attribute.common.time_from')"
                                    :value.sync="editFormData.time_from"
                                    @modified="itemModified"
                                    required
                                    labelPosition="above"
                                    :minuteStep="15"
                                />
                            </div>
                            <div class="col-sm">
                                <!-- <FieldSelect id='time_to' :label="$t('attribute.common.time_to')" :value.sync="editFormData.time_to" @modified="itemModified" :source="getTimeArray(0, 24)" required labelPosition="above" /> -->
                                <!-- :options="{start: editFormData.time_from, step:'00:15' , end: '23:30', format: 'HH:mm' }" -->
                                <!-- :hour-options="getToHourArray(editFormData.time_from ? editFormData.time_from.split(':')[0] : 0, 24)" -->
                                <FieldTime
                                    id='time_to'
                                    :label="$t('attribute.common.time_to')"
                                    :value.sync="editFormData.time_to"
                                    @modified="itemModified"
                                    required
                                    labelPosition="above"
                                    :minuteStep="15"
                                    :hours="getToHourArray(editFormData.time_from ? parseInt(editFormData.time_from.split(':')[0]) : 0, 24)"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <div class="form-check form-check-inline form__weekdays-check">
                                    <FieldCheckbox id='is_monday' :label="$t('app.monday_short')" :value.sync="editFormData.is_monday" @modified="itemModified" labelPosition="above" />
                                    <FieldCheckbox id='is_tuesday' :label="$t('app.tuesday_short')" :value.sync="editFormData.is_tuesday" @modified="itemModified" labelPosition="above" />
                                    <FieldCheckbox id='is_wednesday' :label="$t('app.wednesday_short')" :value.sync="editFormData.is_wednesday" @modified="itemModified" labelPosition="above" />
                                    <FieldCheckbox id='is_thursday' :label="$t('app.thursday_short')" :value.sync="editFormData.is_thursday" @modified="itemModified" labelPosition="above" />
                                    <FieldCheckbox id='is_friday' :label="$t('app.friday_short')" :value.sync="editFormData.is_friday" @modified="itemModified" labelPosition="above" />
                                    <FieldCheckbox id='is_saturday' :label="$t('app.saturday_short')" :value.sync="editFormData.is_saturday" @modified="itemModified" labelPosition="above" />
                                    <FieldCheckbox id='is_sunday' :label="$t('app.sunday_short')" :value.sync="editFormData.is_sunday" @modified="itemModified" labelPosition="above" />
                                </div>
                            </div>
                            <div class="col-sm">
                                <FieldSelect id='day_even' :label="$t('app.even') + ' / ' + $t('app.odd')" :value.sync="editFormData.day_even" @modified="itemModified" :source="evenOdd" showEmptyRow labelPosition="above" />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </form>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    //import { mapState, mapActions } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldTime from '@/components/lib/controls/FieldTime';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldCheckbox from '@/components/lib/controls/FieldCheckbox';

    const componentUrl = 'visit_worktime';

    export default {
        name: 'WorktimeEdit',

        components: {
            TemplatePageStandard,
            ButtonClose, ButtonSave, ButtonDelete,
            // FieldString, FieldEmail, FieldSwitch, FieldText, Grid
            FieldSelect, FieldDate, FieldTime, FieldNumber, FieldCheckbox,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                editFormData: {
                    // id: null,
                    core_workplace_id: null,
                    visit_lock_type_id: null,
                    date_from: null,
                    date_to: null,
                    time_from: null,
                    time_to: null,
                    duration: null,
                    is_monday: false,
                    is_tuesday: false,
                    is_wednesday: false,
                    is_thursday: false,
                    is_friday: false,
                    is_saturday: false,
                    is_sunday: false,
                    day_even: null,
                },
                oldFormData: {},
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-visits-worktime'), to: '/settings/visits/worktimes' },
                    { text: '#' },
                ],
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                evenOdd: 'evenOdd',
            }),
            apiUrl() { return `${this.baseUrl}/${componentUrl}` },
        },

        // =============== Validations ===============
        // validations: {
        //     editFormData: {
        //         username: { required },
        //         email: { required }
        //     }
        // },

        // =============== Methods ===============
        methods: {
            // getTimeArray(fromHour, toHour, stepMinutes) {
            //     let a = [];
            //     for (let i = (fromHour || 0); i <= (toHour || 24); i++) {
            //         let t = i.toString().padStart(2, '0') + ':00:00';
            //         a.push({id: t, name: t});
            //     }
            //     // console.log(`DEBUG: ${this.$options.name}.getTimeArray a=`, a);
            //     return a;
            // },

            getToHourArray(fromHour, toHour) {
                let a = [];
                for (let i = (fromHour || 0); i <= (toHour || 24); i++) {
                    a.push(i);
                }
                // console.log(`DEBUG: ${this.$options.name}.getToHourArray a=`, a);
                return a;
            },

            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            gotData(data) {
                // console.log(`DEBUG: ${this.$options.name}.gotData data=`, data);
                this.editFormData = data;
                this.isModified = false;
                this.msgItemModified = '';
                // this.breadcrumb[this.breadcrumb.length - 1].text = response.data.shortname;
                this.itemId = data.id;
                this.checkButtons();
            },

            async getData() {
                try {
                    const response = await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`);
                    // console.log(`DEBUG: ${this.$options.name}.getData data=`, response.data);
                    this.gotData(response.data);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    //this.$v.$touch();
                    //if (!this.$v.$invalid) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            // console.log(`DEBUG: ${this.$options.name}.saveData() Update this.editFormData=`, this.editFormData);
                            await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData);
                            tools.displaySuccessSave(this);
                            this.isModified = false;
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveData() New this.editFormData=`, this.editFormData);
                            const response = await this.authenticatedAxios.post(this.apiUrl, this.editFormData);
                            tools.displaySuccessSave(this);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response.data=`, response.data);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response.data[0].id=`, response.data[0].id);
                            this.gotData(response.data);
                            // this.getData();
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },
            
            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified`);
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },
        },
        
        // =============== Life cycle ===============
        beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), id=`, this.$route.params.id, 'params.core_workplace_id=', this.$route.params.core_workplace_id);
            this.itemId = parseInt(this.$route.params.id);
            this.editFormData.core_workplace_id = parseInt(this.$route.params.core_workplace_id);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.itemId=`, this.itemId);
            if (this.itemId) {
                await this.getData();
            }
            this.checkButtons();
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
        },
    }
</script>

<style lang="scss">
    .form__weekdays-check {
        label {
            text-align: center;
        }
        input[type=checkbox] {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }
</style>