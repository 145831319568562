<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-15
-->

<template>
    <div>
        <div class="btn-toolbar" style="padding: 0 4px 4px 0;" role="toolbar">
            <div class="h6">{{ $t('component.ClientEdit.accounts') }}</div>
        </div>
        <Grid ref="accounts" :config="accountsGridConfig" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import tools from '@/components/lib/tools';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ClientAccounts',

        components: { Grid },

        // =============== Props ===============
        props: {
            clientId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                accountsGridConfig: {
                    apiUrl: null,
                    filter: null,
                    // title: this.$t('component.ClientEdit.accounts'),
                    readonly: true,
                    columns: [
                        { type:'string', key:'contract_name', header:this.$t('object.fin_contract') },
                        //{ type:'number', key:'sum_account', header:this.$t('attribute.fin_account.sum_account'), align:'right' },
                        { type:'number', key:'sum_payment_final', header:this.$t('attribute.common.sum_rendered'), align:'right' },
                        { type:'number', key:'sum_paid', header:this.$t('attribute.common.sum_paid'), align:'right' },
                        { type:'number', key:'sum_unpaid', header:this.$t('attribute.common.sum_unpaid'), align:'right' },
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id') },
                    ],
                    order: [ 'id' ],
                    displayToolbar: false,
                    // buttons: ['edit'],
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                currentUser: 'currentUser',
            }),
        },

        // =============== Methods ===============
        methods: {
            setGridSettings() {
                // console.log(`DEBUG: ${this.$options.name}.setGridSettings() this.clientId=${this.clientId}`);
                // console.log(`DEBUG: ${this.$options.name}.setGridSettings() this.currentUser.permissions=`, this.currentUser.permissions);
                if (tools.isPrivilegeGranted(this.currentUser.permissions, 'main_menu', 'cashdesk', null)) {
                    // console.log(`DEBUG: ${this.$options.name}.setGridSettings() cashdesk`);
                    let paymentIcon = {
                        type:'icon', icon:'fas fa-pencil-alt', style:'color: #056bbf;', title:this.$t('app.open'),
                        $onClick:account => this.openCashdeskAccount(account)
                    };
                    this.accountsGridConfig.columns.unshift(paymentIcon);
                }
                // console.log(`DEBUG: ${this.$options.name}.setGridSettings() behind cashdesk`);
                if (
                    tools.isPrivilegeGranted(this.currentUser.permissions, 'main_menu', 'finances.accounts', null) &&
                    !tools.isPrivilegeGranted(this.currentUser.permissions, 'main_menu', 'cashdesk', null)
                ) {
                    let paymentIcon = {
                        type:'icon', icon:'fas fa-pencil-alt', style:'color: #056bbf;', title:this.$t('app.open'),
                        $onClick:account => this.openFinAccount(account)
                    };
                    this.accountsGridConfig.columns.unshift(paymentIcon);
                }
                let filter = { where:[
                    { payer_client_id:this.clientId },
                ]};
                this.accountsGridConfig.filter = filter;
                this.accountsGridConfig.apiUrl = 'v_account';
            },

            openCashdeskAccount(account) {
                this.$router.push({ name: 'cashdesk/account', params: { accountId: account.id } });
            },
            openFinAccount(account) {
                this.$router.push({ name: 'finances/account', params: { accountId: account.id } });
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.clientId=`, this.clientId);
            this.setGridSettings();
        },
    }
</script>

<style>
</style>
