<!--
    Author: Eduard Grebenyukov
    Date: 2020-06-20
-->

<template>
     <TemplatePageStandard :config="{ breadcrumb }">
        <div id="page-content">
            <Grid ref="grid" :key="$route.fullPath" :config="gridConfig" />
        </div>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'OpenPeriod',

        components: { TemplatePageStandard, Grid },

        // =============== Data ===============
        data() {
            return {
                breadcrumb: [
                    { text:this.$t('MainMenu.home'), to:'/' },
                    { text:this.$t('MainMenu.settings') },
                    { text:this.$t('MainMenu.settings-rights-open-periods') },
                ],
                defaultPrivilegeId: null,
                objectArray: [
                    { code:'fin_bill', name:this.$t('object.fin_bill') },
                    { code:'visit_rec', name:this.$t('object.visit_rec') },
                    { code:'fin_cash', name:this.$t('object.fin_cash') },
                ],
                gridConfig: {
                    apiUrl:'core_permission',
                    filter: null,
                    columns: [
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', readonly:true, width:'50px' },
                        { type:'select', key:'core_role_id', header:this.$t('object.core_role'), required:true, source:'core_role' },
                        { type:'select', key:'object_name', header:this.$t('attribute.core_permission.object_name'), source:this.objectArray, keyId:'code' },
                        { type:'number', key:'days_from', header:this.$t('attribute.core_permission.days_from') },
                        // { type:'string', key:'object_name', header:this.$t('attribute.core_permission.object_name') },
                        // { type:'string', key:'attribute_name', header:this.$t('attribute.core_permission.attribute_name') },
                        // { type:'select', key:'access_type', header:this.$t('attribute.core_permission.access_type'), source: [
                        //     { id:'r', name:this.$t('constant.access_type.r') },
                        //     { id:'rw', name:this.$t('constant.access_type.rw') },
                        // ], showEmptyRow:true, defaultValue:'rw' },
                        // { type:'hidden', key:'access_type', header:this.$t('attribute.core_permission.access_type'), defaultValue:'rw' },
                        // { type:'select', key:'core_privilege_id', header:this.$t('object.core_privilege'), required:true, source:'core_privilege' },
                        { type:'hidden', key:'core_privilege_id', header:this.$t('object.core_privilege'), required:true, source:'core_privilege' },
                        { type:'hidden', key:'is_read', header:this.$t('attribute.core_permission.is_read'), defaultValue:true },
                        { type:'hidden', key:'is_write', header:this.$t('attribute.core_permission.is_write'), defaultValue:true },
                    ],
                    buttons: [ 'row-selector', 'add', 'save', 'delete' ],
                    order: [ 'object_name' ],
                    displayToolbar: true,
                    saveSessionState: `${this.$options.name}`,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Methods ===============
        methods: {
            // addItem() {
            //     this.$router.push({ name: 'settings/rights/user-new', params: { id: null } });
            // },
            // editItem(id) {
            //     // console.log(`DEBUG: ${this.$options.name}.editItem(), id=${id}`);
            //     this.$router.push({ name: 'settings/rights/user-edit', params: { id } });
            // },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            try {
                let filterString = tools.getFilterString({ where:[{ code:'open_period' }]});
                // let response = await this.authenticatedAxios.get(`${this.baseUrl}/core_privilege${filterString}`);
                // console.log(`DEBUG: ${this.$options.name}.beforeMount(), response=`, response);
                this.defaultPrivilegeId = (await this.authenticatedAxios.get(`${this.baseUrl}/core_privilege${filterString}`)).data.rows[0].id;
                this.gridConfig.filter = { where:[{ core_privilege_id:this.defaultPrivilegeId }]};
                this.gridConfig.columns.find(col => col.key === 'core_privilege_id').defaultValue = this.defaultPrivilegeId;
                this.gridConfig.columns.find(col => col.key === 'object_name').source = this.objectArray;
                // console.log(`DEBUG: ${this.$options.name}.beforeMount(), defaultPrivilegeId=${this.defaultPrivilegeId}`);
            } catch (err) {
                tools.displayApiError(this, err);
            }
        },
    }
</script>

<style>
</style>
