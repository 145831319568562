<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-12
-->

<template>
     <TemplatePageStandard :config="{ breadcrumb }">
        <!-- <template #buttons>
            <div @click="filterModified()" class="btn btn-sm btn-primary mr-1">
                <i class="fas fa-play"></i> {{ $t('app.build') }}
            </div>
        </template> -->

        <div id="page-content">
            <div class="row">
                <div class="col-xl">
                    <FieldDatetime
                        label="From time"
                        :value.sync="filterData.timeFrom"
                        displayClearButton
                        @modified="filterModified"
                    />
                </div>
                <div class="col-md">
                    <FieldDatetime
                        label="To time"
                        :value.sync="filterData.timeTo"
                        displayClearButton
                        @modified="filterModified"
                    />
                </div>
                <div class="col-xl">
                    <!-- <FieldString label="Login" :value.sync="filterData.username" /> -->
                    <FieldSelect
                        label="Username"
                        :value.sync="filterData.username"
                        source="system-log-usernames"
                        keyId="username"
                        keyLabel="username"
                        showEmptyRow
                        @modified="filterModified"
                    />
                </div>
                <div class="col-xl">
                    <FieldNumber label="Session" :value.sync="filterData.core_session_id" @modified="filterModified" />
                </div>
            <!-- </div>
            <div class="row"> -->
                <div class="col-xl">
                    <FieldSelect
                        label="Action"
                        :value.sync="filterData.action"
                        source="system-log-actions"
                        keyId="action"
                        keyLabel="action"
                        showEmptyRow
                        @modified="filterModified"
                    />
                </div>
                <div class="col-xl">
                    <FieldSelect
                        label="Table"
                        :value.sync="filterData.table_name"
                        source="system-log-tables"
                        keyId="table_name"
                        keyLabel="table_name"
                        showEmptyRow
                        @modified="filterModified"
                    />
                </div>
                <div class="col-xl">
                    <FieldNumber label="Row id" :value.sync="filterData.row_id" @modified="filterModified" />
                </div>
                <div class="col-xl">
                    <FieldSelect
                        label="Status"
                        :value.sync="filterData.status"
                        :source="statusList"
                        showEmptyRow
                        @modified="filterModified"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <Grid ref="grid" :key="$route.fullPath" :config="gridConfig" />
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';
    import FieldDatetime from '@/components/lib/controls/FieldDatetime';
    // import FieldString from '@/components/lib/controls/FieldString';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldSelect from '@/components/lib/controls/FieldSelect';

    export default {
        name: 'SystemLog',

        components: {
            TemplatePageStandard, Grid,
            FieldDatetime, /* FieldString, */ FieldNumber, FieldSelect
        },

        props: ['config'],

        // =============== Data ===============
        data() {
            return {
                isShowActiveOnly: true,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-system') },
                    { text: this.$t('MainMenu.settings-system-log') }
                ],
                filterData: {
                    timeFrom: null,
                    timeTo: null,
                    username: null,
                    core_session_id: null,
                    action: null,
                    table_name: null,
                    row_id: null,
                    status: null,
                },
                statusList: [
                    { id:'error', name: 'error' },
                    { id:'success', name: 'success' },
                ],
                gridConfig: {
                    apiUrl: null,
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id') },
                        { type:'datetime', key:'time_of', header:this.$t('attribute.common.datetime_of') },
                        { type:'string', key:'ip_address', header:'IP' },
                        { type:'string', key:'url', header:'URL' },
                        { type:'string', key:'username', header:'Username' },
                        { type:'number', key:'core_session_id', header:'Session' },
                        { type:'string', key:'action', header:'Action' },
                        { type:'string', key:'table_name', header:'Table' },
                        // { type:'string', key:'column_name', header:'Column' },
                        { type:'number', key:'row_id', header:'Row id' },
                        { type:'string', key:'status', header:'Status' },
                        // { type:'string', key:'old_text', header:'Old text' },
                        // { type:'string', key:'new_text', header:'Text' },
                        {
                            type:'string', key:'old_text', header:'Old text',
                            $value: entry => (entry.old_text ? entry.old_text.replaceAll(',"', ', "') : null),
                        },
                        {
                            type:'string', key:'new_text', header:'New text',
                            $value: entry => (entry.new_text ? entry.new_text.replaceAll(',"', ', "') : null),
                        },
                    ],
                    row: { $style:(entry) => entry.status === 'error' ? 'background-color:#ffeeee' : '' },
                    order: [{ key:'time_of', direction:'desc' }],
                    displayToolbar: true,
                    saveSessionState: `${this.$options.name}`,
                },
            }
        },

        // =============== Methods ===============
        methods: {
            setGridConfig() {
                this.gridConfig.apiUrl = 'core_log';
                let whereArray = [];
                // if (this.filterData.username) whereArray.push([ 'username', 'like', `%this.filterData.username%` ]);
                if (this.filterData.timeFrom) whereArray.push([ 'time_of', '>=', this.filterData.timeFrom ]);
                if (this.filterData.timeTo) whereArray.push([ 'time_of', '<=', this.filterData.timeTo ]);
                if (this.filterData.username) whereArray.push({ username:this.filterData.username });
                if (this.filterData.core_session_id) whereArray.push({ core_session_id:this.filterData.core_session_id });
                if (this.filterData.action) whereArray.push({ action:this.filterData.action });
                if (this.filterData.table_name) whereArray.push({ table_name:this.filterData.table_name });
                if (this.filterData.row_id) whereArray.push({ row_id:this.filterData.row_id });
                if (this.filterData.status) whereArray.push({ status:this.filterData.status });

                if (whereArray.length > 0) {
                    this.gridConfig.filter = { where: whereArray };
                } else {
                    this.gridConfig.filter = null;
                }

                this.$refs.grid.refresh();
            },

            filterModified() {
                this.setGridConfig();
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            this.setGridConfig();
        },
    }
</script>

<style>
</style>
