<!--
    Author: Eduard Grebenyukov
    Date: 2021-05-25
-->

<template>
    <ModalWindow
        width="90%"
        max-width="1200px"
        :buttons="buttons"
        @cancel="closeModal"
        @save="saveData"
        @delete="askDeleteItem"
    >
        <span v-if="!itemId" slot="header" :title="msgItemModified">{{ $t('component.MatItemEditModal.createNew') }} {{ isModified? '*' : '' }}</span>
        <span v-if="itemId"  slot="header" :title="msgItemModified">{{ $t('component.MatItemEditModal.edit') }} {{ isModified? '*' : '' }}</span>

        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-row">
                        <div class="col-sm col-md-2">
                            <FieldString
                                :label="$t('attribute.common.code')"
                                :value.sync="editFormData.code"
                                @modified="itemModified('code')"
                            />
                        </div>
                        <div class="col-sm col-md-10">
                            <FieldString
                                :label="$t('attribute.common.name')"
                                :value.sync="editFormData.name"
                                required
                                @modified="itemModified('name')"
                            />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm col-md-2">
                            <FieldString
                                :label="$t('attribute.mat_item.vendor_code')"
                                :value.sync="editFormData.vendor_code"
                                @modified="itemModified('vendor_code')"
                            />
                        </div>
                        <div class="col-sm col-md-10">
                            <FieldString
                                :label="$t('attribute.mat_item.manufacturer')"
                                :value.sync="editFormData.manufacturer"
                                @modified="itemModified('manufacturer')"
                            />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm col-md-6">
                            <FieldSelect
                                :label="$t('object.mat_group')"
                                :value.sync="editFormData.mat_group_id"
                                source="mat_group"
                                required
                                @modified="itemModified('mat_group')"
                            />
                        </div>
                        <!-- <div class="col-sm">
                            <FieldSelect
                                :label="$t('object.mat_pharmgroup')"
                                :value.sync="editFormData.mat_pharmgroup_id"
                                source="mat_pharmgroup"
                                @modified="itemModified()"
                            />
                        </div> -->
                        <!-- <div class="col-sm">
                            <FieldSelect
                                :label="$t('object.mat_pricegroup')"
                                :value.sync="editFormData.mat_pricegroup_id"
                                source="mat_pricegroup"
                                @modified="itemModified()"
                            />
                        </div> -->
                        <div class="col-sm col-md-2">
                            <FieldSelect
                                ref="matUnitId"
                                :label="$t('attribute.common.base_unit_id')"
                                :value.sync="editFormData.base_unit_id"
                                source="mat_unit"
                                required
                                @modified="itemModified('mat_unit')"
                            />
                        </div>
                        <!-- <div class="col-sm">
                            <FieldSelect
                                :label="$t('object.fin_service')"
                                :value.sync="editFormData.fin_service_id"
                                source="fin_service"
                                @modified="itemModified()"
                            />
                        </div> -->
                        <div class="col-sm col-md-2">
                            <FieldSelect
                                :label="$t('object.fin_vat')"
                                :value.sync="editFormData.fin_vat_id"
                                source="fin_vat"
                                required
                                @modified="itemModified('fin_vat')"
                            />
                        </div>
                        <div class="col-sm col-md-2">
                            <FieldSwitch
                                id="is_vital"
                                :label="$t('attribute.mat_item.is_vital')"
                                :value.sync="editFormData.is_vital"
                                :defaultValue="false"
                                @modified="itemModified('is_vital')"
                            />
                        </div>
                    </div>
                    <div v-if="typeof itemId === 'undefined'" class="form-row">
                        <div class="col-sm col-md-4">
                            <FieldSelect
                                ref="packUnit"
                                :label="$t('component.MatItemEditModal.packUnit')"
                                :value.sync="editFormPackData.mat_unit_id"
                                source="mat_unit"
                                required
                                @modified="itemModified('packUnit')"
                            />
                        </div>
                        <div class="col-sm col-md-4">
                            <FieldNumber
                                :label="$t('attribute.mat_pack.unit_factor')"
                                :value.sync="editFormPackData.unit_factor"
                                required
                                @modified="itemModified('unit_factor')"
                            />
                        </div>
                        <div class="col-sm col-md-4">
                            <FieldString
                                :label="$t('component.MatItemEditModal.packName')"
                                :value.sync="editFormPackData.name"
                                required
                                @modified="itemModified('packName')"
                            />
                        </div>
                    </div>
                    <!-- <div class="form-row" v-if="editFormData.id">
                        <div class="col">
                            <TabSwitch
                                :value.sync="currentTab"
                                defaultValue="main"
                                :source="navigationTabs"
                                @modified="filterTabsModified"
                            />
                        </div>
                    </div> -->
                    <!-- <div v-if="isDisplayGrids" class="form-row">
                        <div class="col">
                            <Grid
                                ref="packGrid"
                                :config="packGridConfig"
                                @modified="itemModified('packGrid', $event)"
                            />

                            <Grid
                                ref="barcodeGrid"
                                :config="barcodeGridConfig"
                            />
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <WarningClose v-if="isDisplayWarningClose" @process="$event ? processClose() : cancelClose()" />
        <WarningDelete v-if="isDisplayWarningDelete" @process="$event ? processDeleteItem() : cancelDeleteItem()" />

    </ModalWindow>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import WarningClose from '@/components/lib/regions/WarningClose';
    import WarningDelete from '@/components/lib/regions/WarningDelete';

    // import ButtonCustom from '@/components/lib/controls/ButtonCustom';
    // import ButtonClose from '@/components/lib/controls/ButtonClose';
    // import ButtonSave from '@/components/lib/controls/ButtonSave';
    // import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldString from '@/components/lib/controls/FieldString';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import FieldNumber from '@/components/lib/controls/FieldNumber';

    export default {
        name: 'MatItemEditModal',

        components: {
            ModalWindow, WarningClose, WarningDelete,
            // ButtonSave, ButtonClose, ButtonDelete,
            FieldString, FieldSelect, FieldSwitch, FieldNumber,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Props ===============
        props: {
            matItemId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                editFormData: {
                    // id: null,
                    mat_group_id: null,
                    mat_pharmgroup_id: null,
                    mat_pricegroup_id: null,
                    base_unit_id: null,
                    fin_service_id: null,
                    med_medicine_id: null,
                    code: null,
                    name: null,
                    vendor_code: null,
                    manufacturer: null,
                    is_vital: false,
                },
                oldFormData: {},
                editFormPackData: {
                    // id: null,
                    mat_item_id: null,
                    mat_unit_id: null,
                    unit_factor: null,
                    name: null,
                },
                oldFormPackData: {},
                matItemListConfig: {
                    readonly: true,
                    columns: [
                        { type:'string', key:'code', header:this.$t('attribute.common.code') },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        { type:'string', key:'vendor_code', header:this.$t('attribute.mat_item.vendor_code') },
                     ],
                    order: [ 'code' ],
                    buttons: [ 'add' ],
                    displayToolbar: true,
                    // $onAdd: () => this.openModalEdit(null, null),
                },
                matPackArray: [],
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            // isDisplayGrids() { return typeof this.editFormData.id !='undefined' },
            isValidated() {
                return (
                    // this.editFormData.mat_doc_id &&
                    !tools.isNumberEmpty(this.editFormData.mat_item_id) &&
                    this.editFormData.unit_qty > 0 &&
                    !tools.isNumberEmpty(this.editFormData.mat_pack_id) &&
                    !tools.isNumberEmpty(this.editFormData.unit_price) && this.editFormData.unit_price >= 0 &&
                    !tools.isNumberEmpty(this.editFormPackData.unit_factor) && this.editFormData.unit_factor >= 0 &&
                    !tools.isStringEmpty(this.editFormPackData.packName)
                    // !tools.isNumberEmpty(this.editFormData.fin_vat_id) &&
                    // !tools.isNumberEmpty(this.editFormData.sum_vat)
                );
            },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            checkModified() {
                this.isItemModified = (
                    JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData) ||
                    JSON.stringify(this.oldFormPackData) !== JSON.stringify(this.editFormPackData)
                );
                this.isModified = this.isItemModified || this.isPackGridModified;
            },

            gotData() {
                // this.isModified = false;
                this.isItemModified = false;
                this.msgItemModified = '';
                this.itemId = this.editFormData.id;

                // let filter = { where:[
                //     { mat_item_id:this.editFormData.id },
                // ]};
                // this.packGridConfig.filter = filter;
                // this.packGridConfig.apiUrl = 'mat_pack';
                // this.packGridConfig.columns.find(x => x.key === 'mat_item_id').defaultValue = this.editFormData.id;
                // if (this.$refs.packGrid) this.$refs.packGrid.refresh();

                // this.barcodeGridConfig.filter = filter;
                // this.barcodeGridConfig.apiUrl = 'mat_barcode';
                // if (this.$refs.barcodeGrid) this.$refs.barcodeGrid.refresh();

                this.checkButtons();
            },

            async getData() {
                try {
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/mat_item/${this.itemId}`)).data;
                    if (data) {
                        this.editFormData = data;
                        this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                        this.oldFormPackData = JSON.parse(JSON.stringify(this.editFormPackData));
                    }
                    // console.log(`DEBUG: ${this.$options.name}.getData data=`, this.editFormData);
                    this.gotData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async savePackData(data) {
                let packData = {
                    mat_item_id: data.id,
                    mat_unit_id: this.editFormPackData.mat_unit_id,
                    unit_factor: this.editFormPackData.unit_factor,
                    name: this.editFormPackData.name,
                }
                return (await this.authenticatedAxios.post(`${this.baseUrl}/mat_pack`, packData)).data;
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isModified=${this.isModified}, this.editFormData=`, this.editFormData);
                if (this.isItemModified) {
                    //this.$v.$touch();
                    //if (!this.$v.$invalid) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            // console.log(`DEBUG: ${this.$options.name}.saveData() Update this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.put(`${this.baseUrl}/mat_item/${this.editFormData.id}`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;                                
                                this.gotData();
                                tools.displaySuccessSave(this);
                            }
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveData() New this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.post(`${this.baseUrl}/mat_item`, this.editFormData)).data;
                            if (data) {
                                let matPackData = await this.savePackData(data);
                                this.editFormData = data;
                                // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                                this.gotData();
                                // this.getData();
                                this.oldFormData = JSON.parse(JSON.stringify(this.editFormData)); // to prevent question
                                this.oldFormPackData = JSON.parse(JSON.stringify(this.editFormPackData)); // to prevent question
                                this.checkModified();
                                tools.displaySuccessSave(this.$parent);
                                // console.log(`DEBUG: ${this.$options.name}.saveData() this.$parent=`, this.$parent);
                                // if (this.$parent.refreshMatItemList) {
                                    // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                                    // this.$parent.refreshMatItemList();
                                // }
                                this.$emit('saved', { matItemData:data, matPackData });
                                this.closeModal();
                            }
                        }
                        this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                        this.oldFormPackData = JSON.parse(JSON.stringify(this.editFormPackData));
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                    // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                } else {
                    // no changes
                }
                // if (this.isDisplayGrids) {
                //     if (this.$refs.packGrid) {
                //         this.$refs.packGrid.$emit('save');
                //         this.isPackGridModified = false;
                //     }
                //     // if (this.$refs.programsGrid) {
                //     //     // this.$refs.programsGrid.$emit('save');
                //     //     // this.isProgramsModified = false;
                //     // }
                // }
                this.checkModified();
            },

            itemModified(field) {
                this.checkModified();
                // console.log(`DEBUG: ${this.$options.name}.itemModified('${field}') this.editFormData=`, this.editFormData);
                // console.log(`DEBUG: ${this.$options.name}.itemModified('${field}') this.editFormPackData=`, this.editFormPackData);
                if (
                    (field === 'base_unit_id' || field === 'packUnit' || field === 'unit_factor') &&
                    this.editFormData.base_unit_id &&
                    this.editFormPackData.mat_unit_id &&
                    this.editFormPackData.unit_factor
                ) {
                    if (this.editFormData.base_unit_id === this.editFormPackData.mat_unit_id && this.editFormPackData.unit_factor === 1) {
                        this.editFormPackData.name = this.$refs.matUnitId.itemPresentation;
                    } else {
                        this.editFormPackData.name = `${this.$refs.packUnit.itemPresentation} ${this.editFormPackData.unit_factor} ${this.$refs.matUnitId.itemPresentation}`;
                    }
                }
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
            },

            closeModal() {
                if (this.isModified) {
                    this.isDisplayWarningClose = true;
                } else {
                    this.processClose();
                }
            },
            processClose() { this.$emit('close-modal', null) },
            cancelClose() { this.isDisplayWarningClose = false },
        },

        // =============== Life cycle ===============
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this=`, this);
            if (this.itemId) {
                await this.getData();
            } else {
                this.checkButtons();
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
            this.oldFormPackData = JSON.parse(JSON.stringify(this.editFormPackData));
        },
    }
</script>

<style>
</style>
