<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-04
-->

<template>
    <ModalWindow
        width="90%"
        max-width="1200px"
        :buttons="buttons"
        @cancel="closeModal"
        @save="saveData"
        @delete="askDeleteItem"
    >
        <span slot="header" :title="msgItemModified">{{ $t('object.med_referral') }} {{ isModified? '*' : '' }}</span>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <FieldSelect :label="$t('object.med_referral_type')" :value.sync="editFormData.med_referral_type_id" @modified="itemModified" required source="v_referral_type" />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 col-md-4 col-lg">
                    <FieldNumber :label="$t('attribute.common.quantity')" :value.sync="editFormData.qty" @modified="itemModified" required />
                </div>
                <div class="col-sm-4 col-md-4 col-lg">
                    <FieldSwitch id="is_cito" :label="$t('attribute.med_referral.is_cito')" :value.sync="editFormData.is_cito" @modified="itemModified" />
                </div>
                <div class="col-sm-4 col-md-4 col-lg">
                    <FieldSwitch id="is_canceled" :label="$t('attribute.med_referral.is_canceled')" :value.sync="editFormData.is_canceled" @modified="itemModified" />
                </div>
                <div class="col-sm-6 col-md-6 col-lg">
                    <FieldDate :label="$t('attribute.common.date_from')" :value.sync="editFormData.date_from" @modified="itemModified" required />
                </div>
                <div class="col-sm-6 col-md-6 col-lg">
                    <FieldDate :label="$t('attribute.common.date_to')" :value.sync="editFormData.date_to" @modified="itemModified" required />
                </div>
                <!-- <div class="col-sm-12 col-md">
                    <FieldDate :label="$t('attribute.common.date_plan')" :value.sync="editFormData.date_plan" @modified="itemModified" />
                </div> -->
            </div>
            <div class="row">
                <div class="col">
                    <FieldText :label="$t('attribute.med_referral.purpose')" :value.sync="editFormData.purpose" @modified="itemModified" />
                </div>
            </div>
            <div v-if="editFormData.is_canceled" class="row">
                <div class="col">
                    <FieldText :label="$t('attribute.med_referral.cancellation_reason')" :value.sync="editFormData.cancellation_reason" @modified="itemModified" />
                </div>
            </div>
        </div>

        <WarningClose v-if="isDisplayWarningClose" @process="$event ? processClose() : cancelClose()" />
        <WarningDelete v-if="isDisplayWarningDelete" @process="$event ? processDeleteItem() : cancelDeleteItem()" />

    </ModalWindow>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import WarningClose from '@/components/lib/regions/WarningClose';
    import WarningDelete from '@/components/lib/regions/WarningDelete';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import FieldText from '@/components/lib/controls/FieldText';

    const componentUrl = 'med_referral';

    export default {
        name: 'ReferralEdit',

        components: {
            ModalWindow, WarningClose, WarningDelete,
            FieldDate, FieldSelect, FieldSwitch, FieldText, FieldNumber, 
        },

        // =============== Props ===============
        props: {
            id: Number,
        },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                isModified: false,
                msgItemModified: '',
                editFormData: {
                    // id: null,
                    med_referral_type_id: null,
                    qty: null,
                    date_from: null,
                    date_to: null,
                    // date_plan: null,
                    is_cito: false,
                    is_canceled: false,
                    cancellation_reason: null,
                    purpose: null,
                },
                oldFormData: {},
                isDisplayWarningDelete: false,
                isDisplayWarningClose: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            apiUrl() { return `${this.baseUrl}/${componentUrl}` },
        },

        // =============== Methods ===============
        methods: {
            async getData() {
                try {
                    let response = await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`);
                    // console.log(`DEBUG: ${this.$options.name}.getData() data=`, response.data);
                    this.editFormData = response.data;

                    this.isModified = false;
                    this.msgItemModified = '';
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData(${this.isModified})`,);
                if (this.isModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData);
                            // this.$emit('close-modal', true);
                        } else {
                            // New referral created
                            let response = await this.authenticatedAxios.post(this.apiUrl, this.editFormData);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response.data=`, response.data);
                            this.itemId = response.data.id;
                            // this.editFormData.id = this.itemId;
                            this.getData();
                        }
                        tools.displaySuccessSave(this.$parent);
                        this.$emit('close-modal', true);
                    } catch (err) {
                        tools.displayApiError(this.$parent, err);
                    }
                } else {
                    // no changes
                    this.$emit('close-modal', true);
                }
            },
            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified()`);
                if (JSON.stringify(this.oldFormData) === JSON.stringify(this.editFormData)) {
                    this.isModified = false;
                    this.msgItemModified = '';
                } else {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                }
            },

            askDeleteItem() {
                this.isDisplayWarningDelete = true;
            },
            async processDeleteItem() {
                try {
                    await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                    tools.displaySuccessDelete(this.$parent);
                    this.$emit('close-modal', true);
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },
            cancelDeleteItem() { this.isDisplayWarningDelete = false },

            closeModal() {
                if (this.isModified) {
                    this.isDisplayWarningClose = true;
                } else {
                    this.processClose();
                }
            },
            processClose() { this.$emit('close-modal', false) },
            cancelClose() { this.isDisplayWarningClose = false },

        },

        // =============== Life cycle ===============
        beforeMount() {
            this.itemId = this.id;
        },

        async mounted() {
            if (this.itemId) {
                await this.getData();
                this.buttons = ['save', 'delete', 'close'];
            } else {
                this.buttons = ['save', 'close'];
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
        },
    }
</script>

<style>

</style>
