/*
    Author: Eduard Grebenyukov
    Date: 2019-12-30
*/

import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';

/* eslint-disable no-console */
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
    for(let registration of registrations) {
        registration.unregister();
    }}).catch(function(err) {
        console.log('Service Worker unregistration failed: ', err);
    });
}

import router from './router';
import store from './store';
import { i18n } from './locales';

import './assets/variables.scss';
import './assets/fonts.scss';
import './assets/custom.scss';

// import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
// import 'bootstrap-icons/icons';
// import jquery from 'jquery';

// FA 5
import '@fortawesome/fontawesome-free/css/all.css';
// // import '@fortawesome/fontawesome-free/js/all.js';

import BootstrapVue from 'bootstrap-vue';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';

const sprintf = require('sprintf-js').sprintf;
Vue.prototype.$sprintf = sprintf;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

// socket.io
// import VueSocketIOExt from 'vue-socket.io-extended';
// import { io } from 'socket.io-client';
// import { baseHost, socketUrl } from './store/constants';
// const socket = io(baseHost, { path: socketUrl });
// Vue.use(VueSocketIOExt, socket, { store });

new Vue({
    created() {
        let lang = i18n.locale;
        // console.log(`DEBUG: /main.created() lang=${lang}`);
        const html = document.documentElement; // returns the html tag
        html.setAttribute('lang', lang);
    },
    router,
    store,
    i18n,
    // jquery,
    render: h => h(App),
}).$mount('#app');
