<!--
    Author: Eduard Grebenyukov
    Date: 2020-02-27
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()"/>
            <ButtonSave v-if="buttons.includes('save')" :disabled="!isSaveEnabled" @click="saveData()" />
            <ButtonPrint v-if="buttons.includes('print')" @click="printEstimate()">
                <i class="fas fa-print"></i> {{ $t('component.MedEstimate.printEstimate') }}
            </ButtonPrint>
            <!-- <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" /> -->
        </template>

        <VisitDescription />

        <div class="med-estimate__navbar-container">

            <VisitTabs :currentTab="isDental ? 'visit-estimates-dental' : 'visit-estimates'" />

            <div class="navbar">
                <ButtonSwitch
                    :value.sync="isDisplayRightSidebar"
                    :defaultValue="true"
                    :saveState="`${$options.name}.display_sidebar`"
                    :title="$t('app.hideShow')"
                />
            </div>
        </div>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div :class="isDisplayRightSidebar ? 'med-estimate__renders col-sm-12 col-lg-8 col-xl-8' : 'med-estimate__renders col'">
                        <div class="row">
                            <div class="col">
                                <Grid
                                    ref="renders"
                                    :key="$route.fullPath"
                                    :config="gridConfig"
                                    @edit-item="editRender($event)"
                                    @refreshed="rendersRefreshed()"
                                    @item-modified="renderModified($event)"
                                    @item-selected="renderSelected($event)"
                                    @delete-items="deleteRenders($event)"
                                >
                                    <template slot="buttons">
                                        <button @click.prevent="shiftToProgram()" class="btn btn-sm btn-outline-secondary mr-1" :disabled="renderSelectedQty == 0">
                                            {{ $t('component.MedEstimate.shiftToProgram') }}
                                        </button>
                                        <button @click.prevent="shiftToClient()" class="btn btn-sm btn-outline-secondary mr-1" :disabled="renderSelectedQty == 0">
                                            {{ $t('component.MedEstimate.shiftToClient') }}
                                        </button>
                                        <button @click.prevent="isDisplayDiscountList = true" class="btn btn-sm btn-warning mr-1" :disabled="renderSelectedQty == 0">
                                            {{ $t('component.MedEstimate.addDiscount') }}
                                        </button>
                                        <button @click.prevent="deleteDiscount()" class="btn btn-sm btn-outline-danger mr-1" :disabled="renderSelectedQty == 0">
                                            {{ $t('component.MedEstimate.deleteDiscount') }}
                                        </button>
                                    </template>
                                </Grid>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <FieldText
                                    v-if="editFormData.id !== null"
                                    :label="$t('attribute.common.commentary')"
                                    :value.sync="editFormData.commentary"
                                    @modified="itemCommentaryModified()"
                                    @changed="itemCommentaryChanged()"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="med-estimate__right-sidebar col-sm-12 col-lg-4 col-xl-4" :style="isDisplayRightSidebar ? '' : 'display:none;'">
                        <!-- Right sidebar -->
                        <VisitServiceTemplate
                            :visitTypeId="lastVisit.visit_type_id"
                            :specialityId="lastVisit.core_speciality_id"
                            :visitTime="new Date(lastVisit.time_of)"
                            :formItemName="currentFormItem"
                            :attributeName="currentAttribute"
                            @selected="serviceSelected($event)"
                        />
                    </div>
                </div>
            </div>
        </form>

        <ModalWindow
            v-if="isDisplayDiscountList"
            :header="$t('Fin.discount_bid')"
            width="600px" max-width="600px" max-height="90vh"
            @cancel="isDisplayDiscountList = false"
        >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="discountList" :config="discountGridConfig" @item-selected="discountSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>

        <ModalWindow
            v-if="isDisplayDiscountInput"
            width="600px" max-width="600px" max-height="90vh"
            :header="$t('component.RenderedServices.enterDiscountPct')"
            :buttons="[ 'ok', 'cancel' ]"
            @ok="variableDiscountPct ? variableDiscountEntered() : null"
            @cancel="isDisplayDiscountInput = false"
        >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <FieldNumber
                            :label="$t('component.RenderedServices.discount')"
                            labelPosition="before"
                            :minValue="0"
                            :maxValue="100"
                            :value.sync="variableDiscountPct"
                            required
                        />
                    </div>
                </div>
            </div>
        </ModalWindow>

        <ModalWindow v-if="isDisplayProgramList" width="600px" max-width="600px" max-height="90vh" @cancel="isDisplayProgramList = false" :header="$t('objects.fin_program')" >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="programList" :source="programArray" :config="programGridConfig" @item-selected="programSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';

    import ButtonSwitch from '@/components/lib/controls/ButtonSwitch';
    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonPrint from '@/components/lib/controls/ButtonPrint';

    import Grid from '@/components/lib/regions/Grid';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';
    import VisitServiceTemplate from '@/components/pages/visits/VisitServiceTemplate';
    import FieldText from '@/components/lib/controls/FieldText';
    import FieldNumber from '@/components/lib/controls/FieldNumber';

    export default {
        name: 'MedEstimate',

        components: {
            TemplatePageStandard, ModalWindow,
            ButtonSwitch, ButtonClose, ButtonSave, ButtonPrint,
            VisitDescription, VisitTabs, Grid, VisitServiceTemplate,
            FieldText, FieldNumber,
        },

        // =============== Props ===============
        props: {
            isDental: { type:Boolean, defalult: false },
        },

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                serviceId: null,
                renderQty: 0,
                renderSelectedQty: 0,
                buttons: [],
                msgItemModified: '',
                isDisplayRightSidebar: null,
                emptyFormData: {
                    id: null,
                    core_workplace_id: null,
                    core_client_id: null,
                    visit_rec_id: null,
                    date_of: null,
                    bill_num: null,
                    commentary: null,
                },
                editFormData: {},
                oldFormData: {},
                currentFormItem: null,
                currentAttribute: null,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.estimates') }),
                gridConfig: {
                    apiUrl: null, // v_estimate_render
                    readonly: true,
                    columns: [
                        // { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'50px' }, // first hidden col need to hide Grid.total title
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'64px' },
                        // { type:'string', key:'service_code', hidden:true, readonly:true }, // to search working
                        { type:'string', key:'service_name', hidden:true, readonly:true }, // to search working
                        { type:'string', key:'service_code', header:this.$t('object.fin_service'), $value: entry => `${entry.service_code} / ${entry.service_name}` },
                        // { type:'number', key:'price', header:this.$t('attribute.common.price'), align:'right', width:'64px' },
                        { type:'number', key:'qty', header:this.$t('attribute.common.qty'), $readonly: entry => (entry.sum_paid > 0), sortable:false, align:'right', width:'64px' },
                        { type:'number', key:'sum_discount', header:this.$t('attribute.common.sum_discount'), sortable:false, align:'right', width:'64px' },
                        {
                            type:'number', key:'sum_payment', header:this.$t('attribute.common.sum'), sortable:false, align:'right', width:'64px',
                            $cellStyle: entry => (
                                entry.sum_payment === 0 ? 'background-color:#ffdddd;' :
                                entry.is_price_variable ? 'color:#007bff;' :
                                ''
                            ),
                            $cellTitle: entry => (
                                entry.is_price_variable ? this.$t('attribute.fin_service.is_price_variable') : ''
                            ),
                            $title: entry => (
                                entry.is_price_variable ? this.$t('attribute.fin_service.is_price_variable') : ''
                            ),
                        },
                        { type:'number', key:'sum_program', header:this.$t('attribute.common.sum_program'), sortable:false, align:'right', width:'64px' },
                        { type:'number', key:'sum_patient', header:this.$t('attribute.common.sum_patient'), sortable:false, align:'right', width:'64px' },
                        { type:'string', key:'program_name', header:this.$t('object.fin_program'), sortable:false, readonly:true },
                        { type:'string', key:'teeth', header:this.$t('objects.med_tooth'), readonly:true, hidden:!this.isDental, sortable:false },
                    ],
                    totals: {
                        columns: [
                            { type:'string', key:'service_code', value:this.$t('app.total'), style:'font-weight:bold; font-size:1.1rem;' },
                            { type:'number', key:'sum_discount', $value: data => tools.getArrayFieldSum(data, 'sum_discount'), style:'font-weight:bold; font-size:1.1rem;' },
                            { type:'number', key:'sum_payment', $value: data => tools.getArrayFieldSum(data, 'sum_payment'), style:'font-weight:bold; font-size:1.1rem;' },
                            { type:'number', key:'sum_program', $value: data => tools.getArrayFieldSum(data, 'sum_program'), style:'font-weight:bold; font-size:1.1rem; color:#6f6f6f;' },
                            { type:'number', key:'sum_patient', $value: data => tools.getArrayFieldSum(data, 'sum_patient'), style:'font-weight:bold; font-size:1.1rem; color:#6f6f6f;' },
                        ],
                    },
                    buttons: [ 'row-selector', 'edit', 'delete' ],
                    order: [ 'id' ],
                },
                isDisplayDiscountList: false,
                discountGridConfig: {
                    apiUrl: 'fin_discount',
                    // title: this.$t('object.fin_discount'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        { type:'number', key:'pct_value', header:this.$t('attribute.common.pct_value'), align:'right', width:'100px' },
                    ],
                    order: [ 'pct_value' ],
                    row: { emit: "item-selected", style: "cursor: pointer;" },
                    displayToolbar: false,
                    displayPagination: false,
                },
                selectedDiscount: null,
                isDisplayDiscountInput: false,
                variableDiscountPct: null,
                isDisplayProgramList: false,
                programSelectorMode: null,
                programArray: [],
                programGridConfig: {
                    // title: this.$t('object.fin_program'),
                    readonly: true,
                    columns: [
                        // { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' },
                        { type:'string', key:'program_name', header:this.$t('object.fin_program') },
                        { type:'string', key:'contract_name', header:this.$t('object.fin_contract') },
                        { type:'number', key:'price', header:this.$t('attribute.common.price'), align:'right', width:'100px' },
                    ],
                    order: [ 'price' ],
                    row: { emit: "item-selected", style: "cursor: pointer;" },
                    displayToolbar: false,
                    displayPagination: false,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                lastClient: 'lastClient',
                lastVisit: 'lastVisit'
                // currencyAccounting: 'currencyAccounting',
            }),
            isSaveEnabled() { return JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData) },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                // console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.$refs.renders.qty=`, this.$refs.renders.getTotalRowsQty());
                if (this.renderQty) {
                    this.buttons = [ 'print' ];
                } else {
                    this.buttons = [];
                }
            },

            async programSelected(selectedProgram) {
                // console.log(`DEBUG: ${this.$options.name}.programSelected(${this.programSelectorMode}), selectedProgram=`, selectedProgram);
                try {
                    if (this.programSelectorMode === 'add-service') {
                        await this.addRender(this.serviceId, selectedProgram.fin_program_id, selectedProgram.fin_beneficiary_id);
                        this.serviceId = null;
                    }
                    if (this.programSelectorMode === 'change-service') {
                        let renders = this.$refs.renders.getSelectedRows();
                        // console.log(`DEBUG: ${this.$options.name}.programSelected(${this.programSelectorMode}), renders=`, renders);
                        for (let render of renders) {
                            if (!render.sum_paid) {
                                await this.updateRender(this.composeRender(render, selectedProgram.fin_program_id, selectedProgram.fin_beneficiary_id));
                            }
                        }
                        tools.displaySuccessSave(this);
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                this.isDisplayProgramList = false;
                this.programSelectorMode = null;
                await this.$refs.renders.refresh();
                this.checkButtons();
            },

            async serviceSelected(service) {
                this.serviceId = parseInt(service.id);
                // console.log(`DEBUG: ${this.$options.name}.serviceSelected(), service=`, service);
                // check if this service was before
                const existingRender = this.$refs.renders.getAllRows().find(item => item.fin_service_id === this.serviceId);
                if (existingRender) {
                    existingRender.qty++;
                    await this.renderModified(existingRender);
                } else {
                    // check available programs first
                    // find programs on visit date, do not use fin_estimate.date_of to be simple
                    let filterString = tools.getFilterString({ where:[
                        { visit_rec_id: this.visitId },
                        { fin_service_id: this.serviceId },
                    ]});
                    try {
                        this.programArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-visit-service-programs${filterString}`)).data.rows;
                        // console.log(`DEBUG: ${this.$options.name}.serviceSelected(), programs this.programArray=`, this.programArray);
                        if (this.programArray.length === 0) await this.addRender(this.serviceId, null, null);
                        if (this.programArray.length === 1) await this.addRender(this.serviceId, this.programArray[0].fin_program_id, this.programArray[0].fin_beneficiary_id);
                        if (this.programArray.length > 1) {
                            this.programGridConfig.columns.find(col => col.key === 'price').hidden = false;
                            this.isDisplayProgramList = true;
                            this.programSelectorMode = 'add-service';
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
            },

            async getData() {
                let filterString = tools.getFilterString({ where:[{ visit_rec_id:this.visitId }]});
                try {
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_estimate${filterString}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.getData(), programs data=`, data);
                    if (data && data.length > 0) {
                        this.editFormData = data[0];
                        this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                        this.isModified = false;
                    } else {
                        this.editFormData = JSON.parse(JSON.stringify(this.emptyFormData));
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    try {
                        if (this.editFormData.id != null) {
                            // Update bill
                            await this.authenticatedAxios.put(`${this.baseUrl}/fin_estimate/${this.editFormData.id}`, this.editFormData);
                            await this.getData();
                            tools.displaySuccessSave(this);
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.$refs.addresses=`, this.$refs.addresses);
            },

            async addRender(serviceId, programId, beneficiaryId) {
                // console.log(`DEBUG: ${this.$options.name}.addRender(), this.currentUser=`, this.currentUser);
                let render = {
                    core_user_id: this.currentUser.id,
                    core_client_id: this.clientId,
                    fin_service_id: serviceId,
                    visit_rec_id: this.visitId,
                    fin_beneficiary_id: beneficiaryId,
                    fin_program_id: programId,
                    date_of: null,
                    price: null,
                    qty: 1,
                };
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-create-estimate-render`, render);
                    await this.$refs.renders.refresh();
                    this.getData();
                    this.checkButtons();
                    tools.displaySuccessSave(this);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            composeRender(render, programId, beneficiaryId) {
                return {
                    core_user_id: this.currentUser.id,
                    fin_render_id: render.id,
                    fin_service_id: render.fin_service_id,
                    visit_rec_id: this.visitId,
                    fin_beneficiary_id: beneficiaryId,
                    fin_program_id: programId,
                    date_of: null,
                    price: render.price,
                    qty: render.qty,
                    is_forced: true,
                };
            },

            async updateRender(renderData) {
                // errors handled at callers because their different
                await this.authenticatedAxios.post(`${this.baseUrl}/fin-update-render`, renderData);
            },

            async shiftToProgram() {
                let renders = this.$refs.renders.getSelectedRows();
                try {
                    /*
                        !!! replace to fin-client-programs !!!
                    */
                    this.programArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-visit-programs/${this.visitId}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.shiftToProgram(), renders=`, renders);
                    // if (this.programArray.length === 0) return; // do nothing
                    if (this.programArray.length === 0) {
                        tools.displayWarning(this, this.$t('component.MedEstimate.noProgramsToShift'));
                    }
                    if (this.programArray.length === 1) {
                        for (let render of renders) {
                            if (!render.sum_paid) {
                                await this.updateRender(this.composeRender(render, this.programArray[0].fin_program_id, this.programArray[0].fin_beneficiary_id));
                            }
                        }
                        tools.displaySuccessSave(this);
                    }
                    if (this.programArray.length > 1) {
                        this.programGridConfig.columns.find(col => col.key === 'price').hidden = true;
                        this.isDisplayProgramList = true;
                        this.programSelectorMode = 'change-service';
                        return; // do not refresh renders, else grid checkbox state be lost
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                await this.$refs.renders.refresh();
                this.checkButtons();
            },

            async shiftToClient() {
                let renders = this.$refs.renders.getSelectedRows();
                // console.log(`DEBUG: ${this.$options.name}.shiftToClient(), renders=`, renders);
                try {
                    for (let render of renders) {
                        if (!render.sum_paid) {
                            await this.updateRender(this.composeRender(render, null, null));
                        }
                    }
                    tools.displaySuccessSave(this);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                await this.$refs.renders.refresh();
                this.checkButtons();
            },

            async processCreateDiscount() {
                    const renders = this.$refs.renders.getSelectedRows();
                    // console.log(`DEBUG: ${this.$options.name}.processCreateDiscount() this.selectedDiscount=`, this.selectedDiscount);
                    try {
                        for (const render of renders) {
                            if (!render.sum_paid && !render.sum_discount) {
                                const data = {
                                    fin_render_id: render.id,
                                    fin_discount_id: this.selectedDiscount.id,
                                    pct_value: this.selectedDiscount.pct_value === null ? -this.variableDiscountPct : null,
                                };
                                // await this.processCreateDiscount(data);
                                await this.authenticatedAxios.post(`${this.baseUrl}/fin-create-render-discount`, data);
                            }
                        }
                        tools.displaySuccessSave(this);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                    await this.$refs.renders.refresh();
                    this.checkButtons();
            },

            async discountSelected(discount) {
                this.selectedDiscount = discount;
                if (this.selectedDiscount.pct_value === null) {
                    this.variableDiscountPct = null;
                    this.isDisplayDiscountInput = true;
                } else {
                    await this.processCreateDiscount();
                }
                this.isDisplayDiscountList = false;
            },

            async variableDiscountEntered() {
                await this.processCreateDiscount();
                this.isDisplayDiscountInput = false;
            },

            async deleteDiscount() {
                let renders = this.$refs.renders.getSelectedRows();
                try {
                    for (let render of renders) {
                        if (!render.sum_paid && render.sum_discount) {
                            let filterRenderDiscount = tools.getFilterString({ where:[{ fin_render_id: render.id }] });
                            let renderDiscountArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_render_discount${filterRenderDiscount}`)).data.rows;
                            let renderData = { fin_render_id: render.id, fin_render_discount: renderDiscountArray.map( item => item.id ) };
                            await this.authenticatedAxios.post(`${this.baseUrl}/fin-delete-render-discount`, renderData);
                        }
                    }
                    tools.displaySuccessSave(this);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                await this.$refs.renders.refresh();
                // this.getData();
                this.checkButtons();
            },

            async deleteRenders(renderArray) {
                try {
                    for (let render of renderArray) {
                        if (!render.sum_paid) {
                            await this.authenticatedAxios.post(`${this.baseUrl}/fin-delete-render/${render.id}`);
                        }
                    }
                    await this.$refs.renders.refresh();
                    tools.displaySuccessSave(this);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            editRender(item) {
                // console.log(`DEBUG: ${this.$options.name}.editRender(), item=`, item);
                if (this.isDental) {
                    this.$router.push({ name:'visit-estimate-edit-dental', params:{ clientId:this.clientId, visitId:this.visitId, renderId:item.id }});
                } else {
                    this.$router.push({ name:'visit-estimate-edit', params:{ clientId:this.clientId, visitId:this.visitId, renderId:item.id }});
                }
            },

            rendersRefreshed() {
                // console.log(`DEBUG: ${this.$options.name}.rendersRefreshed(), this.$refs.renders.qty=`, this.$refs.renders.getTotalRowsQty());
                this.renderQty = this.$refs.renders.getTotalRowsQty();
                this.renderSelectedQty = this.$refs.renders.getSelectedRows().length;
                // if (this.renderQty > 0) {
                //     this.billId = this.$refs.renders.getAllRows()[0].fin_bill_id;
                //     console.log(`DEBUG: ${this.$options.name}.rendersRefreshed(), this.billId=`, this.billId);
                // }
                this.checkButtons();
            },

            renderSelected() {
                this.renderSelectedQty = this.$refs.renders.getSelectedRows().length;
            },

            async renderModified(item) {
                // console.log(`DEBUG: ${this.$options.name}.renderModified(), item=`, item);
                try {
                    await this.updateRender(this.composeRender(item, item.fin_program_id, item.fin_beneficiary_id));
                    tools.displaySuccessSave(this);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
                this.$refs.renders.refresh();
            },

            async itemCommentaryModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemCommentaryModified(), this.oldFormData=`, this.oldFormData);
                // console.log(`DEBUG: ${this.$options.name}.itemCommentaryModified(), this.editFormData=`, this.editFormData);
                if (this.isSaveEnabled) {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.isModified = false;
                    this.msgItemModified = '';
                }
            },

            async itemCommentaryChanged() {
                await this.saveData();
            },

            async printEstimate() {
                try {
                    // console.log(`DEBUG: ${this.$options.name}.printEstimate()`);
                    let renders = this.$refs.renders.getAllRows();
                    let sumTotal = renders.reduce(((sum, item) => sum + item.sum_payment), 0);
                    let currencyCode = (await tools.getSetting(this, 'currency_accounting')).value;
                    // console.log(`DEBUG: ${this.$options.name}.printEstimate() currencyCode=`, currencyCode);
                    let filterStringCurrency = tools.getFilterString({ where:[{ code:currencyCode }]});
                    let currency = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_currency${filterStringCurrency}`)).data.rows[0];
                    let filterStringEstimate = tools.getFilterString({ where:[{ visit_rec_id:this.visitId }]});
                    let estimate = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_estimate${filterStringEstimate}`)).data.rows[0];

                    // let currency = this.currencyAccounting;
                    // console.log(`DEBUG: ${this.$options.name}.printEstimate() currency=`, currency);
                    let o = {
                        // taskId: `print.${this.$options.name}`,
                        template: 'estimate',
                        estimate: {
                            estimate_num: estimate.estimate_num,
                            estimate_date: moment(estimate.date_of).format('L'),
                            estimate_commentary: estimate.commentary,
                            total_sum: sumTotal,
                            total_sum_company: 0,
                            total_sum_payer: 0,
                            total_sum_patient: sumTotal,
                            visit_rec_id: this.visitId,
                            currency: currency.name_short,
                            // services: JSON.parse(JSON.stringify(renders)),
                            services: renders
                        },
                        visit: { visit_rec_id: this.visitId },
                    };
                    // console.log(`DEBUG: ${this.$options.name}.printEstimate() o=`, o, ', typeof o.estimate.sumTotal=', typeof o.estimate.sumTotal);
                    tools.printForm(this, o);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this=`, this);
            this.editFormData = JSON.parse(JSON.stringify(this.emptyFormData));
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            // this.gridConfig.apiUrl = `visit/${this.visitId}/services`;
            this.gridConfig.apiUrl = `v_estimate_render`;
            this.gridConfig.filter = { where:[{ visit_rec_id:this.visitId }]};
            await this.getData();
        },

        async mounted() {
            this.checkButtons();
        },
    }
</script>

<style>
    .med-estimate__navbar-container {
        display: flex;
    }
    .med-estimate__renders {
        overflow-x: auto;
    }
    /* .med-estimate__right-sidebar {
    } */
    .med-estimate__renders .grid-renders.grid-container .btn-toolbar {
        min-width: 810px;
    }
</style>
