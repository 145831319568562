/*
    Author: Eduard Grebenyukov
    Date: 2018-11-05
*/

import Axios from 'axios';
import { appCode } from './constants';

export default {
    state: {
        jwt: null,
        authenticated: false,
        ipAddress: null,
    },
    getters: {
        jwt(state) { return state.jwt },
        isAuthenticated(state) {
            return state.authenticated;
        },
        authenticatedAxios(state) {
            // console.log(`DEBUG: /store/auth getters.authenticatedAxios state=`, state);
            return Axios.create({
                headers: {
                    //'Authorization': `Bearer<${state.jwt.id}>`,
                    // 'Authorization': (state.jwt ? state.jwt.id : ''),
                    'Authorization': (state.jwt ? state.jwt : ''),
                    'TZOffset': (new Date()).getTimezoneOffset(),
                }
            });
        },
        currentUser(state) { return state.user },
        ipAddress(state) { return state.ipAddress },
    },

    mutations: {
        setAuthenticated(state, data) {
            // console.log(`DEBUG: /store/auth mutations.setAuthenticated() data=`, data);
            state.jwt = data.token;
            state.user = data.user;
            state.ipAddress = data.ipAddress;
            state.authenticated = true;
        },

        clearAuthentication(state) {
            // console.log(`DEBUG: /store/auth mutations.clearAuthentication()`);
            state.jwt = null;
            state.user = null;
            state.ipAddress = null;
            state.authenticated = false;
        },

        setCurrentUser(state, user) {
            // console.log(`DEBUG: /store/auth mutations.setCurrentUser() user=`, user);
            state.user = user;
        },
    },

    actions: {
        async authenticate(ctx, credentials) {
            try {
                let response = await Axios.post(this.getters.loginUrl, credentials);
                // console.log(`DEBUG: /store/auth actions.authenticate() response=`, response.data);
                if (response.data.success == true) {
                    // console.log(`DEBUG: /store/auth actions.authenticate() response.data=`, response.data);
                    ctx.commit('setAuthenticated', response.data);
                    // console.log(`DEBUG: /store/auth actions.authenticate.this=`, this);
                    // console.log(`DEBUG: /store/auth actions.authenticate.ctx=`, ctx);
                    this.dispatch('clearLastClient');
                    this.dispatch('setClientToSchedule', null);
                    localStorage.removeItem(`${appCode}:clipboard`);
                    sessionStorage.removeItem('schedule.date');
                }
                return response.status;
            } catch (err) {
                // console.log(`DEBUG: /store/auth actions.authenticate error.response=`, err.response);
                if (typeof err.response === 'undefined') {
                    // timeout???!!!, throw exception, catch it in Login page
                    // throw 'Server not available';
                    return null;
                }
                // another error
                // console.log(`DEBUG: /store/auth actions.authenticate error.response.headers=`, err.response.headers);
                // console.log(`DEBUG: /store/auth actions.authenticate error.response.status=`, err.response.status);
                // console.log(`DEBUG: /store/auth actions.authenticate error.response.data=`, err.response.data);
                // console.log(`DEBUG: /store/auth actions.authenticate err=`, err);
                ctx.commit('clearAuthentication');
                return err.response.status;
            }
        },

        async getCurrentUser(ctx) {
            // exception catched in caller to display error message
            let user = (await this.getters.authenticatedAxios.get(`${this.getters.baseUrl}/current_user`)).data;
            // console.log(`DEBUG: /store/auth actions.getCurrentUser() user=`, user);
            ctx.commit('setCurrentUser', user);
        },

        // async checkAuth(ctx) {
        //   console.log('checkAuth.this=', this);
        //   console.log('checkAuth.ctx=', ctx);
        //   console.log('checkAuth.this.state=', this.state);
        //   try {
        //     let response = await this.getters.authenticatedAxios.get(this.getters.checkAuthUrl);
        //     // console.log('checkAuth.response=', response);
        //   } catch (e) {
        //     this.commit('clearAuthentication');
        //     console.log('Authentication expired');
        //   }
        //   //console.log('checkAuth.this2=', this);
        // },

        async logout(ctx) {
            await this.getters.authenticatedAxios.get(this.getters.logoutUrl);
            ctx.commit('clearAuthentication');
            // console.log(`DEBUG: /store/auth actions.logout.this=`, this);
            // console.log(`DEBUG: /store/auth actions.logout.ctx=`, ctx);
            this.dispatch('clearLastClient');
            this.dispatch('setClientToSchedule', null);
            localStorage.removeItem(`${appCode}:clipboard`);
        }
    }
}
