<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-18
-->

<template>
    <li>
        <span @click="toggle" class="fa-li tree-view__li">
            <!-- {{ isGroup? isOpen ? '[-]' : '[+]' : '&bull;' }} -->
            <!-- <i :class="{ 'fa':true, 'fa-folder-o':isGroup && !isOpen, 'fa-folder-open-o':isGroup && isOpen, 'fa-minus':!isGroup, 'folder':isGroup, 'item':!isGroup }"></i> -->
            <i v-if="isGroup" :class="{ 'fa':true, 'fa-caret-right':isGroup && !isOpen, 'fa-caret-down':isGroup && isOpen, 'fa-minus':!isGroup, 'folder':isGroup }"></i>
            <span v-if="!isGroup" >&bull;</span>
        </span>
        <div
            :class="{'tree-view__folder': isGroup}"
            :style="
                (isGroup && folderStyle ? folderStyle : '') + ' ' +
                (isSelected && selectedStyle ? selectedStyle : '') + ' ' +
                (isSelectedContext && selectedContextStyle ? selectedContextStyle : '') + ' ' +
                (item.style ? item.style : '')
            "
            @click="select"
            @dblclick="doubleClick"
            @contextmenu.prevent="$emit('opencontextmenu', { $event, item })"
        >
            <!-- <span v-if="isGroup" :class="{ 'fa':true, 'fa-folder':!isOpen, 'fa-folder-open':isOpen }" ></span> -->
            <span ref="itemText" :class="{ 'tree-view__item': selectable(item) || isGroup }" v-html="itemText"></span>
            <!-- <span v-if="isGroup">[{{ isOpen ? '-' : '+' }}]</span> -->
        </div>
        <ul v-if="isGroup && isOpen" class="fa-ul tree-view__ul">
            <template v-for="(child, index) in item.children">
                <TreeViewItem
                    ref="items"
                    v-if="!child.isGroup || (child.children && child.children.length > 0) || displayEmptyGroups"
                    :key="index"
                    :item="child"
                    :itemSelected="itemSelected"
                    :selectedContextItem="selectedContextItem"
                    :filter="filter"
                    :displayEmptyGroups="displayEmptyGroups"
                    :folderStyle="folderStyle"
                    :selectedStyle="selectedStyle"
                    :selectedContextStyle="selectedContextStyle"
                    @doubleclick="$emit('doubleclick', $event)"
                    @add-item="$emit('add-item', $event)"
                    @select="$emit('select', $event)"
                    @opencontextmenu="$emit('opencontextmenu', $event)"
                />
            </template>
            <!-- <li class="add" @click="$emit('add-item', item)">+</li> -->
        </ul>
        <!-- <span class="highlight">asdfasdf</span> -->
      </li>
</template>>

<script>
    import tools from '@/components/lib/tools';

    export default {
        name: 'TreeViewItem',

        // =============== Props ===============
        props: {
            item: Object,
            itemSelected: Object,
            selectedContextItem: Object,
            filter: String,
            displayEmptyGroups: { type:Boolean, default: () => { return false }},
            folderStyle: String,
            selectedStyle: String,
            selectedContextStyle: String,
        },

        // =============== Data ===============
        data() {
            return {
                isOpenManually: false,
            };
        },

        // =============== Computed ===============
        computed: {
            isSelected() { return JSON.stringify(this.item) == JSON.stringify(this.itemSelected) },
            isSelectedContext() { return JSON.stringify(this.item) === JSON.stringify(this.selectedContextItem) },
            isGroup() { return this.item.isGroup || (this.item.children && this.item.children.length) },
            filterLocal() { return this.filter },
            isOpenOnSearch() { return !tools.isStringEmpty(this.filter) && this.item.children.length > 0 },
            isOpen() { return this.isOpenManually || this.isOpenOnSearch },
            itemText() {
                if (tools.isStringEmpty(this.filterLocal)) return this.item.name;
                return this.item.name.replace(new RegExp(this.filterLocal, "gi"), match => {
                    return `<span class="tree-view__highlight">${match}</span>`;
                });
            },
        },

        // =============== Methods ===============
        methods: {
            selectable(item) {
                return item.isSelectable || typeof item.isSelectable == 'undefined';
            },

            toggle() {
                if (this.isGroup) {
                    this.isOpenManually = !this.isOpenManually;
                }
            },

            select() {
                if (this.selectable(this.item)) {
                    this.$emit("select", this.item);
                    if (this.isGroup) {
                        this.isOpenManually = true;
                    }
                } else {
                    this.toggle();
                }
            },

            doubleClick() {
                this.$emit("double-click", this.item);
            },

            expandAll() {
                this.isOpenManually = true;
                if (this.$refs.items) {
                    for (let item of this.$refs.items) {
                        item.expandAll();
                    }
                }
            },
            collapseAll() {
                this.isOpenManually = false;
                if (this.$refs.items) {
                    for (let item of this.$refs.items) {
                        item.collapseAll();
                    }
                }
            },
        }
    }
</script>>

<style>
    .tree-view__item {
        cursor: pointer;
    }
    /* .tree-view__folder {
        font-weight: bold;
    } */
    ul.tree-view__ul {
        padding-left: 1rem;
        margin-left: 0;
    }
    .tree-view__li {
        margin-top: -0.15rem;
    }
    .tree-view__li i.fa {
        margin-top: 0.25rem;
    }
    .tree-view__li .folder {
        font-size: 1.2rem;
    }
    .tree-view__li .tree-view__item {
        font-size: 0.875rem;
    }
    .tree-view__highlight {
        background: yellow;
    }
</style>