<!--
    Author: Eduard Grebenyukov
    Date: Date: 2020-02-02
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <div id="page-content">
            <Grid ref="grid" :key="$route.fullPath" :config="gridConfig" @add-item="addItem" @edit-item="editItem($event.id)" @show-item="showItem($event.id)" />
        </div>
    </TemplatePageStandard>
</template>

<script>
    import { mapActions } from 'vuex';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'Clients',

        components: { TemplatePageStandard, Grid },
        // props: ['config'],

        // =============== Data ===============
        data() {
            return {
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.clients'), to: '/clients' },
                ],
                gridConfig: {
                    apiUrl: 'v_client',
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        // { type:'icon', key:'icon', sortable:false, title:this.$t('MainMenu.clients-visits'), icon:'fas fa-id-card fa-fw', align:'center', $onClick:() => {
                        //     this.$router.push({ name: 'client-visits', params: { clientId: null } });
                        // } },
                        // {
                        //     type:'icon', key:'icon',
                        //     header:this.$t('MainMenu.clients-visits'), title:this.$t('MainMenu.clients-visits'),
                        //     sortable:false, icon:'fas fa-id-card fa-fw',
                        //     headStyle:'text-align:center;', align:'center',
                        //     emit:'show-item',
                        // },
                        { type:'string', key:'code', header:this.$t('attribute.common.code') },
                        // { type:'string', key:'fullname', header:this.$t('object.core_client') },
                        {
                            type:'string', key:'fullname',
                            header:this.$t('object.core_client'),
                            title:this.$t('MainMenu.clients-visits'),
                            style:'color: #056bbf;',
                            emit:'show-item',
                        },
                        { type:'select', key:'sex', header:this.$t('attribute.core_person.sex'), source: this.$store.getters.sexKind, keyId: 'code', keyLabel: 'name_short' },
                        { type:'date', key:'dob', header:this.$t('attribute.core_person.dob') },
                        { type:'string', key:'phones', header:this.$t('Contacts.phones') },
                        { type:'string', hidden:true, key:'phones_stripped', header:this.$t('Contacts.phones') }, // hidden column for search
                        { type:'string', key:'social_number', header:this.$t('attribute.core_person.social_number') },
                        { type:'string', key:'upn', header:this.$t('attribute.core_person.upn') },
                    ],
                    order: [ 'fullname' ],
                    displayToolbar: true,
                    saveSessionState: `${this.$options.name}`,
                    buttons: [ 'add', 'edit' ],
                }
            }
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['setLastClientId']),

            addItem() {
                this.$router.push({ name: 'client-new', params: { id: null } });
            },
            async editItem(id) {
                // console.log(`DEBUG: ${this.$options.name}.editItem(${id})`);
                await this.setLastClientId(id);
                this.$router.push({ name: 'client-edit', params: { id } });
            },
            async showItem(id) {
                // console.log(`DEBUG: ${this.$options.name}.showItem(${id})`);
                await this.setLastClientId(id);
                this.$router.push({ name: 'client-visits', params: { clientId: id } });
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
