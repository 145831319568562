<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-13
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
        </template>

        <VisitDescription />

        <VisitTabs :clientId="clientId" :visitId="visitId" currentTab="visit-anamnesis-vitae" />

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm-12 col-xl-8">
                    <form>
                        <StandardRegion :title="$t('object.med_blood_type')">
                            <template #header>
                                <ButtonCustom
                                    :title="$t('lib.buttons.change')"
                                    buttonClass="btn-warning btn-sm ml-1"
                                    iconClass="fas fa-pencil-alt fa-fw"
                                    @click="isDisplayModalBloodType = true"
                                />
                            </template>
                            <div v-if="bloodTypeRec" class="m-2">
                                <div class="form-row">
                                    <div class="col">
                                        <FieldSelect
                                            :label="$t('attribute.med_blood_type.blood_type_id')"
                                            :value.sync="bloodTypeRec.blood_type_id"
                                            source="enum/blood_type"
                                            labelPosition="above"
                                            readonly
                                        />
                                    </div>
                                    <div class="col">
                                        <FieldSelect
                                            :label="$t('attribute.med_blood_type.rh_id')"
                                            :value.sync="bloodTypeRec.rh_id"
                                            source="enum/blood_rh"
                                            labelPosition="above"
                                            readonly
                                        />
                                    </div>
                                    <div class="col">
                                        <FieldSelect
                                            :label="$t('attribute.med_blood_type.kell_id')"
                                            :value.sync="bloodTypeRec.kell_id"
                                            source="enum/blood_kell"
                                            labelPosition="above"
                                            readonly
                                        />
                                    </div>
                                </div>
                                <div v-if="bloodTypeRec.blood_commentary" class="form-row">
                                    <div class="col">
                                        <FieldText
                                            :label="$t('attribute.med_blood_type.blood_commentary')"
                                            :value.sync="bloodTypeRec.blood_commentary"
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div v-if="!bloodTypeRec" class="m-2">
                                <div class="form-row">
                                    <div class="col">
                                        {{ $t('component.AnamnesisVitae.isBloodTypeNotDefined') }}
                                    </div>
                                </div>
                            </div>
                        </StandardRegion>

                        <StandardRegion :title="$t('attribute.med_anamnesis_vitae.is_allergy')">
                            <template #header>
                                <FieldSwitch
                                    id="is_allergy"
                                    labelPosition="after"
                                    size="small"
                                    :value.sync="editFormData.is_allergy"
                                    :disabled="allergyVisitId === visitId && ($refs.allergyGrid.getTotalRowsQty() > 0) && editFormData.is_allergy"
                                    @modified="itemModified()"
                                />
                                <span v-if="!editFormData.is_allergy" class="anamnesis-vitae__absent">
                                    {{ $t('component.AnamnesisVitae.isAbsent') }}
                                </span>
                            </template>
                            <div v-show="editFormData.is_allergy" class="m-2">
                                <Grid
                                    ref="allergyGrid"
                                    :config="allergyGridConfig"
                                    @refreshed="gridRefreshed"
                                    @modified="gridModified($event)"
                                    @saved="gridSaved"
                                    @deleted="gridSaved"
                                    @focus="gridFocused($event)"
                                />
                            </div>
                        </StandardRegion>

                        <StandardRegion :title="$t('attribute.med_anamnesis_vitae.is_infection_diseases')">
                            <template #header>
                                <FieldSwitch
                                    id="is_infection_diseases"
                                    labelPosition="after"
                                    size="small"
                                    :value.sync="editFormData.is_infection_diseases"
                                    :disabled="
                                        editFormData.is_tb || !!editFormData.tb_text ||
                                        editFormData.is_hiv || !!editFormData.hiv_text ||
                                        editFormData.is_hepatitis_b || !!editFormData.hepatitis_b_text ||
                                        editFormData.is_hepatitis_c || !!editFormData.hepatitis_c_text ||
                                        editFormData.is_covid || !!editFormData.covid_date_positive || !!editFormData.covid_date_negative ||
                                        editFormData.is_other_infection || !!editFormData.other_infection_text
                                    "
                                    @modified="itemModified()"
                                />
                                <span v-if="!editFormData.is_infection_diseases" class="anamnesis-vitae__absent">
                                    {{ $t('component.AnamnesisVitae.isAbsent') }}
                                </span>
                            </template>
                            <div v-if="editFormData.is_infection_diseases" class="m-2">
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_tb'
                                            :label="$t('attribute.med_anamnesis_vitae.is_tb')"
                                            :value.sync="editFormData.is_tb"
                                            labelPosition="before"
                                            :disabled="!!editFormData.tb_text"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldString
                                            v-if="editFormData.is_tb"
                                            :value.sync="editFormData.tb_text"
                                            labelPosition="none"
                                            @focus="itemFocused('tb_text', 'med_anamnesis_vitae.is_tb')"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_hiv'
                                            :label="$t('attribute.med_anamnesis_vitae.is_hiv')"
                                            :value.sync="editFormData.is_hiv"
                                            labelPosition="before"
                                            :disabled="!!editFormData.hiv_text"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldString
                                            v-if="editFormData.is_hiv"
                                            :value.sync="editFormData.hiv_text"
                                            labelPosition="none"
                                            @focus="itemFocused('hiv_text', 'med_anamnesis_vitae.is_hiv')"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_hepatitis_b'
                                            :label="$t('attribute.med_anamnesis_vitae.is_hepatitis_b')"
                                            :value.sync="editFormData.is_hepatitis_b"
                                            labelPosition="before"
                                            :disabled="!!editFormData.hepatitis_b_text"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldString
                                            v-if="editFormData.is_hepatitis_b"
                                            :value.sync="editFormData.hepatitis_b_text"
                                            labelPosition="none"
                                            @focus="itemFocused('hepatitis_b_text', 'med_anamnesis_vitae.is_hepatitis_b')"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_hepatitis_c'
                                            :label="$t('attribute.med_anamnesis_vitae.is_hepatitis_c')"
                                            :value.sync="editFormData.is_hepatitis_c"
                                            labelPosition="before"
                                            :disabled="!!editFormData.hepatitis_c_text"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldString
                                            v-if="editFormData.is_hepatitis_c"
                                            :value.sync="editFormData.hepatitis_c_text"
                                            labelPosition="none"
                                            @focus="itemFocused('hepatitis_c_text', 'med_anamnesis_vitae.is_hepatitis_c')"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_covid'
                                            :label="$t('attribute.med_anamnesis_vitae.is_covid')"
                                            :value.sync="editFormData.is_covid"
                                            labelPosition="before"
                                            :disabled="!!editFormData.covid_date_positive || !!editFormData.covid_date_negative"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-4 my-auto">
                                        <FieldDate
                                            v-if="editFormData.is_covid"
                                            :label="$t('attribute.med_anamnesis_vitae.covid_date_positive')"
                                            :value.sync="editFormData.covid_date_positive"
                                            labelPosition="above"
                                            @focus="itemFocused(null, null)"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-4 my-auto">
                                        <FieldDate
                                            v-if="editFormData.is_covid"
                                            :label="$t('attribute.med_anamnesis_vitae.covid_date_negative')"
                                            :value.sync="editFormData.covid_date_negative"
                                            labelPosition="above"
                                            @focus="itemFocused(null, null)"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_other_infection'
                                            :label="$t('attribute.med_anamnesis_vitae.is_other_infection')"
                                            :value.sync="editFormData.is_other_infection"
                                            labelPosition="before"
                                            :disabled="!!editFormData.other_infection_text"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldString
                                            v-if="editFormData.is_other_infection"
                                            :value.sync="editFormData.other_infection_text"
                                            labelPosition="none"
                                            @focus="itemFocused('other_infection_text', 'med_anamnesis_vitae.is_other_infection')"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                            </div>
                        </StandardRegion>

                        <StandardRegion :title="$t('attribute.med_anamnesis_vitae.is_chronic_diseases')">
                            <template #header>
                                <FieldSwitch
                                    id="is_chronic_diseases"
                                    labelPosition="after"
                                    size="small"
                                    :disabled="
                                        editFormData.is_diabetes || !!editFormData.diabetes_type_id || editFormData.is_receives_insulin ||
                                        editFormData.is_heart_violations || !!editFormData.heart_violations_text ||
                                        editFormData.is_cardiac_ischemia ||
                                        editFormData.is_hypertension ||
                                        editFormData.is_ulcers ||
                                        editFormData.is_malignant_neoplasm || !!editFormData.malignant_neoplasm_text
                                    "
                                    :value.sync="editFormData.is_chronic_diseases"
                                    @modified="itemModified()"
                                />
                                <span v-if="!editFormData.is_chronic_diseases" class="anamnesis-vitae__absent">
                                    {{ $t('component.AnamnesisVitae.isAbsent') }}
                                </span>
                            </template>
                            <div v-if="editFormData.is_chronic_diseases" class="m-2">
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_diabetes'
                                            :label="$t('attribute.med_anamnesis_vitae.is_diabetes')"
                                            :value.sync="editFormData.is_diabetes"
                                            labelPosition="before"
                                            :disabled="!!editFormData.diabetes_type_id || !!editFormData.is_receives_insulin"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldRadiobutton
                                            v-if="editFormData.is_diabetes"
                                            :value.sync="editFormData.diabetes_type_id"
                                            source="enum/diabetes_type"
                                            labelPosition="none"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldCheckbox
                                            v-if="editFormData.is_diabetes"
                                            id='is_receives_insulin'
                                            :label="$t('attribute.med_anamnesis_vitae.is_receives_insulin')"
                                            :value.sync="editFormData.is_receives_insulin"
                                            labelPosition="before"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_heart_violations'
                                            :label="$t('attribute.med_anamnesis_vitae.is_heart_violations')"
                                            :value.sync="editFormData.is_heart_violations"
                                            labelPosition="before"
                                            :disabled="!!editFormData.heart_violations_text"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldString
                                            v-if="editFormData.is_heart_violations"
                                            :value.sync="editFormData.heart_violations_text"
                                            labelPosition="none"
                                            @focus="itemFocused('heart_violations_text', 'med_anamnesis_vitae.is_heart_violations')"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_cardiac_ischemia'
                                            :label="$t('attribute.med_anamnesis_vitae.is_cardiac_ischemia')"
                                            :value.sync="editFormData.is_cardiac_ischemia"
                                            labelPosition="before"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_hypertension'
                                            :label="$t('attribute.med_anamnesis_vitae.is_hypertension')"
                                            :value.sync="editFormData.is_hypertension"
                                            labelPosition="before"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_ulcers'
                                            :label="$t('attribute.med_anamnesis_vitae.is_ulcers')"
                                            :value.sync="editFormData.is_ulcers"
                                            labelPosition="before"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_malignant_neoplasm'
                                            :label="$t('attribute.med_anamnesis_vitae.is_malignant_neoplasm')"
                                            :value.sync="editFormData.is_malignant_neoplasm"
                                            labelPosition="before"
                                            :disabled="!!editFormData.malignant_neoplasm_text"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldString
                                            v-if="editFormData.is_malignant_neoplasm"
                                            :value.sync="editFormData.malignant_neoplasm_text"
                                            labelPosition="none"
                                            @focus="itemFocused('malignant_neoplasm_text', 'med_anamnesis_vitae.is_malignant_neoplasm')"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                            </div>
                        </StandardRegion>

                        <StandardRegion :title="$t('component.AnamnesisVitae.badHabits')">
                            <template #header>
                                <FieldSwitch
                                    id="is_bad_habits"
                                    labelPosition="after"
                                    size="small"
                                    :disabled="
                                        editFormData.is_smoking ||
                                        editFormData.is_alcohol || !!editFormData.alcohol_text ||
                                        editFormData.is_narcotics || !!editFormData.narcotics_text
                                    "
                                    :value.sync="editFormData.is_bad_habits"
                                    @modified="itemModified()"
                                />
                                <span v-if="!editFormData.is_bad_habits" class="anamnesis-vitae__absent">
                                    {{ $t('component.AnamnesisVitae.isAbsent') }}
                                </span>
                            </template>
                            <div v-if="editFormData.is_bad_habits" class="m-2">
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_smoking'
                                            :label="$t('attribute.med_anamnesis_vitae.is_smoking')"
                                            :value.sync="editFormData.is_smoking"
                                            labelPosition="before"
                                            :disabled="!!editFormData.smoking_years || !!editFormData.smoking_per_day"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldNumber
                                            v-if="editFormData.is_smoking"
                                            :label="$t('attribute.med_anamnesis_vitae.smoking_years')"
                                            :value.sync="editFormData.smoking_years"
                                            @modified="itemModified()"
                                            @focus="itemFocused(null, null)"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldNumber
                                            v-if="editFormData.is_smoking"
                                            :label="$t('attribute.med_anamnesis_vitae.smoking_per_day')"
                                            :value.sync="editFormData.smoking_per_day"
                                            @modified="itemModified()"
                                            @focus="itemFocused(null, null)"
                                        />
                                    </div>
                                </div>
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_alcohol'
                                            :label="$t('attribute.med_anamnesis_vitae.is_alcohol')"
                                            :value.sync="editFormData.is_alcohol"
                                            labelPosition="before"
                                            :disabled="!!editFormData.alcohol_text"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldString
                                            v-if="editFormData.is_alcohol"
                                            :value.sync="editFormData.alcohol_text"
                                            labelPosition="none"
                                            @focus="itemFocused('alcohol_text', 'med_anamnesis_vitae.is_alcohol')"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row anamnesis-vitae__region-row">
                                    <div class="col-4 my-auto">
                                        <FieldCheckbox
                                            id='is_narcotics'
                                            :label="$t('attribute.med_anamnesis_vitae.is_narcotics')"
                                            :value.sync="editFormData.is_narcotics"
                                            labelPosition="before"
                                            :disabled="!!editFormData.narcotics_text"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                    <div class="col my-auto">
                                        <FieldString
                                            v-if="editFormData.is_narcotics"
                                            :value.sync="editFormData.narcotics_text"
                                            labelPosition="none"
                                            @focus="itemFocused('narcotics_text', 'med_anamnesis_vitae.is_narcotics')"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                            </div>
                        </StandardRegion>

                        <StandardRegion :title="$t('attribute.med_anamnesis_vitae.family_anamnesis')">
                            <template #header>
                                <FieldSwitch
                                    id="is_family_anamnesis"
                                    labelPosition="after"
                                    size="small"
                                    :disabled="
                                        !!editFormData.family_anamnesis ||
                                        editFormData.is_kin_ovarian_breast_cancer ||
                                        editFormData.is_kin_depression_mental ||
                                        editFormData.is_kin_glaucoma ||
                                        !!editFormData.kin_other_oncology ||
                                        !!editFormData.kin_cardiovascular
                                    "
                                    :value.sync="editFormData.is_family_anamnesis"
                                    @modified="itemModified()"
                                />
                                <span v-if="!editFormData.is_family_anamnesis" class="anamnesis-vitae__absent">
                                    {{ $t('component.AnamnesisVitae.isNotBurdened') }}
                                </span>
                            </template>
                            <div v-if="editFormData.is_family_anamnesis" class="m-2">
                                <div class="form-row">
                                    <div class="col">
                                        <FieldText
                                            :label="$t('attribute.med_anamnesis_vitae.family_anamnesis')"
                                            labelPosition="none"
                                            :value.sync="editFormData.family_anamnesis"
                                            @focus="itemFocused('family_anamnesis', 'med_anamnesis_vitae.family_anamnesis')"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <FieldCheckbox
                                            id='is_kin_ovarian_breast_cancer'
                                            :label="$t('attribute.med_anamnesis_vitae.is_kin_ovarian_breast_cancer')"
                                            :value.sync="editFormData.is_kin_ovarian_breast_cancer"
                                            labelPosition="before"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <FieldCheckbox
                                            id='is_kin_depression_mental'
                                            :label="$t('attribute.med_anamnesis_vitae.is_kin_depression_mental')"
                                            :value.sync="editFormData.is_kin_depression_mental"
                                            labelPosition="before"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <FieldCheckbox
                                            id='is_kin_glaucoma'
                                            :label="$t('attribute.med_anamnesis_vitae.is_kin_glaucoma')"
                                            :value.sync="editFormData.is_kin_glaucoma"
                                            labelPosition="before"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <FieldString
                                            :label="$t('attribute.med_anamnesis_vitae.kin_other_oncology')"
                                            :value.sync="editFormData.kin_other_oncology"
                                            labelPosition="above"
                                            style="width:100%"
                                            @focus="itemFocused('kin_other_oncology', 'med_anamnesis_vitae.kin_other_oncology')"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <FieldString
                                            :label="$t('attribute.med_anamnesis_vitae.kin_cardiovascular')"
                                            :value.sync="editFormData.kin_cardiovascular"
                                            labelPosition="above"
                                            style="width:100%"
                                            @focus="itemFocused('kin_cardiovascular', 'med_anamnesis_vitae.kin_cardiovascular')"
                                            @modified="itemModified()"
                                        />
                                    </div>
                                </div>
                            </div>
                        </StandardRegion>

                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_anamnesis_vitae.development')"
                                    :value.sync="editFormData.development"
                                    @focus="itemFocused('development', 'med_anamnesis_vitae.development')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_anamnesis_vitae.diseases_and_injuries')"
                                    :value.sync="editFormData.diseases_and_injuries"
                                    @focus="itemFocused('diseases_and_injuries', 'med_anamnesis_vitae.diseases_and_injuries')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_anamnesis_vitae.life_conditions')"
                                    :value.sync="editFormData.life_conditions"
                                    @focus="itemFocused('life_conditions', 'med_anamnesis_vitae.life_conditions')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_anamnesis_vitae.surgeries')"
                                    :value.sync="editFormData.surgeries"
                                    @focus="itemFocused('surgeries', 'med_anamnesis_vitae.surgeries')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_anamnesis_vitae.working_conditions')"
                                    :value.sync="editFormData.working_conditions"
                                    @focus="itemFocused('working_conditions', 'med_anamnesis_vitae.working_conditions')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_anamnesis_vitae.medicines')"
                                    :value.sync="editFormData.medicines"
                                    @focus="itemFocused('medicines', 'med_anamnesis_vitae.medicines')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_anamnesis_vitae.urological_anamnesis')"
                                    :value.sync="editFormData.urological_anamnesis"
                                    @focus="itemFocused('urological_anamnesis', 'med_anamnesis_vitae.urological_anamnesis')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>

                        <div v-if="clientRec.sex === 'f'" class="form-row">
                            <div class="col-sm">
                                <FieldText
                                    :label="$t('attribute.med_anamnesis_vitae.gynecological_anamnesis')"
                                    :value.sync="editFormData.gynecological_anamnesis"
                                    @focus="itemFocused('gynecological_anamnesis', 'med_anamnesis_vitae.gynecological_anamnesis')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div v-if="clientRec.sex === 'f'" class="form-row">
                            <div class="col my-auto">
                                <FieldNumber
                                    :label="$t('attribute.med_anamnesis_vitae.menopause_since')"
                                    labelPosition="before"
                                    :value.sync="editFormData.menopause_since"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldString
                                    :label="$t('attribute.med_anamnesis_vitae.blood_transfusion_anamnesis')"
                                    :value.sync="editFormData.blood_transfusion_anamnesis"
                                    labelPosition="above"
                                    style="width:100%"
                                    @focus="itemFocused('blood_transfusion_anamnesis', 'med_anamnesis_vitae.blood_transfusion_anamnesis')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <FieldString
                                    :label="$t('attribute.med_anamnesis_vitae.implants')"
                                    :value.sync="editFormData.implants"
                                    labelPosition="above"
                                    style="width:100%"
                                    @focus="itemFocused('implants', 'med_anamnesis_vitae.implants')"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-sm-12 col-xl-4">
                    <!-- Right sidebar -->
                        <VisitFormTemplate
                            :title="$t(currentAttribute ? `attribute.${currentAttribute}` : 'Templates.templates')"
                            :visitTypeId="visitRec.visit_type_id"
                            :specialityId="visitRec.core_speciality_id"
                            :formItemName="currentFormItem"
                            :attributeName="currentAttribute"
                            :formData.sync="currentTemplateData"
                            @modified="templatePicked()"
                        />
                </div>
            </div>
        </div>

        <ClientBloodTypeEdit
            v-if="isDisplayModalBloodType"
            :clientId="clientId"
            @close-modal="closeModalBloodTypeEdit($event)"
        />

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';
    // import { mapActions } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';
    import VisitFormTemplate from '@/components/pages/visits/VisitFormTemplate';
    import StandardRegion from '@/components/lib/regions/StandardRegion';
    import Grid from '@/components/lib/regions/Grid';
    import ClientBloodTypeEdit from './ClientBloodTypeEdit';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import FieldCheckbox from '@/components/lib/controls/FieldCheckbox';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldRadiobutton from '@/components/lib/controls/FieldRadiobutton';
    import FieldText from '@/components/lib/controls/FieldText';

    export default {
        name: 'AnamnesisVitae',

        components: {
            TemplatePageStandard, VisitDescription, VisitTabs, VisitFormTemplate, StandardRegion, Grid, ClientBloodTypeEdit,
            ButtonClose, ButtonSave, ButtonCustom, //ButtonPrint,
            FieldSelect, FieldSwitch, FieldCheckbox, FieldString, FieldNumber, FieldText, FieldDate, FieldRadiobutton,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                itemId: undefined,
                buttons: [],
                visitRec: {},
                clientRec: {},
                bloodTypeRec: undefined,
                currentFormItem: null,
                currentAttribute: null,
                currentTemplateData: {},
                isAllergyGridModified: false,
                anamnesisVisitId: null,
                allergyVisitId: null,
                isDisplayModalBloodType: false,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.anamnesis-vitae') }),
                editFormData: {
                    // id,
                    visit_rec_id: null,
                    is_allergy: null,
                    is_infection_diseases: false,
                    is_tb: false,
                    tb_text: null,
                    is_hiv: false,
                    hiv_text: null,
                    is_hepatitis_b: false,
                    hepatitis_b_text: null,
                    is_hepatitis_c: false,
                    hepatitis_c_text: null,
                    is_covid: false,
                    covid_date_positive: null,
                    covid_date_negative: null,
                    is_other_infection: false,
                    other_infection_text: null,
                    is_chronic_diseases: false,
                    is_diabetes: false,
                    diabetes_type_id: null,
                    is_receives_insulin: false,
                    is_heart_violations: false,
                    heart_violations_text: null,
                    is_cardiac_ischemia: false,
                    is_hypertension: false,
                    is_ulcers: false,
                    is_malignant_neoplasm: false,
                    malignant_neoplasm_text: null,
                    is_bad_habits: false,
                    is_smoking: false,
                    smoking_years: null,
                    smoking_per_day: null,
                    is_alcohol: false,
                    alcohol_text: null,
                    is_narcotics: false,
                    narcotics_text: null,
                    development: null,
                    diseases_and_injuries: null,
                    life_conditions: null,
                    surgeries: null,
                    working_conditions: null,
                    medicines: null,
                    urological_anamnesis: null,
                    gynecological_anamnesis: null,
                    menopause_since: null,
                    family_anamnesis: null,
                    is_kin_ovarian_breast_cancer: null,
                    is_kin_depression_mental: null,
                    is_kin_glaucoma: null,
                    kin_other_oncology: null,
                    kin_cardiovascular: null,
                    blood_transfusion_anamnesis: null,
                    implants: null,
                    commentary: null,
                },
                oldFormData: {},
                allergyGridConfig: {
                    apiUrl: null,
                    filter: null,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'visit_rec_id', header:"visit_rec_id", required:true },
                        { type:'string', key:'substance', header:this.$t('attribute.med_allergy.substance') },
                        { type:'string', key:'reaction', header:this.$t('attribute.med_allergy.reaction') },
                    ],
                    order: [ 'substance' ],
                    displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            isFormModified() { return !tools.isEqual(this.oldFormData, this.editFormData) },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close', 'print'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            checkModified() {
                // console.log(`DEBUG: ${this.$options.name}.checkModified(${this.isFormModified}, ${this.isAllergyGridModified})`);
                // console.log(`DEBUG: ${this.$options.name}.checkModified() this.oldFormData=`, this.oldFormData);
                // console.log(`DEBUG: ${this.$options.name}.checkModified() this.editFormData=`, this.editFormData);
                this.isModified = this.isFormModified || this.isAllergyGridModified;
            },

            checkGridReadonly() {
                // console.log(`DEBUG: ${this.$options.name}.checkGridReadonly(${this.allergyVisitId}, ${this.visitId})`);
                if (this.allergyVisitId === this.visitId) {
                    this.allergyGridConfig.buttons = [ 'row-selector', 'add', 'delete' ];
                    this.allergyGridConfig.columns.find(x => x.key === 'substance').readonly = false;
                    this.allergyGridConfig.columns.find(x => x.key === 'reaction').readonly = false;
                    // this.allergyGridConfig = tools.deepCopy(this.allergyGridConfig);
                } else {
                    this.allergyGridConfig.buttons = [ 'add' ];
                    this.allergyGridConfig.columns.find(x => x.key === 'substance').readonly = true;
                    this.allergyGridConfig.columns.find(x => x.key === 'reaction').readonly = true;
                    // this.allergyGridConfig = tools.deepCopy(this.allergyGridConfig);
                }
            },

            checkIsAllergy() {
                // console.log(`DEBUG: ${this.$options.name}.checkIsAllergy() rowsQty=${this.$refs.allergyGrid.getTotalRowsQty()}`);
                // console.log(`DEBUG: ${this.$options.name}.checkIsAllergy() this.editFormData.id=`, this.editFormData.id);
                // console.log(`DEBUG: ${this.$options.name}.checkIsAllergy() this.allergyVisitId=`, this.allergyVisitId);
                // console.log(`DEBUG: ${this.$options.name}.checkIsAllergy() this.visitId=`, this.visitId);
                if ((!this.editFormData.id) && (!this.editFormData.is_allergy) && this.$refs.allergyGrid.getTotalRowsQty() > 0) {
                    setTimeout(() => {
                        this.editFormData.is_allergy = true;
                        // this.oldFormData.is_allergy = this.editFormData.is_allergy;
                        // console.log(`DEBUG: ${this.$options.name}.checkIsAllergy() 111`);
                        this.itemModified();
                    }, 0);
                }
                if (this.editFormData.id && this.allergyVisitId === this.visitId && this.$refs.allergyGrid.getTotalRowsQty() > 0) {
                    setTimeout(() => {
                        this.editFormData.is_allergy = true;
                        // this.oldFormData.is_allergy = this.editFormData.is_allergy;
                        // console.log(`DEBUG: ${this.$options.name}.checkIsAllergy() 222`);
                        this.itemModified();
                    }, 0);
                }
            },

            gridRefreshed() {
                this.checkGridReadonly();
                this.checkIsAllergy();
            },

            async gridSaved() {
                // console.log(`DEBUG: ${this.$options.name}.gridSaved()`);
                await this.getAllergyData(this.visitId);
            },

            gridModified(evt) {
                // console.log(`DEBUG: ${this.$options.name}.gridModified(${evt})`);
                if (evt && this.allergyVisitId != this.visitId) {
                    this.allergyVisitId = this.visitId;
                }
                this.isAllergyGridModified = evt;
                this.checkModified();
                // this.gridRefreshed();
            },

            duplicateGridRows() {
                // console.log(`DEBUG: ${this.$options.name}.duplicateGridRows()`);
                for (let entry of this.$refs.allergyGrid.getAllRows()) {
                    if (!entry.$isDeleted && !entry.$isNew && entry.visit_rec_id != this.visitId) {
                        entry.id = null;
                        entry.visit_rec_id = this.visitId;
                        entry.$isNew = true;
                        entry.$isModified = false;
                    }
                }
                this.$refs.allergyGrid.setModified(true);
                this.isAllergyGridModified = true;
                this.checkModified();
                this.checkGridReadonly();
                this.checkIsAllergy();
                // this.gridModified(true);
            },

            itemModified() {
                this.checkModified();
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.editFormData=`, this.editFormData);
                if (this.isFormModified && this.$refs.allergyGrid && !this.isAllergyGridModified && this.allergyVisitId != this.visitId) {
                    this.allergyVisitId = this.visitId;
                    this.duplicateGridRows();
                }
                if (this.isFormModified && this.allergyVisitId != this.visitId) {
                    this.allergyVisitId = this.visitId;
                }
            },

            templatePicked() {
                if (this.currentTemplateData === this.editFormData) {
                    // picked on form
                    // console.log(`DEBUG: ${this.$options.name}.templatePicked() picked on form`);
                    this.itemModified();
                } else {
                    // picked on grid
                    // console.log(`DEBUG: ${this.$options.name}.templatePicked() picked on grid`);
                    this.$refs.allergyGrid.setModified(true);
                }
            },

            gotAnamnesisData() {
                // this.isModified = false;
                this.itemId = this.editFormData.id;
                this.oldFormData = tools.deepCopy(this.editFormData);

                this.checkModified();
                this.checkButtons();
            },

            async getVisitData() {
                try {
                    this.visitRec = (await this.authenticatedAxios.get(`${this.baseUrl}/visit/${this.visitId}`)).data;
                    this.clientRec = (await this.authenticatedAxios.get(`${this.baseUrl}/v_client/${this.clientId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getVisitData() this.clientRec=`, this.clientRec);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getBloodTypeData() {
                try {
                    let filterString = tools.getFilterString({ where:[{ core_client_id:this.clientId }]});
                    this.bloodTypeRec = (await this.authenticatedAxios.get(`${this.baseUrl}/med_blood_type${filterString}`)).data.rows[0];
                    // this.bloodTypeRec = result ? result : {};
                    // console.log(`DEBUG: ${this.$options.name}.getVisitData() this.bloodTypeRec=`, this.bloodTypeRec);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getAllergyData(visitId) {
                // console.log(`DEBUG: ${this.$options.name}.getAllergyData(${visitId})`);
                // let filterString = tools.getFilterString({ where:[{ visit_rec_id:visitId }]});
                // console.log(`DEBUG: ${this.$options.name}.getAllergyData(${visitId}) filterString=`, filterString);
                // let allergyData = (await this.authenticatedAxios.get(`${this.baseUrl}/med_allergy${filterString}`)).data.rows;
                // console.log(`DEBUG: ${this.$options.name}.getAllergyData(${visitId}) allergyData=`, allergyData);
                this.allergyVisitId = visitId;
                this.allergyGridConfig.filter = { where: [{ visit_rec_id: visitId }]};
                this.allergyGridConfig.columns.find(x => x.key === 'visit_rec_id').defaultValue = this.visitId;
                if (!this.allergyGridConfig.apiUrl) {
                    this.allergyGridConfig.apiUrl = 'med_allergy';
                } else {
                    await this.$refs.allergyGrid.refresh();
                }
            },

            async getAnamnesisData(currentVisit) {
                // console.log(`DEBUG: ${this.$options.name}.getAnamnesisData(id=${currentVisit.id})`);
                try {
                    let anamnesisData = [];
                    let filterString1;
                    if (currentVisit.id) {
                        let filterString = tools.getFilterString({ where:[{ visit_rec_id:currentVisit.id }]});
                        anamnesisData = (await this.authenticatedAxios.get(`${this.baseUrl}/med_anamnesis_vitae${filterString}`)).data.rows[0];
                        // console.log(`DEBUG: ${this.$options.name}.getAnamnesisData(id=${currentVisit.id}) anamnesisData=`, anamnesisData);
                    }
                    if (anamnesisData) {
                        this.anamnesisVisitId = currentVisit.id;
                        this.editFormData = anamnesisData;
                        this.editFormData.visit_rec_id = this.visitId;
                        this.editFormData.id = undefined;
                        // this.gotAnamnesisData();
                        // await this.getAllergyData(currentVisit.id);
                    } else {
                        // get from previous visit
                        if (currentVisit.id) {
                            filterString1 = tools.getFilterString({
                                where:[
                                    { core_client_id: currentVisit.core_client_id },
                                    [ 'time_of', '<', currentVisit.time_of ],
                                ],
                                order: [{ key:'time_of', direction:'desc' }],
                            });
                        } else {
                            // ??? is it possible???
                            filterString1 = tools.getFilterString({
                                where:[
                                    { core_client_id: currentVisit.core_client_id },
                                ],
                                order: [{ key:'time_of', direction:'desc' }],
                            });
                        }
                        let previousVisit = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_rec${filterString1}`)).data.rows[0];
                        if (previousVisit) await this.getAnamnesisData(previousVisit);
                    }
                    this.gotAnamnesisData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isModified=${this.isModified}, this.editFormData=`, this.editFormData);
                if (this.isModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            // console.log(`DEBUG: ${this.$options.name}.saveData() Update this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.put(`${this.baseUrl}/med_anamnesis_vitae/${this.editFormData.id}`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                this.isModified = false;
                                tools.displaySuccessSave(this);
                            }
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveData() New this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.post(`${this.baseUrl}/med_anamnesis_vitae`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                                this.gotAnamnesisData();
                                // this.getData();
                                tools.displaySuccessSave(this);
                            }
                        }
                        await this.$refs.allergyGrid.save();
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },

            closeModalBloodTypeEdit(isRefresh) {
                // console.log(`DEBUG: ${this.$options.name}.closeModalBloodTypeEdit()`);
                this.isDisplayModalBloodType = false;
                if (isRefresh) this.getBloodTypeData(); // no need to wait
            },

            itemFocused(item, templateName) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() item=${item}`);
                this.currentTemplateData = this.editFormData;
                this.currentFormItem = item;
                this.currentAttribute = templateName ? templateName : item;
                // this.currentFormItemValue = this.editFormData[this.currentFormItem.split('.')[1]];
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() this.currentFormItemValue=${this.currentFormItemValue}`);
            },

            gridFocused(evt) {
                // console.log(`DEBUG: ${this.$options.name}.gridFocused() evt=`, evt);
                this.currentTemplateData = evt.entry;
                this.currentFormItem = evt.key;
                this.currentAttribute = evt.attribute;
                // this.currentFormItem = null;
                // this.currentAttribute = null;
            },
        },

        // =============== Life cycle ===============
        beforeMount() {
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            this.editFormData.visit_rec_id = this.visitId;
            this.oldFormData = tools.deepCopy(this.editFormData); // to avoid blinking
        },

        async mounted() {
            if (this.visitId) {
                await this.getVisitData();
                this.getBloodTypeData(); // no need to wait
                await this.getAnamnesisData(this.visitRec);
                await this.getAllergyData(this.anamnesisVisitId || this.visitId);
            } else {
                this.checkButtons();
            }
            this.oldFormData = tools.deepCopy(this.editFormData);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.clientId=${this.clientId}, this.visitId=${this.visitId}`);
        },
    }
</script>

<style>
    .anamnesis-vitae__absent {
        margin-top: auto;
        margin-bottom: auto;
    }
    .anamnesis-vitae__region-row {
        display: flex;
        /* margin-top: 0.5rem; */
        min-height: calc(1.5em + .75rem + 2px + 1rem); /* from bootstrap + 1rem*/
    }
</style>
