<!--
    Author: Eduard Grebenyukov
    Date: 2021-05-24
-->

<template>
    <ModalWindow
        width="90%"
        max-width="800px"
        :buttons="buttons"
        @cancel="closeModal"
        @save="savePassword"
    >
        <span slot="header">{{ $t('component.UserChangePassword.title') }}</span>

        <form class="container" novalidate>
            <div class="row">
                <div class="col-12-sm col-md">
                    <FieldPassword
                        :label="$t('component.UserChangePassword.password_old')"
                        :value.sync="editFormData.password_old"
                        :errorMessage="msgInvalidOldPassword"
                        required
                    />
                </div>
                <div class="col-12-sm col-md">
                    <FieldPassword
                        :label="$t('component.UserChangePassword.password_new')"
                        :value.sync="editFormData.password_new"
                        :errorMessage="msgInvalidNewPassword"
                        required
                    />
                </div>
                <div class="col-12-sm col-md">
                    <FieldPassword
                        :label="$t('component.UserChangePassword.password_new2')"
                        :value.sync="editFormData.password_new2"
                        :class="{ isInvalid: !isSecondPasswordsMatch }"
                        :errorMessage="msgInvalidSecondPassword"
                        required
                    />
                </div>
            </div>
        </form>

        <div v-if="msgPasswordChangeError" class="alert alert-danger" role="alert">
            {{ $t('component.UserChangePassword.passwordChangeError') }}
        </div>
    </ModalWindow>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import FieldPassword from '@/components/lib/controls/FieldPassword';

    export default {
        name: 'UserChangePassword',

        components: { ModalWindow, FieldPassword },

        // =============== Data ===============
        data() {
            return {
                editFormData: {
                    password_old: null,
                    password_new: null,
                    password_new2: null,
                },
                isOldPasswordMismatch: false,
                msgPasswordChangeError: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),

            buttons() {
                let buttonSave = { key:'button_save', event:'save', class:"btn btn-sm btn-primary ml-1", label:this.$t('lib.buttons.save') };
                // if (!this.isSaveEnabled) buttonSave.class += ' disabled';
                if (!this.isSaveEnabled) buttonSave.disabled = true;
                return [buttonSave, 'close'];
            },

            isOldPasswordFilled() {
                return this.editFormData.password_old && this.editFormData.password_old.length > 0;
            },

            isNewPasswordFilled() {
                return this.editFormData.password_new && this.editFormData.password_new.length >= 8;
            },

            isSecondPasswordsMatch() {
                return this.editFormData.password_new === this.editFormData.password_new2;
            },

            msgInvalidOldPassword() {
                if (this.isOldPasswordFilled) {
                    if (this.isOldPasswordMismatch) {
                        return this.$t('component.UserChangePassword.oldPasswordMismatch');
                    } else {
                        return null;
                    }
                } else {
                    return this.$t('component.UserChangePassword.passwordIsEmpty');
                }
            },
            msgInvalidNewPassword() {
                if (this.isNewPasswordFilled) {
                    return null;
                } else {
                    return this.$t('component.UserChangePassword.passwordTooShort');
                }
            },
            msgInvalidSecondPassword() {
                if (this.isSecondPasswordsMatch) {
                    return null;
                } else {
                    return this.$t('component.UserChangePassword.passwordsMismatch');
                }
            },

            isSaveEnabled() {
                return (
                    this.isOldPasswordFilled && 
                    this.isNewPasswordFilled && 
                    this.isSecondPasswordsMatch
                );
            },
        },

        // =============== Methods ===============
        methods: {
            async checkOldPassword() {
                if (this.isOldPasswordFilled) {
                    try {
                        let response = await this.authenticatedAxios.post(`${this.baseUrl}/auth/check_password`, { password_old: this.editFormData.password_old });
                        // console.log(`DEBUG: ${this.$options.name}.getData data=`, response.data);
                        if (response.data.success) {
                            return true;
                        } else {
                            // tools.displayError(this, this.$t('component.UserChangePassword.oldPasswordMismatch'));
                            this.isOldPasswordMismatch = this.$t('component.UserChangePassword.oldPasswordMismatch');
                        }
                    } catch (err) {
                        tools.displayApiError(this.$parent, err);
                    }
                }
                return false;
            },

            async savePassword() {
                if (await this.checkOldPassword()) {
                    try {
                        let response = await this.authenticatedAxios.post(`${this.baseUrl}/auth/change_password`, this.editFormData);
                        if (response.data.success) {
                            tools.displaySuccessSave(this.$parent, this.$t('component.UserChangePassword.passwordChanged'));
                            this.$emit('close-modal', true);
                        } else {
                            // tools.displayError(this, this.$t('component.UserChangePassword.passwordChangeError'));
                            this.msgPasswordChangeError = this.$t('component.UserChangePassword.passwordChangeError');
                        }
                    } catch (err) {
                        tools.displayApiError(this.$parent, err);
                    }
                }
            },

            closeModal() {
                this.processClose();
            },
            processClose() { this.$emit('close-modal', false) },
        },

        // =============== Life cycle ===============
    }
</script>

<style>
</style>
