<!--
    Author: Eduard Grebenyukov
    Date: 2020-05-01
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" :disabled="!isSaveEnabled" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>

        <VisitDescription />

        <form>
            <div id="page-content" class="form-float">

                <!-- <div class="form-row">
                    <div class="col-sm">
                        <FieldSelect :label="$t('object.fin_service')" :value.sync="editFormData.fin_service_id" readonly required source="fin_service"/>
                    </div>
                </div> -->

                <div class="form-row">
                    <div class="col-sm">
                        <FieldString :label="$t('object.fin_service')" :value="`${finService.code} / ${finService.name}`" readonly required />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-sm">
                        <FieldSelect :label="$t('object.fin_program')" :value.sync="editFormData.fin_program_id" readonly source="fin_program"/>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-sm">
                        <FieldSelect :label="$t('object.fin_vat')" :value.sync="editFormData.fin_vat_id" readonly required source="fin_vat"/>
                    </div>
                    <div class="col-sm">
                        <FieldNumber
                            :label="$t('attribute.common.price')"
                            :value.sync="editFormData.price"
                            @modified="itemModified()"
                            :readonly="!finService.is_price_variable || isPageReadonly"
                            required
                        />
                    </div>
                    <div class="col-sm">
                        <FieldNumber
                            :label="$t('attribute.common.quantity')"
                            :value.sync="editFormData.qty"
                            @modified="itemModified()"
                            :readonly="isPageReadonly"
                            :errorMessage="formErrorMessages.qty"
                            required
                        />
                    </div>
                    <div class="col-sm">
                        <FieldNumber
                            :label="$t('attribute.common.sum_payment')"
                            :value.sync="sumPayment"
                            :readonly="true"
                            required
                        />
                    </div>
                    <div class="col-sm">
                        <FieldNumber
                            :label="$t('attribute.common.sum_paid')"
                            :value.sync="sumPaid"
                            :readonly="true"
                        />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <!-- <div class="btn-toolbar" style="padding: 0 4px 4px 0;" role="toolbar">
                            <div class="h6">{{ $t('object.fin_split') }}</div>
                            <button @click.prevent="add()" class="btn btn-warningy btn-sm ml-2" :title="$t('app.add')">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div> -->
                        <Grid ref="splits" :key="$route.fullPath" :source="splitArray" :config="splitGridConfig" readonly>
                            <template slot="buttons">
                                <button @click.prevent="shiftToProgram()" class="btn btn-sm btn-outline-secondary mr-1">
                                    {{ $t('component.RenderedServiceEdit.shiftToProgram') }}
                                </button>
                                <button @click.prevent="shiftToClient()" class="btn btn-sm btn-outline-secondary mr-1">
                                    {{ $t('component.RenderedServiceEdit.shiftToClient') }}
                                </button>
                            </template>
                        </Grid>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <div class="btn-toolbar" style="padding: 0 4px 4px 0;" role="toolbar">
                            <div class="h6">{{ $t('component.RenderedServiceEdit.discountBid') }}</div>
                            <button @click.prevent="isDisplayDiscountList = true" :disabled="isAddDiscountDisabled" class="btn btn-sm btn-warning ml-1" :title="$t('app.add')">
                                <i class="fas fa-plus"></i>
                            </button>
                            <button @click.prevent="deleteDiscount()" :disabled="isDeleteDiscountDisabled" class="btn btn-sm btn-outline-danger ml-1" :title="$t('app.delete')">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div>
                        <Grid
                            ref="discounts"
                            :key="$route.fullPath"
                            :source="renderDiscountArray"
                            :config="renderDiscountGridConfig"
                            readonly
                            @item-selected="renderDiscountSelected($event)"
                        />
                    </div>
                </div>

                <div v-if="isDental" class="row rendered-services__teeth">
                    <div class="col">
                        <div class="form-row">
                            <div class="col-6 quadrant-5">
                                <div class="d-flex mt-2">
                                    <FieldCheckbox class="ml-auto" label="55" :value.sync="teethData.d55" @modified="itemModified" />
                                    <FieldCheckbox label="54" :value.sync="teethData.d54" @modified="itemModified" />
                                    <FieldCheckbox label="53" :value.sync="teethData.d53" @modified="itemModified" />
                                    <FieldCheckbox label="52" :value.sync="teethData.d52" @modified="itemModified" />
                                    <FieldCheckbox label="51" :value.sync="teethData.d51" @modified="itemModified" />
                                </div>
                            </div>
                            <div class="col-6 quadrant-6">
                                <div class="d-flex mt-2">
                                    <FieldCheckbox label="61" :value.sync="teethData.d61" @modified="itemModified" />
                                    <FieldCheckbox label="62" :value.sync="teethData.d62" @modified="itemModified" />
                                    <FieldCheckbox label="63" :value.sync="teethData.d63" @modified="itemModified" />
                                    <FieldCheckbox label="64" :value.sync="teethData.d64" @modified="itemModified" />
                                    <FieldCheckbox class="mr-auto" label="65" :value.sync="teethData.d65" @modified="itemModified" />
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 quadrant-1">
                                <div class="d-flex mt-2">
                                    <FieldCheckbox class="ml-auto" label="18" :value.sync="teethData.d18" @modified="itemModified" />
                                    <FieldCheckbox label="17" :value.sync="teethData.d17" @modified="itemModified" />
                                    <FieldCheckbox label="16" :value.sync="teethData.d16" @modified="itemModified" />
                                    <FieldCheckbox label="15" :value.sync="teethData.d15" @modified="itemModified" />
                                    <FieldCheckbox label="14" :value.sync="teethData.d14" @modified="itemModified" />
                                    <FieldCheckbox label="13" :value.sync="teethData.d13" @modified="itemModified" />
                                    <FieldCheckbox label="12" :value.sync="teethData.d12" @modified="itemModified" />
                                    <FieldCheckbox label="11" :value.sync="teethData.d11" @modified="itemModified" />
                                </div>
                            </div>
                            <div class="col-6 quadrant-2">
                                <div class="d-flex mt-2">
                                    <FieldCheckbox label="21" :value.sync="teethData.d21" @modified="itemModified" />
                                    <FieldCheckbox label="22" :value.sync="teethData.d22" @modified="itemModified" />
                                    <FieldCheckbox label="23" :value.sync="teethData.d23" @modified="itemModified" />
                                    <FieldCheckbox label="24" :value.sync="teethData.d24" @modified="itemModified" />
                                    <FieldCheckbox label="25" :value.sync="teethData.d25" @modified="itemModified" />
                                    <FieldCheckbox label="26" :value.sync="teethData.d26" @modified="itemModified" />
                                    <FieldCheckbox label="27" :value.sync="teethData.d27" @modified="itemModified" />
                                    <FieldCheckbox class="mr-auto" label="28" :value.sync="teethData.d28" @modified="itemModified" />
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 quadrant-4">
                                <div class="d-flex mt-2">
                                    <FieldCheckbox class="ml-auto" label="48" :value.sync="teethData.d48" @modified="itemModified" />
                                    <FieldCheckbox label="47" :value.sync="teethData.d47" @modified="itemModified" />
                                    <FieldCheckbox label="46" :value.sync="teethData.d46" @modified="itemModified" />
                                    <FieldCheckbox label="45" :value.sync="teethData.d45" @modified="itemModified" />
                                    <FieldCheckbox label="44" :value.sync="teethData.d44" @modified="itemModified" />
                                    <FieldCheckbox label="43" :value.sync="teethData.d43" @modified="itemModified" />
                                    <FieldCheckbox label="42" :value.sync="teethData.d42" @modified="itemModified" />
                                    <FieldCheckbox label="41" :value.sync="teethData.d41" @modified="itemModified" />
                                </div>
                            </div>
                            <div class="col-6 quadrant-3">
                                <div class="d-flex mt-2">
                                    <FieldCheckbox label="31" :value.sync="teethData.d31" @modified="itemModified" />
                                    <FieldCheckbox label="32" :value.sync="teethData.d32" @modified="itemModified" />
                                    <FieldCheckbox label="33" :value.sync="teethData.d33" @modified="itemModified" />
                                    <FieldCheckbox label="34" :value.sync="teethData.d34" @modified="itemModified" />
                                    <FieldCheckbox label="35" :value.sync="teethData.d35" @modified="itemModified" />
                                    <FieldCheckbox label="36" :value.sync="teethData.d36" @modified="itemModified" />
                                    <FieldCheckbox label="37" :value.sync="teethData.d37" @modified="itemModified" />
                                    <FieldCheckbox class="mr-auto" label="38" :value.sync="teethData.d38" @modified="itemModified" />
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 quadrant-8">
                                <div class="d-flex mt-2">
                                    <FieldCheckbox class="ml-auto" label="85" :value.sync="teethData.d85" @modified="itemModified" />
                                    <FieldCheckbox label="84" :value.sync="teethData.d84" @modified="itemModified" />
                                    <FieldCheckbox label="83" :value.sync="teethData.d83" @modified="itemModified" />
                                    <FieldCheckbox label="82" :value.sync="teethData.d82" @modified="itemModified" />
                                    <FieldCheckbox label="81" :value.sync="teethData.d81" @modified="itemModified" />
                                </div>
                            </div>
                            <div class="col-6 quadrant-7">
                                <div class="d-flex mt-2">
                                    <FieldCheckbox label="71" :value.sync="teethData.d71" @modified="itemModified" />
                                    <FieldCheckbox label="72" :value.sync="teethData.d72" @modified="itemModified" />
                                    <FieldCheckbox label="73" :value.sync="teethData.d73" @modified="itemModified" />
                                    <FieldCheckbox label="74" :value.sync="teethData.d74" @modified="itemModified" />
                                    <FieldCheckbox class="mr-auto" label="75" :value.sync="teethData.d75" @modified="itemModified" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </form>

        <ModalWindow
            v-if="isDisplayDiscountList"
            width="600px" max-width="600px" max-height="90vh"
            :header="$t('component.RenderedServiceEdit.discountBid')"
            @cancel="isDisplayDiscountList = false"
        >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="discountList" :config="discountGridConfig" @item-selected="discountSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>

        <ModalWindow
            v-if="isDisplayDiscountInput"
            width="600px" max-width="600px" max-height="90vh"
            :header="$t('component.RenderedServiceEdit.enterDiscountPct')"
            :buttons="[ 'ok', 'cancel' ]"
            @ok="variableDiscountPct ? variableDiscountEntered() : null"
            @cancel="isDisplayDiscountInput = false"
        >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <FieldNumber
                            :label="$t('component.RenderedServiceEdit.discount')"
                            labelPosition="before"
                            :minValue="0"
                            :maxValue="100"
                            :value.sync="variableDiscountPct"
                            required
                        />
                    </div>
                </div>
            </div>
        </ModalWindow>

        <ModalWindow
            v-if="isDisplayProgramList"
            width="600px" max-width="600px" max-height="90vh"
            :header="$t('objects.fin_program')"
            @cancel="isDisplayProgramList = false"
        >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="programList" :source="programArray" :config="programGridConfig" @item-selected="programSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import VisitDescription from '@/components/pages/visits/VisitDescription';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldString from '@/components/lib/controls/FieldString';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldCheckbox from '@/components/lib/controls/FieldCheckbox';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'RenderedServiceEdit',

        components: {
            TemplatePageStandard, ModalWindow,
            ButtonClose, ButtonSave, ButtonDelete,
            VisitDescription, Grid,
            FieldString, FieldSelect, FieldNumber, FieldCheckbox,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Props ===============
        props: {
            isDental: { type:Boolean, defalult: false },
            isEstimate: { type:Boolean, defalult: false },
        },

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                itemId: undefined,
                teeth: [],
                finService: {},
                // buttons: [],
                // msgItemModified: '',
                editFormData: {
                    // id: null,
                    fin_bill_id: null,
                    fin_estimate_id: null,
                    fin_service_id: null,
                    fin_service_group_id: null,
                    fin_currency_id: null,
                    fin_program_id: null,
                    fin_beneficiary_id: null,
                    fin_vat_id: null,
                    price: null,
                    qty: null,
                    sum_price: null,
                    is_rendered: null,
                },
                oldFormData: {},
                teethData: {
                    d51: false, d52: false, d53: false, d54: false, d55: false,
                    d61: false, d62: false, d63: false, d64: false, d65: false,
                    d11: false, d12: false, d13: false, d14: false, d15: false, d16: false, d17: false, d18: false,
                    d21: false, d22: false, d23: false, d24: false, d25: false, d26: false, d27: false, d28: false,
                    d41: false, d42: false, d43: false, d44: false, d45: false, d46: false, d47: false, d48: false,
                    d31: false, d32: false, d33: false, d34: false, d35: false, d36: false, d37: false, d38: false,
                    d81: false, d82: false, d83: false, d84: false, d85: false,
                    d71: false, d72: false, d73: false, d74: false, d75: false,
                },
                oldTeethData: {},
                formErrorMessages: {},
                // vRender: {},
                // isPriceReadonly: true,
                // isPageReadonly: false,
                splitArray: [],
                splitGridConfig: {
                    title: this.$t('object.fin_split'),
                    readonly: true,
                    columns: [
                        // { type: 'hidden', key:'dummy' },
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px', sortable:false },
                        // { type:'select', key:'fin_account_id', header:this.$t('object.fin_account'), source:'v_account', keyLabel:'payer_name', sortable:false },
                        // { type:'number', key:'fin_reversal_id', header:this.$t('object.fin_reversal'), sortable:false },
                        {
                            type:'number', key:'reversed_id', header:this.$t('component.RenderedServiceEdit.reversed_id'), sortable:false,
                            $hidden: (data) => (data.some(entry => (entry.reversed_id))),
                        },
                        {
                            type:'datetime', key:'reversal_datetime', header:this.$t('component.RenderedServiceEdit.reversal_datetime'), sortable:false,
                            // $value: (entry) => { return entry.reversed_id ? `${this.formatDateTimeShort(entry.reversal_datetime)} # ${entry.reversal_num}` : null },
                            $textStyle: (entry) => { return this.isReversalGranted ? 'color: #056bbf;' : 'cursor: auto;' },
                            $textOnClick: (entry) => {
                                this.isReversalGranted ?
                                this.$router.push({ name: 'finances/reversal-edit', params: { reversalId:entry.fin_reversal_id } }) :
                                null
                            },
                            $hidden: (data) => (data.some(entry => (entry.reversed_id))),
                        },
                        { type:'string', key:'payer_name', header:this.$t('object.fin_account'), sortable:false },
                        { type:'string', key:'program_name', header:this.$t('object.fin_program'), sortable:false },
                        { type:'number', key:'pct_value', header:this.$t('attribute.common.pct_value'), align:'right', width:'64px', sortable:false },
                        { type:'number', key:'qty', header:this.$t('attribute.common.qty'), align:'right', width:'64px', sortable:false },
                        // { type:'number', key:'sum_price', header:this.$t('attribute.common.sum_payment'), align:'right', width:'100px', sortable:false },
                        { type:'number', key:'sum_bid', header:this.$t('attribute.common.sum_bid'), align:'right', width:'64px', sortable:false },
                        { type:'number', key:'sum_discount', header:this.$t('attribute.common.sum_discount'), align:'right', width:'64px', sortable:false },
                        { type:'number', key:'sum_discount_payment', header:this.$t('attribute.common.sum_discount_payment'), align:'right', width:'64px', sortable:false },
                        { type:'number', key:'sum_payment_final', header:this.$t('attribute.common.sum_payment'), align:'right', width:'100px', sortable:false },
                        { type:'number', key:'sum_paid', header:this.$t('attribute.common.sum_paid'), align:'right', width:'100px', sortable:false },
                        { type:'number', key:'sum_vat_final', header:this.$t('attribute.common.sum_vat'), align:'right', width:'100px', sortable:false },
                        // { type:'number', key:'sum_net', header:this.$t('attribute.common.sum_net'), align:'right', width:'100px', sortable:false },
                        // { type:'number', key:'sum_gross', header:this.$t('attribute.common.sum_gross'), align:'right', width:'100px', sortable:false },
                    ],
                    totals: {
                        columns: [
                            { type:'string', key:'payer_name', value:this.$t('app.total'), style:'font-weight:bold; font-size:1.1rem;' },
                            { type:'number', key:'pct_value', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.pct_value), 0 ) : 0
                            },
                            { type:'number', key:'qty', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.qty), 0 ) : 0
                            },
                            { type:'number', key:'sum_bid', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_bid), 0 ) : 0
                            },
                            { type:'number', key:'sum_discount', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_discount), 0 ) : 0
                            },
                            { type:'number', key:'sum_discount_payment', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_discount_payment), 0 ) : 0
                            },
                            { type:'number', key:'sum_payment_final', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_payment_final), 0 ) : 0
                            },
                            { type:'number', key:'sum_paid', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_paid), 0 ) : 0
                            },
                            { type:'number', key:'sum_vat_final', style:'font-weight:bold; font-size:1.1rem;',
                                $value: (data) => (data && data.length > 0) ? data.reduce( ((sum, item) => sum + item.sum_vat_final), 0 ) : 0
                            },
                        ],
                    },
                    row: { $style:(entry) => entry.reversed_id ? 'background-color:#ffffee' : '' },
                    displaySearch: false,
                    displayPagination: false,
                    displayToolbar: true,
                },
                renderDiscountArray: [],
                renderDiscountSelectedArray: [],
                renderDiscountGridConfig: {
                    // apiUrl: null,
                    // title: this.$t('object.fin_discount'),
                    // source: this.renderDiscountArray,
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' },
                        { type:'select', key:'fin_discount_id', header:this.$t('object.fin_discount'), source:'fin_discount' },
                        { type:'number', key:'pct_value', header:this.$t('attribute.common.pct_value'), align:'right', width:'100px' },
                    ],
                    buttons: ['row-selector', 'add', 'delete'],
                    displayToolbar: false,
                    displayPagination: false,
                },
                isDisplayDiscountList: false,
                discountGridConfig: {
                    apiUrl: 'fin_discount',
                    // title: this.$t('object.fin_discount'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        { type:'number', key:'pct_value', header:this.$t('attribute.common.pct_value'), align:'right', width:'100px' },
                    ],
                    order: [ 'pct_value' ],
                    row: { emit: "item-selected", style: "cursor: pointer;" },
                    displayToolbar: false,
                    displayPagination: false,
                },
                selectedDiscount: null,
                isDisplayDiscountInput: false,
                variableDiscountPct: null,
                isDisplayProgramList: false,
                programArray: [],
                programGridConfig: {
                    // title: this.$t('object.fin_program'),
                    readonly: true,
                    columns: [
                        // { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'64px' },
                        { type:'string', key:'program_name', header:this.$t('object.fin_program') },
                        { type:'string', key:'contract_name', header:this.$t('object.fin_contract') },
                        { type:'number', key:'price', header:this.$t('attribute.common.price'), align:'right', width:'100px' },
                    ],
                    order: [ 'price' ],
                    row: { emit: "item-selected", style: "cursor: pointer;" },
                    displayToolbar: false,
                    displayPagination: false,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                lastClient: 'lastClient',
                // currencyAccounting: 'currencyAccounting',
            }),
            buttons() {
                if (this.itemId && !this.isPageReadonly) return ['save', 'delete', 'close'];
                else return ['close'];
            },
            breadcrumb() {
                return [
                    ...tools.getEhrBreadcrumb(this, {
                        text: this.isEstimate ? this.$t('VisitMenu.estimates') : this.$t('VisitMenu.services'),
                        to: `/client/${this.clientId}/visit/${this.visitId}/${this.isEstimate ? 'estimates' : 'services'}${this.isDental ? '-dental' : ''}`
                    }),
                    { text: this.$t('object.fin_service') },
                ];
            },
            // isPageReadonly() { return this.vRender.sum_paid > 0 },
            isPageReadonly() { return this.sumPaid > 0 },
            // msgItemModified() { this.isModified ? this.$t('app.itemModified') : '' },
            isSaveEnabled() { return Object.keys(this.formErrorMessages).length === 0; },
            isAddDiscountDisabled() { return this.renderDiscountArray.length > 0 || this.isPageReadonly },
            isDeleteDiscountDisabled() { return this.renderDiscountSelectedArray.length === 0 || this.isPageReadonly },
            sumPayment() { return this.splitArray.reduce( ((sum, item) => sum + item.sum_payment_final), 0 ) },
            sumPaid() { return this.splitArray.reduce( ((sum, item) => sum + item.sum_paid), 0 ) },
            isReversalGranted() {
                return tools.isPrivilegeGranted(this.currentUser.permissions, 'reversal');
            },
        },

        // =============== Watch ===============
        // watch: {
        //     variableDiscountPct() {
        //         console.log(`DEBUG: ${this.$options.name}.watch:variableDiscountPct this.variableDiscountPct=`, this.variableDiscountPct);
        //     },
        // },

        // =============== Methods ===============
        methods: {
            formatDateTimeShort(d) {
                if (!d) return '';
                return moment(d).format('L HH:mm');
            },

            async processCreateDiscount() {
                const data = {
                    fin_render_id: this.editFormData.id,
                    fin_discount_id: this.selectedDiscount.id,
                    pct_value: this.selectedDiscount.pct_value === null ? -this.variableDiscountPct : null,
                };
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-create-render-discount`, data);
                    await this.getData();
                    this.renderDiscountSelectedArray = [];
                    tools.displaySuccessSave(this);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async discountSelected(discount) {
                this.selectedDiscount = discount;
                if (discount.pct_value === null) {
                    this.variableDiscountPct = null;
                    this.isDisplayDiscountInput = true;
                } else {
                    await this.processCreateDiscount();
                }
                this.isDisplayDiscountList = false;
            },

            async variableDiscountEntered() {
                await this.processCreateDiscount();
                this.isDisplayDiscountInput = false;
            },

            renderDiscountSelected(selectedData) {
                // console.log(`DEBUG: ${this.$options.name}.renderDiscountSelected() selectedData=`, selectedData);
                this.renderDiscountSelectedArray = selectedData;
            },

            async deleteDiscount() {
                const data = {
                    fin_render_id: this.editFormData.id,
                    fin_render_discount: this.renderDiscountSelectedArray.map( item => item.id ),
                };
                // console.log(`DEBUG: ${this.$options.name}.deleteDiscount() data=`, data);
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/fin-delete-render-discount`, data);
                    this.isDisplayDiscountList = false;
                    this.getData();
                    this.renderDiscountSelectedArray = [];
                    tools.displaySuccessSave(this);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getTeeth() {
                try {
                    this.teeth = (await this.authenticatedAxios.get(`${this.baseUrl}/med_tooth`)).data.rows;
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getRenderTeeth() {
                const filter = tools.getFilterString({ where:[{ fin_render_id:this.editFormData.id }] });
                const previousRenderTeeth = (await this.authenticatedAxios.get(`${this.baseUrl}/med_render_tooth${filter}`)).data.rows;
                // console.log(`DEBUG: ${this.$options.name}.getRenderTeeth() previousRenderTeeth=`, previousRenderTeeth);
                // console.log(`DEBUG: ${this.$options.name}.getRenderTeeth() this.teeth=`, this.teeth);
                for (const key of Object.keys(this.teethData)) {
                    // console.log(key);
                    const tooth = this.teeth.find( item => `d${item.code}` === key );
                    // console.log(`DEBUG: ${this.$options.name}.getRenderTeeth() key=${key} tooth=`, tooth);
                    if (tooth) {
                        const previousRenderTooth = previousRenderTeeth.find( item => item.med_tooth_id === tooth.id );
                        // console.log(`DEBUG: ${this.$options.name}.getRenderTeeth() previousRenderTooth=`, previousRenderTooth);
                        this.teethData[key] = Boolean(previousRenderTooth);
                    }
                }
                this.oldTeethData = JSON.parse(JSON.stringify(this.teethData));
            },

            async getData() {
                try {
                    this.editFormData = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_render/${this.itemId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.editFormData=`, this.editFormData);
                    this.finService = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_service/${this.editFormData.fin_service_id}`)).data;
                    this.itemId = this.editFormData.id;
                    this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                    this.isModified = false;
                    this.validateForm();

                    const filterSplit = tools.getFilterString({
                        where:[{ fin_render_id:this.itemId }],
                        order:[ 'id' ],
                    });
                    this.splitArray = (await this.authenticatedAxios.get(`${this.baseUrl}/v_split${filterSplit}`)).data.rows;

                    const filterRenderDiscount = tools.getFilterString({ where:[{ fin_render_id:this.editFormData.id }] });
                    this.renderDiscountArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_render_discount${filterRenderDiscount}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.getData() data=`, this.renderDiscountArray);
                    // this.$refs.splits.refresh();
                    // this.$refs.discounts.refresh();
                    this.getRenderTeeth();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified()`);
                this.isModified =
                    JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData) ||
                    JSON.stringify(this.oldTeethData) !== JSON.stringify(this.teethData)
                ;
                // console.log(`DEBUG: ${this.$options.name}.itemModified(${this.isModified})`);
                this.validateForm();
            },

            validateForm() {
                this.formErrorMessages = {};
                if (tools.isNumberEmpty(this.editFormData.qty)) this.formErrorMessages.qty = this.$t('lib.validation.fieldIsEmpty');
                if (!(this.editFormData.qty > 0)) this.formErrorMessages.qty = this.$t('lib.validation.fieldMustBePositive');
                // console.log(`DEBUG: ${this.$options.name}.validateForm(${this.editFormData.qty}) this.formErrorMessages=`, this.formErrorMessages);
            },

            async saveRenderTeeth() {
                // console.log(`DEBUG: veRenderTeeth() this.teethData=`, this.teethData);
                try {
                    const filter = tools.getFilterString({ where:[{ fin_render_id:this.editFormData.id }] });
                    const previousRenderTeeth = (await this.authenticatedAxios.get(`${this.baseUrl}/med_render_tooth${filter}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.saveRenderTeeth() previousRenderTeeth=`, previousRenderTeeth);
                    let renderTeethToInsert = [];
                    let renderTeethToDelete = [];
                    for (const [key, value] of Object.entries(this.teethData)) {
                        // console.log(`${key}: ${value}`);
                        const tooth = this.teeth.find( item => `d${item.code}` === key );
                        if (tooth) {
                            const previousRenderTooth = previousRenderTeeth.find( item => item.med_tooth_id === tooth.id );
                            // console.log(`tooth=`, tooth);
                            // console.log(`previousRenderTooth=`, previousRenderTooth);
                            if (value && !previousRenderTooth) renderTeethToInsert.push({ key:key, med_tooth_id:tooth.id });
                            if (!value && previousRenderTooth) renderTeethToDelete.push({ key:key, id:previousRenderTooth.id });
                        }
                    }
                    // console.log(`DEBUG: ${this.$options.name}.saveRenderTeeth() renderTeethToInsert=`, renderTeethToInsert);
                    // console.log(`DEBUG: ${this.$options.name}.saveRenderTeeth() renderTeethToDelete=`, renderTeethToDelete);
                    for (const item of renderTeethToInsert) {
                        await this.authenticatedAxios.post(`${this.baseUrl}/med_render_tooth`, { fin_render_id:this.editFormData.id, med_tooth_id:item.med_tooth_id });
                    }
                    for (const item of renderTeethToDelete) {
                        await this.authenticatedAxios.delete(`${this.baseUrl}/med_render_tooth/${item.id}`);
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isModified=${this.isModified}, this.editFormData=`, this.editFormData);
                if (this.isModified) {
                    //this.$v.$touch();
                    //if (!this.$v.$invalid) {
                    try {
                        const renderData = {
                            core_user_id: this.currentUser.id,
                            fin_render_id: this.editFormData.id,
                            fin_service_id: this.editFormData.fin_service_id,
                            visit_rec_id: this.visitId,
                            fin_beneficiary_id: this.editFormData.fin_beneficiary_id,
                            fin_program_id: this.editFormData.fin_program_id,
                            date_of: null,
                            price: this.finService.is_price_variable ? this.editFormData.price : null,
                            qty: this.editFormData.qty,
                            is_forced: false,
                        };
                        const data = (await this.authenticatedAxios.post(`${this.baseUrl}/fin-update-render`, renderData)).data;

                        if (this.isDental) await this.saveRenderTeeth();

                        if (data) {
                            await this.getData();
                            if (this.$refs.splits) await this.$refs.splits.refresh(); // it is possibly to quick close page and $refs.splits became undefined
                            tools.displaySuccessSave(this);
                        } else {
                            tools.displayError(this, this.$t('lib.messages.errorSave'));
                        }
                        // console.log(`DEBUG: ${this.$options.name}.saveData() data=`, data);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },

            renderData(programId, beneficiaryId) {
                return {
                    core_user_id: this.currentUser.id,
                    fin_render_id: this.editFormData.id,
                    fin_service_id: this.editFormData.fin_service_id,
                    visit_rec_id: this.visitId,
                    fin_beneficiary_id: beneficiaryId,
                    fin_program_id: programId,
                    date_of: null,
                    price: this.editFormData.price,
                    qty: this.editFormData.qty,
                    is_forced: true,
                };
            },

            async updateRender(renderData) {
                try {
                    const data = (await this.authenticatedAxios.post(`${this.baseUrl}/fin-update-render`, renderData)).data;
                    if (data) {
                        await this.getData();
                        if (this.$refs.splits) await this.$refs.splits.refresh(); // it is possibly to quick close page and $refs.splits became undefined
                        tools.displaySuccessSave(this);
                    } else {
                        tools.displayError(this, this.$t('lib.messages.errorSave'));
                    }
                    // console.log(`DEBUG: ${this.$options.name}.updateRender(), data=`, data);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async programSelected(selectedProgram) {
                // console.log(`DEBUG: ${this.$options.name}.programSelected() selectedProgram=`, selectedProgram);
                this.isDisplayProgramList = false;
                await this.updateRender(this.renderData(selectedProgram.fin_program_id, selectedProgram.fin_beneficiary_id));
            },

            async shiftToProgram() {
                // console.log(`DEBUG: ${this.$options.name}.shiftToProgram() this.editFormData=`, this.editFormData);
                const filterString = tools.getFilterString({ where:[{ visit_rec_id:this.visitId }, { fin_service_id:this.editFormData.fin_service_id }]});
                try {
                    this.programArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-visit-service-programs${filterString}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.shiftToProgram(), this.programArray=`, this.programArray);
                    // if (this.programArray.length === 0) return; // do nothing
                    if (this.programArray.length === 1) {
                        await this.updateRender(this.renderData(this.programArray[0].fin_program_id, this.programArray[0].fin_beneficiary_id));
                    }
                    if (this.programArray.length > 1) {
                        this.isDisplayProgramList = true;
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async shiftToClient() {
                await this.updateRender(this.renderData(null, null));
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        const data = (await this.authenticatedAxios.post(`${this.baseUrl}/fin-delete-render/${this.editFormData.id}`)).data;
                        // console.log(`DEBUG: ${this.$options.name}.deleteItem(), data=`, data);
                        // if (data && data.status === 'success') {
                        if (data) {
                            this.isModified = false;
                            tools.displaySuccessDelete(this.$parent);
                            this.$router.go(-1);
                        } else {
                            tools.displayError(this, this.$t('lib.messages.errorUnableToDeleteItem'));
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this=`, this);
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            this.itemId = parseInt(this.$route.params.renderId);
        },

        async mounted() {
            if (this.isDental) await this.getTeeth();
            if (this.itemId) {
                await this.getData();
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
            // this.oldTeethData = JSON.parse(JSON.stringify(this.teethData));
        },
    }
</script>

<style lang="scss">
    .rendered-services__teeth {
        .form-check input[type=checkbox] {
            margin-left: 1rem;
            margin-right: 1rem;
        }

        @media (max-width: 1024px) {
            .form-check input[type=checkbox] {
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
        }

        @media (max-width: 512px) {
            .form-check input[type=checkbox] {
                margin-left: 0.2rem;
                margin-right: 0.2rem;
            }
        }

        .quadrant-1, .quadrant-2, .quadrant-3, .quadrant-4  {
            background-color: #ffeeee;
            text-align: center;
        }
        .quadrant-5, .quadrant-6, .quadrant-7, .quadrant-8 {
            background-color: #eeffee;
            text-align: center;
        }
        .quadrant-5, .quadrant-6 {
            border-top: 1px dotted #aaa;
        }
        .quadrant-8, .quadrant-7 {
            border-bottom: 1px dotted #aaa;
            margin-bottom: 1rem;
        }
        .quadrant-5, .quadrant-1, .quadrant-4, .quadrant-8 {
            border-right: 1px dotted #aaa;
            border-left: 1px dotted #aaa;
        }
        .quadrant-6, .quadrant-2, .quadrant-3, .quadrant-7 {
            border-right: 1px dotted #aaa;
        }
        .quadrant-5, .quadrant-6, .quadrant-1, .quadrant-2, .quadrant-4, .quadrant-3 {
            border-bottom: 1px dotted #aaa;
        }
        .form-group {
            margin-bottom: 5px;
        }
    }
</style>
