<!--
    Author: Eduard Grebenyukov
    Date: 2021-01-30
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >

        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>

        <div id="page-content" class="form-float">
            <div class="form-row">
                <div class="col-sm col-md-2">
                    <FieldString
                        :label="$t('attribute.common.code')"
                        :value.sync="editFormData.code"
                        @modified="itemModified('code', true)"
                    />
                </div>
                <div class="col-sm col-md-10">
                    <FieldString
                        :label="$t('attribute.common.name')"
                        :value.sync="editFormData.name"
                        required
                        @modified="itemModified('name', true)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="col-sm col-md-2">
                    <FieldString
                        :label="$t('attribute.mat_item.vendor_code')"
                        :value.sync="editFormData.vendor_code"
                        @modified="itemModified('vendor_code', true)"
                    />
                </div>
                <div class="col-sm col-md-10">
                    <FieldString
                        :label="$t('attribute.mat_item.manufacturer')"
                        :value.sync="editFormData.manufacturer"
                        @modified="itemModified('manufacturer', true)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="col-sm col-md-6">
                    <FieldSelect
                        :label="$t('object.mat_group')"
                        :value.sync="editFormData.mat_group_id"
                        source="mat_group"
                        required
                        @modified="itemModified('mat_group_id', true)"
                    />
                </div>
                <!-- <div class="col-sm">
                    <FieldSelect
                        :label="$t('object.mat_pharmgroup')"
                        :value.sync="editFormData.mat_pharmgroup_id"
                        source="mat_pharmgroup"
                        @modified="itemModified('mat_pharmgroup_id', true)"
                    />
                </div> -->
                <!-- <div class="col-sm">
                    <FieldSelect
                        :label="$t('object.mat_pricegroup')"
                        :value.sync="editFormData.mat_pricegroup_id"
                        source="mat_pricegroup"
                        @modified="itemModified('mat_pricegroup_id', true)"
                    />
                </div> -->
                <div class="col-sm col-md-2">
                    <FieldSelect
                        :label="$t('attribute.common.base_unit_id')"
                        :value.sync="editFormData.base_unit_id"
                        source="mat_unit"
                        required
                        @modified="itemModified('base_unit_id', true)"
                    />
                </div>
                <!-- <div class="col-sm">
                    <FieldSelect
                        :label="$t('object.fin_service')"
                        :value.sync="editFormData.fin_service_id"
                        source="fin_service"
                        @modified="itemModified('fin_service_id', true)"
                    />
                </div> -->
                <div class="col-sm col-md-2">
                    <FieldSelect
                        :label="$t('object.fin_vat')"
                        :value.sync="editFormData.fin_vat_id"
                        source="fin_vat"
                        required
                        @modified="itemModified('fin_vat_id', true)"
                    />
                </div>
                <div class="col-sm col-md-2">
                    <FieldSwitch
                        id="is_vital"
                        :label="$t('attribute.mat_item.is_vital')"
                        :value.sync="editFormData.is_vital"
                        :defaultValue="false"
                        @modified="itemModified('is_vital', true)"
                    />
                </div>
            </div>
            <!-- <div class="form-row" v-if="editFormData.id">
                <div class="col">
                    <TabSwitch
                        :value.sync="currentTab"
                        defaultValue="main"
                        :source="navigationTabs"
                        @modified="filterTabsModified"
                    />
                </div>
            </div> -->
            <div v-if="isDisplayGrids" class="form-row">
                <div class="col">
                    <Grid
                        ref="packGrid"
                        :config="packGridConfig"
                        @modified="itemModified('packGrid', $event)"
                    />

                    <Grid
                        ref="barcodeGrid"
                        :config="barcodeGridConfig"
                    />
                </div>
            </div>
        </div>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldString from '@/components/lib/controls/FieldString';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';

    // import TabSwitch from '@/components/lib/controls/TabSwitch';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'MatItemEdit',

        components: {
            TemplatePageStandard,
            ButtonClose, ButtonSave, ButtonDelete,
            FieldString, FieldSelect, FieldSwitch, //TabSwitch,
            Grid,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Props ===============
        props: {
            id: [String, Number],
        },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                isItemModified: false,
                isPackGridModified: false,
                // isProgramsModified: false,
                buttons: [],
                msgItemModified: '',
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.store') },
                    { text: this.$t('MainMenu.store-items'), to: `/store/items` },
                    { text: this.$t('object.mat_item') },
                ],
                editFormData: {
                    // id: null,
                    mat_group_id: null,
                    mat_pharmgroup_id: null,
                    mat_pricegroup_id: null,
                    base_unit_id: null,
                    fin_service_id: null,
                    med_medicine_id: null,
                    code: null,
                    name: null,
                    vendor_code: null,
                    manufacturer: null,
                    is_vital: false,
                },
                oldFormData: {},
                // navigationTabs: [
                //     { id: 'main', name: this.$t('component.MatItemEdit.main') },
                //     { id: 'pack', name: this.$t('component.MatItemEdit.pack') },
                //     // { id: 'subdocuments', name: this.$t('component.ContractEdit.subdocuments') },
                // ],
                // currentTab: 'main',
                packGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title: this.$t('component.MatItemEdit.pack'),
                    // readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'50px' },
                        { type:'hidden', key:'mat_item_id', header:"mat_item_id", required:true },
                        { type:'select', key:'mat_unit_id', header:this.$t('object.mat_unit'), source:'mat_unit', required:true },
                        { type:'number', key:'unit_factor', header:this.$t('attribute.mat_pack.unit_factor'), required:true },
                        { type:'string', key:'name', header:this.$t('attribute.common.name'), required:true },
                    ],
                    order: [ 'id' ],
                    displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                    // $onAdd: (grid) => {},
                    // $onEdit: (grid, item) => { editPayer(item.id) },
                },
                barcodeGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title: this.$t('objects.mat_barcode'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'50px' },
                        // { type:'hidden', key:'mat_item_id', header:"mat_item_id" },
                        { type:'string', key:'barcode_value', header:this.$t('attribute.common.barcode') },
                    ],
                    order: [ 'id' ],
                    displayToolbar: false,
                    buttons: [],
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            isDisplayGrids() { return typeof this.editFormData.id !='undefined' },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            // filterTabsModified() {
            // },

            setBreadcrumb() {
                if (this.editFormData.name) {
                    this.breadcrumb[3].text = this.editFormData.name;
                }
            },

            checkModified() {
                this.isItemModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                this.isModified = this.isItemModified || this.isPackGridModified;
            },

            gotData() {
                // this.isModified = false;
                this.isItemModified = false;
                this.msgItemModified = '';
                this.itemId = this.editFormData.id;

                let filter = { where:[
                    { mat_item_id:this.editFormData.id },
                ]};
                this.packGridConfig.filter = filter;
                this.packGridConfig.apiUrl = 'mat_pack';
                this.packGridConfig.columns.find(x => x.key === 'mat_item_id').defaultValue = this.editFormData.id;
                if (this.$refs.packGrid) this.$refs.packGrid.refresh();

                this.barcodeGridConfig.filter = filter;
                this.barcodeGridConfig.apiUrl = 'mat_barcode';
                if (this.$refs.barcodeGrid) this.$refs.barcodeGrid.refresh();

                this.setBreadcrumb();
                this.checkButtons();
            },

            async getData() {
                try {
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/mat_item/${this.itemId}`)).data;
                    if (data) {
                        this.editFormData = data;
                        this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                    }
                    // console.log(`DEBUG: ${this.$options.name}.getData data=`, this.editFormData);
                    this.gotData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isModified=${this.isModified}, this.editFormData=`, this.editFormData);
                if (this.isItemModified) {
                    //this.$v.$touch();
                    //if (!this.$v.$invalid) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            // console.log(`DEBUG: ${this.$options.name}.saveData() Update this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.put(`${this.baseUrl}/mat_item/${this.editFormData.id}`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;                                
                                this.gotData();
                                tools.displaySuccessSave(this);
                            }
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveData() New this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.post(`${this.baseUrl}/mat_item`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                                this.gotData();
                                // this.getData();
                                this.oldFormData = JSON.parse(JSON.stringify(this.editFormData)); // to prevent question
                                this.checkModified();
                                tools.displaySuccessSave(this);
                                this.$router.replace({ name: 'store/item-edit', params: { id:this.editFormData.id } });
                            }
                        }
                        this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                    // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                } else {
                    // no changes
                }
                if (this.isDisplayGrids) {
                    if (this.$refs.packGrid) {
                        this.$refs.packGrid.$emit('save');
                        this.isPackGridModified = false;
                    }
                    // if (this.$refs.programsGrid) {
                    //     // this.$refs.programsGrid.$emit('save');
                    //     // this.isProgramsModified = false;
                    // }
                }
                this.checkModified();
            },

            itemModified(item, isTrue) {
                if (isTrue) {
                    if (item == 'packGrid') this.isPackGridModified = true;
                    // if (item == 'programs') this.isProgramsModified = true;
                }
                // console.log(`DEBUG: ${this.$options.name}.itemModified(${item}, ${this.isItemModified}, ${this.isPackGridModified}, ${this.isProgramsModified})`);
                this.checkModified();
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
                this.setBreadcrumb();
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.baseUrl}/mat_item/${this.itemId}`);
                        this.isItemModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },

        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemId = parseInt(this.id);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this=`, this);
            if (this.itemId) {
                await this.getData();
            } else {
                this.setBreadcrumb();
                this.checkButtons();
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
        },
    }
</script>

<style>

</style>
