<!--
    Author: Eduard Grebenyukov
    Date: 2020-02-16
-->

<template>
    <ModalWindow
        width="90%"
        max-width="1200px"
        :buttons="buttons"
        @cancel="closeModal"
        @save="saveData"
        @delete="askDeleteItem"
    >
        <template #buttons>
            <ButtonCustom @click="saveData" :disabled="!isValidated" buttonClass="btn-primary btn-sm ml-1" :text="$t('lib.buttons.ok')" />
        </template>
        <span v-if="!itemId" slot="header" :title="msgItemModified">{{ $t('component.MatDocSupplierReturnItemEditModal.createNew') }} {{ isModified? '*' : '' }}</span>
        <span v-if="itemId"  slot="header" :title="msgItemModified">{{ $t('component.MatDocSupplierReturnItemEditModal.edit') }} {{ isModified? '*' : '' }}</span>

        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <FieldString :label="$t('attribute.mat_tran.barcode_value')" :value.sync="editFormData.barcode_value" @modified="itemModified('barcode_value')" />
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <FieldString :label="$t('attribute.mat_tran.serial_num')" :value.sync="editFormData.serial_num" @modified="itemModified('serial_num')" />
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <FieldDate :label="$t('attribute.mat_tran.expiration_date')" :value.sync="editFormData.expiration_date" @modified="itemModified('expiration_date')" />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-8">
                    <FieldPopupList
                        ref="matItemId"
                        :label="$t('object.mat_item')"
                        :value.sync="editFormData.mat_item_id"
                        :source="remaindersArray"
                        :listConfig="remaindersListConfig"
                        keyId="mat_item_id"
                        keyLabel="item_name"
                        required
                        :readonly="!!editFormData.id"
                        maxWidth='600px'
                        @modified="itemModified('mat_item_id')"
                    />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-2">
                    <FieldNumber
                        :label="$t('attribute.common.qty')"
                        :value.sync="editFormData.unit_qty"
                        required
                        :disabled="!matItemSelected || matItemSelected.unit_qty <= 0"
                        :minValue="0"
                        :maxValue="matItemSelected ? matItemSelected.unit_qty : null"
                        @modified="itemModified('unit_qty')"
                    />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-2">
                    <FieldSelect :label="$t('object.mat_unit')" :value.sync="editFormData.mat_pack_id" :source="matPackArray" required @modified="itemModified('mat_pack_id')" />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <FieldNumber :label="$t('attribute.common.price')" :value.sync="editFormData.unit_price" disabled />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <FieldNumber :label="$t('attribute.common.sum')" :value.sync="editFormData.sum_net" disabled />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <FieldSelect :label="$t('object.fin_vat')" :value.sync="editFormData.fin_vat_id" source="fin_vat" disabled />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <FieldNumber :label="$t('attribute.common.sum_vat')" :value.sync="editFormData.sum_vat" disabled />
                </div>
            </div>
        </div>

        <!-- <MatItemEditModal
            v-if="isDisplayMatItemEditModal"
            :matItemId="null"
            @close-modal="isDisplayMatItemEditModal = false"
            @saved="selectMatItem($event)"
        /> -->

        <WarningClose v-if="isDisplayWarningClose" @process="$event ? processClose() : cancelClose()" />
        <WarningDelete v-if="isDisplayWarningDelete" @process="$event ? processDeleteItem() : cancelDeleteItem()" />

    </ModalWindow>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import WarningClose from '@/components/lib/regions/WarningClose';
    import WarningDelete from '@/components/lib/regions/WarningDelete';

    import ButtonCustom from '@/components/lib/controls/ButtonCustom'

    import FieldPopupList from '@/components/lib/controls/FieldPopupList';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldDate from '@/components/lib/controls/FieldDate';

    // import MatItemEditModal from './MatItemEditModal';

    export default {
        name: 'MatDocSupplierReturnItemEditModal',

        components: {
            ModalWindow, WarningClose, WarningDelete, ButtonCustom,
            FieldPopupList, FieldString, FieldNumber, FieldSelect, FieldDate,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Props ===============
        props: {
            matDoc: Object,
            matTran: Object,
            barCode: null,
        },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                // isDisplayMatItemEditModal: false,
                editFormData: {
                    // id: null,
                    mat_doc_id: null,
                    source_tran_id: null,
                    mat_item_id: null,
                    barcode_value: null,
                    serial_num: null,
                    expiration_date: null,
                    unit_qty: null,
                    mat_pack_id: null,
                    unit_price: null,
                    sum_net: null,
                    fin_vat_id: null,
                    sum_vat: null,
                },
                matItemSelected: null,
                oldFormData: {},
                remaindersListConfig: {
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'mat_item_id', header:this.$t('attribute.common.id') },
                        // { type:'string', key:'code', header:this.$t('attribute.common.code') },
                        { type:'string', key:'item_name', header:this.$t('attribute.common.name') },
                        { type:'number', key:'unit_qty', header:this.$t('component.MatDocSupplierReturnItemEditModal.remainderQty') },
                        { type:'string', key:'pack_name', header:this.$t('component.MatDocSupplierReturnItemEditModal.packName') },
                     ],
                    order: [ 'item_name' ],
                    buttons: [ 'add' ],
                    displayToolbar: true,
                    // $onAdd: () => { this.isDisplayMatItemEditModal = true },
                },
                matPackArray: [],
                remaindersArray: [],
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            isValidated() {
                return (
                    // this.editFormData.mat_doc_id &&
                    !tools.isNumberEmpty(this.editFormData.source_tran_id) &&
                    !tools.isNumberEmpty(this.editFormData.mat_item_id) &&
                    this.editFormData.unit_qty > 0 &&
                    !tools.isNumberEmpty(this.editFormData.mat_pack_id) &&
                    !tools.isNumberEmpty(this.editFormData.unit_price) && this.editFormData.unit_price >= 0 &&
                    !tools.isNumberEmpty(this.editFormData.sum_net) && this.editFormData.sum_net >= 0
                    // !tools.isNumberEmpty(this.editFormData.fin_vat_id) &&
                    // !tools.isNumberEmpty(this.editFormData.sum_vat)
                );
            },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                // this.buttons = [ 'delete', 'cancel' ];
                // if (this.itemId) {
                //     this.buttons = [ 'delete', 'cancel' ];
                // } else {
                //     this.buttons = [ 'cancel' ];
                // }
                if (this.itemId || this.editFormData.$isNew) {
                    this.buttons = [ 'delete', 'cancel' ];
                } else {
                    this.buttons = [ 'cancel' ];
                }
            },

            async saveData() {
                this.isModified = false;
                this.$emit('close-modal', this.editFormData);
            },

            async getMatPackArray() {
                try {
                    if (this.editFormData.mat_item_id) {
                        this.matPackArray = (await this.authenticatedAxios.get(`${this.baseUrl}/mat-item-packs/${this.editFormData.mat_item_id}`)).data.rows;
                        // console.log(`DEBUG: ${this.$options.name}.getMatPackArray() this.matPackArray=`, this.matPackArray);
                    } else {
                        this.matPackArray = [];
                    }
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            async getRemaindersArray() {
                try {
                    // console.log(`DEBUG: ${this.$options.name}.getRemaindersArray() this.matDoc=`, this.matDoc);
                    if (this.matDoc.store_src_id && this.matDoc.contractor_id) {
                        this.remaindersArray = (await this.authenticatedAxios.get(`${this.baseUrl}/mat-supplier-return-remainders/${this.matDoc.store_src_id}/${this.matDoc.contractor_id}`)).data.rows;
                        // console.log(`DEBUG: ${this.$options.name}.getRemaindersArray() this.remaindersArray=`, this.remaindersArray);
                    } else {
                        this.remaindersArray = [];
                    }
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            async recalcFields() {
                this.editFormData.source_tran_id = this.matItemSelected.source_tran_id;
                if (this.matItemSelected && (this.editFormData.unit_qty > this.matItemSelected.unit_qty)) {
                    this.editFormData.unit_qty = this.matItemSelected.unit_qty;
                }
                this.editFormData.unit_price = this.matItemSelected.unit_price;
                this.editFormData.sum_net = tools.round(this.editFormData.unit_qty * this.editFormData.unit_price, 2);
                this.editFormData.fin_vat_id = this.matItemSelected.fin_vat_id;
                let vatPct;
                if (this.editFormData.fin_vat_id) {
                    try {
                        vatPct = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_vat/${this.editFormData.fin_vat_id}`)).data.pct_value;
                    } catch (err) {
                        tools.displayApiError(this.$parent, err);
                    }
                } else {
                    vatPct = null;
                }
                if (vatPct === null) {
                    this.editFormData.sum_vat = null;
                } else {
                    this.editFormData.sum_vat = tools.round(this.editFormData.sum_net * vatPct / 100, 2);
                }
                this.editFormData.barcode_value = this.matItemSelected.barcode_value;
                this.editFormData.serial_num = this.matItemSelected.serial_num;
                this.editFormData.expiration_date = this.matItemSelected.expiration_date;
            },

            async itemModified(itemChanged) {
                // console.log(`DEBUG: ${this.$options.name}.itemModified(${itemChanged}) this.editFormData=`, this.editFormData);
                if (itemChanged === 'mat_item_id') {
                    await this.getMatPackArray();
                    this.matItemSelected = this.remaindersArray.filter(item => item.mat_item_id === this.editFormData.mat_item_id)[0];
                    // console.log(`DEBUG: ${this.$options.name}.itemModified(${itemChanged}) this.matItemSelected=`, this.matItemSelected);
                    if (this.matItemSelected) {
                        this.editFormData.mat_pack_id = this.matItemSelected.mat_pack_id;
                    }
                    // console.log(`DEBUG: ${this.$options.name}.itemModified(${itemChanged}) this.editFormData=`, this.editFormData);
                    setTimeout(this.recalcFields, 0);
                }
                if (itemChanged === 'unit_qty') {
                    setTimeout(this.recalcFields, 0);
                }
                // await this.recalcFields(itemChanged);
                if (JSON.stringify(this.oldFormData) === JSON.stringify(this.editFormData)) {
                    this.isModified = false;
                    this.msgItemModified = '';
                } else {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                }
                this.checkButtons();
            },

            // async selectMatItem(data) {
            //     // console.log(`DEBUG: ${this.$options.name}.selectMatItem()`);
            //     this.editFormData.mat_item_id = data.matItemData.id;
            //     this.editFormData.fin_vat_id = data.matItemData.fin_vat_id;
            //     this.editFormData.mat_pack_id = data.matPackData.id;
            //     await this.itemModified('mat_item_id');
            //     this.$refs.matItemId.closePopup();
            // },

            async processDeleteItem() {
                this.$emit('delete', this.editFormData);
                this.$emit('close-modal', null);
            },
            cancelDeleteItem() { this.isDisplayWarningDelete = false },

            closeModal() {
                if (this.isModified) {
                    this.isDisplayWarningClose = true;
                } else {
                    this.processClose();
                }
            },
            processClose() { this.$emit('close-modal', null) },
            cancelClose() { this.isDisplayWarningClose = false },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            if (this.matTran) {
                this.editFormData = this.matTran;
                this.itemId = this.editFormData.id;
            }
            this.editFormData.mat_doc_id = this.matDoc.id;
        },

        async mounted() {
            await this.getRemaindersArray();
            if (this.editFormData.id) {
                await this.itemModified('mat_item_id');
            } else {
                await this.getMatPackArray();
            }
            this.checkButtons();
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
            if (!this.matTran) this.editFormData.barcode_value = this.barCode;
        },
    }
</script>

<style>
</style>
