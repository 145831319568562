<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-14
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonPrint v-if="buttons.includes('print')" @click="printItem()" />
            <!-- <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" /> -->
            <ButtonCustom
                v-if="buttons.includes('commit')"
                buttonClass="btn-warning btn-sm ml-1"
                iconClass="fas fa-check-square fa-fw"
                :text="$t('component.MedHospitalization.commit')"
                :disabled="!isReadyToCommit"
                @click="CommitDocument()"
            />
            <ButtonCustom
                v-if="buttons.includes('rollback')"
                buttonClass="btn-outline-danger btn-sm ml-1"
                iconClass="fas fa-square fa-fw"
                :text="$t('component.MedHospitalization.rollback')"
                :disabled="!isReadyToRollback"
                @click="isDisplayWarningRollback = true"
            />
        </template>

        <VisitDescription />

        <VisitTabs currentTab="visit-hospitalization" />

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <!-- <div class="col-sm-12 col-xl-8"> -->
                    <div class="col">
                        <div class="form-row">
                            <div class="col-sm col-lg-3">
                                <FieldDatetime
                                    :label="$t('attribute.med_hospitalization.datetime_admission')"
                                    :value.sync="editFormData.datetime_admission"
                                    :disabled="editFormData.is_commited"
                                    required
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    :label="$t('attribute.med_hospitalization.referring_subject')"
                                    :value.sync="editFormData.referring_subject"
                                    :disabled="editFormData.is_commited"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldString
                                    :label="$t('attribute.med_hospitalization.transportation_type')"
                                    :value.sync="editFormData.transportation_type"
                                    :disabled="editFormData.is_commited"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="col-sm col-lg-2">
                                <FieldSwitch
                                    id="is_urgent"
                                    :label="$t('attribute.med_hospitalization.is_urgent')"
                                    :value.sync="editFormData.is_urgent"
                                    :disabled="editFormData.is_commited"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm col-lg-3">
                                <FieldDatetime
                                    :label="$t('attribute.med_hospitalization.datetime_hospitalization')"
                                    :value.sync="editFormData.datetime_hospitalization"
                                    :disabled="editFormData.is_commited"
                                    required
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="col-sm col-lg-3">
                                <FieldDate
                                    :label="$t('attribute.med_hospitalization.date_discharge_plan')"
                                    :value.sync="editFormData.date_discharge_plan"
                                    :disabled="editFormData.is_commited"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm col-lg-2">
                                <FieldString
                                    :label="$t('attribute.med_hospitalization.hmr_num')"
                                    :value.sync="editFormData.hmr_num"
                                    :disabled="editFormData.is_commited"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="col-sm col-lg-10">
                                <FieldPopupList
                                    :label="$t('attribute.med_hospitalization.hospital_physician_id')"
                                    :value.sync="editFormData.hospital_physician_id"
                                    source='v_workplace'
                                    keyLabel='workplace_name'
                                    :listConfig="workplaceListConfig"
                                    required
                                    :disabled="editFormData.is_commited"
                                    maxWidth="1500px"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm col-lg-3">
                                <FieldDatetime
                                    :label="$t('component.MedHospitalization.datetime_accommodation')"
                                    :value.sync="accommodationFormData.datetime_from"
                                    :disabled="editFormData.is_commited"
                                    required
                                />
                            </div>
                            <div class="col-sm col-lg-4">
                                <FieldPopupList
                                    :label="$t('object.med_bed')"
                                    :value.sync="accommodationFormData.med_bed_id"
                                    source='v_bed'
                                    :listConfig="bedListConfig"
                                    maxWidth="600px"
                                    :disabled="editFormData.is_commited"
                                    required
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <!-- <div class="form-row">
                            <div class="col">
                                <FieldPopupList
                                    :label="$t('attribute.med_hospitalization.referring_physician_id')"
                                    :value.sync="editFormData.referring_physician_id"
                                    source='v_workplace'
                                    keyLabel='workplace_name'
                                    :listConfig="workplaceListConfig"
                                    maxWidth="1500px"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div> -->
                        <div class="form-row">
                            <div class="col-md">
                                <FieldText
                                    :label="$t('attribute.med_hospitalization.referring_diagnosis')"
                                    :value.sync="editFormData.referring_diagnosis"
                                    :disabled="editFormData.is_commited"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="col-md">
                                <FieldText
                                    :label="$t('attribute.med_hospitalization.special_notes')"
                                    :value.sync="editFormData.special_notes"
                                    :disabled="editFormData.is_commited"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-12 col-xl-4"> -->
                        <!-- Right sidebar -->
                    <!-- </div> -->
                </div>
            </div>
        </form>

        <ModalWindow
            v-if="isDisplayWarningRollback"
            minWidth="300px"
            :buttons="['yes', 'no']"
            @yes="processRollback"
            @no="isDisplayWarningRollback = false"
        >
            {{ $t('app.confirmRollback') }}
        </ModalWindow>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonPrint from '@/components/lib/controls/ButtonPrint';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldDatetime from '@/components/lib/controls/FieldDatetime';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import FieldPopupList from '@/components/lib/controls/FieldPopupList';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldText from '@/components/lib/controls/FieldText';

    export default {
        name: 'MedHospitalization',

        components: {
            TemplatePageStandard, ModalWindow, VisitDescription, VisitTabs,
            ButtonClose, ButtonSave, ButtonPrint, ButtonCustom,
            FieldDate, FieldDatetime, FieldSwitch, FieldPopupList, FieldString, FieldText,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                // visitRec: {},
                currentFormItem: null,
                currentAttribute: null,
                // isFormEditable: true,
                isDisplayWarningRollback: false,
                isHospitalizationModified: false,
                isAccommodationModified: false,
                isReadyToRollback: false,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.hospitalization') }),
                editFormData: {
                    // id: null,
                    visit_rec_id: null,
                    referring_physician_id: null,
                    hospital_physician_id: null,
                    referring_subject: null,
                    datetime_admission: null,
                    datetime_hospitalization: null,
                    date_discharge_plan: null,
                    is_urgent: false,
                    is_denied: false,
                    is_commited: false,
                    transportation_type: null,
                    hmr_num: null,
                    deny_reason: null,
                    referring_diagnosis: null,
                    special_notes: null,
                },
                oldFormData: {},
                accommodationFormData: {
                    // id: null,
                    med_bed_id: null,
                    visit_open_id: null,
                    visit_close_id: null,
                    datetime_from: null,
                    datetime_to: null,
                },
                accommodationOldFormData: {},
                // isDisplayWarningDelete: false,
                workplaceListConfig: {
                    filter: { where:[ {is_schedule:true}, {is_active:true} ]},
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        { type:'string', key:'dept_name', header:this.$t('object.core_dept') },
                        { type:'string', key:'speciality_name', header:this.$t('object.core_speciality') },
                        { type:'string', key:'user_name', header:this.$t('object.core_user') },
                        { type:'string', key:'room_name', header:this.$t('object.core_room') },
                        // { type:'string', key:'device_name', header:this.$t('object.core_device') },
                     ],
                    order: [ 'name' ],
                    displayToolbar: true,
                },
                bedListConfig: {
                    filter: { where:[{ is_archived:false }]},
                    readonly: true,
                    columns: [
                        { type:'string', key:'room_name', header:this.$t('object.core_room') },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                     ],
                    order: [ 'name' ],
                    displayToolbar: true,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            isReadyToCommit() {
                // console.log(`DEBUG: ${this.$options.name}.isReadyToCommit(), this.editFormData=`, this.editFormData);
                // console.log(`DEBUG: ${this.$options.name}.isReadyToCommit(), this.accommodationFormData=`, this.accommodationFormData);
                return (
                    this.editFormData.hospital_physician_id &&
                    this.editFormData.datetime_admission &&
                    this.editFormData.datetime_hospitalization &&
                    this.accommodationFormData.med_bed_id &&
                    this.accommodationFormData.visit_open_id &&
                    this.accommodationFormData.datetime_from
                );
            },
        },

        // =============== Watch ===============
        // watch: {
        //     isHospitalizationModified() {
        //         this.checkModified();
        //     },
        //     isAccommodationModified() {
        //         this.checkModified();
        //     },
        // },

        // =============== Methods ===============
        methods: {
            // checkModified() {
            //     this.isModified = this.isHospitalizationModified || this.isAccommodationModified;
            // },
            checkButtons() {
                this.buttons = ['save', 'close'];
                if (this.itemId) {
                    // console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.editFormData=`, this.editFormData);
                    if (this.editFormData.is_commited) {
                        this.buttons.push('rollback');
                    } else {
                        this.buttons.push('commit');
                    }
                }
            },

            setBreadcrumb() {
            },

            async checkIsReadyToRollback() {
                try {
                    const currentVisitRec = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_rec/${this.visitId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.checkIsReadyToRollback currentVisitRec=`, currentVisitRec);
                    let filterString = tools.getFilterString({ where:[{ visit_event_id: currentVisitRec.visit_event_id }]});
                    // console.log(`DEBUG: ${this.$options.name}.checkIsReadyToRollback filterString=`, filterString);
                    let otherVisits = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_rec${filterString}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.checkIsReadyToRollback otherVisits=`, otherVisits);
                    this.isReadyToRollback = otherVisits.length === 1;
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            gotHospitalizationData() {
                // this.editFormData = data;
                this.isHospitalizationModified = false;
                this.msgItemModified = '';
                this.itemId = this.editFormData.id;
                this.oldFormData = tools.deepCopy(this.editFormData);

                this.itemModified();
                this.setBreadcrumb();
                this.checkButtons();
            },

            async getData() {
                try {
                    const nowString = new Date().toISOString();
                    await this.getHospitalizationData(nowString);
                    await this.getAccommodationData(nowString);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getHospitalizationData(nowString) {
                try {
                    let filterString = tools.getFilterString({ where:[{ visit_rec_id: this.visitId }]});
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/med_hospitalization${filterString}`)).data.rows[0];
                    if (data) {
                        this.editFormData = data;
                        this.gotHospitalizationData();
                    } else {
                        this.editFormData.datetime_admission = nowString;
                        this.editFormData.datetime_hospitalization = nowString;
                        this.itemModified();
                        this.setBreadcrumb();
                        this.checkButtons();
                    }
                    // console.log(`DEBUG: ${this.$options.name}.getHospitalizationData data=`, data);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getAccommodationData(nowString) {
                try {
                    let filterString = tools.getFilterString({ where:[{ visit_open_id: this.visitId }]});
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/med_accommodation${filterString}`)).data.rows[0];
                    // console.log(`DEBUG: ${this.$options.name}.getAccommodationData data=`, data);
                    if (data) {
                        this.accommodationFormData = data;
                        this.accommodationOldFormData = tools.deepCopy(this.accommodationFormData);
                        this.itemModified();
                    } else {
                        this.accommodationFormData.datetime_from = nowString;
                        this.itemModified();
                    }
                    // console.log(`DEBUG: ${this.$options.name}.getAccommodationData data=`, data);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isModified=${this.isModified}`);
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isHospitalizationModified=${this.isHospitalizationModified}`);
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isAccommodationModified=${this.isAccommodationModified}`);
                if (this.isModified) {
                    await this.saveHospitalizationData();
                    await this.saveAccommodationData();
                    tools.displaySuccessSave(this);
                } else {
                    // no changes
                }
            },

            async saveHospitalizationData() {
                // console.log(`DEBUG: ${this.$options.name}.saveHospitalizationData() this.isHospitalizationModified=${this.isHospitalizationModified}`);
                if (this.isHospitalizationModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            // console.log(`DEBUG: ${this.$options.name}.saveHospitalizationData() Update this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.put(`${this.baseUrl}/med_hospitalization/${this.editFormData.id}`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                this.gotHospitalizationData();
                            }
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveHospitalizationData() New this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.post(`${this.baseUrl}/med_hospitalization`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                // console.log(`DEBUG: ${this.$options.name}.saveHospitalizationData() this.editFormData=`, this.editFormData);
                                this.gotHospitalizationData();
                            }
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },

            async saveAccommodationData() {
                // console.log(`DEBUG: ${this.$options.name}.saveAccommodationData() this.isHospitalizationModified=${this.isHospitalizationModified}`);
                if (this.isAccommodationModified) {
                    try {
                        if (typeof this.accommodationFormData.id != 'undefined') {
                            // Update
                            // console.log(`DEBUG: ${this.$options.name}.saveAccommodationData() Update this.accommodationFormData=`, this.accommodationFormData);
                            let data = (await this.authenticatedAxios.put(`${this.baseUrl}/med_accommodation/${this.accommodationFormData.id}`, this.accommodationFormData)).data;
                            if (data) {
                                this.accommodationFormData = data;
                                this.isAccommodationModified = false;
                                this.accommodationOldFormData = tools.deepCopy(this.accommodationFormData);
                                this.itemModified();
                            }
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveAccommodationData() New this.accommodationFormData=`, this.accommodationFormData);
                            let data = (await this.authenticatedAxios.post(`${this.baseUrl}/med_accommodation`, this.accommodationFormData)).data;
                            if (data) {
                                this.accommodationFormData = data;
                                this.isAccommodationModified = false;
                                this.accommodationOldFormData = tools.deepCopy(this.accommodationFormData);
                                this.itemModified();
                                // console.log(`DEBUG: ${this.$options.name}.saveAccommodationData() this.accommodationFormData=`, this.accommodationFormData);
                            }
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },

            itemFocused(item, templateName) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() item=${item}`);
                this.currentFormItem = item;
                this.currentAttribute = templateName ? templateName : item;
                // this.currentFormItemValue = this.editFormData[this.currentFormItem.split('.')[1]];
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() this.currentFormItemValue=${this.currentFormItemValue}`);
            },

            itemModified() {
                this.isHospitalizationModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                this.isAccommodationModified = JSON.stringify(this.accommodationOldFormData) !== JSON.stringify(this.accommodationFormData);
                this.isModified = this.isHospitalizationModified || this.isAccommodationModified;
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.isModified=`, this.isModified);
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.oldFormData=`, JSON.stringify(this.oldFormData));
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.editFormData=`, JSON.stringify(this.editFormData));
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
                this.setBreadcrumb();
            },

            async CommitDocument() {
                await this.saveData();
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/med-hospitalization-commit/${this.itemId}`);
                    tools.displaySuccessSave(this);
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async processRollback() {
                this.isDisplayWarningRollback = false;
                await this.saveData();
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/med-hospitalization-rollback/${this.itemId}`);
                    tools.displaySuccessSave(this);
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async printItem() {
                this.saveData();
                tools.printForm(
                    this,
                    {
                        // taskId: `print.${this.$options.name}`,
                        template: 'med-hospitalization',
                        consultation: JSON.parse(JSON.stringify(this.editFormData)),
                        visit: { visit_rec_id: this.visitId },
                    }
                );
                // console.log(`DEBUG: ${this.$options.name}.printItem() printData=`, printData);
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            this.editFormData.visit_rec_id = this.visitId;
            this.accommodationFormData.visit_open_id = this.visitId;
        },

        async mounted() {
            if (this.visitId) {
                await this.getData();
                await this.checkIsReadyToRollback();
            } else {
                this.setBreadcrumb();
                this.checkButtons();
            }
            this.oldFormData = tools.deepCopy(this.editFormData);
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.clientId=${this.clientId}, this.visitId=${this.visitId}`);
        },
    }
</script>

<style>
</style>
