<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-29
-->

<template>
    <div class="form-group" :class="{ 'field-required': required }">
        <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label :for="id" v-if="label && (labelPosition === 'before')" :title="required ? $t('lib.required') : ''" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>
        <!-- <label v-if="required" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{label}}<sup class="required-mark">*</sup>
        </label>
        <label v-if="!required">{{label}}</label> -->
        <!-- <input
            class="form-control-file"
            type="file"
            :required="required"
            @change="itemModified($event)"
            :placeholder="placeholder"
            :disabled="disabled"
        /> -->
        <div class="custom-file">
            <input
                type="file"
                :id="id"
                :required="true"
                @change="itemModified($event)"
                :placeholder="placeholder"
                :disabled="disabled"
                :class="{
                    'custom-file-input': !readonly,
                    'form-control-plaintext': readonly,
                    'is-invalid': !!errorMessage,
                    'field__required-empty': required && (!itemFilename || itemFilename == '')
                }"
                :style="`${inputStyle}`"
            >
            <label class="custom-file-label" :for="id">{{ itemFilename || $t('lib.chooseOrDropFileHere') }}</label>
            <!-- <div class="invalid-feedback">Invalid file</div> -->
        </div>
        <!-- <b-form-file
            :id="id"
            v-model="file"
            :state="Boolean(file)"
            :placeholder="$t('lib.chooseOrDropFileHere')"
            :drop-placeholder="$t('lib.dropFileHere')"
            :required="required"
            :disabled="disabled"
            @change="itemModified($event)"
        /> -->
        <!-- <span>{{ itemFilename }}</span> -->
        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    export default {
        name: 'FieldFile',

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: File,
            filename: String,
            placeholder: String,
            disabled: Boolean,
            required: Boolean,
            readonly: Boolean,
            inputStyle: String,
            errorMessage: String,
        },

        // =============== Data ===============
        data() {
            return {
                itemFilename: null,
                file: null,
                // itemData: null,
            }
        },

        // =============== Watch ===============
        watch: {
            // value() {
            //     this.filename = this.value;
            //     // console.log(`DEBUG: ${this.$options.name}(${this.label}).watch.value this.value=`, this.value);
            // },
            file() {
                // console.log(`DEBUG: ${this.$options.name}(${this.label}).watch.file this.file=`, this.file);
                // this.filename = this.value;
            },
            filename() {
                // console.log(`DEBUG: ${this.$options.name}(${this.label}).watch.file this.file=`, this.file);
                this.itemFilename = this.filename;
            },
        },

        // =============== Methods ===============
        methods: {
            itemModified(evt) {
                let files = evt.target.files || evt.dataTransfer.files;
                if (files.length) {
                    let file = files[0];
                    // console.log(`DEBUG: ${this.$options.name}.itemModified() file=`, file);
                    this.itemFilename = file.name;
                    // this.$emit('update:value', e.target.value);
                    this.$emit('update:value', file);
                    this.$emit('update:filename', this.itemFilename);
                    // e.target.files[0]
                    this.$emit('modified');
                }
            }
        },

        // =============== Life cycle ===============
        beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.itemModified() this.filename=${this.filename}`);
            this.itemFilename = this.filename;
        },
    }
</script>

<style scoped>
    /* .form-group .custom-file {
        font-size: 1rem;
    } */

    .custom-file-input ~ .custom-file-label::after {
        content: 'Browse';
    }

    .custom-file-input:lang(en) ~ .custom-file-label::after {
        content: 'Browse';
    }

    .custom-file-input:lang(ru) ~ .custom-file-label::after {
        content: 'Выбрать';
    }
</style>
