<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-29
-->

<!--
    labelPosition: none | before | above | float
-->

<template>
    <div
        :class="{
            'field-required': required,
            'form-group': true,
            'form-inline': labelPosition === 'before',
            'form-label-group': labelPosition === 'float'
        }"
    >
        <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label :for="id" v-if="label && (labelPosition === 'before')" :title="required ? $t('lib.required') : ''" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <div class="field-string__label-spacer" v-if="!label && (labelPosition === 'above')" ></div>
        
        <input
            v-if="!displayonly"
            :id="id"
            type="text"
            v-model="itemData"
            :required="required"
            :placeholder="placeholder || (labelPosition == 'float' ? label : placeholder)"
            :disabled="disabled"
            :readonly="readonly"
            :maxlength="maxlength"
            :autocomplete="autocomplete"
            :class="{
                'form-control': !readonly,
                'form-control-plaintext': readonly,
                'is-invalid': isInvalid || errorMessage,
                'is-valid': isValid,
                'field__readonly': readonly || disabled,
                'field__disabled': disabled,
                'field__required-empty': required && (!itemData || itemData == '')
            }"
            :style="`${inputStyle}`"
            @input="onInput($event)"
            @change="onChange($event)"
            @focus="itemFocused($event)"
        />
        <span v-if="displayonly">{{ value }}</span>

        <label :for="id" v-if="labelPosition === 'float'" :title="required ? $t('lib.required') : ''" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'FieldString',

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: String,
            // defaultValue: String, !!!!!
            placeholder: String,
            disabled: Boolean,
            readonly: Boolean,
            displayonly: Boolean,
            required: Boolean,
            // saveState: String, !!!!!
            maxlength: Number,
            autocomplete: String,
            isInvalid: Boolean,
            isValid: Boolean,
            // style // reserved word default attribute, implements to top-level template object
            inputStyle: String,
            errorMessage: String,
        },

        // =============== Data ===============
        data() {
            return {
                itemData: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            presentation() {
                // if (this.decimalPlaces) return this.value.toFixed(this.decimalPlaces);
                // if (this.decimalPlaces) return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(this.value);
                if (this.decimalPlaces) return new Intl.NumberFormat('ru-RU', { minimumFractionDigits:this.decimalPlaces }).format(this.value); //!!!!
                return this.value;
            },
        },

        // =============== Watch ===============
        watch: {
            value() {
                this.itemData = this.value;
                // console.log(`DEBUG: ${this.$options.name}(${this.label}).watch.value this.value=`, this.value);
            },
        },

        // =============== Methods ===============
        methods: {
            itemFocused(evt) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() evt=`, evt);
                this.$emit('focus');
            },

            onInput(evt) {
                // console.log(`DEBUG: ${this.$options.name}.onInput() evt=`, evt);
                // console.log(`DEBUG: ${this.$options.name}.onInput() this.itemData=`,  this.itemData);
                // this.$emit('update:value', e.target.value.trim());
                this.$emit('update:value', !this.itemData || this.itemData.trim() == '' ? null : this.itemData.trim() );
                this.$emit('modified');
            },

            onChange(evt) {
                // console.log(`DEBUG: ${this.$options.name}.onChange() evt=`, evt);
                // this.$emit('update:value', e.target.value.trim());
                this.$emit('update:value', !this.itemData || this.itemData.trim() == '' ? null : this.itemData.trim() );
                this.$emit('change');
                this.$emit('modified');
                // this.onInput(evt);
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemData = this.value;
            if (!this.itemData && this.saveState) {
                // Restore stored value
                let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() url response=`, response);
                let item = response.data.rows[0];
                if (item) this.itemData = item.value;
                this.itemModified(this.itemData);
            }
            if (!this.itemData && this.saveSessionState) {
                let sessionData = JSON.parse(sessionStorage.getItem(this.saveSessionState));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() sessionData=`, sessionData);
                if (sessionData) this.itemData = sessionData;
                this.itemModified(this.itemData);
            }
            if (!this.itemData && typeof this.defaultValue != 'undefined' && this.defaultValue !== null) {
                this.itemData = this.defaultValue;
                this.itemModified(this.itemData);
            }
        },
        update() {
            this.itemData = this.value;
        },
    }
</script>

<style>
    .field-string__label-spacer {
        height:25px;
    }
</style>
