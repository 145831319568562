<!--
    Author: Eduard Grebenyukov
    Date: 2020-03-21
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <div class="alert alert-danger" role="alert">
            {{ $t('AccessDenied.AccessDenied') }}
        </div>
    </TemplatePageStandard>
</template>

<script>
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    export default {
        name: 'AccessDenied',
        components: { TemplatePageStandard },
        data() {
            return {
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('AccessDenied.AccessDenied') },
                ]
            }
        }
    }
</script>

<style scoped>
    #page-wrap {
        background-color: #fff;
    }
</style>
