/*
    Author: Eduard Grebenyukov
    Date: 2020-01-08
*/

import { i18n } from '@/locales';
import { appCode } from './appCode';

// console.log(`DEBUG: /store/constants i18n.locale=`, i18n.locale);
// console.log(`DEBUG: /store/constants process.env=`, process.env);

// const appCode = 'heamis';
const appName = 'Heamis';
// const baseUrl = process.env.VUE_APP_ROOT_API;
// const baseUrl = `http://localhost:3000/${appCode}/api`;
// const baseUrl = `http://localhost:5000/api`;
// const baseUrl = '/api';
const baseHost = process.env.NODE_ENV === 'production' ? `` : `http://localdev:5000`;
const baseUrl = `${baseHost}/api`;
const socketUrl = '/api/socket';

const loginUrl = baseUrl + '/auth/login';
const logoutUrl = baseUrl + '/auth/logout';
const checkAuthUrl = baseUrl + '/auth/status';

//const organizationUrl = `${baseUrl}/organizations`;
//const worklistUrl = `${baseUrl}/worklist`;
//const systemSettingUrl = `${baseUrl}/systemsettings`;

const sexKind = [
    { code: 'f', name: i18n.t('attribute.core_person.sex_kind.female'), name_short: i18n.t('attribute.core_person.sex_kind.female').substring(0,1) },
    { code: 'm', name: i18n.t('attribute.core_person.sex_kind.male'), name_short: i18n.t('attribute.core_person.sex_kind.male').substring(0,1) },
];

const evenOdd = [
    { id: 0, name: i18n.t('app.even') },
    { id: 1, name: i18n.t('app.odd') },
];

const direction = [
    { code: 'in', name: i18n.t('crm.in') },
    { code: 'out', name: i18n.t('crm.out') },
];

export { appCode, appName, baseHost, baseUrl, socketUrl, loginUrl, logoutUrl, checkAuthUrl, sexKind, evenOdd, direction };
