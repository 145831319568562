/*
    Author: Eduard Grebenyukov
    Date: 2021-11-20
*/

let visibilityMixin = {
    // =============== Data ===============
    data() {
        return {
            scanHandler: null,
        }
    },

    // =============== Computed ===============
    computed: {
        // isPageHidden() {
        //     return document.hidden;
        // },
    },

    // =============== Methods ===============
    methods: {
        getPageHidden() {
            return document.hidden;
        },

        onVisibilityChanged(evt) {
            // console.log(`DEBUG: ${this.$options.name}.onVisibilityChanged() document.visibilityState=`, document.visibilityState);
            // console.log(`DEBUG: ${this.$options.name}.onVisibilityChanged() document.hidden=`, document.hidden);
            // console.log(`DEBUG: ${this.$options.name}.onVisibilityChanged() evt=`, evt);
            // console.log(`DEBUG: ${this.$options.name}.onVisibilityChanged() this.getPageHidden()=`, this.getPageHidden());
        },
    },

    // =============== Life cycle ===============
    async beforeMount() {
        // console.log(`DEBUG: ${this.$options.name}.beforeMount()`);
        // document, not window due to safari bug
        // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
        document.addEventListener("visibilitychange", this.onVisibilityChanged);
    },

    beforeDestroy() {
        document.removeEventListener('visibilitychange', this.onVisibilityChanged);
    },
}

export default visibilityMixin;
