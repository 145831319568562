<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-02
-->

<template>
    <div class="next-visit">
        <template v-if="!task">
            <h6>{{ $t('component.PlanNextVisit.planNextVisit') }}</h6>
            <!-- <div> {{ visitId }} </div> -->
            <div class="button-bar flex-row justify-content-start">
                <slot name="buttons"></slot>
                <button
                    v-for="mm of [ 1, 3, 6, 12 ]"
                    :key="`btn-${mm}`"
                    @click.prevent="planNextVisitPressed(mm)"
                    class="btn btn-sm btn-outline-primary mr-1"
                >
                    <i class="far fa-calendar-plus"></i>
                    {{ sprintf($t('component.PlanNextVisit.afterMonths'), mm) }}
                </button>
            </div>
        </template>
        <b-alert :show="!!task" >
            <i class="fas fa-info-circle alert-icon"></i> {{ taskTimeStr }}
            <ButtonCustom
                v-if="!!task"
                :text="$t('component.PlanNextVisit.nextVisitOpenTask')"
                buttonClass="btn-outline-secondary btn-sm ml-1"
                iconClass="fas fa-link fa-fw"
                @click="openTaskPressed()"
            />
        </b-alert>
        <ModalWindow
            v-if="isDisplayModalCommentary"
            :header="$t('component.PlanNextVisit.planNextVisit')"
            width="90%" max-width="600px"
            :buttons="[ 'ok', 'cancel' ]"
            @ok="processPlanNextVisit"
            @cancel="isDisplayModalCommentary = false"
        >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <FieldDate :label="$t('attribute.common.date_of')" :value.sync="plannedDate" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <FieldText :label="$t('attribute.common.commentary')" :value.sync="commentary" />
                    </div>
                </div>
            </div>
        </ModalWindow>
    </div>
</template>

<script>
    const sprintf = require('sprintf-js').sprintf;
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import FieldText from '@/components/lib/controls/FieldText';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    export default {
        name: 'PlanNextVisit',

        components: { ModalWindow, FieldText, FieldDate, ButtonCustom },

        // =============== Props ===============
        props: {
            clientId: Number,
            visitId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                task: null,
                isDisplayModalCommentary: false,
                plannedDate: null,
                commentary: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),
            taskTimeStr() {
                return ( this.task ? sprintf(this.$t('component.PlanNextVisit.nextVisitPlannedAt'), moment(this.task.time_end).format('LL')) : '' );
            },
        },

        // =============== Methods ===============
        methods: {
            sprintf(...args) { return sprintf(...args) },

            async getData() {
                try {
                    let taskType = (await this.authenticatedAxios.get(`${this.baseUrl}/enum/task_type/plan_visit`)).data;
                    let filter = tools.getFilterString({
                        where:[
                            { task_type_id: taskType.id },
                            { visit_rec_id: this.visitId },
                        ],
                        order:[ 'time_end' ],
                    });
                    this.task = (await this.authenticatedAxios.get(`${this.baseUrl}/task_rec/${filter}`)).data.rows[0];
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.task=`, this.task);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            planNextVisitPressed(months) {
                this.isDisplayModalCommentary = true;
                this.plannedDate = moment().add(months, 'months').format(moment.HTML5_FMT.DATE);
                this.commentary = null;
                // console.log(`DEBUG: ${this.$options.name}.processPlanNextVisit(${this.plannedDate})`);
            },

            async processPlanNextVisit() {
                // console.log(`DEBUG: ${this.$options.name}.processPlanNextVisit(${this.plannedDate}), s1=${moment(this.plannedDate).format('L')}, s2=${moment(this.plannedDate).format(moment.HTML5_FMT.DATE)}`);
                this.isDisplayModalCommentary = false;
                let plannedDateMoment = moment(this.plannedDate);
                try {
                    let taskType = (await this.authenticatedAxios.get(`${this.baseUrl}/enum/task_type/plan_visit`)).data;
                    let visit = (await this.authenticatedAxios.get(`${this.baseUrl}/v_visit/${this.visitId}`)).data;
                    let client = (await this.authenticatedAxios.get(`${this.baseUrl}/v_client/${visit.core_client_id}`)).data;
                    let taskName = sprintf(
                        this.$t('component.PlanNextVisit.composeName'),
                        taskType.name,
                        client.code,
                        client.shortname,
                        visit.user_shortname,
                        plannedDateMoment.format('L')
                    );
                    let taskDescription = sprintf(
                        this.$t('component.PlanNextVisit.composeDescription'),
                        taskType.name,
                        client.code,
                        client.fullname,
                        visit.user_shortname,
                        plannedDateMoment.format('LL'),
                        this.commentary ? this.commentary : ''
                    );
                    // console.log(`DEBUG: ${this.$options.name}.processPlanNextVisit(${this.plannedDate}) taskDescription=`, taskDescription);
                    let taskRow = {
                        task_type_id:           taskType.id,
                        core_client_id:         this.clientId,
                        visit_rec_id:           this.visitId,
                        initiator_user_id:      this.currentUser.id,
                        initiator_workplace_id: this.currentUser.currentWorkplace ? this.currentUser.currentWorkplace.id : null,
                        time_start:             moment(plannedDateMoment).subtract(1, 'weeks').format(moment.HTML5_FMT.DATE), // !!! move 1 week constant to core_setting
                        time_end:               plannedDateMoment.format(moment.HTML5_FMT.DATE),
                        name:                   taskName,
                        description:            taskDescription,
                        // performer_role_id: null,
                        // performer_user_id: null,
                        // performer_workplace_id: null,
                    };
                    await this.authenticatedAxios.post(`${this.baseUrl}/task`, taskRow);
                    await this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            openTaskPressed() {
                this.$router.push({ name: 'tasks/task-edit', params: { taskId:this.task.id } });
            },

        },

        // =============== Life cycle ===============
        async beforeMount() {
            await this.getData();
        },
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.clientId=${this.clientId}, this.visitId=${this.visitId}`);
        },
    }
</script>

<style>
    .next-visit .button-bar {
        padding: 0.75rem 0.75rem 1.5rem 0;
    }
    .alert-icon {
        font-size: 2rem;
        margin-right: 1rem;
    }
</style>
