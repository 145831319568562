<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-18
-->

<template>
    <StandardRegion id="visit-form-template" :title="title || attributeName" regionClass="sticky-4">

        <!-- <template #title>
            {{ $t(attributeName ? `VisitDetails.${attributeName}` : 'Templates.templates') }}
        </template> -->

        <div class="visit-form-template__buttons">
            <button
                @click.prevent="selectTemplateType('personal')"
                class="btn btn-outline-secondary btn-sm ml-2"
                :class="{ active:templateType == 'personal' }"
                :disabled="!attributeName"
                :title="$t('Templates.personal')"
            ><i class="fas fa-star"></i> {{ $t('component.VisitFormTemplate.personal') }}</button>
            <button
                @click.prevent="selectTemplateType('general')"
                class="btn btn-outline-secondary btn-sm ml-2"
                :class="{ active:templateType == 'general' }"
                :disabled="!attributeName"
                :title="$t('Templates.general')"
            ><i class="fas fa-users"></i> {{ $t('component.VisitFormTemplate.general') }}</button>
            <button
                v-if="isEditEnabled"
                @click.prevent="createTemplateFromAttribute"
                class="btn btn-warning btn-sm ml-2"
                :disabled="!attributeName"
                :title="$t('Templates.create')"
            ><i class="fas fa-plus"></i></button>
            <!-- <button
                v-if="isEditEnabled"
                @click.prevent="createTemplateFromAttribute"
                class="btn btn-warning btn-sm ml-2"
                :disabled="!attributeName"
                :title="$t('Templates.createFromAttribute')"
            ><i class="fas fa-angle-right"></i></button> -->
            <!-- <div @click="createTemplateFromDocument" class="btn btn-warning btn-sm ml-2" :title="$t('Templates.createFromDocument')">
                <i class="fas fa-angle-double-right"></i>
            </div> -->
        </div>
        <div class="visit-form-template__wrapper">
            <TreeView
                :treeData="attributeTemplates"
                folderStyle="font-weight: bold;"
                selectedContextStyle="box-shadow: inset 0 0 5px #007bff88;"
                @select="selectTemplate($event)"
                @opencontextmenu="showContextMenu($event)"
            />
            <div v-if="!attributeName" class="visit-form-template__no-attribute-selected">{{ $t('Templates.noAttributeSelected') }}</div>
            <!-- <div v-if="attributeName && attributeTemplates.length == 0" @contextmenu.prevent="showContextMenu({ $event, item:{ code:'add' } })">
                {{ $t('Templates.noTemplates') }}
            </div> -->
            <div v-if="attributeName && attributeTemplates.length == 0" class="visit-form-template__no-templates">
                {{ $t('Templates.noTemplates') }}
            </div>
        </div>
        <VisitFormTemplateEdit
            v-if="isDisplayModalEditor"
            :templateId="templateId"
            :visitTypeId="visitTypeId"
            :userId="userId"
            :attributeName="attributeName"
            :attributeValue="attributeValue"
            @close-modal="closeModalEditor($event)"
        />

        <VueContext ref="contextMenu">
            <template v-slot:default="child">
                <li><a id="context_select_item" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.select') }}</a></li>
                <hr v-if="isEditEnabled" class="context-divider">
                <!-- <li><a v-if="isEditEnabled" id="context_edit_add" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.add') }}</a></li> -->
                <li><a v-if="isEditEnabled" id="context_edit_item" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.edit') }}</a></li>
                <!-- <li><a v-if="isEditEnabled" id="context_delete_item" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.delete') }}</a></li> -->
            </template>
        </VueContext>

    </StandardRegion>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import StandardRegion from '@/components/lib/regions/StandardRegion';
    // import NavigationTabs from '@/components/lib/regions/NavigationTabs';
    import VisitFormTemplateEdit from '@/components/pages/visits/VisitFormTemplateEdit';
    import TreeView from '@/components/lib/regions/TreeView';
    import { VueContext } from 'vue-context';

    export default {
        name: 'VisitFormTemplate',
        components: { StandardRegion, VisitFormTemplateEdit, TreeView, VueContext },

        // =============== Props ===============
        props: {
            visitTypeId: Number,
            specialityId: Number,
            // userId: Number,
            formItemName: String,
            attributeName: String,
            title: String,
            // attributeValue: String,
            formData: Object,
        },

        // =============== Data ===============
        data() {
            return {
                attributeTemplates: [],
                attributeTemplatesPersonal: [],
                attributeTemplatesGeneral: [],
                documentTemplates: [],
                documentTemplatesPersonal: [],
                documentTemplatesGeneral: [],
                templateType: 'general',
                isDisplayModalEditor: false,
                templateId: null,
                userId: null,
                // attributeValue: null,
                editFormData: {},
                isShowContextMenu: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),

            attributeValue() { return this.editFormData[this.formItemName]; },

            isEditEnabled() { return this.templateType == 'personal'; },
        },

        // =============== Watch ===============
        watch: {
            formItemName() {
                // this.attributeValue = this.editFormData[this.formItemName];
                this.getData();
            },

            templateType() {
                this.chooseTemplates();
            },

            formData() {
                // !!!!! user deep watch here !!!!!
                this.editFormData = this.formData;
                // console.log(`DEBUG: ${this.$options.name}.watch.formData() this.editFormData=`, this.editFormData);
            },
        },

        // =============== Methods ===============
        methods: {
            chooseTemplates() {
                if (this.templateType == 'personal') {
                    this.attributeTemplates = tools.listToTree(this.attributeTemplatesPersonal);
                    this.documentTemplates = tools.listToTree(this.documentTemplatesPersonal);
                    this.userId = this.currentUser.id;
                } else {
                    this.attributeTemplates = tools.listToTree(this.attributeTemplatesGeneral);
                    this.documentTemplates = tools.listToTree(this.documentTemplatesGeneral);
                    this.userId = null;
                }
                // console.log(`DEBUG: ${this.$options.name}.chooseTemplates() this.attributeTemplates=`, this.attributeTemplates);
            },

            async getData() {
                // console.log(`DEBUG: ${this.$options.name}.getData() this.attributeName=${this.attributeName}`);
                try {
                    if (this.attributeName) {
                        if (this.currentUser.id) {
                            let attributeFilterPersonalString = tools.getFilterString(
                                {
                                    where:[
                                        // { visit_type_id:this.visitTypeId },
                                        { core_user_id:this.currentUser.id},
                                        { attribute_name:this.attributeName },
                                    ],
                                    order:[ 'sort_order' ],
                                }
                            );
                            // console.log(`DEBUG: ${this.$options.name}.getData() url=${this.baseUrl}/visit_template${attributeFilterPersonal}`);
                            this.attributeTemplatesPersonal = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_template${attributeFilterPersonalString}`)).data.rows;
                            // console.log(`DEBUG: ${this.$options.name}.getData() this.attributeTemplatesPersonal=`, this.attributeTemplatesPersonal);
                            // this.attributeTemplatesPersonal = this.attributeTemplatesPersonal.map();
                        } else {
                            this.attributeTemplatesPersonal = [];
                        }
                        let attributeFilterGeneralString = tools.getFilterString(
                            {
                                where:[
                                    // { visit_type_id:this.visitTypeId },
                                    { core_user_id:null },
                                    { attribute_name:this.attributeName },
                                ],
                                order:[ 'sort_order' ],
                            }
                        );
                        this.attributeTemplatesGeneral = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_template${attributeFilterGeneralString}`)).data.rows;
                        // console.log(`DEBUG: ${this.$options.name}.getData() this.attributeTemplatesGeneral=`, this.attributeTemplatesGeneral);
                    } else {
                        this.attributeTemplatesPersonal = [];
                        this.attributeTemplatesGeneral = [];
                    }
                    if (this.currentUser.id) {
                        let documentFilterPersonalString = tools.getFilterString(
                            {
                                where:[
                                    // { visit_type_id:this.visitTypeId },
                                    { core_user_id:this.currentUser.id },
                                    { attribute_name:null },
                                ],
                                order:[ 'sort_order' ],
                            }
                        );
                        this.documentTemplatesPersonal = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_template${documentFilterPersonalString}`)).data.rows;
                    } else {
                        this.documentTemplatesPersonal = [];
                    }
                    let documentFilterGeneralString = tools.getFilterString(
                        {
                            where:[
                                // { visit_type_id:this.visitTypeId },
                                { core_user_id:null },
                                { attribute_name:null },
                            ],
                            order:[ 'sort_order' ],
                        }
                    );
                    this.documentTemplatesGeneral = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_template${documentFilterGeneralString}`)).data.rows;

                    if (this.attributeName && this.attributeTemplatesPersonal.length > 0)
                        this.selectTemplateType('personal');
                    else
                        this.selectTemplateType('general');
                    this.chooseTemplates();
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.attributeTemplatesPersonal(${this.userId})=`, this.attributeTemplatesPersonal);
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.attributeTemplatesGeneral=`, this.attributeTemplatesGeneral);
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.documentTemplates=`, this.documentTemplates);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            selectTemplateType(templateType) {
                this.templateType = templateType;
            },

            showContextMenu(e) {
                // console.log(`DEBUG: ${this.$options.name}.showContextMenu() e=`, e);
                // this.isShowContextMenu = true;
                this.$refs.contextMenu.open(e.$event, e.item);
            },
            async onContextMenu(e, item) {
                // console.log(`DEBUG: ${this.$options.name}.onContextMenu() e.target.id=`, e.target.id, ', item=', item);
                if (e.target.id === 'context_select_item')
                    this.selectTemplate(item);
                if (e.target.id === 'context_add_item')
                    this.editTemplate(item.id);
                if (e.target.id === 'context_edit_item')
                    this.editTemplate(item.id);
                if (e.target.id === 'context_delete_item')
                    this.deleteTemplate(item.id);
            },

            selectTemplate(template) {
                // console.log(`DEBUG: ${this.$options.name}.selectTemplate() template=`, template);
                // console.log(`DEBUG: ${this.$options.name}.selectTemplate() this.formItemName=${this.formItemName}, template.template_text=${template.template_text}, this.editFormData=`, this.editFormData);
                let value = (template.template_text && template.template_text.length > 0 ? template.template_text : template.name);
                if (this.editFormData[this.formItemName] && this.editFormData[this.formItemName].length > 0) {
                    this.editFormData[this.formItemName] += ' ' + value;
                } else {
                    this.editFormData[this.formItemName] = value;
                }
                this.$emit('update:formData', this.editFormData);
                this.$emit('modified');
            },

            addTemplate() {
                this.isDisplayModalEditor = true;
            },

            editTemplate(id) {
                this.templateId = id;
                this.isDisplayModalEditor = true;
            },

            createTemplateFromAttribute() {
                // console.log(`DEBUG: ${this.$options.name}.createTemplateFromAttribute() this.visitTypeId=${this.visitTypeId}, this.formItemName=${this.formItemName} this.attributeValue=${this.attributeValue}`);
                this.templateId = null;
                this.isDisplayModalEditor = true;
            },

            // createTemplateFromDocument() {
            //     console.log(`DEBUG: ${this.$options.name}.createTemplateFromDocument() this.visitTypeId=${this.visitTypeId}, this.editFormData=`, this.editFormData);
            //     this.isDisplayModalEditor = true;
            // },

            async deleteTemplate(id) {
                try {
                    await this.authenticatedAxios.delete(`${this.baseUrl}/visit_template/${id}`);
                    tools.displaySuccessDelete(this);
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            closeModalEditor(isRefresh) {
                // console.log(`DEBUG: ${this.$options.name}.closeModalEditor()`);
                this.isDisplayModalEditor = false;
                if (isRefresh)
                    this.getData();
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.editFormData = this.formData;
            this.getData();
            // this.navigationTab = this.currentTab;
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.clientId=${this.clientId}, this.visitId=${this.visitId}, this.navigationTab=${this.navigationTab}`);
            // await this.getVisitData();
            // this.setUrls();
        },
        async mounted() {
            // this.navigationTab = this.currentTab;
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.editFormData.complaints=${this.editFormData.complaints}, this.editFormData=`, this.editFormData);
        },
    }
</script>

<style>
    .visit-form-template__wrapper {
        /* padding: 0.5rem; */
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
    .visit-form-template__buttons {
        padding: 0.5rem 0;
        /* border-bottom: 1px solid #dee2e6; */
    }
    .visit-form-template__no-attribute-selected, .visit-form-template__no-templates {
        margin: -1rem 0 0.5rem 1rem;
    }
</style>
