<!--
    Author: Eduard Grebenyukov
    Date: 2021-06-12
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">

        <VisitDescription />

        <VisitTabs currentTab="visit-tasks" />

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col">
                    <Grid
                        ref="taskGrid"
                        :config="gridConfig"
                        @add-item="addItem"
                        @edit-item="editItem($event.id)"
                    >
                        <!-- @open-visit="openVisit($event)" -->
                        <template #buttons>
                            <FieldSwitch
                                id="isHideClosed"
                                :label="$t('component.Tasks.isHideClosed')"
                                labelPosition="after"
                                size="small"
                                :value.sync="filterData.isHideClosed"
                                :defaultValue="true"
                                :saveSessionState="`${$options.name}-isHideClosed`"
                                @modified="filterModified"
                            />
                            <FieldSwitch
                                id="isHideUntimely"
                                :label="$t('component.Tasks.isHideUntimely')"
                                labelPosition="after"
                                size="small"
                                :value.sync="filterData.isHideUntimely"
                                :defaultValue="true"
                                :saveSessionState="`${$options.name}-isHideUntimely`"
                                @modified="filterModified"
                            />
                            <!-- <FieldNumberPlain
                                v-if="true"
                                :label="$t('attribute.common.rows_qty')"
                                labelPosition="before"
                                :value.sync="filterData.row_qty"
                                :defaultValue="20"
                                :minValue="10"
                                :maxValue="10000"
                                :saveSessionState="`${$options.name}-row_qty`"
                                @modified="filterModified"
                            /> -->
                        </template>
                    </Grid>
                </div>
            </div>
        </div>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';
    import Grid from '@/components/lib/regions/Grid';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';

    export default {
        name: 'VisitTasks',
        components: {
            TemplatePageStandard, VisitDescription, VisitTabs, Grid, FieldSwitch,
        },

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                isDisplayVisitAdd: false,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.tasks') }),
                filterData: {
                    isHideClosed: false,
                    isHideUntimely: false,
                    // row_qty: 20,
                },
                gridConfig: {
                    apiUrl: null, // v_task
                    filter: null,
                    readonly: true,
                    columns: [
                        {
                            type:'icon', key:'icon', sortable:false, align:'center',
                            $title:(entry) => tools.getTaskStatusText(entry), 
                            $icon: entry => tools.getTaskIcon(entry),
                            $cellStyle: entry => `color:${tools.getTaskColor(entry)};`,
                        },
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), required:true, align:'right' },
                        { type:'datetime', key:'time_start', header:this.$t('attribute.common.time_start'), cellStyle:'white-space:nowrap;' },
                        { type:'datetime', key:'time_end', header:this.$t('attribute.common.term'), cellStyle:'white-space:nowrap;' },
                        { type:'select', key:'task_type_id', header:this.$t('enum.task_type'), required:true, source:'enum/task_type' },
                        { type:'string', key:'building_name_short', header:this.$t('object.core_building') },
                        { type:'string', key:'performer_name', header:this.$t('attribute.common.performer') },
                        { type:'string', key:'status_name_short', header:this.$t('attribute.common.status') },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        { type:'string', key:'initiator_name', header:this.$t('attribute.common.initiator') },
                    ],
                    row: {
                        $onRightClick: (visit, evt) => {
                            this.showContextMenu(evt, visit);
                        },
                    },
                    buttons: [ 'add', 'edit' ],
                    order: [ 'time_start' ],
                    saveSessionState: `${this.$options.name}.tasks`,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Methods ===============
        methods: {

            addItem() {
                this.$router.push({ name: 'tasks/task-new', params: { taskId:null, clientId:this.clientId, visitId:this.visitId } });
            },

            editItem(taskId) {
                this.$router.push({ name: 'tasks/task-edit', params: { taskId:taskId } });
            },

            setGridConfig() {
                // console.log(`DEBUG: ${this.$options.name}.setGridConfig() this.filterData.isHideClosed=`, this.filterData.isHideClosed);
                if (this.$refs.taskGrid && this.clientId && this.visitId) {
                    this.reportInProgress = true;
                    let whereArray = [];

                    this.gridConfig.apiUrl = `v_task`;

                    if (this.clientId) whereArray.push({ core_client_id: this.clientId });
                    if (this.visitId) whereArray.push({ visit_rec_id: this.visitId });
                    if (this.filterData.isHideClosed) whereArray.push({ is_open: true });
                    if (this.filterData.isHideUntimely) whereArray.push({ is_untimely: false });

                    if (whereArray.length > 0) {
                        this.gridConfig.filter = { where:whereArray };
                    } else {
                        this.gridConfig.filter = null;
                    }

                    // this.gridConfig.paginationPageSize = this.filterData.row_qty;

                    this.$refs.taskGrid.refresh();

                    this.reportInProgress = false;
                }
            },

            filterModified() {
                this.setGridConfig();
            },

        },

        // =============== Life cycle ===============
        beforeMount() {
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.$router=`, this.$router);
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.visitId=`, this.visitId);
            this.setGridConfig();
        },
    }
</script>

<style>

</style>
