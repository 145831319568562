<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-22
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >

        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldString
                                    :label="$t('attribute.common.name')"
                                    :value.sync="editFormData.name"
                                    required
                                    @modified="itemModified('name', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('attribute.fin_contract.core_org_id')"
                                    :value.sync="editFormData.core_org_id"
                                    :source="sourceOrg"
                                    required
                                    @modified="itemModified('core_org_id', true)"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldDate
                                    :label="$t('attribute.common.date_from')"
                                    :value.sync="editFormData.date_from"
                                    required
                                    @modified="itemModified('date_from', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldDate
                                    :label="$t('attribute.common.date_to')"
                                    :value.sync="editFormData.date_to"
                                    @modified="itemModified('date_to', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('object.fin_source')"
                                    :value.sync="editFormData.fin_source_id"
                                    source="fin_source"
                                    required
                                    @modified="itemModified('fin_source_id', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('enum.contract_type')"
                                    :value.sync="editFormData.contract_type_id"
                                    source="enum/contract_type"
                                    required
                                    @modified="itemModified('contract_type_id', true)"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('attribute.fin_contract.min_balance')"
                                    :value.sync="editFormData.min_balance"
                                    @modified="itemModified('min_balance', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('attribute.fin_contract.contract_limit_sum')"
                                    :value.sync="editFormData.contract_limit_sum"
                                    @modified="itemModified('contract_limit_sum', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('attribute.fin_contract.client_pricelist_id')"
                                    :value.sync="editFormData.client_pricelist_id"
                                    source="fin_pricelist"
                                    required
                                    @modified="itemModified('client_pricelist_id', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('attribute.fin_contract.fin_currency_id')"
                                    :value.sync="editFormData.fin_currency_id"
                                    source="fin_currency"
                                    required
                                    @modified="itemModified('fin_currency_id', true)"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <FieldSwitch
                                    id="is_deposit"
                                    :label="$t('attribute.fin_contract.is_deposit')"
                                    :value.sync="editFormData.is_deposit"
                                    :defaultValue="false"
                                    :disabled="!!this.editFormData.id"
                                    @modified="itemModified('is_deposit', true)"
                                />
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <FieldSwitch
                                    id="is_permit_sum_exceeding"
                                    :label="$t('attribute.fin_contract.is_permit_sum_exceeding')"
                                    :value.sync="editFormData.is_permit_sum_exceeding"
                                    :defaultValue="false"
                                    @modified="itemModified('is_permit_sum_exceeding', true)"
                                />
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <FieldSwitch
                                    id="is_permit_qty_exceeding"
                                    :label="$t('attribute.fin_contract.is_permit_qty_exceeding')"
                                    :value.sync="editFormData.is_permit_qty_exceeding"
                                    :defaultValue="false"
                                    @modified="itemModified('is_permit_qty_exceeding', true)"
                                />
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <FieldSwitch
                                    id="is_permit_deposit_exceeding"
                                    :label="$t('attribute.fin_contract.is_permit_deposit_exceeding')"
                                    :value.sync="editFormData.is_permit_deposit_exceeding"
                                    :defaultValue="false"
                                    @modified="itemModified('is_permit_deposit_exceeding', true)"
                                />
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <FieldSwitch
                                    id="is_print_hide_price"
                                    :label="$t('attribute.fin_contract.is_print_hide_price')"
                                    :value.sync="editFormData.is_print_hide_price"
                                    :defaultValue="false"
                                    @modified="itemModified('is_print_hide_price', true)"
                                />
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <FieldSwitch
                                    id="is_permit_patient_wo_policy"
                                    :label="$t('attribute.fin_contract.is_permit_patient_wo_policy')"
                                    :value.sync="editFormData.is_permit_patient_wo_policy"
                                    :defaultValue="false"
                                    @modified="itemModified('is_permit_patient_wo_policy', true)"
                                />
                            </div>
                        </div>
                        <div class="form-row" v-if="false">
                            <div class="col">
                                <TabSwitch
                                    :value.sync="currentTab"
                                    defaultValue="payers"
                                    :source="navigationTabs"
                                    @modified="filterTabsModified"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <Grid ref="payersGrid" v-if="isDisplayGrids" :config="payersGridConfig" @modified="itemModified('payers', $event)" />

                                <Grid
                                    ref="programsGrid"
                                    v-if="isDisplayGrids"
                                    :config="programsGridConfig"
                                    @modified="itemModified('programs', $event)"
                                    @add-item="addProgram()"
                                    @edit-item="editProgram($event.id)"
                                />
                                <!-- <Grid
                                    ref="payers"
                                    v-if="currentTab == 'subdocuments'"
                                    :config="subdocumentsGridConfig"
                                    @add-item="addSubdocument()"
                                    @edit-item="editSubdocument($event.id)"
                                /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldString from '@/components/lib/controls/FieldString';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';

    import TabSwitch from '@/components/lib/controls/TabSwitch';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ContractEdit',

        components: {
            TemplatePageStandard,
            ButtonClose, ButtonSave, ButtonDelete,
            FieldString, FieldDate, FieldNumber, FieldSelect, FieldSwitch, TabSwitch,
            Grid,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                // isModified: false,
                isItemModified: false,
                isPayersModified: false,
                isProgramsModified: false,
                buttons: [],
                msgItemModified: '',
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.finances') },
                    { text: this.$t('MainMenu.finances-contracts'), to: `/finances/contracts` },
                    { text: this.$t('object.fin_contract') },
                ],
                navigationTabs: [
                    { id: 'payers', name: this.$t('component.ContractEdit.payers') },
                    { id: 'programs', name: this.$t('component.ContractEdit.programs') },
                    // { id: 'subdocuments', name: this.$t('component.ContractEdit.subdocuments') },
                ],
                currentTab: 'payers',
                editFormData: {
                    // id: null,
                    parent_id: null,
                    name: null,
                    date_from: null,
                    date_to: null,
                    core_org_id: null,
                    fin_currency_id: null,
                    fin_source_id: null,
                    contract_type_id: null,
                    min_balance: null,
                    client_pricelist_id: null,
                    contract_limit_sum: null,
                    is_template: false,
                    is_permit_sum_exceeding: false,
                    is_permit_qty_exceeding: false,
                    is_permit_deposit_exceeding: false,
                    is_print_hide_price: false,
                    is_permit_patient_wo_policy: false,
                    is_deposit: false,
                },
                oldFormData: {},
                payersGridConfig: {
                    apiUrl: `fin_contract_payer`,
                    filter: null,
                    title: this.$t('component.ContractEdit.payers'),
                    // readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'fin_contract_id', header:"fin_contract_id", required:true },
                        // { type:'select', key:'payer_org_id', header:this.$t('attribute.fin_contract_payer.payer_org_id'), source:'core_org', showEmptyRow:true, $readonly: (entry) => !!entry.payer_client_id },
                        {
                            type:'popuplist', key:'payer_org_id', header:this.$t('attribute.fin_contract_payer.payer_org_id'),
                            source:'core_org', $readonly: (entry) => !!entry.payer_client_id,
                            listConfig: {
                                readonly:true,
                                columns: [
                                    { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'50px' },
                                    { type:'string', key:'name', header:this.$t('object.core_client') },
                                    { type:'string', key:'tin', header:this.$t('attribute.core_org.tin') },
                                    { type:'string', key:'trrc', header:this.$t('attribute.core_org.trrc') },
                                ],
                                order: [ 'name' ],
                            },
                        },
                        {
                            type:'popuplist', key:'payer_client_id', header:this.$t('attribute.fin_contract_payer.payer_client_id'),
                            source:'v_client', keyLabel:'fullname', $readonly: (entry) => !!entry.payer_org_id,
                            listConfig: {
                                readonly:true,
                                columns: [
                                    { type:'string', key:'code', header:this.$t('attribute.common.code'), width:'50px' },
                                    { type:'string', key:'fullname', header:this.$t('object.core_client') },
                                    { type:'date', key:'dob', header:this.$t('attribute.core_person.dob') },
                                ],
                                order: [ 'fullname' ],
                            },
                        },
                    ],
                    order: [ 'id' ],
                    // displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                    // $onAdd: (grid) => {},
                    // $onEdit: (grid, item) => { editPayer(item.id) },
                },
                programsGridConfig: {
                    apiUrl: `fin_program`,
                    filter: null,
                    title: this.$t('component.ContractEdit.programs'),
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        // { type:'hidden', key:'fin_contract_id', header:'fin_contract_id', required:true },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        { type:'date', key:'date_from', header:this.$t('attribute.common.date_from') },
                        { type:'date', key:'date_to', header:this.$t('attribute.common.date_to') },
                        // { type:'date', key:'date_cancel', header:this.$t('attribute.common.date_cancel') },
                    ],
                    order: [ 'id' ],
                    // displaySearch: false,
                    buttons: [ 'add', 'edit' ],
                    // $onAdd: (grid) => { this.addProgram },
                    // $onEdit: (grid, item) => { this.editProgram(item.id) },
                },
                sourceOrg: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            isDisplayGrids() { return typeof this.editFormData.id !='undefined' },
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            filterTabsModified() {

            },

            addProgram() {
                // !!! check for unsaved data
                this.$router.push({ name: 'finances/program-new', props: { contractId: this.contractId, contractData: this.editFormData } });
            },

            editProgram(id) {
                // console.log(`DEBUG: ${this.$options.name}.editProgram id=`, id);
                // !!! check for unsaved data
                // this.$router.push({ name: 'finances/program-edit', params: { contractId: this.contractId, programId: id } });
                this.$router.push(`/finances/contract/${this.itemId}/program/${id}`);
            },

            setBreadcrumb() {
                // console.log(`DEBUG: ${this.$options.name}.setBreadcrumb core_workplace_id=`, this.$refs.core_workplace_id);
                // console.log(`DEBUG: core_workplace_id.itemPresentation=`, this.$refs.core_workplace_id.itemPresentation);
                // console.log(`DEBUG: core_workplace_id.getPresentation()=`, this.$refs.core_workplace_id.getPresentation());
            },

            checkModified() {
                this.isItemModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                this.isModified = this.isItemModified || this.isPayersModified || this.isProgramsModified;
            },

            gotData() {
                // this.isModified = false;
                this.isItemModified = false;
                this.msgItemModified = '';
                this.itemId = this.editFormData.id;

                let filter = { where:[
                    { fin_contract_id:this.editFormData.id },
                ]};
                this.payersGridConfig.filter = filter;
                this.programsGridConfig.filter = filter;
                this.payersGridConfig.columns.find(x => x.key === 'fin_contract_id').defaultValue = this.editFormData.id;
                // !!! why its works without .refresh()???
                // this.programsGridConfig.columns.find(x => x.key === 'fin_contract_id').defaultValue = this.editFormData.id;
                // console.log(`DEBUG: ${this.$options.name}.gotData fin_contract_id=`, this.payersGridConfig.columns.find(x => x.key === 'fin_contract_id'));
                // this.$refs.payersGrid.refresh();
                // this.$refs.programsGrid.refresh();

                this.setBreadcrumb();
                this.checkButtons();
            },

            async getData() {
                try {
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_contract/${this.itemId}`)).data;
                    if (data) {
                        this.editFormData = data;
                        this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                    }
                    // console.log(`DEBUG: ${this.$options.name}.getData data=`, this.editFormData);
                    this.gotData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isModified=${this.isModified}, this.editFormData=`, this.editFormData);
                if (this.isItemModified) {
                    //this.$v.$touch();
                    //if (!this.$v.$invalid) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            // console.log(`DEBUG: ${this.$options.name}.saveData() Update this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.put(`${this.baseUrl}/fin_contract/${this.editFormData.id}`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;                                
                                this.gotData();
                                tools.displaySuccessSave(this);
                            }
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveData() New this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.post(`${this.baseUrl}/fin_contract`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                                this.gotData();
                                // this.getData();
                                this.oldFormData = JSON.parse(JSON.stringify(this.editFormData)); // to prevent question
                                this.checkModified();
                                tools.displaySuccessSave(this);
                                this.$router.replace({ name: 'finances/contract-edit', params: { contractId:this.editFormData.id } });
                            }
                        }
                        this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                    // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                } else {
                    // no changes
                }
                if (this.isDisplayGrids) {
                    if (this.$refs.payersGrid) {
                        this.$refs.payersGrid.$emit('save');
                        this.isPayersModified = false;
                    }
                    if (this.$refs.programsGrid) {
                        this.$refs.programsGrid.$emit('save');
                        this.isProgramsModified = false;
                    }
                }
                this.checkModified();
            },

            itemModified(item, isTrue) {
                if (isTrue) {
                    if (item == 'payers') this.isPayersModified = true;
                    if (item == 'programs') this.isProgramsModified = true;
                }
                // console.log(`DEBUG: ${this.$options.name}.itemModified(${item}, ${this.isItemModified}, ${this.isPayersModified}, ${this.isProgramsModified})`);
                this.checkModified();
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
                this.setBreadcrumb();
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.baseUrl}/fin_contract/${this.itemId}`);
                        this.isItemModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },

        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemId = parseInt(this.$route.params.contractId);
            try {
                let org_type_id = await tools.getEnumId(this, 'org_type', 'medical');
                this.sourceOrg = 'core_org' + tools.getFilterString({ where:[{ org_type_id:org_type_id }]});
            } catch (err) {
                // console.error(`DEBUG: ${this.$options.name}.beforeMount() err=`, err);
                tools.displayApiError(this, err);
            }
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(${this.itemId})`);
            if (this.itemId) {
                await this.getData();
            } else {
                // new contract
                let currencyCode = (await tools.getSetting(this, 'currency_accounting')).value;
                let filterString = tools.getFilterString({ where:[{ code:currencyCode }]});
                let currency = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_currency${filterString}`)).data.rows[0];
                // console.log(`DEBUG: ${this.$options.name}.mounted(${this.itemId}) currencyCode=${currencyCode}, currency=`, currency);
                this.editFormData.fin_currency_id = currency.id;
                this.setBreadcrumb();
                this.checkButtons();
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
            this.checkModified();
        },
    }
</script>

<style>

</style>
