<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-07
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <div id="page-content">
            <Grid :config="gridConfig" @modified="gridModified($event)" />
        </div>
    </TemplatePageStandard>
</template>

<script>
    import $ from 'jquery';
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'EnumEdit',
        components: {
            TemplatePageStandard, Grid,
        },

        // =============== Data ===============
        data() {
            return {
                id: Number,
                isModified: Boolean,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-general-enum'), to:'/settings/general/enum_group' },
                    { text: '' },
                ],
                gridConfig: {
                    apiUrl: 'core_enum',
                    filter: null,
                    title: '',
                    columns: [
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), required: true,readonly:true, align:'right', width:'50px' },
                        { type:'hidden', key:'core_enum_group_id', header:this.$t('object.core_enum_group'), required:true, source:'core_enum_group' },
                        { type:'string', key:'code', header:this.$t('attribute.common.code'), required:true },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        { type:'string', key:'name_short', header:this.$t('attribute.common.name_short') },
                        { type:'number', key:'sort_order', header:this.$t('attribute.common.sort_order'), required:true },
                        { type:'checkbox', key:'is_archived', header:this.$t('attribute.common.is_archived'), defaultValue:false, width:'50px', align:'center;' },
                    ],
                    buttons: [ 'row-selector', 'add', 'save', 'delete' ],
                    order: [ 'sort_order', 'code' ],
                    displayToolbar: true,
                    saveSessionState: `${this.$options.name}`,
                }
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Watch ===============
        watch: {
            isModified() {
                if (this.isModified) {
                    $('#breadcrumb').addClass('modified');
                } else {
                    $('#breadcrumb').removeClass('modified');
                }
            },
        },

        // =============== Methods ===============
        methods: {
            async getData() {
                try {
                    let response = await this.authenticatedAxios.get(`${this.baseUrl}/core_enum_group/${this.id}`);
                    //this.editFormData = response.data;
                    //console.log('response=', response);
                    // this.gridConfig.title = this.$t('Role.usersWithRole') + ' "' + response.data.name + '"';
                    this.breadcrumb[this.breadcrumb.length - 1].text = response.data.name;
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
            
            gridModified(e) {
                this.isModified = e;
            }
        },

        // =============== Life cycle ===============
        beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.$route.params.id=`, this.$route.params.id);
            this.id = parseInt(this.$route.params.id);
            this.gridConfig.filter = { where:[
                { core_enum_group_id:this.id },
            ]};
            this.gridConfig.columns.find(x => x.key === 'core_enum_group_id').defaultValue = this.id;
        },

        async mounted() {
            await this.getData();
        },

    }
</script>

<style>
</style>
