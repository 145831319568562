<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-15
-->

<template>
    <div>
        <div class="btn-toolbar" style="padding: 0 4px 4px 0;" role="toolbar">
            <div class="h6">{{ $t('component.ClientEdit.beneficiary') }}</div>
            <div @click="addBeneficiary()" class="btn btn-warning btn-sm ml-2" :title="$t('component.ClientEdit.joinProgram')">
                <i class="fas fa-user-plus"></i>
                <!-- {{ $t('component.ClientEdit.joinProgram') }} -->
            </div>
        </div>
        <Grid ref="beneficiary" :config="beneficiaryGridConfig" @edit-item="editBeneficiary($event)" />
        <ClientBeneficiaryEdit
            v-if="isDisplayModalBeneficiaryEdit"
            @close-modal="closeModalBeneficiary($event)"
            :id="beneficiaryId"
            :clientId="clientId"
        />
    </div>
</template>

<script>
    import Grid from '@/components/lib/regions/Grid';
    import ClientBeneficiaryEdit from './ClientBeneficiaryEdit';

    export default {
        name: 'ClientBeneficiary',

        components: { Grid, ClientBeneficiaryEdit },

        // =============== Props ===============
        props: {
            clientId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                // beneficiary: [],
                // beneficiaryId: null,
                isDisplayModalBeneficiaryEdit: false,
                beneficiaryId: null,
                beneficiaryGridConfig: {
                    apiUrl: null,
                    filter: null,
                    // title: this.$t('component.ClientEdit.beneficiary'),
                    readonly: true,
                    columns: [
                        // { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        // { type:'hidden', key:'core_user_id', header:'User', required: true, source:'users', keyLabel:'username' },
                        { type:'string', key:'contract_name', header:this.$t('object.fin_contract') },
                        { type:'string', key:'program_name', header:this.$t('object.fin_program') },
                        { type:'string', key:'beneficiary_num', header:this.$t('attribute.fin_beneficiary.beneficiary_num') },
                        { type:'date', key:'date_from', header:this.$t('attribute.common.date_from') },
                        { type:'date', key:'date_to', header:this.$t('attribute.common.date_to') },
                        { type:'number', key:'beneficiary_limit_sum', header:this.$t('attribute.fin_beneficiary.beneficiary_limit_sum'), align:'right' },
                        { type:'number', key:'sum_payment_final', header:this.$t('attribute.common.sum_rendered'), align:'right' },
                    ],
                    order: [ 'date_from' ],
                    displayToolbar: false,
                    buttons: ['edit'],
                },
            }
        },

        // =============== Methods ===============
        methods: {

            setGridSettings() {
                // console.log(`DEBUG: ${this.$options.name}.setGridSettings() this.clientId=${this.clientId}`);
                let filter = { where:[
                    { core_client_id:this.clientId },
                ]};
                this.beneficiaryGridConfig.filter = filter;
                this.beneficiaryGridConfig.apiUrl = 'v_beneficiary';
            },

            addBeneficiary() {
                this.beneficiaryId = null;
                this.isDisplayModalBeneficiaryEdit = true;
            },

            editBeneficiary(beneficiary) {
                this.beneficiaryId = beneficiary.id;
                this.isDisplayModalBeneficiaryEdit = true;
            },

            closeModalBeneficiary(isRefresh) {
                this.isDisplayModalBeneficiaryEdit = false;
                if (isRefresh)
                    this.$refs.beneficiary.refresh();
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.clientId=`, this.clientId);
            this.setGridSettings();
        },
    }
</script>

<style>
</style>
