<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-08
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">

        <VisitDescription v-if="visitRowQty > 0" />

        <VisitTabs v-if="visitRowQty > 0" ref="visitTabs" :clientId="clientId" :currentTab="null" />

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm-12 col-xl-8">
                    <Grid
                        ref="visits"
                        :key="$route.fullPath"
                        :config="gridConfig"
                        @add-item="addVisit"
                        @select-visit="selectVisit($event)"
                        @updated="visitsUpdated"
                    >
                        <template #buttons>
                            <!-- <div class="custom-control custom-switch" style="padding-top:6px;">
                                <input type="checkbox" class="custom-control-input" id="is_only_debt" v-model="isHideCanceled" >
                                <label class="custom-control-label" for="is_only_debt">{{ $t('component.Visits.hideDeleted') }}</label>
                            </div> -->
                            <FieldSwitch
                                id="isHideCanceled"
                                :label="$t('component.Visits.isHideCanceled')"
                                labelPosition="after"
                                size="small"
                                :value.sync="isHideCanceled"
                                :defaultValue="true"
                                :saveSessionState="`${$options.name}-isHideCanceled`"
                            />
                        </template>
                    </Grid>
                </div>
                <div class="col-sm-12 col-xl-4">
                    <!-- Right sidebar -->
                    <StandardRegion v-if="lastVisit && lastVisit.id" :title="$t('app.details')">
                        <VisitDisplayDetails />
                    </StandardRegion>
                </div>
            </div>
        </div>

        <VisitAdd v-if="isDisplayVisitAdd" :clientId="clientId" @close-modal="closeVisitAdd($event)" />

        <VisitContextMenu mode="client" ref="visitContextMenu" @refresh-status="$refs.visits.refresh()" />

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import tools from '@/components/lib/tools';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';
    import VisitContextMenu from '@/components/pages/visits/VisitContextMenu';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import StandardRegion from '@/components/lib/regions/StandardRegion';
    import Grid from '@/components/lib/regions/Grid';
    import VisitDisplayDetails from '@/components/pages/visits/VisitDisplayDetails';
    import VisitAdd from '@/components/pages/visits/VisitAdd';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';

    export default {
        name: 'Visits',
        components: {
            TemplatePageStandard, VisitDescription, VisitTabs, VisitContextMenu,
            Grid, StandardRegion, VisitDisplayDetails, VisitAdd,
            FieldSwitch,
        },

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                // buttons: [],
                isDisplayVisitAdd: false,
                isHideCanceled: true,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.clients'), to: '/clients' },
                    { text: this.$t('object.core_client'), to: `/client/${this.clientId}` },
                    { text: this.$t('MainMenu.clients-visits') },
                ],
                visitRowQty: null,
                gridConfig: {
                    apiUrl: null, // v_visit
                    filter: null,
                    readonly: true,
                    hideZero: true,
                    columns: [
                        {
                            type:'icon', key:'icon', sortable:false, align:'center',
                            $title:(entry) => {
                                return entry.visit_event_type_is_inner ? entry.visit_event_name : entry.status_name;
                            },
                            $icon: entry => tools.getVisitIcon(entry),
                            $cellStyle: entry => `color:${tools.getVisitColor(entry)};`,
                        },
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', headStyle:'max-width:100px;' },
                        { type:'datetime', key:'time_of', header: this.$t('app.datetime') },
                        { type:'string', key:'visit_type_name', header:this.$t('object.visit_type') },
                        { type:'string', key:'user_name', header:this.$t('app.employee') },
                        { type:'string', key:'speciality_name', header:this.$t('object.core_speciality') },
                        { type:'string', key:'building_name_short', header:this.$t('object.core_building') },
                        // { type:'hidden', key:'file_qty', header:this.$t('component.Visits.files'), sortable:false },
                        {
                            type:'icon', key:'file_qty',
                            // header:this.$t('component.Visits.isFiles'),
                            title:this.$t('component.Visits.files'),
                            $icon:(entry) => (entry.file_qty ? 'far fa-file' : ''),
                            $hidden: (data) => (data.some(item => (item.file_qty > 0))),
                        },
                        // {
                        //     type:'html', key:'$colorMark', sortable:false,
                        //     $cellStyle: (entry) => {
                        //         // `color:${tools.getVisitColor(entry)};`
                        //         if (entry.visit_event_id) {
                        //             return 'background-color: #bbf;';
                        //         } else {
                        //             return 'background-color: inherit;';
                        //         }
                        //     },
                        // },
                        // { type:'select', key:'core_building_id', header:this.$t('object.core_building'), source:'core_building', keyLabel:'name_short' },
                        // { type:'number', key:'sum_payment_final', header:this.$t('attribute.common.services') },
                        // { type:'number', key:'sum_unpaid_person', header:this.$t('attribute.common.sum_unpaid') },
                        // { type:'select', key:'core_dept_id', header:this.$t('object.core_dept'), source:'core_dept' },
                    ],
                    row: {
                        emit: 'select-visit',
                        // style: 'cursor: pointer;',
                        $style: (visit) => {
                            return `cursor: pointer;${visit.visit_is_open_event ? 'font-weight: bold;' : ''}`;
                        },
                        $onRightClick: (visit, evt) => {
                            // console.log(`DEBUG: ${this.$options.name}.config.row.$onRightClick visit=`, visit);
                            // console.log(`DEBUG: ${this.$options.name}.config.row.$onRightClick evt=`, evt);
                            this.showContextMenu(evt, visit);
                        },
                        $backgroundColor: (visit) => {
                            // console.log(`DEBUG: ${this.$options.name}.config.row.$backgroundColor visit=`, visit);
                            // console.log(`DEBUG: ${this.$options.name}.config.row.$backgroundColor this=`, this);
                            if (visit.id == this.visitId) {
                                return '#feb';
                            } else if (visit.status_code === 'canceled') {
                                return '#dadedf'
                            }
                            if (visit.visit_event_id) {
                                // return '#b3cefb';
                                return '#c9dcfc';
                            }
                            return '';
                        },
                    },
                    buttons: [ 'add' ],
                    order: [{ key:'time_of', direction:'desc' }],
                    displayToolbar: true,
                    saveSessionState: `${this.$options.name}`,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                lastClient: 'lastClient',
                lastVisit: 'lastVisit',
            }),
        },

        // =============== Watch ===============
        watch: {
            isHideCanceled() {
                this.setGridConfig();
            },
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['setLastVisitId']),

            setGridConfig() {
                // this.gridConfig.apiUrl = `client/${this.clientId}/visits`;
                this.gridConfig.apiUrl = `v_visit`;
                this.gridConfig.filter = { where:[
                    { core_client_id:this.clientId },
                    // this.isHideCanceled ? ['status_code', '<>', 'canceled' ] : {},
                ]};
                if (this.isHideCanceled) {
                    this.gridConfig.filter.where.push(['status_code', '<>', 'canceled' ]);
                }
                this.$refs.visits.refresh();
            },

            setBreadcrumb() {
                this.breadcrumb[this.breadcrumb.length - 2].to = `/client/${this.clientId}`;
                this.breadcrumb[this.breadcrumb.length - 2].text = this.lastClient.shortname;
                this.setGridConfig();
            },

            addVisit() {
                // this.$router.push({ name: this.gridConfig.onEdit, params: { id: null }});
                this.isDisplayVisitAdd = true;
            },

            async selectVisit(visit) {
                // console.log(`DEBUG: ${this.$options.name}.selectVisit() visit=`, visit);
                if (!visit) return;
                this.visitId = visit.id;
                await this.setLastVisitId(this.visitId);
                this.$refs.visits.refresh();
            },

            async visitsUpdated() {
                let isLastFound = this.$refs.visits.filteredData.find(visit => visit.id == (this.lastVisit && this.lastVisit.id));
                // console.log(`DEBUG: ${this.$options.name}.visitsUpdated() this.visitId=${this.visitId}, isLastFound=`, isLastFound);
                if (!this.visitId && isLastFound)
                    await this.selectVisit(isLastFound);
                if (!this.visitId) {
                    await this.selectVisit(this.$refs.visits.filteredData[0]);
                }
                this.visitRowQty = this.$refs.visits.getTotalRowsQty();
                // console.log(`DEBUG: ${this.$options.name}.visitsUpdated() this.visitRowQty=`, this.visitRowQty);
            },

            async closeVisitAdd(visitId) {
                this.isDisplayVisitAdd = false;
                // console.log(`DEBUG: ${this.$options.name}.closeVisitAdd() visitId=`, visitId);
                if (visitId) {
                    this.visitId = visitId;
                    await this.setLastVisitId(this.visitId);
                    await this.$refs.visits.refresh();
                    // await this.setLastVisitId(this.visitId);
                    // this.selectVisit(visitId);
                }
            },

            showContextMenu(evt, visit) {
                this.$refs.visitContextMenu.show(evt, visit);
            },
        },

        // =============== Life cycle ===============
        beforeMount() {
            this.clientId = parseInt(this.$route.params.clientId);
            if (this.$route.params.visitId)
                this.visitId = parseInt(this.$route.params.visitId);
            // else if (this.lastVisitId)
            //     this.visitId = this.lastVisitId;
            // console.log(`DEBUG: ${this.$options.name}.beforeMount(), this.clientId=${this.clientId}, this.visitId=${this.visitId}`, );
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.$router=`, this.$router);
            if (this.clientId) {
                this.setBreadcrumb();
            }
            // this.checkButtons();
        },
    }
</script>

<style lang="scss" >
    @import '@/assets/context-menu.scss';

</style>
