<!--
    Author: Eduard Grebenyukov
    Date: 2020-03-29
-->

<!--
    labelPosition: before | above
    keyId
    keyLabel
    source: String,
    readonly: Boolean,
    required: Boolean,
    listConfig: {
        title: String,
        columns: [
            {
                type: "hidden | html | icon | string | number | select | date | datetime | checkbox",
                readonly: Boolean,
                key: String,
                required: Boolean,
                sortable: Boolean,
                width: String,
                headStyle: String,
                header: String,
                order: String,
                //cellStyle: String, $cellStyle: Function,
                align: String, $align: Function,
                cellTitle: String, $cellTitle: Function,
                $condition: Function,
                value: String, $value: Function,
                title: String, $title: Function,
                class: String,
                style: String,
                textClass: String,
                textStyle: String,
                //textTitle: String, $textTitle: Function,
                // emit: String,
                // textEmit: String,
                // $onClick: Function,
                // $textOnClick: Function,
                source: String | Object,
                keyId: String,
                keyLabel: String,
            }
        ],
        filter: String,
        order: [
            {
                key: String,
                direction: "asc|desc"
            }
        ],
        $onAdd: Function,
        // not implemented
        // row: {
        //     style: String,
        //     backgroundColor: String,
        //     color: String,
        //     $style: Function,
        //     $backgroundColor: Function,
        //     $color: Function,
        // }
    }
-->

<template>
    <div class="form-group" :class="{ 'field-required':required, 'row':labelPositionCode === 'before' }">
        <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label :for="id" v-if="label && (labelPosition === 'before')" :title="required ? $t('lib.required') : ''" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <!-- <label :title="required ? $t('lib.required') : ''" :class="{
            'col-form-label':labelPositionCode === 'before'
        }">
            {{label}}<sup v-if="required" class="required-mark">*</sup>
        </label> -->

        <!-- @click="() => {if (textEmit) $emit(textEmit, itemData); if (textOnClick) eval(textOnClick, itemData);}" -->
        <div class="input-group">
            <!-- <span
                @click.prevent="onClick($event)"
                class="input-group-text"
                :class="{ textClass, 'form-control':labelPositionCode != 'plain', 'col-sm-10':labelPositionCode === 'before' }" :style="textStyle"
            >
                {{ itemPresentation }}
            </span> -->
            <!-- <input type="text" class="form-control" readonly > -->
            <span
                type="text"
                :class="{
                    textClass,
                    'form-control':labelPositionCode != 'plain',
                    'col-sm-10':labelPositionCode === 'before',
                    'is-invalid':!!errorMessage,
                    'text-nowrap':true,
                    'overflow-hidden':true,
                    'field__readonly': readonly || disabled,
                    'field__disabled': disabled,
                    'field__required-empty': required && (!itemData || itemData == '')
                }"
                :style="textStyle + inputStyle"
                :title="itemPresentation"
            >{{ itemPresentation }}</span>
            <div class="input-group-append">
                <slot name="buttons"></slot>
                <button
                    v-if="itemData"
                    type="button"
                    class="btn btn-outline-secondary"
                    :disabled="readonly || disabled"
                    @click.prevent="onClear($event)"
                >
                    <i class="fas fa-times"></i>
                </button>
                <button
                    type="button"
                    class="btn btn-outline-secondary dropdown-toggle"
                    :disabled="readonly || disabled"
                    aria-haspopup
                    @click.prevent="onSelector($event)"
                ></button>
            </div>
        </div>
        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>

        <ModalWindow v-if="isDisplayList" :max-width="maxWidth" :max-height="maxHeight" @cancel="closePopup" :header="label" >
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <Grid
                            ref="selectlist"
                            :source="Array.isArray(source) ? source : null"
                            :config="gridConfig"
                            @add-item="onAddItem()"
                            @item-selected="itemSelected($event)"
                        />
                    </div>
                </div>
            </div>
        </ModalWindow>

    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import ModalWindow from '../regions/ModalWindow';
    import Grid from '../regions/Grid';

    const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'FieldPopupList',

        components: { ModalWindow, Grid },

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: [String, Number],
            defaultValue: [String, Number],
            placeholder: String,
            readonly: Boolean,
            disabled: Boolean,
            required: Boolean,
            source: [String, Array],
            keyId: String,
            keyLabel: String,
            // textEmit: String,
            // textOnClick: String,
            textClass: String,
            textStyle: String,
            showEmptyRow: Boolean,
            saveState: String,
            saveSessionState: String,
            listConfig: { required: true },
            inputStyle: String,
            paginationPageSize: Number,
            errorMessage: String,
            maxHeight: { type:String, default: () => '90vh' },
            maxWidth: { type:String, default: () => '90%' },
        },

        // =============== Data ===============
        data() {
            return {
                controlId: null, // internal control ID, can be overwritten by props.id
                selectListData: [],
                itemData: null,
                itemPresentation: null,
                labelPositionCode: null,
                isDisplayList: false,
                gridConfig: {
                    apiUrl: (typeof this.source === 'string') ? this.source : null,
                    filter: this.listConfig.filter,
                    title: this.listConfig.title,
                    readonly: true,
                    columns: this.listConfig.columns,
                    // row: this.listConfig.row,
                    row: { emit: "item-selected", style: "cursor: pointer;" },
                    buttons: this.listConfig.buttons,
                    order: this.listConfig.order,
                    displayToolbar: true,
                    paginationPageSize: this.paginationPageSize || 10,
                    $onAdd: this.listConfig.$onAdd,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Watch ===============
        watch: {
            value() {
                this.itemData = this.value;
                this.changePresentation();
                // this.loadPresentationValue();
                // console.log(`DEBUG: ${this.$options.name}.watch.value this.value=`, this.value);
            },
            source() {
                // console.log(1DEBUG: ${this.$options.name}.watch.source this.source=1, this.source);
                this.getListValues();
            }
        },

        // =============== Methods ===============
        methods: {
            getPresentation() {
                // console.log(`DEBUG: ${this.$options.name}.getPresentation (${this.id}) this.itemPresentation=`, this.itemPresentation);
                return this.itemPresentation;
            },

            async changePresentation() {
                // console.log(`DEBUG: ${this.$options.name}.changePresentation(${this.itemData}) this.selectListData=`, this.selectListData);
                // this.itemPresentation = this.selectListData.find(x => {console.log('x=', x); return x[this.keyId||'id'] === this.itemData})[this.keyLabel||'name'];
                // do not check type on compare!
                // Do not use "===" as long as saved state value stored in db as string, but field actual type may be another
                if (this.selectListData.length > 0) {
                    // get Presentation from selectListData
                    let currentListItem = this.selectListData.find(x => { return x[this.keyId||'id'] == this.itemData });
                    if (currentListItem) {
                        this.itemPresentation = currentListItem[this.keyLabel || 'name'];
                    } else {
                        this.itemPresentation = null;
                    }
                } else {
                    // get Presentation from backend
                    if (typeof this.source === 'string') {
                        try {
                            if (this.itemData != null) {
                                let item = (await this.authenticatedAxios.get(`${this.baseUrl}/${this.source}/${this.itemData}`)).data;
                                this.itemPresentation = item[this.keyLabel || 'name'];
                            } else {
                                this.itemPresentation = null;
                            }
                        } catch (err) {
                            tools.displayApiError(this.$parent, err);
                        }
                    }
                }
            },

            onSelector(e) {
                // console.log(`DEBUG: ${this.$options.name}.onSelector e=`, e);
                if (!this.readonly && !this.disabled)
                    this.isDisplayList = true;
            },

            async onClear() {
                if (!this.readonly && !this.disabled) {
                    this.itemData = null;
                    await this.changePresentation();
                    this.itemModified();
                }
            },

            // closeModal() {
            //     // console.log(`DEBUG: ${this.$options.name}.closeModal()`);
            //     this.isDisplayList = false;
            // },

            closePopup() {
                this.isDisplayList = false;
            },

            async itemSelected(e) {
                // console.log(`DEBUG: ${this.$options.name}.itemSelected e=`, e);
                this.itemData = e[this.keyId || 'id'];
                await this.changePresentation();
                this.itemModified();
                this.closePopup();
            },

            async onAddItem() {
                // console.log(`DEBUG: ${this.$options.name}.onAddItem`);
                this.$emit('add-item');
            },

            async getListValues() {
                // console.log(`DEBUG: ${this.$options.name}.getListValues this.id=${this.id}`);
                // if (typeof this.source === 'string') {
                //     // console.log(`DEBUG: ${this.$options.name}.getListValues url=`, `${this.baseUrl}/${this.source}`);
                //     try {
                //         await this.changePresentation();
                //         this.selectListData = (await this.authenticatedAxios.get(`${this.baseUrl}/${this.source}`)).data;
                //     } catch (err) {
                //         tools.displayApiError(this.$parent, err);
                //     }
                // } else if (typeof this.source === 'object' && Array.isArray(this.source)) {
                if (typeof this.source === 'object' && Array.isArray(this.source)) {
                    this.selectListData = this.source;
                } else {
                    // Unknown object, set empty array
                    this.selectListData = [];
                }
                await this.changePresentation();
                // this.itemModified(); // why?
                // console.log(`DEBUG: ${this.$options.name}.getListValues this.selectListData=`, this.selectListData);
            },

            itemModified() {
                if (this.saveState) {
                    // Save current value
                    // console.log(`DEBUG: ${this.$options.name}.itemModified() this.itemData=`, this.itemData);
                    this.authenticatedAxios.post(`${this.baseUrl}/${userSettingsUrl}`, {code: this.saveState, value: this.itemData});
                }
                if (this.saveSessionState) {
                    sessionStorage.setItem(this.saveSessionState, JSON.stringify(this.itemData));
                }
                // this.$emit('update:value', e.target.value.trim());
                this.$emit('update:value', this.itemData);
                this.$emit('modified');
            },

            async refresh() {
                // console.log(`DEBUG: ${this.$options.name}.refresh()`);
                await this.$refs.selectlist.refresh();
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}().beforeMount(${this.itemData}) this.paginationPageSize=`, this.paginationPageSize);
            this.itemData = this.value;
            if (!this.itemData && this.saveState) {
                // Restore stored value
                let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
                let item = response.data.rows[0];
                // !!! potential problem !!!
                // saved value stored in db as string, but actual field type may be another type
                if (item) this.itemData = item.value;
                // console.log(`DEBUG: ${this.$options.name}.beforeMount(${this.itemData}) restore state response=`, response);
                this.itemModified();
            }
            if (!this.itemData && this.saveSessionState) {
                let sessionData = JSON.parse(sessionStorage.getItem(this.saveSessionState));
                if (sessionData) this.itemData = sessionData;
                this.itemModified(this);
            }
            if (!this.itemData && typeof this.defaultValue != 'undefined' && this.defaultValue !== null) {
                this.itemData = this.defaultValue;
                this.itemModified();
            }
        },
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}(${this.id}).mounted() this.value=`, this.value);
            this.labelPositionCode = (this.labelPosition ? this.labelPosition : 'above');
            this.getListValues();
        },

    }
</script>

<style>
</style>
