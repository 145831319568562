<!--
    Author: Eduard Grebenyukov
    Date: 2020-05-17
-->

<template>
    <ModalWindow
        width="90%"
        max-width="1200px"
        :buttons="buttons"
        @cancel="closeModal()"
        @save="saveData()"
        @delete="askDeleteItem()"
    >
        <span slot="header" :title="msgItemModified">{{ $t('Visit.create_visit') }} {{ isModified? '*' : '' }}</span>

        <div class="container">
            <div class="form-row">
                <div class="col-sm">
                    <FieldSelect
                        ref="core_workplace_id"
                        id="core_workplace_id"
                        :label="$t('object.core_workplace')"
                        labelPosition="above"
                        :value.sync="editFormData.core_workplace_id"
                        :source='`v_workplace?filter={"where":[{"core_user_id":${currentUser.id}},{"is_schedule":true},{"is_active":true}]}`'
                        keyLabel='workplace_name'
                        @modified="itemModified()"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="col-sm">
                    <FieldSelect
                        id='visit_type_id'
                        :label="$t('object.visit_type')"
                        labelPosition="above"
                        :value.sync="editFormData.visit_type_id"
                        :source="editFormData.core_workplace_id?`workplace-visit_type/${editFormData.core_workplace_id}`:[]"
                        showEmptyRow
                        @modified="itemVisitTypeModified"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="col-sm">
                    <FieldDatetime id='time_of' :label="$t('app.time')" :value.sync="editFormData.time_of" @modified="itemModified()" required labelPosition="above" />
                </div>
                <div class="col-sm">
                    <FieldNumber id='duration' :label="$t('attribute.common.duration')" :value.sync="editFormData.duration" @modified="itemModified()" required labelPosition="above" />
                </div>
            </div>
            <div class="form-row">
                <div class="col-sm">
                    <FieldText :label="$t('attribute.core_person.commentary')" :value.sync="editFormData.commentary" @modified="itemModified()" />
                </div>
            </div>
            <div class="form-row">
                <div class="col-sm">
                    <FieldSelect
                        :label="$t('object.visit_event')"
                        :value.sync="editFormData.visit_event_id"
                        :source='`v_event?filter={"where":[{"core_client_id":${clientId}},{"is_open":true},{"is_closed":false}]}`'
                        showEmptyRow
                        @modified="itemModified()"
                    />
                </div>
            </div>
        </div>

        <WarningClose v-if="isDisplayWarningClose" @process="$event ? processClose() : cancelClose()" />
        <WarningDelete v-if="isDisplayWarningDelete" @process="$event ? processDeleteItem() : cancelDeleteItem()" />

    </ModalWindow>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { mapGetters, mapActions } from 'vuex';
    //import { mapState, mapActions } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';
    // import moment from 'moment';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import WarningClose from '@/components/lib/regions/WarningClose';
    import WarningDelete from '@/components/lib/regions/WarningDelete';
    import FieldDatetime from '@/components/lib/controls/FieldDatetime';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    // import FieldString from '@/components/lib/controls/FieldString';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    // import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import FieldText from '@/components/lib/controls/FieldText';

    export default {
        name: 'VisitAdd',

        components: {
            ModalWindow, WarningClose, WarningDelete,
            FieldDatetime, FieldNumber, FieldSelect, FieldText
        },

        // =============== Props ===============
        props: {
            clientId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                isModified: false,
                msgItemModified: '',
                editFormData: {
                    // id: null,
                    base_visit_id: null,
                    core_workplace_id: null,
                    core_client_id: null,
                    visit_event_id: null,
                    // lastname: null,
                    // firstname: null,
                    // patronymic: null,
                    visit_type_id: null,
                    time_of: null,
                    duration: null,
                    commentary: null,
                },
                oldFormData: {},
                isDisplayWarningDelete: false,
                isDisplayWarningClose: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),
            // apiUrl() { return `${this.baseUrl}/${componentUrl}` },
            // displayGrids() {return typeof this.editFormData.id !='undefined' },
        },

        // =============== Validations ===============
        // validations: {
        //     editFormData: {
        //         username: { required },
        //         email: { required },
        //         organization_id: {required}
        //     }
        // },

        // =============== Methods ===============
        methods: {
            // ...mapActions(['setLastVisitId']),
            async getFillEvent() {
                try {
                    const filterString = tools.getFilterString({ where:[
                        { core_client_id: this.clientId },
                        { is_open: true },
                        { is_closed: false },
                    ]});
                    let response = await this.authenticatedAxios.get(`${this.baseUrl}/v_event${filterString}`);
                    // console.log(`DEBUG: ${this.$options.name}.getFillEvent() data=`, response.data);
                    if (response.data.rows.length === 1) {
                        this.editFormData.visit_event_id = response.data.rows[0].id;
                    }
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },
            // async getData() {
            //     try {
            //         let response = await this.authenticatedAxios.get(`${this.baseUrl}/visit_rec/${this.itemId}`);
            //         // console.log(`DEBUG: ${this.$options.name}.getData() data=`, response.data);
            //         this.editFormData = response.data;

            //         this.isModified = false;
            //         this.msgItemModified = '';
            //     } catch (err) {
            //         tools.displayApiError(this.$parent, err);
            //     }
            // },

            async saveData() {
                if (this.isModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            await this.authenticatedAxios.put(`${this.baseUrl}/visit_rec/${this.editFormData.id}`, this.editFormData);
                            // this.$emit('close-modal', true);
                        } else {
                            // New user created
                            let response = await this.authenticatedAxios.post(`${this.baseUrl}/visit_rec`, this.editFormData);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response.data=`, response.data);
                            this.itemId = response.data.id;
                            // this.editFormData.id = this.itemId;
                            // this.getData();
                        }
                        tools.displaySuccessSave(this.$parent);
                        // await this.setLastVisitId(this.itemId);
                        this.$emit('close-modal', this.itemId);
                    } catch (err) {
                        tools.displayApiError(this.$parent, err);
                    }
                } else {
                    // no changes
                    this.$emit('close-modal', false);
                }
            },

            async itemVisitTypeModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemVisitTypeModified visit_type_id=', this.editFormData.visit_type_id);
                if (this.editFormData.visit_type_id) {
                    try {
                        // let filterString = tools.getFilterString({ where:[{ id:this.editFormData.visit_type_id }]});
                        let response = await this.authenticatedAxios.get(`${this.baseUrl}/visit_type/${this.editFormData.visit_type_id}`);
                        // console.log(`DEBUG: ${this.$options.name}.itemVisitTypeModified data=', response.data);
                        this.editFormData.duration = response.data.duration;
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                this.itemModified();
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified()`);
                if (JSON.stringify(this.oldFormData) === JSON.stringify(this.editFormData)) {
                    this.isModified = false;
                    this.msgItemModified = '';
                } else {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                }
            },

            askDeleteItem() {
                this.isDisplayWarningDelete = true;
            },
            // async processDeleteItem() {
            //     try {
            //         await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
            //         tools.displaySuccessDelete(this.$parent);
            //         this.$emit('close-modal', true);
            //     } catch (err) {
            //         tools.displayApiError(this.$parent, err);
            //     }
            // },
            // cancelDeleteItem() { this.isDisplayWarningDelete = false },

            closeModal() {
                // console.log(`DEBUG: ${this.$options.name}.closeModal()`);
                if (this.isModified) {
                    this.isDisplayWarningClose = true;
                } else {
                    // console.log(`DEBUG: ${this.$options.name}.closeModal() process`);
                    this.processClose();
                }
            },
            processClose() { this.$emit('close-modal', null) },
            cancelClose() { this.isDisplayWarningClose = false },

        },

        // =============== Life cycle ===============
        beforeMount() {
            // this.itemId = this.id;
            this.editFormData.core_client_id = this.clientId;
        },

        async mounted() {
            if (this.itemId) {
                await this.getData();
                this.buttons = ['save', 'delete', 'close'];
            } else {
                this.buttons = ['save', 'close'];
            }
            await this.getFillEvent();
            // this.editFormData.time_of = `${moment().format('L')} ${moment().format('LT')}`;
            this.editFormData.time_of = (new Date()).toISOString();
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.editFormData.time_of=`, this.editFormData.time_of);
        },
    }
</script>

<style>
</style>
