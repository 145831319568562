<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-13
-->

<template>
    <nav class="visit-tabs navbar tabs">
        <!-- :key="`${lastClient.id}-${lastVisit.id}`" -->
        <NavigationTabs
            :value.sync="navigationTab"
            :source="filteredMenu.length > 0 ? filteredMenu : emptyTab"
            keyId="code"
            keyLabel="title"
            :disabled="!isEnabled"
        />
    </nav>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import NavigationTabs from '@/components/lib/regions/NavigationTabs';

    export default {
        name: 'VisitTabs',

        components: { NavigationTabs },

        // =============== Props ===============
        props: {
            currentTab: String,
        },

        // =============== Data ===============
        data() {
            return {
                navigationTab: null,
                filterData: [],
                filteredMenu: [],
                emptyTab: [
                    { code:null, title:this.$t('MainMenu.clients-visits'), name:null, href:'', alias:[] },
                ],
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                visitMenu: 'visitMenu',
                lastClient: 'lastClient',
                lastVisit: 'lastVisit',
            }),
            isEnabled() {
                return ['started', 'completed', 'closed'].includes(this.lastVisit && this.lastVisit.status_code);
            },
        },

        // =============== Watch ===============
        watch: {
            currentTab() {
                this.navigationTab = this.currentTab;
            },
            lastClient() {
                this.filterMenu();
            },
            lastVisit() {
                // console.log(`DEBUG: ${this.$options.name}.watch.lastVisit this.lastVisit=`, this.lastVisit);
                this.filterMenu();
            },
        },

        // =============== Methods ===============
        methods: {
            async getFilter() {
                try {
                    this.filterData = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_type_page`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.getFilter() this.filterData=`, this.filterData);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            filterMenu() {
                // console.log(`DEBUG: ${this.$options.name}.filterMenu() this.lastVisit.status_code=`, this.lastVisit.status_code);
                let filteredData = JSON.parse(JSON.stringify(this.visitMenu));
                if (this.lastVisit && this.lastClient.id && this.lastVisit.id) {
                    // filter menu
                    filteredData = filteredData.filter(
                        item => this.filterData.some(
                            page => page.code == item.code && (page.visit_type_id == this.lastVisit.visit_type_id || page.visit_type_id == null)
                        )
                    );
                    // console.log(`DEBUG: ${this.$options.name}.filterMenu() this.visitMenu[4]=`, this.visitMenu[4]);
                    // replace ids
                    for (let item of filteredData) {
                        item.href = item.href.split('/').map(e => {
                            // console.log(`this.visitId=${this.visitId}, e=${e}`);
                            switch (e) {
                                case ':clientId': return this.lastClient.id;
                                case ':visitId': return this.lastVisit.id;
                                default: return e;
                            }
                        }).join('/');
                    }
                } else {
                    filteredData = [];
                }
                this.filteredMenu = filteredData;
            },

            async refresh() {
                this.filterMenu();
            },

        },

        // =============== Life cycle ===============
        async mounted() {
            this.navigationTab = this.currentTab;
            await this.getFilter();
            this.filterMenu();
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.clientId=${this.clientId}, this.visitId=${this.visitId}, this.navigationTab=${this.navigationTab}`);
        },
    }
</script>

<style>
    .visit-tabs.navbar.tabs {
        min-height: 41px;
        padding-top: 4px;
        padding-bottom: 0;
        width: 100%;
    }
    .visit-tabs.navbar.tabs ul {
        width: 100%;
    }
    /* .visit-tabs.navbar.tabs a {
        height: 2.25rem;
    } */
</style>
