<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-24
-->

<template>
    <Grid id="similar-clients" v-if="clients.length > 0" ref="clients" :config="gridConfig" :source="clients" @show-item="showItem($event.id)" />
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import tools from '@/components/lib/tools';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'SimilarOfClient',

        components: { Grid },

        // =============== Props ===============
        props: {
            clientToCompare: Object,
        },

        // =============== Data ===============
        data() {
            return {
                clients: [],
                gridConfig: {
                    // apiUrl: null,
                    // filter: null,
                    readonly: true,
                    title: this.$t('component.SimilarOfClient.similarClients'),
                    columns: [
                        { type:'string', key:'code', header:this.$t('attribute.common.code') },
                        {
                            type:'string', key:'fullname',
                            header:this.$t('object.core_client'),
                            title:this.$t('app.open'),
                            style:'color: #056bbf;',
                            emit:'show-item',
                        },
                        { type:'date', key:'dob', header:this.$t('attribute.core_person.dob') },
                        { type:'string', key:'phones', header:this.$t('Contacts.phones') },
                    ],
                    order: [ 'fullname' ],
                    displayToolbar: false,
                    paginationPageSize: 10,
                    displayPagination: false,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['setLastClientId']),

            async getData() {
                if (
                    this.clientToCompare.lastname && this.clientToCompare.lastname.length > 0 ||
                    (
                        this.clientToCompare.firstname && this.clientToCompare.firstname.length > 0 &&
                        this.clientToCompare.patronymic && this.clientToCompare.patronymic.length >0
                    )
                ) {
                    // console.log(`DEBUG: ${this.$options.name}.getData(), this.clientToCompare=`, this.clientToCompare);
                    try {
                        let filter = tools.getFilterString({ where:[
                            { id:this.clientToCompare.id ? this.clientToCompare.id : null },
                            { lastname:this.clientToCompare.lastname },
                            { firstname:this.clientToCompare.firstname },
                            { patronymic:this.clientToCompare.patronymic ? this.clientToCompare.patronymic : null },
                        ]});
                        this.clients = (await this.authenticatedAxios.get(`${this.baseUrl}/client-duplicates/${filter}`)).data.rows;
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.clients = [];
                }
            },
            refresh() {
                // console.log(`DEBUG: ${this.$options.name}.refresh(), this.clientToCompare=`, this.clientToCompare);
                this.getData();
            },
            clear() {
                this.clients = [];
            },
            async showItem(id) {
                // console.log(`DEBUG: ${this.$options.name}.showItem(${id})`);
                await this.setLastClientId(id);
                this.$router.push({ name: 'client-edit', params: { id } });
                // this.$router.push({ name: 'client-visits', params: { clientId: id } });
            },
        },
    }
</script>

<style>
    /* #similar-clients .grid-editable, #similar-clients .grid-editable th, #similar-clients .grid-editable td {
        border-color: red;
    } */
    #similar-clients {
        border: 1px solid red;
        padding: 2px;
    }
</style>
