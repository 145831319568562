<!--
    Author: Eduard Grebenyukov
    Date: 2020-05-04
-->

<template>
    <div id="printform" v-html="documentText" ></div>
</template>

<script>
    const sprintf = require('sprintf-js').sprintf;
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import tools from '@/components/lib/tools';

    export default {
        name: 'PrintPage',

        props: {
            taskId: null,
        },

        // =============== Data ===============
        data() {
            return {
                config: null,
                templateText: null,
                documentText: null,
                visitData: null,
                clientData: null,
                workplaceData: null,
                userData: null,
                deptData: null,
                orgData: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Methods ===============
        methods: {
            processRowNumber(str, rowNumber) {
                let displayStr = rowNumber.toString();
                if (!displayStr) {
                    // add class 'hidden'
                    str = str.replace(new RegExp(`#row_number.class#`, 'g'), 'hidden');
                }
                str = str.replace(new RegExp(`#row_number#`, 'g'), displayStr || '');
                return str;
            },

            processItem(dataSource, str, itemName, replaceEmpty) {
                // console.log(`DEBUG: ${this.$options.name}.processItem(${this.taskId}, ${itemName}) typeof dataSource[itemName]=`, typeof dataSource[itemName]);
                let displayStr;
                // if (typeof dataSource[itemName] == 'number') {
                //     displayStr = dataSource[itemName].toString();
                // } else {
                //     displayStr = dataSource[itemName];
                // }
                if (typeof dataSource[itemName] === 'string' || dataSource[itemName] === null || dataSource[itemName] === undefined) {
                    displayStr = dataSource[itemName];
                } else {
                    displayStr = dataSource[itemName].toString();
                }

                // if (typeof replaceEmpty === 'undefined') replaceEmpty = '';

                if (!displayStr) {
                    // add class 'hidden'
                    str = str.replace(new RegExp(`#${itemName}.class#`, 'g'), 'hidden');
                }
                // console.log(`DEBUG: ${this.$options.name}.processItem(${this.taskId}, ${itemName}) displayStr=`, displayStr);
                str = str.replace(new RegExp(`#${itemName}#`, 'g'), displayStr || replaceEmpty || '');
                return str;
            },

            // processItemNoHide(str, itemName) {
            //     str = str.replace(new RegExp(`#${itemName}#`, 'g'), dataSource[itemName] || '&nbsp;');
            //     return str;
            // },

            async processVisit(str) {
                if (this.config.visit && this.config.visit.visit_rec_id) {
                    try {
                        this.visitData = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_rec/${this.config.visit.visit_rec_id}`)).data;
                        this.config.visit.visit_date_of = this.visitData.time_of;
                        this.config.visit.visit_date = moment(this.visitData.time_of).format('L');
                        this.config.visit.core_client_id = this.visitData.core_client_id;
                        this.config.visit.core_workplace_id = this.visitData.workplace_final_id || this.visitData.core_workplace_id;

                        this.config.client = { core_client_id: this.config.visit.core_client_id };
                        this.config.workplace = { core_workplace_id: this.config.visit.core_workplace_id };

                        str = this.processItem(this.config.visit, str, 'visit_date');
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                return str;
            },

            async processClient(str) {
                if (this.config.client && this.config.client.core_client_id) {
                    try {
                        this.clientData = (await this.authenticatedAxios.get(`${this.baseUrl}/core_client/${this.config.client.core_client_id}`)).data;
                        // console.log(`DEBUG: ${this.$options.name}.processClient() this.clientData=`, this.clientData);
                        this.config.client.client_code = this.clientData.code;
                        this.config.client.client_fullname = this.clientData.fullname;
                        this.config.client.client_shortname = this.clientData.shortname;
                        if (this.clientData.dob) {
                            this.config.client.client_dob = moment(this.clientData.dob).format('L');
                            this.config.client.client_age = tools.getAge(this.clientData.dob, this.config.client.visit_date_of);
                        } else {
                            this.config.client.client_dob = null;
                            this.config.client.client_age = null;
                        }
                        if (this.clientData.sex) {
                            if (this.clientData.sex === 'f') this.config.client.client_sex = this.$t('attribute.core_person.sex_kind.female');
                            if (this.clientData.sex === 'm') this.config.client.client_sex = this.$t('attribute.core_person.sex_kind.male');
                        }
                        this.config.client.client_document_serial = this.clientData.document_serial;
                        this.config.client.client_document_num = this.clientData.document_num;
                        if (this.clientData.document_date) {
                            this.config.client.client_document_date = moment(this.clientData.document_date).format('L');
                        } else {
                            this.config.client.client_document_date = null;
                        }
                        this.config.client.client_document_issuer = this.clientData.document_issuer;
                        this.config.client.client_job_place = this.clientData.job_place;
                        this.config.client.client_job_position = this.clientData.job_position;
                        this.config.client.client_pob = this.clientData.pob;
                        this.config.client.client_email = this.clientData.email;
                        this.config.client.client_phones = this.clientData.phones;

                        let addressRegEnumId = await tools.getEnumId(this, 'address_type', 'registration');
                        let addressHomeEnumId = await tools.getEnumId(this, 'address_type', 'home');
                        let addressRegFilterString = tools.getFilterString({ where:[
                            { core_person_id: this.clientData.client_person_id },
                            { address_type_id: addressRegEnumId },
                        ]});
                        let addressHomeFilterString = tools.getFilterString({ where:[
                            { core_person_id: this.clientData.client_person_id },
                            { address_type_id: addressHomeEnumId },
                        ]});
                        let clientAddressRegData = (await this.authenticatedAxios.get(`${this.baseUrl}/core_address${addressRegFilterString}`)).data.rows[0];
                        let clientAddressHomeData = (await this.authenticatedAxios.get(`${this.baseUrl}/core_address${addressHomeFilterString}`)).data.rows[0];
                        if (clientAddressRegData) {
                            this.config.client.client_address_reg = clientAddressRegData.address;
                        }
                        if (clientAddressHomeData) {
                            this.config.client.client_address_home = clientAddressHomeData.address;
                        }

                        let changeData = await tools.getChange(this, 'core_client', this.config.client.core_client_id);
                        if (changeData && changeData.time_created) {
                            this.config.client.client_date_created = moment(changeData.time_created).format('L');
                        }

                        let bloodTypeFilterString = tools.getFilterString({ where:[
                            { core_client_id: this.config.client.core_client_id },
                        ]});
                        let bloodTypeData = (await this.authenticatedAxios.get(`${this.baseUrl}/med_blood_type${bloodTypeFilterString}`)).data.rows[0];
                        if (bloodTypeData) {
                            this.config.client.client_blood_type = (await this.authenticatedAxios.get(`${this.baseUrl}/core_enum/${bloodTypeData.blood_type_id}`)).data.name;
                            this.config.client.client_blood_rh = (await this.authenticatedAxios.get(`${this.baseUrl}/core_enum/${bloodTypeData.rh_id}`)).data.name;
                            // console.log(`DEBUG: ${this.$options.name}.processClient() bloodTypeData=`, bloodTypeData);
                            // console.log(`DEBUG: ${this.$options.name}.processClient() this.config.client.client_blood_type=`, this.config.client.client_blood_type);
                        }

                        let anamnesisVitaeFilterString = tools.getFilterString(
                            { where: [
                                { core_client_id: this.config.client.core_client_id },
                                { is_current: true },
                            ]},
                        );
                        let anamnesisVitaeData = (await this.authenticatedAxios.get(`${this.baseUrl}/v_anamnesis_vitae${anamnesisVitaeFilterString}`)).data.rows[0];
                        // console.log(`DEBUG: ${this.$options.name}.processClient() anamnesisVitaeData=`, anamnesisVitaeData);
                        if (anamnesisVitaeData && anamnesisVitaeData.is_allergy) {
                            let allergiesFilterString = tools.getFilterString(
                                { where: [
                                    { visit_rec_id: anamnesisVitaeData.visit_rec_id },
                                ]},
                                { order: [ 'id' ]},
                            );
                            let allergiesData = (await this.authenticatedAxios.get(`${this.baseUrl}/med_allergy${allergiesFilterString}`)).data.rows;
                            let allergiesString = '';
                            for (let a of allergiesData) {
                                allergiesString = allergiesString + `${a.substance}: ${a.reaction}; `;
                            }
                            this.config.client.client_allergies = allergiesString;
                        }

                        str = this.processItem(this.config.client, str, 'client_code');
                        str = this.processItem(this.config.client, str, 'client_fullname');
                        str = this.processItem(this.config.client, str, 'client_shortname');
                        str = this.processItem(this.config.client, str, 'client_dob');
                        str = this.processItem(this.config.client, str, 'client_sex');
                        str = this.processItem(this.config.client, str, 'client_age');
                        str = this.processItem(this.config.client, str, 'client_document_serial');
                        str = this.processItem(this.config.client, str, 'client_document_num');
                        str = this.processItem(this.config.client, str, 'client_document_date');
                        str = this.processItem(this.config.client, str, 'client_document_issuer');
                        str = this.processItem(this.config.client, str, 'client_job_place');
                        str = this.processItem(this.config.client, str, 'client_job_position');
                        str = this.processItem(this.config.client, str, 'client_pob');
                        str = this.processItem(this.config.client, str, 'client_email');
                        str = this.processItem(this.config.client, str, 'client_phones');
                        str = this.processItem(this.config.client, str, 'client_address_reg');
                        str = this.processItem(this.config.client, str, 'client_address_home');
                        str = this.processItem(this.config.client, str, 'client_date_created');
                        str = this.processItem(this.config.client, str, 'client_blood_type');
                        str = this.processItem(this.config.client, str, 'client_blood_rh');
                        str = this.processItem(this.config.client, str, 'client_allergies');
                        str = this.processItem(this.config.client, str, 'insurance_org_names');
                        // not implemented:
                        // insurance_org_names
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }                
                return str;
            },

            async processWorkplace(str) {
                if (this.config.workplace && this.config.workplace.core_workplace_id) {
                    try {
                        this.workplaceData = (await this.authenticatedAxios.get(`${this.baseUrl}/core_workplace/${this.config.workplace.core_workplace_id}`)).data;
                        this.config.workplace.workplace_position = this.workplaceData.position;
                        this.config.workplace.core_user_id = this.workplaceData.core_user_id;
                        this.config.workplace.core_device_id = this.workplaceData.core_device_id;
                        this.config.workplace.core_dept_id = this.workplaceData.core_dept_id;
                        this.config.workplace.core_speciality_id = this.workplaceData.core_speciality_id;
                        this.config.workplace.core_room_id = this.workplaceData.core_room_id;
                        this.config.workplace.workplace_position = this.workplaceData.position;

                        this.config.user = { core_user_id: this.config.workplace.core_user_id };
                        this.config.dept = { core_dept_id: this.config.workplace.core_dept_id };
                        this.config.room = { core_room_id: this.config.workplace.core_room_id };

                        str = this.processItem(this.config.workplace, str, 'workplace_position');
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                return str;
            },

            async processUser(str) {
                if (this.config.user && this.config.user.core_user_id) {
                    try {
                        this.userData = (await this.authenticatedAxios.get(`${this.baseUrl}/v_user/${this.config.user.core_user_id}`)).data;
                        this.config.user.user_fullname = this.userData.fullname;
                        this.config.user.user_shortname = this.userData.shortname;
                        this.config.user.user_subscribe = this.userData.subscribe;

                        str = this.processItem(this.config.user, str, 'user_shortname');
                        str = this.processItem(this.config.user, str, 'user_fullname');
                        str = this.processItem(this.config.user, str, 'user_subscribe');
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                return str;
            },

            async processDept(str) {
                if (this.config.dept && this.config.dept.core_dept_id) {
                    try {
                        this.deptData = (await this.authenticatedAxios.get(`${this.baseUrl}/core_dept/${this.config.dept.core_dept_id}`)).data;
                        this.config.dept.dept_name = this.deptData.name;
                        this.config.dept.dept_shortname = this.deptData.name_short;
                        this.config.dept.dept_fullname = this.deptData.name_full;
                        this.config.dept.core_org_id = this.deptData.core_org_id;

                        this.config.org = { core_org_id: this.config.dept.core_org_id };

                        str = this.processItem(this.config.dept, str, 'dept_name');
                        str = this.processItem(this.config.dept, str, 'dept_shortname');
                        str = this.processItem(this.config.dept, str, 'dept_fullname');
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                return str;
            },

            async processRoom(str) {
                // console.log(`DEBUG: ${this.$options.name}.processRoom() this.config.room=`, this.config.room);
                if (this.config.room && this.config.room.core_room_id) {
                    try {
                        this.roomData = (await this.authenticatedAxios.get(`${this.baseUrl}/core_room/${this.config.room.core_room_id}`)).data;
                        this.config.room.room_name = this.roomData.name;
                        this.config.room.room_num = this.roomData.room_num;
                        this.config.room.core_building_id = this.roomData.core_building_id;

                        this.config.building = { core_building_id: this.config.room.core_building_id };

                        str = this.processItem(this.config.room, str, 'room_name');
                        str = this.processItem(this.config.room, str, 'room_num');
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                return str;
            },

            async processBuilding(str) {
                // console.log(`DEBUG: ${this.$options.name}.processBuilding() this.config.building=`, this.config.building);
                if (this.config.building && this.config.building.core_building_id) {
                    try {
                        this.buildingData = (await this.authenticatedAxios.get(`${this.baseUrl}/core_building/${this.config.building.core_building_id}`)).data;
                        this.config.building.building_name = this.buildingData.name;
                        this.config.building.building_name_short = this.buildingData.name_short;
                        this.config.building.building_address_act = this.buildingData.address_act;

                        str = this.processItem(this.config.building, str, 'building_name');
                        str = this.processItem(this.config.building, str, 'building_name_short');
                        str = this.processItem(this.config.building, str, 'building_address_act');
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                return str;
            },

            async processOrg(str) {
                // console.log(`DEBUG: ${this.$options.name}.processOrg() this.config.org=`, this.config.org);
                if (this.config.org && this.config.org.core_org_id) {
                    try {
                        this.orgData = (await this.authenticatedAxios.get(`${this.baseUrl}/core_org/${this.config.org.core_org_id}`)).data;
                        this.config.org.org_name = this.orgData.name;
                        this.config.org.org_shortname = this.orgData.name_short;
                        this.config.org.org_fullname = this.orgData.name_full;
                        this.config.org.org_address_reg = this.orgData.address_reg;
                        this.config.org.org_address_act = this.orgData.address_act;
                        this.config.org.org_phone = this.orgData.phone;
                        this.config.org.org_email = this.orgData.email;
                        this.config.org.org_fax = this.orgData.fax;
                        this.config.org.org_url = this.orgData.url;
                        this.config.org.org_tin = this.orgData.tin;
                        this.config.org.org_rnnbo = this.orgData.rnnbo;
                        this.config.org.org_license = this.orgData.license;

                        str = this.processItem(this.config.org, str, 'org_name');
                        str = this.processItem(this.config.org, str, 'org_shortname');
                        str = this.processItem(this.config.org, str, 'org_fullname');
                        str = this.processItem(this.config.org, str, 'org_address_reg');
                        str = this.processItem(this.config.org, str, 'org_address_act');
                        str = this.processItem(this.config.org, str, 'org_phone');
                        str = this.processItem(this.config.org, str, 'org_email');
                        str = this.processItem(this.config.org, str, 'org_fax');
                        str = this.processItem(this.config.org, str, 'org_url');
                        str = this.processItem(this.config.org, str, 'org_tin');
                        str = this.processItem(this.config.org, str, 'org_rnnbo');
                        str = this.processItem(this.config.org, str, 'org_license');
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                return str;
            },

            processConsultation(str) {
                if (this.config.consultation) {
                    str = this.processItem(this.config.consultation, str, 'complaints');
                    str = this.processItem(this.config.consultation, str, 'illness_history');
                    str = this.processItem(this.config.consultation, str, 'physical_exam');
                    str = this.processItem(this.config.consultation, str, 'instrumental_exam');
                    str = this.processItem(this.config.consultation, str, 'diff_diag');
                    str = this.processItem(this.config.consultation, str, 'exam_plan');
                    str = this.processItem(this.config.consultation, str, 'diagnosis');
                    str = this.processItem(this.config.consultation, str, 'treatment');
                    str = this.processItem(this.config.consultation, str, 'regime');
                }
                return str;
            },

            processDental(str) {
                if (this.config.consultation) {
                    str = this.processItem(this.config.consultation, str, 'd55', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd54', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd53', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd52', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd51', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd61', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd62', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd63', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd64', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd65', '&nbsp;');

                    str = this.processItem(this.config.consultation, str, 'd18', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd17', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd16', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd15', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd14', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd13', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd12', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd11', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd21', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd22', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd23', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd24', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd25', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd26', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd27', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd28', '&nbsp;');

                    str = this.processItem(this.config.consultation, str, 'd48', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd47', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd46', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd45', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd44', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd43', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd42', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd41', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd31', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd32', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd33', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd34', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd35', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd36', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd37', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd38', '&nbsp;');

                    str = this.processItem(this.config.consultation, str, 'd85', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd84', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd83', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd82', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd81', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd71', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd72', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd73', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd74', '&nbsp;');
                    str = this.processItem(this.config.consultation, str, 'd75', '&nbsp;');
                }
                return str;
            },

            processBill(str) {
                // console.log(`DEBUG: ${this.$options.name}(${this.taskId}).processBill() strBefore=`, strBefore, ', =', strRow, ', =', strAfter);
                if (this.config.bill) {
                    str = this.processItem(this.config.bill, str, 'bill_num');
                    str = this.processItem(this.config.bill, str, 'bill_date');
                    str = this.processItem(this.config.bill, str, 'total_sum');
                    str = this.processItem(this.config.bill, str, 'total_sum_company');
                    str = this.processItem(this.config.bill, str, 'total_sum_payer');
                    str = this.processItem(this.config.bill, str, 'total_sum_patient');
                    str = this.processItem(this.config.bill, str, 'currency');

                    let [ strBefore, strRow, strAfter ] = str.split('#service_row#');
                    // console.log(`DEBUG: ${this.$options.name}(${this.taskId}).processBill() strBefore=`, strBefore, ', =', strRow, ', =', strAfter);
                    str = strBefore;

                    let i = 1;
                    for (let row of this.config.bill.services) {
                        let s1 = strRow;
                        // console.log(`DEBUG: ${this.$options.name}(${this.taskId}).processBill() row=`, row);
                        s1 = this.processRowNumber(s1, i);
                        s1 = this.processItem(row, s1, 'teeth', '');
                        s1 = this.processItem(row, s1, 'service_code', '');
                        s1 = this.processItem(row, s1, 'service_name', '');
                        s1 = this.processItem(row, s1, 'price', '');
                        s1 = this.processItem(row, s1, 'qty', '');
                        s1 = this.processItem(row, s1, 'sum_payment', '');
                        s1 = this.processItem(row, s1, 'sum_payment_final', '');
                        s1 = this.processItem(row, s1, 'sum_discount_final', '');
                        s1 = this.processItem(row, s1, 'program_name', '');
                        str += s1;
                        i++;
                    }

                    str += strAfter;
                }
                return str;
            },

            processEstimate(str) {
                // console.log(`DEBUG: ${this.$options.name}(${this.taskId}).processEstimate() strBefore=`, strBefore, ', =', strRow, ', =', strAfter);
                if (this.config.estimate) {
                    str = this.processItem(this.config.estimate, str, 'estimate_num');
                    str = this.processItem(this.config.estimate, str, 'estimate_date');
                    str = this.processItem(this.config.estimate, str, 'estimate_commentary');
                    str = this.processItem(this.config.estimate, str, 'total_sum');
                    str = this.processItem(this.config.estimate, str, 'total_sum_company');
                    str = this.processItem(this.config.estimate, str, 'total_sum_payer');
                    str = this.processItem(this.config.estimate, str, 'total_sum_patient');
                    str = this.processItem(this.config.estimate, str, 'currency');

                    let [ strBefore, strRow, strAfter ] = str.split('#service_row#');
                    // console.log(`DEBUG: ${this.$options.name}(${this.taskId}).processEstimate() strBefore=`, strBefore, ', =', strRow, ', =', strAfter);
                    str = strBefore;

                    let i = 1;
                    for (let row of this.config.estimate.services) {
                        let s1 = strRow;
                        // console.log(`DEBUG: ${this.$options.name}(${this.taskId}).processEstimate() row=`, row);
                        s1 = this.processRowNumber(s1, i);
                        s1 = this.processItem(row, s1, 'teeth', '');
                        s1 = this.processItem(row, s1, 'service_code', '');
                        s1 = this.processItem(row, s1, 'service_name', '');
                        s1 = this.processItem(row, s1, 'price', '');
                        s1 = this.processItem(row, s1, 'qty', '');
                        s1 = this.processItem(row, s1, 'sum_payment', '');
                        s1 = this.processItem(row, s1, 'sum_discount', '');
                        s1 = this.processItem(row, s1, 'program_name', '');
                        str += s1;
                        i++;
                    }

                    str += strAfter;
                }
                return str;
            },

            processReferrals(str) {
                // console.log(`DEBUG: ${this.$options.name}(${this.taskId}).processReferrals(), this.config.referrals=`, this.config.referrals);
                if (this.config.referrals) {
                    str = this.processItem(this.config.referrals, str, 'visit_date');
                    str = this.processItem(this.config.referrals, str, 'total_sum');
                    str = this.processItem(this.config.referrals, str, 'total_sum_company');
                    str = this.processItem(this.config.referrals, str, 'total_sum_payer');
                    str = this.processItem(this.config.referrals, str, 'total_sum_patient');
                    str = this.processItem(this.config.referrals, str, 'currency');

                    let [ strBefore, strRow, strAfter ] = str.split('#referral_row#');
                    // console.log(`DEBUG: ${this.$options.name}(${this.taskId}).processReferrals() strBefore=`, strBefore, ', =', strRow, ', =', strAfter);
                    str = strBefore;

                    let i = 1;
                    for (let row of this.config.referrals.services) {
                        let s1 = strRow;
                        // console.log(`DEBUG: ${this.$options.name}(${this.taskId}).processReferrals() row=`, row);
                        s1 = this.processRowNumber(s1, i);
                        s1 = this.processItem(row, s1, 'teeth', '');
                        // s1 = this.processItem(row, s1, 'referral_code', '');
                        s1 = this.processItem(row, s1, 'name', '');
                        s1 = this.processItem(row, s1, 'price', '');
                        s1 = this.processItem(row, s1, 'qty', '');
                        s1 = this.processItem(row, s1, 'sum_payment', '');
                        // s1 = this.processItem(row, s1, 'program_name', '');
                        str += s1;
                        i++;
                    }

                    str += strAfter;
                }
                return str;
            },

            async processTemplate() {
                // console.log(`DEBUG: ${this.$options.name}(${this.taskId}).processTemplate() this.config=`, this.config);
                let str = this.templateText;

                str = await this.processVisit(str);
                str = await this.processClient(str);
                str = await this.processWorkplace(str);
                str = await this.processUser(str);
                str = await this.processDept(str);
                str = await this.processRoom(str);
                str = await this.processBuilding(str);
                str = await this.processOrg(str);

                str = this.processConsultation(str);
                str = this.processDental(str);

                str = this.processBill(str);
                str = this.processEstimate(str);
                str = this.processReferrals(str);

                this.documentText = str;
            },

            async getTemplate() {
                let filterString = tools.getFilterString({ where:[
                    { code: this.config.template },
                ]});
                try {
                    let template = (await this.authenticatedAxios.get(`${this.baseUrl}/core_printform${filterString}`)).data.rows[0];
                    if (template) {
                        this.templateText = template.template_text;
                        await this.processTemplate();
                    } else {
                        tools.displayError(this, sprintf(this.$t('lib.error.templateNotFound'), this.config.template));
                    }
                    // console.log(`DEBUG: ${this.$options.name}.getTemplate() this.config=`, this.config);
                    // this.templateText = response.data.rows[0].template
                    // this.documentText = this.templateText;
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        beforeMount() {
            // this.taskId = parseInt(this.$route.params.taskId);
            this.config = JSON.parse(localStorage.getItem(`print.${this.taskId}`));
            localStorage.removeItem(`print.${this.taskId}`); // ??? caller page not affected ???
            // console.log(`DEBUG: ${this.$options.name}(${this.taskId}).beforeMount() this.config=`, this.config);
            this.authenticatedAxios.post(`${this.baseUrl}/page`, { url:this.$route.fullPath, build:tools.getFrontendBuild(this) }); // no wait, build:tools.getFrontendBuild(this) })).data;
        },
        async mounted() {
            await this.getTemplate();
            setTimeout("window.print()", 500);
        },
    }
</script>

<style scoped>
    /* html, body {
        width: 210mm;
        height: 297mm;
        margin: 0 auto;
    }

    @page {
        size: portrait;
        margin: 0;
    } */

</style>
