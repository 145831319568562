<!--
    Author: Eduard Grebenyukov
    Date: 2021-05-03
-->

<template>
    <ModalWindow
        width="90%"
        max-width="600px"
        :buttons="buttons"
        @cancel="closePressed"
        @save="saveData"
    >
        <!-- <span slot="header" >{{ $t('app.edit') }}</span> -->
        <span v-if="!editFormData.id" slot="header" :title="msgItemModified">{{ $t('object.med_blood_type') }} {{ isModified? '*' : '' }}</span>
        <span v-if="editFormData.id"  slot="header" :title="msgItemModified">{{ $t('object.med_blood_type') }} {{ isModified? '*' : '' }}</span>

        <div class="container">
            <div class="row">
                <div class="col-12-sm col-md">
                    <FieldRadiobutton
                        :label="$t('attribute.med_blood_type.blood_type_id')"
                        :value.sync="editFormData.blood_type_id"
                        source="enum/blood_type"
                        order="sort_order"
                        labelPosition="above"
                        @modified="itemModified"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12-sm col-md">
                    <FieldRadiobutton
                        :label="$t('attribute.med_blood_type.rh_id')"
                        :value.sync="editFormData.rh_id"
                        source="enum/blood_rh"
                        order="sort_order"
                        labelPosition="above"
                        @modified="itemModified"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12-sm col-md">
                    <FieldRadiobutton
                        :label="$t('attribute.med_blood_type.kell_id')"
                        :value.sync="editFormData.kell_id"
                        source="enum/blood_kell"
                        order="sort_order"
                        labelPosition="above"
                        @modified="itemModified"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12-sm col-md">
                    <FieldText
                        :label="$t('attribute.med_blood_type.blood_commentary')"
                        :value.sync="editFormData.blood_commentary"
                        @modified="itemModified"
                    />
                </div>
            </div>
        </div>

        <WarningClose v-if="isDisplayWarningClose" @process="$event ? processClose(true) : processClose(false)" />

    </ModalWindow>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import WarningClose from '@/components/lib/regions/WarningClose';
    import FieldRadiobutton from '@/components/lib/controls/FieldRadiobutton';
    import FieldText from '@/components/lib/controls/FieldText';

    export default {
        name: 'ClientBloodTypeEdit',

        components: {
            ModalWindow, WarningClose,
            FieldRadiobutton, FieldText,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Props ===============
        props: {
            clientId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                msgItemModified: '',
                editFormData: {
                    // id: null,
                    core_client_id: null,
                    blood_type_id: null,
                    rh_id: null,
                    kell_id: null,
                    rh_c1: false,
                    rh_c2: false,
                    rh_d1: false,
                    rh_e1: false,
                    rh_e2: false,
                    blood_commentary: null,
                },
                buttons: [ 'save', 'close' ],
                isDisplayWarningDelete: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Methods ===============
        methods: {
            async getData() {
                try {
                    let filterString = tools.getFilterString({ where:[{ core_client_id:this.clientId }]});
                    let result = (await this.authenticatedAxios.get(`${this.baseUrl}/med_blood_type${filterString}`)).data.rows[0];
                    if (result) {
                        this.editFormData = result;
                    }
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.editFormData=`, this.editFormData);
                    this.isModified = false;
                    this.msgItemModified = '';
                    this.oldFormData = tools.deepCopy(this.editFormData);
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            await this.authenticatedAxios.put(`${this.baseUrl}/med_blood_type/${this.editFormData.id}`, this.editFormData);
                        } else {
                            // New
                            // let response = 
                            await this.authenticatedAxios.post(`${this.baseUrl}/med_blood_type`, this.editFormData);
                            // console.log(`DEBUG: ${this.$options.name}.saveData() response.data=`, response.data);
                            this.getData();
                        }
                        tools.displaySuccessSave(this.$parent);
                        this.$emit('close-modal', true);
                    } catch (err) {
                        tools.displayApiError(this.$parent, err);
                    }
                } else {
                    // no changes
                    this.$emit('close-modal', false);
                }
            },

            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified()`);
                if (tools.isEqual(this.oldFormData, this.editFormData)) {
                    this.isModified = false;
                    this.msgItemModified = '';
                } else {
                    this.isModified = true;
                    this.msgItemModified = this.$t('app.itemModified');
                }
            },

            processClose(doClose) {
                // console.log(`DEBUG: ${this.$options.name}.processClose(${doClose}) this.next=`, this.next);
                this.isDisplayWarningClose = false;
                window.removeEventListener('beforeunload', this.beforePageDestroyed);
                if (doClose) {
                    this.isModified = false;
                    this.$emit('close-modal', false)
                }
            },
        },

        // =============== Life cycle ===============
        beforeMount() {
            this.editFormData.core_client_id = this.clientId;
        },

        async mounted() {
            await this.getData();
            this.oldFormData = tools.deepCopy(this.editFormData);
        },
    }
</script>

<style>
</style>
