/*
    Author: Eduard Grebenyukov
    Date: 2020-01-08
*/

import { i18n } from '@/locales';
import tools from '@/components/lib/tools';

const mainMenu= [
    { code:'home',      accessToken:'$everyone', title:i18n.t('MainMenu.home'),     href:'/',               icon:'fas fa-home fa-fw' },
    { code:'clients',   accessToken:'clients',   title:i18n.t('MainMenu.clients'),  href:'/clients',        icon:'fas fa-user-injured fa-fw',  alias:['/client', '/client/*'] },
    { code:'schedules', accessToken:'schedules', title:i18n.t('MainMenu.schedule'), href:'/schedules',      icon:'fas fa-calendar-alt fa-fw',  alias:['/schedule', '/schedule/*'] },
    { code:'tasks',     accessToken:'tasks',     title:i18n.t('MainMenu.tasks'),    href:'/tasks',          icon:'fas fa-tasks fa-fw',         alias:['/tasks/task', '/tasks/*'] },
    { code:'finances',  accessToken:'finances',  title:i18n.t('MainMenu.finances'), /* href:'/finances', */ icon:'fas fa-coins fa-fw',         alias:['/finances'], child: [
        { code:'finances.contracts', accessToken:'finances', title:i18n.t('MainMenu.finances-contracts'), href:'/finances/contracts', icon:'fas fa-list-ul fa-fw', alias:['/finances/contract', '/finances/contract/*'] },
        { code:'finances.accounts',  accessToken:'finances', title:i18n.t('MainMenu.finances-accounts'),  href:'/finances/accounts',  icon:'fas fa-list-ul fa-fw', alias:['/finances/accounts/*', '/finances/account', '/finances/account/*'] },
        { code:'finances.bills',     accessToken:'finances', title:i18n.t('MainMenu.finances-bills'),     href:'/finances/bills',     icon:'fas fa-list-ul fa-fw' },
        { code:'finances.reversals', accessToken:'finances', title:i18n.t('MainMenu.finances-reversals'), href:'/finances/reversals', icon:'fas fa-list-ul fa-fw', alias:['/finances/reversal', '/finances/reversal?*', '/finances/reversal/*'] },
        { code:'finances.services',  accessToken:'finances', title:i18n.t('MainMenu.finances-services'),  href:'/finances/services',  icon:'fas fa-list-ul fa-fw' },
        { code:'finances.setprices', accessToken:'finances', title:i18n.t('MainMenu.finances-setprices'), href:'/finances/setprices', icon:'fas fa-list-ul fa-fw', alias:['/finances/setprice', '/finances/setprice/*'] },
        // { code:'finances.invoices', accessToken:'finances', title:i18n.t('MainMenu.finances-invoices'), href:'/finances/invoices', icon:'far fa-file-text fa-fw' },
        // { code:'finances.cashflow', accessToken:'finances', title:i18n.t('MainMenu.finances-cashflow'), href:'/finances/cashflow', icon:'fas fa-credit-card fa-fw' },
        // { code:'finances.currency-rates', title:i18n.t('MainMenu.finances-currency-rates'), href:'/finances/currency-rates', icon:'fas fa-usd fa-fw' },
        // { code:'finances.pnl-items', title:i18n.t('MainMenu.pnl-items'), href:'/finances/pnl-items', icon:'fas fa-cube fa-fw' },
    ]},
    { code:'cashdesk', accessToken:'cashdesk', title:i18n.t('MainMenu.cashdesk'), /* href:'/finances', */ icon:'fas fa-cash-register fa-fw', alias:['/cashdesk', '/cashdesk/*'], child: [
        { code:'cashdesk.payers',  accessToken:'cashdesk', title:i18n.t('MainMenu.cashdesk-payers'),  href:'/cashdesk/payers',  icon:'fas fa-list-ul fa-fw', alias:['/cashdesk/account/*', '/cashdesk/payment'] },
        { code:'cashdesk.control', accessToken:'cashdesk', title:i18n.t('MainMenu.cashdesk-control'), href:'/cashdesk/control', icon:'fas fa-list-ol fa-fw' },
    ]},
    { code:'crm', accessToken:'crm', title:i18n.t('MainMenu.crm'), /* href:'/crm', */ alias:['/crm'], icon:'fas fa-handshake fa-fw', child: [
        { code:'crm.requests', accessToken:'crm', title:i18n.t('MainMenu.crm-requests'), href:'/crm/requests', icon:'fas fa-phone fa-fw', alias:['/crm/request', '/crm/request/*'] },
        { code:'crm.calls',    accessToken:'crm', title:i18n.t('MainMenu.crm-calls'),    href:'/crm/calls',    icon:'fas fa-phone fa-fw' },
    ]},
    { code:'store', title:i18n.t('MainMenu.store'), /* href:'/store', */ alias:['/store'], icon:'fas fa-cubes fa-fw', child: [
        { code:'store.balance',          title:i18n.t('MainMenu.store-balance'),          href:'/store/balance',          icon:'fas fa-cube fa-fw' },
        // { code:'store.supplier-orders', title:i18n.t('MainMenu.store-supplier-orders'), href:'/store/supplier-orders', icon:'fas fa-cube fa-fw' },
        { code:'store.arrivals',         title:i18n.t('MainMenu.store-arrivals'),         href:'/store/arrivals',         icon:'fas fa-cube fa-fw', alias:['/store/arrival', '/store/arrival/*'] },
        { code:'store.supplier-returns', title:i18n.t('MainMenu.store-supplier-returns'), href:'/store/supplier-returns', icon:'fas fa-cube fa-fw', alias:['/store/supplier-return', '/store/supplier-return/*'] },
        // { code:'store.transfer-requests', title:i18n.t('MainMenu.store-transfer-requests'), href:'/store/transfer-requests', icon:'fas fa-cube fa-fw', alias:['/store/store-transfer-request', '/store/store-transfer-request/*'] },
        // { code:'store.consignment-requirements', title:i18n.t('MainMenu.store-consignment-requirements'), href:'/store/consignment-requirements', icon:'fas fa-cube fa-fw', alias:['/store/store-consignment-requirement', '/store/store-consignment-requirement/*'] },
        { code:'store.transfers',        title:i18n.t('MainMenu.store-transfers'),        href:'/store/transfers',        icon:'fas fa-cube fa-fw', alias:['/store/store-transfer', '/store/store-transfer/*'] },
        { code:'store.writeoffs',        title:i18n.t('MainMenu.store-writeoffs'),        href:'/store/writeoffs',        icon:'fas fa-cube fa-fw', alias:['/store/store-writeoff', '/store/store-writeoff/*'] },
        { code:'store.inventory',        title:i18n.t('MainMenu.store-inventory'),        href:'/store/inventories',      icon:'fas fa-cube fa-fw', alias:['/store/store-inventory', '/store/store-inventory/*'] },
        // { code:'store.price-settings', title:i18n.t('MainMenu.store-price-settings'), href:'/store/price-settings', icon:'fas fa-cube fa-fw' },
        { code:'store.dictionaries',     title:i18n.t('MainMenu.store-dictionaries'),                                     icon:'fas fa-table fa-fw', child: [
            { code:'store.stores',         title:i18n.t('MainMenu.store-stores'),      href:'/store/stores',      icon:'fas fa-table fa-fw' },
            { code:'store.units',          title:i18n.t('MainMenu.store-units'),       href:'/store/units',       icon:'fas fa-table fa-fw' },
            { code:'store.groups',         title:i18n.t('MainMenu.store-groups'),      href:'/store/groups',      icon:'fas fa-table fa-fw' },
            // { code:'store.pharmgroups', title:i18n.t('MainMenu.store-pharmgroups'), href:'/store/pharmgroups', icon:'fas fa-table fa-fw' },
            // { code:'store.pricegroups', title:i18n.t('MainMenu.store-pricegroups'), href:'/store/pricegroups', icon:'fas fa-table fa-fw' },
            { code:'store.items',          title:i18n.t('MainMenu.store-items'),       href:'/store/items',       icon:'fas fa-table fa-fw', alias:['/store/item', '/store/item/*'] },
            { code:'store.suppliers',      title:i18n.t('MainMenu.store-suppliers'),   href:'/store/suppliers',   icon:'fas fa-table fa-fw' },
        ]},
    ]},
    { code:'reports', title:i18n.t('MainMenu.reports'), /* href:'/reports', */ alias:['/reports'], icon:'fas fa-chart-bar fa-fw', roles: ['administrator'], child: [
        { code:'reports.price',                title:i18n.t('MainMenu.reports-price'),                href:'/reports/price',                icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.doctor-personal',      title:i18n.t('MainMenu.reports-doctor-personal'),      href:'/reports/doctor-personal',      icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.visits',               title:i18n.t('MainMenu.reports-visits'),               href:'/reports/visits',               icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.doctors',              title:i18n.t('MainMenu.reports-doctors'),              href:'/reports/doctors',              icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.doctors-patients',     title:i18n.t('MainMenu.reports-doctors-patients'),     href:'/reports/doctors-patients',     icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.adm',                  title:i18n.t('MainMenu.reports-adm'),                  href:'/reports/adm',                  icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.adm-patients',         title:i18n.t('MainMenu.reports-adm-patients'),         href:'/reports/adm-patients',         icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.adm-patients-primary', title:i18n.t('MainMenu.reports-adm-patients-primary'), href:'/reports/adm-patients-primary', icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.daily',                title:i18n.t('MainMenu.reports-daily'),                href:'/reports/daily',                icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.daily-doctors',        title:i18n.t('MainMenu.reports-daily-doctors'),        href:'/reports/daily-doctors',        icon:'fas fa-chart-bar fa-fw' },
        // { code:'reports.daily-pivot', title:i18n.t('MainMenu.reports-daily'), href:'/reports/daily-pivot', icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.deposit',              title:i18n.t('MainMenu.reports-deposit'),              href:'/reports/deposit',              icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.doctors-eff',          title:i18n.t('MainMenu.reports-doctors-eff'),          href:'/reports/doctors-eff',          icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.services-rendered',    title:i18n.t('MainMenu.reports-services-rendered'),    href:'/reports/services-rendered',    icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.revenue',              title:i18n.t('MainMenu.reports-revenue'),              href:'/reports/revenue',              icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.wage-doctors',         title:i18n.t('MainMenu.reports-wage-doctors'),         href:'/reports/wage-doctors',         icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.wage-assistants',      title:i18n.t('MainMenu.reports-wage-assistants'),      href:'/reports/wage-assistants',      icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.labo-services',        title:i18n.t('MainMenu.reports-labo-services'),        href:'/reports/labo-services',        icon:'fas fa-chart-bar fa-fw' },
        // { code:'reports.pnl', title:'Доходы и расходы', href:'/reports/pnl', icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.studies',              title:i18n.t('MainMenu.reports-studies'),              href:'/reports/studies',              icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.patients',             title:i18n.t('MainMenu.reports-patients'),             href:'/reports/patients',             icon:'fas fa-chart-bar fa-fw' },
        { code:'reports.debts',                title:i18n.t('MainMenu.reports-debts'),                href:'/reports/debts',                icon:'fas fa-chart-bar fa-fw' },
    ]},
    { code:'settings', title:i18n.t('MainMenu.settings'), /* href:'/settings', */ icon:'fas fa-cogs fa-fw', alias:['/settings'], roles: ['administrator'], child: [
        { code:'settings.general', title:i18n.t('MainMenu.settings-general'), /* href:'/settings/general', */ icon:'fas fa-cog fa-fw', alias:['/settings/general'], child: [
            { code:'settings.general.system',          title:i18n.t('MainMenu.settings-general-system'),          href:'/settings/general/system',          icon:'' },
            { code:'settings.general.enum',            title:i18n.t('MainMenu.settings-general-enum'),            href:'/settings/general/enum_group',      icon:'', alias:['/settings/general/enum/*'] },
            { code:'settings.general.organizations',   title:i18n.t('MainMenu.settings-general-organizations'),   href:'/settings/general/organizations',   icon:'' },
            { code:'settings.general.buildings',       title:i18n.t('MainMenu.settings-general-buildings'),       href:'/settings/general/buildings',       icon:'' },
            { code:'settings.general.departments',     title:i18n.t('MainMenu.settings-general-departments'),     href:'/settings/general/departments',     icon:'' },
            { code:'settings.general.rooms',           title:i18n.t('MainMenu.settings-general-rooms'),           href:'/settings/general/rooms',           icon:'' },
            { code:'settings.general.countries',       title:i18n.t('MainMenu.settings-general-countries'),       href:'/settings/general/countries',       icon:'' },
            { code:'settings.general.specialities',    title:i18n.t('MainMenu.settings-general-specialities'),    href:'/settings/general/specialities',    icon:'' },
            { code:'settings.general.devices',         title:i18n.t('MainMenu.settings-general-devices'),         href:'/settings/general/devices',         icon:'' },
            // { code:'settings.general.visit-types', title:i18n.t('MainMenu.settings-general-visit-types'), href:'/settings/general/visit-types', icon:'' },
            { code:'settings.general.printforms',      title:i18n.t('MainMenu.settings-general-printforms'),      href:'/settings/general/printforms',      icon:'', alias:['/settings/general/printform/*'] },
            // { code:'settings.general.component-types', title:i18n.t('MainMenu.settings-general-component-types'), href:'/settings/general/component-types', icon:'' },
            { code:'settings.general.components',      title:i18n.t('MainMenu.settings-general-components'),      href:'/settings/general/components',      icon:'', alias:['/settings/general/component/*'] },
        ]},
        { code:'settings.rights', title:i18n.t('MainMenu.settings-rights'), /* href:'/settings/rights', */ icon:'fas fa-users-cog fa-fw', alias:['/settings/rights'], child: [
            { code:'settings.rights.users',        title:i18n.t('MainMenu.settings-rights-users'),        href:'/settings/rights/users',        icon:'', alias:['/settings/rights/user', '/settings/rights/user/*', '/settings/visits/workplace/*'] },
            { code:'settings.rights.roles',        title:i18n.t('MainMenu.settings-rights-roles'),        href:'/settings/rights/roles',        icon:'', alias:['/settings/rights/role/*'] },
            { code:'settings.rights.permissions',  title:i18n.t('MainMenu.settings-rights-permissions'),  href:'/settings/rights/permissions',  icon:'', alias:['/settings/rights/permissions/*'] },
            // { code:'settings.rights.menu', title:i18n.t('MainMenu.settings-rights-menu'), href:'/settings/rights/menu', icon:'', alias:['/settings/rights/menu/*'] },
            { code:'settings.rights.open-periods', title:i18n.t('MainMenu.settings-rights-open-periods'), href:'/settings/rights/open-periods', icon:'' },
        ]},
        { code:'settings.visits', title:i18n.t('MainMenu.settings-visits'), /* href:'/settings/visits', */ icon:'fas fa-calendar-alt fa-fw', alias:['/settings/visits'], child: [
            { code:'settings.visits.workplaces',     title:i18n.t('MainMenu.settings-visits-workplaces'),     href:'/settings/visits/workplaces',     icon:'', alias:['/settings/visits/workplace', '/settings/visits/workplace/*'] },
            { code:'settings.visits.types',          title:i18n.t('MainMenu.settings-visits-types'),          href:'/settings/visits/types',          icon:'', alias:['/settings/visits/visit-type', '/settings/visits/visit-type/*'] },
            { code:'settings.visits.worktimes',      title:i18n.t('MainMenu.settings-visits-worktime'),       href:'/settings/visits/worktimes',      icon:'', alias:['/settings/visits/worktimes', '/settings/visits/worktime/*'] },
            { code:'settings.visits.lock-types',     title:i18n.t('MainMenu.settings-visits-lock-types'),     href:'/settings/visits/lock-types',     icon:'' },
            { code:'settings.visits.groups',         title:i18n.t('MainMenu.settings-visits-groups'),         href:'/settings/visits/groups',         icon:'', alias:['/settings/visits/visit-group-workplaces', '/settings/visits/visit-group-workplaces/*'] },
            { code:'settings.visits.type-pages',     title:i18n.t('MainMenu.settings-visits-type-pages'),     href:'/settings/visits/type-pages',     icon:'' },
            { code:'settings.visits.visitor-groups', title:i18n.t('MainMenu.settings-visits-visitor-groups'), href:'/settings/visits/visitor-groups', icon:'' },
            { code:'settings.visits.routes',         title:i18n.t('MainMenu.settings-visits-routes'),         href:'/settings/visits/routes',         icon:'', alias:['/settings/visits/route', '/settings/visits/route/*'] },
            { code:'settings.visits.queue-monitor',  title:i18n.t('MainMenu.settings-visits-queue-monitor'),  href:'/settings/visits/queue-monitor',  icon:'' },
        ]},
        { code:'settings.med', title:i18n.t('MainMenu.settings-med'), /* href:'/settings/med', */ icon:'fas fa-medkit fa-fw', alias:['/settings/med'], child: [
            { code:'settings.med.referral-types',       title:i18n.t('MainMenu.settings-med-referral-types'),       href:'/settings/med/referral-types', icon:'' },
            // { code:'settings.med.referral-visit-types', title:i18n.t('MainMenu.settings-med-referral-visit-types'), href:'/settings/med/referral-visit-types', icon:'' },
            { code:'settings.med.templates',            title:i18n.t('MainMenu.settings-med-templates'),            href:'/settings/med/templates',      icon:'' },
            // { code:'settings.med.visit-type-pages', title:i18n.t('MainMenu.settings-med-visit-type-pages'), href:'/settings/med/visit-type-pages', icon:'' },
            { code:'settings.med.event-types',          title:i18n.t('MainMenu.settings-med-event-types'),          href:'/settings/med/event-types',    icon:'' },
            { code:'settings.med.beds',                 title:i18n.t('MainMenu.settings-med-beds'),                 href:'/settings/med/beds',           icon:'' },
        ]},
        { code:'settings.finance', title:i18n.t('MainMenu.settings-finance'), /* href:'/settings/finance', */ icon:'fas fa-coins fa-fw', alias:['/settings/finance'], child: [
            { code:'settings.finance.currencies',     title:i18n.t('MainMenu.settings-finance-currencies'),     href:'/settings/finance/currencies',     icon:'' },
            { code:'settings.finance.sources',        title:i18n.t('MainMenu.settings-finance-sources'),        href:'/settings/finance/sources',        icon:'' },
            { code:'settings.finance.vats',           title:i18n.t('MainMenu.settings-finance-vats'),           href:'/settings/finance/vats',           icon:'' },
            { code:'settings.finance.service-groups', title:i18n.t('MainMenu.settings-finance-service-groups'), href:'/settings/finance/service-groups', icon:'' },
            { code:'settings.finance.services',       title:i18n.t('MainMenu.settings-finance-services'),       href:'/settings/finance/services',       icon:'' },
            { code:'settings.finance.service-sets',   title:i18n.t('MainMenu.settings-finance-service-sets'),   href:'/settings/finance/sets',           icon:'', alias:['/settings/finance/set/*'] },
            { code:'settings.finance.pricelists',     title:i18n.t('MainMenu.settings-finance-pricelists'),     href:'/settings/finance/pricelists',     icon:'' },
            { code:'settings.finance.prices',         title:i18n.t('MainMenu.settings-finance-prices'),         href:'/settings/finance/prices',         icon:'' },
            { code:'settings.finance.discounts',      title:i18n.t('MainMenu.settings-finance-discounts'),      href:'/settings/finance/discounts',      icon:'' },
            { code:'settings.finance.payment-types',  title:i18n.t('MainMenu.settings-finance-payment-types'),  href:'/settings/finance/payment-types',  icon:'' },
        ]},
        { code:'settings.navigation', title:i18n.t('MainMenu.settings-navigation'), /* href:'/settings/navigation', */ icon:'fas fa-route fa-fw', alias:['/settings/navigation'], child: [
            { code:'settings.navigation.maps', title:i18n.t('MainMenu.settings-navigation-maps'), href:'/settings/navigation/maps', icon:'' },
            { code:'settings.navigation.points', title:i18n.t('MainMenu.settings-navigation-points'), href:'/settings/navigation/points', icon:'', alias:['/settings/navigation/point', '/settings/navigation/point/*'] },
            { code:'settings.navigation.connections',  title:i18n.t('MainMenu.settings-navigation-connections'),   href:'/settings/navigation/connections',  icon:'' },
        ]},
        { code:'settings.questionaire', title:i18n.t('MainMenu.settings-questionaire'), /* href:'/settings/questionaire', */ icon:'fas fa-list-ol fa-fw', child: [
            { code:'settings.questionaire.items', title:i18n.t('MainMenu.settings-questionaire-items'), href:'/settings/questionaire/items', icon:'' },
        ]},
        { code:'settings.system', title:i18n.t('MainMenu.settings-system'), /* href:'', */ icon:'fas fa-cogs fa-fw', child: [
            { code:'settings.system.sessions',    title:i18n.t('MainMenu.settings-system-sessions'),    href:'/settings/system/sessions' },
            { code:'settings.system.log',         title:i18n.t('MainMenu.settings-system-log'),         href:'/settings/system/log' },
            { code:'settings.system.errors',      title:i18n.t('MainMenu.settings-system-errors'),      href:'/settings/system/errors' },
            { code:'settings.system.performance', title:i18n.t('MainMenu.settings-system-performance'), href:'/settings/system/performance' },
        //   { code:'settings.system.user-activity', name: 'User activity', href:'/settings/user-activity' },
        //   { code:'settings.system.change-log', name: 'Change log', href:'/settings/change-log' },
        //   { code:'settings.system.license', name: 'License', href:'/settings/license' },
        ] },
    ]}
];

function filterData(state, data) {
    var r = data.filter(function(o) {
        if (o.child) o.child = filterData(state, o.child);
        return tools.isMainMenuItemGranted(state.auth.user.permissions, o);
    });
    return r;
}

function getMainMenu(state) {
    // let filteredMenu = mainMenu.filter(function f(o) {
    //     return isMainMenuItemGranted(state.auth.user.permissions, o) || o.child && (o.child = o.child.filter(f)).length;
    // });

    let filteredMenu = filterData(state, JSON.parse(JSON.stringify(mainMenu)));

    // let filteredMenu = [];
    // for (let item of mainMenu) {
    //     if ((!item.accessToken) || state.auth.user.permissions.filter( p => p.privilege_code === 'access_token' && p.code === item.accessToken ).length > 0 ) {
    //         filteredMenu.push(item);
    //     }
    // }
    let client = state.lastClient;
    let clientMenu = filteredMenu.find( item => item.code === 'clients' );
    if (clientMenu) {
        let searchMenu = { code:'clients.search', title:i18n.t('MainMenu.clients-search'), href:`/clients`, icon:'fas fa-search fa-fw' };
        let mergeMenu = {
            code:'clients.merge', title:i18n.t('MainMenu.clients-to-merge'), href:`/clients-to-merge`, alias:[`/clients-merge/*`], icon:'fas fa-compress-alt fa-fw'
        };
        if (client && client.id) {
            if (clientMenu) {
                clientMenu.href = null;
                clientMenu.child = [
                    searchMenu,
                    { code:'clients.ehr', title:client.shortname, href:`/client/${client.id}`, alias:[`/client/*`], child: [
                        { code:'clients.visits', title:i18n.t('MainMenu.clients-visits'), href:`/client/${client.id}/visits`, alias:[`/client/*/visit/*`], icon:'fas fa-id-card fa-fw' },
                        { code:'clients.lab-results', title:i18n.t('MainMenu.clients-lab-results'), href:`/client/${client.id}/lab-results`, alias:[], icon:'fas fa-flask fa-fw' },
                        { code:'clients.files',  title:i18n.t('MainMenu.clients-files'), href:`/client/${client.id}/files`, alias:[`/client/*/file/*`], icon:'fas fa-copy fa-fw' },
                        { code:'clients.tasks', title:i18n.t('MainMenu.clients-tasks'), href:`/client/${client.id}/tasks`, alias:[`/client/*/task/*`], icon:'fas fa-tasks fa-fw' },
                        // { code:'clients.personal', title:i18n.t('MainMenu.clients-personal'), href:`/client/${client.id}/personal`, icon:'fas fa-key fa-fw' },
                        // { code:'clients.communication', title:i18n.t('MainMenu.clients-communication'), href:`/client/${client.id}/communication`, icon:'fas fa-comments fa-fw' },
                    ] },
                    // mergeMenu,
                ];
            }
        } else {
            clientMenu.href = null;
            clientMenu.child = [
                searchMenu,
                // mergeMenu,
            ];
        }
        if (tools.isMainMenuItemGranted(state.auth.user.permissions, mergeMenu)) {
            clientMenu.child.push(mergeMenu);
        }
    }
    return filteredMenu;
}

export { mainMenu, getMainMenu };
