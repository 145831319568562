/*
    Author:Eduard Grebenyukov
    Date:2019-12-30
*/

import Vue from 'vue';
import VueRouter from 'vue-router';

import { i18n } from '@/locales';
import store from '../store';

import routesClients from './clients';
import routesVisits from './visits';
import routesMed from './med';
import routesTasks from './tasks';
import routesFinances from './finances';
import routesCashdesk from './cashdesk';
import routesCRM from './crm';
import routesStore from './store';
import routesReports from './reports';
import routesSettings from './settings';

import LoginPage from '@/components/pages/common/LoginPage';
import HomePage from '@/components/pages/common/HomePage';
// Lazy loading
const PrintPage = () => import('@/components/pages/common/PrintPage');
const AccessDenied = () => import('@/components/pages/common/AccessDenied');
// const NotFound = () => import('@/components/pages/common/NotFound');
// const DynamicPage = () => import('@/components/pages/common/DynamicPage');
// const DynamicPage = () => import('http://localhost:5000/api/component/reports/ReportDailyGrid');
// import DynamicPage from 'http://localhost:5000/api/component/reports/ReportDailyGrid';

Vue.use(VueRouter);

const routes = [
    { name:'login',     path:'/login',              component:LoginPage,             meta:{title:i18n.t('LoginPage.title'), isPublic:true} },
    { name:'home',      path:'/',                   component:HomePage,              meta:{title:i18n.t('MainMenu.home')} },
    { name:'print',     path:'/print/:taskId',      component:PrintPage, props:true, meta:{title:i18n.t('app.print')} },
    { name:'logout',    path:'/logout' },
    // { path:'*', redirect:'/'}
    // { name:'error.404', path:'*', component:NotFound, meta:{title:'Page not found'} }
    { name:'error.403', path:'*',                   component:AccessDenied,          meta:{title:'Access Denied'} },

    // { name:'test',      path:'/test',               component:DynamicPage,              meta:{title:'test page'} },

    ...routesClients,
    ...routesVisits,
    ...routesMed,
    ...routesTasks,
    ...routesFinances,
    ...routesCashdesk,
    ...routesCRM,
    ...routesStore,
    ...routesReports,
    ...routesSettings,
]

const router = new VueRouter({
    mode:'history',
    base:process.env.BASE_URL,
    routes
});

function checkAuth(cb) {
    // console.log('checkAuth.router=', router);
    // console.log('checkAuth.store=', store);
    // console.log('checkAuth.context=', context);
    // console.log('checkAuth.this.state=', this.state);
    store.getters.authenticatedAxios.get(store.getters.checkAuthUrl).then( response => {
        // console.log('/router/index.checkAuth() done response=', response);
        cb();
    }).catch( err => {
        store.commit('clearAuthentication');
        // console.log(`DEBUG: /router/index.checkAuth() Authentication expired`);
        // console.log(`DEBUG: /router/index.checkAuth() ERROR:`, err);
        cb();
    });
}

router.beforeEach((to, from, next) => {
    // console.log(`DEBUG: /router/index router.beforeEach to=`, to);

    function go() {
        document.title = to.meta.title + ' - ' + store.getters.appName;
        return next();
    }

    if (to.path === '/logout') {
        store.dispatch('logout');
        return next('/login');
    }

    //store.dispatch('checkAuth');
    if (to.matched.some(m => m.meta.isPublic)) {
        go();
    } else {
        checkAuth(() => {
            // console.log(`DEBUG: /router/index router.beforeEach store.state.auth.authenticated=`, store.state.auth.authenticated);
            if (store.state.auth.authenticated) {
                go();
            } else {
                return next('/login');
            }
        });
    }
});

export default router
