<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-12
-->

<template>
    <div>
        <VueContext ref="contextMenu">
            <template  v-slot:default="child">
                <li v-if="showContextCreateSchedule">
                    <a href="#" @click.prevent="onContextMenu({ operation:CREATE_SCHEDULE_REC, ...child.data })" class="default-action">
                        <i class="fas fa-calendar-alt fa-fw visit-create-schedule-rec"></i>
                        {{ $t('Visit.create_schedule_rec') }}
                    </a>
                </li>
                <li v-if="showContextOpenSchedule">
                    <a href="#" @click.prevent="onContextMenu({ operation:OPEN_SCHEDULE_REC, ...child.data })" class="default-action">
                        <i class="fas fa-calendar-alt fa-fw visit-open-schedule-rec"></i>
                        {{ $t('Visit.open_schedule_rec') }}
                    </a>
                </li>

                <!-- ---------------------------------------------------------------- -->
                <hr
                    v-if="
                        (
                            showContextCreateSchedule || showContextOpenSchedule
                        ) && (
                            showContextVisitConfirmed || showContextVisitConfirmedCancel ||
                            showContextClientArrived  || showContextClientArrivedCancel  ||
                            showContextVisitStarted   || showContextVisitStartedCancel   ||
                            showContextVisitCompleted || showContextVisitCompletedCancel ||
                            showContextVisitClosed    || showContextVisitClosedCancel    ||
                            showContextClientCanceled || showContextClientCanceledCancel ||
                            showContextChangeWorkplace
                        )
                    "
                    class="context-divider"
                >

                <li v-if="showContextVisitConfirmed">
                    <a href="#" @click.prevent="onContextMenu({ operation:VISIT_CONFIRMED, ...child.data })">
                        <i class="fas fa-user-check fa-fw visit-client-confirmed"></i>
                        {{ $t('Visit.visit_confirmed') }}
                    </a>
                </li>
                <li v-if="showContextClientArrived">
                    <a href="#" @click.prevent="onContextMenu({ operation:CLIENT_ARRIVED, ...child.data })">
                        <i class="fas fa-user-circle fa-fw visit-client-arrived"></i>
                        {{ $t('Visit.client_arrived') }}
                    </a>
                </li>
                <li v-if="showContextVisitStarted">
                    <a href="#" @click.prevent="onContextMenu({ operation:VISIT_STARTED, ...child.data })">
                        <i class="fas fa-play fa-fw visit-started"></i>
                        {{ $t('Visit.visit_started') }}
                    </a>
                </li>
                <li v-if="showContextVisitCompleted">
                    <a href="#" @click.prevent="onContextMenu({ operation:VISIT_COMPLETED, ...child.data })">
                        <i class="fas fa-stop fa-fw visit-completed"></i>
                        {{ $t('Visit.visit_completed') }}
                    </a>
                </li>
                <li v-if="showContextVisitClosed">
                    <a href="#" @click.prevent="onContextMenu({ operation:VISIT_CLOSED, ...child.data })">
                        <i class="fas fa-check-square fa-fw visit-closed"></i>
                        {{ $t('Visit.visit_closed') }}
                    </a>
                </li>
                <li v-if="showContextClientCanceled">
                    <a href="#" @click.prevent="onContextMenu({ operation:CLIENT_CANCELED, ...child.data })">
                        <!-- <span class="fa-stack fa-sm">
                            <i class="fas fa-user fa-stack-2x"></i>
                            <i class="fas fa-times fa-stack-1x" style="color:red;" data-fa-transform="shrink-11 down-4.2 right-4"></i>
                            <i class="fas fa-times fa-stack-1x" style="color:red;"></i>
                        </span> -->
                        <i class="fas fa-times-circle fa-fw visit-canceled"></i>
                        {{ $t('Visit.visit_canceled') }}
                    </a>
                </li>

                <li v-if="showContextVisitConfirmedCancel">
                    <a href="#" @click.prevent="onContextMenu({ operation:VISIT_CONFIRMED_CANCEL, ...child.data })">
                        <i class="fas fa-undo fa-fw visit-status-cancel"></i>
                        {{ $t('Visit.visit_confirmed_cancel') }}
                    </a>
                </li>
                <li v-if="showContextClientArrivedCancel">
                    <a href="#" @click.prevent="onContextMenu({ operation:CLIENT_ARRIVED_CANCEL, ...child.data })">
                        <i class="fas fa-undo fa-fw visit-status-cancel"></i>
                        {{ $t('Visit.client_arrived_cancel') }}
                    </a>
                </li>
                <li v-if="showContextVisitStartedCancel">
                    <a href="#" @click.prevent="onContextMenu({ operation:VISIT_STARTED_CANCEL, ...child.data })">
                        <i class="fas fa-undo fa-fw visit-status-cancel"></i>
                        {{ $t('Visit.visit_started_cancel') }}
                    </a>
                </li>
                <li v-if="showContextVisitCompletedCancel">
                    <a href="#" @click.prevent="onContextMenu({ operation:VISIT_COMPLETED_CANCEL, ...child.data })">
                        <i class="fas fa-undo fa-fw visit-status-cancel"></i>
                        {{ $t('Visit.visit_completed_cancel') }}
                    </a>
                </li>
                <li v-if="showContextVisitClosedCancel">
                    <a href="#" @click.prevent="onContextMenu({ operation:VISIT_CLOSED_CANCEL, ...child.data })">
                        <i class="fas fa-undo fa-fw visit-status-cancel"></i>
                        {{ $t('Visit.visit_closed_cancel') }}
                    </a>
                </li>
                <li v-if="showContextClientCanceledCancel">
                    <a href="#" @click.prevent="onContextMenu({ operation:CLIENT_CANCELED_CANCEL, ...child.data })">
                        <i class="fas fa-undo fa-fw visit-status-cancel"></i>
                        {{ $t('Visit.visit_canceled_cancel') }}
                    </a>
                </li>
                <li v-if="showContextChangeWorkplace">
                    <a href="#" @click.prevent="onContextMenu({ operation:EDIT_FINAL_WORKPLACE, ...child.data })">
                        <i class="fas fa-edit fa-fw visit-edit-final-workplace"></i>
                        {{ $t('Visit.edit_final_workplace') }}
                    </a>
                </li>

                <!-- ---------------------------------------------------------------- -->
                <hr v-if="
                    showContextCreateClient ||
                    showContextOpenClient ||
                    showContextOpenVisit ||
                    showContextOpenPayment
                " class="context-divider">

                <li v-if="showContextCreateClient">
                    <a href="#" @click.prevent="onContextMenu({ operation:CREATE_CLIENT, ...child.data })">
                        <i class="fas fa-user-plus fa-fw visit-create-client"></i>
                        {{ $t('Visit.create_client') }}
                    </a>
                </li>
                <li v-if="showContextOpenClient">
                    <a href="#" @click.prevent="onContextMenu({ operation:OPEN_CLIENT, ...child.data })">
                        <i class="fas fa-user fa-fw visit-open-client"></i>
                        {{ $t('Visit.open_client') }}
                    </a>
                </li>
                <li v-if="showContextOpenVisit">
                    <a href="#" @click.prevent="onContextMenu({ operation:OPEN_VISIT_REC, ...child.data })">
                        <i class="fas fa-id-card fa-fw visit-open-visit"></i>
                        {{ $t('Visit.open_visit') }}
                    </a>
                </li>
                <li v-if="showContextOpenPayment">
                    <a href="#" @click.prevent="onContextMenu({ operation:OPEN_PAYMENT, ...child.data })">
                        <i class="fas fa-coins fa-fw visit-open-payment"></i>
                        {{ $t('Visit.open_payment') }}
                    </a>
                </li>

                <!-- ---------------------------------------------------------------- -->
                <hr v-if="showContextCutRec || showContextCopyRec || showContextPasteRec" class="context-divider">

                <li v-if="showContextCutRec">
                    <a href="#" @click.prevent="onContextMenu({ operation:CUT_VISIT, ...child.data })">
                        <i class="fas fa-cut fa-fw visit-cut"></i>
                        {{ $t('app.cut') }}
                    </a>
                </li>
                <li v-if="showContextCopyRec">
                    <a href="#" @click.prevent="onContextMenu({ operation:COPY_VISIT, ...child.data })">
                        <i class="fas fa-copy fa-fw visit-copy"></i>
                        {{ $t('app.copy') }}
                    </a>
                </li>
                <li v-if="showContextPasteRec">
                    <a href="#" @click.prevent="onContextMenu({ operation:PASTE_VISIT, ...child.data })">
                        <i class="fas fa-paste fa-fw visit-paste"></i>
                        {{ $t('app.paste') }}
                    </a>
                </li>
            </template>
        </VueContext>

        <ModalWindow v-if="isDisplayWorkplaceList" width="900px" max-width="90%" max-height="90vh" @cancel="isDisplayWorkplaceList = false" :header="$t('object.core_workplace')" >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="workplaces" :config="workplaceGridConfig" @item-selected="workplaceSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { VueContext } from 'vue-context';
    import tools from '@/components/lib/tools';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'VisitContextMenu',

        components: { VueContext, ModalWindow, Grid },

        // =============== Props ===============
        props: {
            mode: String,
        },

        // =============== Data ===============
        data() {
            return {
                visit: null,
                operation: null,
                showContextVisitConfirmed: false,
                showContextClientArrived: false,
                showContextVisitStarted: false,
                showContextVisitCompleted: false,
                showContextVisitClosed: false,
                showContextClientCanceled: false,
                showContextVisitConfirmedCancel: false,
                showContextClientArrivedCancel: false,
                showContextVisitStartedCancel: false,
                showContextVisitCompletedCancel: false,
                showContextVisitClosedCancel: false,
                showContextClientCanceledCancel: false,
                showContextChangeWorkplace: false,

                showContextCreateClient: false,
                showContextOpenClient: false,
                showContextOpenVisit: false,
                showContextCreateSchedule: false,
                showContextOpenSchedule: false,
                showContextOpenPayment: false,

                showContextCutRec: false,
                showContextCopyRec: false,
                showContextPasteRec: false,
                // codes
                VISIT_CONFIRMED: 'context_visit_confirmed',
                CLIENT_ARRIVED:  'context_client_arrived',
                VISIT_STARTED:   'context_visit_started',
                VISIT_COMPLETED: 'context_visit_completed',
                VISIT_CLOSED:    'context_visit_closed',
                CLIENT_CANCELED: 'context_client_canceled',

                VISIT_CONFIRMED_CANCEL: 'context_visit_confirmed_cancel',
                CLIENT_ARRIVED_CANCEL:  'context_client_arrived_cancel',
                VISIT_STARTED_CANCEL:   'context_visit_started_cancel',
                VISIT_COMPLETED_CANCEL: 'context_visit_completed_cancel',
                VISIT_CLOSED_CANCEL:    'context_visit_closed_cancel',
                CLIENT_CANCELED_CANCEL: 'context_client_canceled_cancel',

                CREATE_CLIENT:        'context_create_client',
                OPEN_CLIENT:          'context_open_client',
                OPEN_VISIT_REC:       'context_open_visit_rec',
                EDIT_FINAL_WORKPLACE: 'context_edit_final_workplace',
                CREATE_SCHEDULE_REC:  'context_create_schedule',
                OPEN_SCHEDULE_REC:    'context_open_schedule',
                OPEN_PAYMENT:         'context_open_payment',

                CUT_VISIT:   'context_cut_rec',
                COPY_VISIT:  'context_copy_rec',
                PASTE_VISIT: 'context_paste_rec',
                // ----------------
                clipboardContent: null,
                // ----------------
                isDisplayWorkplaceList: false,
                workplaceGridConfig: {
                    apiUrl: null, // visit_final_user_workplaces
                    filter: null,
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'string', key:'user_name', header:this.$t('app.employee') },
                        { type:'string', key:'building_name', header:this.$t('object.core_building') },
                        { type:'string', key:'dept_name', header:this.$t('object.core_dept') },
                        { type:'string', key:'speciality_name', header:this.$t('object.core_speciality') },
                        { type:'string', key:'room_name', header:this.$t('object.core_room') },
                        // { type:'string', key:'device_name', header:this.$t('object.core_device') },
                        { type:'string', key:'position', header:this.$t('attribute.common.position') },
                    ],
                    order: [ 'user_name', 'building_name', 'speciality_name' ],
                    displayToolbar: true,
                    row: { emit: "item-selected", style: "cursor: pointer;" },
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                getAppClipboard: 'getAppClipboard',
            }),
        },

        // =============== Watch ===============
        watch: {
            visit() {
                // this.getData();
            },
        },

        // =============== Methods ===============
        methods: {
            ...mapActions([ 'setLastClientId', 'setLastVisitId', 'clearLastClient' ]),
            // scheduleNew(e, slot) {
            //     // console.log(`DEBUG: ${this.$options.name}.scheduleNew() slot=`, slot);
            //     this.$router.push({ name: 'schedule-new', params: { coreWorkplaceId: slot.coreWorkplaceId, timeOf: slot.timeOf } });
            // },

            // scheduleEdit(e, rec) {
            //     this.$router.push({ name: 'schedule-edit', params: { id: rec.id } });
            // },

            async startVisit() {
                try {
                    await tools.setVisitStatus(this, this.visit.id, 'started');
                    await this.setLastVisitId(this.visit.id); // need to VisitTabs enable tabs on status changed
                    tools.displaySuccessSave(this);
                    this.$emit('refresh-status');
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            finalizeWorkplace(visit) {
                // console.log(`DEBUG: ${this.$options.name}.finalizeWorkplace(), visit=`, visit);
                this.workplaceGridConfig.apiUrl = `visit_final_user_workplaces/${visit.id}`;
                this.isDisplayWorkplaceList = true;
            },

            async workplaceSelected(workplace) {
                // console.log(`DEBUG: ${this.$options.name}.workplaceSelected(), workplace=`, workplace);
                try {
                    await this.authenticatedAxios.put(`${this.baseUrl}/visit_rec/${this.visit.id}`, { workplace_final_id: workplace.id });
                    this.isDisplayWorkplaceList = false;
                    if (this.operation === this.VISIT_STARTED) {
                        await this.startVisit();
                    } else {
                        this.$emit('refresh-status');
                    }
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            show(evt, visit, slot) {
                this.visit = visit;
                // console.log(`DEBUG: ${this.$options.name}.show() this.visit=`, this.visit);
                if (visit) {
                    // Filled cell
                    this.showContextVisitConfirmed = this.visit.status_code === 'planned';
                    this.showContextClientArrived  = ['planned', 'confirmed'].includes(this.visit.status_code);
                    this.showContextVisitStarted   = this.visit.status_code === 'arrived' && this.visit.core_client_id;
                    this.showContextVisitCompleted = this.visit.status_code === 'started';
                    this.showContextVisitClosed    = this.visit.status_code === 'completed';
                    this.showContextClientCanceled = this.visit.status_code === 'planned';

                    this.showContextVisitConfirmedCancel = this.visit.status_code === 'confirmed';
                    this.showContextClientArrivedCancel  = this.visit.status_code === 'arrived' && !this.visit.is_medical_data && !this.visit.is_financial_data;
                    this.showContextVisitStartedCancel   = this.visit.status_code === 'started' && !this.visit.is_medical_data && !this.visit.is_financial_data;
                    this.showContextVisitCompletedCancel = this.visit.status_code === 'completed';
                    this.showContextVisitClosedCancel    = this.visit.status_code === 'closed';
                    this.showContextClientCanceledCancel = this.visit.status_code === 'canceled';

                    // console.log(`DEBUG: ${this.$options.name}.show() (${this.showContextClientArrived},${this.showContextVisitStarted},${this.showContextVisitCompleted},${this.showContextVisitClosed},${this.showContextClientCanceled})`);
                    // console.log(`DEBUG: ${this.$options.name}.show() this.visit=`, this.visit);
                    if (this.visit.is_client) {
                        this.showContextCreateClient = false;
                        this.showContextOpenClient = true;
                        this.showContextOpenVisit = true;
                        if (this.mode === 'client' && ['started', 'completed'].includes(this.visit.status_code)) {
                            this.showContextChangeWorkplace = true;
                        } else {
                            this.showContextChangeWorkplace = false;
                        }
                    } else {
                        this.showContextCreateClient = true;
                        this.showContextOpenClient = false;
                        this.showContextOpenVisit = false;
                        this.showContextChangeWorkplace = false;
                    }
                    this.showContextCreateSchedule = false;
                    if (this.mode === 'schedule') this.showContextOpenSchedule = true;
                    // this.showContextOpenPayment = visit.sum_unpaid_client && tools.isPrivilegeGranted(this.currentUser.permissions, 'cashdesk');
                    this.showContextOpenPayment = tools.isPrivilegeGranted(this.currentUser.permissions, 'cashdesk') && this.visit.core_client_id;

                    this.showContextCutRec = tools.isScheduleEditable(this.visit.status_code) && this.mode === 'schedule';
                    this.showContextCopyRec = (this.mode === 'schedule'); // display cut-copy-paste menu only in 'schedule' mode
                    this.showContextPasteRec = false;

                    this.clipboardContent = null;
                } else {
                    // Empty cell
                    this.showContextVisitConfirmed = false;
                    this.showContextClientArrived  = false;
                    this.showContextVisitStarted   = false;
                    this.showContextVisitCompleted = false;
                    this.showContextVisitClosed    = false;
                    this.showContextClientCanceled = false;

                    this.showContextVisitConfirmedCancel = false;
                    this.showContextClientArrivedCancel  = false;
                    this.showContextVisitStartedCancel   = false;
                    this.showContextVisitCompletedCancel = false;
                    this.showContextVisitClosedCancel    = false;
                    this.showContextClientCanceledCancel = false;

                    this.showContextCreateClient    = false;
                    this.showContextOpenClient      = false;
                    this.showContextOpenVisit       = false;
                    this.showContextChangeWorkplace = false;
                    this.showContextCreateSchedule  = true;
                    this.showContextOpenSchedule    = false;

                    this.showContextOpenPayment = false;

                    this.showContextCutRec  = false;
                    this.showContextCopyRec = false;

                    this.clipboardContent = tools.getAppClipboard(this);
                    // console.log(`DEBUG: ${this.$options.name}.show() appClipboardContent=`, appClipboardContent);
                    this.showContextPasteRec = this.clipboardContent && this.clipboardContent.objectName === 'schedule';
                }
                if (
                    this.showContextVisitConfirmed  || this.showContextVisitConfirmedCancel ||
                    this.showContextClientArrived   || this.showContextClientArrivedCancel ||
                    this.showContextVisitStarted    || this.showContextVisitStartedCancel ||
                    this.showContextVisitCompleted  || this.showContextVisitCompletedCancel ||
                    this.showContextVisitClosed     || this.showContextVisitClosedCancel ||
                    this.showContextClientCanceled  || this.showContextClientCanceledCancel ||
                    this.showContextCreateClient    || this.showContextOpenClient ||
                    this.showContextOpenVisit       ||
                    this.showContextChangeWorkplace ||
                    this.showContextCreateSchedule  || this.showContextOpenSchedule ||
                    this.showContextCutRec          || this.showContextCopyRec || this.showContextPasteRec
                ) {
                    // console.log(`DEBUG: ${this.$options.name}.show() open!!!`);
                    // this.$refs.contextMenu.close();
                    this.$refs.contextMenu.open(evt, visit ? { type: 'visit',  visit } : { type: 'slot', slot });
                } else {
                    this.$refs.contextMenu.close();
                }
            },

            async onContextMenu(data) {
                // console.log(`DEBUG: ${this.$options.name}.onContextMenu() evt.target=`, evt.target);
                // console.log(`DEBUG: ${this.$options.name}.onContextMenu() data=`, data);
                this.operation = data.operation;
                if (
                    [
                        this.VISIT_CONFIRMED, this.VISIT_CONFIRMED_CANCEL,
                        this.CLIENT_ARRIVED,  this.CLIENT_ARRIVED_CANCEL,
                        this.VISIT_STARTED,   this.VISIT_STARTED_CANCEL,
                        this.VISIT_COMPLETED, this.VISIT_COMPLETED_CANCEL,
                        this.VISIT_CLOSED,    this.VISIT_CLOSED_CANCEL,
                        this.CLIENT_CANCELED, this.CLIENT_CANCELED_CANCEL
                    ].includes(data.operation)
                ) {
                    try {
                        if (data.operation === this.VISIT_CONFIRMED)        await tools.setVisitStatus(this, data.visit.id, 'confirmed');
                        if (data.operation === this.CLIENT_ARRIVED)         await tools.setVisitStatus(this, data.visit.id, 'arrived');
                        if (data.operation === this.VISIT_STARTED)          { this.finalizeWorkplace(data.visit); return; }
                        if (data.operation === this.VISIT_COMPLETED)        await tools.setVisitStatus(this, data.visit.id, 'completed');
                        if (data.operation === this.VISIT_CLOSED)           await tools.setVisitStatus(this, data.visit.id, 'closed');
                        if (data.operation === this.CLIENT_CANCELED)        await tools.setVisitStatus(this, data.visit.id, 'canceled');

                        if (data.operation === this.VISIT_CONFIRMED_CANCEL) await tools.rollbackVisitStatus(this, data.visit.id, 'confirmed');
                        if (data.operation === this.CLIENT_ARRIVED_CANCEL)  await tools.rollbackVisitStatus(this, data.visit.id, 'arrived');
                        if (data.operation === this.VISIT_STARTED_CANCEL)   await tools.rollbackVisitStatus(this, data.visit.id, 'started');
                        if (data.operation === this.VISIT_COMPLETED_CANCEL) await tools.rollbackVisitStatus(this, data.visit.id, 'completed');
                        if (data.operation === this.VISIT_CLOSED_CANCEL)    await tools.rollbackVisitStatus(this, data.visit.id, 'closed');
                        if (data.operation === this.CLIENT_CANCELED_CANCEL) await tools.rollbackVisitStatus(this, data.visit.id, 'canceled');
                        await this.setLastVisitId(data.visit.id); // need to VisitTabs enable tabs on status changed
                        tools.displaySuccessSave(this);
                        this.$emit('refresh-status');
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                }
                if (data.operation === this.OPEN_CLIENT) {
                    await this.setLastClientId(data.visit.core_client_id);
                    this.$router.push({ name: 'client-edit', params: { id: data.visit.core_client_id }});
                }
                if (data.operation === this.OPEN_VISIT_REC) {
                    await this.setLastClientId(data.visit.core_client_id);
                    await this.setLastVisitId(data.visit.id);
                    this.$router.push({ name: 'client-visits', params: { clientId: data.visit.core_client_id }});
                }
                if (data.operation === this.EDIT_FINAL_WORKPLACE) {
                    await this.setLastClientId(data.visit.core_client_id);
                    await this.setLastVisitId(data.visit.id);
                    this.finalizeWorkplace(data.visit);
                }
                if (data.operation === this.CREATE_CLIENT) {
                    // console.log(`DEBUG: ${this.$options.name}.onContextMenu(${data.operation}) data.visit=`, data.visit);
                    await this.clearLastClient();
                    this.$router.push({ name: 'client-new', params: {
                        lastname: data.visit.lastname,
                        firstname: data.visit.firstname,
                        patronymic: data.visit.patronymic,
                        phoneNum: data.visit.phone_num,
                        visitId: data.visit.id,
                    }});
                }
                if (data.operation === this.OPEN_SCHEDULE_REC) {
                    this.$router.push({ name: 'schedule-edit', params: { id: data.visit.id }});
                }
                if (data.operation === this.CREATE_SCHEDULE_REC) {
                    // console.log(`DEBUG: ${this.$options.name}.onContextMenu(CREATE_SCHEDULE_REC), data=`, data);
                    if (data.slot) {
                        this.$router.push({ name: 'schedule-new', params: { coreWorkplaceId: data.slot.coreWorkplaceId, timeOf: data.slot.timeOf } });
                    }
                }
                if (data.operation === this.OPEN_PAYMENT) {
                    const filterString = tools.getFilterString({ where:[
                        {payer_org_id: null},
                        {payer_client_id: data.visit.core_client_id},
                        {fin_contract_id: null},
                    ]});
                    let account = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_account${filterString}`)).data.rows[0];
                    // console.log(`DEBUG: ${this.$options.name}.onContextMenu(OPEN_PAYMENT), account=`, account);
                    this.$router.push({ name: 'cashdesk/account', params: { accountId: account.id }});
                }
                if (data.operation === this.CUT_VISIT) {
                    // console.log(`DEBUG: ${this.$options.name}.onContextMenu(CUT_VISIT), data.visit=`, data.visit);
                    tools.setAppClipboard(this, 'cut', 'schedule', tools.getVisitFromExtended(data.visit));
                }
                if (data.operation === this.COPY_VISIT) {
                    // console.log(`DEBUG: ${this.$options.name}.onContextMenu(CUT_VISIT), data.visit=`, data.visit);
                    tools.setAppClipboard(this, 'copy', 'schedule', tools.getVisitFromExtended(data.visit));
                }
                if (data.operation === this.PASTE_VISIT) {
                    // console.log(`DEBUG: ${this.$options.name}.onContextMenu(PASTE_VISIT), data.slot=`, data.slot);
                    if (data.slot && this.clipboardContent && this.clipboardContent.objectName === 'schedule') {
                        this.$router.push({ name: 'schedule-new', params: {
                            clipboard: this.clipboardContent,
                            coreWorkplaceId: data.slot.coreWorkplaceId,
                            timeOf: data.slot.timeOf
                        }});
                    }
                }
            },
        },

        // =============== Life cycle ===============
        // async mounted() {
        // }
    }
</script>

<style lang="scss" src="@/assets/context-menu.scss"></style>
<style lang="scss">
    .v-context {
        .default-action          { font-weight: bold; }

        // .visit-client-confirmed { color: #a5a56c; }
        .visit-client-arrived   { color: #0000ff; }
        .visit-started           { color: #0000ff; }
        .visit-completed         { color: #007b00; }
        .visit-closed            { color: #007b00; }
        .visit-canceled          { color: #ff0000; }
        .visit-status-cancel     { color: #ff0000; }

        .visit-create-client     { color: #00aa00; }

        .visit-cut               { color: #cc0000; }
        .visit-copy              { color: #0000ff; }
        .visit-paste             { color: #0000ff; }
    }
</style>
