<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-15
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonPrint v-if="buttons.includes('print')" @click="printItem()" />
            <!-- <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" /> -->
            <ButtonCustom
                v-if="buttons.includes('commit')"
                buttonClass="btn-warning btn-sm ml-1"
                iconClass="fas fa-check-square fa-fw"
                :text="$t('component.MedDischarge.commit')"
                :disabled="!isReadyToCommit"
                @click="CommitDocument()"
            />
            <ButtonCustom
                v-if="buttons.includes('rollback')"
                buttonClass="btn-outline-danger btn-sm ml-1"
                iconClass="fas fa-square fa-fw"
                :text="$t('component.MedDischarge.rollback')"
                @click="isDisplayWarningRollback = true"
            />
        </template>

        <VisitDescription />

        <VisitTabs currentTab="visit-discharge" />

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <!-- <div class="col-sm-12 col-xl-8"> -->
                    <div class="col">
                        <div class="form-row">
                            <div class="col-sm col-lg-3">
                                <FieldDatetime
                                    :label="$t('attribute.med_hospitalization.datetime_admission')"
                                    :value.sync="hospitalizationFormData.datetime_admission"
                                    disabled
                                    required
                                />
                            </div>
                            <div class="col-sm col-lg-3">
                                <FieldDatetime
                                    :label="$t('attribute.med_discharge.datetime_discharge')"
                                    :value.sync="editFormData.datetime_discharge"
                                    :disabled="editFormData.is_commited"
                                    required
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="col-sm col-lg-3">
                                <FieldNumber
                                    :label="$t('component.MedDischarge.daysBetween')"
                                    :value="daysBetween(hospitalizationFormData.datetime_admission, editFormData.datetime_discharge)"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm col-lg-6">
                                <FieldSelect
                                    :label="$t('attribute.med_discharge.hosp_result_id')"
                                    :value.sync="editFormData.hosp_result_id"
                                    source='enum/hosp_result'
                                    order="sort_order"
                                    :disabled="editFormData.is_commited"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="col-sm col-lg-6">
                                <FieldSelect
                                    :label="$t('attribute.med_discharge.clinical_outcome_id')"
                                    :value.sync="editFormData.clinical_outcome_id"
                                    source='enum/clinical_outcome'
                                    order="sort_order"
                                    :disabled="editFormData.is_commited"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="col-sm col-lg-6">
                                <FieldSelect
                                    :label="$t('attribute.med_discharge.hosp_outcome_id')"
                                    :value.sync="editFormData.hosp_outcome_id"
                                    source='enum/hosp_outcome'
                                    order="sort_order"
                                    :disabled="editFormData.is_commited"
                                    @modified="itemModified()"
                                />
                            </div>
                            <div class="col-sm col-lg-6">
                                <FieldSelect
                                    :label="$t('attribute.med_discharge.workability_status_id')"
                                    :value.sync="editFormData.workability_status_id"
                                    source='enum/workability_status'
                                    order="sort_order"
                                    :disabled="editFormData.is_commited"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md">
                                <FieldText
                                    :label="$t('attribute.common.commentary')"
                                    :value.sync="editFormData.commentary"
                                    :disabled="editFormData.is_commited"
                                    @modified="itemModified()"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-12 col-xl-4"> -->
                        <!-- Right sidebar -->
                    <!-- </div> -->
                </div>
            </div>
        </form>

        <ModalWindow
            v-if="isDisplayWarningRollback"
            minWidth="300px"
            :buttons="['yes', 'no']"
            @yes="processRollback"
            @no="isDisplayWarningRollback = false"
        >
            {{ $t('app.confirmRollback') }}
        </ModalWindow>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import moment from 'moment';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonPrint from '@/components/lib/controls/ButtonPrint';
    import ButtonCustom from '@/components/lib/controls/ButtonCustom';

    import FieldDatetime from '@/components/lib/controls/FieldDatetime';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldText from '@/components/lib/controls/FieldText';

    export default {
        name: 'MedDischarge',

        components: {
            TemplatePageStandard, ModalWindow, VisitDescription, VisitTabs,
            ButtonClose, ButtonSave, ButtonPrint, ButtonCustom,
            FieldDatetime, FieldNumber, FieldSelect, FieldText,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                // visitRec: null,
                currentFormItem: null,
                currentAttribute: null,
                // isFormEditable: true,
                isDisplayWarningRollback: false,
                isDischargeModified: false,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.discharge') }),
                editFormData: {
                    // id: null,
                    visit_rec_id: null,
                    hosp_result_id: null,
                    clinical_outcome_id: null,
                    hosp_outcome_id: null,
                    workability_status_id: null,
                    datetime_discharge: null,
                    commentary: null,
                },
                oldFormData: {},
                hospitalizationFormData: {
                    // id: null,
                    visit_rec_id: null,
                    referring_physician_id: null,
                    hospital_physician_id: null,
                    referring_subject: null,
                    datetime_admission: null,
                    datetime_hospitalization: null,
                    date_discharge_plan: null,
                    is_urgent: false,
                    is_denied: false,
                    is_commited: false,
                    transportation_type: null,
                    hmr_num: null,
                    deny_reason: null,
                    referring_diagnosis: null,
                    special_notes: null,
                },
                // hospitalizationOldFormData: {},
                // isDisplayWarningDelete: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            isReadyToCommit() { return this.editFormData.datetime_discharge },
        },

        // =============== Methods ===============
        methods: {
            daysBetween(d1, d2) {
                // console.log(`DEBUG: ${this.$options.name}.daysBetween('${d1}', '${d2}'), tmoment(d2).diff(moment(d1), 'days')=`, moment(d2).diff(moment(d1), 'days'));
                return moment(d2).diff(moment(d1), 'days') + 1;
            },
            checkButtons() {
                this.buttons = ['save', 'close'];
                if (this.itemId) {
                    // console.log(`DEBUG: ${this.$options.name}.checkButtons(), this.editFormData=`, this.editFormData);
                    if (this.editFormData.is_commited) {
                        this.buttons.push('rollback');
                    } else {
                        this.buttons.push('commit');
                    }
                }
            },

            setBreadcrumb() {
            },

            gotDischargeData() {
                // this.editFormData = data;
                this.isDischargeModified = false;
                this.msgItemModified = '';
                this.itemId = this.editFormData.id;
                this.oldFormData = tools.deepCopy(this.editFormData);

                this.itemModified();
                this.setBreadcrumb();
                this.checkButtons();
            },

            async getData() {
                try {
                    this.visitRec = (await this.authenticatedAxios.get(`${this.baseUrl}/visit/${this.visitId}`)).data;
                    const nowString = new Date().toISOString();
                    await this.getDischargeData(nowString);
                    await this.getHospitalizationData(nowString);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getDischargeData(nowString) {
                try {
                    let filterString = tools.getFilterString({ where:[{ visit_rec_id: this.visitId }]});
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/med_discharge${filterString}`)).data.rows[0];
                    if (data) {
                        this.editFormData = data;
                        this.gotDischargeData();
                    } else {
                        this.editFormData.datetime_discharge = nowString;
                        this.itemModified();
                        this.setBreadcrumb();
                        this.checkButtons();
                    }
                    // console.log(`DEBUG: ${this.$options.name}.getDischargeData data=`, data);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getHospitalizationData(nowString) {
                try {
                    const currentVisitRec = (await this.authenticatedAxios.get(`${this.baseUrl}/v_visit/${this.visitId}`)).data;
                    const currentEvent = (await this.authenticatedAxios.get(`${this.baseUrl}/v_event/${currentVisitRec.visit_event_id}`)).data;
                    let filterString = tools.getFilterString({ where:[
                        { visit_rec_id: currentEvent.visit_open_id }
                    ]});
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/med_hospitalization${filterString}`)).data.rows[0];
                    // console.log(`DEBUG: ${this.$options.name}.getHospitalizationData data=`, data);
                    if (data) {
                        this.hospitalizationFormData = data;
                    }
                    // console.log(`DEBUG: ${this.$options.name}.getHospitalizationData data=`, data);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isModified=${this.isModified}`);
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isDischargeModified=${this.isDischargeModified}`);
                if (this.isModified) {
                    await this.saveDischargeData();
                    tools.displaySuccessSave(this);
                } else {
                    // no changes
                }
            },

            async saveDischargeData() {
                // console.log(`DEBUG: ${this.$options.name}.saveDischargeData() this.isDischargeModified=${this.isDischargeModified}`);
                if (this.isDischargeModified) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            // console.log(`DEBUG: ${this.$options.name}.saveDischargeData() Update this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.put(`${this.baseUrl}/med_discharge/${this.editFormData.id}`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                this.gotDischargeData();
                            }
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveDischargeData() New this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.post(`${this.baseUrl}/med_discharge`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                // console.log(`DEBUG: ${this.$options.name}.saveDischargeData() this.editFormData=`, this.editFormData);
                                this.gotDischargeData();
                            }
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
            },

            itemFocused(item, templateName) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() item=${item}`);
                this.currentFormItem = item;
                this.currentAttribute = templateName ? templateName : item;
                // this.currentFormItemValue = this.editFormData[this.currentFormItem.split('.')[1]];
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() this.currentFormItemValue=${this.currentFormItemValue}`);
            },

            itemModified() {
                this.isDischargeModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                this.isModified = this.isDischargeModified;
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.isModified=`, this.isModified);
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.oldFormData=`, JSON.stringify(this.oldFormData));
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.editFormData=`, JSON.stringify(this.editFormData));
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
                this.setBreadcrumb();
            },

            async CommitDocument() {
                await this.saveData();
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/med-discharge-commit/${this.itemId}`);
                    tools.displaySuccessSave(this);
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async processRollback() {
                this.isDisplayWarningRollback = false;
                await this.saveData();
                try {
                    await this.authenticatedAxios.post(`${this.baseUrl}/med-discharge-rollback/${this.itemId}`);
                    tools.displaySuccessSave(this);
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async printItem() {
                this.saveData();
                tools.printForm(
                    this,
                    {
                        // taskId: `print.${this.$options.name}`,
                        template: 'med-discharge',
                        consultation: JSON.parse(JSON.stringify(this.editFormData)),
                        visit: { visit_rec_id: this.visitId },
                    }
                );
                // console.log(`DEBUG: ${this.$options.name}.printItem() printData=`, printData);
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
            this.editFormData.visit_rec_id = this.visitId;
        },

        async mounted() {
            if (this.visitId) {
                await this.getData();
            } else {
                this.setBreadcrumb();
                this.checkButtons();
            }
            this.oldFormData = tools.deepCopy(this.editFormData);
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.clientId=${this.clientId}, this.visitId=${this.visitId}`);
        },
    }
</script>

<style>
</style>
