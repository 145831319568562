<!--
    Author: Eduard Grebenyukov
    Date: 2020-02-26
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <!-- <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" /> -->
        </template>
        <div id="page-content" class="form-float">
            <div v-if="displayGrids" class="row">
                <div class="col-sm">
                    <Grid ref="workplaceGrid" :config="workplaceGridConfig" @modified="workplaceGridModified" />
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { mapState, mapActions } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import Grid from '@/components/lib/regions/Grid';

    const componentUrl = 'visit_group';
    const workplacesUrl = 'visit_group_det';

    export default {
        name: 'VisitGroupWorkplaces',

        components: {
            TemplatePageStandard, ButtonClose, ButtonSave, Grid,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                isWorkplaceGridModified: false,
                editFormData: {},
                oldFormData: {},
                isDisplayWarningDelete: false,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-visits-groups'), to: '/settings/visits/groups' },
                    { text: 'visit-group-workplaces' },
                ],
                workplaceGridConfig: {
                    apiUrl: workplacesUrl,
                    filter: null,
                    title: this.$t('component.UserEdit.workplaces'),
                    columns: [
                        { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'visit_group_id', header:'Visit group', required: true },
                        // { type:'select', key:'core_workplace_id', header:this.$t('object.core_workplace'), required:true, source:'v_workplace', keyLabel:'workplace_name' },
                        {
                            type:'popuplist', key:'core_workplace_id', header:this.$t('object.core_workplace'), required:true,
                            source:'v_workplace',
                            keyLabel:'workplace_name',
                            listConfig: {
                                filter:{ where:[{ is_schedule:true }]},
                                readonly:true,
                                columns: [
                                    // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'50px' },
                                    // { type:'string', key:'name', header:this.$t('attribute.name') },
                                    { type:'string', key:'building_name', header:this.$t('object.core_building') },
                                    { type:'string', key:'dept_name', header:this.$t('object.core_dept') },
                                    { type:'string', key:'speciality_name', header:this.$t('object.core_speciality') },
                                    { type:'string', key:'user_name', header:this.$t('app.employee') },
                                    { type:'string', key:'room_name', header:this.$t('object.core_room') },
                                    // { type:'checkbox', key:'is_schedule', header:this.$t('attribute.core_workplace.is_schedule') },
                                ],
                                order: [ 'building_name', 'dept_name', 'speciality_name', 'user_name' ],
                            },
                        },
                        { type:'number', key:'duration', header:this.$t('Settings.interval') },
                        { type:'number', key:'sort_order', header:this.$t('attribute.common.sort_order') },
                    ],
                    buttons: [ 'row-selector', 'add', 'delete' ],
                    order: [ 'sort_order' ],
                    saveSessionState: `${this.$options.name}`,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            apiUrl() { return `${this.baseUrl}/${componentUrl}` },
            // displayGrids() { return itemId },
            displayGrids() { return typeof this.editFormData.id !='undefined' },
        },

        // =============== Validations ===============
        // validations: {
        //     editFormData: {
        //         username: { required },
        //         email: { required }
        //     }
        // },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            setGridFilter() {
                this.workplaceGridConfig.filter = { where:[
                    { visit_group_id:this.itemId },
                ]};
                this.workplaceGridConfig.columns.find(x => x.key === 'visit_group_id').defaultValue = this.itemId;
                // !!! why its works without .refresh()???
            },

            async getData() {
                try {
                    let response = await this.authenticatedAxios.get(`${this.apiUrl}/${this.itemId}`);
                    this.editFormData = response.data;
                    this.isModified = false;
                    this.msgItemModified = '';
                    this.breadcrumb[this.breadcrumb.length - 1].text = response.data.name;
                    this.breadcrumb[this.breadcrumb.length - 1].to = `settings/visits/visit-group-workplaces/${this.itemId}`;
                    this.itemId = response.data.id;
                    this.checkButtons();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                if (this.isModified) {
                    //this.$v.$touch();
                    //if (!this.$v.$invalid) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update user
                            await this.authenticatedAxios.put(`${this.apiUrl}/${this.editFormData.id}`, this.editFormData);
                            tools.displaySuccessSave(this);
                            this.isModified = false;
                        } else {
                            // New user created
                            let response = await this.authenticatedAxios.post(this.apiUrl, this.editFormData);
                            tools.displaySuccessSave(this);
                            this.isModified = false;
                            this.itemId = response.data.id;
                            this.editFormData.id = this.itemId;
                            this.setGridFilter();
                            this.getData();
                        }
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    // no changes
                }
                
                if (this.isWorkplaceGridModified) {
                    this.$refs.workplaceGrid.$emit('save');
                    this.isWorkplaceGridModified = false;
                }
                // this.$emit('close-modal', true);
            },
            itemModified() {
                this.isModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                if (
                    this.isModified ||
                    this.isWorkplaceGridModified
                ) {
                    this.msgItemModified = this.$t('component.UserEdit.itemModified');
                } else {
                    this.msgItemModified = '';
                }
            },

            askDeleteItem() {
                this.isDisplayWarningDelete = true;
            },
            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.apiUrl}/${this.itemId}`);
                        this.isModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },

            // Grids
            workplaceGridModified() {
                this.isWorkplaceGridModified = true;
            },

        },

        // =============== Life cycle ===============
        beforeMount() {
            this.itemId = parseInt(this.$route.params.id);
        },

        async mounted() {
            if (this.itemId) {
                await this.getData();
                this.setGridFilter();
            }
            this.checkButtons();
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
        },
    }
</script>

<style>

</style>
