<!--
    Author: Eduard Grebenyukov
    Date: 2020-12-13
-->

<template>
    <button :class="`btn btn-link ${buttonClass || ''}`" :title="title" @click.prevent="toggleState()">
        <i class="fas fa-bars"></i>
    </button>
</template>

<script>
    import { mapGetters } from 'vuex';
    import tools from '@/components/lib/tools';

    const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'ButtonSwitch',

        // =============== Props ===============
        props: {
            id: String,
            value: Boolean,
            defaultValue: Boolean,
            buttonClass: String,
            disabled: Boolean,
            saveState: String,
            saveSessionState: String,
            title: String,
        },

        // =============== Data ===============
        data() {
            return {
                itemData: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
        },

        // =============== Watch ===============
        watch: {
            value() {
                this.itemData = this.value;
                // console.log(`DEBUG: ${this.$options.name}.watch.value this.value=`, this.value);
            },
        },

        // =============== Methods ===============
        methods: {
            toggleState() {
                this.itemData = !this.itemData;
                this.itemModified();
            },
            itemModified() {
                // console.log(`DEBUG: ${this.$options.name}.itemModified() checked=`, e.target.checked);
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.itemData=`, this.itemData);
                if (this.saveState) {
                    // Save current value
                    this.authenticatedAxios.post(`${this.baseUrl}/${userSettingsUrl}`, { code: this.saveState, value: (this.itemData ? 'true' : 'false') });
                }
                if (this.saveSessionState) {
                    sessionStorage.setItem(this.saveSessionState, (this.itemData ? 'true' : 'false'));
                }
                // this.$emit('update:value', e.target.checked);
                this.$emit('update:value', this.itemData);
                this.$emit('modified');
            }
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemData = this.value;
            // await console.log(`DEBUG: ${this.$options.name}.beforeMount() this.itemData=`, this.itemData);
            if (tools.isBooleanEmpty(this.itemData) && this.saveState) {
                // Restore stored value
                let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() response.data=`, response.data);
                let item = response.data.rows[0];
                if (item) this.itemData = (item.value === 'true');
                this.itemModified();
            }
            // await console.log(`DEBUG: ${this.$options.name}.beforeMount() 2 this.itemData=`, this.itemData);
            if (tools.isBooleanEmpty(this.itemData) && this.saveSessionState) {
                let sessionData = JSON.parse(sessionStorage.getItem(this.saveSessionState));
                if (sessionData) this.itemData = (sessionData === 'true');
                this.itemModified();
            }
            // await console.log(`DEBUG: ${this.$options.name}.beforeMount() 3 this.itemData=`, this.itemData);
            if (tools.isBooleanEmpty(this.itemData) && !tools.isBooleanEmpty(this.defaultValue)) {
                this.itemData = this.defaultValue;
                this.itemModified();
            }
            // await console.log(`DEBUG: ${this.$options.name}.beforeMount() 4 this.itemData=`, this.itemData);
        },
    }
</script>

<style scoped>
</style>
