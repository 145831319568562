/*
    Author:Eduard Grebenyukov
    Date:2021-09-12
*/

import { i18n } from '@/locales';

import { pageConfig } from '../store/pageConfig';
import { pageSubmenu } from '../store/pageSubmenu';

const SubmenuPage = () => import('@/components/lib/pages/SubmenuPage');
const GridEdit = () => import('@/components/pages/common/GridEdit');

const ContractEdit = () => import('@/components/pages/finances/ContractEdit');
const ProgramEdit = () => import('@/components/pages/finances/ProgramEdit');
const Accounts = () => import('@/components/pages/finances/Accounts');
const AccountFin = () => import('@/components/pages/finances/AccountFin');
const ReversalEdit = () => import('@/components/pages/finances/ReversalEdit');
const Services = () => import('@/components/pages/finances/Services');
const SetpriceEdit = () => import('@/components/pages/finances/SetpriceEdit');

const Transactions = () => import('@/components/pages/cashdesk/Transactions');
const TransactionEdit = () => import('@/components/pages/cashdesk/TransactionEdit');

const routes = [
    { name:'finances',                  path:'/finances',                                         component:SubmenuPage,     props:{ config:pageSubmenu.submenu_finances }, meta:{title:i18n.t('MainMenu.finances')} },
    { name:'finances/contracts',        path:'/finances/contracts',                               component:GridEdit,        props:{ config:pageConfig.fin_contract },      meta:{title:i18n.t('MainMenu.finances-contracts')} },
    { name:'finances/contract-new',     path:'/finances/contract',                                component:ContractEdit,                                                   meta:{title:i18n.t('object.fin_contract')} },
    { name:'finances/contract-edit',    path:'/finances/contract/:contractId',                    component:ContractEdit,                                                   meta:{title:i18n.t('object.fin_contract')} },
    { name:'finances/program-new',      path:'/finances/contract/:contractId/program',            component:ProgramEdit,                                                    meta:{title:i18n.t('object.fin_program')} },
    { name:'finances/program-edit',     path:'/finances/contract/:contractId/program/:programId', component:ProgramEdit,                                                    meta:{title:i18n.t('object.fin_program')} },
    { name:'finances/accounts',         path:'/finances/accounts',                                component:Accounts,                                                       meta:{title:i18n.t('MainMenu.finances-accounts')} },
    { name:'finances/account',          path:'/finances/account/:accountId',                      component:AccountFin,                                                     meta:{title:i18n.t('object.fin_account')} },
    { name:'finances/transactions',     path:'/finances/account/:accountId/transactions',         component:Transactions,                                                   meta:{title:i18n.t('objects.fin_cash')} },
    { name:'finances/transaction-edit', path:'/finances/account/:accountId/transaction/:cashId',  component:TransactionEdit,                                                meta:{title:i18n.t('object.fin_cash')} },
    { name:'finances/bills',            path:'/finances/bills',                                   component:GridEdit,        props:{ config:pageConfig.fin_bill },          meta:{title:i18n.t('MainMenu.finances-bills')} },
    { name:'finances/reversals',        path:'/finances/reversals',                               component:GridEdit,        props:{ config:pageConfig.fin_reversal },      meta:{title:i18n.t('MainMenu.finances-reversals')} },
    { name:'finances/reversal-new',     path:'/finances/reversal',                                component:ReversalEdit,                                                   meta:{title:i18n.t('object.fin_reversal')} },
    { name:'finances/reversal-edit',    path:'/finances/reversal/:reversalId',                    component:ReversalEdit,                                                   meta:{title:i18n.t('object.fin_reversal')} },
    { name:'finances/services',         path:'/finances/services',                                component:Services,                                                       meta:{title:i18n.t('MainMenu.finances-services')} },
    { name:'finances/setprices',        path:'/finances/setprices',                               component:GridEdit,        props:{ config:pageConfig.fin_setprice },      meta:{title:i18n.t('MainMenu.finances-setprices')} },
    { name:'finances/setprice-new',     path:'/finances/setprice',                                component:SetpriceEdit,                                                   meta:{title:i18n.t('MainMenu.finances-setprices')} },
    { name:'finances/setprice-edit',    path:'/finances/setprice/:setpriceId',                    component:SetpriceEdit,                                                   meta:{title:i18n.t('MainMenu.finances-setprices')} },
]

export default routes
