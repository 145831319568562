/*
    Author: Eduard Grebenyukov
    Date: 2020-07-02
*/

import { i18n } from '@/locales';
// import { baseUrl } from './constants';

const pageConfigMed = {
    med_referral_type: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            // { text: i18n.t('MainMenu.settings-med') },
            { text: i18n.t('MainMenu.settings-med-referral-types') }
        ],
        gridConfig: {
            apiUrl: 'med_referral_type',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'string', key:'name', header:i18n.t('attribute.common.name') },
                { type:'select', key:'fin_service_id', header:i18n.t('object.fin_service'), required:true, source:'fin_service' },
                // { type:'select', key:'referral_type_id', header:i18n.t('enum.referral_type'), required:true, source:'enum/referral_type' },
                { type:'number', key:'term', header:i18n.t('attribute.med_referral_type.term') },
                { type:'checkbox', key:'is_sample_required', header:i18n.t('attribute.med_referral_type.is_sample_required'), defaultValue:false, width:'50px', align:'center;' },
                { type:'checkbox', key:'is_permit_cito', header:i18n.t('attribute.med_referral_type.is_permit_cito'), defaultValue:false, width:'50px', align:'center;' },
                { type:'select', key:'visit_route_id', header:i18n.t('object.visit_route'), source:'visit_route' },
            ],
            buttons: [
                'row-selector', 'add', 'save', 'delete',
                {
                    key:'button-fill', class:'btn btn-outline-warning btn-sm', iconClass:'far fa-window-restore', text:i18n.t('component.med_referral_type.fill_from_services'),
                    $onClick:async (entry, evt, ctx) => {
                        // console.log(`clicked, `, ctx);
                        await ctx.authenticatedAxios.post(`${ctx.baseUrl}/med-fill-referral-types`);
                        await ctx.refresh();
                    }
                },
            ],
            order: [ 'fin_service_id' ],
            displayToolbar: true,
            saveSessionState: 'med_referral_type',
        }
    },
    visit_template: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            // { text: i18n.t('MainMenu.settings-med') },
            { text: i18n.t('MainMenu.settings-med-templates') }
        ],
        gridConfig: {
            apiUrl: 'visit_template',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right' },
                { type:'number', key:'parent_id', header:i18n.t('attribute.common.parent_id'), align:'right' },
                { type:'select', key:'visit_type_id', header:i18n.t('object.visit_type'), required:true, source:'visit_type' },
                // { type:'select', key:'core_speciality_id', header:i18n.t('object.core_speciality'), required:true, source:'core_speciality' },
                { type:'select', key:'core_user_id', header:i18n.t('object.core_user'), required:true, source:'v_user', keyLabel:'shortname' },
                { type:'string', key:'attribute_name', header:i18n.t('attribute.common.code'), required:true },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'number', key:'sort_order', header:i18n.t('attribute.common.sort_order'), align:'right' },
                { type:'string', key:'template_text', header:i18n.t('attribute.common.text') },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'attribute_name' ],
            displayToolbar: true,
            saveSessionState: 'visit_template',
        }
    },
    visit_event_type: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            // { text: i18n.t('MainMenu.settings-med') },
            { text: i18n.t('MainMenu.settings-med-event-types') }
        ],
        gridConfig: {
            apiUrl: 'visit_event_type',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, width:'50px', align:'right' },
                { type:'string', key:'code', header:i18n.t('attribute.common.name'), required:true },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'checkbox', key:'is_inner', header:i18n.t('attribute.visit_event_type.is_inner'), defaultValue:false, width:'50px', align:'center;' },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'name' ],
            displayToolbar: true,
            saveSessionState: 'visit_event_type',
        }
    },
    med_bed: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            // { text: i18n.t('MainMenu.settings-med') },
            { text: i18n.t('MainMenu.settings-med-beds') }
        ],
        gridConfig: {
            apiUrl: 'med_bed',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, width:'50px', align:'right' },
                { type:'select', key:'core_room_id', header:i18n.t('object.core_room'), required:true, source:'core_room' },
                { type:'select', key:'bed_type_id', header:i18n.t('enum.bed_type'), required:true, source:'enum/bed_type' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'checkbox', key:'is_archived', header:i18n.t('attribute.common.is_archived'), defaultValue:false, width:'50px', align:'center;' },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'name' ],
            displayToolbar: true,
            saveSessionState: 'med_bed',
        }
    },
}

export { pageConfigMed };
