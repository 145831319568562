<!--
    Author: Eduard Grebenyukov
    Date: 2021-05-24
-->

<template>
    <ModalWindow
        width="90%"
        max-width="600px"
        :buttons="[ 'close' ]"
        @cancel="closeModal"
    >
        <span slot="header">{{ $t('component.UserSessionInfo.title') }}</span>

        <!-- <h6>{{ $t('component.UserSessionInfo.session') }}</h6> -->

        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <FieldNumber
                        :label="$t('component.UserSessionInfo.session_id')"
                        :value="currentUser.session.id"
                        disabled
                    />
                </div>
                <div class="col-sm-12 col-md-4">
                    <FieldDatetime
                        :label="$t('attribute.common.time_start')"
                        :value="currentUser.session.time_start"
                        disabled
                    />
                </div>
                <div class="col-sm-12 col-md-4">
                    <FieldDatetime
                        :label="$t('attribute.common.time_end')"
                        :value="currentUser.session.time_end"
                        disabled
                    />
                </div>
                <div class="col-sm-12 col-md-4">
                    <FieldString
                        :label="$t('component.UserSessionInfo.timezone')"
                        :value="timezoneStr"
                        disabled
                    />
                </div>
                <div class="col-sm-12 col-md-4">
                    <FieldString
                        :label="$t('component.UserSessionInfo.ipAddress')"
                        :value="ipAddress"
                        disabled
                    />
                </div>
            </div>
        </div>

        <!-- <h6>{{ $t('component.UserSessionInfo.timezone') }}: GMT{{ timezone > 0 ? '+' : '' }}{{ timezone }}</h6> -->

    </ModalWindow>
</template>

<script>
    import { mapGetters } from 'vuex';

    import ModalWindow from '@/components/lib/regions/ModalWindow';
    import FieldString from '@/components/lib/controls/FieldString';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldDatetime from '@/components/lib/controls/FieldDatetime';

    export default {
        name: 'UserSessionInfo',

        components: {
            ModalWindow, FieldString, FieldNumber, FieldDatetime,
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                currentUser: 'currentUser',
                ipAddress: 'ipAddress',
            }),
            timezone() { return -( new Date()).getTimezoneOffset() / 60 },
            timezoneStr() { return `GMT${ this.timezone > 0 ? '+' : '' }${ this.timezone }` },
        },

        // =============== Methods ===============
        methods: {
            closeModal() {
                this.processClose();
            },
            processClose() { this.$emit('close-modal', false) },
        },
    }
</script>

<style>
</style>
