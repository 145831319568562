<!--
    Author: Eduard Grebenyukov
    Date: 2020-01-07
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
    >
        <div id="page-content">
            <Grid :key="$route.fullPath" :config="gridConfig" @modified="gridModified($event)" />
        </div>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import $ from 'jquery';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'MatSuppliers',

        components: {
            TemplatePageStandard, Grid,
        },

        // =============== Data ===============
        data() {
            return {
                isModified: false,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.store') },
                    { text: this.$t('MainMenu.store-suppliers') },
                ],
                gridConfig: {
                    apiUrl: null, //'core_org'
                    filter: null,
                    columns: [
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                        { type:'hidden', key:'org_type_id', header:this.$t('Settings.org_type'), required:true, source:'enum/org_type', keyLabel:'name' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name'), required:true },
                        { type:'string', key:'name_short', header:this.$t('attribute.common.name_short') },
                        { type:'string', key:'name_full', header:this.$t('attribute.common.name_full') },
                        { type:'string', key:'tin', header:this.$t('attribute.core_org.tin') },
                        { type:'string', key:'trrc', header:this.$t('attribute.core_org.trrc') },
                        { type:'string', key:'commentary', header:this.$t('attribute.common.commentary') },
                    ],
                    order: [ 'name' ],
                    displayToolbar: true,
                    saveSessionState: `${this.$options.name}`,
                    buttons: [ 'row-selector', 'add', 'save', 'delete' ],
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Watch ===============
        // watch: {
        //     isModified() {
        //         if (this.isModified) {
        //             $('#breadcrumb').addClass('modified');
        //         } else {
        //             $('#breadcrumb').removeClass('modified');
        //         }
        //     }
        // },

        // =============== Methods ===============
        methods: {
            gridModified(e) {
                this.isModified = e;
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            try {
                let orgTypeId = await tools.getEnumId(this, 'org_type', 'supplier');
                this.gridConfig.apiUrl = 'core_org';
                this.gridConfig.filter = { where:[{ org_type_id:orgTypeId }]};
                this.gridConfig.columns.find(x => x.key === 'org_type_id').defaultValue = orgTypeId;
            } catch (err) {
                tools.displayApiError(this, err);
            }
        },
    }
</script>

<style>
</style>
