<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-11
-->

<template>
    <footer id="page-footer" class="fixed-bottom navbar navbar-light justify-content-between">
        <!-- <small class="build">Build <br class="xs-only"/>{{ build }}</small> -->
        <small :class="{ 'build':true, 'outdated':isBuildOutdated }">Build <br class="xs-only"/>{{ build }}</small>
        <small v-if="displayLogout" class="copy">Copyright &copy; 2018-2022 Healtis. <br class="xs-only"/>All rights reserved.</small>
        <!-- <div v-if="displayLogout" class="nav" > -->
            <!-- <small class="nav-item"><router-link class="nav-link text-info" :to="'/contact'">Contact<span class="d-none d-sm-inline"> us</span></router-link></small>
            <small class="nav-item"><router-link class="nav-link text-info" :to="'/legal/terms'">Terms<span class="d-none d-sm-inline"> of Use</span></router-link></small>
            <small class="nav-item"><router-link class="nav-link text-info" :to="'/legal/privacy'">Privacy<span class="d-none d-sm-inline"> policy</span></router-link></small> -->

            <!-- <small class="nav-item">
                <router-link id="logout" class="nav-link" :to="'/logout'">
                    {{ $t('PageFooter.logout') }}
                </router-link>
            </small>
        </div> -->
    </footer>
</template>

<script>
    import tools from '@/components/lib/tools';

    export default {
        name: 'PageFooter',

        // =============== Props ===============
        props: {
            displayLogout: Boolean,
        },

        // =============== Data ===============
        data() {
            return {
                isBuildOutdated: false,
            }
        },

        // =============== Computed ===============
        computed: {
            build() { return tools.getFrontendBuild(this) },
        },
    }
</script>

<style lang="scss">
    #page-footer {
        height: var(--page-footer-height);

        background-color: #e9ecef;
        padding: 0 1rem;
    }

    #page-footer .xs-only {
        display: none;
    }

    .build.outdated {
        color: red;
        font-weight: bold;
    }
</style>
