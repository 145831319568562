<!--
    Author: Eduard Grebenyukov
    Date: 2020-05-30
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col">
                    <Grid
                        ref="files"
                        :key="$route.fullPath"
                        :config="gridConfig"
                        @edit-item="editFile($event)"
                        @download="downloadFile($event)"
                        @view="viewFile($event)"
                    />
                </div>
            </div>
        </div>

        <!-- <iframe :src="fileContent"></iframe> -->

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ClientFiles',

        components: {
            TemplatePageStandard, Grid,
        },

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                isDisplayVisitAdd: false,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.clients'), to: '/clients' },
                    { text: this.$t('object.core_client'), to: `/client/${this.clientId}` },
                    { text: this.$t('MainMenu.clients-files') },
                ],
                gridConfig: {
                    apiUrl:null,
                    readonly: true,
                    columns: [
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'50px' },
                        { type:'select', key:'core_rubric_id', header:this.$t('object.core_rubric'), source:'core_rubric' },
                        { type:'datetime', key:'visit_time_of', header:this.$t('object.visit_rec') },
                        // { type:'number', key:'visit_rec_id', header:this.$t('object.visit_rec') },
                        // {
                        //     type:'popuplist', key:'visit_rec_id', header:this.$t('object.visit_rec'), source:'v_visit', keyLabel:'$name',
                        //     listConfig: {
                        //         readonly:true,
                        //         columns: [
                        //             { type:'datetime', key:'time_of', header:this.$t('attribute.common.datetime_of') },
                        //             { type:'hidden', key:'$name', header:this.$t('attribute.common.name'), $value:entry => `${entry.time_of}` },
                        //         ],
                        //         order: [ 'name' ],
                        //     },
                        // },
                        { type:'datetime', key:'filetime', header:this.$t('attribute.core_file.filetime') },
                        { type:'string', key:'filename', header:this.$t('attribute.common.filename') },
                        { type:'string', key:'description', header:this.$t('attribute.common.description') },
                        { type:'icon', key:'file-view', header:this.$t('app.view'), icon:'fas fa-eye', align:'center', style:'color: #056bbf;', sortable:false, emit:'view' },
                        { type:'icon', key:'file-download', header:this.$t('app.download'), icon:'fas fa-download', align:'center', style:'color: #056bbf;', sortable:false, emit:'download' },
                        // { type:'string', key:'$isNew', header:'isNew' },
                    ],
                    order: [ 'code' ],
                    displayToolbar: true,
                    buttons: [ 'add', 'edit' ],
                    saveSessionState: `${this.$options.name}`,
                    $onAdd: (grid) => { grid.$router.push({ name: 'client-file-new' }) },
                    $onEdit: (grid, item) => { grid.$router.push({ name: 'client-file-edit', params: { clientId:this.clientId, id: item.id } }) },
                },
                // fileContent: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                lastClient: 'lastClient',
            }),
        },

        // =============== Methods ===============
        methods: {
            async editFile(file) {
                // console.log(`DEBUG: ${this.$options.name}.selectVisit file=`, file);
                if (!file) return;
            },

            async viewFile(file) {
                try {
                    let response = await this.authenticatedAxios.get(`${this.baseUrl}/core_file/${file.id}/file`, { responseType: 'arraybuffer' });
                    let fileContent = new Blob([ response.data ], { type: file.mimetype });
                    let url = window.URL.createObjectURL(fileContent);
                    window.open(url, '_blank');
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
            async downloadFile(file) {
                // console.log(`DEBUG: ${this.$options.name}.downloadFile() file=`, file);
                // console.log(`DEBUG: ${this.$options.name}.downloadFile() this.$store=`, this.$store);
                // variant 1
                // problem: needs cookie auth, not bearer
                // window.open(`${this.baseUrl}/core_file/${file.id}/file`, '_blank');
                // variant 2
                // problem: filename is lost or file downloaded, not displayed
                // let response = await this.authenticatedAxios.get(`${this.baseUrl}/core_file/${file.id}/file`, {responseType: 'blob'});
                // let fileContent = new Blob([response.data], { type: file.mimetype });
                // let link = document.createElement('a');
                // link.href = window.URL.createObjectURL(fileContent);
                // // link.download = file.filename;
                // link.target = '_blank';
                // link.click();
                // variant 3
                // problem: filename is lost or file downloaded, not displayed
                // let xhr = new XMLHttpRequest();
                // xhr.open('GET', `${this.baseUrl}/core_file/${file.id}/file`, true);
                // xhr.setRequestHeader('Authorization', this.$store.state.auth.jwt);
                // xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                // xhr.responseType = 'arraybuffer';
                // xhr.onload = function(err) {
                //     if (this.status === 200) {
                //         let blob = new Blob([this.response], { type:file.mimetype });
                //         let link = document.createElement('a');
                //         link.href = window.URL.createObjectURL(blob);
                //         // link.download = file.filename;
                //         link.target = '_blank';
                //         link.click();
                //         window.URL.revokeObjectURL(link.href);
                //     }
                // }
                // xhr.send();
                // variant 4
                // problem: filename is lost or file downloaded, not displayed
                // this.authenticatedAxios.get(`${this.baseUrl}/core_file/${file.id}/file`, { responseType: 'arraybuffer' })
                //     .then(response => {
                //         // console.log(response);
                //         let blob = new Blob([response.data], { type: file.mimetype });
                //         let url = window.URL.createObjectURL(blob);
                //         window.open(url, '_blank'); // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
                //     });
                // variant 5
                // window.open(`${this.baseUrl}/core_file/${file.id}/file`, '_blank');
                // const link = document.createElement("a");
                // document.body.appendChild(link);
                // link.href = `${this.baseUrl}/core_file/${file.id}/file`;
                // link.setAttribute('Authorization', this.$store.state.auth.jwt);
                // // link.target = '_blank';
                // // link.setAttribute("download", true);
                // link.click();
                // variant 6
                this.authenticatedAxios.get(`${this.baseUrl}/core_file/${file.id}/file`, { responseType: 'arraybuffer' }).then( response => {
                    let fileContent = new Blob([ response.data ], { type: file.mimetype });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(fileContent);
                    link.download = file.filename;
                    link.click();
                    window.URL.revokeObjectURL(link);
                });
            },

        },

        // =============== Life cycle ===============
        beforeMount() {
            this.clientId = parseInt(this.$route.params.clientId);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.$router=`, this.$router);
            if (this.clientId) {
                // this.setBreadcrumb();
                this.breadcrumb[this.breadcrumb.length - 2].to = `/client/${this.clientId}`;
                this.breadcrumb[this.breadcrumb.length - 2].text = this.lastClient.shortname;
                this.gridConfig.filter = { where:[{ core_client_id:this.clientId }]};
                this.gridConfig.apiUrl = `v_file`;
            }
        },
    }
</script>

<style>

</style>
