/*
    Author: Eduard Grebenyukov
    Date: 2020-04-22
*/

import { i18n } from '@/locales';
// import { sexKind } from './constants';

const pageConfigCore = {
    core_setting: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-general-system') }
        ],
        gridConfig: {
            apiUrl:'core_setting',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required: true,readonly:true, align:'right', width:'50px' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code'), required:true },
                { type:'string', key:'name', header:i18n.t('attribute.common.name') },
                { type:'string', key:'value', header:i18n.t('Settings.value'), required:true },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'core_setting',
        }
    },
    core_enum_group: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-general-enum') }
        ],
        gridConfig: {
            apiUrl:'core_enum_group',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required: true,readonly:true, align:'right', width:'50px' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code'), required:true },
                { type:'string', key:'name', header:i18n.t('attribute.common.name') },
            ],
            buttons: [ 'row-selector', 'add', 'edit', 'save', 'delete' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'core_enum_group',
            $onEdit: (ctx, item) => { ctx.$router.push({ path: `/settings/general/enum/${item.id}` }) },
        }
    },
    core_org: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-general-organizations') }
        ],
        gridConfig: {
            apiUrl:'core_org',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                { type:'select', key:'org_type_id', header:i18n.t('Settings.org_type'), required:true, source:'enum/org_type', keyLabel:'name' },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'string', key:'name_short', header:i18n.t('attribute.common.name_short') },
                { type:'string', key:'name_full', header:i18n.t('attribute.common.name_full') },
                { type:'string', key:'tin', header:i18n.t('attribute.core_org.tin') },
                { type:'string', key:'trrc', header:i18n.t('attribute.core_org.trrc') },
                { type:'string', key:'rnnbo', header:i18n.t('attribute.core_org.rnnbo') },
                // { type:'string', key:'address_reg', header:i18n.t('attribute.common.address_reg') },
                // { type:'string', key:'address_act', header:i18n.t('attribute.common.address_act') },
                { type:'string', key:'commentary', header:i18n.t('attribute.common.commentary') },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'name' ],
            displayToolbar: true,
            saveSessionState: 'core_org',
        }
    },
    core_building: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-general-buildings') }
        ],
        gridConfig: {
            apiUrl:'core_building',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                // { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'string', key:'name_short', header:i18n.t('attribute.common.name_short') },
                { type:'string', key:'address_act', header:i18n.t('Settings.address_act') },
                { type:'string', key:'commentary', header:i18n.t('Settings.commentary') },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'name' ],
            displayToolbar: true,
            saveSessionState: 'core_building',
        }
    },
    core_department: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-general-departments') }
        ],
        gridConfig: {
            apiUrl:'core_dept',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                { type:'select', key:'core_org_id', header:i18n.t('object.core_org'), required:true, source:'core_org', keyLabel:'name' },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'string', key:'name_short', header:i18n.t('attribute.common.name_short') },
                { type:'string', key:'name_full', header:i18n.t('attribute.common.name_full') },
                { type:'string', key:'commentary', header:i18n.t('Settings.commentary') },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'core_org_id', 'name' ],
            displayToolbar: true,
            saveSessionState: 'core_department',
        }
    },
    core_room: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-general-rooms') }
        ],
        gridConfig: {
            apiUrl:'core_room',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                { type:'select', key:'room_type_id', header:i18n.t('enum.room_type'), required:true, source:'enum/room_type', keyLabel:'name' },
                { type:'select', key:'core_org_id', header:i18n.t('object.core_org'), source:'core_org', keyLabel:'name' },
                { type:'select', key:'core_dept_id', header:i18n.t('object.core_dept'), source:'core_dept', keyLabel:'name' },
                { type:'select', key:'core_building_id', header:i18n.t('object.core_building'), source:'core_building', keyLabel:'name' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'string', key:'room_num', header:i18n.t('Settings.room_num') },
                { type:'number', key:'floor', header:i18n.t('Settings.floor') },
                { type:'string', key:'commentary', header:i18n.t('Settings.commentary') },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'name' ],
            displayToolbar: true,
            saveSessionState: 'core_room',
        }
    },
    core_country: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-general-countries') }
        ],
        gridConfig: {
            apiUrl:'core_country',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                { type:'string', key:'code_iso_3166_1_a2', header:i18n.t('Settings.code_iso_3166_1_a2'), required:true },
                { type:'string', key:'code_iso_3166_1_a3', header:i18n.t('Settings.code_iso_3166_1_a3'), required:true },
                { type:'string', key:'code_iso_3166_1_d', header:i18n.t('Settings.code_iso_3166_1_d'), required:true },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'string', key:'name_intl', header:i18n.t('attribute.common.name_intl') },
                { type:'number', key:'sort_order', header:i18n.t('attribute.common.sort_order') },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'name' ],
            displayToolbar: true,
            saveSessionState: 'core_country',
        }
    },
    core_speciality: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-general-specialities') }
        ],
        gridConfig: {
            apiUrl:'core_speciality',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code'), required:true },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'name' ],
            displayToolbar: true,
            saveSessionState: 'core_speciality',
        }
    },
    core_device: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-general-devices') }
        ],
        gridConfig: {
            apiUrl:'core_device',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                { type:'select', key:'device_type_id', header:i18n.t('enum.device_type'), required:true, source:'enum/device_type', keyLabel:'name' },
                { type:'select', key:'core_org_id', header:i18n.t('object.core_org'), source:'core_org', keyLabel:'name' },
                { type:'select', key:'core_dept_id', header:i18n.t('object.core_dept'), source:'core_dept', keyLabel:'name' },
                { type:'select', key:'core_room_id', header:i18n.t('object.core_room'), source:'core_room', keyLabel:'name' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code'), required:true },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'string', key:'name_short', header:i18n.t('attribute.common.name_short') },
                { type:'string', key:'ip_address', header:i18n.t('attribute.common.ip_address') },
                { type:'select', key:'core_driver_id', header:i18n.t('object.core_driver'), source:'core_driver' },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete', 'edit' ],
            order: [ 'name' ],
            displayToolbar: true,
            saveSessionState: 'core_device',
            onAdd: 'settings/general/device-new',
            onEdit: 'settings/general/device-edit',
        }
    },
    core_printform: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-general-printforms') }
        ],
        gridConfig: {
            apiUrl:'core_printform',
            readonly: true,
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, align:'right', width:'50px' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code'), required:true },
                { type:'string', key:'filename', header:i18n.t('attribute.common.filename'), required:true },
                { type:'string', key:'description', header:i18n.t('attribute.common.description') },
            ],
            buttons: [ 'add', 'edit' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'core_printform',
            // $onAdd() { console.log(`pageConfig.this=`, this); this.$router.push({ name:'settings/visits/workplace-edit', params: { id: null } }); },
            // $onEdit(id) { this.$router.push({ name:'settings/visits/workplace', params: { id } }); },
            onAdd: 'settings/general/printform-new',
            onEdit: 'settings/general/printform-edit',
        }
    },
    // core_component_type: {
    //     breadcrumb: [
    //         { text:i18n.t('MainMenu.home'), to:'/' },
    //         { text:i18n.t('MainMenu.settings') },
    //         { text:i18n.t('MainMenu.settings-general-component-types') }
    //     ],
    //     gridConfig: {
    //         apiUrl:'core_component_type',
    //         columns: [
    //             { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, align:'right', width:'50px' },
    //             { type:'string', key:'code', header:i18n.t('attribute.common.code'), required:true },
    //             { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
    //         ],
    //         buttons: [ 'add', 'edit' ],
    //         order: [ 'code' ],
    //         displayToolbar: true,
    //         saveSessionState: 'core_component_type',
    //     }
    // },
    core_component: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-general-components') }
        ],
        gridConfig: {
            apiUrl:'core_component',
            readonly: true,
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, align:'right', width:'50px' },
                { type:'string', key:'app_code', header:i18n.t('attribute.common.app_code') },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                { type:'string', key:'name', header:i18n.t('attribute.common.name') },
                { type:'string', key:'url', header:i18n.t('attribute.common.url') },
                { type:'string', key:'description', header:i18n.t('attribute.common.description') },
            ],
            buttons: [ 'add', 'edit' ],
            order: [ 'code' ],
            displayToolbar: true,
            saveSessionState: 'core_component',
            // $onAdd() { console.log(`pageConfig.this=`, this); this.$router.push({ name:'settings/visits/workplace-edit', params: { id: null } }); },
            // $onEdit(id) { this.$router.push({ name:'settings/visits/workplace', params: { id } }); },
            onAdd: 'settings/general/component-new',
            onEdit: 'settings/general/component-edit',
        }
    },
    core_role: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-rights-roles') }
        ],
        gridConfig: {
            apiUrl:'core_role',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code'), required:true },
                { type:'string', key:'name', header:i18n.t('attribute.common.name'), required:true },
                { type:'string', key:'commentary', header:i18n.t('Settings.commentary') },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete', 'edit' ],
            order: [ 'name' ],
            displayToolbar: true,
            saveSessionState: 'core_role',
        }
    },
    core_permission: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-rights-permissions') }
        ],
        gridConfig: {
            apiUrl:'core_permission',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), align:'right', readonly:true, width:'50px' },
                { type:'select', key:'core_privilege_id', header:i18n.t('object.core_privilege'), required:true, source:'core_privilege' },
                { type:'select', key:'core_role_id', header:i18n.t('object.core_role'), required:true, source:'core_role', showEmptyRow:true },
                { type:'string', key:'object_name', header:i18n.t('attribute.core_permission.object_name') },
                // { type:'string', key:'attribute_name', header:i18n.t('attribute.core_permission.attribute_name') },
                // { type:'number', key:'row_id', header:i18n.t('attribute.core_permission.row_id'), align:'right', width:'50px' },
                { type:'string', key:'code', header:i18n.t('attribute.common.code') },
                // { type:'select', key:'access_type', header:i18n.t('attribute.core_permission.access_type'), source: [
                //     { id:'r', name:i18n.t('constant.access_type.r') },
                //     { id:'rw', name:i18n.t('constant.access_type.rw') },
                // ], showEmptyRow:true },
                { type:'checkbox', key:'is_read', header:i18n.t('attribute.core_permission.is_read'), defaultValue:false, width:'50px', align:'center;' },
                { type:'checkbox', key:'is_write', header:i18n.t('attribute.core_permission.is_write'), defaultValue:false, width:'50px', align:'center;' },
                // { type:'select', key:'organization_id', header:i18n.t('Settings.organization'), source:'organizations' }
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete' ],
            order: [ 'core_privilege_id' ],
            displayToolbar: true,
            saveSessionState: 'core_permission',
        }
    },
    settings_menu: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-rights-menu') }
        ],
        gridConfig: {
            apiUrl:'core_permission',
            readonly: true,
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), align:'right', width:'50px' },
                { type:'select', key:'core_privilege_id', header:i18n.t('object.core_privilege'), required:true, source:'core_privilege' },
                { type:'string', key:'fullname', header:i18n.t('Settings.user') },
                { type:'string', key:'username', header:i18n.t('Settings.username') },
                { type:'string', key:'email', header:i18n.t('Settings.email') },
                { type:'checkbox', key:'is_curator', header:i18n.t('component.UserEdit.is_curator'), width:'50px', align:'center;' },
                // { type:'select', key:'organization_id', header:i18n.t('Settings.organization'), source:'organizations' }
            ],
            buttons: [ 'add', 'edit' ],
            order: [ 'username' ],
            displayToolbar: true,
            saveSessionState: 'settings_menu',
        }
    },
    core_workplace: {
        breadcrumb: [
            { text:i18n.t('MainMenu.home'), to:'/' },
            { text:i18n.t('MainMenu.settings') },
            { text:i18n.t('MainMenu.settings-visits-workplaces') }
        ],
        gridConfig: {
            apiUrl:'core_workplace',
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id'), required:true, readonly:true, align:'right', width:'50px' },
                { type:'string', key:'name', header:i18n.t('attribute.common.name') },
                { type:'select', key:'core_user_id', header:i18n.t('object.core_user'), source:'v_user', keyLabel:'shortname', showEmptyRow:true },
                { type:'select', key:'core_device_id', header:i18n.t('object.core_device'), source:'core_device', keyLabel:'name', showEmptyRow:true },
                { type:'select', key:'core_dept_id', header:i18n.t('object.core_dept'), source:'core_dept', keyLabel:'name', showEmptyRow:true },
                { type:'select', key:'core_speciality_id', header:i18n.t('object.core_speciality'), source:'core_speciality', showEmptyRow:true },
                { type:'select', key:'core_room_id', header:i18n.t('object.core_room'), source:'core_room', showEmptyRow:true },
                { type:'string', key:'position', header:i18n.t('attribute.common.position') },
                { type:'date', key:'date_from', header:i18n.t('attribute.common.date_from') },
                { type:'date', key:'date_to', header:i18n.t('attribute.common.date_to') },
                { type:'checkbox', key:'is_schedule', header:i18n.t('attribute.core_workplace.is_schedule'), defaultValue:true },
                { type:'checkbox', key:'is_final', header:i18n.t('attribute.core_workplace.is_final'), defaultValue:true },
                { type:'checkbox', key:'is_self_recording', header:i18n.t('attribute.core_workplace.is_self_recording'), defaultValue:false },
            ],
            buttons: [ 'row-selector', 'add', 'save', 'delete', 'edit' ],
            order: [ 'name' ],
            displayToolbar: true,
            saveSessionState: 'core_workplace',
            // $onAdd() { console.log(`pageConfig.this=`, this); this.$router.push({ name:'settings/visits/workplace-edit', params: { id: null } }); },
            // $onEdit(id) { this.$router.push({ name:'settings/visits/workplace', params: { id } }); },
            onEdit:'settings/visits/workplace-edit',
        }
    },
    core_session: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-system') },
            { text: i18n.t('MainMenu.settings-system-sessions') }
        ],
        gridConfig: {
            apiUrl: 'v_session',
            readonly: true,
            columns: [
                { type:'number', key:'id', header:i18n.t('attribute.common.id') },
                { type:'string', key:'username', header:i18n.t('object.core_user') },
                { type:'string', key:'device_name', header:i18n.t('object.core_device') },
                { type:'string', key:'ip_address', header:i18n.t('attribute.common.ip_address') },
                { type:'datetime', key:'time_start', header:i18n.t('attribute.common.time_from') },
                { type:'datetime', key:'time_end', header:i18n.t('attribute.common.time_to') },
                { type:'icon', key:'$user_status', header:i18n.t('attribute.common.status'), width:'50px', align:'center;',
                    $icon:(entry) => (entry.username ? (entry.user_is_active ? 'fas fa-lock-open' : 'fas fa-ban') : ''),
                    $cellStyle:(entry) => entry.user_is_active ? 'color:green;' : 'color:red;',
                    $title:(entry) => entry.user_is_active ? i18n.t('component.Users.active') : i18n.t('component.Users.archived'),
                },
            ],
            order: [{ key:'time_start', direction:'desc' }],
            displayToolbar: true,
            saveSessionState: 'core_session',
        }
    },
    system_performance_sql: {
        breadcrumb: [
            { text: i18n.t('MainMenu.home'), to: '/' },
            { text: i18n.t('MainMenu.settings') },
            { text: i18n.t('MainMenu.settings-system') },
            { text: i18n.t('MainMenu.settings-system-performance') }
        ],
        gridConfig: {
            apiUrl: 'system-performance-sql',
            readonly: true,
            columns: [
                { type:'number', key:'total_time', header:'Total time' },
                { type:'number', key:'calls', header:'Calls' },
                { type:'number', key:'mean', header:'Mean' },
                { type:'number', key:'percentage_cpu', header:'CPU %' },
                { type:'string', key:'query', header:'Query' },
            ],
            order: [{ key:'total_time', direction:'desc' }],
            displayToolbar: true,
            saveSessionState: 'system_performance_sql',
        }
    },
}

export { pageConfigCore };
