var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:{ 'field-required':_vm.required, 'row':_vm.labelPositionCode === 'before' }},[(_vm.label && (_vm.labelPosition === 'above'))?_c('label',{staticClass:"field__label__above",attrs:{"for":_vm.id,"title":_vm.required ? _vm.$t('lib.required') : ''}},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('sup',{staticClass:"required-mark"},[_vm._v("*")]):_vm._e()]):_vm._e(),(_vm.label && (_vm.labelPosition === 'before'))?_c('label',{staticClass:"field__label__before",attrs:{"for":_vm.id,"title":_vm.required ? _vm.$t('lib.required') : ''}},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('sup',{staticClass:"required-mark"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"input-group"},[_c('span',{class:{
                textClass: _vm.textClass,
                'form-control':_vm.labelPositionCode != 'plain',
                'col-sm-10':_vm.labelPositionCode === 'before',
                'is-invalid':!!_vm.errorMessage,
                'text-nowrap':true,
                'overflow-hidden':true,
                'field__readonly': _vm.readonly || _vm.disabled,
                'field__disabled': _vm.disabled,
                'field__required-empty': _vm.required && (!_vm.itemData || _vm.itemData == '')
            },style:(_vm.textStyle + _vm.inputStyle),attrs:{"type":"text","title":_vm.itemPresentation}},[_vm._v(_vm._s(_vm.itemPresentation))]),_c('div',{staticClass:"input-group-append"},[_vm._t("buttons"),(_vm.itemData)?_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button","disabled":_vm.readonly || _vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.onClear($event)}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e(),_c('button',{staticClass:"btn btn-outline-secondary dropdown-toggle",attrs:{"type":"button","disabled":_vm.readonly || _vm.disabled,"aria-haspopup":""},on:{"click":function($event){$event.preventDefault();return _vm.onSelector($event)}}})],2)]),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.errorMessage}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),(_vm.isDisplayList)?_c('ModalWindow',{attrs:{"max-width":_vm.maxWidth,"max-height":_vm.maxHeight,"header":_vm.label},on:{"cancel":_vm.closePopup}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('Grid',{ref:"selectlist",attrs:{"source":Array.isArray(_vm.source) ? _vm.source : null,"config":_vm.gridConfig},on:{"add-item":function($event){return _vm.onAddItem()},"item-selected":function($event){return _vm.itemSelected($event)}}})],1)])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }