<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-29
-->

<!--
    labelPosition: none | before | above | float
-->

<template>
    <div
        :class="{
            'field-required': required,
            'form-group': true,
            'form-inline': labelPosition === 'before',
            'form-label-group': labelPosition === 'float'
        }"
    >
        <label :for="id" v-if="label && (labelPosition === 'above')" :title="required ? $t('lib.required') : ''" class="field__label__above">
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <label :for="id" v-if="label && (labelPosition === 'before')" :title="required ? $t('lib.required') : ''" class="field__label__before" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <div class="field-number__label-spacer" v-if="!label && (labelPosition === 'above')" ></div>

        <input
            v-if="!readonly"
            :id="id"
            type="number"
            v-model="itemData"
            :required="required"
            :placeholder="placeholder || (labelPosition == 'float' ? label : placeholder)"
            :disabled="disabled"
            :maxlength="maxlength"
            :min="minValue"
            :max="maxValue"
            :class="{
                'form-control': !readonly,
                'form-control-plaintext': readonly,
                'is-invalid': !!errorMessage,
                'field__required-empty': required && (isNaN(itemData) || itemData === null || itemData === '')
            }"
            :style="`text-align: right; ${inputStyle}`"
            @input="onInput($event)"
            @change="onChange($event)"
            @focus="itemFocused($event)"
        />
        <span
            v-if="readonly"
            class="field-number field__readonly"
            :class="{ textClass, 'form-control':labelPosition != 'plain', 'col-sm-10':labelPosition === 'before' }" :style="textStyle"
        >
            {{ presentation }}
        </span>

        <label :for="id" v-if="labelPosition === 'float'" :title="required ? $t('lib.required') : ''" >
            {{ label }}<sup v-if="required" class="required-mark">*</sup>
        </label>

        <b-form-invalid-feedback :state="!errorMessage">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    const userSettingsUrl = 'core_user_settings';

    export default {
        name: 'FieldNumber',

        // =============== Props ===============
        props: {
            id: String,
            label: String,
            labelPosition: { type: String, default: () => { return 'above' } },
            value: Number,
            defaultValue: Number,
            minValue: Number,
            maxValue: Number,
            placeholder: String,
            disabled: Boolean,
            required: Boolean,
            readonly: Boolean,
            maxlength: Number,
            saveState: String,
            saveSessionState: String,
            // style // reserved word default attribute, implements to top-level template object
            inputStyle: String,
            textClass: String,
            textStyle: String,
            // autocomplete: String,
            errorMessage: String,
            decimalPlaces: Number,
        },

        // =============== Data ===============
        data() {
            return {
                itemData: null,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios'
            }),
            presentation() {
                // if (this.decimalPlaces) return this.value.toFixed(this.decimalPlaces);
                // if (this.decimalPlaces) return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(this.value);
                if (this.decimalPlaces) return new Intl.NumberFormat('ru-RU', { minimumFractionDigits:this.decimalPlaces }).format(this.value); //!!!!
                return this.value;
            },
        },

        // =============== Watch ===============
        watch: {
            value() {
                this.itemData = this.convert(this.value);
                // console.log(`DEBUG: ${this.$options.name}(${this.label}).watch.value this.value=`, this.value);
            },
        },

        // =============== Methods ===============
        methods: {
            convert(value) {
                return value === '' || value === null || isNaN(parseFloat(value)) ? null : parseFloat(value);
            },

            checkBounds() {
                // console.log(`DEBUG: ${this.$options.name}.checkBounds('${this.itemData}')`);
                if ((this.minValue || this.minValue === 0) && this.itemData < this.minValue) this.itemData = this.minValue;
                if ((this.maxValue || this.maxValue === 0) && this.itemData > this.maxValue) this.itemData = this.maxValue;
            },

            itemModified(value) {
                // console.log(`DEBUG: ${this.$options.name}.itemModified('${this.itemData}')`);
                if (this.saveState) {
                    // Save current value
                    this.authenticatedAxios.post(`${this.baseUrl}/${userSettingsUrl}`, { code: this.saveState, value: this.itemData });
                }
                if (this.saveSessionState) {
                    sessionStorage.setItem(this.saveSessionState, JSON.stringify(this.itemData));
                }
                this.$emit('update:value', this.convert(this.itemData));
                this.$emit('modified');
            },

            itemFocused(evt) {
                // console.log(`DEBUG: ${this.$options.name}.itemFocused() evt=`, evt);
                this.$emit('focus');
            },

            onInput(evt) {
                this.checkBounds();
                this.itemModified();
            },

            onChange(evt) {
                // console.log(`DEBUG: ${this.$options.name}.onChange('${evt.target.value}')`);
                this.checkBounds();
                this.$emit('change');
                this.itemModified();
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.itemData = this.convert(this.value);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.value=`, this.value);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.itemData=`, this.itemData);
            if (!this.itemData && this.saveState) {
                // Restore stored value
                let response = (await this.authenticatedAxios.get(`${this.baseUrl}/${userSettingsUrl}/${this.saveState}`));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() url response=`, response);
                let item = response.data.rows[0];
                if (item) this.itemData = item.value;
                this.itemModified(this.itemData);
            }
            if (!this.itemData && this.saveSessionState) {
                let sessionData = JSON.parse(sessionStorage.getItem(this.saveSessionState));
                // console.log(`DEBUG: ${this.$options.name}.beforeMount() sessionData=`, sessionData);
                if (sessionData) this.itemData = sessionData;
                this.itemModified(this.itemData);
            }
            if (!this.itemData && typeof this.defaultValue != 'undefined' && this.defaultValue !== null) {
                this.itemData = this.defaultValue;
                this.itemModified(this.itemData);
            }
        },

        update() {
            this.itemData = parseFloat(this.value);
        },
    }
</script>

<style>
    .field-number {
        text-align: right;
    }

    .field-number__label-spacer {
        height:25px;
    }
</style>
