<!--
    Author: Eduard Grebenyukov
    Date: 2021-09-19
-->

<template>
    <StandardRegion :title="$t('component.MedMedicationTemplate.template')" regionClass="sticky-4">

        <div class="med-medications-template__buttons">
            <button
                @click.prevent="selectTemplateType('personal')"
                class="btn btn-outline-secondary btn-sm ml-2"
                :class="{ active:templateType == 'personal' }"
                :title="$t('component.MedMedicationTemplate.personal')"
            ><i class="fas fa-star"></i> {{ $t('component.MedMedicationTemplate.personal') }}</button>
            <button
                @click.prevent="selectTemplateType('general')"
                class="btn btn-outline-secondary btn-sm ml-2"
                :class="{ active:templateType == 'general' }"
                :title="$t('component.MedMedicationTemplate.general')"
            ><i class="fas fa-users"></i> {{ $t('component.MedMedicationTemplate.general') }}</button>
        </div>
        <div class="med-medications-template__wrapper">
            <Grid
                ref="medicinesGrid"
                :source="medicinesGridSource"
                :config="medicinesGridConfig"
                @selected="selectItem($event)"
            />
        </div>

        <VueContext ref="contextMenu">
            <template v-slot:default="child">
                <li><a id="context_select_item" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.select') }}</a></li>
                <hr class="context-divider">
                <li v-if="!isEditEnabled"><a id="context_add_item_to_personal_template" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.addToPersonalTemplate') }}</a></li>
                <!-- <li><a v-if="isEditEnabled" id="context_edit_add" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.add') }}</a></li> -->
                <li><a v-if="isEditEnabled" id="context_edit_item" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.edit') }}</a></li>
                <li><a v-if="isEditEnabled" id="context_delete_item" href="#" @click.prevent="onContextMenu($event, child.data)">{{ $t('app.delete') }}</a></li>
            </template>
        </VueContext>

    </StandardRegion>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import moment from 'moment';

    import tools from '@/components/lib/tools';
    import StandardRegion from '@/components/lib/regions/StandardRegion';
    // import NavigationTabs from '@/components/lib/regions/NavigationTabs';
    import Grid from '@/components/lib/regions/Grid';
    import { VueContext } from 'vue-context';

    export default {
        name: 'MedMedicationTemplate',

        components: { StandardRegion, Grid, VueContext },

        // =============== Props ===============
        props: {
            visitTypeId: Number,
            specialityId: Number,
            visitTime: Date,
        },

        // =============== Data ===============
        data() {
            return {
                medicationsTemplates: [],
                medicationsTemplatesPersonal: [],
                // medicationsTemplatesGeneral: [],
                templateType: 'general',
                isDisplayModalEditor: false,
                templateId: null,
                // attributeValue: null,
                editFormData: {},
                isShowContextMenu: false,
                // searchKeybuffer: '',
                medicinesGridSource: null,
                medicinesGridConfig: {
                    source: null,
                    apiUrl: null,
                    filter: null,
                    readonly: true,
                    hideZero: true,
                    columns: [
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        { type:'string', key:'substance', header:this.$t('attribute.med_medicine.substance') },
                        { type:'string', key:'release_forms', header:this.$t('attribute.med_medicine.release_forms') },
                    ],
                    row: {
                        emit: 'selected',
                        style: 'cursor: pointer;',
                        // $style: (item) => {
                        //     return `cursor: pointer;${item.is_archived ? 'background-color: #aaa;' : ''}`;
                        // },
                        $onRightClick: (item, evt) => {
                            this.showContextMenu(evt, item);
                        },
                        $backgroundColor: (item) => {
                            if (item.is_archived) {
                                return '#aaa';
                            }
                            return '';
                        },
                    },
                    order: [ 'name' ],
                    displayToolbar: true,
                    paginationPageSize: 10,
                    saveSessionState: `${this.$options.name}`,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),

            // attributeValue() {
            //     return this.editFormData[this.formItemName];
            // },

            isEditEnabled() {
                return this.templateType === 'personal';
            },
        },

        // =============== Watch ===============
        watch: {
            // formItemName() {
            //     // this.attributeValue = this.editFormData[this.formItemName];
            //     this.getData();
            // },

            templateType() {
                this.chooseTemplates();
            },

            // formData() {
            //     this.editFormData = this.formData;
            //     // console.log(`DEBUG: ${this.$options.name}.watch.formData() this.editFormData=`, this.editFormData);
            // },
        },

        // =============== Methods ===============
        methods: {
            chooseTemplates() {
                if (this.templateType == 'personal') {
                    this.medicinesGridConfig.apiUrl = null;
                    this.medicinesGridSource = this.medicationsTemplatesPersonal;
                    // this.$refs.medicinesGridConfig.filter = null;
                } else {
                    this.medicinesGridSource = null;
                    this.medicinesGridConfig.apiUrl = 'v_medicine';
                    this.medicinesGridConfig.filter =  { where:[{ is_active: true }]};
                }
                // this.$refs.medicinesGrid.refresh();
            },

            async getData() {
                // console.log(`DEBUG: ${this.$options.name}.getData() this.attributeName=${this.attributeName}`);
                try {
                    if (this.currentUser.id) {
                        let referralFilterPersonalString = tools.getFilterString(
                            {
                                where:[
                                    { core_user_id: this.currentUser.id },
                                    { attribute_name:'med_medication.med_medicine_id' },
                                ],
                                order:[ 'sort_order' ],
                            }
                        );
                        this.medicationsTemplatesPersonal = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_template${referralFilterPersonalString}`)).data.rows;
                        // console.log(`DEBUG: ${this.$options.name}.getData() this.medicationsTemplatesPersonal(${this.currentUser.id})=`, this.medicationsTemplatesPersonal);
                    } else {
                        this.medicationsTemplatesPersonal = [];
                    }

                    if (this.medicationsTemplatesPersonal.length > 0)
                        this.selectTemplateType('personal');
                    else
                        this.selectTemplateType('general');
                    this.chooseTemplates();
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.medicationsTemplatesPersonal(${this.userId})=`, this.medicationsTemplatesPersonal);
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.documentTemplates=`, this.documentTemplates);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            selectTemplateType(templateType) {
                this.templateType = templateType;
            },

            showContextMenu(evt, item) {
                // console.log(`DEBUG: ${this.$options.name}.showContextMenu() evt=`, evt);
                // console.log(`DEBUG: ${this.$options.name}.showContextMenu() item=`, item);
                this.$refs.contextMenu.open(evt, item);
            },
            async onContextMenu(evt, item) {
                // console.log(`DEBUG: ${this.$options.name}.onContextMenu() e.target.id=`, evt.target.id, ', item=', item);
                switch (evt.target.id) {
                    case 'context_select_item':
                        this.selectItem(item);
                        break;
                    // case 'context_edit_item':
                    //     this.editTemplate(item.id);
                    //     break;
                    case 'context_delete_item':
                        this.deleteTemplate(item.id);
                        break;
                    case 'context_add_item_to_personal_template':
                        this.addItemToPersonalTemplate(item);
                        break;
                }
            },

            selectItem(item) {
                // console.log(`DEBUG: ${this.$options.name}.selectItem() item=`, item);
                this.$emit('selected', item);
            },

            // editTemplate(id) {
            //     this.templateId = id;
            //     this.isDisplayModalEditor = true;
            // },

            async addItemToPersonalTemplate(item) {
                // console.log(`DEBUG: ${this.$options.name}.addItemToPersonalTemplate() item=`, item);
                // console.log(`DEBUG: ${this.$options.name}.addItemToPersonalTemplate() currentUser=`, this.currentUser);
                let filter = tools.getFilterString({ where:[
                    { attribute_name: 'med_medication.med_medicine_id' },
                    { template_text: item.id},
                ]});
                let prevTemplate = (await this.authenticatedAxios.get(`${this.baseUrl}/visit_template${filter}`)).data.rows[0];
                if (!prevTemplate) {
                    let visit_template = {
                        parent_id: null,
                        visit_type_id: null,
                        core_speciality_id: null,
                        core_user_id: this.currentUser.id,
                        attribute_name: 'med_medication.med_medicine_id',
                        name: item.name,
                        sort_order: null,
                        template_text: item.id,
                    }
                    await this.authenticatedAxios.post(`${this.baseUrl}/visit_template`, visit_template);
                    // console.log(`DEBUG: ${this.$options.name}.addItemToPersonalTemplate() response=`, response);
                    this.getData();
                } else {
                    tools.displayWarning(this, this.$t('component.MedMedicationTemplate.personalAlreadyExists'));
                }
            },

            async deleteTemplate(id) {
                try {
                    await this.authenticatedAxios.delete(`${this.baseUrl}/visit_template/${id}`);
                    tools.displaySuccessDelete(this.$parent);
                    this.getData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.editFormData = this.formData;
            this.getData();
        },
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.editFormData.complaints=${this.editFormData.complaints}, this.editFormData=`, this.editFormData);
        },
    }
</script>

<style>
    .med-medications-template__wrapper {
        /* padding: 0.5rem; */
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
    .med-medications-template__buttons {
        padding: 0.5rem 0;
        border-bottom: 1px solid #dee2e6;
    }
    .med-medications-template__wrapper .grid-container .grid-data-container {
        font-size: 0.75rem;
    }
</style>
