<!--
    Author: Eduard Grebenyukov
    Date: 2021-06-19
-->

<template>
     <TemplatePageStandard :config="{ breadcrumb }">
        <!-- <template #buttons>
            <div @click="filterModified()" class="btn btn-sm btn-primary mr-1">
                <i class="fas fa-play"></i> {{ $t('app.build') }}
            </div>
        </template> -->

        <div id="page-content">
            <div class="row">
                <div class="col-xl">
                    <FieldDatetime
                        label="From time"
                        :value.sync="filterData.timeFrom"
                        displayClearButton
                        @modified="filterModified"
                    />
                </div>
                <div class="col-md">
                    <FieldDatetime
                        label="To time"
                        :value.sync="filterData.timeTo"
                        displayClearButton
                        @modified="filterModified"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <Grid ref="grid1" :config="gridConfig1" />
                </div>
            </div>
            <!-- <div class="row">
                <div class="col">
                    <Grid ref="grid2" :config="gridConfig2" />
                </div>
            </div> -->
        </div>
    </TemplatePageStandard>
</template>

<script>
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';
    import FieldDatetime from '@/components/lib/controls/FieldDatetime';

    export default {
        name: 'SystemLog',

        components: {
            TemplatePageStandard, Grid,
            FieldDatetime,
        },

        props: ['config'],

        // =============== Data ===============
        data() {
            return {
                isShowActiveOnly: true,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-system') },
                    { text: this.$t('MainMenu.settings-system-errors') }
                ],
                filterData: {
                    timeFrom: null,
                    timeTo: null,
                },
                gridConfig1: {
                    apiUrl: null,
                    readonly: true,
                    columns: [
                        {
                            type:'string', key:'description', header:'Description',
                            $value: entry => (entry.description ? entry.description.replaceAll(',"', ', "') : null),
                        },
                        { type:'number', key:'qty', header:'Qty' },
                    ],
                    order: [{ key:'qty', direction:'desc' }],
                    displayToolbar: true,
                    paginationPageSize: 10,
                    saveSessionState: `${this.$options.name}`,
                },
            }
        },

        // =============== Methods ===============
        methods: {
            setGridConfig() {
                this.gridConfig1.apiUrl = 'system-log-errors';
                let whereArray = [];
                if (this.filterData.timeFrom) whereArray.push([ 'time_of', '>=', this.filterData.timeFrom ]);
                if (this.filterData.timeTo) whereArray.push([ 'time_of', '<=', this.filterData.timeTo ]);

                if (whereArray.length > 0) {
                    this.gridConfig1.filter = { where: whereArray };
                } else {
                    this.gridConfig1.filter = null;
                }

                this.$refs.grid1.refresh();
            },

            filterModified() {
                this.setGridConfig();
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            this.setGridConfig();
        },
    }
</script>

<style>
</style>
