<!--
    Author: Eduard Grebenyukov
    Date: 2020-05-20
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        @process-close="processClose($event)"
    >
        <!-- <template #buttons>
            <ButtonClose @click="closePressed()" />
        </template> -->

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-sm-12 col-xl-6">
                    </div>
                    <div class="col-sm-12 col-xl-6">
                        <!-- Right sidebar -->
                        <StandardRegion :title="$t('component.CashRegisterControl.cashdesk')">
                            <div class="cash-register-control__cashdesk-info__wrapper">
                                <table class="cash-register-control__cashdesk-info__header">
                                    <tr>
                                        <th class="cash-register-control__cashdesk-info__title">{{ $t('attribute.fin_cash.core_workplace_id') + ':' }}</th>
                                        <td class="cash-register-control__cashdesk-info__value">{{ currentUser.shortname }}</td>
                                    </tr>
                                    <tr v-if="!coreDeviceArray || coreDeviceArray.length < 1">
                                        <th class="cash-register-control__cashdesk-info__title">{{ $t('attribute.fin_cash.cash_register_id') + ':' }}</th>
                                        <td class="cash-register-control__cashdesk-info__value error">{{ $t('component.CashRegisterControl.notConnected') }}</td>
                                    </tr>
                                    <tr v-if="coreDeviceArray && coreDeviceArray.length > 0">
                                        <th class="cash-register-control__cashdesk-info__title">
                                            <div style="margin-top:0.3rem;">
                                                {{ $t('attribute.fin_cash.cash_register_id') + ':' }}
                                            </div>
                                        </th>
                                        <td class="cash-register-control__cashdesk-info__value error">
                                            <FieldSelect
                                                labelPosition="before"
                                                :value.sync="editFormData.cash_register_id"
                                                :source="coreDeviceArray"
                                                inputStyle="margin-bottom:-15px;"
                                                :showEmptyRow="true"
                                                @modified="cashRegisterIdSelected()"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="cash-register-control__cashdesk-info__title">{{ $t('attribute.fin_cash.pos_terminal_id') + ':' }}</th>
                                        <td class="cash-register-control__cashdesk-info__value error">{{ $t('component.CashRegisterControl.notConnected') }}</td>
                                    </tr>
                                    <tr><td class="px-2" colspan="2">
                                        <div class="row">
                                            <div class="col">
                                                <button disabled @click.prevent="" class="btn btn-outline-secondary btn-block">{{ $t('component.CashRegisterControl.xReport') }}</button>
                                                <button disabled @click.prevent="" class="btn btn-outline-secondary btn-block">{{ $t('component.CashRegisterControl.printLastCheque') }}</button>
                                                <button disabled @click.prevent="" class="btn btn-outline-secondary btn-block">{{ $t('component.CashRegisterControl.openShift') }}</button>
                                                <button disabled @click.prevent="" class="btn btn-outline-secondary btn-block">{{ $t('component.CashRegisterControl.closeShift') }}</button>
                                                <button disabled @click.prevent="" class="btn btn-outline-secondary btn-block">{{ $t('component.CashRegisterControl.openCashbox') }}</button>
                                                <button disabled @click.prevent="" class="btn btn-outline-secondary btn-block">{{ $t('component.CashRegisterControl.setTime') }}</button>
                                                <button disabled @click.prevent="" class="btn btn-outline-secondary btn-block">{{ $t('component.CashRegisterControl.getServiceInfo') }}</button>
                                                <button disabled @click.prevent="" class="btn btn-outline-secondary btn-block">{{ $t('component.CashRegisterControl.cashIn') }}</button>
                                                <button disabled @click.prevent="" class="btn btn-outline-secondary btn-block">{{ $t('component.CashRegisterControl.cashOut') }}</button>
                                            </div>
                                        </div>
                                    </td></tr>
                                </table>
                            </div>
                        </StandardRegion>
                    </div>
                </div>
            </div>
        </form>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    // import ModalWindow from '@/components/lib/regions/ModalWindow';
    import StandardRegion from '@/components/lib/regions/StandardRegion';
    // import ButtonClose from '@/components/lib/controls/ButtonClose';

    // import FieldString from '@/components/lib/controls/FieldString';
    // import FieldDate from '@/components/lib/controls/FieldDate';
    // import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    // import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    // import FieldCheckbox from '@/components/lib/controls/FieldCheckbox';

    // import TabSwitch from '@/components/lib/controls/TabSwitch';
    // import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'CashRegisterControl',

        components: {
            TemplatePageStandard, StandardRegion,
            FieldSelect,
            // ButtonClose,
            // FieldString, FieldNumber,
            // FieldDate, FieldSelect, FieldSwitch, FieldCheckbox,
            // Grid,
        },

        mixins: [ tools.warningCloseMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.cashdesk') },
                    // { text: this.$t('MainMenu.finances-accounts'), to: '/finances/accounts' },
                    { text: this.$t('component.CashRegisterControl.control'), to: '#' },
                ],
                coreDeviceArray: null,
                editFormData: {
                    cash_register_id: null,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
                selectedCashRegisterId: 'selectedCashRegisterId',
            }),
        },

        // =============== Watch ===============
        watch: {
            selectedCashRegisterId() {
                // console.log(`DEBUG: ${this.$options.name}.watch:cashRegisterIdSelected() this.selectedCashRegisterId=`, this.selectedCashRegisterId);
                this.editFormData.cash_register_id = this.selectedCashRegisterId;
            },
        },

        // =============== Methods ===============
        methods: {
            ...mapActions([
                'selectCashRegisterId',
            ]),

            checkButtons() {
                // if (this.itemId) {
                //     this.buttons = ['save', 'delete', 'close'];
                // } else {
                //     this.buttons = ['save', 'close'];
                // }
            },

            async cashRegisterIdSelected() {
                // console.log(`DEBUG: ${this.$options.name}.cashRegisterIdSelected()`);
                // this.selectedDevice = this.coreDeviceArray.find(item => item.id === this.editFormData.cash_register_id);
                // console.log(`DEBUG: ${this.$options.name}.cashRegisterIdSelected() this.selectedDevice=`, this.selectedDevice);
                this.selectCashRegisterId(this.editFormData.cash_register_id);
            },

            async getData() {
                // console.log(`DEBUG: ${this.$options.name}.getData()`);
                try {
                    this.coreDeviceArray = (await this.authenticatedAxios.get(`${this.baseUrl}/fin-get-cash-registers`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.coreDeviceArray=`, this.coreDeviceArray);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            await this.getData();
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.selectedCashRegisterId=`, this.selectedCashRegisterId);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.selectedCashRegisterId=`, this.selectedCashRegisterId);
            this.editFormData.cash_register_id = this.selectedCashRegisterId;
        },
    }
</script>

<style>
    .cash-register-control__cashdesk-info__wrapper {
        padding-bottom: 1rem;
        /* background-color: rgb(252, 242, 213); */
        /* background-color: #fff8e5; */
    }
    .cash-register-control__cashdesk-info__title {
        padding: .375rem 0.5rem;
        white-space: nowrap;
        vertical-align: top;
        font-size: 0.875rem;
        font-weight: bold;
    }
    .cash-register-control__cashdesk-info__header {
        width: 100%;
    }
    /* .cash-register-control__cashdesk-info__header .buttons {
        text-align: right;
    } */
    .cash-register-control__cashdesk-info__header .cash-register-control__cashdesk-info__title {
        text-align: right;
    }
    .cash-register-control__cashdesk-info__details .cash-register-control__cashdesk-info__title {
        margin-top: 0.5rem;
    }
    .cash-register-control__cashdesk-info__value {
        padding-right: 0.5rem;
        font-size: 0.875rem;
    }
    .cash-register-control__cashdesk-info__value.error {
        color: red;
    }
    .cash-register-control__cashdesk-info__details .cash-register-control__cashdesk-info__value {
        padding-left: 0.5rem;
    }

    .btn.btn-payment {
        margin-top: 25px;
        height:4rem;
        width: 100%;
    }
</style>
