<!--
    Author: Eduard Grebenyukov
    Date: 2020-03-01
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-12">
                        <div class="form-row">
                            <div class="col">
                                <FieldPopupList
                                    :label="$t('object.core_workplace')"
                                    labelPosition="above"
                                    :value.sync="filter.coreWorkplaceId"
                                    :defaultValue="filter.coreWorkplaceIdDefault"
                                    source='v_workplace'
                                    keyLabel='workplace_name'
                                    :listConfig="workplaceListConfig"
                                    :saveState="`${$options.name}.core_workplace_id`"
                                    maxWidth="1500px"
                                    @modified="filterModified"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <Grid ref="worktimeGrid" :config="worktimeGridConfig" @add-item="addItem" @edit-item="editItem($event.id)" />
                    </div>
                </div>
            </div>
        </form>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import FieldPopupList from '@/components/lib/controls/FieldPopupList';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'Worktime',

        components: {
            TemplatePageStandard, Grid,
            FieldPopupList, 
        },
        data() {
            return {
                buttons: [],
                filter: {
                    coreWorkplaceId: null,
                    coreWorkplaceIdDefault: null,
                },
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    { text: this.$t('MainMenu.settings-visits-worktime'), to: '/settings/visits/worktimes' },
                ],
                workplaceListConfig: {
                    filter: { where:[ {is_schedule:true}, {is_active:true} ]},
                    readonly: true,
                    columns: [
                        // { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        { type:'string', key:'dept_name', header:this.$t('object.core_dept') },
                        { type:'string', key:'speciality_name', header:this.$t('object.core_speciality') },
                        { type:'string', key:'user_name', header:this.$t('object.core_user') },
                        { type:'string', key:'room_name', header:this.$t('object.core_room') },
                        { type:'string', key:'device_name', header:this.$t('object.core_device') },
                     ],
                    order: [ 'name' ],
                    displayToolbar: true,
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                evenOdd: 'evenOdd',
            }),
            apiUrl() { return `${this.baseUrl}/v_workplace` },
            worktimeGridConfig() {
                // computed, not static because of evenOdd delay
                return {
                    apiUrl: 'visit_worktime',
                    filter: null,
                    readonly: true,
                    // core_workplace_id: null,
                    // title: this.$t('MainMenu.settings-visits-worktime'),
                    columns: [
                        { type:'hidden', key:'id', header:'id', readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'core_workplace_id', header:'Workplace', required: true },
                        { type:'select', key:'visit_lock_type_id', header:this.$t('object.visit_lock_type'), source:'visit_lock_type' },
                        { type:'date', key:'date_from', header:this.$t('attribute.common.date_from'), required:true },
                        { type:'date', key:'date_to', header:this.$t('attribute.common.date_to') },
                        { type:'string', key:'time_from', header:this.$t('attribute.common.time_from'), required:true },
                        { type:'string', key:'time_to', header:this.$t('attribute.common.time_to'), required:true },
                        { type:'number', key:'duration', header:this.$t('Settings.interval') },
                        { type:'html', key:'weekdays', header:this.$t('app.weekdays'), $value:this.getWeekdaysString },
                        { type:'select', key:'day_even', header:(this.$t('app.even') + ' / ' + this.$t('app.odd')), source:this.evenOdd },
                    ],
                    buttons: [ 'row-selector', 'add', 'edit', 'delete' ],
                    order: [ 'id' ],
                    displayToolbar: true,
                    displaySearch: false,
                    // saveSessionState: `${this.$options.name}`,
                }
            },
        },

        methods: {
            getWeekdaysString(entry) {
                let aDays = [];
                if (entry.is_monday) aDays.push(this.$t('app.monday_short'));
                if (entry.is_tuesday) aDays.push(this.$t('app.tuesday_short'));
                if (entry.is_wednesday) aDays.push(this.$t('app.wednesday_short'));
                if (entry.is_thursday) aDays.push(this.$t('app.thursday_short'));
                if (entry.is_friday) aDays.push(this.$t('app.friday_short'));
                if (entry.is_saturday) aDays.push(this.$t('app.saturday_short'));
                if (entry.is_sunday) aDays.push(this.$t('app.sunday_short'));

                return aDays.join(', ');
            },

            getEvenOddArray() { return this.evenOdd },

            setGridFilter() {
                this.worktimeGridConfig.filter = { where:[
                    { core_workplace_id:this.filter.coreWorkplaceId },
                ]};
                this.worktimeGridConfig.columns.find(x => x.key === 'core_workplace_id').defaultValue = this.filter.coreWorkplaceId;
                this.$refs.worktimeGrid.refresh();
                // console.log(`DEBUG: ${this.$options.name}.setGridFilter() this.worktimeGridConfig.filter=`, this.worktimeGridConfig.filter);
            },

            filterModified() {
                // console.log(`DEBUG: ${this.$options.name}.filterModified() this.filter.coreWorkplaceId=`, this.filter.coreWorkplaceId);
                this.setGridFilter();
            },

            addItem() {
                this.$router.push({ name: 'settings/visits/worktime-new', params: { id: null, core_workplace_id: this.filter.coreWorkplaceId } });
            },
            editItem(id) {
                // console.log(`DEBUG: ${this.$options.name}.editItem(${id})`);
                this.$router.push({ name: 'settings/visits/worktime-edit', params: { id, core_workplace_id: this.filter.coreWorkplaceId } });
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.filter.coreWorkplaceId=`, this.filter.coreWorkplaceId);
            try {
                this.filter.coreWorkplaceIdDefault = (await this.authenticatedAxios.get(`${this.apiUrl}`)).data.rows[0].id;
            } catch (err) {
                tools.displayApiError(this, err);
            }
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.evenOdd=`, this.evenOdd);
            // this.evenOddArray = this.evenOdd;
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.filter.coreWorkplaceId=`, this.filter.coreWorkplaceId);
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.$refs.coreWorkplaceId=`, this.$refs.coreWorkplaceId);
            // if (!this.filter.coreWorkplaceId) {
            //     try {
            //     this.filter.coreWorkplaceId = (await this.authenticatedAxios.get(`${this.apiUrl}`)).data.rows[0].id;
            //     this.filterModified();
            // }
        },
    }
</script>>

<style scoped>

</style>>