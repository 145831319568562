<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-23
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >

        <template #buttons>
            <ButtonClose v-if="buttons.includes('close')" @click="closePressed()" />
            <ButtonSave v-if="buttons.includes('save')" @click="saveData()" />
            <ButtonDelete v-if="buttons.includes('delete')" @click="askDeleteItem()" />
        </template>

        <form>
            <div id="page-content" class="form-float">
                <div class="row">
                    <div class="col-sm-12 col-xl-9">
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldString
                                    :label="$t('attribute.common.name')"
                                    :value.sync="editFormData.name"
                                    required 
                                    @modified="itemModified('name', true)"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldDate
                                    :label="$t('attribute.common.date_from')"
                                    :value.sync="editFormData.date_from"
                                    required
                                    @modified="itemModified('date_from', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldDate
                                    :label="$t('attribute.common.date_to')"
                                    :value.sync="editFormData.date_to"
                                    @modified="itemModified('date_to', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldDate
                                    :label="$t('attribute.common.date_cancel')"
                                    :value.sync="editFormData.date_cancel"
                                    @modified="itemModified('date_cancel', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldSelect
                                    :label="$t('object.fin_pricelist')"
                                    :value.sync="editFormData.fin_pricelist_id"
                                    source="fin_pricelist"
                                    required
                                    @modified="itemModified('fin_pricelist_id', true)"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('attribute.fin_program.program_limit_sum')"
                                    :value.sync="editFormData.program_limit_sum"
                                    @modified="itemModified('program_limit_sum', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('attribute.fin_program.client_limit_sum')"
                                    :value.sync="editFormData.client_limit_sum"
                                    @modified="itemModified('client_limit_sum', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldNumber
                                    :label="$t('attribute.fin_program.franchise_service_sum')"
                                    :value.sync="editFormData.franchise_service_sum"
                                    @modified="itemModified('franchise_service_sum', true)"
                                />
                            </div>
                            <div class="col-sm">
                                <FieldSwitch
                                    id="is_exceeding_to_client"
                                    :label="$t('attribute.fin_program.is_exceeding_to_client')"
                                    :value.sync="editFormData.is_exceeding_to_client"
                                    @modified="itemModified('is_exceeding_to_client', true)"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-3">
                        <!-- Right sidebar -->
                        <StandardRegion :title="$t('object.fin_contract')">
                            <div class="contract_details__wrapper">
                            <!-- <div class="container-fluid"> -->
                                <!-- id:{{ visitId }} -->
                                <table class="contract_details__header">
                                    <tr>
                                        <th class="contract_details__title">{{ $t('attribute.common.name') + ':' }}</th>
                                        <td class="contract_details__value">{{ contractData.name }}</td>
                                    </tr>
                                    <tr>
                                        <th class="contract_details__title">{{ $t('attribute.common.date_from') + ':' }}</th>
                                        <FieldDate id='date_from' :value.sync="contractData.date_from" textClass="contract_details__value" displayonly labelPosition="none" />
                                    </tr>
                                    <tr>
                                        <th class="contract_details__title">{{ $t('attribute.common.date_to') + ':' }}</th>
                                        <FieldDate id='date_to' :value.sync="contractData.date_to" textClass="contract_details__value" displayonly labelPosition="none" />
                                    </tr>
                                    <tr>
                                        <th class="contract_details__title">{{ $t('attribute.fin_contract.is_deposit') + ':' }}</th>
                                        <!-- <td class="contract_details__value">{{ contractData.is_deposit }}</td> -->
                                        <td class="contract_details__value">
                                            <FieldCheckbox id='is_deposit' :value.sync="contractData.is_deposit" textClass="contract_details__value" displayonly labelPosition="none" />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </StandardRegion>
                    </div>
                </div>
                <div class="row">
                     <div class="col-12">
                        <!-- <div class="form-row" v-if="false">
                            <div class="col">
                                <TabSwitch
                                    :value.sync="currentTab"
                                    defaultValue="payers"
                                    @modified="filterTabsModified"
                                    :source="navigationTabs"
                                />
                            </div>
                        </div> -->
                        <div class="form-row">
                            <div class="col">
                                <Grid ref="payerGrid" v-if="isDisplayGrids" :config="payerGridConfig" @modified="itemModified('payer', $event)" />
                                <Grid ref="setGrid" v-if="isDisplayGrids" :config="setGridConfig" @modified="itemModified('set', $event)" />
                                <Grid ref="serviceGrid" v-if="isDisplayGrids" :config="serviceGridConfig" @modified="itemModified('service', $event)" />
                                <Grid ref="discountGrid" v-if="isDisplayGrids" :config="discountGridConfig" @modified="itemModified('discount', $event)" />
                                <Grid ref="beneficiaryGrid" v-if="isDisplayGrids" :config="beneficiaryGridConfig" @modified="itemModified('beneficiary', $event)" />
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </form>

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import { required } from 'vuelidate/lib/validators';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import StandardRegion from '@/components/lib/regions/StandardRegion';

    import ButtonClose from '@/components/lib/controls/ButtonClose';
    import ButtonSave from '@/components/lib/controls/ButtonSave';
    import ButtonDelete from '@/components/lib/controls/ButtonDelete';

    import FieldString from '@/components/lib/controls/FieldString';
    import FieldDate from '@/components/lib/controls/FieldDate';
    import FieldNumber from '@/components/lib/controls/FieldNumber';
    import FieldSelect from '@/components/lib/controls/FieldSelect';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import FieldCheckbox from '@/components/lib/controls/FieldCheckbox';

    // import TabSwitch from '@/components/lib/controls/TabSwitch';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ProgramEdit',

        components: {
            TemplatePageStandard, StandardRegion,
            ButtonClose, ButtonSave, ButtonDelete,
            FieldString, FieldDate, FieldNumber, FieldSelect, FieldSwitch, FieldCheckbox,
            Grid,/* TabSwitch, */ 
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Props ===============
        // props: {
        //     contractData: Object,
        // },

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                contractId: undefined,
                // isModified: false,
                isItemModified: false,
                isPayerModified: false,
                isSetModified: false,
                isServiceModified: false,
                isDiscountModified: false,
                isBeneficiaryModified: false,
                buttons: [],
                msgItemModified: '',
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.finances') },
                    { text: this.$t('object.fin_contract'), to: '#' },
                    // { text: this.$t('objects.fin_program'), to: `/finances/clients` },
                    { text: this.$t('object.fin_program') },
                ],
                // navigationTabs: [
                //     { id: 'payers', name: this.$t('component.ProgramEdit.payers') },
                //     { id: 'clients', name: this.$t('component.ProgramEdit.clients') },
                //     // { id: 'subdocuments', name: this.$t('component.ProgramEdit.subdocuments') },
                // ],
                currentTab: 'payers',
                editFormData: {
                    // id: null,
                    fin_contract_id: null,
                    name: null,
                    date_from: null,
                    date_to: null,
                    date_cancel: null,
                    program_limit_sum: null,
                    client_limit_sum: null,
                    franchise_service_sum: null,
                    fin_pricelist_id: null,
                    is_exceeding_to_client: false,
                },
                oldFormData: {},
                payerGridConfig: null,
                setGridConfig: {
                    apiUrl: `fin_program_set`,
                    filter: null,
                    title: this.$t('component.ProgramEdit.serviceSets'),
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'fin_program_id', required:true },
                        { type:'select', key:'fin_set_id', header:this.$t('attribute.fin_program_set.fin_set_id'), source:`fin_set`, required:true },
                        { type:'date', key:'date_from', header:this.$t('attribute.common.date_from'), required:true },
                        { type:'date', key:'date_to', header:this.$t('attribute.common.date_to') },
                        { type:'select', key:'include_type_id', header:this.$t('attribute.fin_program_set.include_type_id'), required:true, source:'enum/service_include_type' },
                    ],
                    order: [ 'id' ],
                    // displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                },
                serviceGridConfig: {
                    apiUrl: `fin_program_service`,
                    filter: null,
                    title: this.$t('component.ProgramEdit.services'),
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'fin_program_id', required:true },
                        {
                            type:'popuplist', key:'fin_service_id', header:this.$t('attribute.fin_program_service.fin_service_id'),
                            source:'fin_service', required:true,
                            listConfig: {
                                readonly:true,
                                columns: [
                                    { type:'string', key:'code', header:this.$t('attribute.common.code'), width:'100px' },
                                    { type:'string', key:'name', header:this.$t('attribute.common.name') },
                                ],
                                order: [ 'name' ],
                            },
                        },
                        { type:'date', key:'date_from', header:this.$t('attribute.common.date_from') },
                        { type:'date', key:'date_to', header:this.$t('attribute.common.date_to') },
                        { type:'select', key:'include_type_id', header:this.$t('attribute.fin_program_service.include_type_id'), required:true, source:'enum/service_include_type' },
                        { type:'number', key:'program_limit_qty', header:this.$t('attribute.fin_program_service.program_limit_qty') },
                        { type:'number', key:'beneficiary_limit_qty', header:this.$t('attribute.fin_program_service.beneficiary_limit_qty') },
                    ],
                    order: [ 'id' ],
                    // displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                },
                discountGridConfig: {
                    apiUrl: `fin_program_discount`,
                    filter: null,
                    title: this.$t('component.ProgramEdit.discounts'),
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'fin_program_id', required:true },
                        { type:'select', key:'fin_discount_id', header:this.$t('attribute.fin_program_discount.fin_discount_id'), source:'fin_discount', required:true },
                    ],
                    order: [ 'id' ],
                    // displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                },
                beneficiaryGridConfig: {
                    apiUrl: `fin_beneficiary`,
                    filter: null,
                    title: this.$t('component.ProgramEdit.clients'),
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                        { type:'hidden', key:'fin_program_id', required:true },
                        {
                            type:'popuplist', key:'core_client_id', header:this.$t('object.core_client'),
                            source:'v_client', keyLabel:'fullname', required:true,
                            listConfig: {
                                readonly:true,
                                columns: [
                                    { type:'string', key:'code', header:this.$t('attribute.common.code'), width:'50px' },
                                    { type:'string', key:'fullname', header:this.$t('object.core_client') },
                                    { type:'date', key:'dob', header:this.$t('attribute.core_person.dob') },
                                ],
                                order: [ 'fullname' ],
                            },
                        },
                        { type:'date', key:'date_from', header:this.$t('attribute.common.date_from') },
                        { type:'date', key:'date_to', header:this.$t('attribute.common.date_to') },
                        // { type:'date', key:'date_cancel', header:this.$t('attribute.common.date_cancel') },
                        { type:'string', key:'beneficiary_num', header:this.$t('attribute.fin_beneficiary.beneficiary_num') },
                        { type:'number', key:'beneficiary_limit_sum', header:this.$t('attribute.fin_beneficiary.beneficiary_limit_sum') },
                    ],
                    order: [ 'id' ],
                    // displaySearch: false,
                    buttons: [ 'row-selector', 'add', 'delete' ],
                    // $onAdd: (grid) => {  },
                    // $onEdit: (grid, item) => { this.editProgram(item.id) },
                },
                contractData: {},
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
            isDisplayGrids() { return typeof this.editFormData.id !='undefined' },
        },

        // =============== Watch ===============
        watch: {
            contractId() {
                if (this.contractId) {
                    this.payerGridConfig = {
                        apiUrl: `fin_payer`,
                        filter: null,
                        title: this.$t('component.ProgramEdit.payers'),
                        // readonly: true,
                        columns: [
                            { type:'hidden', key:'id', header:this.$t('attribute.common.id'), readonly:true, align:'right', width:'100px' },
                            { type:'hidden', key:'fin_program_id', header:'fin_program_id', required:true },
                            // { type:'select', key:'fin_account_id', header:this.$t('attribute.fin_payer.fin_account_id'), source:`fin_contract/${this.contractId}/accounts`, keyLabel:'account_name', required:true },
                            {
                                type:'select',
                                key:'fin_account_id',
                                header:this.$t('attribute.fin_payer.fin_account_id'),
                                source:`v_contract_account${tools.getFilterString({ where:[{ fin_contract_id:this.contractId }]})}`,
                                keyLabel:'account_name',
                                required:true
                            },
                            { type:'number', key:'pct_value', header:this.$t('attribute.common.pct_value'), defaultValue:"100", required:true },
                        ],
                        order: [ 'id' ],
                        // displaySearch: false,
                        buttons: [ 'row-selector', 'add', 'delete' ],
                        // $onAdd: (grid) => {},
                        // $onEdit: (grid, item) => { editPayer(item.id) },
                    }
                } else {
                    this.payerGridConfig = null;
                }
            }
        },

        // =============== Methods ===============
        methods: {
            checkButtons() {
                if (this.itemId) {
                    this.buttons = ['save', 'delete', 'close'];
                } else {
                    this.buttons = ['save', 'close'];
                }
            },

            // filterTabsModified() {

            // },

            setBreadcrumb() {
                // console.log(`DEBUG: ${this.$options.name}.setBreadcrumb core_workplace_id=`, this.$refs.core_workplace_id);
                // console.log(`DEBUG: core_workplace_id.itemPresentation=`, this.$refs.core_workplace_id.itemPresentation);
                // console.log(`DEBUG: core_workplace_id.getPresentation()=`, this.$refs.core_workplace_id.getPresentation());
                this.breadcrumb[this.breadcrumb.length - 2].to = `/finances/contract/${this.contractId}`;
            },

            checkModified() {
                this.isItemModified = JSON.stringify(this.oldFormData) !== JSON.stringify(this.editFormData);
                this.isModified = (
                    this.isItemModified ||
                    this.isPayerModified ||
                    this.isSetModified ||
                    this.isServiceModified ||
                    this.isDiscountModified ||
                    this.isBeneficiaryModified
                );
            },

            gotData() {
                // this.isModified = false;
                this.isItemModified = false;
                this.msgItemModified = '';
                this.itemId = this.editFormData.id;

                let filter = { where:[
                    { fin_program_id:this.editFormData.id },
                ]};
                this.payerGridConfig.filter = filter;
                this.setGridConfig.filter = filter;
                this.serviceGridConfig.filter = filter;
                this.discountGridConfig.filter = filter;
                this.beneficiaryGridConfig.filter = filter;
                // !!! why its works without .refresh()???

                this.payerGridConfig.columns.find(x => x.key === 'fin_program_id').defaultValue = this.editFormData.id;
                this.setGridConfig.columns.find(x => x.key === 'fin_program_id').defaultValue = this.editFormData.id;
                this.serviceGridConfig.columns.find(x => x.key === 'fin_program_id').defaultValue = this.editFormData.id;
                this.discountGridConfig.columns.find(x => x.key === 'fin_program_id').defaultValue = this.editFormData.id;
                this.beneficiaryGridConfig.columns.find(x => x.key === 'fin_program_id').defaultValue = this.editFormData.id;

                // this.beneficiaryGridConfig.columns.find(x => x.key === 'fin_contract_id').defaultValue = this.editFormData.id;
                // console.log(`DEBUG: ${this.$options.name}.gotData fin_program_id=`, this.payerGridConfig.columns.find(x => x.key === 'fin_program_id'));
                // this.$refs.payerGrid.refresh();
                // this.$refs.clientsGrid.refresh();

                this.setBreadcrumb();
                this.checkButtons();
            },

            async getContract() {
                try {
                    this.contractData = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_contract/${this.contractId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getConract this.contractData=`, this.contractData);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async getData() {
                // console.log(`DEBUG: ${this.$options.name}.getData this.workplace=`, this.workplace);
                try {
                    let data = (await this.authenticatedAxios.get(`${this.baseUrl}/fin_program/${this.itemId}`)).data;
                    if (data) {
                        this.editFormData = data;
                        this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                    }
                    // console.log(`DEBUG: ${this.$options.name}.getData data=`, this.editFormData);
                    this.gotData();
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async saveData() {
                // console.log(`DEBUG: ${this.$options.name}.saveData() this.isModified=${this.isModified}, this.editFormData=`, this.editFormData);
                if (this.isItemModified) {
                    //this.$v.$touch();
                    //if (!this.$v.$invalid) {
                    try {
                        if (typeof this.editFormData.id != 'undefined') {
                            // Update
                            // console.log(`DEBUG: ${this.$options.name}.saveData()Update this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.put(`${this.baseUrl}/fin_program/${this.editFormData.id}`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;                                
                                this.gotData();
                                this.oldFormData = JSON.parse(JSON.stringify(this.editFormData)); // to prevent question
                                this.checkModified();
                                tools.displaySuccessSave(this);
                            }
                        } else {
                            // New record
                            // console.log(`DEBUG: ${this.$options.name}.saveData() New this.editFormData=`, this.editFormData);
                            let data = (await this.authenticatedAxios.post(`${this.baseUrl}/fin_program`, this.editFormData)).data;
                            if (data) {
                                this.editFormData = data;
                                // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                                this.gotData();
                                // this.getData();
                                tools.displaySuccessSave(this);
                            }
                        }
                        this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                    // console.log(`DEBUG: ${this.$options.name}.saveData() this.editFormData=`, this.editFormData);
                } else {
                    // no changes
                }
                if (this.isDisplayGrids) {
                    if (this.$refs.payerGrid) {
                        this.$refs.payerGrid.$emit('save');
                        this.isPayerModified = false;
                    }
                    if (this.$refs.setGrid) {
                        this.$refs.setGrid.$emit('save');
                        this.isSetModified = false;
                    }
                    if (this.$refs.serviceGrid) {
                        this.$refs.serviceGrid.$emit('save');
                        this.isServiceModified = false;
                    }
                    if (this.$refs.discountGrid) {
                        this.$refs.discountGrid.$emit('save');
                        this.isDiscountModified = false;
                    }
                    if (this.$refs.beneficiaryGrid) {
                        this.$refs.beneficiaryGrid.$emit('save');
                        this.isBeneficiaryModified = false;
                    }
                }
                this.checkModified();
            },

            itemModified(item, isTrue) {
                if (isTrue) {
                    if (item == 'payer') this.isPayerModified = true;
                    if (item == 'set') this.isSetModified = true;
                    if (item == 'service') this.isServiceModified = true;
                    if (item == 'discount') this.isDiscountModified = true;
                    if (item == 'beneficiary') this.isBeneficiaryModified = true;
                }
                // console.log(`DEBUG: ${this.$options.name}.itemModified() this.isModified=${this.isModified}, item=${item}`);
                this.checkModified();
                if (this.isModified) {
                    this.msgItemModified = this.$t('app.itemModified');
                } else {
                    this.msgItemModified = '';
                }
                this.setBreadcrumb();
            },

            async processDeleteItem(doDelete) {
                if (doDelete) {
                    try {
                        await this.authenticatedAxios.delete(`${this.baseUrl}/fin_program/${this.itemId}`);
                        this.isItemModified = false;
                        tools.displaySuccessDelete(this.$parent);
                        this.$router.go(-1);
                    } catch (err) {
                        tools.displayApiError(this, err);
                    }
                } else {
                    this.isDisplayWarningDelete = false;
                }
            },

        },

        // =============== Life cycle ===============
        async beforeMount() {
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() params=`, this.$route.params);
            // console.log(`DEBUG: ${this.$options.name}.beforeMount() this.$route=`, this.$route);
            this.itemId = parseInt(this.$route.params.programId);
            this.contractId = parseInt(this.$route.params.contractId);
            this.editFormData.fin_contract_id = this.contractId;
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.contractId=${this.contractId}`);
            if (this.contractId) await this.getContract();
            if (this.itemId) {
                await this.getData();
            } else {
                this.editFormData.name = this.contractData.name;
                this.editFormData.date_from = this.contractData.date_from;
                // console.log(`DEBUG: ${this.$options.name}.mounted() this.editFormData.name=${this.editFormData.name}, this.contractData=`, this.contractData);
                this.setBreadcrumb();
                this.checkButtons();
            }
            this.oldFormData = JSON.parse(JSON.stringify(this.editFormData));
            this.checkModified();
        },
    }
</script>

<style>
    .contract_details__wrapper {
        padding-bottom: 1rem;
        /* background-color: rgb(252, 242, 213); */
        /* background-color: #fff8e5; */
    }
    .contract_details__title {
        padding: .375rem 0.5rem;
        white-space: nowrap;
        vertical-align: top;
        font-size: 0.875rem;
        font-weight: bold;
    }
    .contract_details__header .contract_details__title {
        text-align: right;
    }
    .contract_details__details .contract_details__title {
        margin-top: 0.5rem;
    }
    .contract_details__value {
        padding-right: 0.5rem;
        font-size: 0.875rem;
    }
    .contract_details__details .contract_details__value {
        padding-left: 0.5rem;
    }
</style>
