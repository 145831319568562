<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-11
-->

<template>
    <div :class="'region-standard ' + (regionClass ? regionClass : '')" :style="(regionStyle ? regionStyle : '')" >
        <div class="region-standard__header">
            <!-- <slot name="header"></slot> -->
            <div class="region-standard__extra-buttons">
                <h6 class="region-standard__title"><slot name="title">{{title}}</slot></h6>
                <slot name="header"></slot>
            </div>
        </div>
        <div class="region-standard__body" :class="(regionBodyClass ? regionBodyClass : '')" :style="(regionBodyStyle ? regionBodyStyle : '')">
            <!-- <div class="container-fluid"> -->
            <slot></slot>
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StandardRegion',

        props: {
            title: String,
            regionClass: String,
            regionStyle: String,
            regionBodyClass: String,
            regionBodyStyle: String,
        }
    }
</script>>

<style>
    .region-standard {
        border: 1px solid #dee2e6;
        margin-bottom: 1rem;
    }

    .region-standard__header {
        background-color: #e9ecef;
    }

    .region-standard__header button {
        margin: -4px 0 -4px 0;
    }

    .region-standard__title {
        margin-bottom: 0;
        line-height: 1.5;
        padding: 0 8px;
    }

    .region-standard__extra-buttons {
        display: flex;
        padding: 8px 0;
    }

    .region-standard__body {
        padding: 0;
    }
</style>
