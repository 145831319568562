<!--
    Author: Eduard Grebenyukov
    Date: 2021-05-16
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">

        <VisitDescription />

        <VisitTabs currentTab="visit-files" />

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col">
                    <Grid
                        ref="files"
                        :key="$route.fullPath"
                        :config="gridConfig"
                        @edit-item="editFile($event)"
                        @download="downloadFile($event)"
                        @view="viewFile($event)"
                    />
                </div>
            </div>
        </div>

        <!-- <iframe :src="fileContent"></iframe> -->

    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import VisitDescription from '@/components/pages/visits/VisitDescription';
    import VisitTabs from '@/components/pages/visits/VisitTabs';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'VisitFiles',
        components: {
            TemplatePageStandard, VisitDescription, VisitTabs, Grid,
        },

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                visitId: null,
                isDisplayVisitAdd: false,
                breadcrumb: tools.getEhrBreadcrumb(this, { text:this.$t('VisitMenu.files') }),
                gridConfig: {
                    apiUrl:null,
                    readonly: true,
                    columns: [
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'50px' },
                        { type:'select', key:'core_rubric_id', header:this.$t('object.core_rubric'), source:'core_rubric' },
                        // { type:'datetime', key:'filetime', header:this.$t('attribute.common.datetime_of') },
                        { type:'string', key:'filename', header:this.$t('attribute.common.filename') },
                        { type:'string', key:'description', header:this.$t('attribute.common.description') },
                        { type:'icon', key:'file-view', header:this.$t('app.view'), icon:'fas fa-eye', align:'center', style:'color: #056bbf;', sortable:false, emit:'view' },
                        { type:'icon', key:'file-download', header:this.$t('app.download'), icon:'fas fa-download', align:'center', style:'color: #056bbf;', sortable:false, emit:'download' },
                    ],
                    order: [ 'filetime' ],
                    displayToolbar: true,
                    buttons: [ 'add', 'edit' ],
                    saveSessionState: `${this.$options.name}`,
                    $onAdd: (grid) => { grid.$router.push({ name: 'visit-file-new' }) },
                    $onEdit: (grid, item) => { grid.$router.push({ name: 'visit-file-edit', params: { clientId:this.clientId, visitId:this.visitId, id: item.id } }) },
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                lastClient: 'lastClient',
            }),
        },

        // =============== Methods ===============
        methods: {
            async editFile(file) {
                // console.log(`DEBUG: ${this.$options.name}.selectVisit file=`, file);
                if (!file) return;
            },

            async viewFile(file) {
                try {
                    let response = await this.authenticatedAxios.get(`${this.baseUrl}/core_file/${file.id}/file`, { responseType: 'arraybuffer' });
                    let fileContent = new Blob([response.data], { type: file.mimetype });
                    let url = window.URL.createObjectURL(fileContent);
                    window.open(url, '_blank');
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async downloadFile(file) {
                try {
                    this.authenticatedAxios.get(`${this.baseUrl}/core_file/${file.id}/file`, { responseType: 'arraybuffer' }).then( response => {
                        let fileContent = new Blob([response.data], { type: file.mimetype });
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(fileContent);
                        link.download = file.filename;
                        link.click();
                    });
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

        },

        // =============== Life cycle ===============
        beforeMount() {
            this.clientId = parseInt(this.$route.params.clientId);
            this.visitId = parseInt(this.$route.params.visitId);
        },

        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.$router=`, this.$router);
            // console.log(`DEBUG: ${this.$options.name}.mounted() this.visitId=`, this.visitId);
            if (this.clientId && this.visitId) {
                this.gridConfig.filter = {
                    where: [
                        { core_client_id: this.clientId },
                        { visit_rec_id: this.visitId },
                    ]
                };
                this.gridConfig.apiUrl = `core_file`;
            }
        },
    }
</script>

<style>

</style>
