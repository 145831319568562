<!--
    Author: Eduard Grebenyukov
    Date: 2020-09-27
-->

<template>
    <button @click.prevent="$emit('click')" :disabled="disabled" class="btn btn-primary btn-sm ml-1">
        <slot>
            <i class="fas fa-save"></i> {{ $t('lib.buttons.save') }}
        </slot>
    </button>
</template>

<script>
    export default {
        name: 'ButtonSave',

        // =============== Props ===============
        props: {
            disabled: Boolean,
        },
    }
</script>
