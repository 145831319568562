<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-06
-->

<template>
    <div>
            <!-- v-if="taskArray.length > 0"
            :source="taskArray" -->
        <Grid
            ref="taskGrid"
            :config="taskGridConfig"
            @refreshed="gridRefreshed()"
            @edit-item="editTask($event)"
        />
        <TaskContextMenu ref="taskContextMenu" @refresh-status="getData()" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    // import moment from 'moment';

    import tools from '@/components/lib/tools';
    import Grid from '@/components/lib/regions/Grid';
    import TaskContextMenu from '@/components/pages/tasks/TaskContextMenu';

    export default {
        name: 'UserTasks',

        components: { Grid, TaskContextMenu },

        // =============== Data ===============
        data() {
            return {
                // taskArray: [],
                taskGridConfig: {
                    apiUrl: null,
                    filter: null,
                    title:this.$t('objects.task_rec'),
                    readonly: true,
                    columns: [
                        {
                            type:'icon', key:'icon', sortable:false, align:'center',
                            $title:(entry) => tools.getTaskStatusText(entry),
                            $icon: entry => tools.getTaskIcon(entry),
                            $cellStyle: entry => `color:${tools.getTaskColor(entry)};`,
                        },
                        { type:'number', key:'id', header:this.$t('attribute.common.id'), sortable:false },
                        // { type:'select', key:'task_type_id', header:this.$t('enum.task_type'), source:'enum/task_type' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name'), sortable:false },
                        { type:'string', key:'status_name', header:this.$t('attribute.common.status'), sortable:false },
                        { type:'string', key:'performer_name', header:this.$t('attribute.common.performer'), sortable:false },
                        // {
                        //     type:'icon', key:'action', header:this.$t('app.action'), style:'color:#4285f4;',
                        //     title:this.$t('component.Tasks.startTask'),
                        //     $icon:(entry) => { if (tools.isTaskRunnable(entry, this.currentUser)) return 'fas fa-play' },
                        //     $onClick: (entry) => { if (tools.isTaskRunnable(entry, this.currentUser)) this.runTask(entry) },
                        // },
                        { type:'hidden', key:'time_start', header:this.$t('attribute.common.time_start') },
                        // { type:'datetime', key:'time_end', header:this.$t('attribute.common.time_end') },
                    ],
                    row: {
                        $onRightClick: (task, evt) => {
                            // console.log(`DEBUG: ${this.$options.name}.config.row.$onRightClick visit=`, visit);
                            // console.log(`DEBUG: ${this.$options.name}.config.row.$onRightClick evt=`, evt);
                            this.showContextMenu(evt, task);
                        },
                    },
                    paginationPageSize: 10,
                    order: [{ key:'time_start' }],
                    // displayToolbar: false,
                    buttons: ['edit'],
                },
            }
        },

        // =============== Watch ===============
        watch: {
            currentUser() {
                this.setGridSettings();
            },
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                currentUser: 'currentUser',
            }),
        },

        // =============== Methods ===============
        methods: {
            editTask(task) {
                this.$router.push({ name: 'tasks/task-edit', params: { taskId:task.id } });
            },

            setGridSettings() {
                if (this.currentUser && this.currentUser.currentWorkplace.id) {
                    this.taskGridConfig.apiUrl = `task-runnable/${this.currentUser.currentWorkplace.id}`;
                } else {
                    this.taskGridConfig.apiUrl = null;
                }
            },

            gridRefreshed() {
                const taskQty = this.$refs.taskGrid.getTotalRowsQty();
                const searchString = this.$refs.taskGrid.searchString;
                // console.log(`DEBUG: ${this.$options.name}.gridRefreshed() searchString=`, searchString);
                this.$emit('refreshed', taskQty > 0 || searchString);
            },

            // async getData() {
            //     if (this.currentUser.currentWorkplace) {
            //         try {
            //             this.taskArray = (await this.authenticatedAxios.get(`${this.baseUrl}/task-runnable/${this.currentUser.currentWorkplace.id}`)).data.rows;
            //             this.$emit('modified', this.taskArray.length);
            //         } catch (err) {
            //             tools.displayApiError(this, err);
            //         }
            //     }
            // },

            showContextMenu(evt, task) {
                this.$refs.taskContextMenu.show(evt, task);
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            // this.getData();
            this.setGridSettings();
        }
    }
</script>

<style>
</style>
