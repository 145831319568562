<!--
    Author: Eduard Grebenyukov
    Date: 2020-01-07
-->

<template>
    <TemplatePageStandard :config="config">
        <div id="page-content">
            <Grid
                :key="$route.fullPath"
                :config="config.gridConfig"
                @modified="gridModified($event)"
                @add-item="addItem"
                @edit-item="editItem($event.id)"
            />
        </div>
    </TemplatePageStandard>
</template>

<script>
    import $ from 'jquery';

    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'GridEdit',

        components: {
            TemplatePageStandard, Grid,
        },
        props: ['config'],

        // =============== Data ===============
        data() {
            return {
                isModified: Boolean,
                id: Number,
            }
        },

        // =============== Watch ===============
        watch: {
            isModified() {
                if (this.isModified) {
                    $('#breadcrumb').addClass('modified');
                } else {
                    $('#breadcrumb').removeClass('modified');
                }
            }
        },

        // =============== Methods ===============
        methods: {
            gridModified(e) {
                this.isModified = e;
            },

            addItem() {
                this.$router.push({ name: this.config.gridConfig.onAdd, params: { id: null } });
            },

            editItem(id) {
                // console.log(`DEBUG: ${this.$options.name}.editItem(${id})`);
                this.$router.push({ name: this.config.gridConfig.onEdit, params: { id } });
            },
        },
    }
</script>

<style>
</style>
