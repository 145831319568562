<!--
    Author: Eduard Grebenyukov
    Date: Date: 2020-05-20
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">
        <div id="page-content">
            <Grid ref="grid" :key="$route.fullPath" :config="gridConfig" @edit-item="payerSelected($event)" >
                <template #buttons>
                    <FieldSwitch
                        id="isShowDebtOnly"
                        :label="$t('component.Payers.onlyDebt')"
                        labelPosition="after"
                        size="small"
                        :value.sync="isShowDebtOnly"
                        :defaultValue="true"
                        :saveSessionState="`${$options.name}-isShowDebtOnly`"
                    />
                    <!-- <div class="custom-control custom-switch" style="padding-top:6px;">
                        <input type="checkbox" class="custom-control-input" id="is_only_debt" v-model="isShowDebtOnly" >
                        <label class="custom-control-label" for="is_only_debt">{{ $t('component.Payers.onlyDebt') }}</label>
                    </div> -->
                    <!-- <div slot="buttons" style="padding-top:4px;">
                        <b-form-checkbox v-model="isShowDebtOnly" inline >{{ $t('component.Payers.onlyDebt') }}</b-form-checkbox>
                    </div> -->
                </template>
            </Grid>
        </div>

        <ModalWindow v-if="isDisplayAccountList" width="90%" max-width="600px" max-height="90vh" @cancel="isDisplayAccountList = false" :header="$t('component.Payers.selectAccount')" >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <Grid ref="selectlist" :config="accountsGridConfig" @item-selected="accountSelected($event)" />
                    </div>
                </div>
            </div>
        </ModalWindow>
        
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import ModalWindow from '@/components/lib/regions/ModalWindow';
    // import FieldSwitch from '@/components/lib/controls/FieldSwitch';
    import Grid from '@/components/lib/regions/Grid';
    import FieldSwitch from '@/components/lib/controls/FieldSwitch';

    export default {
        name: 'Payers',

        components: { TemplatePageStandard, Grid, ModalWindow, FieldSwitch },

        // =============== Data ===============
        data() {
            return {
                isShowDebtOnly: true,
                isDisplayAccountList: false,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.cashdesk') },
                    { text: this.$t('MainMenu.cashdesk-payers') },
                ],
                gridConfig: {
                    apiUrl: null, // v_payer
                    readonly: true,
                    columns: [
                        // { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'string', key:'payer_name', header:this.$t('component.Payers.payer') },
                        { type:'number', key:'sum_payment_final', header:this.$t('component.Payers.sum_payment') },
                        { type:'number', key:'sum_paid', header:this.$t('component.Payers.sum_paid') },
                        { type:'number', key:'sum_unpaid', header:this.$t('component.Payers.sum_unpaid') },
                    ],
                    order: [ 'payer_name' ],
                    displayToolbar: true,
                    saveSessionState: `${this.$options.name}`,
                    buttons: [ 'edit' ],
                },
                accountsGridConfig: {
                    apiUrl: null,
                    readonly: true,
                    columns: [
                        { type:'hidden', key:'id', header:this.$t('attribute.common.id'), align:'right', width:'100px' },
                        { type:'string', key:'payer_name', header:this.$t('component.Payers.payer') },
                        { type:'string', key:'contract_name', header:this.$t('component.Payers.contract') },
                        { type:'number', key:'sum_unpaid', header:this.$t('component.Payers.sum_unpaid') },
                    ],
                    order: [ 'fullname' ],
                    row: { emit: 'item-selected', style: 'cursor: pointer;' },
                },
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Watch ===============
        watch: {
            isShowDebtOnly() {
                this.setGridConfig();
            },
            // selectedClientId() {
            //     this.setAccountsGridConfig();
            // },
            // selectedOrgId() {
            //     this.setAccountsGridConfig();
            // },
        },

        // =============== Methods ===============
        methods: {
            ...mapActions(['setLastClientId']),

            setGridConfig() {
                // this.gridConfig.apiUrl = `fin-get-payers-to-pay/${ this.isShowDebtOnly ? 'debt' : 'all' }`;
                this.gridConfig.apiUrl = `v_payer`;
                this.gridConfig.filter = this.isShowDebtOnly ? { where:[ ['sum_unpaid','>',0] ]} : {};
                this.$refs.grid.refresh();
                // this.gridConfig = JSON.parse(JSON.stringify(this.gridConfig));
                // console.log(`DEBUG: ${this.$options.name}.setGridConfig(${this.isShowDebtOnly}) this.gridConfig.filter=`, this.gridConfig.filter);
            },

            async payerSelected(payer) {
                // console.log(`DEBUG: ${this.$options.name}.payerSelected(${id})`);
                // await this.setAccountsGridConfig();
                let accountFilter;
                if (payer.payer_client_id) accountFilter = { where:[{ payer_client_id: payer.payer_client_id }]};
                if (payer.payer_org_id) accountFilter = { where:[{ payer_org_id: payer.payer_org_id }]};
                let accounts = (await this.authenticatedAxios.get(`${this.baseUrl}/v_account${tools.getFilterString(accountFilter)}`)).data.rows;
                // console.log(`DEBUG: ${this.$options.name}.payerSelected(${payer.id}) accounts=`, accounts);
                if (accounts.length == 1) {
                    this.accountSelected(accounts[0]);
                } else {
                    this.accountsGridConfig.filter = accountFilter;
                    this.accountsGridConfig.apiUrl = `v_account`;
                    this.isDisplayAccountList = true;
                }
                // await this.setLastClientId(id);
                // this.$router.push({ name: 'client-edit', params: { id } });
            },

            accountSelected(account) {
                // console.log(`DEBUG: ${this.$options.name}.accountSelected(${account.id})`, account);
                this.$router.push({ name: 'cashdesk/account', params: { accountId: account.id } });
            },

        },

        // =============== Life cycle ===============
        async mounted() {
            this.setGridConfig();
        },
    }
</script>

<style>
</style>
