<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-11
-->

<template>
    <div v-if="lastVisit.id" class="visit-details__wrapper">
    <!-- <div class="container-fluid"> -->
        <!-- id:{{ visitId }} -->
        <table class="visit-details__header">
            <tr>
                <th class="visit-details__title">{{ $t('app.datetime') + ':' }}</th>
                <td class="visit-details__value">{{ formatDateTimeShort(lastVisit.time_of) }}</td>
                <!-- <td class="visit-details__value">
                    <FieldDatetime id='time_of' :value.sync="lastVisit.time_of" textClass="visit-details__value" displayonly labelPosition="none" />
                </td> -->
                <!-- <FieldDatetime id='time_of' :value.sync="lastVisit.time_of" textClass="visit-details__value" displayonly labelPosition="before" /> -->
            </tr>
            <tr>
                <th class="visit-details__title">{{ $t('object.visit_type') + ':' }}</th>
                <td class="visit-details__value">{{ lastVisit.visit_type_name }}</td>
            </tr>
            <tr>
                <th class="visit-details__title">{{ $t('app.employee') + ':' }}</th>
                <td class="visit-details__value">{{ lastVisit.user_shortname }}</td>
            </tr>
            <tr>
                <th class="visit-details__title">{{ $t('object.core_dept') + ':' }}</th>
                <td class="visit-details__value">{{ lastVisit.dept_name }}</td>
            </tr>
            <tr>
                <th class="visit-details__title">{{ $t('object.core_speciality') + ':' }}</th>
                <td class="visit-details__value">{{ lastVisit.speciality_name }}</td>
            </tr>
            <tr v-if="!lastVisit.visit_event_id">
                <th class="visit-details__title">{{ $t('attribute.common.status') + ':' }}</th>
                <td class="visit-details__value">{{ lastVisit.status_name }}</td>
            </tr>
            <tr v-if="lastVisit.visit_event_id">
                <!-- <th class="visit-details__title">{{ $t('object.visit_event') + ':' }}</th> -->
                <th class="visit-details__title">{{ lastVisit.visit_event_type_name + ':' }}</th>
                <td class="visit-details__value">{{ lastVisit.visit_event_name }}</td>
            </tr>
            <tr v-if="lastVisit.sum_payment_final && lastVisit.sum_payment_final != 0">
                <th class="visit-details__title">{{ $t('attribute.common.services') + ':' }}</th>
                <td class="visit-details__value">{{ lastVisit.sum_payment_final }}</td>
            </tr>
            <!-- <tr>
                <th class="visit-details__title">{{ $t('attribute.common.sum_paid') + ':' }}</th>
                <td class="visit-details__value">{{ lastVisit.sum_paid }}</td>
            </tr> -->
            <tr v-if="lastVisit.sum_unpaid_person && lastVisit.sum_unpaid_person != 0">
                <th class="visit-details__title">{{ $t('attribute.common.sum_unpaid') + ':' }}</th>
                <td class="visit-details__value">{{ lastVisit.sum_unpaid_person }}</td>
            </tr>
            <tr v-if="lastVisit.commentary && lastVisit.commentary != ''">
                <th class="visit-details__title">{{ $t('attribute.common.commentary') + ':' }}</th>
                <td class="visit-details__value">{{ lastVisit.commentary }}</td>
            </tr>
            <tr v-if="lastVisit.assistant_shortname">
                <th class="visit-details__title">{{ $t('attribute.med_consultation.assistant_user_id') + ':' }}</th>
                <td class="visit-details__value">{{ lastVisit.assistant_shortname }}</td>
            </tr>
        </table>
        <table v-if="lastVisit.details" class="visit-details__details">
            <tr v-for="d in lastVisit.details" :key="d.code">
                <td v-if="Array.isArray(d.files)">
                    <div class="visit-details__title">{{ $t(`VisitDetails.files`) }}</div>
                    <ul>
                        <li v-for="file in d.files" :key="`file-${file.id}`" class="visit-details__value">
                            {{ file.filename }}
                            <i class="fas fa-eye visit-details__link" @click="viewFile(file)" :title="$t('app.view')"></i>
                            <i class="fas fa-download visit-details__link" @click="downloadFile(file)" :title="$t('app.download')"></i>
                        </li>
                    </ul>
                </td>
                <td v-if="!Array.isArray(d.referrals) && !Array.isArray(d.files)">
                    <div class="visit-details__title">{{ $t(`VisitDetails.${d.code}`) }}</div>
                    <div class="visit-details__value">{{ d.value }}</div>
                </td>
                <td v-if="Array.isArray(d.referrals)">
                    <div class="visit-details__title">{{ $t(`VisitDetails.referrals`) }}</div>
                    <ul>
                        <li v-for="r in d.referrals" :key="`ref-${r.id}`" class="visit-details__value">{{ r.name }}</li>
                    </ul>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    // import FieldDatetime from '@/components/lib/controls/FieldDatetime';

    export default {
        name: 'VisitDisplayDetails',

        // components: { FieldDatetime },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
                lastVisit: 'lastVisit',
            }),
        },

        // =============== Methods ===============
        methods: {
            formatDateTimeShort(d) {
                if (!d) return '';
                return moment(d).format('L HH:mm');
            },

            async viewFile(file) {
                try {
                    let response = await this.authenticatedAxios.get(`${this.baseUrl}/core_file/${file.id}/file`, { responseType: 'arraybuffer' });
                    let fileContent = new Blob([response.data], { type: file.mimetype });
                    let url = window.URL.createObjectURL(fileContent);
                    window.open(url, '_blank');
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },

            async downloadFile(file) {
                try {
                    this.authenticatedAxios.get(`${this.baseUrl}/core_file/${file.id}/file`, { responseType: 'arraybuffer' }).then( response => {
                        let fileContent = new Blob([response.data], { type: file.mimetype });
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(fileContent);
                        link.download = file.filename;
                        link.click();
                    });
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        // mounted() {
        //     console.log(`DEBUG: ${this.$options.name}.mounted this.lastVisit=`, this.lastVisit);
        // }
    }
</script>

<style>
    .visit-details__wrapper {
        padding-bottom: 1rem;
        /* background-color: rgb(252, 242, 213); */
        /* background-color: #fff8e5; */
    }
    .visit-details__title {
        padding: .375rem 0.5rem;
        white-space: nowrap;
        vertical-align: top;
        font-size: 0.875rem;
        font-weight: bold;
    }
    .visit-details__header .visit-details__title {
        text-align: right;
    }
    .visit-details__details .visit-details__title {
        margin-top: 0.5rem;
    }
    .visit-details__value {
        padding: .375rem 0.5rem .375rem 0;
        font-size: 0.875rem;
    }
    .visit-details__details .visit-details__value {
        padding-left: 0.5rem;
    }
    .visit-details__link {
        margin-left: 0.5rem;
        cursor: pointer;
        color: #056bbf;
    }
</style>
