<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-06
-->

<template>
    <TemplatePageStandard :config="{ breadcrumb }">

        <template #buttons>
            <button :disabled="reportInProgress" @click.prevent="downloadGrid()" class="btn btn-sm btn-outline-primary mr-1">
                <i class="fas fa-download"></i> {{ $t('lib.Grid.download') }}
            </button>
            <!-- <button :disabled="reportInProgress" @click.prevent="filterModified()" class="btn btn-sm btn-primary mr-1">
                <i class="fas fa-play"></i> {{ $t('app.build') }}
            </button> -->
        </template>

        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm">
                    <Grid v-if="false" ref="report" :config="gridConfig">
                        <!-- <template #buttons>
                            <FieldNumberPlain
                                :label="$t('attribute.common.rows_qty')"
                                labelPosition="before"
                                :value.sync="filterData.row_qty"
                                :defaultValue="20"
                                :minValue="10"
                                :maxValue="10000"
                            />
                        </template> -->
                    </Grid>
                    <div v-if="client && client.code === '00037496'">
                        <table ref="resultGrid" class="lab-result">
                            <thead class="table-header">
                                <tr>
                                    <th class="grid-header" colspan="4">Набор, Полное наименование</th>
                                    <th class="grid-header date-time" colspan="4">31.08.2021</th>
                                    <th class="grid-header date-time" colspan="3">01.09.2021</th>
                                    <th class="grid-header date-time">02.09.2021</th>
                                    <th class="grid-header date-time">03.09.2021</th>
                                    <th class="grid-header date-time">04.09.2021</th>
                                    <th class="grid-header date-time">05.09.2021</th>
                                    <th class="grid-header date-time" colspan="2">08.09.2021</th>
                                </tr>
                                <tr>
                                    <th class="grid-header" rowspan="2">Номер теста</th>
                                    <th class="grid-header" rowspan="2">Описание теста</th>
                                    <th class="grid-header" rowspan="2">Единица измерения</th>
                                    <th class="grid-header" rowspan="2">Референсные значения</th>
                                    <th class="grid-header date-time">08:46</th>
                                    <th class="grid-header date-time">13:07</th>
                                    <th class="grid-header date-time">14:33</th>
                                    <th class="grid-header date-time">23:56</th>
                                    <th class="grid-header date-time">05:20</th>
                                    <th class="grid-header date-time"></th>
                                    <th class="grid-header date-time"></th>
                                    <th class="grid-header date-time">05:38</th>
                                    <th class="grid-header date-time">05:12</th>
                                    <th class="grid-header date-time">05:41</th>
                                    <th class="grid-header date-time">06:24</th>
                                    <th class="grid-header date-time">06:09</th>
                                    <th class="grid-header date-time">09:12</th>
                                </tr>
                                <tr><th class="grid-header" colspan="13">Результат теста</th></tr>
                            </thead>
                            <tbody class="table-body">
                                <tr class="group"><td colspan="17">A010, Общий анализ крови (CBC) без лейкоцитарной формулы</td></tr>
                                <tr><td>A0101</td><td>Гемоглобин</td><td>г/л</td><td>120-158</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td class="result-low">94</td><td></td><td></td><td class="result-low">104</td><td></td></tr>
                                <tr><td>A0102</td><td>Эритроциты</td><td>10^12/л</td><td>3,90-5,20</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td class="result-low">3,46</td><td></td><td></td><td class="result-low">3,80</td><td></td></tr>
                                <tr><td>A0103</td><td>Средний объём эритроцитов</td><td>фл</td><td>81,0-102,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>84,1</td><td></td><td></td><td>85,5</td><td></td></tr>
                                <tr><td>A0104</td><td>Среднее содержание Hb в эритроците</td><td>пг</td><td>26,0-34,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>27,2</td><td></td><td></td><td>27,4</td><td></td></tr>
                                <tr><td>A0105</td><td>Средняя концентрация Hb в эритроците</td><td>г/л</td><td>310-370</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>323</td><td></td><td></td><td>320</td><td></td></tr>
                                <tr><td>A0106</td><td>Гетерогенность эритроцитов по объёму</td><td>%</td><td>11,9-15,5</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>14,7</td><td></td><td></td><td>15,1</td><td></td></tr>
                                <tr><td>A0107</td><td>Гематокрит</td><td>%</td><td>34,9-44,5</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td class="result-low">29,1</td><td></td><td></td><td class="result-low">32,5</td><td></td></tr>
                                <tr><td>A0108</td><td>Тромбоциты</td><td>10^9/л</td><td>150-400</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td class="result-high">450</td><td></td><td></td><td class="result-high">474</td><td></td></tr>
                                <tr><td>A0109</td><td>Средний объём тромбоцитов</td><td>фл</td><td>6,0-13,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>9,8</td><td></td><td></td><td>9,7</td><td></td></tr>
                                <tr><td>A0110</td><td>Гетерогенность тромбоцитов по объёму</td><td>%</td><td>9,0-20,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>10,9</td><td></td><td></td><td>10,6</td><td></td></tr>
                                <tr><td>A0111</td><td>Тромбокрит</td><td>%</td><td>0,12-0,36</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td class="result-high">0,44</td><td></td><td></td><td class="result-high">0,46</td><td></td></tr>
                                <tr><td>A0112</td><td>Лейкоциты</td><td>10^9/л</td><td>4,00-10,50</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td class="result-high">14,94</td><td></td><td></td><td class="result-high">10,44</td><td></td></tr>
                                <tr class="group"><td colspan="17">A020, Общий анализ крови (CBC/Diff) с лейкоцитарной формулой   (5 фракций лейкоцитов)</td></tr>
                                <tr><td>A0101</td><td>Гемоглобин</td><td>г/л</td><td>120-158</td><td class="result-low">118</td><td></td><td></td><td></td><td></td><td class="result-low">104</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0102</td><td>Эритроциты</td><td>10^12/л</td><td>3,90-5,20</td><td>4,25</td><td></td><td></td><td></td><td></td><td>3,77</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0103</td><td>Средний объём эритроцитов</td><td>фл</td><td>81,0-102,0</td><td>84,5</td><td></td><td></td><td></td><td></td><td>82,8</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0104</td><td>Среднее содержание Hb в эритроците</td><td>пг</td><td>26,0-34,0</td><td>27,8</td><td></td><td></td><td></td><td></td><td>27,6</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0105</td><td>Средняя концентрация Hb в эритроците</td><td>г/л</td><td>310-370</td><td>329</td><td></td><td></td><td></td><td></td><td>333</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0106</td><td>Гетерогенность эритроцитов по объёму</td><td>%</td><td>11,9-15,5</td><td>14,4</td><td></td><td></td><td></td><td></td><td>14,5</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0107</td><td>Гематокрит</td><td>%</td><td>34,9-44,5</td><td>35,9</td><td></td><td></td><td></td><td></td><td>31,2</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0108</td><td>Тромбоциты</td><td>10^9/л</td><td>150-400</td><td class="result-high">446</td><td></td><td></td><td></td><td></td><td class="result-high">427</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0109</td><td>Средний объём тромбоцитов</td><td>фл</td><td>6,0-13,0</td><td>10,7</td><td></td><td></td><td></td><td></td><td>9,9</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0110</td><td>Гетерогенность тромбоцитов по объёму</td><td>%</td><td>9,0-20,0</td><td>11,9</td><td></td><td></td><td></td><td></td><td>10,5</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0111</td><td>Тромбокрит</td><td>%</td><td>0,12-0,36</td><td class="result-high">0,48</td><td></td><td></td><td></td><td></td><td class="result-high">0,42</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0112</td><td>Лейкоциты</td><td>10^9/л</td><td>4,00-10,50</td><td class="result-high">23,21</td><td></td><td></td><td></td><td></td><td class="result-high">19,75</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0205</td><td>Нейтрофилы</td><td>%</td><td>41,00-75,00</td><td class="result-high">86,20</td><td></td><td></td><td></td><td></td><td class="result-high">95,30</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0206</td><td>Эозинофилы</td><td>%</td><td>0,80-6,20</td><td>0,00</td><td></td><td></td><td></td><td></td><td>0,10</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0207</td><td>Базофилы</td><td>%</td><td>0,10-2,00</td><td>0,10</td><td></td><td></td><td></td><td></td><td class="result-low">0,10</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0208</td><td>Моноциты</td><td>%</td><td>4,00-12,50</td><td>4,00</td><td></td><td></td><td></td><td></td><td class="result-low">0,50</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0209</td><td>Лимфоциты</td><td>%</td><td>19,00-48,00</td><td class="result-low">9,70</td><td></td><td></td><td></td><td></td><td class="result-low">4,00</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0210</td><td>Нейтрофилы</td><td>10^9/л</td><td>1,90-8,60</td><td class="result-high">19,99</td><td></td><td></td><td></td><td></td><td class="result-high">18,84</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0211</td><td>Эозинофилы</td><td>10^9/л</td><td>0,04-0,60</td><td class="result-low">0,01</td><td></td><td></td><td></td><td></td><td class="result-low">0,01</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0212</td><td>Базофилы</td><td>10^9/л</td><td>0,00-0,20</td><td>0,02</td><td></td><td></td><td></td><td></td><td>0,01</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0213</td><td>Моноциты</td><td>10^9/л</td><td>0,08-1,50</td><td>0,94</td><td></td><td></td><td></td><td></td><td>0,10</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0214</td><td>Лимфоциты</td><td>10^9/л</td><td>0,90-4,20</td><td>2,25</td><td></td><td></td><td></td><td></td><td class="result-low">0,79</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">A030, Лейкоцитарная формула (микроскопия)</td></tr>
                                <tr><td>A0306</td><td>Палочкоядерные нейтрофилы</td><td>%</td><td>1-6</td><td>2</td><td></td><td></td><td></td><td></td><td class="result-low">0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>                            </tr>
                                <tr><td>A0307</td><td>Сегментоядерные нейтрофилы</td><td>%</td><td>41-72</td><td class="result-high">90</td><td></td><td></td><td></td><td></td><td class="result-high">84</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>                            </tr>
                                <tr><td>A0308</td><td>Эозинофилы</td><td>%</td><td>0-6</td><td>0</td><td></td><td></td><td></td><td></td><td>0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0309</td><td>Базофилы</td><td>%</td><td>0-1</td><td>0</td><td></td><td></td><td></td><td></td><td>0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0310</td><td>Моноциты</td><td>%</td><td>4-12</td><td>3</td><td></td><td></td><td></td><td></td><td>6</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>A0311</td><td>Лимфоциты</td><td>%</td><td>19-48</td><td class="result-low">5</td><td></td><td></td><td></td><td></td><td class="result-low">10</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B001, Альбумин</td></tr>
                                <tr><td>B0011</td><td>Альбумин</td><td>г/л</td><td>35,0-52,0</td><td>36,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>36,0</td><td></td></tr>
                                <tr class="group"><td colspan="17">B007, Креатинин</td></tr>
                                <tr><td>B0071</td><td>Креатинин</td><td>мкмоль/л</td><td>50-98</td><td class="result-high">101</td><td></td><td></td><td></td><td></td><td></td><td>72</td><td></td><td>81</td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B015, Мочевая кислота</td></tr>
                                <tr><td>B0151</td><td>Мочевая кислота</td><td>мкмоль/л</td><td>160,0-347,0</td><td class="result-high">352,5</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>263,0</td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B024, Прокальцитонин</td></tr>
                                <tr><td>B0241</td><td>Прокальцитонин</td><td>нг/мл</td><td>0,000-0,500</td><td>0,390</td><td></td><td></td><td></td><td></td><td></td><td>0,400</td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B025, С-реактивный протеин (CRP)</td></tr>
                                <tr><td>B0251</td><td>CRP</td><td>мг/л</td><td>0,0-5,0</td><td class="result-high">211,9</td><td></td><td></td><td></td><td></td><td></td><td class="result-high">176,3</td><td></td><td class="result-high">111,8</td><td></td><td></td><td class="result-high">20,0</td><td></td></tr>
                                <tr class="group"><td colspan="17">B053, Глюкоза</td></tr>
                                <tr><td>B0531</td><td>Глюкоза плазмы</td><td>ммоль/л</td><td>4,10-6,10</td><td class="result-high">11,06</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B055, Гликированный гемоглобин (HbA1c)</td></tr>
                                <tr><td>B0551</td><td>HbA1c</td><td>%</td><td>4,4-6,0</td><td class="result-high">7,1</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B091, Билирубин и его фракции: общий, прямой (связанный) и непрямой (свободный)</td></tr>
                                <tr><td>B0871</td><td>Билирубин общий</td><td>мкмоль/л</td><td>3,40-20,50</td><td>16,70</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B0891</td><td>Билирубин прямой</td><td>мкмоль/л</td><td>0,20-8,60</td><td>3,90</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B0911</td><td>Билирубин непрямой</td><td>мкмоль/л</td><td>3,40-18,80</td><td>12,80</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B093, АЛТ (аланинаминотрансфераза)</td></tr>
                                <tr><td>B0931</td><td>АЛТ</td><td>Ед/л</td><td>0-35</td><td>23</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B095, АСТ (аспартатаминотрансфераза)</td></tr>
                                <tr><td>B0951</td><td>АСТ</td><td>Ед/л</td><td>0-35</td><td>24</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B103, ГГТП (гамма-глутамилтранспептидаза)</td></tr>
                                <tr><td>B1031</td><td>ГГТП</td><td>Ед/л</td><td>4-38</td><td>38</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B121, Щелочная фосфатаза (ALP)</td></tr>
                                <tr><td>B1211</td><td>Щелочная фосфатаза</td><td>Ед/л</td><td>30-120</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>88</td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B125, Калий / Натрий / Хлориды</td></tr>
                                <tr><td>B1251</td><td>Калий</td><td>ммоль/л</td><td>3,60-5,30</td><td>4,55</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B1252</td><td>Натрий</td><td>ммоль/л</td><td>134,0-150,0</td><td>137,7</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B1253</td><td>Хлориды</td><td>ммоль/л</td><td>95,0-106,0</td><td>100,6</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B127, Исследование кислотно-основного состояния (КОС) венозной крови</td></tr>
                                <tr><td>B1271</td><td>pН крови</td><td>Ед</td><td>7,320-7,420</td><td></td><td></td><td></td><td></td><td class="result-high">7,459</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B1272</td><td>pО2</td><td>мм рт, ст,</td><td>38,0-40,0</td><td></td><td></td><td></td><td></td><td class="result-high">77,1</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B1273</td><td>pСО2</td><td>мм рт, ст,</td><td>46,0-58,0</td><td></td><td></td><td></td><td></td><td class="result-low">31,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B1274</td><td>BE(B) (актуальный)</td><td>ммоль/л</td><td>-2,0-2,0</td><td></td><td></td><td></td><td></td><td class="result-low">-1,6</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B1275</td><td>BE(ecf) (стандартный)</td><td>ммоль/л</td><td>-2,3-2,7</td><td></td><td></td><td></td><td></td><td class="result-low">-2,3</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B1276</td><td>AB (Actual Bicarbonate,HCO3 act)</td><td>ммоль/л</td><td>21,0-26,0</td><td></td><td></td><td></td><td></td><td>21,5</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B1277</td><td>SB (Standart Bicarbonate,HCO3 std)</td><td>ммоль/л</td><td>20,0-26,0</td><td></td><td></td><td></td><td></td><td>23,1</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B1278</td><td>sO2 (сатурация О2)</td><td>%</td><td>44,0-49,0</td><td></td><td></td><td></td><td></td><td class="result-high">95,9</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B8923</td><td>Гемоглобин (tHb)</td><td>г/л</td><td>120-158</td><td></td><td></td><td></td><td></td><td class="result-low">109</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B8924</td><td>Гематокрит (Hct)</td><td>%</td><td>34,9-44,5</td><td></td><td></td><td></td><td></td><td class="result-low">32,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B8925</td><td>HbO2</td><td>%</td><td>45-50</td><td></td><td></td><td></td><td></td><td class="result-high">95</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B8927</td><td>HbCO</td><td>%</td><td>0,0-1,5</td><td></td><td></td><td></td><td></td><td>0,6</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B8928</td><td>MetHb</td><td>%</td><td>0,0-1,5</td><td></td><td></td><td></td><td></td><td>0,3</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B8929</td><td>Калий</td><td>ммоль/л</td><td>3,60-5,30</td><td></td><td></td><td></td><td></td><td>4,54</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B8930</td><td>Натрий</td><td>ммоль/л</td><td>134,0-150,0</td><td></td><td></td><td></td><td></td><td>138,3</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B8931</td><td>Хлориды</td><td>ммоль/л</td><td>95-106</td><td></td><td></td><td></td><td></td><td>106</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B8932</td><td>Кальций ионизированный</td><td>ммоль/л</td><td>1,05-1,23</td><td></td><td></td><td></td><td></td><td class="result-high">1,33</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B8933</td><td>Глюкоза</td><td>ммоль/л</td><td>4,1-6,1</td><td></td><td></td><td></td><td></td><td class="result-high">17,2</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B8934</td><td>Лактат</td><td>ммоль/л</td><td>0,00-2,00</td><td></td><td></td><td></td><td></td><td>1,36</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B130, Кальций общий</td></tr>
                                <tr><td>B1301</td><td>Кальций общий</td><td>ммоль/л</td><td>2,20-2,65</td><td class="result-high">2,70</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B132, Кальций общий, скорректированный по альбумину (формула Payne)</td></tr>
                                <tr><td>B0011</td><td>Альбумин</td><td>г/л</td><td>35,0-52,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td class="result-low">26,3</td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B1301</td><td>Кальций общий</td><td>ммоль/л</td><td>2,20-2,65</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>2,29</td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B1321</td><td>Кальций скоррегированный по альбумину</td><td>ммоль/л</td><td>2,20-2,65</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>2,56</td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B145, Магний</td></tr>
                                <tr><td>B1451</td><td>Магний</td><td>ммоль/л</td><td>0,73-1,03</td><td>0,77</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B240, Белок мочи</td></tr>
                                <tr><td>B2401</td><td>Белок мочи</td><td>г/л</td><td>0,00-0,14</td><td class="result-high">1,44</td><td></td><td></td><td></td><td></td><td></td><td></td><td class="result-high">0,86</td><td></td><td></td><td></td><td></td><td class="result-high">1,14</td></tr>
                                <tr class="group"><td colspan="17">B400, ТТГ (тиреотропный гормон)</td></tr>
                                <tr><td>B4001</td><td>ТТГ</td><td>мМЕ/л</td><td>0,400-4,000</td><td>0,752</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B420, Т4 свободный</td></tr>
                                <tr><td>B4201</td><td>T4 свободный</td><td>пмоль/л</td><td>7,70-14,20</td><td>13,27</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B595, С-пептид</td></tr>
                                <tr><td>B0532</td><td>Взятие образца</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>B5951</td><td>С-пептид</td><td>нмоль/л</td><td>0,26-2,40</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>2,03</td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B600, Паратгормон</td></tr>
                                <tr><td>B6001</td><td>Паратгормон</td><td>пг/мл</td><td>15,0-65,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>24,6</td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B605, Кальцитонин</td></tr>
                                <tr><td>B6051</td><td>Кальцитонин</td><td>пг/мл</td><td>0,0-5,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>&lt;2,0</td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">B612, 25-OH витамин D</td></tr>
                                <tr><td>B6121</td><td>25-OH витамин D</td><td>нг/мл</td><td>30,0-100,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>22,1</td><td></td><td></td><td></td></tr>
                                <tr class="group"><td colspan="17">C001, Общий анализ мочи</td></tr>
                                <tr><td>C0011</td><td>Цвет</td><td></td><td></td><td>желтый</td><td></td><td></td><td></td><td></td><td></td><td></td><td>соломенно-жёлтый</td><td></td><td></td><td></td><td></td><td>соломенно-жёлтый</td></tr>
                                <tr><td>C0012</td><td>Прозрачность</td><td></td><td></td><td>мутная</td><td></td><td></td><td></td><td></td><td></td><td></td><td>полная</td><td></td><td></td><td></td><td></td><td>слабо мутная</td></tr>
                                <tr><td>C0013</td><td>Относительная плотность</td><td>г/мл</td><td>1,005-1,035</td><td>1,020</td><td></td><td></td><td></td><td></td><td></td><td></td><td>1,015</td><td></td><td></td><td></td><td></td><td>1,015</td></tr>
                                <tr><td>C0014</td><td>рН мочи</td><td>Ед</td><td>5,0-8,0</td><td>5,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td>5,5</td><td></td><td></td><td></td><td></td><td>5,5</td></tr>
                                <tr><td>C0015</td><td>Белок в моче</td><td>г/л</td><td>0,0-0,1</td><td class="result-high">1,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td class="result-high">1,0</td><td></td><td></td><td></td><td></td><td class="result-high">1,0</td></tr>
                                <tr><td>C0016</td><td>Глюкоза в моче</td><td>ммоль/л</td><td>0,0-0,8</td><td>0,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td>0,0</td><td></td><td></td><td></td><td></td><td>0,0</td></tr>
                                <tr><td>C0017</td><td>Билирубин</td><td></td><td></td><td>++</td><td></td><td></td><td></td><td></td><td></td><td></td><td>отрицательный</td><td></td><td></td><td></td><td></td><td>отрицательный</td></tr>
                                <tr><td>C0018</td><td>Уробилиноген</td><td>мкмоль/л</td><td>0,0-34,0</td><td>16,0</td><td></td><td></td><td></td><td></td><td></td><td></td><td>3,2</td><td></td><td></td><td></td><td></td><td>3,2</td></tr>
                                <tr><td>C0019</td><td>Кетоновые тела</td><td>ммоль/л</td><td>0,0-0,5</td><td class="result-high">1,5</td><td></td><td></td><td></td><td></td><td></td><td></td><td>0,0</td><td></td><td></td><td></td><td></td><td>0,0</td></tr>
                                <tr><td>C0020</td><td>Нитриты</td><td></td><td></td><td>положительный</td><td></td><td></td><td></td><td></td><td></td><td></td><td>отрицательный</td><td></td><td></td><td></td><td></td><td>отрицательный</td></tr>
                                <tr><td>C0023</td><td>Эпителий плоский</td><td></td><td></td><td>4 - 6 в п/зр</td><td></td><td></td><td></td><td></td><td></td><td></td><td>1 - 2 в п/зр</td><td></td><td></td><td></td><td></td><td>0 - 1 в п/зр</td></tr>
                                <tr><td>C0024</td><td>Эпителий переходный</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>0 - 1 в п/зр</td></tr>
                                <tr><td>C0025</td><td>Эпителий почечный</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0026</td><td>Лейкоциты</td><td></td><td></td><td>лейкоциты сплошь все п/зр</td><td></td><td></td><td></td><td></td><td></td><td></td><td>100 - 150 в п/зр</td><td></td><td></td><td></td><td></td><td>15 - 20 в п/зр</td></tr>
                                <tr><td>C0027</td><td>Эритроциты неизменённые</td><td></td><td></td><td>1 - 2 в п/зр</td><td></td><td></td><td></td><td></td><td></td><td></td><td>10 - 15 в п/зр</td><td></td><td></td><td></td><td></td><td>20 - 30 в п/зр</td></tr>
                                <tr><td>C0028</td><td>Эритроциты изменённые</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0029</td><td>Гиалиновые цилиндры</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>0 - 1 в п/зр</td></tr>
                                <tr><td>C0030</td><td>Эпителиальные цилиндры</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0031</td><td>Зернистые цилиндры</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0032</td><td>Восковидные цилиндры</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0033</td><td>Эритроцитарные цилиндры</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0034</td><td>Лейкоцитарные цилиндры</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0035</td><td>Цилиндроиды</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0036</td><td>Слизь</td><td></td><td></td><td>в небольшом количестве</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>в небольшом количестве</td></tr>
                                <tr><td>C0037</td><td>Бактерии</td><td></td><td></td><td>в большом количестве</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>в умеренном количестве</td></tr>
                                <tr><td>C0038</td><td>Мочевая кислота</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0039</td><td>Ураты</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>C0040</td><td>Оксалаты</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0041</td><td>Аморфные фосфаты</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                                <tr><td>C0042</td><td>Триппельфосфаты</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0043</td><td>Кристаллы цистина</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0044</td><td>Кристаллы лейцина</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0045</td><td>Кристаллы тирозина</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0046</td><td>Кристаллы холестерина</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0047</td><td>Нейтральный жир</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0048</td><td>Жирные кислоты</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0049</td><td>Гемосидерин</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0050</td><td>Гематоидин</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                                <tr><td>C0051</td><td>Грибы  дрожжевые</td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td></td><td></td><td>нет</td><td></td><td></td><td></td><td></td><td>нет</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </TemplatePageStandard>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    // import FieldNumberPlain from '@/components/lib/controls/FieldNumberPlain';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'ReportPrice',

        components: {
            TemplatePageStandard,
            // FieldNumberPlain,
            Grid,
        },

        // =============== Data ===============
        data() {
            return {
                clientId: null,
                client: null,
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.clients'), to: '/clients' },
                    { text: this.$t('object.core_client'), to: `/client/${this.clientId}` },
                    { text: this.$t('MainMenu.clients-lab-results') },
                ],
                filterData: {
                    dateFrom: null,
                    dateTo: null,
                    // core_building_id: null,
                    // row_qty: null,
                },
                reportInProgress: false,
                gridConfig: {
                    apiUrl: 'report-price',
                    readonly: true,
                    columns: [
                        { type:'string', key:'group_name', header:this.$t('object.fin_service_group') },
                        { type:'string', key:'code', header:this.$t('attribute.common.code'), style:'white-space:nowrap;' },
                        { type:'string', key:'code_public', header:this.$t('attribute.common.code_public'), style:'white-space:nowrap;' },
                        { type:'string', key:'name', header:this.$t('attribute.common.name') },
                        // { type:'number', key:'price', header:this.$t('attribute.common.price'), align:'right' },
                        {
                            type:'number', key:'price', header:this.$t('attribute.common.price'), align:'right',
                            $value: (entry) => ( entry.is_price_variable ? 'изм.' : entry.price ),
                        },
                    ],
                    order: [ 'group_name', 'name' ],
                    saveSessionState: 'report_price',
                },
            };
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Methods ===============
        methods: {
            async setGridConfig() {
                this.reportInProgress = true;
                // this.gridConfig.paginationPageSize = this.filterData.row_qty;
                // this.gridConfig.apiUrl = `report-price`;
                await this.$refs.report.refresh();
                this.reportInProgress = false;
            },

            async filterModified() { await this.setGridConfig() },

            async getData() {
                try {
                    this.client = (await this.authenticatedAxios.get(`${this.baseUrl}/v_client/${this.clientId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.editFormData=`, this.editFormData);
                    // this.isModified = false;
                    // this.msgItemModified = '';
                    // await this.getReferrals();
                } catch (err) {
                    tools.displayApiError(this.$parent, err);
                }
            },

            saveReport() {
                this.gridConfig.downloadFileName = (
                    this.breadcrumb[this.breadcrumb.length - 1].text + '_' + moment().format('YYYY-MM-DD')
                );
                this.$refs.report.download();
            },

            downloadGrid() {
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), this.$el.innerHTML=`, this.$el.innerHTML);
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), this.$el=`, this.$el);
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), this.$refs.reportTable=`, this.$refs.reportTable);
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), this.filteredData=`, this.filteredData);

                let reportPrefix = `
                    <html><head>
                        <meta charset="utf-8">
                        <style>
                            .subtotals, .totals {
                                font-weight: bold;
                            }
                            .grid-header {
                                color: #ffffff;
                                background-color: #4285f4;
                                border:.5pt solid #dee2e6;
                                font-weight: bold;
                            }
                            .grid-cell {
                                border:.5pt solid #dee2e6;
                            }
                        </style>
                    </head><body>
                `;
                let reportSuffix = `
                    </body></html>
                `;
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), this.$refs.resultGrid=`, this.$refs.resultGrid);
                let parser = new DOMParser();
                let gridTable = parser.parseFromString(this.$refs.resultGrid.outerHTML, 'text/html');
                // let gridFilters = gridTable.querySelector('.grid-filters');
                // if (gridFilters) gridFilters.remove();
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), ${typeof reportBody} gridTable=`, gridTable);

                // let reportBody = this.$refs.reportTable.outerHTML;
                let reportBody = gridTable.querySelector('.lab-result').outerHTML;
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), ${typeof reportBody} reportBody=`, reportBody);
                
                // let reportBody = this.$refs.resultGrid.outerHTML;
                reportBody = reportBody.replaceAll('&nbsp;', ' ');
                reportBody = reportBody.replaceAll('<!---->', '');
                reportBody = reportBody.replaceAll(' title=""', '');
                // reportBody = reportBody.replaceAll('grid-data-container ', '');
                // reportBody = reportBody.replaceAll(' active', '');
                // reportBody = reportBody.replaceAll(' column-required', '');
                // reportBody = reportBody.replaceAll(' icon', '');
                reportBody = reportBody.replaceAll(' date-time', '');
                // reportBody = reportBody.replaceAll('.', ',');
                // console.log(`DEBUG: ${this.$options.name}.downloadGrid(), ${typeof reportBody} reportBody=`, reportBody);
                let fileContent = new Blob([ reportPrefix, reportBody, reportSuffix ], { type: 'application/vnd.ms-excel' });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(fileContent);
                link.download = `${ this.client.fullname }-labo-results.xls`;
                link.click();
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            this.clientId = parseInt(this.$route.params.clientId);
        },

        async mounted() {
            if (this.clientId) {
                await this.getData();
                // this.setBreadcrumb();
                this.breadcrumb[this.breadcrumb.length - 2].to = `/client/${this.clientId}`;
                this.breadcrumb[this.breadcrumb.length - 2].text = this.client.shortname;
                // this.gridConfig.filter = { where:[{ core_client_id:this.clientId }]};
                // this.gridConfig.apiUrl = `v_file`;
            }
        },
    }
</script>

<style>
    .lab-result, .lab-result th, .lab-result td {
        border: 1px solid #aaa;
        border-collapse: collapse;
    }
    .lab-result th {
        padding: 0 4px;
        background-color: #4285f4;
        color: #fff;
    }
    .lab-result tr.group {
        background-color: #b1ccf8;
    }
    .lab-result td {
        padding: 0 4px;
        font-size: 0.8rem;
    }
    .date-time {
        text-align: center;
    }
    .result-high {
        color: red;
        font-weight: bold;
    }
    .result-low {
        color: magenta;
        font-weight: bold;
    }
</style>
