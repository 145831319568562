<!--
    Author: Eduard Grebenyukov
    Date: 2022-01-29
-->

<template>
    <TemplatePageStandard
        :config="{ breadcrumb }"
        :isModified="isModified"
        :isDisplayWarningClose="isDisplayWarningClose"
        :isDisplayWarningDelete="isDisplayWarningDelete"
        @process-close="processClose($event)"
        @process-delete="processDeleteItem($event)"
    >
        <div id="page-content" class="form-float">
            <div class="row">
                <div class="col-sm">
                    <Grid ref="visitorsGridConfig" :config="visitorsGridConfig"/>
                </div>
            </div>
        </div>

    </TemplatePageStandard>
</template>

<script>
    // import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';
    import Grid from '@/components/lib/regions/Grid';

    export default {
        name: 'VisitQueueMonitor',

        components: {
            TemplatePageStandard,
            Grid,
        },

        mixins: [ tools.warningCloseMixin, tools.warningDeleteMixin ],

        // =============== Data ===============
        data() {
            return {
                itemId: undefined,
                buttons: [],
                msgItemModified: '',
                breadcrumb: [
                    { text: this.$t('MainMenu.home'), to: '/' },
                    { text: this.$t('MainMenu.settings') },
                    // { text: this.$t('MainMenu.settings-visits') },
                    { text: this.$t('MainMenu.settings-visits-queue-monitor') },
                ],
                visitorsGridConfig: {
                    apiUrl: 'v_visit_queue',
                    filter: null,
                    title: this.$t('component.VisitQueueMonitor.v_visit_queue'),
                    readonly: true,
                    columns: [
                        { type:'number', key:'visit_rec_id', header:this.$t('component.VisitQueueMonitor.visit_rec_id'), align:'right', width:'64px' },
                        { type:'number', key:'visit_visitor_id', header:this.$t('component.VisitQueueMonitor.visit_visitor_id'), align:'right', width:'64px' },
                        { type:'string', key:'ticket_num', header:this.$t('component.VisitQueueMonitor.ticket_num') },
                        { type:'datetime', key:'visitor_time_of', header:this.$t('component.VisitQueueMonitor.visitor_time_of') },
                        // { type:'string', key:'visit_status_code', header:this.$t('component.VisitQueueMonitor.visit_status_code') },
                        { type:'string', key:'visit_status_name_short', header:this.$t('component.VisitQueueMonitor.visit_status_name') },
                        { type:'datetime', key:'visit_status_time_from', header:this.$t('component.VisitQueueMonitor.visit_status_time_from') },
                        { type:'string', key:'client_code', header:this.$t('component.VisitQueueMonitor.client_code') },
                        // { type:'string', key:'client_fullname', header:this.$t('component.VisitQueueMonitor.client_fullname') },
                        { type:'string', key:'client_shortname', header:this.$t('component.VisitQueueMonitor.client_shortname') },
                        { type:'string', key:'route_name', header:this.$t('component.VisitQueueMonitor.route_name') },
                        { type:'string', key:'visit_stage_name', header:this.$t('component.VisitQueueMonitor.visit_stage_name') },
                        { type:'string', key:'workplace_name', header:this.$t('component.VisitQueueMonitor.workplace_name') },
                        { type:'string', key:'speciality_name', header:this.$t('component.VisitQueueMonitor.speciality_name') },
                    ],
                    row: {
                        $backgroundColor: (row) => {
                            if (row.visit_status_code === 'started') { return '#c9dcfc'; }
                            if (row.visit_status_code === 'completed') { return '#c9fcdc'; }
                            if (row.visit_status_code === 'canceled') { return '#fcc9dc'; }
                            return '';
                        },
                    },
                    order: [{ key: 'visit_visitor_id', direction: 'desc' }],
                    // saveSessionState: `${this.$options.name}.squ_visitor`,
                },
            }
        },

        // // =============== Computed ===============
        // computed: {
        //     ...mapGetters({
        //         baseUrl: 'baseUrl',
        //         authenticatedAxios: 'authenticatedAxios',
        //     }),
        // },

        // // =============== Methods ===============
        // methods: {
        // },
    }
</script>

<style>
</style>
