<!--
    Author: Eduard Grebenyukov
    Date: 2020-08-11
-->

<template>
    <div>
        <StandardRegion v-if="clientId" :title="$t('object.core_client')">
            <div class="client-info__wrapper">
                <table class="client-info__header">
                    <tr>
                        <th class="client-info__title">{{ $t('attribute.common.code') + ':' }}</th>
                        <td class="client-info__value">{{ client.code }}</td>
                    </tr>
                    <tr>
                        <th class="client-info__title">{{ $t('attribute.core_person.fullname') + ':' }}</th>
                        <td class="client-info__value">{{ client.fullname }}</td>
                    </tr>
                    <tr>
                        <th class="client-info__title">{{ $t('attribute.core_person.dob') + ':' }}</th>
                        <td class="client-info__value">{{ client.dob | dateString }}</td>
                    </tr>
                    <tr>
                        <th class="client-info__title">{{ $t('attribute.core_person.email') + ':' }}</th>
                        <td class="client-info__value">{{ client.email }}</td>
                    </tr>
                </table>
                <!-- <table class="client-info__details">
                    <tr>
                        <td>
                            <div class="client-info__title">{{ $t(`Contacts.phones`) }}</div>
                            <ul>
                                <li v-for="phone in phones" :key="`phone-${phone.id}`" class="client-info__value">{{ phone.phone_num }}</li>
                            </ul>
                        </td>
                    </tr>
                </table> -->
            </div>
        </StandardRegion>
        <PersonPhones ref="phones" :personId="client.client_person_id" />
        <ClientBuildings ref="buildings" :clientId="clientId" />
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    import tools from '@/components/lib/tools';
    import StandardRegion from '@/components/lib/regions/StandardRegion';
    import PersonPhones from './PersonPhones';
    import ClientBuildings from './ClientBuildings';

    export default {
        name: 'ClientInfo',

        components: { StandardRegion, PersonPhones, ClientBuildings },

        // =============== Props ===============
        props: {
            clientId: Number,
        },

        // =============== Data ===============
        data() {
            return {
                client: {},
                // phones: [],
            }
        },

        // =============== Filters ===============
        filters: {
            dateString(t) {
                return t ? moment(t).format('L') : '';
            },
        },

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                baseUrl: 'baseUrl',
                authenticatedAxios: 'authenticatedAxios',
            }),
        },

        // =============== Watch ===============
        watch: {
            async clientId() {
                await this.getData();
            },
        },

        // =============== Methods ===============
        methods: {
            async getData() {
                try {
                    this.client = (await this.authenticatedAxios.get(`${this.baseUrl}/v_client/${this.clientId}`)).data;
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.client=`, this.client);
                    // let filter = tools.getFilterString({ where:[
                    //     { core_person_id:this.client.client_person_id },
                    // ]});
                    // this.phones = (await this.authenticatedAxios.get(`${this.baseUrl}/core_phone${filter}`)).data.rows;
                    // console.log(`DEBUG: ${this.$options.name}.getData() this.phones=`, this.phones);
                } catch (err) {
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            // console.log(`DEBUG: ${this.$options.name}.mounted(), this.clientId=`, this.clientId);
            if (this.clientId) {
                await this.getData();
            }
        },
    }
</script>

<style>
    .client-info__wrapper {
        padding-bottom: 1rem;
        /* background-color: rgb(252, 242, 213); */
        /* background-color: #fff8e5; */
    }
    .client-info__title {
        padding: .375rem 0.5rem;
        white-space: nowrap;
        vertical-align: top;
        font-size: 0.875rem;
        font-weight: bold;
    }
    .client-info__header .client-info__title {
        text-align: right;
    }
    .client-info__details .client-info__title {
        margin-top: 0.5rem;
    }
    .client-info__value {
        padding: .375rem 0.5rem .375rem 0;
        font-size: 0.875rem;
    }
    .client-info__details .client-info__value {
        padding-left: 0.5rem;
    }
</style>
