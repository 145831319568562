<!--
    Author: Eduard Grebenyukov
    Date: 2020-07-01
-->

<template>
    <RenderedServiceEdit :isDental="true" />
</template>

<script>
    import RenderedServiceEdit from './RenderedServiceEdit';

    export default {
        name: 'RenderedServiceEditDental',
        components: { RenderedServiceEdit },
    }
</script>

<style scoped>
</style>
