<!--
    Author: Eduard Grebenyukov
    Date: 2020-04-04
-->

<template>
    <TemplatePageStandard :config="config">
        <div id="page-content">
            <div v-if="!isDeck" class="submenu-container">
                <div class="row">
                    <div v-for="item in config.items" :key="item.code" class="col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0">
                        <router-link :to="item.href" class="submenu-link">
                            <div class="card submenu-card submenu-card-isgrid" >
                                <div class="card-body">
                                    <i class="card-icon" :class="item.icon" ></i> {{ item.title }}
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div v-if="isDeck" class="submenu-container card-deck">
                <router-link v-for="item in config.items" :key="item.code" :to="item.href" class="card submenu-card submenu-card-isdeck submenu-link col-sm-6 col-md-4 col-lg-3 col-xl-2 px-0">
                    <div class="card-body">
                        <i class="card-icon" :class="item.icon" ></i> {{ item.title }}
                    </div>
                </router-link>
            </div>
        </div>
    </TemplatePageStandard>
</template>>

<script>
    import TemplatePageStandard from '@/components/pages/common/TemplatePageStandard';

    export default {
        name: 'SubmenuPage',
        components: { TemplatePageStandard },

        // =============== Props ===============
        props: {
            config: { required: false },
        },

        // =============== Data ===============
        data() {
            return {
                isDeck: true,
            }
        },
    }
</script>

<style scoped>
    .submenu-container {
        margin: 5px 13px;
    }
    .submenu-container.card-deck {
        margin: 0px 0px;
    }
    .submenu-link {
        text-decoration: none;
        color: #6c757d;
    }
    .submenu-link:hover {
        text-decoration: none;
        color: #2c3e50;
    }
    .submenu-card {
        margin: 2px;
    }
    .submenu-card-isgrid {
        min-height: 6rem;
    }
    .submenu-card-isdeck {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
    }
    .submenu-card:hover {
        background-color: #e9ecef;
    }
    .card-icon {
        font-size: 1.2rem;
    }
</style>>
