<!--
    Author: Eduard Grebenyukov
    Date: 2020-01-07
-->

<template>
    <div>
        <SidebarMenu class="sidebar sidebar-lg" :menu="mainMenu" :relative="true" hideToggle showOneChild :currentMenu="currentMenu">
            <i class="fas fa-angle-double-left fa-fw" slot="toggle-icon"></i>
            <i class="fas fa-chevron-down fa-fw" slot="dropdown-icon"></i>
        </SidebarMenu>
    </div>
</template>

<script>
    import $ from 'jquery';
    import { mapGetters } from 'vuex';
    import { SidebarMenu } from 'vue-sidebar-menu';

    export default {
        name: 'MainMenuSidebar',

        components: { SidebarMenu },
        props: ['currentMenu'],

        // =============== Computed ===============
        computed: {
            ...mapGetters({
                mainMenu: 'mainMenu',
            }),
        },

        // =============== Methods ===============
        methods: {
            onScroll() {
                let wrapper = $('.sticky .v-sidebar-menu .vsm--scroll-wrapper');
                // console.log(`DEBUG: ${this.$options.name}.onScroll() y=${$('.sticky .v-sidebar-menu .vsm--scroll-wrapper').offset().top}`);
                let top = wrapper.offset().top;
                if (top > 50) {
                    wrapper.height($(window).height() - 40);
                } else {
                    wrapper.height($(window).height() - 40 - 50);
                }
            },
        },

        // =============== Life cycle ===============
        async mounted() {
            this.$nextTick(() => {
                window.addEventListener('scroll', this.onScroll);
            });
            this.onScroll();
        },
    }
</script>

<style lang="scss">
    .v-sidebar-menu {
        padding-bottom: var(--page-footer-height);
    }

    .v-sidebar-menu.vsm_expanded {
        width: var(--sidebar-menu-width);
        max-width: var(--sidebar-menu-width);
    }

    #sidebar .v-sidebar-menu .vsm--scroll-wrapper {
        height: calc(100vh - var(--page-footer-height));
        scrollbar-width: thin;
    }

    #sidebar .v-sidebar-menu .vsm--scroll-wrapper::-webkit-scrollbar {
        width: 8px;
        /* background: #fff; */
    }

    #sidebar .v-sidebar-menu .vsm--scroll-wrapper::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        /* outline: 1px solid slategrey; */
        border-radius: 8px;
    }

    /* #sidebar .v-sidebar-menu .vsm--scroll-wrapper::-webkit-scrollbar-button {
        height: 8px;
        background: #888;
    } */

    /* .v-sidebar-menu { margin-top: 50px; } */
    #body-row .v-sidebar-menu .vsm--item span { overflow: hidden; text-overflow: ellipsis; }
    #body-row .v-sidebar-menu .vsm--link { font-size: 1rem; font-weight: 300; }
    #body-row .v-sidebar-menu .vsm--link_active { font-weight: 600; }
    #body-row .v-sidebar-menu .vsm--icon { font-size: 1.2rem; }
    #body-row .v-sidebar-menu .vsm--link.vsm--link_level-1 { padding:6px 5px 6px 10px; }
    #body-row .v-sidebar-menu .vsm--link.vsm--link_level-2 { padding:6px 0 6px 24px; background-color: #1e1e1e; }
    #body-row .v-sidebar-menu .vsm--link.vsm--link_level-3 { padding:6px 5px 6px 32px; background-color: #1e1e1e; }

    #body-row .v-sidebar-menu .vsm--item_open .vsm--list { background-color: #1e1e1e; }
    #body-row .v-sidebar-menu .vsm--link_level-1 .vsm--icon { background-color: inherit; }
    #body-row .v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 { background-color: #1e1e1e; }

    #body-row .v-sidebar-menu .vsm--link_active { box-shadow: 3px 0px 0px 0px #4285f4 inset; color: #4285f4; }
    #body-row .v-sidebar-menu .vsm--link_exact-active { box-shadow: 3px 0px 0px 0px yellowgreen inset; color: yellowgreen; }
    #body-row .v-sidebar-menu .vsm--link_active.vsm--link_level-2 { margin-left: -5px; margin-right: 5px; padding-left: 29px; }
    /* #body-row .v-sidebar-menu .vsm--link_active.vsm--link_level-2 .vsm--title { padding-left: 5px; } */
    #body-row .v-sidebar-menu .vsm--link_active.vsm--link_level-3 { margin-left: -10px; }
    #body-row .v-sidebar-menu .vsm--link_active.vsm--link_level-3 .vsm--title { padding-left: 10px; }

    /* #body-row .v-sidebar-menu .vsm--link_exact-active { margin-left: -10px; box-shadow: 3px 0px 0px 0px yellowgreen inset; color: yellowgreen; }
    #body-row .v-sidebar-menu .vsm--link_exact-active .vsm--title { padding-left: 10px; } */

    /* #body-row .v-sidebar-menu .vsm--link_exact-active, .v-sidebar-menu .vsm--link_active { color: yellowgreen; } */
    /* #body-row .v-sidebar-menu .vsm--link_exact-active { margin-left: -10px; padding-left: 10px; border-left: 3px solid yellowgreen; color: yellowgreen; }
    #body-row .v-sidebar-menu .vsm--link_exact-active .vsm--title { padding-left: 7px; } */
    /* #body-row .v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon { background-color: #303035; } */
    /* #body-row .v-sidebar-menu .vsm--item.vsm--item_open { background-color: #1e1e1e; } */
    /* .v-sidebar-menu .vsm--link.vsm--link_active { background-color: green; }
    .v-sidebar-menu .vsm--link.vsm--link_exact-active { background-color: indigo; } */
</style>
