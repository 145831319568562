<!--
    Author: Eduard Grebenyukov
    Date: 2018-11-11
-->

<template>
    <div id="page-wrap">
        <div id="filler"></div>
        <!-- <h4 v-if="showFailureMessage" class="bg-danger text-white text-center p-2 my-2"> {{$t('LoginPage.failed')}}</h4> -->
        <div id="login" v-cloak>
            <div class="container">
                <!-- <div class="text-center"><img id="login-logo-image" src="@/assets/images/healtis-logo-grey-64.png" width="128px"></div> -->
                <div class="login__logo text-center">Heamis</div>
                <p class="text-center">{{ $t('LoginPage.greeting') }}</p>
                <div class="login-form-container">
                    <hr/>
                    <div class="form-group">
                        <input
                            ref="username"
                            type="text"
                            name="username"
                            class="form-control"
                            :placeholder="$t('LoginPage.username')"
                            v-model.trim="username"
                            autocomplete="username"
                            focus-first
                            @keyup.enter="username ? $refs.password.focus() : null"
                        >
                        <!-- <validation-error v-bind:validation="username" /> -->
                    </div>
                    <div class="form-group">
                        <input
                            ref="password"
                            type="password"
                            name="password"
                            class="form-control"
                            :placeholder="$t('LoginPage.password')"
                            v-model.trim="password"
                            autocomplete="current-password"
                            @keyup.enter="handleAuth()"
                        >
                        <!-- <validation-error v-bind:validation="password" /> -->
                    </div>
                    <!--div class="form-group form-check">
                        <input class="form-check-input" type="checkbox" id="remember" name="rememberMe" v-model="rememberMe"/>
                        <label class="form-check-label" for="remember">Remember Me</label>
                    </div-->
                    <div class="form-group">
                        <button class="btn btn-dark btn-lg btn-block" v-on:click="handleAuth()">{{ $t('LoginPage.login') }}</button>
                    </div>
                    <!-- <p class="text-center"><a style="float:left" href="/signup">Sign up</a> <a style="float:right" href="/password/forgot">Forgot your password?</a></p> -->
                    <div class="btn-group btn-group-sm float-right" role="group">
                        <div class="btn ml-1" @click="setLang('ru')" :class="(language==='ru' ? 'btn-secondary disabled' : 'btn-outline-secondary')">
                            РУС
                        </div>
                        <div class="btn ml-1" @click="setLang('en')" :class="(language==='en' ? 'btn-secondary disabled' : 'btn-outline-secondary')">
                            ENG
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PageFooter :key="$route.fullPath" :displayLogout="false" />

    </div>

</template>

<script>
    import { mapActions, mapState } from "vuex";

    import tools from '@/components/lib/tools';

    import PageFooter from '@/components/pages/common/PageFooter';

    export default {
        name: 'LoginPage',

        components: { PageFooter },

        // =============== Data ===============
        data() {
            return {
                username: null,
                password: null,
                showFailureMessage: false,
            }
        },

        // =============== Computed ===============
        computed: {
            ...mapState({
                authenticated: state => state.auth.authenticated,
                language: state => state.language,
            }),
            build() { return tools.getFrontendBuild(this) },
        },

        // =============== Methods ===============
        methods: {
            ...mapActions([ 'authenticate', 'setLanguage' ]),

            setLang(lang) {
                this.setLanguage(lang);
                this.$router.go(); // refresh page to reload lang in mainMenu
                // window.location.reload(true);
            },

            async handleAuth() {
                try {
                    let result = await this.authenticate({ username: this.username, password: this.password, build:this.build });
                    // console.log(`DEBUG: ${this.$options.name}.handleAuth() result=`, result);
                    if (this.authenticated) {
                        // console.log(`DEBUG: ${this.$options.name}.handleAuth() auth success`);
                        this.$router.push("/");
                    } else {
                        // console.log(`DEBUG: ${this.$options.name}.handleAuth() auth error`);
                        // this.showFailureMessage = true;
                        if (result === null) {
                            tools.displayErrorServerNotAvailable(this);
                        } else {
                            if ([403, 404, 405, 422, 500].includes(result)) {
                                tools.displayApiError(this, { response:{ status:result }});
                            } else {
                                tools.displayWarning(this, this.$t('LoginPage.failed'));
                            }
                        }
                    }
                } catch (err) {
                    // console.log(`DEBUG: ${this.$options.name}.handleAuth() auth exception`);
                    tools.displayApiError(this, err);
                }
            },
        },

        // =============== Life cycle ===============
        async beforeMount() {
            tools.refreshOutdatedPage(this); // no wait
        },
    }
</script>

<style scoped>
    #page-wrap {
        width: 100%;
        height: 100%;
        background-color: #505f6d;
    }

    /*#page-wrap {
        padding: 5% 0;
    }*/

    #login {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 500px;
        /* min-height: 400px; */
        margin: -250px 0 0 -250px;

        padding: 25px;

        background-color: #fff;

    }
    /*.login-form-container {
        .container-sm();
    }*/
    @media (max-width: 768px) {
        #login {
            /* position: absolute; */
            top: 0;
            left: 0;
            width: 100%;
            margin: 0;
        }
        img#login-logo {
            max-width:30%;
            height:auto;
        }
    }

    #login, #login a {
        color: #404040;
    }
    #login h1 {
        font-size: 2.4rem;
        color: #505f6d;
    }

    .login__logo {
        font-size: 2.4rem;
        font-weight: bold;
        font-family: 'Days One', Helvetica, Arial, sans-serif;
        color: #505f6d;
    }

    .login__logo::first-letter {
        color: #ff7a7a;
    }

    #login-logo-image {
        margin-left: auto;
        margin-right: auto;
    }

    #page-footer {
        background-color: #fff;
    }
</style>
